import { ToastState, Action } from 'Map/Components/ToastTypes';
import { ToastProps } from 'Map/Components/ToastTypes';

export const initialToastState: ToastState = {
    toasts: [],
};

export const reducer = (state: ToastState, action: Action): ToastState => {
    switch (action.type) {
        case 'add-toast':
            const newToast: ToastProps = {
                message: action.message,
                status: action.status,
                date: new Date(),
                autoTimer: action.autoTimeout,
                timeOutTimer: action.timeoutTimer,
            };
            return {
                ...state,
                toasts: [...state.toasts, newToast],
            };
        case 'remove-toast':
            const tempArr = [...state.toasts];
            tempArr.splice(action.id, 1);
            const newToastArr = [...tempArr];

            return {
                ...state,
                toasts: newToastArr,
            };
        default:
            return state;
    }
};
