import { useEffect, useMemo, useState } from 'react';
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon, FunnelIcon } from '@heroicons/react/24/solid';
import { DefaultCell, Skeleton } from 'Map/DataBrowser/DataBrowserCells';
import { useTable, useFilters, useSortBy, usePagination, useBlockLayout, useResizeColumns, Column } from 'react-table';
import {
    fuzzyTextFilterFn,
    fuzzyNodeDisplayNameFilterFn,
    filterGreaterThan,
    DefaultColumnFilter,
} from 'Library/TableComponents';
import { useTenant } from 'Hooks/Hooks';
import { DELETE_ALERT_DEFINITION, LIST_ALERT_DEFINITIONS } from 'Graph/queries';
import { useMutation, useQuery } from '@apollo/client';
import { AddAlertDefinition } from './AddAlertDefinition';
import { classNames } from 'Utilities/utils';
import { useToasts } from 'Hooks/Toasts';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUserPermissions } from 'Utilities/UserPermissions';
import { AlertDefinition } from 'Alerts/AlertTypes';

const AlertConfigColumns = () =>
    [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            width: 500,
        },
        {
            Header: 'Alert Type',
            accessor: 'jobType',
            filter: 'fuzzyText',
            width: 300,
        },
        {
            Header: 'Warning Threshold',
            accessor: 'warningThreshold',
            filter: 'fuzzyText',
            width: 200,
        },
        {
            Header: 'Alert Threshold',
            accessor: 'alertThreshold',
            filter: 'fuzzyText',
            width: 200,
        },
    ] as Column<AlertDefinition>[];

export const AlertsConfig = (): JSX.Element => {
    const { enableFilterDisabledAlerts } = useFlags();
    const { addToast } = useToasts();

    const [selectedAlert, setSelectedAlert] = useState<AlertDefinition | undefined>();
    const [toggleFilters, setToggleFilters] = useState(false);

    const [alertDefinitions, setAlertDefinitions] = useState<AlertDefinition[]>([]);

    const tenantId = useTenant();

    const { userCan } = useUserPermissions();
    const canDeleteAlertDefinitions = userCan('delete', '/alerts/definitions');
    const canCreateAlertDefinitions = userCan('create', '/alerts/definitions');

    const { loading, error, data } = useQuery(LIST_ALERT_DEFINITIONS, { variables: { tenantId } });

    const [deleteAlertDefinition, { loading: loadingDelete }] = useMutation(DELETE_ALERT_DEFINITION, {
        refetchQueries: [{ query: LIST_ALERT_DEFINITIONS, variables: { tenantId } }],
    });

    const deleteAlert = async () => {
        if (selectedAlert && selectedAlert.id) {
            try {
                const result = await deleteAlertDefinition({
                    variables: {
                        tenantId,
                        deleteAlertDefinitionInput: { id: selectedAlert.id },
                    },
                });

                if (result.data?.deleteAlertDefinition?.success) {
                    addToast('Alert definition deleted', 'success');
                    setSelectedAlert(undefined);
                } else {
                    addToast('Failed to delete alert definition', 'failure');
                }
            } catch (e) {
                addToast('Failed to delete alert definition', 'failure');
            }
        }
    };

    useEffect(() => {
        if (data && data.listAlertDefinitions) {
            if (data.listAlertDefinitions.alertDefinitions) {
                if (enableFilterDisabledAlerts) {
                    const enabledAlertDefinitions = data.listAlertDefinitions.alertDefinitions.filter(
                        (alert: AlertDefinition) => alert.enabled,
                    );

                    setAlertDefinitions(enabledAlertDefinitions);
                } else {
                    setAlertDefinitions(data.listAlertDefinitions.alertDefinitions);
                }
            } else {
                setAlertDefinitions([]);
            }
        }
    }, [data, enableFilterDisabledAlerts]);

    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            fuzzyNodeDisplayName: fuzzyNodeDisplayNameFilterFn,
            greaterThan: filterGreaterThan,
        }),
        [],
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            minWidth: 100,
            width: 200,
            maxWidth: 800,
        }),
        [],
    );

    const columns: Column<AlertDefinition>[] = useMemo(AlertConfigColumns, []);

    const loadingData = useMemo(() => Array(5).fill({}), []);

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable<AlertDefinition>(
        {
            columns,
            data: loading ? loadingData : alertDefinitions,
            defaultColumn,
            filterTypes,
            disableMultiSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: 40,
                sortBy: [{ id: 'name' }],
            },
        },
        useFilters,
        useSortBy,
        usePagination,
        useFilters,
        useBlockLayout,
        useResizeColumns,
    );

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <p className="uppercase tracking-wider font-bold text-xs text-gray-400">
                    <button
                        onClick={() => setSelectedAlert(undefined)}
                        className="uppercase tracking-wider font-bold text-xs text-gray-400"
                    >
                        Alert Definitions
                    </button>
                    {selectedAlert && (
                        <>
                            <span className="ml-1">{'>'}</span>
                            <span className="ml-1">Edit Alert Definition</span>
                        </>
                    )}
                </p>
                <div className="flex items-center space-x-2 text-xs">
                    {!selectedAlert && (
                        <button
                            type="button"
                            className="btn rounded-md px-3 py-2 focus-none"
                            onClick={() => setToggleFilters(!toggleFilters)}
                        >
                            <FunnelIcon className="h-4 w-4" />
                        </button>
                    )}
                    {canCreateAlertDefinitions && !selectedAlert && (
                        <button
                            type="button"
                            className="btn rounded-md px-3 py-2 focus-none"
                            onClick={() => {
                                if (!selectedAlert) {
                                    setSelectedAlert({
                                        name: '',
                                        status: 'Ok',
                                        query: '',
                                        message: '',
                                        warningThreshold: 0,
                                        alertThreshold: 1,
                                        muted: false,
                                        mutedUntil: '',
                                        destination: [],
                                        jobType: 'UNKNOWN',
                                    });
                                }
                            }}
                        >
                            Create Alert +
                        </button>
                    )}
                    {canDeleteAlertDefinitions && selectedAlert && selectedAlert.id && (
                        <button
                            type="button"
                            className={classNames(
                                'btn rounded-md px-3 py-2 focus-none',
                                loadingDelete ? 'btn-disabled' : '',
                            )}
                            onClick={deleteAlert}
                            disabled={loadingDelete}
                        >
                            {loadingDelete ? 'Deleting...' : 'Delete Alert Definition'}
                        </button>
                    )}
                </div>
            </div>
            {selectedAlert ? (
                <AddAlertDefinition selectedAlert={selectedAlert} finished={() => setSelectedAlert(undefined)} />
            ) : (
                <table {...getTableProps()} className="w-full text-left text-xs mb-4">
                    <thead className="sticky top-0 bg-gray-700">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()} className="pt-2 px-2 align-middle bg-gray-700">
                                        <div className="flex items-center mb-2">
                                            <span>{column.render('Header')}</span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <ChevronDownIcon
                                                        className="h-5 w-5"
                                                        {...column.getSortByToggleProps()}
                                                    />
                                                ) : (
                                                    <ChevronUpIcon
                                                        className="h-5 w-5"
                                                        {...column.getSortByToggleProps()}
                                                    />
                                                )
                                            ) : (
                                                <ChevronUpDownIcon
                                                    className="h-5 w-5"
                                                    {...column.getSortByToggleProps()}
                                                />
                                            )}
                                            <div
                                                {...column.getResizerProps()}
                                                className={column.isResizing ? 'resizerIsResizing' : 'resizer'}
                                            />
                                        </div>
                                        {toggleFilters && (
                                            <div className="pl-1">
                                                {column.canFilter ? column.render('Filter') : null}
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="overflow-y-hidden pb-[40px] bg-gray-600">
                        {!loading && !error && alertDefinitions.length === 0 && (
                            <tr className="text-gray-400 py-1">
                                <td colSpan={columns.length} className="px-2 py-4 text-center">
                                    No alert definitions found
                                </td>
                            </tr>
                        )}
                        {error && alertDefinitions.length === 0 && (
                            <tr className="py-1 text-gray-400">
                                <td colSpan={columns.length} className="px-2 py-2 flex items-center justify-center">
                                    <ExclamationTriangleIcon className="h-8 w-8 pr-2" />
                                    There was an issue loading alert definitions, please try again later.
                                </td>
                            </tr>
                        )}
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className="text-gray-300 hover:bg-gray-900 py-1"
                                    onClick={() => {
                                        setSelectedAlert(row.original);
                                    }}
                                >
                                    {row.cells.map((cell, idx) => {
                                        if (loading) {
                                            return <Skeleton cell={cell} key={idx} />;
                                        }

                                        switch (cell.column.id) {
                                            case 'actions':
                                                return (
                                                    <td {...cell.getCellProps()} className="py-1.5 px-2 ">
                                                        <div className="flex space-x-2 items-center ">
                                                            <button className="btn py-1 px-2">Mute</button>
                                                            <button className="btn py-1 px-2">Resolve</button>
                                                        </div>
                                                    </td>
                                                );
                                            case 'status':
                                                return (
                                                    <td {...cell.getCellProps()} className="py-1.5 px-2 ">
                                                        <div className="flex items-center uppercase text-xxs">
                                                            {cell.value === 'Ok' && (
                                                                <span className="bg-green-600 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    Ok
                                                                </span>
                                                            )}
                                                            {cell.value === 'Warning' && (
                                                                <span className="bg-yellow-500 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    Warning
                                                                </span>
                                                            )}
                                                            {cell.value === 'Alert' && (
                                                                <span className="bg-red-600 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    Alert
                                                                </span>
                                                            )}
                                                            {cell.value === 'No Data' && (
                                                                <span className="bg-gray-700 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    No Data
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                            case 'jobType':
                                                return (
                                                    <td {...cell.getCellProps()} className="py-1.5 px-2 ">
                                                        <div className="flex items-center ">
                                                            {cell.value === 'FAILED_ACCESS_FROM_ACTORS' && (
                                                                <span className="bg-gray-500 text-white py-1 px-2 rounded-md w-50 text-center">
                                                                    Failed Access From Actors
                                                                </span>
                                                            )}
                                                            {cell.value === 'FAILED_ACCESS_TO_TARGETS' && (
                                                                <span className="bg-gray-500 text-white py-1 px-2 rounded-md w-50 text-center">
                                                                    Failed Access To Targets
                                                                </span>
                                                            )}
                                                            {cell.value === 'SUSPICIOUS_LOCATIONS' && (
                                                                <span className="bg-gray-500 text-white py-1 px-2 rounded-md w-50 text-center">
                                                                    Suspicious Locations
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                            default:
                                                return <DefaultCell cell={cell} key={idx} />;
                                        }
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};
