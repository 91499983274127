import { getDisplayName, getUsersTimezone, tagNameLookup } from 'Utilities/utils';
import { ListItem } from '../Library/List';
import { GET_ENTITY_BY_STATUS, GET_ENTITY_LIST, GET_STATUS_REASONS } from 'Graph/queries';
import { format } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TimeIntervalProps } from 'Types/types';
import { classNames } from 'Utilities/utils';
import { SkipToTime } from 'Library/SkipToTime';
import { ActorsOutcomeChart } from './Charts/ActorsOutcomeChart';
import { AccessCountItem, BasePanel, CountItem, ReasonCountItem } from './Library/BasePanel';

import { Node } from 'Types/types';
import { NodeAvatar } from 'Library/NodeAvatar';
import { ActorStats } from './Stats/ActorStats';
import { DashboardTabProps } from './DashboardModalTypes';
import { TheWallInsights } from './Wall/TheWallInsights';
import { useMemo } from 'react';
import { generateDataSeries } from 'Map/Graph/Data';

export const Access = ({
    open,
    startDate,
    endDate,
    selectedStartDate,
    selectedEndDate,
    interval,
    onBarClick,
    selectedBar,
}: DashboardTabProps) => {
    const { policy, enableTheWall } = useFlags();

    const trustData = useMemo(() => {
        const data = generateDataSeries();
        return data;
    }, []);

    if (!open) return <> </>;

    return (
        <div className="flex space-x-4">
            <div className={classNames(enableTheWall ? 'w-3/4' : 'w-full')}>
                <div className={classNames('bg-gray-700 px-0 pt-2 pb-4 rounded-md block')}>
                    <div className="flex items-center justify-between">
                        <div>
                            <h3 className="text-xs text-gray-400 flex ml-5 mt-2" data-test="selected-date-time">
                                <span className="uppercase tracking-widest ">
                                    {format(selectedStartDate, 'EEE do, HH:mm')} -{' '}
                                    {format(selectedEndDate, 'EEE do, HH:mm')}{' '}
                                    <span className="text-gray-500">({getUsersTimezone()})</span>
                                </span>
                                <SkipToTime startDate={selectedStartDate} endDate={selectedEndDate} />
                            </h3>
                        </div>
                    </div>

                    <div className="flex pl-3">
                        <ActorsOutcomeChart
                            startDate={startDate}
                            endDate={endDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                            plotTrust={policy}
                            trustData={trustData}
                        />
                    </div>
                </div>

                <div className="flex flex-wrap sm:flex-nowrap justify-around items-center bg-gray-700 pt-2 pb-1 rounded-md mt-4 md:h-24">
                    <ActorStats
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                    />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 text-xs mt-4">
                    <TopActivityActors startDate={selectedStartDate} endDate={selectedEndDate} />
                    <TopFailingActors startDate={selectedStartDate} endDate={selectedEndDate} />
                    <TopFailureReasons startDate={selectedStartDate} endDate={selectedEndDate} />
                    <TopActivityTargets startDate={selectedStartDate} endDate={selectedEndDate} />
                </div>
            </div>
            {enableTheWall && (
                <div className="w-1/4">
                    <div className="bg-gray-700 rounded-md block h-full">
                        <TheWallInsights startDate={startDate} endDate={endDate} />
                    </div>
                </div>
            )}
        </div>
    );
};

const TopActivityActors = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Actor Activity"
            height="h-200"
            defaultLimit={13}
            startDate={startDate}
            endDate={endDate}
            query={GET_ENTITY_LIST}
            queryName="getEntityList"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_ACTOR',
                order: 'STATS_ORDER_DESC',
            }}
            resolveNodes={true}
            renderItem={(idx, item: AccessCountItem, node: Node, isNodeInExplorer, addNodeToExplorer) => {
                return (
                    <ListItem
                        key={idx}
                        node={node}
                        title={getDisplayName(node)}
                        description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                        secondTitle="Events"
                        secondDescription={item.accessCount.toString()}
                        highlighted={isNodeInExplorer}
                        icon={<NodeAvatar node={node} />}
                        onClick={() => {
                            if (addNodeToExplorer) {
                                addNodeToExplorer(node, false, true);
                            }
                        }}
                    />
                );
            }}
        />
    );
};

export const TopFailingActors = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Actors with Most Failures"
            startDate={startDate}
            endDate={endDate}
            height="h-200"
            emptyIsPositive={true}
            defaultLimit={13}
            query={GET_ENTITY_BY_STATUS}
            queryName="getEntityByStatus"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_ACTOR',
                status: 'OUTCOME_DENY',
                order: 'STATS_ORDER_DESC',
            }}
            resolveNodes={true}
            renderItem={(idx, item: CountItem, node: Node, isNodeInExplorer, addNodeToExplorer) => {
                return (
                    <ListItem
                        key={idx}
                        node={node}
                        title={getDisplayName(node)}
                        description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                        secondTitle="Events"
                        secondDescription={`${item.count}`}
                        thirdTitle="Failure"
                        thirdDescription={`${item.rate}%`}
                        thirdDescriptionColor="text-gray-500"
                        highlighted={isNodeInExplorer}
                        icon={<NodeAvatar node={node} />}
                        onClick={() => {
                            if (addNodeToExplorer) {
                                addNodeToExplorer(node, false, true);
                            }
                        }}
                    />
                );
            }}
        />
    );
};

export const TopActivityTargets = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Target Activity"
            startDate={startDate}
            endDate={endDate}
            className="bg-gray-700 rounded-md row-span-1 col-span-1 p-3"
            query={GET_ENTITY_LIST}
            queryName="getEntityList"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_TARGET',
                limit: 5,
                order: 'STATS_ORDER_DESC',
            }}
            resolveNodes={true}
            emptyIsPositive={true}
            renderItem={(idx, item: AccessCountItem, node: Node, isNodeInExplorer, addNodeToExplorer) => {
                return (
                    <ListItem
                        key={idx}
                        node={node}
                        title={getDisplayName(node)}
                        description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                        secondTitle="Events"
                        secondDescription={item.accessCount.toString()}
                        highlighted={isNodeInExplorer}
                        icon={<NodeAvatar node={node} />}
                        onClick={() => {
                            if (addNodeToExplorer) {
                                addNodeToExplorer(node, false, true);
                            }
                        }}
                    />
                );
            }}
        />
    );
};

export const TopFailureReasons = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Top Access Failure Reason"
            className="bg-gray-700 rounded-md row-span-1 col-span-1 p-3"
            startDate={startDate}
            endDate={endDate}
            query={GET_STATUS_REASONS}
            queryName="getStatusReasons"
            variables={{
                status: 'OUTCOME_DENY',
                order: 'STATS_ORDER_DESC',
            }}
            resolveNodes={false}
            emptyIsPositive={true}
            renderItem={(idx, item: ReasonCountItem) => {
                return (
                    <ListItem
                        key={idx}
                        title={item.reason || 'No reason given by the identity provider.'}
                        description={item.count.toString() + ' Events'}
                    />
                );
            }}
        />
    );
};
