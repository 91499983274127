import { GET_ENTITY_EVENT_HISTORY } from 'Graph/queries';
import { BackendEventHistoryData } from 'Types/types';
import { BaseChart, DashboardChartProps } from 'Dashboard/Charts/BaseChart';
import { getEntityType } from 'Utilities/utils';
import { Node } from 'Types/types';

export const EventHistoryChart = ({
    startDate,
    endDate,
    interval,
    onBarClick,
    node,
    selectedBar,
    plotTrust,
    trustData,
}: DashboardChartProps & { node: Node }) => {
    return (
        <BaseChart
            height={108}
            margin={{ top: -38, right: 0, bottom: 0, left: -20 }}
            legendHeight={40}
            query={GET_ENTITY_EVENT_HISTORY}
            queryName="getEntityEventHistory"
            variables={{
                entityId: node?.id,
                entityType: getEntityType(node),
            }}
            processRow={(row: BackendEventHistoryData, previousRow) => {
                const previousTrust = previousRow?.Trust || 0;
                const roundedTrustScore = Math.round(row.trustScore || 0);
                const trustScore = roundedTrustScore > 0 ? roundedTrustScore : previousTrust > 0 ? previousTrust : 0;

                return {
                    Success: row.successCount,
                    Challenge: row.challengeCount,
                    Failure: row.denyCount,
                    Trust: trustScore,
                };
            }}
            bars={[
                {
                    name: 'Success',
                    dataKey: 'Success',
                    activeColor: 'rgba(101, 163, 13, 1)',
                    inactiveColor: 'rgba(101, 163, 13, 0.5)',
                },
                {
                    name: 'Challenge',
                    dataKey: 'Challenge',
                    activeColor: 'rgba(245, 158, 11, 1)',
                    inactiveColor: 'rgba(245, 158, 11, 0.5)',
                },
                {
                    name: 'Failure',
                    dataKey: 'Failure',
                    activeColor: 'rgba(225, 29, 72, 1)',
                    inactiveColor: 'rgba(225, 29, 72, 0.5)',
                },
            ]}
            startDate={startDate}
            endDate={endDate}
            interval={interval}
            onBarClick={onBarClick}
            selectedBar={selectedBar}
            plotTrust={plotTrust}
            trustData={trustData}
        />
    );
};
