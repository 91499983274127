import { Menu } from '@headlessui/react';
import { ChevronDownIcon, DocumentDuplicateIcon, DocumentIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { classNames, providerNameLookup } from 'Utilities/utils';
import { Actors } from './ActorsDashboard';
import { Targets } from './TargetsDashboard';
import { Access } from './AccessDashboard';

import 'react-datepicker/dist/react-datepicker.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import { useChartTimeRange } from './Charts/ChartHooks';
import { Trust } from './TrustDashboard';
import { Location } from './LocationDashboard';
import { CSVDownloader } from './CSV/CSVDownloader';
import { getProviderIconElement } from 'Map/Graph/Icons';
import { Tooltip } from 'Library/Tooltip';
import { useQuery } from '@apollo/client';
import { LIST_PROVIDERS } from 'Graph/queries';
import { useTenant, useURLParameter } from 'Hooks/Hooks';
import { TrustPrototype } from './TrustDashboardPrototype';
import { DashboardModalProps, DashboardTabs } from './DashboardModalTypes';

export const DashboardModal = ({ open }: DashboardModalProps): JSX.Element => {
    const tenantId = useTenant();

    const urlQuery = useURLParameter();

    const [insightsAutoLoadComplete, setInsightsAutoLoadComplete] = useState(false);

    const { data: dataListProviders } = useQuery(LIST_PROVIDERS, {
        variables: { tenantId: tenantId || '' },
        skip: !tenantId,
    });

    const {
        enableDashboardReporting,
        openInsightsOnLoad,
        policy,
        enableWorldMap,
        // enableLocationPrototype,
        insightsDefaultTab,
        enableFutureTabs,
    } = useFlags();
    const { dispatch } = useContext(IdentityMapContext);

    const {
        startDate,
        endDate,
        selectedStartDate,
        selectedEndDate,
        interval,
        setNarrowedEpoch,
        UnitSelect,
        TimeWindowSelect,
    } = useChartTimeRange();

    const [selectedBar, setSelectedBar] = useState(-1);

    useEffect(() => {
        setSelectedBar(-1);
    }, [startDate, endDate, interval]);

    const onBarClick = (epoch: number | undefined, index: number | undefined) => {
        setNarrowedEpoch(epoch);
        setSelectedBar(index ?? -1);
    };

    const closeDashboard = () => {
        if (open) {
            dispatch({ type: 'toggle-dashboard' });
        }
    };
    useHotkeys('esc', closeDashboard, [open]);

    useEffect(() => {
        if (openInsightsOnLoad && !insightsAutoLoadComplete) {
            const viewQueryParam = urlQuery.get('view');

            if (viewQueryParam) {
                console.log('A shared View query parameter exists, not opening dashboard');
                return;
            }

            console.log('Opening Insights Dashboard on load');

            dispatch({ type: 'toggle-dashboard' });

            setInsightsAutoLoadComplete(true);
        }
    }, [dispatch, insightsAutoLoadComplete, openInsightsOnLoad, urlQuery]);

    const [tab, setTab] = useState<DashboardTabs>(insightsDefaultTab);

    const Tab = ({ tabName, tabTitle }: { tabName: DashboardTabs; tabTitle: string }) => (
        <li>
            <button
                data-test={tabName}
                type="button"
                className={classNames(
                    'rounded-t-md py-3 px-4 text-sm h-full',
                    tab === tabName
                        ? 'bg-gray-700/50 text-white'
                        : 'text-gray-500 hover:text-gray-300 hover:bg-gray-700/25',
                )}
                onClick={() => setTab(tabName)}
            >
                {tabTitle}
            </button>
        </li>
    );

    return (
        <div
            className={open ? 'fixed bottom-0 z-10 inset-0 overflow-y-auto top-[56px] h-[calc(100vh-56px)]' : 'hidden'}
        >
            {open && (
                <Helmet>
                    <title>Insights</title>
                </Helmet>
            )}
            <div className="flex items-end justify-center text-center sm:block sm:p-0">
                <div
                    className="fixed top-[56px] h-[calc(100vh-56px)] w-[100vw] after:inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                    onClick={closeDashboard}
                />
                <div
                    id="Insights"
                    className="absolute left-[50%] translate-x-[-50%] inline-block bg-gray-800 rounded-lg text-left shadow-xl transform transition-all w-[90vw] xxl:w-[75vw] mt-14 align-top md:mt-5"
                >
                    <div id="Header" className="flex justify-between items-start p-4 pb-2">
                        <h1 className="text-md font-bold tracking-wider">Insights</h1>
                        {enableDashboardReporting && tab === 'access' ? (
                            <div className="flex space-x-2">
                                <CSVDownloader
                                    tab={tab}
                                    startDate={selectedStartDate}
                                    endDate={selectedEndDate}
                                    interval={interval}
                                />
                            </div>
                        ) : tab === 'trust' ? (
                            <div className="flex space-x-3">
                                {dataListProviders && dataListProviders.listProviders.length > 0 && (
                                    <div className="flex space-x-8 py-2 pl-4 pr-6 bg-gray-700 rounded-lg items-center shadow-lg">
                                        {dataListProviders?.listProviders?.map((provider: any) => {
                                            return (
                                                <ProviderStatus
                                                    key={provider.providerId}
                                                    label={`${provider.name} is enabled`}
                                                    name={providerNameLookup(provider.type)}
                                                    color="bg-green-500"
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                                <DownloadButton />
                            </div>
                        ) : (
                            <div className="h-8 w-8"></div>
                        )}
                    </div>

                    <div className="flex">
                        <ul id="Tabs" className="flex space-x-1 px-2 w-full">
                            {policy && <Tab tabName="trust" tabTitle="Trust" />}
                            <Tab tabName="access" tabTitle="Access" />
                            <Tab tabName="actors" tabTitle="Actors" />
                            <Tab tabName="targets" tabTitle="Targets" />
                            {enableWorldMap && <Tab tabName="location" tabTitle="Location" />}
                            {enableFutureTabs && <Tab tabName="trust-future" tabTitle="Trust (Future)" />}
                            {/* {enableLocationPrototype && <Tab tabName="location-future" tabTitle="Location (Future)" />} */}
                        </ul>
                        <div className="flex space-x-2 mr-4 mb-2 place-items-center">
                            <UnitSelect />
                            <TimeWindowSelect />
                        </div>
                    </div>
                    <div className="bg-gray-700/50 p-4 text-xs overflow-y-auto max-h-[calc(100vh-200px)] rounded-b-lg">
                        <Access
                            open={tab === 'access'}
                            startDate={startDate}
                            endDate={endDate}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                        <Actors
                            open={tab === 'actors'}
                            startDate={startDate}
                            endDate={endDate}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                        <Targets
                            open={tab === 'targets'}
                            startDate={startDate}
                            endDate={endDate}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                        <Trust
                            open={tab === 'trust'}
                            startDate={startDate}
                            endDate={endDate}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                        <TrustPrototype
                            open={tab === 'trust-future'}
                            startDate={startDate}
                            endDate={endDate}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                        <Location
                            open={tab === 'location'}
                            startDate={startDate}
                            endDate={endDate}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                    </div>
                    <button
                        data-test="modal-close"
                        type="button"
                        className="text-white text-xs rounded-full p-1 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md"
                        onClick={closeDashboard}
                    >
                        <XMarkIcon className="h-4 w-4 text-gray-200" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export const ProviderStatus = ({ label, name, color }: { label: string; name: string; color: string }): JSX.Element => {
    return (
        <Tooltip label={label}>
            <div className="relative">
                <img src={getProviderIconElement(name).src} className="h-5 w-5" />
                <span className={'absolute -bottom-0.5 -right-2.5 h-2 w-2 rounded-full ' + color}></span>
            </div>
        </Tooltip>
    );
};

// const repeatOptions = [
//     { value: 'daily', label: 'Daily' },
//     { value: 'weekly', label: 'Weekly, on Mondays' },
//     { value: 'monthly', label: 'Monthly, on the 19th ' },
// ];

// const ScheduleButton = (): JSX.Element => {
//     const [startDate, setStartDate] = useState(new Date('9/19/2022'));
//     const [repeat, setRepeat] = useState(false);
//     const [endRepeat, setEndRepeat] = useState(false);
//     const [recipients, setRecipients] = useState([]);

//     const onRecipientChange = (event: []) => {
//         setRecipients(event);
//     };

//     const onStartChange = (event: Date) => {
//         setStartDate(event);
//     };

//     return (
//         <Popover className="relative">
//             {({ open }) => (
//                 <>
//                     <Popover.Button
//                         className={`
//                   ${open ? 'opacity-50' : ''}
//                   btn text-xs rounded-md inline-flex`}
//                     >
//                         <CalendarIcon className="h-4 w-4 mr-2" />
//                         Schedule
//                     </Popover.Button>
//                     <Popover.Panel className="absolute left-0 z-10 mt-3 w-72 transform pr-4">
//                         <div className="overflow-hidden rounded-lg shadow-lg">
//                             <div className="relative bg-gray-900 p-4 text-xs">
//                                 <label className="text-xs text-gray-400 mt-0 mb-2 block">
//                                     Add recipients for this report
//                                 </label>
//                                 <CreatableSelect
//                                     isMulti
//                                     classNamePrefix="select-sm"
//                                     onChange={onRecipientChange}
//                                     value={recipients}
//                                 />
//                                 <label className="text-xs text-gray-400 mt-3 mb-2 block">
//                                     Choose a date to deliver report
//                                 </label>
//                                 <DatePicker
//                                     selected={startDate}
//                                     onChange={onStartChange}
//                                     startDate={startDate}
//                                     minDate={new Date()}
//                                     inline
//                                 />
//                                 <label className="text-xs text-gray-400 mb-2 mt-3 flex items-center">
//                                     <input
//                                         type="checkbox"
//                                         checked={repeat}
//                                         onChange={() => {
//                                             setRepeat(!repeat);
//                                         }}
//                                         className="chk-box h-4 w-4 mr-2"
//                                     />
//                                     Repeat sending this report
//                                 </label>

//                                 {repeat && (
//                                     <>
//                                         <Select
//                                             classNamePrefix="select-sm"
//                                             defaultValue={repeatOptions[0]}
//                                             name="dataType"
//                                             options={repeatOptions}
//                                             className="dashboard-datepicker"
//                                             menuPlacement="top"
//                                         />
//                                         <label className="text-xs text-gray-400 mb-2 mt-3 flex items-center">
//                                             <input
//                                                 type="checkbox"
//                                                 checked={endRepeat}
//                                                 onChange={() => {
//                                                     setEndRepeat(!endRepeat);
//                                                 }}
//                                                 className="chk-box h-4 w-4 mr-2"
//                                             />
//                                             Stop sending this report after
//                                         </label>
//                                         {endRepeat && (
//                                             <div className="flex items-stretch">
//                                                 <input
//                                                     type="number"
//                                                     className="flex-1 input-gray text-xs pr-4 rounded-l-md rounded-r-none"
//                                                     min="1"
//                                                     defaultValue="1"
//                                                     placeholder="Number"
//                                                     data-step
//                                                 />
//                                                 <span className="flex-1 input-gray text-center text-xs text-gray-200 bg-gray-500 rounded-r-md rounded-l-none">
//                                                     occurrences
//                                                 </span>
//                                             </div>
//                                         )}
//                                     </>
//                                 )}

//                                 <div className="flex justify-between items-center mt-3">
//                                     <label className="flex items-center text-gray-400">
//                                         <input type="checkbox" className="chk-box h-4 w-4 mr-2" />
//                                         Send all reports
//                                     </label>
//                                     <button type="button" className="btn btn-primary px-3 text-xs rounded-md">
//                                         Schedule
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </Popover.Panel>
//                 </>
//             )}
//         </Popover>
//     );
// };

const DownloadButton = (): JSX.Element => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={`
                  ${open ? 'opacity-50' : ''}
                  btn btn-primary text-xs rounded-md inline-flex h-full`}
                    >
                        Download Report
                        <ChevronDownIcon className="h-4 w-4 ml-2" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-gray-900 shadow-lg text-xs outline-none">
                        <div className="py-2">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-blue-700 text-white' : 'text-gray-400',
                                            'px-4 py-2 text-xs flex items-center',
                                        )}
                                        href="#"
                                    >
                                        <DocumentIcon className="h-4 w-4 mr-2" />
                                        Download current report
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-blue-700 text-white' : 'text-gray-400',
                                            'px-4 py-2 text-xs flex items-center',
                                        )}
                                        href="#"
                                    >
                                        <DocumentDuplicateIcon className="h-4 w-4 mr-2" />
                                        Download all reports
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </>
            )}
        </Menu>
    );
};
