import { useFlags } from 'launchdarkly-react-client-sdk';
import { Panel } from './Panel';

type Bindings = {
    [key: string]: string[];
};

const bindings: Bindings = {
    'Select All Nodes': ['control+a'],
    'Show All Nodes': ['shift+a'],
    'Zoom Map to Fit': ['z'],
    'Center Map': ['c'],
    'Pan Map': ['space (hold)'],
    Undo: ['control+z'],
    'Focus Search Bar': ['control+p'],
    'Remove All Nodes from Explorer': ['x'],
    'Remove Non-Searched Nodes from Explorer': ['shift-x'],
    'Show Toolbox': ['`'],
    'Show Profile': ['p'],
    'Show Insights': ['o'],
    'Pan Direction': ['w a s d'],
    'Zoom In/Out': ['↑ ↓'],
    'Drag All Selected Nodes': ['alt / option (hold)'],
    'Lock Selected Nodes': ['l'],
    'Unlock All Nodes': ['u'],
    'Toggle Node Panel': ['e'],
    'Toggle Events Layer': ['control+e'],
    'Toggle Labels Layer': ['control+l'],
    'Toggle Names Layer': ['control+n'],
};

const Keybindings = (): JSX.Element => {
    const { agentsPanel, policy, timeline } = useFlags();

    if (policy) bindings['Toggle Policy'] = ['1'];
    if (timeline) bindings['Toggle Timeline'] = ['2'];
    if (agentsPanel) bindings['Toggle Agents'] = ['3'];

    return (
        <Panel title="Keybindings" subtitle="Navigate faster, get stuff done">
            <table className="w-full text-left text-xs ">
                <colgroup>
                    <col className="w-6/12" />
                    <col className="w-6/12" />
                </colgroup>
                <thead className="">
                    <tr className="border-b border-gray-600">
                        <th className="py-2 px-5 w-1/4">Command</th>
                        <th className="py-2 px-5 w-1/4">Keybinding</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(bindings).map(([command, keys]) => (
                        <tr key={command}>
                            <td className="py-2 px-5">{command}</td>
                            <td className="py-2 px-5">{keys.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Panel>
    );
};

export default Keybindings;
