import { useCombobox, UseComboboxStateChange } from 'downshift';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';
import {
    classNames,
    compareNodesByDisplayName,
    getDisplayName,
    isNodeHidden,
    targetNodeTypeLookup,
} from 'Utilities/utils';
import {
    FunnelIcon,
    PlusIcon,
    MagnifyingGlassIcon,
    XCircleIcon,
    ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/solid';
import { getIconSourceURL, getNodeIconElement } from '../Graph/Icons';
import User from 'assets/icons/Actors/User.png';
import Desktop from 'assets/icons/Devices/Desktop.png';
import DoubleZero from 'assets/icons/Identities/DoubleZero.png';
import Target from 'assets/icons/Targets/Target.png';
import Application from 'assets/icons/Applications/Application.png';
import { GraphFilter } from 'Types/types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce, useProductTutorial, useTenant } from 'Hooks/Hooks';
import { useGraphControls } from 'Hooks/GraphHooks';
import Avatar from 'react-avatar';
import { Tooltip } from 'Library/Tooltip';
import { ToastContext } from './ToastContext';
import { SEARCH_ATTRIBUTES } from 'Graph/queries';
import { useLazyQuery } from '@apollo/client';
import { ResultsChat } from './ResultsChat';
import { useFlags } from 'launchdarkly-react-client-sdk';

let numTargets = 0;
let numIdentities = 0;
let numDevices = 0;
let numActors = 0;
let numApplications = 0;

const maxItemsToRender = 25;

const itemToString = (item: Node | null): string => {
    if (item) {
        getDisplayName(item);
    }
    return '';
};

const searchQueryReplacements = (query: string): string => {
    // To make it easier to find some objects, we replace some common words
    // with their synonyms in the search string
    const r = query.replace(/^user|use|us$/gi, 'actor');
    return r;
};

type SearchAttributeItem = {
    id: string;
    sid: string;
    email: string;
    displayName: string;
    country: string;
    state: string;
    city: string;
    enabled: boolean;
};

export const CommandPalette = (): JSX.Element => {
    const { enableSean } = useFlags();

    const tenantId = useTenant();
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { graphRef, graphData, unfilteredGraphData } = mapState;
    const { runOnTutorialStep, moveToNextTutorialStep } = useProductTutorial();
    const { addNodeToExplorer, addNodeToQuery } = useGraphControls();

    // TODO: use tab state to switch dataset
    const [searchContext, setSearchContext] = useState<'map' | 'global'>('global');
    const [seanMode, setSeanMode] = useState<boolean>(false);

    const [searchAttributesActor, { loading: loadingActor, error: errorActor }] = useLazyQuery(SEARCH_ATTRIBUTES);
    const [searchAttributesTarget, { loading: loadingTarget, error: errorTarget }] = useLazyQuery(SEARCH_ATTRIBUTES);
    const error = errorActor || errorTarget;
    const debouncedLoading = useDebounce<boolean>(loadingActor || loadingTarget);

    const mapHasNodes = useMemo(() => {
        return mapState.queriedNodes.size > 0 && graphData.nodes.length > 0;
    }, [graphData.nodes.length, mapState.queriedNodes.size]);

    const handleSelectedItemChange = ({ selectedItem }: UseComboboxStateChange<Node>) => {
        // Clear the search field and close the menu, returning focus to the graph
        clearAndCloseMenu();

        // Select the node in the graph and zoom to it
        const node = selectedItem;
        if (node && graphRef) {
            addNodeToExplorer(node);
            if (isHotkeyPressed('shift')) {
                addNodeToQuery(node);
            }

            dispatch({ type: 'set-scroll-to-node', node: node });
        }

        // Sometimes the search field does not get cleared correctly the first time
        // so we need to clear it again ;)
        clearAndCloseMenu();
        reset();
    };

    const [searchResults, setSearchResults] = useState(graphData.nodes);

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        setInputValue,
        inputValue: searchString,
        openMenu,
        closeMenu,
        reset,
    } = useCombobox<Node>({
        items: searchResults,
        onSelectedItemChange: handleSelectedItemChange,
        itemToString: itemToString,
        defaultHighlightedIndex: 0,
    });

    // Execute local searching
    useEffect(() => {
        if (searchContext == 'map') {
            if (searchString) {
                console.debug('Local searching for ' + searchString);
                const data = unfilteredGraphData || graphData;
                const replacedSearchString = searchQueryReplacements(searchString.toLowerCase());
                const resultSet = data.nodes.filter((node: Node) => {
                    if (isNodeHidden(node, mapState.visible)) {
                        return false;
                    }
                    if (node.label === 'identity') {
                        return false;
                    }
                    const name = getDisplayName(node).toLowerCase() || '';
                    const label = node.label?.toLowerCase() || '';
                    return name.includes(replacedSearchString) || label.includes(replacedSearchString);
                });

                setSearchResults(resultSet);

                if (resultSet.length === 0 && searchContext === 'map') {
                    setSearchContext('global');
                }
            }
        }
    }, [graphData, mapState.visible, searchContext, searchString, unfilteredGraphData]);

    // Execute global searching
    useEffect(() => {
        const executeSearch = async () => {
            if (searchContext == 'global') {
                if (searchString) {
                    console.debug('Global searching for ' + searchString);
                    const now = new Date();
                    const nowMinus30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
                    const [actorResults, targetResults] = await Promise.all([
                        searchAttributesActor({
                            variables: {
                                tenantId: tenantId,
                                startDate: +nowMinus30Days,
                                endDate: +now,
                                entity: 'STATS_ENTITY_TYPE_ACTOR',
                                sortField: 'displayName',
                                search: searchString,
                            },
                        }),
                        searchAttributesTarget({
                            variables: {
                                tenantId: tenantId,
                                startDate: +nowMinus30Days,
                                endDate: +now,
                                entity: 'STATS_ENTITY_TYPE_TARGET',
                                sortField: 'displayName',
                                search: searchString,
                            },
                        }),
                    ]);
                    if (actorResults && targetResults) {
                        let actorResultSet = [];
                        let targetResultSet = [];
                        if (actorResults.data && actorResults.data.searchAttributes.items) {
                            actorResultSet = actorResults.data.searchAttributes.items.map(
                                (item: SearchAttributeItem) => {
                                    return {
                                        id: item.id,
                                        name: item.displayName,
                                        label: 'actor',
                                        props: {
                                            displayName: item.displayName,
                                            alternateId: item.email,
                                        },
                                        attributes: [],
                                        tags: [],
                                        x: 0,
                                        y: 0,
                                        links: [],
                                        neighbors: [],
                                    } as Node;
                                },
                            );
                        }
                        if (targetResults.data && targetResults.data.searchAttributes.items) {
                            targetResultSet = targetResults.data.searchAttributes.items.map(
                                (item: SearchAttributeItem) => {
                                    return {
                                        id: item.id,
                                        name: item.displayName,
                                        label: 'target',
                                        props: {
                                            displayName: item.displayName,
                                            alternateId: item.email,
                                        },
                                        attributes: [],
                                        tags: [],
                                        x: 0,
                                        y: 0,
                                        links: [],
                                        neighbors: [],
                                    } as Node;
                                },
                            );
                        }

                        setSearchResults([...actorResultSet, ...targetResultSet]);
                    } else {
                        setSearchResults([]);
                    }
                } else {
                    setSearchResults([]);
                }
            }
        };
        executeSearch();
    }, [searchAttributesActor, searchAttributesTarget, searchContext, searchString, tenantId]);

    const clearAndCloseMenu = (): void => {
        setInputValue('');
        closeMenu();
    };

    const renderItems = () => {
        const itemsToRender = searchResults.slice(0, maxItemsToRender);

        return itemsToRender.map((node: Node, index: number) => (
            <li
                className="cursor-default select-none rounded-lg px-3 py-2 text-left space-x-4 flex items-center justify-between"
                style={highlightedIndex === index ? { backgroundColor: '#374151' } : {}}
                key={`${node.id}${index}`}
                {...getItemProps({ item: node, index })}
            >
                <div className="flex items-center space-x-4">
                    <>
                        {node.label == 'actor' ? (
                            <div className="h-8 w-8 flex-shrink-0 flex-grow-0">
                                <Avatar size="100%" name={getDisplayName(node)} round={true} maxInitials={2} />
                            </div>
                        ) : (
                            <img
                                src={getIconSourceURL(getNodeIconElement(node))}
                                className="h-8 w-8 flex-shrink-0 flex-grow-0"
                                alt=""
                            />
                        )}
                    </>

                    <div className="flex-1">
                        <p className="text-gray-200 text-xs break-words">{getDisplayName(node)}</p>
                        <p className="text-xs text-gray-400 font-light my-auto capitalize">
                            {node.label}
                            {node.label == 'target' && node.nodeType && node.nodeType != 'NODE_TYPE_UNKNOWN' && (
                                <span> • {targetNodeTypeLookup(node.nodeType)}</span>
                            )}
                        </p>
                    </div>
                </div>
            </li>
        ));
    };

    const inputRef = React.useRef<HTMLInputElement>(null);
    useHotkeys('Control+p', () => inputRef.current?.focus(), {});

    if (seanMode) {
        return (
            <>
                <ResultsChat onClose={() => setSeanMode(false)} />
                {/* These divs are required for downshift to work correctly */}
                <div {...getComboboxProps()} />
                <div {...getInputProps()} />
                <div {...getMenuProps()} />
            </>
        );
    }

    return (
        <div
            id="CommandPalette"
            className="mx-auto w-full flex-shrink-0 flex-grow-0 transform overflow-hidden rounded-lg bg-gray-800 shadow-2xl transition-all z-10 pointer-events-auto"
        >
            <div {...getComboboxProps()} className="relative w-full">
                <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-2.5 left-4 h-5 w-5 text-gray-500"
                    aria-hidden="true"
                />
                <input
                    type="search"
                    name="search"
                    data-test="command-palette-input"
                    placeholder={searchContext == 'map' ? 'Select from the map...' : 'Select from the database...'}
                    className={classNames(
                        'h-10 w-full border-0 bg-transparent pl-11 pr-4 text-white placeholder-gray-500 focus:ring-0 text-sm',
                        mapHasNodes ? 'global-search' : '',
                    )}
                    {...getInputProps({
                        ref: inputRef,
                        onKeyDown: (event) => {
                            if (event.key === 'Escape') {
                                if (searchString.length == 0) {
                                    inputRef.current?.blur();
                                }
                            }
                        },
                        onFocus: () => {
                            if (searchContext == 'map' && !mapHasNodes) {
                                setSearchContext('global');
                            }

                            openMenu();

                            runOnTutorialStep('Command Palette Open', () => {
                                moveToNextTutorialStep();
                            });
                        },
                        onBlur: () => {
                            runOnTutorialStep(
                                [
                                    'Command Palette Refinements',
                                    'Command Palette Node Counts',
                                    'Command Palette Filter',
                                ],
                                () => {
                                    // We re-open the command palette that tries to close automatically on tutorial steps
                                    inputRef.current?.focus();
                                },
                            );
                        },
                    })}
                />
                <div className="flex rounded-full items-stretch justify-center bg-gray-700 absolute right-2 top-2">
                    {mapHasNodes && (
                        <div
                            className={classNames(
                                'w-16 bg-blue-700 absolute left-0 top-0 text-xs p-1 rounded-full transition-all',
                                searchContext == 'map'
                                    ? 'translate-x-0 '
                                    : searchContext == 'global'
                                      ? 'translate-x-full'
                                      : '',
                            )}
                        >
                            &nbsp;
                        </div>
                    )}
                    {mapHasNodes && (
                        <>
                            <p
                                data-test="command-palette-map-search"
                                onClick={() => {
                                    if (mapHasNodes) {
                                        setSearchContext('map');
                                    }
                                }}
                                className={classNames(
                                    'w-16 text-xs flex justify-center p-1 px-2 rounded-l-full transition-all cursor-pointer z-10',
                                    searchContext == 'map'
                                        ? 'text-gray-100 font-medium hover:text-white'
                                        : 'text-gray-400 hover:text-blue-600',
                                )}
                            >
                                Map
                            </p>
                            <p
                                data-test="command-palette-global-search"
                                onClick={() => setSearchContext('global')}
                                className={classNames(
                                    'w-16 text-xs flex justify-center p-1 px-2 rounded-r-full transition-all cursor-pointer z-10',
                                    searchContext == 'global'
                                        ? 'text-gray-100 font-medium hover:text-white'
                                        : 'text-gray-400 hover:text-blue-600',
                                )}
                            >
                                Global
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className="divide-y divide-gray-500 divide-opacity-20" {...getMenuProps({})}>
                {isOpen && (
                    <>
                        {searchContext == 'map' && <NodeCounts clearAndCloseMenu={clearAndCloseMenu} />}

                        {searchContext == 'map' && searchString.length == 0 && searchResults.length == 0 && (
                            <RefinementFilters clearAndCloseMenu={clearAndCloseMenu} />
                        )}

                        {searchResults.length > 0 && (
                            <ul className="max-h-96 scroll-py-3 overflow-y-auto p-3">{renderItems()}</ul>
                        )}

                        {searchContext == 'global' && (
                            <div className="p-2 text-xs text-gray-400 flex place-content-center items-center">
                                {!debouncedLoading && error && (
                                    <span className="text-red-400" data-test="cp-error">
                                        There was an issue retrieving your results
                                    </span>
                                )}

                                {debouncedLoading && searchString.length > 2 && (
                                    <span className="loader h-4 w-4" data-test="cp-loading"></span>
                                )}

                                {!debouncedLoading &&
                                    !error &&
                                    searchResults.length == 0 &&
                                    searchString.length <= 2 && (
                                        <div data-test="cp-keep-typing" className="flex flex-col space-y-2 text-center">
                                            <div>Keep typing to begin search</div>
                                            {enableSean && (
                                                <div className="flex flex-col space-y-2">
                                                    <span className="italic text-gray-600">or</span>
                                                    <span>
                                                        <button
                                                            className="hover:text-underline hover:text-blue-600 flex items-center"
                                                            onClick={() => setSeanMode(true)}
                                                        >
                                                            Start a conversation with Sean
                                                            <ChatBubbleLeftRightIcon className="h-4 w-4 ml-1" />
                                                        </button>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                {!debouncedLoading &&
                                    !error &&
                                    searchResults.length == 0 &&
                                    searchString.length > 2 && <span data-test="cp-no-results">No results found</span>}

                                {!debouncedLoading && searchResults.length > maxItemsToRender && (
                                    <span>Showing first {maxItemsToRender} results</span>
                                )}

                                {!debouncedLoading &&
                                    searchResults.length > 0 &&
                                    searchResults.length <= maxItemsToRender && (
                                        <span data-test="cp-info">{`Search returned ${searchResults.length} result${
                                            searchResults.length == 1 ? '' : 's'
                                        }`}</span>
                                    )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const NodeCounts = ({ clearAndCloseMenu }: { clearAndCloseMenu: () => void }): JSX.Element => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { isNodeInExplorer, isNodeInQuery } = useGraphControls();
    const { dispatch: toastDispatch } = useContext(ToastContext);
    useMemo(() => {
        numTargets = 0;
        numIdentities = 0;
        numDevices = 0;
        numActors = 0;
        numApplications = 0;

        if (mapState.selectedNodes.size === 0) {
            return;
        }

        mapState.graphData.nodes.map((node) => {
            if (isNodeHidden(node, mapState.visible)) {
                return;
            }
            if (node.label == 'target') {
                numTargets++;
            } else if (node.label == 'identity') {
                numIdentities++;
            } else if (node.label == 'device') {
                numDevices++;
            } else if (node.label == 'actor') {
                numActors++;
            } else if (node.label == 'application') {
                numApplications++;
            }
        });
    }, [mapState.graphData.nodes, mapState.selectedNodes.size, mapState.visible]);

    const selectNodesByLabel = (label: string) => {
        // Select and sort eligible nodes to add to the explorer
        let newSelectedNodes = mapState.graphData.nodes
            .filter((node) => {
                if (node.label == label && !isNodeHidden(node, mapState.visible) && !isNodeInExplorer(node)) {
                    return node;
                }
            })
            .sort(compareNodesByDisplayName);

        // Set a limit to only add a maximum of 100 nodes to the explorer through this method
        if (newSelectedNodes.length > 100) {
            console.log('Limiting the number of nodes to add to the explorer to 100');
            newSelectedNodes = newSelectedNodes.slice(0, 100);
            toastDispatch({
                type: 'add-toast',
                message: `Only the first 100 nodes were added to the explorer.`,
                status: 'information',
                autoTimeout: true,
                timeoutTimer: 15,
            });
        }

        // Add nodes to the explorer
        if (newSelectedNodes) {
            dispatch({ type: 'set-selected-nodes', nodes: new Set([...mapState.selectedNodes, ...newSelectedNodes]) });
            dispatch({ type: 'set-scroll-to-node', node: newSelectedNodes.at(-1) });
        }

        // Add nodes to the query if shift is pressed
        if (isHotkeyPressed('shift')) {
            const newQueriedNodes = [...newSelectedNodes].filter((node) => {
                if (!isNodeInQuery(node)) {
                    return node;
                }
            });
            dispatch({ type: 'set-queried-nodes', nodes: new Set([...mapState.queriedNodes, ...newQueriedNodes]) });
        }

        // Close the menu command palette and clear the search input
        clearAndCloseMenu();
    };

    return (
        <ul id="NodeCounts" className="flex p-2">
            <Tooltip label="Select all actors">
                <li
                    className="text-xs flex flex-col flex-1 justify-center cursor-pointer select-none rounded-lg px-1.5 py-2 text-center items-center hover:bg-gray-700"
                    onClick={() => {
                        selectNodesByLabel('actor');
                    }}
                >
                    <div className="relative -mb-3 -top-1 h-12 w-12 rounded-full border border-gray-700 bg-gray-800 px-1.5 p-2 flex items-center justify-center">
                        <img src={User} alt="" />
                    </div>
                    <div className="badge relative min-w-4 mb-1">{numActors}</div>
                    Actors
                </li>
            </Tooltip>
            <Tooltip label="Select all devices">
                <li
                    className="text-xs flex flex-col flex-1 justify-center cursor-pointer select-none rounded-lg px-1.5 py-2 text-center items-center hover:bg-gray-700"
                    onClick={() => {
                        selectNodesByLabel('device');
                    }}
                >
                    <div className="relative -mb-3 -top-1 h-12 w-12 rounded-full border border-gray-700 bg-gray-800 px-1.5 py-2 flex items-center justify-center">
                        <img src={Desktop} alt="" />
                    </div>
                    <div className="badge relative mb-1">{numDevices}</div>
                    Devices
                </li>
            </Tooltip>
            <Tooltip label="Select all applications">
                <li
                    className="text-xs flex flex-col flex-1 justify-center cursor-pointer select-none rounded-lg px-1.5 -3 py-2 text-center items-center hover:bg-gray-700"
                    onClick={() => {
                        selectNodesByLabel('application');
                    }}
                >
                    <div className="relative -mb-3 -top-1 h-12 w-12 rounded-full border border-gray-700 bg-gray-800 px-1.5 py-2 flex items-center justify-center">
                        <img src={Application} alt="" />
                    </div>
                    <div className="badge relative mb-1">{numApplications}</div>
                    Applications
                </li>
            </Tooltip>
            <Tooltip label="Select all identities">
                <li
                    className="text-xs flex flex-col flex-1 justify-center cursor-pointer select-none rounded-lg px-1.5 py-2 text-center items-center hover:bg-gray-700"
                    onClick={() => {
                        selectNodesByLabel('identity');
                    }}
                >
                    <div className="relative -mb-3 -top-1  h-12 w-12 rounded-full border border-gray-700 bg-gray-800 p-2 flex items-center justify-center">
                        <img src={DoubleZero} alt="" />
                    </div>
                    <div className="badge relative mb-1">{numIdentities}</div>
                    Identities
                </li>
            </Tooltip>
            <Tooltip label="Select all targets">
                <li
                    className="text-xs flex flex-col flex-1 justify-center cursor-pointer select-none rounded-lg px-3 py-2 text-center items-center hover:bg-gray-700"
                    onClick={() => {
                        selectNodesByLabel('target');
                    }}
                >
                    <div className="relative -mb-3 h-12 w-12 rounded-full border border-gray-700 bg-gray-800 p-2 flex items-center justify-center">
                        <img src={Target} alt="" />
                    </div>
                    <div className="badge relative mb-1">{numTargets}</div>
                    Targets
                </li>
            </Tooltip>
        </ul>
    );
};

const RefinementFilters = ({ clearAndCloseMenu }: { clearAndCloseMenu: () => void }): JSX.Element => {
    const { dispatch } = useContext(IdentityMapContext);
    const { runOnTutorialStep, moveToNextTutorialStep } = useProductTutorial();
    const { zoomGraph } = useGraphControls();

    const handleClick = (filter: GraphFilter[]): void => {
        dispatch({ type: 'apply-graph-filter', filters: filter });
        zoomGraph({ delay: 500, reheat: true });
        clearAndCloseMenu();
    };

    return (
        <ul id="RefinementFilters" className="p-3 space-y-2">
            <li className="text-left uppercase tracking-wider font-bold text-xs text-gray-400">
                Common refinement filters
            </li>

            <li
                className="text-sm hover:bg-gray-700 flex cursor-default select-none rounded-lg px-3 py-2 text-left space-x-4 items-center"
                onClick={() => {
                    const filter: GraphFilter[] = [
                        {
                            label: 'link',
                            property: 'critical',
                            operation: 'greater-than',
                            value: 1,
                        },
                    ];

                    handleClick(filter);
                }}
            >
                <FunnelIcon className="h-4 w-4 mr-3" /> Show Accesses with policy violations
            </li>
            <li
                className="text-sm hover:bg-gray-700 flex cursor-default select-none rounded-lg px-3 py-2 text-left space-x-4 items-center"
                onClick={() => {
                    const filter: GraphFilter[] = [
                        {
                            label: 'target',
                            property: 'serviceDomain',
                            operation: 'contains',
                            value: '.',
                        },
                    ];
                    handleClick(filter);
                }}
            >
                <FunnelIcon className="h-4 w-4 mr-3" /> Show Targets with a known domain
            </li>
            <li
                id="DeviceFilter"
                className="text-sm hover:bg-gray-700 flex cursor-default select-none rounded-lg px-3 py-2 text-left space-x-4 items-center"
                onClick={() => {
                    const filter: GraphFilter[] = [
                        {
                            label: 'actor',
                            property: 'deviceCount',
                            operation: 'greater-than',
                            value: 2,
                        },
                    ];
                    handleClick(filter);

                    runOnTutorialStep('Command Palette Filter', () => {
                        moveToNextTutorialStep();
                    });
                }}
            >
                <FunnelIcon className="h-4 w-4 mr-3" /> Show Actors with 3 or more Devices
            </li>
            <li
                className="text-sm hover:bg-gray-700 flex cursor-default select-none rounded-lg px-3 py-2 text-left space-x-4 items-center"
                onClick={() => {
                    dispatch({ type: 'toggle-filter' });
                }}
            >
                <PlusIcon className="h-4 w-4 mr-3" />
                Add your own filter criteria..
            </li>
            <li
                className="text-sm hover:bg-gray-700 flex cursor-default select-none rounded-lg px-3 py-2 text-left space-x-4 items-center"
                onClick={() => {
                    const filter: GraphFilter[] = [];
                    handleClick(filter);
                }}
            >
                <XCircleIcon className="h-4 w-4 mr-3" />
                Clear all filter criteria
            </li>
        </ul>
    );
};
