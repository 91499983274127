import { gql } from '../GeneratedGQL/gql';

// Generic Queries

export const GET_ENTITIES_AS_NODES = gql(`#graphql
    query getEntitiesAsNodes($tenantId: ID!, $entityType: StatsEntityType!, $entityIds: [String!]) {
        getEntitiesAsNodes(tenantId: $tenantId, entityType: $entityType, entityIds: $entityIds) {
            nodeId
            nodeType
            label
            props
            tags
            attributes {
                updatedAt
                key
                value
            }
            groupingData
            currentLevelTrail
            hasNextLevel
            nextLevelBase64
        }
    }
`);

// Dashboard Queries

export const GET_INVENTORY_STATISTICS = gql(`#graphql
    query getInventoryStatistics($entity: StatsEntityType, $tenantId: ID!) {
        getInventoryStatistics(entity: $entity, tenantId: $tenantId) {
            items {
                count
                subType
                entityType
            }
        }
    }
`);

export const GET_GLOBAL_TRUST_STATISTICS = gql(`#graphql
    query getGlobalTrustStatistics($tenantId: ID!, $entity: StatsEntityType!) {
        getGlobalTrustStatistics(tenantId: $tenantId, entity: $entity) {
            globalTrustScore
            buckets {
                count
                name
                startTrustScore
                endTrustScore
            }
        }
    }
`);

export const GET_ENTITY_BY_TRUST = gql(`#graphql
    query getEntityByTrust($entity: StatsEntityType!, $tenantId: ID!, $limit: Int!, $order: StatsOrder!, $startDate: Int64!, $endDate: Int64!) {
        getEntityByTrust(entity: $entity, tenantId: $tenantId, limit: $limit, order: $order, startDate: $startDate, endDate: $endDate ) {
            items {
                entityId
                trustScore
                accessCount
            }
        }
    }
`);

// Profile Queries

export const GET_ENTITY_PROFILE = gql(`#graphql
    query getEntityProfile($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!, $startDate: Int64!, $endDate: Int64!) {
        getEntityProfile(entity: $entity, tenantId: $tenantId, entityId: $entityId, startDate: $startDate, endDate: $endDate) {
            firstAccessed
            lastAccessed
            providers
            mfaMethods
            tags
        }
}`);

export const GET_ENTITY_PERMISSION_STATS = gql(`#graphql
    query getEntityPermissionStats($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!) {
        getEntityPermissionStats(entity: $entity, tenantId: $tenantId, entityId: $entityId) {
            groupCount
            policyCount
            targetCount
        }
    }
`);

export const GET_ENTITY_SPIDER = gql(`#graphql
    query getEntitySpider($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!) {
        getEntitySpider(entity: $entity, tenantId: $tenantId, entityId: $entityId) {
            dimensions {
                actor
                sessions
                policies
                devices
                processes
                targets
                activities
            }
        }
    }
`);

// Policy Config Queries

export const LIST_POLICY_PROFILES = gql(`#graphql
    query listPolicyProfiles($tenantId: ID!, $filter: PolicyProfileQueryInput) {
        listPolicyProfiles(tenantId: $tenantId, filter: $filter) {
            profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
            deletedAt
        }
    }
`);

export const GET_POLICY_PROFILE = gql(`#graphql
    query getPolicyProfile($tenantId: ID!, $profileId: ID!) {
        getPolicyProfile(tenantId: $tenantId, policyProfileId: $profileId) {
            profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
        }
    }
`);

export const CREATE_POLICY_PROFILE = gql(`#graphql
    mutation createPolicyProfile($tenantId: ID!, $policyProfileInput: PolicyProfileInput!) {
        createPolicyProfile(tenantId: $tenantId, policyProfileInput: $policyProfileInput) {
            profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
        }
    }
`);

export const UPDATE_POLICY_PROFILE = gql(`#graphql
    mutation updatePolicyProfile($tenantId: ID!, $profileId: ID!, $policyProfileInput: PolicyProfileInput!) {
        updatePolicyProfile(tenantId: $tenantId, policyProfileId: $profileId, policyProfileInput: $policyProfileInput) {
            profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
        }
    }
`);

export const DELETE_POLICY_PROFILE = gql(`#graphql
    mutation deletePolicyProfile($tenantId: ID!, $profileId: ID!) {
        deletePolicyProfile(tenantId: $tenantId, policyProfileId: $profileId) 
    }
`);

export const ADD_ACTORS_TO_POLICY_PROFILE = gql(`#graphql
mutation addActorsToPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $actorIds: [ID!]!) {
    addActorsToPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, actorIds: $actorIds) {
     profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
                updatedAt
    }
}
`);

export const REMOVE_ACTORS_FROM_POLICY_PROFILE = gql(`#graphql
mutation removeActorsFromPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $actorIds: [ID!]!) {
    removeActorsFromPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, actorIds: $actorIds) {
     profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
    }
}
`);

export const ADD_TARGETS_TO_POLICY_PROFILE = gql(`#graphql
mutation addTargetsToPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $targets: [PolicyProfileTargetInput!]!) {
    addTargetsToPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, target: $targets) {
     profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
    }
}
`);

export const REMOVE_TARGETS_FROM_POLICY_PROFILE = gql(`#graphql
mutation removeTargetsFromPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $targetIds: [ID!]!) {
    removeTargetsFromPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, targetIds: $targetIds) {
     profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
    }
}
`);

export const ADD_POLICY_PROFILE_POLICY = gql(`#graphql
mutation addPolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policies: [PolicyProfilePolicyInput]!) {
    addPolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policies: $policies) {
     profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
    }
}
`);

export const REMOVE_POLICY_PROFILE_POLICY = gql(`#graphql
mutation removePolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policyIds: [ID!]!) {
    removePolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policyIds: $policyIds) {
    profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
    }
}
`);

export const UPDATE_POLICY_PROFILE_POLICY = gql(`#graphql
mutation updatePolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policy: PolicyProfilePolicyInput!) {
    updatePolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policy: $policy) {
    profileId
            displayName
            description
            policies {
                profileId
                policyId
                weight
            }
            targets {
                profileId
                targetId
                threshold
                action
                options
            }
            actorIds
            version
            originVersion
            externalReadOnly
            createdAt
            updatedAt
    }
}
`);

// Policy Documents

export const LIST_POLICY_DOCUMENTS = gql(`#graphql
query listPolicyDocuments($tenantId: ID!, $filter: PolicyDocumentQueryInput) {
    listPolicyDocuments(tenantId: $tenantId, filter: $filter) {
            policyDocumentId
            displayName
            description
            policyDocument
            status
            requestedState
            version
            originVersion
            signature
            createdAt
        }
    }
`);

export const GET_POLICY_DOCUMENT = gql(`#graphql
    query getPolicyDocument($tenantId: ID!, $policyId: ID!, $version: Int64) {
        getPolicyDocument(tenantId: $tenantId, policyId: $policyId, version: $version) {
            policyDocumentId
            displayName
            description
            policyDocument
            status
            requestedState
            version
            originVersion
            signature
            createdAt
        }
    }
`);

export const STAGE_POLICY_DOCUMENT = gql(`#graphql
    mutation stagePolicyDocument(
        $tenantId: ID!
        $policyDocument: String!
        $name: String
        $description: String
        $validateOnly: Boolean
    ) {
        stagePolicyDocument(
            tenantId: $tenantId
            policyDocument: $policyDocument
            name: $name
            description: $description
            validateOnly: $validateOnly
        ) {
            policyDocument {
                policyDocumentId
            }
            status
            errors {
                code
                message
            }
        }
    }
`);

export const PUBLISH_POLICY_DOCUMENT = gql(`#graphql
    mutation publishPolicyDocument(
        $tenantId: ID!
        $policyDocumentId: String!
        $action: PolicyAction
        $version: Int64
    ) {
        publishPolicyDocument(
            tenantId: $tenantId
            policyDocumentId: $policyDocumentId
            action: $action
            version: $version
        ) {
            policyDocumentId
            status
            errors {
                code
                message
            }
        }
    }
`);

export const DELETE_POLICY_DOCUMENT = gql(`#graphql
    mutation deletePolicyDocument($tenantId: ID!, $policyDocumentId: ID!, $version: Int64) {
        deletePolicyDocument(tenantId: $tenantId, policyDocumentId: $policyDocumentId, version: $version) 
    }
`);

export const UPDATE_POLICY_DOCUMENT = gql(`#graphql
    mutation updatePolicyDocument($tenantId: ID!, $policyUpdates: UpdatePolicyDocumentFieldsInput!) {
        updatePolicyDocument(tenantId: $tenantId, policyUpdates: $policyUpdates) {
            policyDocumentId
            displayName
            description
            policyDocument
            status
            requestedState
            version
            originVersion
            signature
            createdAt
        }
    }
`);

export const CREATE_WEBHOOK = gql(`#graphql
    mutation createWebhook($tenantId: ID!, $webhookInput: WebhookInput!) {
        createWebhook(tenantId: $tenantId, webhookInput: $webhookInput) {
            webhookId
            url
            method
            headers {
                key
                value
            }
        }
    }
`);

export const LIST_WEBHOOKS = gql(`#graphql
    query listWebhooks($tenantId: ID!) {
        listWebhooks(tenantId: $tenantId) {
            webhookId
            url
            method
            headers {
                key
                value
            }
        }
    }
`);

export const GET_WEBHOOK = gql(`#graphql
    query getWebhook($tenantId: ID!, $webhookId: ID!) {
        getWebhook(tenantId: $tenantId, webhookId: $webhookId) {
            webhookId
            url
            method
            headers {
                key
                value
            }
        }
    }
`);

export const DELETE_WEBHOOK = gql(`#graphql
    mutation deleteWebhook($tenantId: ID!, $webhookId: ID!) {
        deleteWebhook(tenantId: $tenantId, webhookId: $webhookId)
    }
`);

export const GET_TRUST_PROFILE_HISTORY = gql(`#graphql
    query getTrustProfileHistory($tenantId: ID!, $profileId: ID, $unit: StatsUnits!, $startDate: Int64!, $endDate: Int64!) {
        getTrustProfileHistory(tenantId: $tenantId, profileId: $profileId, unit: $unit, startDate: $startDate, endDate: $endDate) {
            items {
                date
                trustScore
            }
        }
    }
`);

export const GET_STORAGE_KEYS = gql(`#graphql
    query getStorageKeys($tenantId: ID!) {
        getStorageKeys(tenantId: $tenantId) {
            keys {
                key
                shared
                userId
            }
        }
    }
`);

export const GET_STORAGE_KEY = gql(`#graphql
    query getStorage($tenantId: ID!, $userId: String!, $key: String!, $shared: Boolean!) {
        getStorage(tenantId: $tenantId, userId: $userId, key: $key, shared: $shared) {
                id
                key
                data
                publishedAt
                updatedAt
                shared
            }
        }
`);

export const SET_STORAGE_KEY = gql(`#graphql
    mutation setStorage($tenantId: ID!, $key: String!, $data: String!, $shared: Boolean!) {
        setStorage(tenantId: $tenantId, key: $key, data: $data, shared: $shared) {
            id
            key
            data
            publishedAt
            updatedAt
            shared
        }
    }
`);

export const DELETE_STORAGE_KEY = gql(`#graphql
    mutation deleteStorage($tenantId: ID!, $key: String!, $shared: Boolean!) {
        deleteStorage(tenantId: $tenantId, key: $key, shared: $shared)
    }
`);

export const LIST_ALERTS = gql(`#graphql
    query listAlerts($tenantId: ID!, $input: ListAlertInput!) {
        listAlerts(tenantId: $tenantId, input: $input) {
            alerts {
                id
                raisedAt
                eventDate
                status
                muted
                observedCount
                definitionId
                alertDefinitionName
                triggeredNodeId
            }
            pageInfo {
                currentPage
                pageCount
            }
        }
    }
`);

export const GET_TOTAL_ALERT_COUNT = gql(`#graphql
    query getTotalAlertCount($tenantId: ID!) {
        getTotalAlertCount (tenantId: $tenantId) {
            count
        }
    }
`);
