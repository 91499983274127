import { UserList } from 'Configuration/Panels/Users';
import { WelcomeComponent, Header, NextButton } from './WelcomeComponents';

const CreateUsers = ({ prevStep, nextStep }: WelcomeComponent): JSX.Element => {
    return (
        <div>
            <Header title="Invite Users" previous={prevStep} />
            <div className="my-4 text-xs text-gray-300">
                <p>Invite any team mates that you wish to have access to SailPoint Identity Risk</p>
            </div>
            <div className="text-gray-300">
                <UserList />
            </div>
            <NextButton title="Next Step" next={nextStep} />
        </div>
    );
};

export default CreateUsers;
