import { useQuery } from '@apollo/client';
import { useTenant } from 'Hooks/Hooks';
import { useContext, useMemo, useState } from 'react';
import { classNames, getEntityType } from 'Utilities/utils';
import { AttributeProps } from '../ProfileTypes';
import { RuleCardProps } from 'Mocks/PolicyMock/Components/RuleCardMock';
import { Tooltip } from 'Library/Tooltip';
import { DocumentMagnifyingGlassIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { LockClosedIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import { LIST_POLICY_DOCUMENTS, LIST_POLICY_PROFILES } from 'Graph/typedQueries';
import { ListPolicyDocumentsQuery, ListPolicyProfilesQuery, PolicyAction, StatsUnits } from 'GeneratedGQL/graphql';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { GET_ENTITY_EVENT_HISTORY } from 'Graph/queries';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { policyActionLookup } from 'Policy/PolicyImplemented/Components/AdaptiveTrustPolicy';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { Node } from 'Types/types';
import { useUserPermissions } from 'Utilities/UserPermissions';

export type JoinedObjectProps = {
    modifiedKeys: Record<string, string | number>;
    unmodifiedKeys: Record<string, string | number>;
};

type TrustProfile = ListPolicyProfilesQuery['listPolicyProfiles'][number];

export const NodeTrustDisplay = ({
    node,
    startDate,
    endDate,
}: AttributeProps & { dynamicHeight?: boolean }): JSX.Element => {
    const { useCalculatedTrustScore } = useFlags();
    const tenantId = useTenant();

    const { userCan } = useUserPermissions();

    const canGetTrust = userCan('list', '/policy/profile');

    const { mapState, dispatch } = useContext(IdentityMapContext);

    const {
        loading,
        error,
        data: dataTrustProfiles,
    } = useQuery(LIST_POLICY_PROFILES, {
        variables: {
            tenantId: tenantId || '',
            filter: node.label === 'actor' ? { actorIds: [String(node.id)] } : { targetIds: [String(node.id)] },
        },
        skip: !tenantId || !canGetTrust,
    });

    const profiles = useMemo(() => {
        const profiles: TrustProfile[] = [];
        if (dataTrustProfiles?.listPolicyProfiles) {
            dataTrustProfiles.listPolicyProfiles.map((profile) => {
                profiles.push(profile);
            });
        }
        return profiles;
    }, [dataTrustProfiles]);

    // List Policy Documents
    const { data: policyDocumentsData } = useQuery(LIST_POLICY_DOCUMENTS, {
        variables: {
            tenantId: tenantId || '',
        },
        skip: !tenantId || !canGetTrust,
    });

    const policyDocumentsById = useMemo(() => {
        const pd: Record<string, ListPolicyDocumentsQuery['listPolicyDocuments'][number]> = {};

        policyDocumentsData?.listPolicyDocuments.map((policyDocument) => {
            pd[policyDocument?.policyDocumentId || ''] = policyDocument;
        });

        return pd;
    }, [policyDocumentsData]);

    // Trust Data
    const { data: trustData } = useQuery(GET_ENTITY_EVENT_HISTORY, {
        variables: {
            tenantId: tenantId,
            entityId: node?.id,
            entityType: getEntityType(node),
            startDate: startDate,
            endDate: endDate,
            unit: StatsUnits.StatsUnitsHour,
        },
        skip: !tenantId || !canGetTrust,
    });

    const { currentTrust, averageTrust, lowestTrust } = useMemo(() => {
        let currentTrust = 0;
        let averageTrust = 0;
        let lowestTrust = 0;

        if (!useCalculatedTrustScore && node.trustData) {
            currentTrust = Math.round(node.trustData.at(-1) || 0);
            averageTrust = Math.round(node.trustData.reduce((acc, curr) => acc + curr, 0) / node.trustData.length);
            lowestTrust = node.trustData.reduce((acc, curr) => Math.min(acc, curr), 100);
        }

        if (useCalculatedTrustScore && trustData?.getEntityEventHistory?.items) {
            const trustScores: number[] = [];

            trustData.getEntityEventHistory.items.map((item: { trustScore: number }) => {
                if (item.trustScore > -1) {
                    trustScores.push(item.trustScore);
                }
            });

            console.log(trustScores);

            if (trustScores.length === 0) {
                return {
                    currentTrust: undefined,
                    averageTrust: undefined,
                    lowestTrust: undefined,
                };
            }

            currentTrust = Math.round(trustScores[trustScores.length - 1]);
            averageTrust = Math.round(
                trustScores.reduce((acc: number, curr: number) => acc + curr, 0) / trustScores.length,
            );
            lowestTrust = Math.round(trustScores.reduce((acc: number, curr: number) => Math.min(acc, curr), 100));
        }

        return {
            currentTrust,
            averageTrust,
            lowestTrust,
        };
    }, [node.trustData, trustData, useCalculatedTrustScore]);

    if (!canGetTrust) {
        return (
            <div className="text-center text-gray-300 text-xs pt-2 flex flex-col items-center justify-center h-40">
                <DocumentMagnifyingGlassIcon className="h-8 w-8 text-gray-400 mb-3" />
                You do not have permission to view Adaptive Trust Policies
            </div>
        );
    }

    if (loading) {
        return (
            <div className="bg-gray-700 rounded-lg flex justify-center items-center text-xs h-16">
                <div className="h-4 w-4 loader" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-gray-700 rounded-l py-4 flex flex-col justify-center items-center text-xs">
                <NoSymbolIcon className="h-8 w-8 text-red-400 mb-2" />
                <span className="text-gray-400">Could not load Trust</span>
            </div>
        );
    }

    return (
        <div className="h-full flex flex-col">
            {currentTrust && (
                <div className="flex justify-around items-center bg-gray-700 rounded-lg mb-3 flex-none">
                    <ProfilePanelTabHeader
                        title={'Current Trust'}
                        count={currentTrust}
                        riskCount={0}
                        active={false}
                        onClick={() => {
                            console.log('noop');
                        }}
                    />
                    <ProfilePanelTabHeader
                        title={'Average Trust'}
                        count={averageTrust}
                        riskCount={0}
                        active={false}
                        onClick={() => {
                            console.log('noop');
                        }}
                    />
                    <ProfilePanelTabHeader
                        title={'Lowest Trust'}
                        count={lowestTrust}
                        riskCount={0}
                        active={false}
                        onClick={() => {
                            console.log('noop');
                        }}
                    />
                </div>
            )}

            <div className="bg-gray-700 rounded-lg pl-4 py-2 flex flex-col flex-grow space-y-2">
                {profiles.length == 0 && (
                    <div className="flex flex-col items-center justify-center h-full p-4 space-y-4">
                        <span className="font-medium text-gray-500 capitalize">No Trust Profiles</span>
                        <button
                            className="btn rounded-md bg-gray-600"
                            onClick={() => {
                                if (!mapState.policyOpen) {
                                    dispatch({ type: 'toggle-policy' });
                                }
                            }}
                        >
                            Configure a Trust Profile
                        </button>
                    </div>
                )}
                {node.label === 'actor' && profiles.length > 0 && Object.keys(policyDocumentsById).length > 0 && (
                    <div className="mb-3 space-y-6">
                        {profiles.map((profile) => {
                            return (
                                <div key={profile.profileId}>
                                    <Tooltip
                                        label={`This Actor matches the '${profile.displayName}' trust profile`}
                                        placement="right"
                                    >
                                        <div className="pt-1 pb-2">
                                            <div className="text-md tracking-widest uppercase text-gray-400 font-bold">
                                                {profile.displayName}
                                            </div>
                                            <div className="text-xxs text-gray-600 uppercase tracking-widest font-semibold">
                                                {profile.description}
                                            </div>
                                        </div>
                                    </Tooltip>
                                    <div>
                                        {profile.policies.map((policy) => {
                                            const document = policyDocumentsById[policy?.policyId || ''];
                                            return (
                                                <RuleCardSmall
                                                    key={document?.policyDocumentId || ''}
                                                    name={document?.displayName || ''}
                                                    description={document?.description || ''}
                                                    author="SailPoint"
                                                    riskLevel={2}
                                                    icon={<LockClosedIcon />}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
                {node.label === 'target' && profiles.length > 0 && (
                    <div className="my-3">
                        <div className="text-md tracking-widest uppercase text-gray-300 font-bold mb-2">
                            Adaptive Trust Policies
                        </div>
                        <div className="space-y-4">
                            {profiles.map((profile) => {
                                return (
                                    <div key={profile.profileId}>
                                        <div className="text-md tracking-widest uppercase text-gray-400 font-bold mb-1">
                                            {profile.displayName}
                                        </div>
                                        {profile.targets.map((target) => {
                                            if (target && target.targetId === node.id) {
                                                return (
                                                    <div key={target.targetId} className="flex items-center space-x-2">
                                                        <AdaptiveTrustPolicyThreshold
                                                            threshold={target.threshold}
                                                            action={target.action}
                                                            node={node}
                                                        />
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const AdaptiveTrustPolicyThreshold = ({
    threshold,
    action,
    node,
}: {
    threshold: number;
    action: PolicyAction;
    node: Node;
}): JSX.Element => {
    const { dispatch } = useContext(IdentityMapContext);

    const pieColor = 'rgb(12 92 151)';
    const pieSlices = [
        { value: threshold, color: pieColor },
        { value: 100 - (threshold || 0), color: 'rgb(31 41 55)' },
    ];

    return (
        <div className="flex items-center space-x-4">
            <div className="flex -ml-1">
                <PieChart width={60} height={60}>
                    <Pie
                        data={pieSlices}
                        dataKey="value"
                        innerRadius={'75%'}
                        outerRadius={'100%'}
                        paddingAngle={0}
                        stroke="none"
                        isAnimationActive={false}
                    >
                        {pieSlices.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                        <Label
                            value={pieSlices[0].value}
                            position="center"
                            fontSize={16}
                            fontWeight="bold"
                            fill="white"
                        />
                    </Pie>
                </PieChart>
            </div>
            <div className="text-md  tracking-wide">
                <span className="text-gray-300 bg-gray-800 px-2 py-1 ">Action</span>
                <span className="text-gray-200 bg-gray-600 px-4 py-1">{policyActionLookup[action]}</span>
            </div>
            <div>
                <button
                    className="btn bg-gray-600 rounded-sm py-1"
                    onClick={() => {
                        dispatch({ type: 'set-selected-policy-target', target: node });
                    }}
                >
                    View Details
                </button>
            </div>
        </div>
    );
};

export const RuleCardMock = ({ name, icon, description }: RuleCardProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    return (
        <div key={name} className="py-1 relative">
            <div className="ml-6 pl-6 bg-gray-700 rounded-md" onClick={() => setOpen(!open)}>
                <Tooltip label="This factor is having a critical impact on the trust score." placement="left">
                    <div
                        className={classNames(
                            name == 'Connecting from Non-Directory Location' ? 'border-yellow-500' : 'border-blue-700',
                            'bg-gray-600 border-2 transition rounded-full shadow-lg absolute left-0 top-1 h-10 w-10 text-white flex items-center justify-center',
                        )}
                    >
                        <div className="h-4 w-4">{icon}</div>
                    </div>
                </Tooltip>
                <div className="px-2 py-1 relative flex justify-between items-center hover:bg-gray-600 rounded-md">
                    <div>
                        <h4 className="text-md font-semibold mb-1 text-white mr-6">{name}</h4>
                        <p className="text-white text-xs">{description}</p>
                    </div>
                </div>
            </div>

            {open && (
                <div className="pl-16 pt-2 text-gray-300 space-y-4">
                    <div className="flex items-center">
                        <ExclamationTriangleIcon className="h-4 w-4 mr-2 text-yellow-500" />
                        <p>This factor is having a critical impact on the trust score.</p>
                    </div>
                    <div className="space-y-1">
                        <p className="font-semibold">Policy Outcome</p>
                        <p className="text-gray-400">Click to view the full policy</p>
                        <p className="bg-gray-900 text-gray-300 p-2 border-gray-500 border rounded-md">
                            Current Location does not match directory or travel location.
                        </p>
                    </div>
                    <div className="space-y-2">
                        <p className="font-semibold">Policy Context</p>
                        <div className="rounded-md overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-700 ">
                                <thead>
                                    <tr className="bg-gray-800 text-gray-300">
                                        <th className="px-2 py-2 text-left text-xxs font-medium uppercase tracking-wider">
                                            Context
                                        </th>
                                        <th className="px-2 py-2 text-left text-xxs font-medium uppercase tracking-wider">
                                            Value
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-gray-900 divide-y divide-gray-700">
                                    <tr>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">Current Location</td>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">China, Beijing</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">
                                            Directory Location
                                        </td>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">USA, Charlotte </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">Travel Location</td>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const ProfilePanelTabHeader = ({
    title,
    onClick,
    active,
    count,
}: {
    title: string;
    onClick: () => void;
    active: boolean;
    count: number;
    riskCount: number;
}) => {
    // Break down the count to
    // 0 - 10 = red
    // 11 - 30 = orange
    // 31 - 50 = yellow
    // 51- 75 = blue
    // 76 - 100 = green
    let color = 'bg-gray-500';
    if (count < 11) {
        color = 'bg-red-500';
    } else if (count < 31) {
        color = 'bg-orange-500';
    } else if (count < 51) {
        color = 'bg-yellow-500';
    } else if (count < 76) {
        color = 'bg-blue-500';
    } else if (count < 101) {
        color = 'bg-green-500';
    }

    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(
                active ? 'border-white' : '',
                'flex-1 flex flex-col items-center justify-center px-3 py-1.5 transition-all border-2 border-transparent outline-none rounded-lg',
            )}
        >
            <h1 className="text-gray-400">{title}</h1>
            <div className="flex items-center justify-center relative">
                <h1 className="text-lg font-bold">{count}</h1>
                <div className="text-xs text-green-400 flex items-center absolute -right-8">
                    <span className={classNames('inline-block w-3 h-3 mr-2 rounded-full', color)} />
                </div>
            </div>
        </button>
    );
};

export const RuleCardSmall = ({ name, icon, description }: RuleCardProps): JSX.Element => {
    const { enableNewPolicyModal } = useFlags();

    const [open] = useState(false);

    const displayName = useMemo(() => {
        if (enableNewPolicyModal) {
            return name.split(' -- ')[1];
        } else {
            return name;
        }
    }, [name, enableNewPolicyModal]);

    return (
        <div key={name} className="py-1 relative">
            <div className="ml-6 pl-6 bg-gray-700 rounded-md">
                <Tooltip label={name} placement="left">
                    <div
                        className={classNames(
                            name == 'Connecting from Non-Directory Location' ? 'border-yellow-500' : 'border-blue-700',
                            'bg-gray-600 border-2 transition rounded-full shadow-lg absolute left-0 top-1 h-10 w-10 text-white flex items-center justify-center',
                        )}
                    >
                        <div className="h-4 w-4">{icon}</div>
                    </div>
                </Tooltip>
                <div className="px-2 py-1 relative flex justify-between items-center hover:bg-gray-600 rounded-md">
                    <div>
                        <h4 className="text-md font-semibold mb-1 text-white mr-6">{displayName}</h4>
                        <p className="text-white text-xs">{description}</p>
                    </div>
                </div>
            </div>

            {open && (
                <div className="pl-14 pr-4 pt-2 text-gray-300 space-y-4">
                    <div className="space-y-1">
                        <p className="font-semibold">Policy Specification</p>
                        <p className="bg-gray-800 text-gray-300 p-2 border-gray-500 border"></p>
                    </div>
                </div>
            )}
        </div>
    );
};
