import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { EventsTableBase } from './Library/EventsTableBase';
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { classNames, getDisplayName, getEntityType, operatingSystemNameLookup } from 'Utilities/utils';
import { Node } from 'Types/types';
import { useQuery } from '@apollo/client';
import {
    GET_ENTITY_EVENTS_SUMMARY,
    GET_ENTITY_EVENTS_SUMMARY_CODES,
    GET_ENTITY_EVENTS_SUMMARY_CODES_LIST,
} from 'Graph/queries';
import { endOfDay } from 'date-fns';
import { useNodes, useTenant } from 'Hooks/Hooks';
import { Tooltip } from 'Library/Tooltip';
import { GreaterThanColumnFilter, SelectOutcomeFilter } from 'Library/TableComponents';

export type GroupedSummaryFields = {
    summaryId?: string;
    count: number;
    node: Node | string | undefined;
    location: {
        city?: string;
        state?: string;
        lat: number;
        long: number;
        latlong?: string[];
    };
    outcome: string;
    mfa: string;
    risk: string;
    eventUrl?: URL;
};

type GroupedCodeFields = {
    count: number;
    date: number;
    reason: string;
    risk: string;
    codeId?: string;
};

type EventListFields = {
    date: number;
    device: string;
    application: string;
    provider: string;
    raw: string;
    accesses: number;
};

type ProfileTableProps = {
    node: Node;
    rowClickHandler: (row: unknown) => void;
    startDate?: number;
    endDate?: number;
};

const getTargetType = (node: Node): string => {
    if (!node) {
        return '';
    }
    switch (node.label) {
        case 'actor':
            return 'STATS_ENTITY_TYPE_TARGET';
        case 'target':
            return 'STATS_ENTITY_TYPE_ACTOR';
    }
    return 'STATS_ENTITY_TYPE_UNKNOWN';
};

const parseStatusToOutcome = (status: string): string => {
    switch (status) {
        case 'OUTCOME_ALLOW':
            return 'OUTCOME_SUCCESS';
        case 'OUTCOME_SKIPPED':
            return 'OUTCOME_CHALLENGE';
        case 'OUTCOME_DENY':
            return 'OUTCOME_FAILURE';
        default:
            return status;
    }
};

function GroupedSummaryTable({ node, rowClickHandler, startDate, endDate }: ProfileTableProps) {
    const [summaryList, setSummaryList] = useState([] as GroupedSummaryFields[]);
    const [resolvedSummaryList, setResolvedSummaryList] = useState([] as GroupedSummaryFields[]);
    const [nodeIds, setNodeIds] = useState<string[]>([]);

    const tenantId = useTenant();

    const {
        loading: nodesLoading,
        error: nodeLoadingError,
        nodes,
    } = useNodes(nodeIds, node.label === 'actor' ? 'STATS_ENTITY_TYPE_TARGET' : 'STATS_ENTITY_TYPE_ACTOR');

    useEffect(() => {
        if (Object.keys(nodes).length > 0) {
            summaryList.map((summary) => {
                if (typeof summary.node === 'string') {
                    const node = nodes[summary.node];
                    if (node) {
                        summary.node = node;
                    } else {
                        summary.node = undefined;
                        console.log('Node not found for id: ' + summary.node + ' in nodes: ', nodes);
                    }
                }
            });
            setResolvedSummaryList(summaryList);
        }
    }, [nodes, summaryList]);

    const { loading, error, data } = useQuery(GET_ENTITY_EVENTS_SUMMARY, {
        variables: {
            tenantId,
            entityId: node?.id,
            entityType: getEntityType(node),
            targetType: getTargetType(node),
            startDate: startDate,
            endDate: endDate,
        },
        skip: !node,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (data && summaryList.length === 0) {
            const rows: GroupedSummaryFields[] = [];
            if (data.getEntityEventsSummary) {
                const { items } = data.getEntityEventsSummary;
                if (items) {
                    const nodeIds: string[] = [];
                    items.map((item: any) => {
                        if (!item.entityTargetId) {
                            return;
                        }
                        const row = {
                            summaryId: item.summaryId,
                            count: item.count,
                            node: item.entityTargetId,
                            outcome: parseStatusToOutcome(item.status),
                            mfa: item.mfa,
                            risk: item.risk || 'normal',
                            location: {
                                lat: 1,
                                long: 1,
                                latlong: ['1, 1', 'Test, Location'],
                            },
                        };
                        rows.push(row);
                        nodeIds.push(item.entityTargetId);
                    });
                    setSummaryList(rows);
                    setNodeIds(nodeIds);
                }
            }
        }
    }, [data, summaryList.length]);

    useEffect(() => {
        setNodeIds([]);
        setSummaryList([]);
        setResolvedSummaryList([]);
    }, [node, startDate, endDate]);

    const columns: Column<GroupedSummaryFields>[] = useMemo(
        () =>
            [
                {
                    Header: 'Count',
                    accessor: 'count' as keyof Column<GroupedSummaryFields>,
                    sortType: 'greaterThan',
                    Filter: GreaterThanColumnFilter,
                    filter: 'greaterThan',
                },
                {
                    Header: node.label === 'target' ? 'Actor' : 'Target',
                    accessor: 'node' as keyof Column<GroupedSummaryFields>,
                    filter: 'fuzzyNodeDisplayName',
                    sortType: 'nodeDisplayName',
                },
                {
                    Header: 'Outcome',
                    accessor: 'outcome' as keyof Column<GroupedSummaryFields>,
                    Filter: SelectOutcomeFilter,
                    filter: 'includes',
                },
                {
                    Header: 'Risk',
                    accessor: 'risk' as keyof Column<GroupedSummaryFields>,
                    filter: 'fuzzyText',
                },
            ] as unknown as Column<GroupedSummaryFields>[],
        [node.label],
    );
    return (
        <>
            <EventsTableBase
                columns={columns}
                data={resolvedSummaryList}
                rowClickHandler={rowClickHandler}
                loading={loading || nodesLoading}
                error={Boolean(error) || Boolean(nodeLoadingError)}
            />
        </>
    );
}

function GroupedCodeTable({ node, rowClickHandler, summaryId }: ProfileTableProps & { summaryId: string }) {
    const [codeList, setCodeList] = useState<GroupedCodeFields[]>([]);

    const tenantId = useTenant();

    const today = +endOfDay(new Date());

    const { loading, error, data } = useQuery(GET_ENTITY_EVENTS_SUMMARY_CODES, {
        variables: {
            tenantId,
            entityId: node?.id,
            summaryId: summaryId,
            entityType: getEntityType(node),
            targetType: getTargetType(node),
            startDate: 0,
            endDate: today,
        },
        fetchPolicy: 'network-only',
        skip: !node || !summaryId,
    });

    useEffect(() => {
        if (data && codeList.length === 0) {
            const rows: GroupedCodeFields[] = [];
            if (data.getEntityEventsSummaryCodes) {
                const { items } = data.getEntityEventsSummaryCodes;
                if (items) {
                    items.map((item: any) => {
                        const row = {
                            count: item.count,
                            date: item.date,
                            risk: item.risk || 'normal',
                            reason: item.reason || 'No reason given by the identity provider',
                            codeId: item.codeId,
                        };
                        rows.push(row);
                    });
                    setCodeList(rows);
                }
            }
        }
    }, [codeList.length, data]);

    const columns: Column<GroupedCodeFields>[] = useMemo(
        () =>
            [
                {
                    Header: 'Count',
                    accessor: 'count' as keyof Column<GroupedCodeFields>,
                    sortType: 'greaterThan',
                    Filter: GreaterThanColumnFilter,
                    filter: 'greaterThan',
                },
                {
                    Header: 'Reason',
                    accessor: 'reason' as keyof Column<GroupedCodeFields>,
                    filter: 'fuzzyText',
                },
                {
                    Header: 'Risk',
                    accessor: 'risk' as keyof Column<GroupedCodeFields>,
                    filter: 'fuzzyText',
                },
            ] as unknown as Column<GroupedCodeFields>[],
        [],
    );

    return (
        <EventsTableBase
            columns={columns}
            data={codeList}
            rowClickHandler={rowClickHandler}
            loading={loading}
            error={Boolean(error)}
        />
    );
}

function IndividualEventTable({
    rowClickHandler,
    node,
    summaryId,
    codeId,
}: ProfileTableProps & { summaryId: string; codeId: string }) {
    const [eventsList, setEventsList] = useState<EventListFields[]>([]);
    const [resolvedEventsList, setResolvedEventsList] = useState<EventListFields[]>([]);
    const [deviceIds, setDeviceIds] = useState<string[]>([]);
    const [applicationIds, setApplicationIds] = useState<string[]>([]);

    const tenantId = useTenant();

    const today = +endOfDay(new Date());

    const {
        loading: devicesLoading,
        error: deviceLoadingError,
        nodes: devices,
    } = useNodes(deviceIds, 'STATS_ENTITY_TYPE_DEVICE');
    const {
        loading: applicationsLoading,
        error: applicationLoadingError,
        nodes: applications,
    } = useNodes(applicationIds, 'STATS_ENTITY_TYPE_APPLICATION');

    useEffect(() => {
        setDeviceIds([]);
        setApplicationIds([]);
        setEventsList([]);
        setResolvedEventsList([]);
    }, [node]);

    useEffect(() => {
        if (Object.keys(devices).length > 0 && Object.keys(applications).length > 0) {
            eventsList.map((event) => {
                const device = devices[event.device];
                const application = applications[event.application];
                if (device) {
                    if (device.props.deviceOperatingSystem) {
                        event.device = `${getDisplayName(device)} (${operatingSystemNameLookup(
                            device.props.deviceOperatingSystem,
                        )})`;
                    } else {
                        event.device = getDisplayName(device);
                    }
                }
                if (application) {
                    event.application = getDisplayName(application);
                }
            });
            setResolvedEventsList(eventsList);
        }
    }, [devices, applications, eventsList]);

    const { loading, error, data } = useQuery(GET_ENTITY_EVENTS_SUMMARY_CODES_LIST, {
        variables: {
            codeId: codeId,
            summaryId: summaryId,
            tenantId,
            entityId: node?.id,
            entityType: getEntityType(node),
            targetType: getTargetType(node),
            startDate: 0,
            endDate: today,
        },
        fetchPolicy: 'network-only',
        skip: !node || !summaryId,
    });

    useEffect(() => {
        if (data && eventsList.length === 0) {
            const rows: EventListFields[] = [];
            if (data.getEntityEventsSummaryCodesList) {
                const { items } = data.getEntityEventsSummaryCodesList;
                const deviceIds: string[] = [];
                const applicationIds: string[] = [];
                if (items) {
                    items.map((item: any) => {
                        const row = {
                            date: item.date,
                            device: item.deviceId,
                            application: item.applicationId,
                            provider: item.provider,
                            raw: item.value,
                            accesses: item.accesses,
                        };
                        rows.push(row);
                        deviceIds.push(item.deviceId);
                        applicationIds.push(item.applicationId);
                    });
                    setEventsList(rows);
                    setDeviceIds(deviceIds);
                    setApplicationIds(applicationIds);
                }
            }
        }
    }, [data, eventsList.length]);

    const columns: Column<EventListFields>[] = useMemo(
        () =>
            [
                {
                    Header: 'Time',
                    accessor: 'date' as keyof Column<EventListFields>,
                    filter: 'fuzzyDate',
                    sortType: 'greaterThan',
                },
                {
                    Header: 'Accesses',
                    accessor: 'accesses' as keyof Column<EventListFields>,
                    sortType: 'greaterThan',
                    Filter: GreaterThanColumnFilter,
                    filter: 'greaterThan',
                },
                {
                    Header: 'Device',
                    accessor: 'device' as keyof Column<EventListFields>,
                    filter: 'fuzzyText',
                },
                {
                    Header: 'Application',
                    accessor: 'application' as keyof Column<EventListFields>,
                    filter: 'fuzzyText',
                },
                {
                    Header: 'Provider',
                    accessor: 'provider' as keyof Column<EventListFields>,
                    filter: 'providerDisplayName',
                },
            ] as unknown as Column<EventListFields>[],
        [],
    );

    return (
        <EventsTableBase
            columns={columns}
            data={resolvedEventsList}
            rowClickHandler={rowClickHandler}
            loading={loading || devicesLoading || applicationsLoading}
            error={Boolean(error) || Boolean(deviceLoadingError) || Boolean(applicationLoadingError)}
        />
    );
}

type EventsTableProps = {
    node: Node;
    expanded: boolean;
    onClickExpandIcon: () => void;
    startDate: number;
    endDate: number;
};

export const EventsTable = ({ node, expanded, onClickExpandIcon, startDate, endDate }: EventsTableProps) => {
    const [tableNestLevel, setTableNestLevel] = useState<'grouped' | 'codes' | 'events'>('grouped');
    const [groupNestLevelNode, setGroupNestLevelNode] = useState<string | null>(null);
    const [codeNestLevelNode, setCodeNestLevelNode] = useState<string | null>(null);

    const [summaryId, setSummaryId] = useState<string | null>(null);
    const [codeId, setCodeId] = useState<string | null>(null);

    useEffect(() => {
        setTableNestLevel('grouped');
        setGroupNestLevelNode(null);
        setCodeNestLevelNode(null);
    }, [node]);

    return (
        <>
            <div className="flex items-center justify-between">
                <nav className="flex rounded-t-lg" aria-label="Breadcrumb">
                    <ol
                        role="list"
                        className="rounded-t-md 
                bg-gray-700 px-3 h-8 flex"
                    >
                        <li
                            onClick={() => setTableNestLevel('grouped')}
                            className="flex items-center space-x-2 text-gray-500 hover:text-gray-300 last:text-gray-100 text-xs font-medium cursor-pointer"
                        >
                            Access Events
                        </li>
                        {(tableNestLevel === 'codes' || tableNestLevel === 'events') && (
                            <li
                                onClick={() => setTableNestLevel('codes')}
                                className="flex items-center space-x-2 text-gray-500 hover:text-gray-300 last:text-gray-100 text-xs font-medium cursor-pointer"
                            >
                                <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                                {groupNestLevelNode}
                            </li>
                        )}
                        {tableNestLevel === 'events' && (
                            <li className="flex items-center space-x-2 text-gray-500 hover:text-gray-300 last:text-gray-100 text-xs font-medium cursor-pointer">
                                <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                                {codeNestLevelNode}
                            </li>
                        )}
                    </ol>
                </nav>
                <Tooltip label={expanded ? 'Collapse' : 'Expand'}>
                    <button type="button" className="bg-gray flex items-center" onClick={onClickExpandIcon}>
                        {expanded ? (
                            <ChevronDownIcon className="h-6 w-6 p-1 rounded-full bg-gray-900" />
                        ) : (
                            <ChevronUpIcon className="h-6 w-6 p-1 rounded-full bg-gray-900" />
                        )}
                    </button>
                </Tooltip>
            </div>
            <div
                className={classNames(
                    expanded ? 'max-h-[calc(100vh-498px)]' : 'max-h-[calc(100vh-688px)]',
                    'flex flex-col space-y-4 bg-[#2F3645] rounded-b-lg rounded-tr-lg pb-10 overflow-y-scroll',
                )}
            >
                <>
                    {tableNestLevel === 'grouped' && (
                        <GroupedSummaryTable
                            rowClickHandler={(row: { original: { node: Node; summaryId: string } }) => {
                                setTableNestLevel('codes');
                                setGroupNestLevelNode(getDisplayName(row.original.node));
                                setSummaryId(row.original.summaryId);
                            }}
                            node={node}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {tableNestLevel === 'codes' && summaryId && (
                        <GroupedCodeTable
                            rowClickHandler={(row: { original: { code: string; codeId: string } }) => {
                                setCodeNestLevelNode(row.original.code);
                                setTableNestLevel('events');
                                setCodeId(row.original.codeId);
                            }}
                            node={node}
                            summaryId={summaryId}
                        />
                    )}
                    {tableNestLevel === 'events' && summaryId && codeId && (
                        <IndividualEventTable
                            rowClickHandler={() => console.log('max depth reached')}
                            node={node}
                            summaryId={summaryId}
                            codeId={codeId}
                        />
                    )}
                </>
            </div>
        </>
    );
};
