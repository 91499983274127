import { Transition } from '@headlessui/react';
import { DocumentMagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { TrustFactor } from './Components/TrustFactor';
import { SideNav } from './Components/SideNav';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import { TrustProfileMappings } from './Components/TrustProfileMappings';
import { AdaptiveTrustPolicy } from './Components/AdaptiveTrustPolicy';
import { TrustProfile } from './Components/TrustProfile';
import { SelectedNavItem } from './PolicyModalTypes';
import { useUserPermissions } from 'Utilities/UserPermissions';

export const PolicyModalImplemented = (): JSX.Element => {
    const { userCan } = useUserPermissions();

    const canGetTrust = userCan('list', '/policy/profile');

    const { policy } = useFlags();
    const { dispatch, mapState } = useContext(IdentityMapContext);
    const [selectedNavItem, setSelectedNavItem] = useState<SelectedNavItem | undefined>(undefined);

    const selectedTarget = mapState.selectedPolicyTarget;

    useEffect(() => {
        if (selectedTarget) {
            setSelectedNavItem({ type: 'target', id: String(selectedTarget.id) });
        }
    }, [selectedTarget]);

    const closePolicy = () => dispatch({ type: 'toggle-policy' });
    useHotkeys('esc', closePolicy);

    const panes = {
        target: (
            <AdaptiveTrustPolicy
                selectedTarget={selectedTarget}
                selectedTargetId={selectedNavItem?.id}
                setSelectedNavItem={setSelectedNavItem}
            />
        ),
        mapping: <TrustProfileMappings />,
        profile: <TrustProfile trustProfileId={selectedNavItem?.id} setSelectedNavItem={setSelectedNavItem} />,
        document: <TrustFactor policyDocumentId={selectedNavItem?.id} setSelectedNavItem={setSelectedNavItem} />,
    };

    return (
        policy && (
            <Transition.Root show={true} as={Fragment}>
                <div className="fixed bottom-0 z-10 inset-0 top-[56px] h-[calc(100vh-56px)]">
                    <Helmet>
                        <title>Adaptive Trust</title>
                    </Helmet>
                    <div className="flex items-end justify-center text-center sm:block sm:p-0">
                        <div
                            className="fixed top-[56px] h-[calc(100vh-56px)] w-[100vw] after:inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                            onClick={closePolicy}
                        />

                        <div
                            id="Policy"
                            className="absolute left-[50%] translate-x-[-50%] inline-block bg-gray-800 rounded-lg text-left shadow-xl transform transition-all w-[90vw] xxl:w-[75vw] mt-14 align-top md:mt-5"
                        >
                            <div
                                id="Header"
                                className="flex justify-between items-start p-4 pt-3 pb-3 border-b border-gray-700"
                            >
                                <h1 className="text-md font-semibold tracking-wider">Adaptive Trust Policy</h1>
                            </div>
                            {canGetTrust ? (
                                <div className="flex items-stretch relative ">
                                    <div className="w-80 lg:w-96 border-r border-gray-700 xs:collapse h-[calc(100vh-169px)] overflow-y-scroll ">
                                        <SideNav
                                            selectedNavItem={selectedNavItem}
                                            setSelectedNavItem={setSelectedNavItem}
                                        />
                                    </div>
                                    <div className="bg-gray-700/50 flex-1 h-[calc(100vh-168px)] shadow-[2px_2px_4px_0_rgba(0,0,0,0.25)_inset] overflow-y-scroll ">
                                        {selectedNavItem ? (
                                            panes[selectedNavItem.type]
                                        ) : (
                                            <div className="flex h-full">
                                                <div className="m-auto">
                                                    <h4 className="text-gray-500 filter drop-shadow-md tracking-wide ">
                                                        Select an item from the side menu to view its configuration
                                                    </h4>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="text-center text-gray-300 text-xs pt-2 flex flex-col items-center justify-center h-40">
                                    <DocumentMagnifyingGlassIcon className="h-8 w-8 text-gray-400 mb-3" />
                                    You do not have permission to view Adaptive Trust Policies
                                </div>
                            )}
                            <button
                                type="button"
                                className="text-white text-xs rounded-full p-1 bg-gray-900 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                                onClick={closePolicy}
                            >
                                <XMarkIcon className="h-4 w-4 text-gray-200" />
                            </button>
                        </div>
                    </div>
                </div>
            </Transition.Root>
        )
    );
};
