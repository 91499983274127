import { GET_GLOBAL_EVENTS_HISTORY } from 'Graph/queries';
import { BackendEventHistoryData } from 'Types/types';
import { BaseChart, DashboardChartProps } from './BaseChart';

export const ActorsCountChart = ({ startDate, endDate, interval, onBarClick, selectedBar }: DashboardChartProps) => {
    return (
        <BaseChart
            query={GET_GLOBAL_EVENTS_HISTORY}
            queryName="getEntityHistory"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_ACTOR',
                detailed: true,
            }}
            processRow={(row: BackendEventHistoryData) => {
                return {
                    Actors: row.entityCount ? row.entityCount : 0,
                };
            }}
            bars={[
                {
                    name: 'Actors',
                    dataKey: 'Actors',
                    activeColor: 'rgba(34, 118, 189, 1)',
                    inactiveColor: 'rgba(34, 118, 189, 0.5)',
                },
            ]}
            startDate={startDate}
            endDate={endDate}
            interval={interval}
            onBarClick={onBarClick}
            selectedBar={selectedBar}
        />
    );
};
