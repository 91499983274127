import { useQuery } from '@apollo/client';
import { GET_TENANT } from 'Graph/queries';
import { useAuth } from 'Hooks/Auth';
import { useTenant } from 'Hooks/Hooks';
import { useSelectedAuthProvider } from 'Hooks/Auth';
import { clearLocalCaches, formatBytes } from 'Utilities/utils';
import { Panel, PanelItem } from './Panel';

const Account = (): JSX.Element => {
    const selectedAuthProvider = useSelectedAuthProvider();
    const { logout } = useAuth();
    const tenantId = useTenant();
    const { loading, error, data } = useQuery(GET_TENANT, { variables: { tenantId } });
    const switchOrganization = () => {
        clearLocalCaches();
        localStorage.removeItem('org_id');
        logout();
    };

    let content = <></>;

    if (loading) {
        content = <h4 className="text-xs text-gray-300">Loading Tenant...</h4>;
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not load tenant. Please retry later.</h4>;
    } else if (data) {
        // This is a place holder for the amount of data a tenant has really used
        const { name, tenantId, slug, storageUsed, firstEventAt, lastEventAt } = data.getTenant;
        content = (
            <>
                <PanelItem title="Your Organization Display Name">
                    <p>{name}</p>
                </PanelItem>
                <PanelItem title="Your Organization Name">
                    <p>{slug}</p>
                </PanelItem>
                <PanelItem title="Your Organization ID">
                    <p className="select-all">{tenantId}</p>
                </PanelItem>
                <PanelItem title="Storage Quota">
                    <div
                        className="bg-gray-900 rounded h-4 mt-1 w-5/6"
                        role="progressbar"
                        aria-valuemin={0}
                        aria-valuemax={100}
                    >
                        <div
                            className="bg-green-800 rounded h-4 transition"
                            style={{ width: `${100}%`, transition: 'width 2s' }}
                        ></div>
                    </div>
                    <p className="mt-2">You have used {formatBytes(storageUsed)} of storage.</p>
                    <p className="mt-2">As an early adopter, you currently have unlimited storage.</p>
                </PanelItem>
                <PanelItem title="Available Events">
                    {firstEventAt == 0 ? (
                        <p>No events observed.</p>
                    ) : (
                        <>
                            <p className="mt-2">
                                The first observed event was at {new Date(firstEventAt / 1000000).toLocaleString()}.
                            </p>
                            <p className="mt-2">
                                The most recent observed event was at {new Date(lastEventAt / 1000000).toLocaleString()}
                                .
                            </p>{' '}
                        </>
                    )}
                </PanelItem>
            </>
        );
    }

    return (
        <Panel title="Account Details" subtitle="These settings apply to all users of the account">
            {content}
            {selectedAuthProvider == 'auth0' && (
                <PanelItem title="Switch Accounts">
                    <p>You will be logged out and have the option to specify your desired Organization</p>
                    <button onClick={switchOrganization} className="btn mt-2" data-test="change-organization">
                        Change Organization
                    </button>
                </PanelItem>
            )}
        </Panel>
    );
};

export default Account;
