import { Node } from 'Types/types';

export const isATargetGroup = (node: Node): boolean => {
    if (node.label === 'target') {
        if (node.hasNextLevel) {
            return true;
        }
    }
    return false;
};

export const isAGroupLeafNode = (node: Node): boolean => {
    if (node.label === 'target') {
        if (node.groupingData?.GROUPING_LEVEL_1) {
            return true;
        }
    }
    return false;
};

export const getParentGroupTrail = (node: Node): string | undefined => {
    if (node.label === 'target') {
        if (node.groupingData?.GROUPING_LEVEL_1) {
            return btoa(node.groupingData.GROUPING_LEVEL_1);
        }
    }
};

export const getGroupTrail = (node: Node): string | undefined => {
    if (node.label === 'target' && node.hasNextLevel && node.nextLevelBase64) {
        return node.nextLevelBase64;
    }
};
