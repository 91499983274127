// import { Chart as ChartJS, registerables } from 'chart.js';

// import { Line } from 'react-chartjs-2';
// import 'chartjs-adapter-date-fns';

// ChartJS.register(...registerables);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    stacked: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            backgroundColor: 'rgba(18, 24, 38, 0.8)',
            titleColor: 'white',
            titleAlign: 'center' as const,
            padding: 8,
            boxPadding: 6,
            caretSize: 0,
            caretPadding: 12,
            usePointStyle: true,
            bodySpacing: 6,
            titleFont: {
                family: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                size: 12,
                weight: 'bold',
            },
            bodyFont: {
                family: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                size: 12,
                weight: 'normal',
            },
        },
    },
    scales: {
        y: {
            max: 6.5,
            min: 0.5,
            ticks: {
                stepSize: 1,
                display: false,
            },
            grid: {
                borderColor: '#575E6C',
                color: '#575E6C',
                drawTicks: false,
            },
            title: {
                display: false,
            },
        },
        x: {
            type: 'time' as const,
            time: {
                unit: 'day' as const,
            },
            grid: {
                borderColor: '#575E6C',
                color: '#575E6C',
                drawTicks: false,
            },
            ticks: {
                stepSize: 0.25,
                padding: 10,
                color: '#9ca3af',
            },
        },
    },
};

export const data = {
    datasets: [
        {
            label: 'Patrick Ann',
            data: [
                { x: 1643184000000, y: 5 },
                { x: 1643162400000, y: 5 },
                { x: 1643140800000, y: 4 },
                { x: 1643119200000, y: 3 },
                { x: 1643097600000, y: 6 },
                { x: 1643076000000, y: 4 },
                { x: 1643054400000, y: 3 },
                { x: 1643032800000, y: 5 },
                { x: 1643011200000, y: 1 },
                { x: 1642989600000, y: 3 },
                { x: 1642968000000, y: 3 },
                { x: 1642946400000, y: 2 },
                { x: 1642924800000, y: 3 },
                { x: 1642903200000, y: 1 },
                { x: 1642881600000, y: 2 },
                { x: 1642860000000, y: 3 },
                { x: 1642838400000, y: 2 },
                { x: 1642816800000, y: 3 },
                { x: 1642795200000, y: 5 },
                { x: 1642773600000, y: 6 },
                { x: 1642752000000, y: 4 },
                { x: 1642708800000, y: 5 },
                { x: 1642687200000, y: 6 },
                { x: 1642665600000, y: 5 },
            ],
            backgroundColor: 'rgba(189, 94, 234, 0.25)',
            borderColor: '#BD5EEA',
            borderWidth: 2,
            pointBackgroundColor: '#BD5EEA',
            pointBorderColor: 'white',
            pointBorderWidth: 1,
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 9,
            spanGaps: true,
            tension: 0.4,
        },
        {
            label: 'Jeff Ham',
            data: [
                { x: 1643184000000, y: 1 },
                { x: 1643162400000, y: 2 },
                { x: 1643162400000, y: 2 },
                { x: 1643140800000, y: 3 },
                { x: 1643119200000, y: 2 },
                { x: 1643097600000, y: 5 },
                { x: 1643076000000, y: 3 },
                { x: 1643054400000, y: 1 },
                { x: 1643032800000, y: 3 },
                { x: 1643011200000, y: 4 },
                { x: 1642989600000, y: 3 },
                { x: 1642968000000, y: 5 },
                { x: 1642946400000, y: 1 },
                { x: 1642924800000, y: 2 },
                { x: 1642903200000, y: 3 },
                { x: 1642881600000, y: 2 },
                { x: 1642860000000, y: 5 },
                { x: 1642838400000, y: 2 },
                { x: 1642816800000, y: 1 },
                { x: 1642795200000, y: 3 },
                { x: 1642773600000, y: 2 },
                { x: 1642752000000, y: 5 },
                { x: 1642708800000, y: 4 },
                { x: 1642687200000, y: 3 },
                { x: 1642665600000, y: 2 },
            ],
            backgroundColor: 'rgba(94, 117, 234, 0.25)',
            borderColor: '#5E75EA',
            borderWidth: 2,
            pointBackgroundColor: '#5E75EA',
            pointBorderColor: 'white',
            pointBorderWidth: 1,
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 9,
            spanGaps: true,
            tension: 0.4,
        },
    ],
};

export const Chart = (): JSX.Element => {
    return (
        <div className="w-full h-full relative px-4">
            <div className="absolute w-full h-full top-0 right-1 pt-[10px] pr-[40px] pb-[35px] pl-[48px]">
                <div className="bg-green-600 opacity-20 hover:opacity-40 h-1/3 w-[100%]"></div>
                <div className="bg-yellow-600 opacity-20 hover:opacity-40 h-1/3 w-[100%]"></div>
                <div className="bg-red-600 opacity-20 hover:opacity-40 h-1/3 w-[100%]"></div>
                <p className="absolute top-4 opacity-40 left-14 uppercase tracking-wider font-bold text-xs text-green-300">
                    High Trust
                </p>
                <p className="absolute bottom-10 opacity-40 left-14 uppercase tracking-wider font-bold text-xs text-red-300">
                    Low Trust
                </p>
            </div>
            {/* <Line options={options} data={data} className="h-full relative z-10" /> */}
        </div>
    );
};
