import { useQuery } from '@apollo/client';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { GET_ENTITY_ATTRIBUTES } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext, useMemo, useState } from 'react';
import { classNames, getEntityType } from 'Utilities/utils';
import { AttributeProps } from '../ProfileTypes';
import { Disclosure } from '@headlessui/react';
import { PolicyRuleCategory } from 'Mocks/PolicyMock/Components/PolicyWindowMock';
import { PolicyRuleMock } from 'Types/types';
import { RuleCardProps } from 'Mocks/PolicyMock/Components/RuleCardMock';
import { Tooltip } from 'Library/Tooltip';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import {
    FolderIcon,
    GlobeAltIcon,
    KeyIcon,
    LockClosedIcon,
    NoSymbolIcon,
    MoonIcon,
    UserMinusIcon,
    ShieldCheckIcon,
    ArrowTopRightOnSquareIcon,
    CpuChipIcon,
    ClockIcon,
    BuildingStorefrontIcon,
    CheckBadgeIcon,
    CloudArrowDownIcon,
    BellAlertIcon,
    CubeTransparentIcon,
} from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';

export type JoinedObjectProps = {
    modifiedKeys: Record<string, string | number>;
    unmodifiedKeys: Record<string, string | number>;
};

export const NodeTrustDisplayPrototype = ({
    node,
    dynamicHeight,
}: AttributeProps & { dynamicHeight?: boolean }): JSX.Element => {
    const { mapState } = useContext(IdentityMapContext);
    const tenantId = useTenant();
    const nodeTypeHasAttributes = node.label == 'actor' || node.label == 'device';

    const { loading, error } = useQuery(GET_ENTITY_ATTRIBUTES, {
        variables: {
            tenantId: tenantId,
            entityId: node.id,
            entityType: getEntityType(node),
            startTimeInMs: mapState.selectedTime[0],
            endTimeInMs: mapState.selectedTime[1],
        },
        skip: !tenantId || !nodeTypeHasAttributes,
    });

    const { currentTrust, averageTrust, lowestTrust } = useMemo(() => {
        let currentTrust = 0;
        let averageTrust = 0;
        let lowestTrust = 0;

        if (node.trustData) {
            currentTrust = Math.round(node.trustData.at(-1) || 0);
            averageTrust = Math.round(node.trustData.reduce((acc, curr) => acc + curr, 0) / node.trustData.length);
            lowestTrust = node.trustData.reduce((acc, curr) => Math.min(acc, curr), 100);
        }
        return {
            currentTrust,
            averageTrust,
            lowestTrust,
        };
    }, [node.trustData]);

    if (loading) {
        return (
            <div className="bg-gray-700 rounded-lg flex justify-center items-center text-xs h-16">
                <div className="h-4 w-4 loader" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-gray-700 rounded-l py-4 flex flex-col justify-center items-center text-xs">
                <NoSymbolIcon className="h-8 w-8 text-red-400 mb-2" />
                <span className="text-gray-400">Could not load Trust</span>
            </div>
        );
    }

    return (
        <div className="h-full flex flex-col">
            <div className="flex justify-around items-center bg-gray-700 rounded-lg mb-3 flex-none">
                <ProfilePanelTabHeader
                    title={'Current Trust'}
                    count={currentTrust}
                    riskCount={0}
                    active={false}
                    onClick={() => {
                        console.log('noop');
                    }}
                />
                <ProfilePanelTabHeader
                    title={'Average Trust'}
                    count={averageTrust}
                    riskCount={0}
                    active={false}
                    onClick={() => {
                        console.log('noop');
                    }}
                />
                <ProfilePanelTabHeader
                    title={'Lowest Trust'}
                    count={lowestTrust}
                    riskCount={0}
                    active={false}
                    onClick={() => {
                        console.log('noop');
                    }}
                />
            </div>

            <div className="bg-gray-700 rounded-lg pl-4 py-2 flex flex-col flex-grow">
                <Tooltip label="This Actor matches the 'US Employees' trust profile" placement="right">
                    <div className="text-md tracking-widest uppercase text-gray-500 font-bold pt-1 pb-2">
                        US Employees Profile
                    </div>
                </Tooltip>
                <div
                    className={classNames(
                        'overflow-y-auto pr-2',
                        dynamicHeight ? 'flex-grow' : 'h-[calc(100vh-850px)]',
                    )}
                >
                    {policyRuleCategories.map((item: PolicyRuleCategory) => (
                        <Disclosure as="div" key={item.name} defaultOpen={item.defaultOpen} className="space-y-2 py-1">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button
                                        className={classNames(
                                            'bg-gray-700 text-gray-300 text-xs hover:bg-gray-700 hover:text-white',
                                            'group w-full flex items-center pl-1 pr-1 text-left font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400',
                                        )}
                                        as="button"
                                    >
                                        {open ? (
                                            <ChevronDownIcon width="18" height="18" className="mr-2" />
                                        ) : (
                                            <ChevronRightIcon width="18" height="18" className="mr-2" />
                                        )}
                                        {item.name}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="space-y-1">
                                        {item.children.map(
                                            (rule: PolicyRuleMock) =>
                                                rule.icon && (
                                                    <RuleCardMock
                                                        key={rule.name}
                                                        name={rule.name}
                                                        description={rule.description}
                                                        author={rule.author}
                                                        icon={rule.icon}
                                                        riskLevel={rule.riskLevel}
                                                    />
                                                ),
                                        )}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const RuleCardMock = ({ name, icon, description }: RuleCardProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    return (
        <div key={name} className="py-1 relative">
            <div className="ml-6 pl-6 bg-gray-700 rounded-md" onClick={() => setOpen(!open)}>
                <Tooltip label="This factor is having a critical impact on the trust score." placement="left">
                    <div
                        className={classNames(
                            name == 'Connecting from Non-Directory Location' ? 'border-yellow-500' : 'border-blue-700',
                            'bg-gray-600 border-2 transition rounded-full shadow-lg absolute left-0 top-1 h-10 w-10 text-white flex items-center justify-center',
                        )}
                    >
                        <div className="h-4 w-4">{icon}</div>
                    </div>
                </Tooltip>
                <div className="px-2 py-1 relative flex justify-between items-center hover:bg-gray-600 rounded-md">
                    <div>
                        <h4 className="text-md font-semibold mb-1 text-white mr-6">{name}</h4>
                        <p className="text-white text-xs">{description}</p>
                    </div>
                </div>
            </div>

            {open && (
                <div className="pl-16 pt-2 text-gray-300 space-y-4">
                    <div className="flex items-center">
                        <ExclamationTriangleIcon className="h-4 w-4 mr-2 text-yellow-500" />
                        <p>This factor is having a critical impact on the trust score.</p>
                    </div>
                    <div className="space-y-1">
                        <p className="font-semibold">Policy Outcome</p>
                        <p className="text-gray-400">Click to view the full policy</p>
                        <p className="bg-gray-900 text-gray-300 p-2 border-gray-500 border rounded-md">
                            Current Location does not match directory or travel location.
                        </p>
                    </div>
                    <div className="space-y-2">
                        <p className="font-semibold">Policy Context</p>
                        <div className="rounded-md overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-700 ">
                                <thead>
                                    <tr className="bg-gray-800 text-gray-300">
                                        <th className="px-2 py-2 text-left text-xxs font-medium uppercase tracking-wider">
                                            Context
                                        </th>
                                        <th className="px-2 py-2 text-left text-xxs font-medium uppercase tracking-wider">
                                            Value
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-gray-900 divide-y divide-gray-700">
                                    <tr>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">Current Location</td>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">China, Beijing</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">
                                            Directory Location
                                        </td>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">USA, Charlotte </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300">Travel Location</td>
                                        <td className="px-2 py-2 whitespace-nowrap text-gray-300"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const ProfilePanelTabHeader = ({
    title,
    onClick,
    active,
    count,
}: {
    title: string;
    onClick: () => void;
    active: boolean;
    count: number;
    riskCount: number;
}) => {
    // Break down the count to
    // 0 - 10 = red
    // 11 - 30 = orange
    // 31 - 50 = yellow
    // 51- 75 = blue
    // 76 - 100 = green
    let color = 'bg-gray-500';
    if (count < 11) {
        color = 'bg-red-500';
    } else if (count < 31) {
        color = 'bg-orange-500';
    } else if (count < 51) {
        color = 'bg-yellow-500';
    } else if (count < 76) {
        color = 'bg-blue-500';
    } else if (count < 101) {
        color = 'bg-green-500';
    }

    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(
                active ? 'border-white' : '',
                'flex-1 flex flex-col items-center justify-center px-3 py-1.5 transition-all border-2 border-transparent outline-none rounded-lg',
            )}
        >
            <h1 className="text-gray-400">{title}</h1>
            <div className="flex items-center justify-center relative">
                <h1 className="text-lg font-bold">{count}</h1>
                <div className="text-xs text-green-400 flex items-center absolute -right-8">
                    <span className={classNames('inline-block w-3 h-3 mr-2 rounded-full', color)} />
                </div>
            </div>
        </button>
    );
};

const policyRuleCategories: PolicyRuleCategory[] = [
    {
        name: 'Trust Impacting Factors',
        defaultOpen: true,
        children: [
            {
                name: 'Connecting from Non-Directory Location',
                description: `The actor is connecting from a location that does not match their directory location.`,
                author: 'SailPoint Identity Risk - data from Identity Provider and Workday',
                selected: false,
                riskLevel: 4,
                icon: <GlobeAltIcon />,
            },
        ],
    },
    {
        name: 'Identity Trust',
        defaultOpen: false,
        children: [
            {
                name: 'Employee Pending Termination',
                description: `The actor is pending termination from the organization`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: Workday',
                riskLevel: 3,
                icon: <UserMinusIcon />,
            },
            {
                name: 'Employee On Leave',
                description: `The actor is on Leave or PTO from the organization`,
                selected: true,
                author: 'SailPoint Identity Risk - Data Source: Workday',
                riskLevel: 2,
                icon: <MoonIcon />,
            },
        ],
    },
    {
        name: 'Behavior Trust',
        defaultOpen: false,
        children: [
            {
                name: 'Failed Login Attempts',
                description: `Actor has had multiple failed login attempts in the last 24 hours`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: MFA provider',
                riskLevel: 3,
                icon: <LockClosedIcon />,
            },
            {
                name: 'Multi-Factor Authentication Requirement',
                description: `Actor accessing resource without multi-factor authentication requirement`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: MFA provider',
                riskLevel: 3,
                icon: <KeyIcon />,
            },
            {
                name: 'Multi-Factor Authentication Strength',
                description: `Actor is using weak or non-phishing-resistant MFA method`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: MFA provider',
                riskLevel: 3,
                icon: <CheckBadgeIcon />,
            },
            {
                name: 'Multi-Factor Authentication Age',
                description: `Actor has not passed multi-factor authentication challenge for the past 6 hours`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: MFA provider',
                riskLevel: 2,
                icon: <ClockIcon />,
            },
        ],
    },

    {
        name: 'Access Trust',
        defaultOpen: false,
        children: [
            {
                name: 'Connecting from an Anonymous IP',
                description: `The actor is connecting from an anonymous IP address, such as a VPN, proxy, or TOR`,
                author: 'SailPoint Identity Risk - Data Source: Identity Provider',
                selected: false,
                riskLevel: 3,
                icon: <CubeTransparentIcon />,
            },

            {
                name: 'Connecting from a Public Location',
                description: `This actor is connecting from a public location, such as a coffee shop or airport`,
                selected: true,
                author: 'SailPoint Identity Risk - Data Source: Identity Provider',
                riskLevel: 2,
                icon: <BuildingStorefrontIcon />,
            },
            {
                name: 'Connecting from Business Travel Location',
                description: `The actor is connecting from expected business travel location`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: Identity Provider and Concur',
                riskLevel: 1,
                icon: <ArrowTopRightOnSquareIcon />,
            },
            {
                name: 'Connecting from an Approved Remote Location',
                description: `The actor is working from an approved non-company location such as home office`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: Identity Provider and Workday',
                riskLevel: 1,
                icon: <ShieldCheckIcon />,
            },
        ],
    },
    {
        name: 'Device Trust',
        defaultOpen: false,
        children: [
            {
                name: 'Device Compromise',
                description: `The device reported as compromised by endpoint security`,
                selected: true,
                author: 'SailPoint Identity Risk - Data Source: CrowdStrike',
                riskLevel: 3,
                icon: <BellAlertIcon />,
            },
            {
                name: 'Device Trust',
                description: `The device trust level reported below acceptable threshold`,
                selected: true,
                author: 'SailPoint Identity Risk - Data Source: SentinelOne',
                riskLevel: 2,
                icon: <CheckIcon />,
            },
            {
                name: 'Device Management',
                description: `The device is not managed by UEM/MDM`,
                selected: true,
                author: 'SailPoint Identity Risk - Data Source: Jamf, Intune',
                riskLevel: 2,
                icon: <CloudArrowDownIcon />,
            },
            {
                name: 'Device TPM',
                description: `The device does not have a verified TPM enabled`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: Jamf, Intune',
                riskLevel: 2,
                icon: <CpuChipIcon />,
            },
            {
                name: 'Disk Encryption',
                description: `Device disk encryption not enabled`,
                selected: false,
                author: 'SailPoint Identity Risk - Data Source: Jamf, InTune',
                riskLevel: 2,
                icon: <FolderIcon />,
            },
        ],
    },
];
