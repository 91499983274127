import { XMarkIcon } from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { useContext, useState } from 'react';

import { classNames } from 'Utilities/utils';
import { GroupSchema } from './GroupingTypes';

export const GroupingPanel = () => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { graphData, groupingOpen } = mapState;
    const { nodes } = graphData;
    const [selectedTab, setSelectedTab] = useState('target');

    const clearGroup = (label: string) => {
        const nodesToGroup = nodes.filter((node) => node.label === label);
        nodesToGroup.map((node) => {
            node.group = node.label;
        });
    };

    const GroupHeader = ({ description, label }: { description: string; label: keyof typeof mapState.group }) => {
        return (
            <button
                data-test="group-header"
                type="button"
                className={classNames(HeaderStyles, selectedTab == label ? 'text-white border-white' : '')}
                onClick={() => setSelectedTab(label)}
                style={{ marginBottom: '-1px' }}
            >
                {description}
                {mapState.group[label] === undefined ? (
                    ''
                ) : (
                    <div className="h-2 w-2 bg-blue-500 rounded-full ml-1"></div>
                )}
            </button>
        );
    };

    const GroupButton = ({
        description,
        label,
        attribute,
        setGroup,
    }: {
        description: string;
        label: keyof typeof mapState.group;
        attribute: string;
        setGroup: (nodes: Node[]) => void;
    }) => {
        const selected = mapState.group[label] === attribute;
        return (
            <button
                data-test="group-button"
                className={'btn flex-auto rounded-md' + (selected ? ' bg-gray-900' : '')}
                onClick={() => {
                    if (selected) {
                        clearGroup(label);
                        dispatch({ type: 'set-group', label: label, attribute: undefined });
                    } else {
                        const nodesToGroup = nodes.filter((node) => node.label === label);
                        setGroup(nodesToGroup);
                        dispatch({ type: 'set-group', label: label, attribute: attribute });
                    }
                }}
            >
                {description}
            </button>
        );
    };

    return (
        <div
            className={classNames('flex items-center justify-center w-192', groupingOpen ? '' : 'hidden')}
            id="grouping-widget"
        >
            <div className="w-full rounded-md bg-gray-800 border border-gray-400 border-opacity-60 text-xs text-gray-200 hover:border-gray-400  pointer-events-auto">
                <button
                    onClick={() => dispatch({ type: 'toggle-grouping' })}
                    className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-2.5 -right-2.5 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                >
                    <XMarkIcon className="h-3.5 w-3.5 text-gray-200" />
                </button>
                <div className="p-4 text-xs text-gray-400 overflow-hidden">
                    <h2 className="uppercase tracking-wider font-bold text-xs text-gray-500">Grouping Options</h2>
                    <div className="flex flex-col space-y-4">
                        <div className="mt-1">
                            <nav
                                className="flex border-b border-gray-600 justify-around items-stretch mb-3"
                                data-test="grouping-tabs"
                            >
                                <GroupHeader description="Target" label="target" />
                                <GroupHeader description="Identity" label="identity" />
                                <GroupHeader description="Device" label="device" />
                                <GroupHeader description="Actor" label="actor" />
                            </nav>
                            {selectedTab == 'target' && (
                                <div className="grid grid-cols-3 gap-2" data-test="grouping-buttons">
                                    {Object.entries(GroupSchema.target).map(
                                        ([attribute, { description, setGroupFunction }]) => {
                                            return (
                                                <GroupButton
                                                    label="target"
                                                    key={attribute}
                                                    attribute={attribute}
                                                    description={description}
                                                    setGroup={setGroupFunction}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            )}
                            {selectedTab == 'identity' && (
                                <div className="grid grid-cols-3 gap-2">
                                    {Object.entries(GroupSchema.identity).map(
                                        ([attribute, { description, setGroupFunction }]) => {
                                            return (
                                                <GroupButton
                                                    label="identity"
                                                    key={attribute}
                                                    attribute={attribute}
                                                    description={description}
                                                    setGroup={setGroupFunction}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            )}
                            {selectedTab == 'device' && (
                                <div className="grid grid-cols-3 gap-2">
                                    {Object.entries(GroupSchema.device).map(
                                        ([attribute, { description, setGroupFunction }]) => {
                                            return (
                                                <GroupButton
                                                    label="device"
                                                    key={attribute}
                                                    attribute={attribute}
                                                    description={description}
                                                    setGroup={setGroupFunction}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            )}
                            {selectedTab == 'actor' && (
                                <div className="grid grid-cols-3 gap-2">
                                    {Object.entries(GroupSchema.actor).map(
                                        ([attribute, { description, setGroupFunction }]) => {
                                            return (
                                                <GroupButton
                                                    label="actor"
                                                    key={attribute}
                                                    attribute={attribute}
                                                    description={description}
                                                    setGroup={setGroupFunction}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const HeaderStyles =
    'flex items-center justify-center flex-1 py-1.5 rounded-t-md border-b-2 border-transparent hover:border-gray-500 hover:text-gray-200 focus:outline-none focus:ring-0';
