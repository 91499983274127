import { Disclosure } from '@headlessui/react';
import {
    ChevronDownIcon,
    WifiIcon,
    MapPinIcon,
    ExclamationTriangleIcon,
    ExclamationCircleIcon,
    CheckCircleIcon,
} from '@heroicons/react/24/solid';

import Desktop from 'assets/icons/Devices/Desktop.png';
import Tablet from 'assets/icons/Devices/Tablet.png';
import Phone from 'assets/icons/Devices/Phone.png';
import Laptop from 'assets/icons/Devices/Laptop.png';

export const Details = (): JSX.Element => {
    return (
        <div className="space-y-2">
            <p className="uppercase tracking-wider font-bold text-xs text-gray-400 mb-5 flex items-center">
                Information
            </p>
            <Disclosure as="div">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between items-center space-x-2 w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-700 hover:bg-gray-600 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <div className="flex place-content-center">
                                <div className="ml-1 h-5 w-5 rounded-full border-2 border-white bg-[#5E75EA]"></div>
                            </div>
                            <div className="flex-1">
                                <span>Jeff Ham</span>
                                <p className="text-xs">4 Devices</p>
                            </div>
                            <div className="flex space-x-2">
                                <div className="badge bg-red-500 text-xs">Low Trust</div>
                                <ChevronDownIcon
                                    className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                />
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-1 pt-2 text-sm text-gray-500 space-y-1">
                            <Disclosure as="div" className="bg-gray-900 hover:bg-black p-2 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center space-x-2">
                                            <div className="flex space-x-4 w-32">
                                                <div className="rounded-full border-2 border-red-500 p-2">
                                                    <img src={Tablet} className="h-4 w-4" />
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="text-xs text-gray-300">Tablet</p>
                                                    <p className="text-xs text-gray-300">iOS</p>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <WifiIcon className="w-3 h-3 mr-2" /> Public Network
                                                </p>
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <MapPinIcon className="w-3 h-3 mr-2" /> Brisbane, Australia
                                                </p>
                                            </div>
                                            <ChevronDownIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-sm text-gray-500 space-y-2 mt-2">
                                            <div className="text-xs text-gray-300 flex">
                                                <ExclamationCircleIcon className="h-4 w-4 text-red-400 mr-2" />
                                                Abnormal device location
                                            </div>
                                            <div className="text-xs text-gray-300 flex">
                                                <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400 mr-2" />
                                                Connected to a public network
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="bg-gray-900 hover:bg-black p-2 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center space-x-2">
                                            <div className="flex space-x-4 w-32">
                                                <div className="rounded-full border-2 border-yellow-500 p-2">
                                                    <img src={Phone} className="h-4 w-4" />
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="text-xs text-gray-300">Mobile</p>
                                                    <p className="text-xs text-gray-300">iOS</p>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <WifiIcon className="w-3 h-3 mr-2" /> Public Network
                                                </p>
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <MapPinIcon className="w-3 h-3 mr-2" /> Los Angeles, CA
                                                </p>
                                            </div>
                                            <ChevronDownIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-sm text-gray-500 space-y-2 mt-2">
                                            <div className="text-xs text-gray-300 flex">
                                                <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400 mr-2" />
                                                Connected to a public network
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="bg-gray-900 hover:bg-black p-2 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center space-x-2">
                                            <div className="flex space-x-4 w-32">
                                                <div className="rounded-full border-2 border-yellow-500 p-2">
                                                    <img src={Laptop} className="h-4 w-4" />
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="text-xs text-gray-300">Laptop</p>
                                                    <p className="text-xs text-gray-300">macOS</p>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <WifiIcon className="w-3 h-3 mr-2" /> Public Network
                                                </p>
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <MapPinIcon className="w-3 h-3 mr-2" /> Los Angeles, CA
                                                </p>
                                            </div>
                                            <ChevronDownIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-sm text-gray-500 space-y-2 mt-2">
                                            <div className="text-xs text-gray-300 flex">
                                                <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400 mr-2" />
                                                Connected to a public network
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="bg-gray-900 hover:bg-black p-2 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center space-x-2">
                                            <div className="flex space-x-4 w-32">
                                                <div className="rounded-full border border-gray-500 p-2">
                                                    <img src={Desktop} className="h-4 w-4" />
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="text-xs text-gray-300">Desktop</p>
                                                    <p className="text-xs text-gray-300">Windows</p>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <WifiIcon className="w-3 h-3 mr-2" /> Secure Network
                                                </p>
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <MapPinIcon className="w-3 h-3 mr-2" /> Torrance, CA
                                                </p>
                                            </div>
                                            <ChevronDownIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-sm text-gray-500 space-y-2 mt-2">
                                            <div className="text-xs flex">
                                                <CheckCircleIcon className="h-4 w-4 text-green-400 mr-2" />
                                                No policy warnings
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            <Disclosure as="div">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between items-center space-x-2 w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-700 hover:bg-gray-600 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <div className="flex place-content-center">
                                <div className="ml-1 h-5 w-5 rounded-full border-2 border-white bg-[#BD5EEA]"></div>
                            </div>
                            <div className="flex-1">
                                <span>Patrick Ann</span>
                                <p className="text-xs">4 Devices</p>
                            </div>
                            <div className="flex space-x-2">
                                <div className="badge bg-green-400 text-xs">High Trust</div>
                                <ChevronDownIcon
                                    className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                />
                            </div>
                        </Disclosure.Button>

                        <Disclosure.Panel className="px-1 pt-2 text-sm text-gray-500 space-y-1">
                            <Disclosure as="div" className="bg-gray-900 hover:bg-black p-2 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center space-x-2">
                                            <div className="flex space-x-4 w-32">
                                                <div className="rounded-full border border-gray-500 p-2">
                                                    <img src={Tablet} className="h-4 w-4" />
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="text-xs text-gray-300">Tablet</p>
                                                    <p className="text-xs text-gray-300">iOS</p>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <WifiIcon className="w-3 h-3 mr-2" /> Secure Network
                                                </p>
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <MapPinIcon className="w-3 h-3 mr-2" /> Campbell, CA
                                                </p>
                                            </div>
                                            <ChevronDownIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-sm text-gray-500 space-y-2 mt-2">
                                            <div className="text-xs flex">
                                                <CheckCircleIcon className="h-4 w-4 text-green-400 mr-2" />
                                                No policy warnings
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="bg-gray-900 hover:bg-black p-2 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center space-x-2">
                                            <div className="flex space-x-4 w-32">
                                                <div className="rounded-full border border-gray-500 p-2">
                                                    <img src={Phone} className="h-4 w-4" />
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="text-xs text-gray-300">Phone</p>
                                                    <p className="text-xs text-gray-300">iOS</p>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <WifiIcon className="w-3 h-3 mr-2" /> Secure Network
                                                </p>
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <MapPinIcon className="w-3 h-3 mr-2" /> Campbell, CA
                                                </p>
                                            </div>
                                            <ChevronDownIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-sm text-gray-500 space-y-2 mt-2">
                                            <div className="text-xs flex">
                                                <CheckCircleIcon className="h-4 w-4 text-green-400 mr-2" />
                                                No policy warnings
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="bg-gray-900 hover:bg-black p-2 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center space-x-2">
                                            <div className="flex space-x-4 w-32">
                                                <div className="rounded-full border border-gray-500 p-2">
                                                    <img src={Laptop} className="h-4 w-4" />
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="text-xs text-gray-300">Laptop</p>
                                                    <p className="text-xs text-gray-300">macOS</p>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <WifiIcon className="w-3 h-3 mr-2" /> Secure Network
                                                </p>
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <MapPinIcon className="w-3 h-3 mr-2" /> Campbell, CA
                                                </p>
                                            </div>
                                            <ChevronDownIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-sm text-gray-500 space-y-2 mt-2">
                                            <div className="text-xs flex">
                                                <CheckCircleIcon className="h-4 w-4 text-green-400 mr-2" />
                                                No policy warnings
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="bg-gray-900 hover:bg-black p-2 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center space-x-2">
                                            <div className="flex space-x-4 w-32">
                                                <div className="rounded-full border border-gray-500 p-2">
                                                    <img src={Desktop} className="h-4 w-4" />
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="text-xs text-gray-300">Desktop</p>
                                                    <p className="text-xs text-gray-300">Windows</p>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <WifiIcon className="w-3 h-3 mr-2" /> Secure Network
                                                </p>
                                                <p className="flex text-xs items-center text-gray-300">
                                                    <MapPinIcon className="w-3 h-3 mr-2" /> Campbell, CA
                                                </p>
                                            </div>
                                            <ChevronDownIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-sm text-gray-500 space-y-2 mt-2">
                                            <div className="text-xs flex">
                                                <CheckCircleIcon className="h-4 w-4 text-green-400 mr-2" />
                                                No policy warnings
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};
