import { MinusSmallIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Tooltip } from 'Library/Tooltip';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useCallback, useContext, useEffect } from 'react';
import {
    SubmitHandler,
    useFieldArray,
    UseFieldArrayRemove,
    UseFieldArrayUpdate,
    useForm,
    UseFormRegister,
    UseFormWatch,
} from 'react-hook-form';
import { GraphFilterForm, GraphFilter, GraphFilterProperties, GraphFilterOperations } from 'Types/types';
import { classNames } from 'Utilities/utils';
import { FilterSchema } from './Schema';

interface FilterInputProps {
    idx: number;
    register: UseFormRegister<GraphFilterForm>;
    remove: UseFieldArrayRemove;
    watch: UseFormWatch<GraphFilterForm>;
    update: UseFieldArrayUpdate<GraphFilterForm, 'filters'>;
}

const NumberOperations = () => {
    return (
        <>
            <option value="greater-than">greater than</option>
            <option value="less-than">less than</option>
            <option value="equals">equals</option>
        </>
    );
};

const StringOperations = () => {
    return (
        <>
            <option value="equals">matches</option>
            <option value="contains">contains</option>
            <option value="not-equals">does not match</option>
            <option value="not-contains">does not contain</option>
            <option value="regex">regex</option>
        </>
    );
};

const ObjectOperations = () => {
    return (
        <>
            <option value="has">has</option>
            <option value="does-not-have">doesn't have</option>
        </>
    );
};

const getAvailableProperties = (label: string): GraphFilterProperties[] => {
    const properties = FilterSchema[label as keyof typeof FilterSchema];
    return Object.keys(properties) as GraphFilterProperties[];
};

const AvailableProperties = ({ label }: { label: string }) => {
    const properties = FilterSchema[label as keyof typeof FilterSchema];

    const options = [];

    if (properties) {
        for (const [key, value] of Object.entries(properties)) {
            options.push(
                <option key={key} value={key}>
                    {value.name}
                </option>,
            );
        }
    }

    return <>{options}</>;
};

const getAvailableOperations = (label: string, property: string): GraphFilterOperations[] | undefined => {
    const properties = FilterSchema[label as keyof typeof FilterSchema];
    const propertyDetails = properties[property];
    if (propertyDetails) {
        switch (propertyDetails.type) {
            case 'number':
                return ['greater-than', 'less-than', 'equals'];
            case 'string':
                return ['contains', 'equals', 'not-equals', 'not-contains', 'regex'];
            case 'object':
                return ['has', 'does-not-have'];
        }
    }
};

const AvailableOperations = ({ label, property }: { label: string; property: string }) => {
    const properties = FilterSchema[label as keyof typeof FilterSchema];
    const propertyDetails = properties[property];

    if (propertyDetails) {
        switch (propertyDetails.type) {
            case 'number':
                return <NumberOperations />;
            case 'string':
                return <StringOperations />;
            case 'object':
                return <ObjectOperations />;
        }
    }

    return <></>;
};

const FilterInput = ({ idx, register, remove, watch, update }: FilterInputProps) => {
    const label_id = `filters.${idx}.label` as const;
    const label = watch(label_id);

    const property_id = `filters.${idx}.property` as const;
    const property = watch(property_id);

    const operation_id = `filters.${idx}.operation` as const;
    const operation = watch(operation_id);

    const value_id = `filters.${idx}.value` as const;
    const value = watch(value_id);

    // when the label changes, update the available properties
    useEffect(() => {
        const availableProperties = getAvailableProperties(label);
        if (!availableProperties.includes(property)) {
            const p = availableProperties[0];
            update(idx, { label, property: p, operation, value });
        }
    }, [idx, label, operation, property, update, value]);

    // when the label changes, update the available operations (after the property has been updated)
    useEffect(() => {
        const availableOperations = getAvailableOperations(label, property);
        if (availableOperations) {
            if (!availableOperations.includes(operation)) {
                const o = availableOperations[0];
                update(idx, { label, property, operation: o, value });
            }
        }
    }, [idx, label, operation, property, update, value]);

    return (
        <div className="full drop-shadow-md rounded-md relative">
            <div className="flex">
                <select
                    data-test="filter-label"
                    className="flex-auto pl-3 pr-7 py-2 text-xs bg-gray-900 border-0 border-b-0 border-gray-400 focus:ring-0 focus:border-1 focus:border-gray-400 focus:outline-none rounded-tl-md text-white"
                    {...register(label_id, { required: true })}
                >
                    <option value="target">Target</option>
                    <option value="actor">Actor</option>
                    <option value="device">Device</option>
                    <option value="identity">Identity</option>
                    <option value="application">Application</option>
                    <option value="link">Identity {'→'} Target</option>
                </select>
                <select
                    data-test="filter-property"
                    className="flex-auto pl-3 pr-7 py-2 text-xs bg-gray-900 border-t-0 border-b-0 border-gray-600 focus:ring-0 focus:border-1 focus:border-gray-600 focus:outline-none text-white"
                    {...register(property_id, { required: true })}
                >
                    <AvailableProperties label={label} />
                </select>
                <select
                    data-test="filter-operation"
                    className="flex-auto pl-3 pr-7 py-2 text-xs bg-gray-900 border-0 border-b-0 border-gray-400 focus:ring-0 focus:border-1 focus:border-gray-400 focus:outline-none rounded-tr-md text-white"
                    {...register(operation_id, { required: true })}
                >
                    <AvailableOperations label={label} property={property} />
                </select>
            </div>
            <input
                data-test="filter-value"
                type="text"
                className="block w-full px-3 py-2 rounded-none rounded-b-md border-0 border-t focus:ring-0 focus:border-gray-600 border-gray-600 text-xs bg-gray-700 text-white"
                placeholder="filter value"
                {...register(value_id, { required: true })}
            />
            <Tooltip label="Remove filter">
                <button
                    data-test="remove-filter"
                    type="button"
                    onClick={() => remove(idx)}
                    className="text-white text-xs rounded-full p-0.5 bg-gray-900 border border-gray-500 hover:border-gray-200 absolute -top-2.5 -right-2.5 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                >
                    <MinusSmallIcon className="h-3.5 w-3.5 text-gray-200" />
                </button>
            </Tooltip>
        </div>
    );
};

export const FilterPanel = (): JSX.Element => {
    const { dispatch, mapState } = useContext(IdentityMapContext);
    const { filterOpen } = mapState;

    const {
        control,
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, isValid },
    } = useForm<GraphFilterForm>({ mode: 'onChange' });

    const { fields, append, remove, update } = useFieldArray<GraphFilterForm>({
        control,
        name: 'filters',
    });

    // useEffect here to add pre-canned filters to the refine panel list
    useEffect(() => {
        remove();
        if (mapState.graphFilters) {
            mapState.graphFilters.map((NodeFilter) => {
                append(NodeFilter);
            });
        }
    }, [append, mapState.graphFilters, remove]);

    const onSubmit: SubmitHandler<GraphFilterForm> = useCallback(
        (data) => {
            dispatch({ type: 'apply-graph-filter', filters: data.filters });
            reset(data);
        },
        [dispatch, reset],
    );

    const addFilter = () => {
        const temp: GraphFilter = {
            label: 'target',
            property: 'displayName',
            operation: 'contains',
            value: '',
        };
        append(temp);
    };

    return (
        <div
            className={classNames('flex items-center justify-center w-192', filterOpen ? '' : 'hidden')}
            id="filter-widget"
        >
            <div className="w-full rounded-md bg-gray-800 border border-gray-400 border-opacity-60 text-xs text-gray-200 hover:border-gray-400  pointer-events-auto">
                <button
                    onClick={() => dispatch({ type: 'toggle-filter' })}
                    className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-2.5 -right-2.5 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                >
                    <XMarkIcon className="h-3.5 w-3.5 text-gray-200" />
                </button>
                <div className="p-4 text-xs text-gray-400 overflow-hidden">
                    <h2 className="uppercase tracking-wider font-bold text-xs text-gray-500">Filter Criteria</h2>
                    <div className="flex flex-col space-y-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="space-y-4 mt-3" data-test="filter-list">
                                {fields.map((field, index) => {
                                    return (
                                        <FilterInput
                                            key={field.id}
                                            idx={index}
                                            register={register}
                                            remove={remove}
                                            watch={watch}
                                            update={update}
                                        />
                                    );
                                })}
                                {errors.filters && <div className="mt-4 text-red-500">Please enter a valid filter</div>}
                                <div className="flex items-center justify-between">
                                    <div>
                                        {fields.length < 3 && (
                                            <button
                                                data-test="add-filter"
                                                type="button"
                                                className="btn rounded-md text-xs pl-2 pr-3"
                                                onClick={addFilter}
                                            >
                                                <PlusIcon className="h-4 w-4 mr-1" /> Add Filter
                                            </button>
                                        )}
                                    </div>
                                    <button
                                        data-test="apply-filter"
                                        type="submit"
                                        disabled={!isValid}
                                        className={classNames(
                                            'btn rounded-md text-xs',
                                            isValid ? 'btn-primary' : '',
                                            !isValid ? 'opacity-60' : '',
                                        )}
                                    >
                                        Apply Filter
                                    </button>
                                </div>
                                {mapState.graphData.nodes.length == 0 &&
                                    mapState.unfilteredGraphData &&
                                    mapState.unfilteredGraphData.nodes.length > 0 && (
                                        <div className="mt-4 text-right">No results</div>
                                    )}
                                {mapState.graphData.nodes.length > 0 &&
                                    mapState.unfilteredGraphData &&
                                    mapState.unfilteredGraphData.nodes.length > 0 && (
                                        <div className="mt-4 text-right">
                                            Filter returned {mapState.graphData.nodes.length} nodes and{' '}
                                            {mapState.graphData.links.length} edges
                                        </div>
                                    )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
