import { Node, Link, PolicyStats } from 'Types/types';
import { LinkProps, LinkTags, NodeAttributes, NodeGroupingData, NodeProps, NodeTags } from 'Types/types';
import { deviceTypeNameLookup, deviceTypes, getDisplayName } from 'Utilities/utils';
import { getIconSourceURL, getNodeIconElement } from './Icons';

export type BackendNode = {
    nodeId: string;
    label: string;
    props: NodeProps;
    tags: NodeTags[];
    attributes?: NodeAttributes[];
    groupingData?: NodeGroupingData;
    currentLevelTrail?: string;
    hasNextLevel?: boolean;
    nextLevelBase64?: string;
    nodeType?: string;
};

type BackendEdge = {
    edgeId: string;
    label: string;
    inV: string;
    outV: string;
    tags: LinkTags[];
    props: LinkProps;
};

// Convert the output of the backend representation of the Identity Map graph
// to the format expected by the react-graph-vis library as used by the front end.
export const transformData = (nodes: [], edges: [], maxEdgesToLoad?: number): [nodes: Node[], links: Link[]] => {
    let tmpNodes: Node[] = [];
    const tmpNodeMap: { [key: string]: Node } = {};
    let tmpLinks: Link[] = [];

    nodes.map((n: BackendNode) => {
        const node = parseBackendNode(n);

        tmpNodes.push(node);
        tmpNodeMap[n.nodeId] = node;
    });

    const seenEventIds = new Set<string>();

    edges.map((e: BackendEdge) => {
        // in some cases, the backend will return duplicate edges - typically if a
        // provider has been removed/added without proper cleanup
        // todo: remove this check once the backend is fixed
        if (e.label === 'accesses') {
            if (e.props && e.props.eventId) {
                if (seenEventIds.has(e.props.eventId)) {
                    return;
                }
                seenEventIds.add(e.props.eventId);
            }
        }
        const target = tmpNodeMap[e.inV.toString()];
        const source = tmpNodeMap[e.outV.toString()];

        const link: Link = {
            index: e.edgeId,
            source: source,
            target: target,
            label: e.label,
            props: { ...e.props },
            tags: [...e.tags],
        };

        if (target && source) {
            if (target.neighbors.indexOf(source) == -1) target.neighbors.push(source);
            if (source.neighbors.indexOf(target) == -1) source.neighbors.push(target);
            target.links.push(link);
            source.links.push(link);
        }
        tmpLinks.push(link);
    });

    // Set up identity displayNames by concatenating the actor and device neighbors
    tmpNodes.forEach((n: Node) => {
        if (n.label === 'identity') {
            const actor = getNode(n.neighbors, 'actor');
            const device = getNode(n.neighbors, 'device');
            const application = getNode(n.neighbors, 'application');
            if (actor && device) {
                const actorName = getDisplayName(actor);
                const deviceName = getDisplayName(device);
                n.props.displayName = `${actorName} on ${deviceName}`;
            }
            n.identityComponents = {
                actor: Boolean(actor),
                device: Boolean(device),
                application: Boolean(application),
            };
        }
    });

    // Create the policy stats for all targets and links
    //
    // The target stats are a cumulative sum of the policy stats for all links
    //
    // The link stats are more complicated, we need to loop through all the links
    // finding those that share the same source identity then calculate the cumulative
    // total for that pair (identity, target). We then must loop through all the links
    // for a second time assign the cumulative stats to all the links as we cannot
    // predict which link will be selected by force-graph to display on the map
    tmpNodes.forEach((n: Node) => {
        const nodeStats = {
            critical: 0,
            warning: 0,
            success: 0,
            neutral: 0,
        };

        const linkStatsMap: Record<string, PolicyStats> = {};

        if (n.label === 'target') {
            n.links.forEach((l: Link) => {
                const identity = l.target;

                const id = getIdFromNode(identity);

                const linkStats = linkStatsMap[id] || { critical: 0, warning: 0, success: 0, neutral: 0 };

                linkStats.success += l.props.OUTCOME_ALLOW ? parseInt(l.props.OUTCOME_ALLOW, 10) : 0;
                linkStats.success += l.props.OUTCOME_SUCCESS ? parseInt(l.props.OUTCOME_SUCCESS, 10) : 0;

                linkStats.warning += l.props.OUTCOME_CHALLENGE ? parseInt(l.props.OUTCOME_CHALLENGE, 10) : 0;
                linkStats.warning += l.props.OUTCOME_SKIPPED ? parseInt(l.props.OUTCOME_SKIPPED, 10) : 0;

                linkStats.critical += l.props.OUTCOME_FAILURE ? parseInt(l.props.OUTCOME_FAILURE, 10) : 0;
                linkStats.critical += l.props.OUTCOME_DENY ? parseInt(l.props.OUTCOME_DENY, 10) : 0;

                linkStats.neutral += l.props.OUTCOME_UNKNOWN ? parseInt(l.props.OUTCOME_UNKNOWN, 10) : 0;

                nodeStats.success += linkStats.success;
                nodeStats.neutral += linkStats.neutral;
                nodeStats.warning += linkStats.warning;
                nodeStats.critical += linkStats.critical;

                linkStatsMap[id] = linkStats;
            });

            n.links.forEach((l: Link) => {
                const identity = l.target;
                const id = getIdFromNode(identity);
                const linkStats = linkStatsMap[id];
                l.policyStatsAbsolute = linkStats;
                l.policyStats = convertStatsToPercent(linkStats);
            });

            n.policyStats = convertStatsToPercent(nodeStats);
            n.policyStatsAbsolute = nodeStats;
        }
    });

    if (maxEdgesToLoad) {
        const seenNodeIds = new Set<string | number>();

        tmpLinks = tmpLinks.slice(0, maxEdgesToLoad);

        tmpLinks.forEach((l: Link) => {
            const source = l.source;
            const target = l.target;

            seenNodeIds.add(source.toString());
            seenNodeIds.add(target.toString());
        });

        tmpNodes = tmpNodes.filter((n) => seenNodeIds.has(n.id));
    }

    return [tmpNodes, tmpLinks];
};

const convertStatsToPercent = (stats: PolicyStats) => {
    const total = stats.critical + stats.warning + stats.success + stats.neutral;
    return {
        critical: Math.round((stats.critical / total) * 100),
        warning: Math.round((stats.warning / total) * 100),
        success: Math.round((stats.success / total) * 100),
        neutral: Math.round((stats.neutral / total) * 100),
    };
};

const getNode = (nodes: Node[], label: string): Node | undefined => {
    return nodes.find((n: Node) => n.label === label);
};

const getIdFromNode = (node: Node | string | number): string => {
    let id;
    if (typeof node === 'string') {
        id = node;
    } else if (typeof node === 'number') {
        id = node.toString();
    } else {
        id = node.id.toString();
    }
    return id;
};

export const parseBackendNode = (n: BackendNode): Node => {
    const node: Node = {
        id: n.nodeId,
        name: n.nodeId,
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        group: n.label,
        label: n.label,
        props: { ...n.props },
        tags: [...n.tags],
        attributes: n.attributes ? [...n.attributes] : [],
        groupingData: { ...n.groupingData },
        currentLevelTrail: n.currentLevelTrail,
        hasNextLevel: n.hasNextLevel,
        nextLevelBase64: n.nextLevelBase64,
        nodeType: n.nodeType,
        trustData: generateDataSeries(),
    };

    node.name = getDisplayName(node);

    if (!node.props.displayName) {
        node.props.displayName = node.name;
    }

    // Convert the device type display name (e.g. 'DEVICE_COMPUTER' to 'Computer')
    if (n.label === 'device') {
        if (n.props.deviceType) {
            node.props.displayName = deviceTypeNameLookup(n.props.deviceType as keyof deviceTypes);
        }
    }
    // Annotate the Mailbox target to help distinguish from actors with the same name/email
    if (n.label === 'target') {
        if (n.nodeType && n.nodeType === 'NODE_TYPE_MAILBOX') {
            node.props.displayName = `Mailbox for ${node.props.displayName}`;
        }
    }

    node.icon = getIconSourceURL(getNodeIconElement(node));
    return node;
};

export const generateDataSeries = (): number[] => {
    const length = 24;
    const dataPoints: number[] = [];
    let currentValue = Math.floor(Math.random() * 10 + 80); // Random start between 80 and 90, rounded to nearest integer
    dataPoints.push(currentValue);

    const trendSlope = 0.5; // Adjust this for a steeper or shallower overall trend

    for (let i = 1; i < length; i++) {
        // Random change for walk
        const change = Math.random() * 10 - 5;
        currentValue += change;

        // Add increased noise with trend
        const noise = Math.random() * 10 - 5;
        currentValue += noise + trendSlope;

        // Clamping the value between 50 and 100, and rounding to nearest integer
        currentValue = Math.max(50, Math.min(100, currentValue));
        currentValue = Math.round(currentValue);

        dataPoints.push(currentValue);
    }

    // Adjust final value to be between 80 and 100, rounded to nearest integer
    dataPoints[length - 1] = Math.round(Math.random() * 20 + 80);

    return dataPoints;
};
