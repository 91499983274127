import { useState } from 'react';
import { classNames } from 'Utilities/utils';

export const ChartTooltip = ({ payload }: any): JSX.Element => {
    return (
        <div className="bg-gray-900 p-4 rounded-lg outline-none active:outline-none focus:outline-none">
            {payload && payload.length
                ? payload.map((bar: { name: string; value: number }) => (
                      <p className="label">{`${bar.name} : ${bar.value}`}</p>
                  ))
                : ''}
        </div>
    );
};

export const ChartXAxis = ({ x, y, payload }: any): JSX.Element => {
    const label = payload.value;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={8} fill="rgba(255,255,255,0.5)">
                <tspan textAnchor="middle" x="0" fontSize="11">
                    {label && label.split(/,(.*)/)[0]}
                </tspan>
                <tspan textAnchor="middle" x="0" dy="12" fontSize="11">
                    {label && label.split(/,(.*)/)[1]}
                </tspan>
            </text>
        </g>
    );
};

export const ChartLegend = ({ payload, onClick }: any): JSX.Element => {
    return (
        <div className="btn bg-gray-600 hover:bg-gray-600 rounded-md inline-flex overflow-hidden p-0 absolute left-[50%] translate-x-[calc(-50%+5px)] divide-x divide-gray-500">
            {payload.map((entry: any, index: number) => {
                const { dataKey, value, color } = entry;
                return <ChartLegendItem key={index} value={value} dataKey={dataKey} color={color} onClick={onClick} />;
            })}
        </div>
    );
};

const ChartLegendItem = ({ dataKey, value, color, onClick }: any): JSX.Element => {
    const [disabled, setDisabled] = useState(false);
    return (
        <div
            className={classNames(
                'px-1.5 py-[4px] flex items-center hover:bg-gray-500 text-[10px]',
                disabled ? 'bg-gray-900 opacity-50 hover:bg-gray-900' : 'bg-gray-600',
            )}
            onClick={() => {
                setDisabled(!disabled);
                onClick(dataKey, disabled);
            }}
        >
            <div
                className="block h-3 w-3 rounded-sm mr-2"
                style={{ background: disabled ? 'rgb(156 163 175)' : color }}
            />
            {value ? value : dataKey}
        </div>
    );
};
