import { ToastContext } from 'Map/Components/ToastContext';
import { useCallback, useContext } from 'react';

export const useToasts = () => {
    const { dispatch: toastDispatch } = useContext(ToastContext);

    return {
        addToast: useCallback(
            (
                message: string,
                status: 'success' | 'warning' | 'failure' | 'information' | 'unknown',
                autoTimeout = true,
                timeoutTimer = 5,
            ) => {
                toastDispatch({
                    type: 'add-toast',
                    message,
                    status,
                    autoTimeout,
                    timeoutTimer,
                });
            },
            [toastDispatch],
        ),
    };
};
