import { useQuery } from '@apollo/client';
import { ArrowsPointingOutIcon, EllipsisHorizontalIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { StatsEntityType } from 'GeneratedGQL/graphql';
import { GET_ENTITY_PROFILE } from 'Graph/typedQueries';
import { useLocalStorage, useTenant } from 'Hooks/Hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tooltip } from 'Library/Tooltip';
import { getIconSourceURL, getNodeIconElement, getTagIconElement } from 'Map/Graph/Icons';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { Resizable } from 're-resizable';
import React, { useContext, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import {
    getDisplayName,
    getEntityType,
    getOperatingSystemDisplayNameFromNode,
    providerNameLookup,
    tagNameLookup,
    wordWrap,
} from 'Utilities/utils';

type ProfileHeaderProps = {
    title: string;
    node: Node;
    displayContextMenu: (e: React.MouseEvent) => void;
    startDate: number;
    endDate: number;
};

export const ProfileHeader = ({ title, node, displayContextMenu, startDate, endDate }: ProfileHeaderProps) => {
    const tenantId = useTenant();

    const { data, error } = useQuery(GET_ENTITY_PROFILE, {
        variables: {
            tenantId: tenantId || '',
            entity: getEntityType(node) as StatsEntityType,
            entityId: String(node?.id) || '',
            startDate: startDate,
            endDate: endDate,
        },
        skip: !tenantId || !node,
    });

    let angle = 0.785;
    // how many radians between each tag
    const numberOfTags = node.tags.length;
    const step = (2 * Math.PI) / numberOfTags;

    return (
        <div>
            <div className="flex items-center justify-between">
                <h2 className="text-xs text-gray-500 flex uppercase tracking-widest font-bold mb-3">{title}</h2>
            </div>
            <div className="flex items-center justify-between">
                <div className="flex flex-row space-x-3">
                    <div className="flex-shrink-0 items-center relative">
                        <div className="h-12 w-12 rounded-full bg-gray-900 border border-gray-700 p-2 flex items-center justify-center mr-2">
                            {node.label == 'actor' ? (
                                <Avatar size="100%" name={getDisplayName(node)} round={true} maxInitials={2} />
                            ) : (
                                <img src={getIconSourceURL(getNodeIconElement(node))} />
                            )}
                        </div>
                        {node.tags.map((tag) => {
                            const tagElement = (
                                <div
                                    key={tag}
                                    className={`rounded-full border border-gray-700 bg-gray-800 p-1 flex place-content-center absolute`}
                                    style={{
                                        // center the tag in the middle of the 00 icon
                                        top: '12px',
                                        left: '12px',
                                        // rotate the tag to the correct angle then translate to the edge of the 00 icon
                                        transform: `rotate(${angle}rad) translateX(28px)`,
                                    }}
                                >
                                    <img
                                        key={tag}
                                        src={getIconSourceURL(getTagIconElement(tag))}
                                        style={{
                                            // the actual tag icon will be incorrectly rotated after the parent transform, so we need to
                                            // inverse rotate the tag icon to counteract the parent transform
                                            transform: `rotate(-${angle}rad)`,
                                        }}
                                        alt={tagNameLookup(tag)}
                                        title={tagNameLookup(tag)}
                                        className="h-4 w-4"
                                    />
                                </div>
                            );
                            angle += step;
                            return tagElement;
                        })}
                    </div>
                    <div>
                        <div className="flex items-center">
                            <Tooltip
                                label={
                                    (node.label == 'actor' && node.props.alternateId != '' && node.props.alternateId) ||
                                    (node.label == 'target' &&
                                        node.props.serviceDomain != '' &&
                                        node.props.serviceDomain) ||
                                    ''
                                }
                            >
                                <h3
                                    dangerouslySetInnerHTML={wordWrap(getDisplayName(node))}
                                    className="text-xs leading-5 font-medium text-gray-300 word-wrap inline"
                                ></h3>
                            </Tooltip>
                            <Tooltip label="Show Actions">
                                <button
                                    id="Menu"
                                    type="button"
                                    onClick={displayContextMenu}
                                    className="btn text-xs rounded-md p-0 block ml-2 mb-[1.5px]"
                                >
                                    <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
                                </button>
                            </Tooltip>
                        </div>
                        <div className="text-xs text-gray-500">
                            {node.tags.map((tag) => {
                                return (
                                    <p className="block" key={tag}>
                                        {tagNameLookup(tag)}
                                    </p>
                                );
                            })}
                        </div>
                        {node.label === 'device' && (
                            <div className="text-xs text-gray-500">{getOperatingSystemDisplayNameFromNode(node)}</div>
                        )}
                        <div className="text-xs text-gray-500">
                            {data && data.getEntityProfile && data.getEntityProfile.providers && (
                                <span>
                                    Observed through{' '}
                                    {data.getEntityProfile.providers
                                        .map((provider: string) => providerNameLookup(provider))
                                        .join(', ')}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="min-w-[120px]">
                    <p className="text-xs text-gray-500">First Access</p>
                    <div className="text-xs text-white whitespace-nowrap">
                        {data && data.getEntityProfile ? (
                            format(new Date(data.getEntityProfile.firstAccessed), `MMM dd yyy, HH:mm`)
                        ) : error ? (
                            <div className="h-[16px]" />
                        ) : (
                            <div className="space-x-1 flex justify-start items-center h-[16px]">
                                <div
                                    className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                                    style={{ animationDelay: '0.15s' }}
                                ></div>
                                <div
                                    className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                                    style={{ animationDelay: '0.30s' }}
                                ></div>
                                <div
                                    className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                                    style={{ animationDelay: '0.45s' }}
                                ></div>
                            </div>
                        )}
                    </div>
                    <p className="text-xs text-gray-500">Last Access</p>
                    <div className="text-xs text-white whitespace-nowrap">
                        {data && data.getEntityProfile ? (
                            format(new Date(data.getEntityProfile.lastAccessed), `MMM dd yyy, HH:mm`)
                        ) : error ? (
                            <div className="h-[16px]" />
                        ) : (
                            <div className="space-x-1 flex justify-start items-center h-[16px]">
                                <div
                                    className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                                    style={{ animationDelay: '0.15s' }}
                                ></div>
                                <div
                                    className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                                    style={{ animationDelay: '0.30s' }}
                                ></div>
                                <div
                                    className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                                    style={{ animationDelay: '0.45s' }}
                                ></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

type ProfilePanelProps = {
    children: JSX.Element | JSX.Element[];
};

export const ProfilePanel = ({ children }: ProfilePanelProps) => {
    const { enableMaxProfile } = useFlags();
    const { dispatch } = useContext(IdentityMapContext);
    const { getScopedStorageKey } = useLocalStorage();
    const key = getScopedStorageKey('profileWidth');
    const [profileWidth, setProfileWidth] = useState<string | undefined>();
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (key) {
            const width = localStorage.getItem(key);
            if (width) {
                setProfileWidth(width);
            } else {
                setProfileWidth('650px');
            }
        }
    }, [key]);

    if (!profileWidth) {
        return <></>;
    }

    return (
        <div className="flex items-center justify-center" id="actor-profile">
            <Resizable
                onResizeStop={(_event, _direction, elementRef) => {
                    const width = elementRef.style.width;
                    setProfileWidth(width);
                    if (key) {
                        localStorage.setItem(key, width);
                    }
                }}
                defaultSize={{
                    width: profileWidth,
                    height: 'auto',
                }}
                handleStyles={
                    hover
                        ? {
                              left: {
                                  marginTop: -5,
                                  marginLeft: -3,
                                  top: '50%',
                                  left: 0,
                                  cursor: 'ew-resize',
                                  border: '3px solid #999',
                                  borderTop: 'none',
                                  borderRight: 'none',
                                  borderBottom: 'none',
                                  borderWidth: 5,
                                  borderColor: 'rgb(156 163 175)',
                                  width: 10,
                                  height: 20,
                                  boxSizing: 'border-box',
                                  zIndex: 1,
                              },
                          }
                        : {}
                }
                maxWidth="700px"
                minWidth="480px"
                enable={{
                    top: false,
                    right: false,
                    bottom: false,
                    left: true,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                }}
            >
                <div
                    onMouseEnter={() => {
                        setHover(true);
                    }}
                    onMouseLeave={() => {
                        setHover(false);
                    }}
                    className="w-full rounded-md bg-gray-800 border border-gray-400 border-opacity-60 text-xs text-gray-200 hover:border-gray-400  pointer-events-auto"
                >
                    {children}

                    {enableMaxProfile && (
                        <button
                            onClick={() => dispatch({ type: 'toggle-max-profile' })}
                            className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-2.5 right-4 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                        >
                            <ArrowsPointingOutIcon className="h-3.5 w-3.5 text-gray-200" />
                        </button>
                    )}

                    <button
                        onClick={() => dispatch({ type: 'close-profile' })}
                        className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-2.5 -right-2.5 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                    >
                        <XMarkIcon className="h-3.5 w-3.5 text-gray-200" />
                    </button>
                </div>
            </Resizable>
        </div>
    );
};
