import { Dispatch, SetStateAction } from 'react';
import logo from 'assets/logo-192x192.png';
import { SignUpStep } from './SignUpTypes';

interface Props {
    setStep: Dispatch<SetStateAction<SignUpStep>>;
}

const TermsOfService = ({ setStep }: Props): JSX.Element => {
    return (
        <>
            <img className="h-12 w-auto" src={logo} alt="SailPoint Identity Risk" />
            <h2 className="text-3xl font-extrabold text-gray-200">SailPoint Identity Risk</h2>
            <p className="mt-2 text-sm text-gray-400">About</p>
            <a
                onClick={() => setStep(SignUpStep.Form)}
                className="mt-2 text-sm font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
            >
                Return
            </a>
            <hr className="mt-6 mb-6" />
            <div className="mt-6 space-y-8">
                <p className="text-xs text-gray-300 overflow-scroll h-192">
                    Copyright © 2024 SailPoint Technologies, Inc. All Rights Reserved.
                    <br />
                    <br />
                    All logos, text, content, including underlying HTML code, designs, and graphics used and/or depicted
                    on these written materials or in this Internet website are protected under United States and
                    international copyright and trademark laws and treaties, and may not be used or reproduced without
                    the prior express written permission of SailPoint Technologies, Inc.
                    <br />
                    <br />
                    “SailPoint Technologies” (design and word mark), “SailPoint” (design and word mark), “IdentityIQ,”
                    “IdentityNow,” “IdentityAI”, “Identity Cube” and “SailPoint Predictive Identity” are registered
                    trademarks of SailPoint Technologies, Inc. “Identity is Everything,” “The Power of Identity,”
                    “Navigate” and “Identity University” are trademarks of SailPoint Technologies, Inc. None of the
                    foregoing marks may be used without the prior express written permission of SailPoint Technologies,
                    Inc.
                    <br />
                    <br />
                    Patents:{' '}
                    <a href="https://www.sailpoint.com/legal/patents/">https://www.sailpoint.com/legal/patents/</a>
                </p>
            </div>
        </>
    );
};

export default TermsOfService;
