import { Menu, Item, theme, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useGraphControls } from 'Hooks/GraphHooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isAGroupLeafNode, isATargetGroup } from 'Utilities/NodeUtilities';
import { useContext } from 'react';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import {
    DocumentArrowDownIcon,
    ArrowsPointingOutIcon,
    LockOpenIcon,
    ChevronDoubleLeftIcon,
    FunnelIcon,
    LockClosedIcon,
    DocumentChartBarIcon,
    ArrowUturnLeftIcon,
    MapPinIcon,
    Squares2X2Icon,
    XCircleIcon,
    MinusCircleIcon,
    PlusCircleIcon,
    Square3Stack3DIcon,
    AdjustmentsVerticalIcon,
} from '@heroicons/react/24/outline';
import { CursorArrowRaysIcon } from '@heroicons/react/24/solid';
import { classNames, groupingForceApplied } from 'Utilities/utils';
import { Portal } from 'react-portal';

export const ContextMenu = ({
    id,
    node,
    onHidden,
    className,
}: {
    id: string | number;
    node?: Node | null;
    className?: string;
    onHidden?: () => void;
}): JSX.Element => {
    const { policy, enableNavigator } = useFlags();
    const {
        addNodeToExplorer,
        isNodeInExplorer,
        removeNodeFromExplorer,
        isNodeOnMap,
        expandGroupNode,
        collapseGroupNode,
        filterByNode,
        toggleNodeLock,
        zoomGraph,
        centerGraph,
        unlockAllNodes,
        unFilterAllNodes,
        unGroupAllNodes,
        lockSelectedNodes,
        selectAllNodes,
        gridTargets,
        undoLastAction,
        generateMapEventsCSV,
        generateMapObjectsCSV,
    } = useGraphControls();
    const { dispatch, mapState } = useContext(IdentityMapContext);

    const viewTrustProfile = (): void => {
        if (node) {
            dispatch({ type: 'set-profile-node', node: node });
            dispatch({ type: 'set-profile-window', open: true });
        }
    };

    const viewTrustPolicy = (): void => {
        if (node) {
            dispatch({ type: 'set-selected-policy-target', target: node });
        }
    };

    const addAdaptiveTrustPolicyToTarget = (): void => {
        if (node) {
            dispatch({ type: 'set-selected-policy-target', target: node });
        }
    };

    const setProfileNode = (node: Node): void => {
        if (node.label == 'actor' || (node.label == 'target' && !isATargetGroup(node))) {
            dispatch({ type: 'set-profile-node', node: node });
            dispatch({ type: 'set-profile-window', open: true });
            if (mapState.dashboardOpen) {
                dispatch({ type: 'toggle-dashboard' });
            }
        }
    };

    if (!node) {
        return (
            <Portal>
                <Menu
                    id={id}
                    theme={theme.dark}
                    animation={''}
                    className={classNames(className ? className : '', 'fixed z-[9000]')}
                    onHidden={onHidden}
                >
                    <Item
                        onClick={() => {
                            undoLastAction();
                        }}
                        className="text-xs"
                    >
                        <ArrowUturnLeftIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        Undo last selection
                    </Item>
                    <Separator />

                    {mapState.selectedNodes.size > 0 && (
                        <>
                            <Item
                                onClick={() => {
                                    lockSelectedNodes();
                                }}
                                className="text-xs"
                            >
                                <LockClosedIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                                Lock selected nodes
                            </Item>
                        </>
                    )}

                    {mapState.lockedNodes.size > 0 && (
                        <Item
                            id="Unlock All Nodes"
                            onClick={() => {
                                unlockAllNodes();
                            }}
                            className="text-xs"
                        >
                            <LockOpenIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Unlock all nodes
                        </Item>
                    )}
                    {mapState.graphFilters && mapState.graphFilters.length > 0 && (
                        <Item
                            id="Unfilter all nodes"
                            onClick={() => {
                                unFilterAllNodes();
                            }}
                            className="text-xs"
                        >
                            <XCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Clear all filters
                        </Item>
                    )}
                    {groupingForceApplied(mapState.group) && (
                        <Item
                            id="Ungroup all nodes"
                            onClick={() => {
                                unGroupAllNodes();
                            }}
                            className="text-xs"
                        >
                            <XCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Clear all groupings
                        </Item>
                    )}
                    {mapState.lockedNodes.size > 0 ||
                    (mapState.graphFilters && mapState.graphFilters.length > 0) ||
                    groupingForceApplied(mapState.group) ? (
                        <Separator />
                    ) : (
                        ''
                    )}
                    <Item
                        id="Select all nodes"
                        onClick={() => {
                            selectAllNodes();
                        }}
                        className="text-xs"
                    >
                        <CursorArrowRaysIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        Select all nodes
                    </Item>
                    <Item
                        onClick={() => {
                            gridTargets();
                        }}
                        className="text-xs"
                    >
                        <Squares2X2Icon className="mr-2 h-4 w-4 text-white" />
                        Align all targets as grid
                    </Item>
                    <Item
                        id="Center map"
                        onClick={() => {
                            centerGraph();
                        }}
                        className="text-xs"
                    >
                        <MapPinIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        Center map to window
                    </Item>
                    <Item
                        id="Zoom to fit"
                        onClick={() => {
                            zoomGraph();
                        }}
                        className="text-xs"
                    >
                        <ArrowsPointingOutIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        Zoom map to fit window
                    </Item>
                    <Separator />
                    <Item id="Export visible events as CSV" className="text-xs" onClick={generateMapEventsCSV}>
                        <DocumentArrowDownIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        Export visible events as CSV
                    </Item>
                    <Item id="Export visible objects as CSV" className="text-xs" onClick={generateMapObjectsCSV}>
                        <DocumentArrowDownIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        Export visible objects as CSV
                    </Item>
                </Menu>
            </Portal>
        );
    } else {
        return (
            <Portal>
                <Menu
                    id={id}
                    theme={theme.dark}
                    animation={''}
                    className={classNames(className ? className : '', 'fixed z-[9000]')}
                    onHidden={onHidden}
                >
                    {(node.label == 'actor' ||
                        node.label == 'device' ||
                        (node.label == 'target' && !isATargetGroup(node))) && (
                        <>
                            <Item
                                onClick={() => {
                                    setProfileNode(node);
                                    if (mapState.navigatorOpen) {
                                        dispatch({ type: 'toggle-navigator' });
                                    }
                                }}
                                className="text-xs"
                            >
                                <DocumentChartBarIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                                View profile...
                            </Item>
                            <Separator />
                        </>
                    )}
                    {enableNavigator &&
                        (node.label == 'actor' || (node.label == 'target' && !isATargetGroup(node))) && (
                            <>
                                <Item
                                    onClick={() => {
                                        dispatch({ type: 'set-permissions-node', node: node });
                                        if (!mapState.navigatorOpen) {
                                            dispatch({ type: 'toggle-navigator' });
                                        }
                                    }}
                                    className="text-xs"
                                >
                                    <Square3Stack3DIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                                    View permissions...
                                </Item>
                                <Separator />
                            </>
                        )}
                    {policy && (
                        <>
                            {node.label == 'actor' && (
                                <Item onClick={viewTrustProfile} className="text-xs">
                                    <AdjustmentsVerticalIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                                    View trust profile...
                                </Item>
                            )}
                            {node.label == 'target' && !isATargetGroup(node) && (
                                <>
                                    <Item onClick={viewTrustPolicy} className="text-xs">
                                        <AdjustmentsVerticalIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                                        View adaptive trust policies...
                                    </Item>
                                    <Item onClick={addAdaptiveTrustPolicyToTarget} className="text-xs">
                                        <PlusCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                                        Add adaptive trust policy
                                    </Item>
                                </>
                            )}
                            <Separator />
                        </>
                    )}
                    {isNodeInExplorer(node) ? (
                        <Item
                            onClick={() => {
                                removeNodeFromExplorer(node);
                            }}
                            className="text-xs"
                        >
                            <MinusCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Remove from explorer list
                        </Item>
                    ) : (
                        <>
                            <Item
                                onClick={() => {
                                    addNodeToExplorer(node);
                                }}
                                className="text-xs"
                            >
                                <PlusCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                                Add to explorer list
                            </Item>
                            <Item
                                onClick={() => {
                                    addNodeToExplorer(node, true);
                                }}
                                className="text-xs"
                            >
                                <PlusCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                                Add to explorer and search
                            </Item>
                        </>
                    )}

                    {isNodeOnMap(node) && isATargetGroup(node) && (
                        <Item
                            onClick={() => {
                                expandGroupNode(node);
                            }}
                            className="text-xs"
                        >
                            <ArrowsPointingOutIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Expand group node
                        </Item>
                    )}
                    {isNodeOnMap(node) && isAGroupLeafNode(node) && (
                        <Item
                            id="Minimize"
                            onClick={() => {
                                collapseGroupNode(node);
                            }}
                            className="text-xs"
                        >
                            <ChevronDoubleLeftIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Collapse group node
                        </Item>
                    )}
                    {mapState.lockedNodes.has(node) && isNodeOnMap(node) ? (
                        <Item
                            id="Unlock"
                            onClick={() => {
                                toggleNodeLock(node);
                            }}
                            className="text-xs"
                        >
                            <LockOpenIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Unlock this node
                        </Item>
                    ) : !mapState.lockedNodes.has(node) && isNodeOnMap(node) ? (
                        <Item
                            id="Lock"
                            onClick={() => {
                                toggleNodeLock(node);
                            }}
                            className="text-xs"
                        >
                            <LockClosedIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Lock this node
                        </Item>
                    ) : (
                        <></>
                    )}
                    {isNodeOnMap(node) && (
                        <Item
                            id="Filter"
                            onClick={() => {
                                filterByNode(node);
                            }}
                            className="text-xs"
                        >
                            <FunnelIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                            Filter by this node...
                        </Item>
                    )}
                </Menu>
            </Portal>
        );
    }
};
