import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { getDisplayName, classNames, wordWrap } from 'Utilities/utils';
import { ProfilePanel } from './Library/ProfileComponents';
import { ContextMenu } from 'Map/Components/ContextMenu';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { getIconSourceURL, getNodeIconElement } from 'Map/Graph/Icons';
import { Tooltip } from 'Library/Tooltip';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { PanelItem } from 'Configuration/Panels/Panel';
import { ComposableMap, ZoomableGroup, Geographies, Geography } from 'react-simple-maps';
import WorldCountriesGeos from 'Dashboard/Charts/WorldCountriesGeos.json';

export const QueryProfile = () => {
    const { mapState } = useContext(IdentityMapContext);

    const { selectedProfileNode: node } = mapState;

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [view, setView] = useState<'definition' | 'location'>('definition');

    const { show } = useContextMenu({
        id: 'profile',
    });

    const displayContextMenu = (e: TriggerEvent & { clientX: number; clientY: number }) => {
        // prevent default List click action
        e.stopPropagation();
        e.preventDefault();

        // show context menu
        show(e, {
            position: {
                x: e.clientX,
                y: e.clientY,
            },
        });
    };

    const geojson = useMemo(() => {
        if (node && node.queryAttributes?.location) {
            const { coordinates } = node.queryAttributes?.location;

            return {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: coordinates,
                        },
                    },
                ],
            };
        }

        return undefined;
    }, [node]);

    const { latitude, longitude, radius } = node?.queryAttributes?.location || { latitude: 0, longitude: 0, radius: 0 };
    const radiusKm = radius / 1000;

    let pillTranslate = 'translate-x-0';
    switch (view) {
        case 'definition':
            pillTranslate = 'translate-x-0';
            break;
        case 'location':
            pillTranslate = 'translate-x-20';
    }

    if (!node) {
        return <></>;
    }

    return (
        <ProfilePanel>
            <Helmet>
                <title>Profile | {getDisplayName(node)}</title>
            </Helmet>
            <div
                data-test="profile-window"
                className={classNames('p-3', isMobile ? 'overflow-y-auto h-[calc(100vh-328px)]' : '')}
                onContextMenu={displayContextMenu}
            >
                <ContextMenu id={'profile'} node={node} />
                <div>
                    <div className="flex items-center justify-between">
                        <h2 className="text-xs text-gray-500 flex uppercase tracking-widest font-bold mb-3">
                            Query Profile
                        </h2>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-row space-x-3">
                            <div className="flex-shrink-0 items-center relative">
                                <div className="h-12 w-12 rounded-full bg-gray-900 border border-gray-700 p-2 flex items-center justify-center mr-2">
                                    <img src={getIconSourceURL(getNodeIconElement(node))} />
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <Tooltip
                                        label={
                                            'This is a query node. Query nodes are used to search for other nodes in the graph.'
                                        }
                                    >
                                        <h3
                                            dangerouslySetInnerHTML={wordWrap(getDisplayName(node))}
                                            className="text-xs leading-5 font-medium text-gray-300 word-wrap inline"
                                        ></h3>
                                    </Tooltip>
                                    <Tooltip label="Show Actions">
                                        <button
                                            id="Menu"
                                            type="button"
                                            onClick={displayContextMenu}
                                            className="btn text-xs rounded-md p-0 block ml-2 mb-[1.5px]"
                                        >
                                            <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="flex items-center justify-between">
                    <div className="flex rounded-full items-stretch justify-center my-3 bg-gray-700 relative">
                        <div
                            className={classNames(
                                'w-20 bg-blue-700 absolute left-0 top-0 text-xs p-1 rounded-full transition-all',
                                pillTranslate,
                            )}
                        >
                            &nbsp;
                        </div>

                        <p
                            onClick={() => {
                                setView('definition');
                            }}
                            className={classNames(
                                'text-xs w-20 flex justify-center p-1 px-2 rounded-l-full transition-all cursor-pointer z-10',
                                view == 'definition'
                                    ? 'text-white font-medium hover:text-white'
                                    : 'text-blue-600 hover:text-blue-600',
                            )}
                        >
                            Definition
                        </p>

                        <p
                            onClick={() => {
                                setView('definition');
                            }}
                            className={classNames(
                                'text-xs w-20 flex justify-center p-1 px-2 rounded-l-full transition-all cursor-pointer z-10',
                                view == 'definition'
                                    ? 'text-white font-medium hover:text-white'
                                    : 'text-blue-600 hover:text-blue-600',
                            )}
                        >
                            Location
                        </p>
                    </div>
                </div> */}

                <div className="bg-gray-800/50 mt-4">
                    {view == 'definition' && (
                        <div className="bg-gray-700 rounded-lg px-4 py-2">
                            <div className="flex justify-between items-center mb-2 mt-2">
                                <PanelItem title="Latitude">
                                    <p>{latitude.toFixed(4)}</p>
                                </PanelItem>
                                <PanelItem title="Longitude">
                                    <p>{longitude.toFixed(4)}</p>
                                </PanelItem>
                                <PanelItem title="Radius">
                                    <p>{radiusKm.toFixed(0)} km</p>
                                </PanelItem>
                                <PanelItem title="View in Maps">
                                    <button
                                        className=""
                                        onClick={() => {
                                            window.open(
                                                `https://www.google.com/maps/search/?api=1&query=${node.queryAttributes?.location.latitude},${node.queryAttributes?.location.longitude}`,
                                            );
                                        }}
                                    >
                                        Open
                                    </button>
                                </PanelItem>
                            </div>
                            <div>
                                <ComposableMap
                                    id="map"
                                    projection="geoMercator"
                                    projectionConfig={{
                                        scale: 180,
                                        rotate: [-10, 0, 0],
                                        center: [0, 18],
                                    }}
                                    style={{ width: '100%', height: '250px' }}
                                >
                                    <ZoomableGroup>
                                        <Geographies geography={WorldCountriesGeos}>
                                            {({ geographies }) =>
                                                geographies.map((geo) => {
                                                    const fill = '#6B7280';
                                                    const strokeWidth = 0.5;

                                                    return (
                                                        <Geography
                                                            key={geo.rsmKey}
                                                            geography={geo}
                                                            fill={fill}
                                                            fillOpacity={0.05}
                                                            stroke="#9DA3AD"
                                                            strokeWidth={strokeWidth}
                                                            style={{
                                                                default: { outline: 'none' },
                                                                hover: { outline: 'none' },
                                                                pressed: { outline: 'none' },
                                                            }}
                                                        />
                                                    );
                                                })
                                            }
                                        </Geographies>

                                        {geojson && (
                                            <Geographies geography={geojson}>
                                                {({ geographies }) =>
                                                    geographies.map((geo) => {
                                                        return (
                                                            <Geography
                                                                key={geo.rsmKey}
                                                                geography={geo}
                                                                fill={'#36a8fa'}
                                                                fillOpacity={0.7}
                                                                stroke="#36a8fa"
                                                                strokeOpacity={0.8}
                                                                style={{
                                                                    default: { outline: 'none' },
                                                                    hover: { outline: 'none' },
                                                                    pressed: { outline: 'none' },
                                                                }}
                                                            />
                                                        );
                                                    })
                                                }
                                            </Geographies>
                                        )}
                                    </ZoomableGroup>
                                </ComposableMap>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ProfilePanel>
    );
};
