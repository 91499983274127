import { NodeTypes } from 'reactflow';
import { Chip, GroupChip, HeaderChip, InfoChip, LoadMoreChip, SubGroupChip } from './Chip';

export const EDGE_COLOR = '#7e8793';

export const nodeTypes: NodeTypes = {
    chip: Chip,
    groupChip: GroupChip,
    header: HeaderChip,
    info: InfoChip,
    loadMore: LoadMoreChip,
    subGroupChip: SubGroupChip,
};

export const snapGrid: [number, number] = [260, 60];

export const nodeSpacing: Record<string, number> = {
    actor: 60,
    group: 70,
    policy: 80,
    target: 60,
};

export const nodeOffset: Record<string, { x: number; y: number }> = {
    actor: { x: 0, y: 0 },
    group: { x: 400, y: 0 },
    policy: { x: 800, y: 10 },
    target: { x: 1200, y: 0 },
};

export const nodeHeaderText: Record<string, string> = {
    actor: 'Actors',
    group: 'Groups',
    policy: 'Policies',
    target: 'Targets',
};
