import { useMemo, useReducer } from 'react';
import { initialToastState, reducer } from 'Map/State/ToastReducer';
import { ToastContext } from './ToastContext';

type ToastProviderProps = {
    children: JSX.Element;
};

export const ToastProvider = ({ children }: ToastProviderProps): JSX.Element => {
    const [toastState, dispatch] = useReducer(reducer, initialToastState);
    const contextValue = useMemo(() => {
        return { toastState, dispatch };
    }, [toastState, dispatch]);
    return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
};
