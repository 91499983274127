import { FunnelIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useGraphControls } from 'Hooks/GraphHooks';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext } from 'react';
import { Tooltip } from 'Library/Tooltip';

export const FilterIndicator = (): JSX.Element => {
    const { dispatch, mapState } = useContext(IdentityMapContext);
    const { unFilterAllNodes } = useGraphControls();

    return (
        <div className="flex items-center justify-center relative">
            <div className="bg-gray-800 text-blue-500 flex items-center justify-center text-xs p-1 rounded-md pointer-events-auto">
                <FunnelIcon
                    onClick={() => {
                        !mapState.filterOpen && dispatch({ type: 'toggle-filter' });
                    }}
                    className="h-5 w-5 text-blue-500 mr-2 cursor-pointer"
                />
                <Tooltip label="Click to see applied filters">
                    <div
                        className="mr-2 cursor-pointer"
                        onClick={() => {
                            !mapState.filterOpen && dispatch({ type: 'toggle-filter' });
                        }}
                    >
                        Map is filtered
                    </div>
                </Tooltip>
                <Tooltip label="Unfilter nodes">
                    <XMarkIcon
                        className="h-4 w-4 text-blue-500 cursor-pointer"
                        onClick={unFilterAllNodes}
                        data-test="global-remove-filter"
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export const GroupIndicator = (): JSX.Element => {
    const { dispatch, mapState } = useContext(IdentityMapContext);
    const { unGroupAllNodes } = useGraphControls();

    return (
        <div className="flex items-center justify-center relative">
            <div className="bg-gray-800 text-orange-500 flex items-center justify-center text-xs p-1 rounded-md pointer-events-auto">
                <PlusCircleIcon
                    onClick={() => {
                        !mapState.groupingOpen && dispatch({ type: 'toggle-grouping' });
                    }}
                    className="h-5 w-5 text-orange-500 mr-2 cursor-pointer"
                />
                <Tooltip label="Click to see applied groups">
                    <span
                        onClick={() => {
                            !mapState.groupingOpen && dispatch({ type: 'toggle-grouping' });
                        }}
                        className="mr-2 cursor-pointer"
                    >
                        Map is grouped
                    </span>
                </Tooltip>
                <Tooltip label="Ungroup nodes">
                    <XMarkIcon
                        className="h-4 w-4 text-orange-500 cursor-pointer"
                        onClick={unGroupAllNodes}
                        data-test="global-remove-grouping"
                    />
                </Tooltip>
            </div>
        </div>
    );
};
