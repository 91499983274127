import { useLazyQuery } from '@apollo/client';
import { LIST_USER_PERMISSIONS } from 'Graph/queries';
import { useLocalStorage, useTenant } from 'Hooks/Hooks';
import { useCurrentUser } from 'Hooks/User';
import { createContext, useContext, useEffect, useState } from 'react';

type UserPermissionsContextProps = {
    permissions: Record<string, string[]> | undefined;
};

export const UserPermissionsContext = createContext<UserPermissionsContextProps>({
    permissions: undefined,
});

type UserPermissionsProps = {
    children: JSX.Element;
};

export const UserPermissions = ({ children }: UserPermissionsProps): JSX.Element => {
    const timeoutInMs = 2000;
    const tenantId = useTenant();
    const { userId } = useCurrentUser();
    const { getScopedStorageKey } = useLocalStorage();
    const key = getScopedStorageKey(`userPermissions`);

    const [permissions, setPermissions] = useState<Record<string, string[]> | undefined>(undefined);
    const [timeoutOrErrorOccurred, setTimeoutOrErrorOccurred] = useState(false);

    const context: UserPermissionsContextProps = { permissions };

    const [listUserPermissions, { data }] = useLazyQuery(LIST_USER_PERMISSIONS, {
        variables: { tenantId, userId },
    });

    // Process the users permissions when we receive them from the API
    // This may happen after the timeout and the UI has been unblocked
    useEffect(() => {
        if (data) {
            // Convert the permissions into a record indexed by path
            const listUserPermissions = data.listUserPermissions;
            const extractedPermissions: Record<string, string[]> = {};
            Object.values(listUserPermissions).forEach((permission: { path: string; permissions: string[] }) => {
                const { path, permissions } = permission;
                extractedPermissions[path] = permissions;
            });

            // Update the state and cache the permissions
            setPermissions(extractedPermissions);
            if (key) {
                localStorage.setItem(key, JSON.stringify(extractedPermissions));
            }
            console.log("User's permissions:", extractedPermissions);
        }
    }, [data, key]);

    useEffect(() => {
        // In this function we will start a race between the API request and a timer
        // If the API request returns before the timer, all good
        // If the timer expires before the API request returns or an error occurs we will set a flag
        // indicating that the timer expired and the UI should be unblocked.
        //
        // The reason we do this is so that the UI is not blocked indefinitely or for a long time
        // incase there is an issue with the listUserPermissions API call.
        //
        // If the timeout occurs we will either (a) use the cached permissions or (b) unblock the UI in read-only mode
        // Obviously, the cached permissions are preferred, but they may not be available if the user has never logged in before
        async function fetchUserPermissions() {
            let isTimedOut = false;
            let isErrored = false;

            const responsePromise = listUserPermissions();
            const timeoutPromise: Promise<{ error: string }> = new Promise((resolve) =>
                setTimeout(resolve, timeoutInMs, { error: 'timeout' }),
            );

            const response = await Promise.race([responsePromise, timeoutPromise]);

            if (response.error === 'timeout') {
                console.log('Timeout occurred while fetching user permissions');
                isTimedOut = true;
            } else {
                console.log('User permissions API returned before timeout');

                if (response.error) {
                    console.warn('Error fetching user permissions:', response.error);
                    isErrored = true;
                }
            }

            if (isTimedOut || isErrored) {
                setTimeoutOrErrorOccurred(true);

                // If we have cached permissions, use them
                if (key) {
                    const cachedPermissions = localStorage.getItem(key);
                    if (cachedPermissions) {
                        const parsedPermissions = JSON.parse(cachedPermissions);
                        if (parsedPermissions) {
                            setPermissions(parsedPermissions);
                            console.warn('Using cached permissions');
                            return;
                        }
                    }
                }

                // If we don't have cached permissions, unblock the UI in read-only mode
                console.warn(
                    'Error or timeout occurred while waiting for user permissions, unblocking UI in read-only mode',
                );
            }
        }

        fetchUserPermissions();
    }, [key, listUserPermissions]);

    if (timeoutOrErrorOccurred || permissions) {
        return <UserPermissionsContext.Provider value={context}>{children}</UserPermissionsContext.Provider>;
    }

    return <Loading />;
};
const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs" data-testid="auth-spinner">
                Retrieving User Permissions ...
            </p>
        </div>
    </div>
);

// This hook is used to check if the user has permissions to perform an action on a path
// It is used in the UI to hide/show different elements based on the users permissions
export const useUserPermissions = () => {
    const { permissions } = useContext(UserPermissionsContext);

    /**
     * Checks if a user has permission to perform a certain action on a specific path.
     *
     * @param {string} action - The action to be performed.
     * @param {string} path - The path where the action is performed.
     * @returns {boolean} Returns true if the user has the permissions to perform the action, false otherwise.
     *
     * @example
     * // returns true if the 'get' action is available on the '/users/*' path
     * userCan('get', '/user/*')
     */
    const userCan = (action: string, path: string) => {
        if (!permissions) {
            return false;
        }

        const availablePaths = permissions[action];

        if (!availablePaths || !availablePaths.includes(path)) {
            return false;
        }

        return true;
    };

    return { userCan };
};
