import { ReactNode } from 'react';

const ExternalPage = ({ children }: { children: ReactNode }): JSX.Element => {
    const background = window.__env__.ASSET_HOST + '/img/00-background.jpg';
    return (
        <div className="min-h-screen bg-gray-800 flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 md:py-0 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96 max-h-screen overflow-y-scroll overflow-x-visible">
                    {children}
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <img className="absolute inset-0 h-full w-full object-cover" src={background} alt="" />
            </div>
        </div>
    );
};

export default ExternalPage;
