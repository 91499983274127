import { useAuth } from 'Hooks/Auth';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { useMemo } from 'react';

const ld_client_id = window.__env__.LAUNCH_DARKLY_CLIENT_ID || '';

const AuthenticatedLaunchDarklyProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const { email, tenantId } = useAuth();

    const user = useMemo(() => {
        if (email && tenantId) {
            console.log(`Initializing Launch Darkly with user ${email} and tenant ${tenantId}`);
            const user: LDUser = {
                key: email,
                email: email,
                name: email,
                custom: {
                    tenantId: tenantId,
                },
            };
            return user;
        }
        return null;
    }, [email, tenantId]);

    if (!user) {
        return (
            <div className="flex h-screen">
                <div className="m-auto text-center">
                    <p className="text-gray-500 text-xs">Retrieving user profile...</p>
                </div>
            </div>
        );
    }

    return (
        <LDProvider
            clientSideID={ld_client_id}
            user={user}
            children={children}
            options={{ bootstrap: 'localStorage' }}
            deferInitialization
        />
    );
};

export default AuthenticatedLaunchDarklyProvider;
