import { Node } from 'Types/types';
import { tagNameLookup } from 'Utilities/utils';

export const fromNodeProp = (nodes: Node[], prop: string, displayTextFn?: (property: string) => string) => {
    nodes.map((node) => {
        if (prop in node.props) {
            let group = node.props[prop as keyof typeof node.props] as string;
            if (displayTextFn) {
                group = displayTextFn(group);
            }
            node.group = group;
        }
    });
};

export const fromEmail = (nodes: Node[], prop: string) => {
    nodes.map((node) => {
        if (prop in node.props) {
            const email = node.props[prop as keyof typeof node.props] as string;
            if (email) {
                const domain = email.split('@')[1];
                if (domain) {
                    node.group = `email domain ${domain}`;
                    return;
                }
            }
            node.group = 'email domain unknown';
        }
    });
};

export const fromDomain = (nodes: Node[], prop: string) => {
    nodes.map((node) => {
        if (prop in node.props) {
            const domain = node.props[prop as keyof typeof node.props] as string;
            if (domain) {
                node.group = `target domain ${domain}`;
                return;
            }
        }
        node.group = 'unknown';
    });
};

export const fromTargetAccessCount = (nodes: Node[]) => {
    nodes.map((node) => {
        let count = 0;

        if (node.label === 'actor') {
            node.neighbors.map((neighbor) => {
                if (neighbor.label === 'identity') {
                    neighbor.neighbors.map((neighbor2) => {
                        if (neighbor2.label === 'target') {
                            count++;
                        }
                    });
                }
            });
        } else if (node.label === 'identity') {
            node.neighbors.map((neighbor) => {
                if (neighbor.label === 'target') {
                    count++;
                }
            });
        }
        node.group = `targets accessed: ${count}`;
    });
};

export const fromUserCount = (nodes: Node[]) => {
    nodes.map((node) => {
        let count = 0;

        if (node.label === 'target') {
            node.neighbors.map((neighbor) => {
                if (neighbor.label === 'identity') {
                    neighbor.neighbors.map((neighbor2) => {
                        if (neighbor2.label === 'actor') {
                            count++;
                        }
                    });
                }
            });
            node.group = `users accessed ${count}`;
        }
    });
};

export const fromNodeTags = (nodes: Node[]) => {
    nodes.map((node) => {
        const nodeString = node.tags.map((tag) => tagNameLookup(tag)).join(', ');
        node.group = nodeString ? nodeString : node.label;
    });
};

const wellKnownTargets = [
    'Github',
    'Amazon',
    'AWS',
    'Zoom',
    'Cisco',
    'Microsoft',
    'Office',
    'Google',
    'Slack',
    'Box',
    'Dropbox',
    'Salesforce',
    'Concur',
    'Snowflake',
    'Okta',
    'Workday',
    'Tableau',
    'Vimeo',
    'ServiceNow',
    'JAMF',
];

export const fromWellKnownTargets = (nodes: Node[]) => {
    nodes.map((node) => {
        if (node.label === 'target') {
            node.group = 'unknown';

            wellKnownTargets.map((wellKnownTargetName) => {
                if (node.props.displayName?.toLowerCase().includes(wellKnownTargetName.toLowerCase())) {
                    console.log(wellKnownTargetName);
                    node.group = 'well-known';
                }
            });
        }
    });
};

export const fromActorType = (nodes: Node[]) => {
    nodes.map((node) => {
        if (node.label === 'actor' && node.props.alternateId) {
            if (isAnEmail(node.props.alternateId)) {
                node.group = 'human';
            } else {
                node.group = 'machine';
            }
        }
    });
};

const isAnEmail = (alternateId: string) => {
    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(alternateId).toLowerCase());
};

export const fromPolicyViolations = (nodes: Node[], label: string) => {
    nodes.map((node) => {
        if (node.label === 'target') {
            if (node.policyStats) {
                if (node.policyStats.critical > 0) {
                    node.group = `${label} - policy violations`;
                    return;
                }
            }
            node.group = `${label} - no policy violations`;
        } else if (node.label === 'identity') {
            node.group = `${label} - no policy violations`;
            node.links.map((link) => {
                if (link.policyStats) {
                    if (link.policyStats.critical > 0) {
                        node.group = `${label} - policy violations`;
                        return;
                    }
                }
            });
        } else if (node.label === 'actor') {
            node.group = `${label} - no policy violations`;
            node.neighbors.map((neighbor) => {
                if (neighbor.label === 'identity') {
                    neighbor.links.map((link) => {
                        if (link.policyStats) {
                            if (link.policyStats.critical > 0) {
                                node.group = `${label} - policy violations`;
                                return;
                            }
                        }
                    });
                }
            });
        }
    });
};
