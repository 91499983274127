import { AdjustmentsVerticalIcon, Square3Stack3DIcon, UsersIcon } from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';
import { useMemo } from 'react';
import { useTenant } from 'Hooks/Hooks';
import { useQuery } from '@apollo/client';
import { LIST_POLICY_PROFILES } from 'Graph/typedQueries';
import { SelectedNavItem } from '../PolicyModalTypes';
interface SideNavProps {
    selectedNavItem: SelectedNavItem | undefined;
    setSelectedNavItem: React.Dispatch<React.SetStateAction<SelectedNavItem | undefined>>;
}
const headerStyle = classNames(
    'border-transparent text-gray-300',
    'cursor-default group border-l-4 px-6 py-2 flex items-center justify-between text-sm font-medium',
);

const getItemStyle = (active: boolean) =>
    classNames(
        active
            ? 'bg-blue-800 border-blue-700 text-gray-200'
            : 'border-transparent hover:bg-gray-700 text-gray-400 hover:text-gray-200',
        'cursor-pointer group border-l-4 pl-10 pr-6 py-2 flex items-center justify-between text-xs font-medium',
    );

export const SideNav = ({ selectedNavItem, setSelectedNavItem }: SideNavProps): JSX.Element => {
    const tenantId = useTenant();

    // List Policy Profiles
    const { data: policyProfilesData } = useQuery(LIST_POLICY_PROFILES, {
        variables: {
            tenantId: tenantId || '',
        },
        skip: !tenantId,
    });

    const profileId = useMemo(() => {
        if (!policyProfilesData?.listPolicyProfiles) {
            return;
        }

        if (policyProfilesData?.listPolicyProfiles.length > 0) {
            return policyProfilesData?.listPolicyProfiles[0].profileId;
        }
    }, [policyProfilesData]);

    return (
        <div className="flex flex-col flex-grow pt-4 pb-4  relative">
            <div className="flex-grow flex flex-col">
                <nav className="flex-1 space-y-4 bg-gray-800" aria-label="Sidebar">
                    <p className="uppercase tracking-wider px-4 font-bold text-xs text-gray-400 mb-4 h-8 flex items-center">
                        Configuration
                    </p>

                    <div>
                        <span className={headerStyle}>
                            <span className="flex align-center">
                                <span className="text-gray-500 w-4">1.</span>How Trust Is Calculated
                            </span>
                        </span>
                        <span
                            onClick={() => setSelectedNavItem({ type: 'profile', id: profileId })}
                            className={getItemStyle(
                                selectedNavItem?.type == 'profile' && selectedNavItem?.id == profileId,
                            )}
                            title={'All Targets'}
                        >
                            <span className="inline-block truncate pr-4">Configure Trust Scoring</span>
                            <AdjustmentsVerticalIcon
                                width="18"
                                height="18"
                                className="text-blue-500 mr-2 float-right"
                            />
                        </span>
                    </div>

                    <div>
                        <span className={headerStyle}>
                            <span className="flex align-center">
                                <span className="text-gray-500 w-4">2.</span>Actors To Calculate Trust On
                            </span>
                        </span>
                        <span
                            onClick={() => setSelectedNavItem({ type: 'mapping' })}
                            className={getItemStyle(selectedNavItem?.type == 'mapping')}
                        >
                            <span className="flex align-center">Configure Actors</span>
                            <UsersIcon width="18" height="18" className="text-blue-500 mr-2 float-right" />
                        </span>
                    </div>

                    <div>
                        <span className={headerStyle}>
                            <span className="flex align-center">
                                <span className="text-gray-500 w-4">3.</span>Where To Detect Untrusted Access
                            </span>
                        </span>

                        <span
                            onClick={() => setSelectedNavItem({ type: 'target', id: 'ANY' })}
                            className={getItemStyle(selectedNavItem?.type == 'target' && selectedNavItem?.id == 'ANY')}
                            title={'Configure Targets'}
                        >
                            <span className="inline-block truncate pr-4">Configure Targets</span>
                            <div className="">
                                <Square3Stack3DIcon width="18" height="18" className="text-blue-500 mr-2 float-right" />
                            </div>
                        </span>
                    </div>
                </nav>
            </div>
        </div>
    );
};
