import { ApolloError, useQuery } from '@apollo/client';
import { GET_ENTITIES_AS_NODES, GET_EXPANDED_IDENTITY_MAP, GET_IDENTITY_MAP } from 'Graph/queries';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BackendNode, parseBackendNode, transformData } from 'Map/Graph/Data';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { Action } from 'Types/types';
import { IdentityMapState } from 'Types/types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Environments } from 'Types/types';
import { useLocation } from 'react-router-dom';
import { TourContext } from 'Map/Components/TourProvider';
import { getTourStepName } from 'Map/State/TourReducer';
import { ToastContext } from 'Map/Components/ToastContext';
import { useAuth } from './Auth';
import { useCurrentUser } from './User';
import { datadogRum } from '@datadog/browser-rum';
import { compareNodesByDisplayName } from 'Utilities/utils';

export const useTenant = (): string | null | undefined => {
    const { tenantId } = useAuth();

    return tenantId;
};

export const useBoxSelect = (mapState: IdentityMapState, dispatch: React.Dispatch<Action>) => {
    const boxSelect = useMemo(() => document.createElement('div'), []);
    boxSelect.id = 'boxSelect';
    let boxSelectStart = { x: 0, y: 0 };

    // forceGraph element is the element provided to the Force Graph Library
    const pointerDown = (e: React.MouseEvent<HTMLElement>) => {
        if (e.shiftKey) {
            e.preventDefault();
            boxSelect.style.left = e.nativeEvent.offsetX.toString() + 'px';
            boxSelect.style.top = e.nativeEvent.offsetX.toString() + 'px';
            boxSelect.style.height = '0px';
            boxSelect.style.width = '0px';
            boxSelectStart = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };

            // app element is the element just above the forceGraph element.
            document.getElementById('graphContainer')?.appendChild(boxSelect);
        }
    };

    const pointerMove = (e: React.PointerEvent<HTMLElement>) => {
        if (e.shiftKey && boxSelect) {
            e.preventDefault();
            if (e.nativeEvent.offsetX < boxSelectStart.x) {
                boxSelect.style.left = e.nativeEvent.offsetX.toString() + 'px';
                boxSelect.style.width = (boxSelectStart.x - e.nativeEvent.offsetX).toString() + 'px';
            } else {
                boxSelect.style.left = boxSelectStart.x.toString() + 'px';
                boxSelect.style.width = (e.nativeEvent.offsetX - boxSelectStart.x).toString() + 'px';
            }
            if (e.nativeEvent.offsetY < boxSelectStart.y) {
                boxSelect.style.top = e.nativeEvent.offsetY.toString() + 'px';
                boxSelect.style.height = (boxSelectStart.y - e.nativeEvent.offsetY).toString() + 'px';
            } else {
                boxSelect.style.top = boxSelectStart.y.toString() + 'px';
                boxSelect.style.height = (e.nativeEvent.offsetY - boxSelectStart.y).toString() + 'px';
            }
        } else if (boxSelect) {
            boxSelect.remove();
        }
    };

    const pointerUp = (e: React.PointerEvent<HTMLElement>) => {
        if (e.shiftKey && boxSelect) {
            e.preventDefault();
            let left, bottom, top, right;
            if (e.nativeEvent.offsetX < boxSelectStart.x) {
                left = e.nativeEvent.offsetX;
                right = boxSelectStart.x;
            } else {
                left = boxSelectStart.x;
                right = e.nativeEvent.offsetX;
            }
            if (e.nativeEvent.offsetY < boxSelectStart.y) {
                top = e.nativeEvent.offsetY;
                bottom = boxSelectStart.y;
            } else {
                top = boxSelectStart.y;
                bottom = e.nativeEvent.offsetY;
            }
            runBoxSelect(left, bottom, top, right);
            boxSelect.remove();
        } else if (boxSelect) {
            boxSelect.remove();
        }
    };

    const runBoxSelect = (left: number, bottom: number, top: number, right: number) => {
        const tl = mapState.graphRef?.current?.screen2GraphCoords(left, top);
        const br = mapState.graphRef?.current?.screen2GraphCoords(right, bottom);
        if (tl && br) {
            let hitNodes: Node[] = [];
            mapState.graphData.nodes.forEach((node) => {
                if (tl.x < node.x && node.x < br.x && br.y > node.y && node.y > tl.y) {
                    hitNodes.push(node);
                }
            });

            const existingNodeIds = new Set<string>();
            mapState.selectedNodes.forEach((n) => {
                existingNodeIds.add(String(n.id));
            });

            hitNodes = hitNodes.filter((n) => {
                const inExp = existingNodeIds.has(String(n.id));
                return !inExp;
            });
            hitNodes = hitNodes.sort(compareNodesByDisplayName);

            if (hitNodes.length > 0) {
                const nodeToScrollTo = hitNodes.at(-1);

                dispatch({ type: 'set-selected-nodes', nodes: new Set([...mapState.selectedNodes, ...hitNodes]) });
                dispatch({ type: 'set-scroll-to-node', node: nodeToScrollTo });
            }
        }
    };

    return {
        pointerDown,
        pointerMove,
        pointerUp,
    };
};

export const useIdentityMapData = (mapState: IdentityMapState, dispatch: React.Dispatch<Action>) => {
    const { useLocallyGeneratedData, excludeTags, enableTour, pendingQueryPollInterval = 3 } = useFlags();
    const { productTourState } = useContext(TourContext);
    const [pendingIdentityMapQueryId, setPendingIdentityMapQueryId] = useState<string | null | undefined>(null);
    const [extendedLoading, setExtendedLoading] = useState(false);
    const { name, email } = useCurrentUser();

    const tenantId = useTenant();

    const [localData, setLocalData] = useState<object | undefined>(undefined);

    const handleError = (err: ApolloError) => {
        let message = `Error loading identity map (${err.message})`;
        if (name && email) {
            message = `${name} (${email}) received an error while loading the identity map (${err.message})`;
        }

        const errorAttributes = {
            message: err.message,
            tenantId: tenantId || 'Unknown tenantId',
            startTime: mapState.selectedTime[0],
            startTimeString: new Date(mapState.selectedTime[0]).toISOString(),
            endTime: mapState.selectedTime[1],
            endTimeString: new Date(mapState.selectedTime[1]).toISOString(),
            gqlErrors: JSON.stringify(err.graphQLErrors),
            networkErrors: JSON.stringify(err.networkError),
            clientErrors: JSON.stringify(err.clientErrors),
            extraInfo: err.extraInfo,
        };

        console.debug(message, errorAttributes);
        datadogRum.addError(message, errorAttributes);
    };

    const filter = useMemo(() => {
        if (mapState.queriedNodes.size > 0) {
            const eventQueries = Array.from(mapState.queriedNodes.values())
                .filter((n) => n.label === 'query')
                .map((n) => {
                    return {
                        eventQuery: {
                            location: {
                                latitude: n?.queryAttributes?.location.latitude,
                                longitude: n?.queryAttributes?.location.longitude,
                                radius: n?.queryAttributes?.location.radius,
                            },
                        },
                    };
                });

            if (eventQueries.length > 1) {
                console.warn('Multiple event queries are not supported. Only the first one will be used.');
            }

            return {
                tenantId,
                groups: {
                    operator: 'FILTER_OPERATOR_OR',
                    elements: [
                        {
                            actorQuery: {
                                actorIds: Array.from(mapState.queriedNodes.values())
                                    .filter((n) => n.label === 'actor')
                                    .map((n) => n.id),
                            },
                        },
                        {
                            deviceQuery: {
                                deviceIds: Array.from(mapState.queriedNodes.values())
                                    .filter((n) => n.label === 'device')
                                    .map((n) => n.id),
                            },
                        },
                        {
                            applicationQuery: {
                                applicationIds: Array.from(mapState.queriedNodes.values())
                                    .filter((n) => n.label === 'application')
                                    .map((n) => n.id),
                            },
                        },
                        {
                            targetQuery: {
                                targetIds: Array.from(mapState.queriedNodes.values())
                                    .filter((n) => n.label === 'target')
                                    .map((n) => n.id),
                            },
                        },
                        {
                            identityQuery: {
                                identityIds: Array.from(mapState.queriedNodes.values())
                                    .filter((n) => n.label === 'identity')
                                    .map((n) => n.id),
                            },
                        },
                        eventQueries[0],
                    ],
                },
            };
        }
        return null;
    }, [mapState.queriedNodes, tenantId]);

    // load the identity map, this will re-execute the query every time
    // the time slider is changed. if the tenantId is not yet available, we'll hold off
    const {
        loading,
        error,
        data,
        refetch: refetchIdentityMap,
    } = useQuery(GET_IDENTITY_MAP, {
        variables: {
            tenantId,
            queryId: '',
            startTime: mapState.selectedTime[0],
            endTime: mapState.selectedTime[1],
            loadAttributes: false,
            filter: filter,
        },
        skip:
            mapState.blockIdentityMapUpdates ||
            !tenantId ||
            excludeTags === undefined ||
            mapState.levelTrails.size > 0 ||
            mapState.timelineDragging ||
            mapState.queriedNodes.size === 0,
        onError: handleError,
    });

    // load the identity map, this will re-execute the query every time
    // the time slider is changed. if the tenantId is not yet available, we'll hold off
    const {
        loading: loadingExpanded,
        error: errorExpanded,
        data: dataExpanded,
    } = useQuery(GET_EXPANDED_IDENTITY_MAP, {
        variables: {
            tenantId,
            startTime: mapState.selectedTime[0],
            endTime: mapState.selectedTime[1],
            levelTrails: Array.from(mapState.levelTrails),
            loadAttributes: false,
            filter: filter,
        },
        skip:
            mapState.blockIdentityMapUpdates ||
            !tenantId ||
            excludeTags === undefined ||
            mapState.levelTrails.size === 0 ||
            mapState.timelineDragging ||
            mapState.queriedNodes.size === 0,
        onError: handleError,
    });

    useEffect(() => {
        if (useLocallyGeneratedData && !productTourState.run && !mapState.timelineDragging) {
            console.log('Loading test data from /ui/public/test/data.json');
            fetch('/test/data.json')
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    const map = json.data.getIdentityMap;

                    const [nodes, links] = transformData(map.nodes, map.edges);

                    dispatch({ type: 'set-graph-data', data: { nodes, links } });

                    setLocalData(json.data.getIdentityMap);
                });
        }
    }, [
        dispatch,
        mapState.graphRef,
        mapState.selectedTime,
        mapState.timelineDragging,
        productTourState.run,
        useLocallyGeneratedData,
    ]);

    useEffect(() => {
        if (enableTour && productTourState.run) {
            console.log('Loading test data from /ui/public/data/tutorial.json');
            fetch('/data/tutorial.json')
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    const map = json.data.getIdentityMap;

                    const [nodes, links] = transformData(map.nodes, map.edges);

                    dispatch({ type: 'set-graph-data', data: { nodes, links } });

                    setLocalData(json.data.getIdentityMap);
                });
        }
    }, [dispatch, enableTour, mapState.graphRef, mapState.selectedTime, productTourState.run]);

    // When the identity map query is running, we don't know whether there will be a
    // pending async query returned that we'll need to poll for. To avoid the screen flashing
    // for a second when the query returns data but it hasn't yet been processed to figure out
    // if there's a pending queryId, we'll deliberately set this value to "undefined",
    // i.e. we don't know if there's a pending queryId, so keep displaying the loading spinner
    // until that's been determined.
    useEffect(() => {
        if (loading) {
            setPendingIdentityMapQueryId(undefined);
        }
    }, [loading]);

    useEffect(() => {
        if (pendingIdentityMapQueryId) {
            // set an interval that runs every 3s (by default, configurable via a feature flag)
            // to check on the status of the identity map query
            const interval = setInterval(() => {
                console.debug(`Checking on identity map query (${pendingQueryPollInterval}s interval)`);
                refetchIdentityMap({ queryId: pendingIdentityMapQueryId });
            }, pendingQueryPollInterval * 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [pendingIdentityMapQueryId, pendingQueryPollInterval, refetchIdentityMap]);

    useEffect(() => {
        if (!useLocallyGeneratedData && !productTourState.run && data) {
            console.log('Loading Identity Map data from GraphQL');
            const map = data.getIdentityMap;

            // If the backend requires more time to fetch the data into the cache,
            // the response will contain isLoading: true. In this case, we'll receive
            // a queryId which can be used to track the status of the query. We'll
            // kick off an effect which polls the backend for the status of the query
            // by setting the pendingIdentityMapQueryId.
            if (map.isLoading) {
                console.debug(
                    'There is a pending identity map query, setting pendingIdentityMapQueryId to',
                    map.queryId,
                );
                setPendingIdentityMapQueryId(map.queryId);
                setExtendedLoading(true);
                return;
            } else {
                setPendingIdentityMapQueryId(null);
                setExtendedLoading(false);
            }

            const [nodes, links] = transformData(map.nodes, map.edges);

            dispatch({ type: 'set-graph-data', data: { nodes, links } });
        }
    }, [data, dispatch, mapState.graphRef, productTourState.run, useLocallyGeneratedData]);

    useEffect(() => {
        if (!useLocallyGeneratedData && !productTourState.run && dataExpanded) {
            console.log('Loading Expanded Identity Map data from GraphQL');
            const map = dataExpanded.getExpandedGrouping;

            const [nodes, links] = transformData(map.nodes, map.edges);

            dispatch({ type: 'set-graph-data', data: { nodes, links } });
        }
    }, [dataExpanded, dispatch, mapState.graphRef, productTourState.run, useLocallyGeneratedData]);

    if (useLocallyGeneratedData) {
        if (localData) {
            return {
                loading: false,
                extendedLoading: false,
                error: undefined,
                data: localData,
            };
        } else {
            return {
                loading: false,
                extendedLoading: false,
                error: undefined,
                data: undefined,
            };
        }
    } else {
        if (mapState.levelTrails.size > 0) {
            return {
                loading: loadingExpanded,
                extendedLoading: extendedLoading,
                error: errorExpanded,
                data: dataExpanded ? dataExpanded.getExpandedGrouping : undefined,
            };
        }
        return {
            // the query is "loading" until we have received the data and checked there isn't an async query pending
            loading:
                loading ||
                (pendingIdentityMapQueryId === undefined && !error) ||
                (pendingIdentityMapQueryId !== null && !error),
            extendedLoading: extendedLoading,
            error,
            data: data ? data.getIdentityMap : undefined,
        };
    }
};

export const useEnvironment = (): Environments => {
    return getEnvironment();
};

export const getEnvironment = (): Environments => {
    if (
        process.env.NODE_ENV === 'development' ||
        window.__env__.ENVIRONMENT === 'dev' ||
        window.__env__.ENVIRONMENT === 'local'
    ) {
        return 'dev';
    } else if (window.__env__.ENVIRONMENT === 'staging') {
        return 'staging';
    } else if (window.__env__.ENVIRONMENT === 'prod') {
        return 'prod';
    }
    return 'dev';
};

export const useLocalStorage = () => {
    const { userId } = useAuth();
    const tenantId = useTenant();

    const getScopedStorageKey = (key: string): string | undefined => {
        if (userId && tenantId) {
            return `${tenantId}$${userId}$` + key;
        }
        return undefined;
    };

    return { getScopedStorageKey };
};

export const useURLParameter = () => new URLSearchParams(useLocation().search);

export const useProductTutorial = () => {
    const { productTourState, dispatch: productTourDispatch } = useContext(TourContext);
    const { mapState, dispatch: mapDispatch } = useContext(IdentityMapContext);
    const { graphRef } = mapState;
    const { dispatch: toastDispatch } = useContext(ToastContext);
    const { userId } = useAuth();
    const tenantId = useTenant();

    const runOnTutorialStep = useCallback(
        (step: string | string[], callback: () => void) => {
            const currentStep = getTourStepName(productTourState);
            const tourRunning = productTourState.run;
            if (tourRunning && currentStep) {
                if (typeof step === 'string') {
                    if (currentStep === step) {
                        console.debug('Calling callback for step', step);
                        callback();
                    }
                } else {
                    if (step.includes(currentStep)) {
                        console.debug('Calling callback for step', step);
                        callback();
                    }
                }
            }
        },
        [productTourState],
    );

    const moveToNextTutorialStep = useCallback(() => {
        productTourDispatch({
            type: 'move-between-steps',
            payload: { stepIndex: productTourState.stepIndex + 1 },
        });
    }, [productTourDispatch, productTourState.stepIndex]);

    const centerMapOnNode = useCallback(
        (nodeName: string) => {
            if (mapState.graphData) {
                const nodes = mapState.graphData.nodes;
                nodes.map((node: Node) => {
                    if (node.props.displayName == nodeName && graphRef && graphRef.current != null) {
                        const centerNode = node;
                        graphRef.current.centerAt(centerNode.x, centerNode.y, 0);
                        graphRef.current.zoom(5, 0);
                    }
                });
            }
        },
        [graphRef, mapState.graphData],
    );

    const startTutorial = useCallback(() => {
        if (userId && tenantId) {
            localStorage.setItem(`${tenantId}$${userId}$tourSeen`, 'no');
            productTourDispatch({ type: 'reset-tour' });
            productTourDispatch({ type: 'start-tour' });

            toastDispatch({
                type: 'add-toast',
                message: `Product Tutorial data has been loaded to the Identity Map`,
                status: 'information',
                autoTimeout: true,
                timeoutTimer: 15,
            });

            if (!mapState.visible.devices) {
                mapDispatch({
                    type: 'set-visibility',
                    visible: { ...mapState.visible, devices: true },
                });
            }
            return true;
        }
        return false;
    }, [mapDispatch, mapState.visible, productTourDispatch, tenantId, toastDispatch, userId]);

    return {
        runOnTutorialStep,
        moveToNextTutorialStep,
        startTutorial,
        centerMapOnNode,
        tutorialRunning: productTourState.run,
    };
};

export const useNodes = (
    nodeIds: string[],
    entityType: string,
): { loading: boolean; error: ApolloError | undefined; nodes: Record<string, Node> } => {
    const tenantId = useTenant();

    const [nodes, setNodes] = useState<Record<string, Node>>({});

    const { loading, error, data } = useQuery(GET_ENTITIES_AS_NODES, {
        variables: { tenantId, entityIds: nodeIds, entityType: entityType },
        skip: !tenantId || !nodeIds || nodeIds.length === 0,
    });

    useEffect(() => {
        setNodes({});
    }, [nodeIds]);

    useEffect(() => {
        if (data && Object.keys(nodes).length === 0) {
            const nodes: Record<string, Node> = {};

            data.getEntitiesAsNodes.map((node: BackendNode) => {
                nodes[node.nodeId] = parseBackendNode(node);
            });

            setNodes(nodes);
        }
    }, [data, nodes]);

    return { loading, error, nodes };
};

export function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}
