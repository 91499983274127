import { Node } from 'Types/types';
import { getIconSourceURL, getNodeIconElement } from 'Map/Graph/Icons';
import Avatar from 'react-avatar';
import { getDisplayName } from 'Utilities/utils';

export const NodeAvatar = ({ node }: { node: Node }) => {
    return node.label == 'actor' ? (
        <div className="h-6 w-6 flex-shrink-0 flex-grow-0">
            <Avatar size="100%" name={getDisplayName(node)} round={true} maxInitials={2} />
        </div>
    ) : (
        <img src={getIconSourceURL(getNodeIconElement(node))} className="h-6 w-6 flex-shrink-0" />
    );
};
