import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { useContext } from 'react';
import { getDisplayName, getEntityType } from 'Utilities/utils';

import 'react-datepicker/dist/react-datepicker.css';
import { BackendEventHistoryData } from 'Types/types';
import { List, ListItem } from 'Library/List';
import { getIconSourceURL, getNodeIconElement, getOSIconElement, getProviderIconElement } from 'Map/Graph/Icons';
import { BaseChart, DashboardChartProps } from 'Dashboard/Charts/BaseChart';
import { GET_ENTITY_EVENT_HISTORY } from 'Graph/queries';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import Avatar from 'react-avatar';
import { geojson } from 'Dashboard/TrustDashboardGeoJson';
import { renderToString } from 'react-dom/server';
import { ComposableMap, ZoomableGroup, Geographies, Geography } from 'react-simple-maps';
import WorldCountriesGeos from 'Dashboard/Charts/WorldCountriesGeos.json';
import { NodeTrustDisplayPrototype } from 'Map/Profiles/Library/NodeTrustDisplayPrototype';

export const MaxNodeProfileOverviewPrototype = ({
    startDate,
    endDate,
    interval,
    onBarClick,
    selectedBar,
}: DashboardChartProps): JSX.Element => {
    const { selectedProfileNode: node } = useContext(IdentityMapContext).mapState;

    if (!node) {
        return <div>No node selected</div>;
    }

    return (
        <div className="grid lg:grid-cols-6 grid-cols-3 grid-flow-dense gap-4">
            <div className="col-span-3 bg-gray-800 rounded-lg p-4">
                <div className="flex justify-between">
                    <div className="flex-grow space-y-2 flex flex-col">
                        <div className="flex">
                            <div className="flex items-top justify-between pt-0.5">
                                <div className="flex flex-row space-x-3">
                                    <div className="flex-shrink-0 items-center relative">
                                        <div className="h-12 w-12 rounded-full bg-gray-900 border border-gray-700 p-2 flex items-center justify-center mr-2">
                                            {node.label == 'actor' ? (
                                                <Avatar
                                                    size="100%"
                                                    name={getDisplayName(node)}
                                                    round={true}
                                                    maxInitials={2}
                                                />
                                            ) : (
                                                <img src={getIconSourceURL(getNodeIconElement(node))} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="text-lg">{getDisplayName(node)}</div>
                                <div>{node.props.alternateId}</div>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="text-gray-300">
                                Actor is <span className="text-blue-600">Internal</span>,{' '}
                                <span className="text-green-600">Privileged</span>
                            </div>
                            <div className="text-gray-400">Observed through Azure AD, Office 365, Okta</div>
                        </div>
                    </div>

                    <div className="flex-grow flex space-x-4">
                        <div className="space-y-2 flex-grow">
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-100">Job Title</span>
                                <span className="text-gray-400">Software Developer</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium">Department</span>
                                <span className="text-gray-400">Tech Innovation</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium">Enabled</span>
                                <span className="text-gray-400">True</span>
                            </div>
                        </div>
                        <div className="space-y-2 flex-grow">
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-100">City</span>
                                <span className="text-gray-400">Palo Alto</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium">State</span>
                                <span className="text-gray-400">California</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium">Country</span>
                                <span className="text-gray-400">United States</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-span-3 bg-gray-800 rounded-lg flex place-items-center space-x-4 p-4">
                <TrustSpider />

                <BaseChart
                    margin={{ top: 0, right: 0, bottom: 0, left: -20 }}
                    legendHeight={40}
                    query={GET_ENTITY_EVENT_HISTORY}
                    queryName="getEntityEventHistory"
                    variables={{
                        entityId: node?.id,
                        entityType: getEntityType(node),
                    }}
                    processRow={(row: BackendEventHistoryData) => {
                        return {
                            Success: row.successCount,
                            Challenge: row.challengeCount,
                            Failure: row.denyCount,
                        };
                    }}
                    bars={[
                        {
                            name: 'Success',
                            dataKey: 'Success',
                            activeColor: 'rgba(101, 163, 13, 1)',
                            inactiveColor: 'rgba(101, 163, 13, 0.5)',
                        },
                        {
                            name: 'Challenge',
                            dataKey: 'Challenge',
                            activeColor: 'rgba(245, 158, 11, 1)',
                            inactiveColor: 'rgba(245, 158, 11, 0.5)',
                        },
                        {
                            name: 'Failure',
                            dataKey: 'Failure',
                            activeColor: 'rgba(225, 29, 72, 1)',
                            inactiveColor: 'rgba(225, 29, 72, 0.5)',
                        },
                    ]}
                    startDate={startDate}
                    endDate={endDate}
                    interval={interval}
                    onBarClick={onBarClick}
                    selectedBar={selectedBar}
                    plotTrust={true}
                    trustData={node.trustData}
                />
            </div>

            <div className="col-span-3">
                <div className="flex w-full space-x-4">
                    <div className="flex flex-col space-y-4">
                        <button className="btn flex-grow rounded-lg">Email Manager</button>
                        <button className="btn flex-grow rounded-lg">Notify SIRT</button>
                    </div>

                    <div className="flex-grow bg-gray-700 rounded-lg p-2 space-y-1">
                        <div className="font-medium text-gray-500 capitalize w-full text-center">
                            Authentication Methods
                        </div>
                        <div className="flex place-items-center">
                            <CheckCircleIcon className="h-4 w-4 text-green-600 mr-2" />
                            FIDO2
                        </div>
                        <div className="flex place-items-center">
                            <CheckCircleIcon className="h-4 w-4 text-green-600 mr-2" />
                            Microsoft Authenticator
                        </div>
                        <div className="flex place-items-center">
                            <ExclamationCircleIcon className="h-4 w-4 text-yellow-600 mr-2" />
                            SMS
                        </div>
                    </div>

                    <div className="bg-gray-700 rounded-lg p-2 w-24 flex flex-col">
                        <div className="font-medium text-gray-500 capitalize w-full text-center">Groups</div>
                        <div className="flex justify-center items-center text-3xl font-medium h-full">52</div>
                    </div>

                    <div className="bg-gray-700 rounded-lg p-2 w-24 flex flex-col">
                        <div className="font-medium text-gray-500 capitalize w-full text-center">Applications</div>
                        <div className="flex justify-center items-center text-3xl font-medium h-full">86</div>
                    </div>

                    <div className="flex flex-col space-y-4">
                        <button className="btn flex-grow rounded-lg">Permissions</button>
                        <button className="btn flex-grow rounded-lg">Access Policy</button>
                    </div>
                </div>
            </div>

            <div className="col-span-3 row-span-2">
                <NodeTrustDisplayPrototype node={node} startDate={startDate} endDate={endDate} dynamicHeight={true} />
            </div>

            <div className="bg-gray-700 rounded-lg h-full">
                <List title="Most Accessed Targets">
                    <MockListItem name="Azure Active Directory" />
                    <MockListItem name="Microsoft Graph" />
                    <MockListItem name="MongoDB Atlas" />
                    <MockListItem name="AWS" />
                </List>
            </div>
            <div className="bg-gray-700 rounded-lg">
                <List title="Unusual Recent Accesses">
                    <MockListItem name="Salesforce.com" />
                    <MockListItem name="Workday" />
                    <MockListItem name="Concur" />
                    <MockListItem name="OneDrive" />
                </List>
            </div>
            <div className="bg-gray-700 rounded-lg">
                <List title="Devices">
                    <MockListItemDevice name="MacBook Pro" os="OS_IOS" />
                    <MockListItemDevice name="iPhone" os="OS_IOS" />
                    <MockListItemDevice name="win-03694-123" os="OS_WINDOWS" />
                    <MockListItemDevice name="Unmanaged Device - Windows" os="OS_WINDOWS" />
                </List>
            </div>

            <div className="lg:col-span-4 col-span-3 bg-gray-800 rounded-lg flex place-items-center">
                {/* <h1 className="text-sm font-medium px-4 pt-4">Access</h1> */}
                <MaxProfileTrustWorldMap />
            </div>
            <div className="lg:col-span-2 col-span-3 bg-gray-700 rounded-lg">
                <List title="Activity Log">
                    <ListItem
                        title="New MFA registered"
                        description="SMS - +44******1234"
                        iconHTMLImageElement={getProviderIconElement('Azure AD')}
                    />
                    <ListItem
                        title="Admin Privilege Granted"
                        description="Entra ID Administrator"
                        iconHTMLImageElement={getProviderIconElement('Azure AD')}
                    />
                    <ListItem
                        title="Location Discrepancy"
                        description="MFA Push Accepted from London, UK while logged in from San Jose, CA"
                        iconHTMLImageElement={getProviderIconElement('SailPoint Identity Risk')}
                    />
                    <ListItem
                        title="MFA Push Accepted"
                        description="Location: London, UK"
                        iconHTMLImageElement={getProviderIconElement('One Login')}
                    />
                    <ListItem
                        title="Excessive password reset attempts"
                        description="23 password resets detected in the last hour"
                        iconHTMLImageElement={getProviderIconElement('Azure AD')}
                    />
                    <ListItem
                        title="Badged into Building 14, San Jose HQ"
                        description="Access Granted to Building 14, San Jose HQ"
                        iconHTMLImageElement={getProviderIconElement('Workday')}
                    />
                    <ListItem
                        title="Leave has begun for this Actor"
                        description="Leave will end in 2 weeks"
                        iconHTMLImageElement={getProviderIconElement('Workday')}
                    />
                </List>
            </div>
        </div>
    );
};

const MockListItem = ({ name }: { name: string }): JSX.Element => {
    return (
        <ListItem
            title={name}
            iconHTMLImageElement={getNodeIconElement({
                label: 'target',
                props: { displayName: name },
            } as Node)}
        />
    );
};
const MockListItemDevice = ({ name, os }: { name: string; os: string }): JSX.Element => {
    return (
        <ListItem
            title={name}
            iconHTMLImageElement={getOSIconElement({
                label: 'device',
                props: { deviceOperatingSystem: os },
            } as Node)}
        />
    );
};

const data = [
    {
        subject: 'Actor',
        trust: 75,
        fullMark: 100,
    },
    {
        subject: 'Sessions',
        trust: 20,
        fullMark: 100,
    },
    {
        subject: 'Device',
        trust: 50,
        fullMark: 100,
    },
    {
        subject: 'Process',
        trust: 20,
        fullMark: 100,
    },
    {
        subject: 'Target',
        trust: 50,
        fullMark: 100,
    },
    {
        subject: 'Policy',
        trust: 65,
        fullMark: 100,
    },
    {
        subject: 'Activity',
        trust: 65,
        fullMark: 100,
    },
];

export const TrustSpider = (): JSX.Element => {
    return (
        <RadarChart data={data} height={200} width={300} margin={{ top: 0, bottom: -65 }}>
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis angle={30} domain={[0, 100]} tick={false} />
            <Radar
                name=""
                dataKey="trust"
                stroke="#165BAA"
                fill="#63ABFD"
                fillOpacity={0.6}
                isAnimationActive={false}
            />
        </RadarChart>
    );
};

export const MaxProfileTrustWorldMap = () => {
    return (
        <ComposableMap
            id="map"
            projection="geoMercator"
            projectionConfig={{
                scale: 250,
                rotate: [-10, 0, 0],
                center: [0, 18],
            }}
            style={{ width: '100%', height: '350px' }}
        >
            <ZoomableGroup>
                <Geographies geography={WorldCountriesGeos}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const fill = '#6B7280';
                            const strokeWidth = 0.5;

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={fill}
                                    fillOpacity={0.05}
                                    stroke="#9DA3AD"
                                    strokeWidth={strokeWidth}
                                    style={{
                                        default: { outline: 'none' },
                                        hover: { outline: 'none' },
                                        pressed: { outline: 'none' },
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>

                <Geographies geography={geojson}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const { value, row, col, opacity } = geo.properties;

                            let fill = '#36a8fa';
                            let stroke = '#36a8fa';

                            if (row === 9 && col === 15) {
                                fill = '#EBAB07';
                                stroke = '#EBAB07';
                            }
                            if (row === 22 && col === 28) {
                                fill = '#EBAB07';
                                stroke = '#EBAB07';
                            }

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    data-tooltip-id={'heatmap'}
                                    data-tooltip-html={renderToString(
                                        <div className="text-center">
                                            <div>{value} Events</div>
                                        </div>,
                                    )}
                                    geography={geo}
                                    fill={fill}
                                    fillOpacity={value > 0 ? opacity : 0.8}
                                    stroke={stroke}
                                    strokeOpacity={value > 0 ? opacity + 0.1 : 0.9}
                                    className="cursor-pointer"
                                    style={{
                                        default: { outline: 'none' },
                                        hover: { outline: 'none' },
                                        pressed: { outline: 'none' },
                                    }}
                                    onMouseEnter={() => {
                                        console.log(
                                            `Row: ${row}, Column: ${col}, Value: ${value}, Opacity: ${opacity}`,
                                        );
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>
            </ZoomableGroup>
        </ComposableMap>
    );
};
