import { useTenant } from 'Hooks/Hooks';
import { useHistory } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';
import { Panel, PanelItem } from './Panel';
import { useAuth } from 'Hooks/Auth';

const Advanced = (): JSX.Element => {
    const history = useHistory();
    const tenantId = useTenant();
    const { token } = useAuth();

    const clipboard = useClipboard({
        copiedTimeout: 2000,
    });

    return (
        <Panel title="Advanced Settings" subtitle="For the true power user">
            <PanelItem title="Account Onboarding">
                <p onClick={() => history.push('/welcome')} className="cursor-pointer ">
                    Launch Setup Wizard
                </p>
            </PanelItem>
            <PanelItem title="Access Token">
                <p onClick={() => clipboard.copy(token)} className="cursor-pointer ">
                    Copy an API access token {clipboard.copied && '(copied!)'}
                </p>
            </PanelItem>
            <PanelItem title="Tenant ID">
                <p className="select-all">{tenantId}</p>
            </PanelItem>
        </Panel>
    );
};

export default Advanced;
