import { Fragment, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
    BellIcon,
    BellSlashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronUpDownIcon,
    ChevronUpIcon,
    FunnelIcon,
} from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';
import { PauseIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import { DefaultCell } from 'Map/DataBrowser/DataBrowserCells';
import { useTable, useFilters, useSortBy, usePagination, useBlockLayout, useResizeColumns, Column } from 'react-table';
import {
    fuzzyTextFilterFn,
    fuzzyNodeDisplayNameFilterFn,
    filterGreaterThan,
    DefaultColumnFilter,
} from 'Library/TableComponents';
import { AlertData, configuredAlerts } from './AlertsConfigMock';

type ActiveAlertData = AlertData & {
    triggered: string;
    value: number;
};

const ActiveAlertsColumns = () =>
    [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            width: 500,
        },
        {
            Header: 'Status',
            accessor: 'status',
            filter: 'fuzzyText',
            width: 150,
        },
        {
            Header: 'Muted',
            accessor: 'muted',
            filter: 'fuzzyText',
            width: 150,
        },
        {
            Header: 'Muted Until',
            accessor: 'mutedUntil',
            filter: 'fuzzyText',
        },
        {
            Header: 'Triggered ',
            accessor: 'triggered',
            filter: 'fuzzyText',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
        },
    ] as Column<ActiveAlertData>[];

const activeAlerts: ActiveAlertData[] = [
    {
        ...configuredAlerts[0],
        status: 'Alert',
        triggered: '50m',
        value: 10,
    },
    {
        ...configuredAlerts[0],
        status: 'Warning',
        triggered: '2hr',
        value: 3,
    },
    {
        ...configuredAlerts[0],
        status: 'Warning',
        triggered: '4hr',
        value: 2,
    },
    {
        ...configuredAlerts[1],
        status: 'No Data',
        triggered: 'Yesterday',
        value: 0,
    },
    {
        ...configuredAlerts[2],
        status: 'Alert',
        triggered: '10w',
        value: 4,
    },
    {
        ...configuredAlerts[3],
        status: 'Alert',
        triggered: '7hr',
        value: 4,
    },
];

export const ActiveAlertsMock = (): JSX.Element => {
    const [selectedAlert, setSelectedAlert] = useState<ActiveAlertData | undefined>();
    const [toggleFilters, setToggleFilters] = useState(false);

    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            fuzzyNodeDisplayName: fuzzyNodeDisplayNameFilterFn,
            greaterThan: filterGreaterThan,
        }),
        [],
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            minWidth: 100,
            width: 200,
            maxWidth: 800,
        }),
        [],
    );

    const columns: Column<ActiveAlertData>[] = useMemo(ActiveAlertsColumns, []);

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable<ActiveAlertData>(
        {
            columns,
            data: activeAlerts,
            defaultColumn,
            filterTypes,
            disableMultiSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: 40,
                sortBy: [{ id: 'name' }],
            },
        },
        useFilters,
        useSortBy,
        usePagination,
        useFilters,
        useBlockLayout,
        useResizeColumns,
    );

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <p className="uppercase tracking-wider font-bold text-xs text-gray-400">Active Alerts</p>
                <div className="flex items-center space-x-2 text-xs">
                    {!selectedAlert && (
                        <>
                            <button
                                type="button"
                                className="btn rounded-md px-3 py-2 focus-none"
                                onClick={() => setToggleFilters(!toggleFilters)}
                            >
                                <FunnelIcon className="h-4 w-4" />
                            </button>
                            <button type="button" className="btn rounded-md px-3 py-2 focus-none">
                                Resolve All
                            </button>
                            <button type="button" className="btn rounded-md px-3 py-2 focus-none">
                                Mute All
                            </button>
                        </>
                    )}
                    <Menu as="div" className="relative inline-block text-left ml-2 z-10">
                        <div>
                            <Menu.Button className="inline-flex justify-center w-full rounded-md px-3 py-2 bg-blue-700 text-xs font-medium text-white hover:bg-blue-800 focus:outline-none">
                                Actions
                                <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <CheckCircleIcon
                                                    className="mr-3 h-5 w-5 text-green-400"
                                                    aria-hidden="true"
                                                />
                                                Publish
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <PauseIcon
                                                    className="mr-3 h-5 w-5 text-yellow-400"
                                                    aria-hidden="true"
                                                />
                                                Pause
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <NoSymbolIcon
                                                    className="mr-3 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                />
                                                Disable
                                            </a>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
            {selectedAlert ? (
                <div className="mt-6 ml-4">
                    <form>
                        <div className="">
                            <h4 className="font-bold text-xs mb-2">Alert Details</h4>
                            <div className="space-y-2">
                                <div>
                                    <label className="text-xs">
                                        <span className="w-40 text-right pr-3 pt-2 text-gray-400">Alert Name</span>
                                        <p className="m-2">{selectedAlert.name}</p>
                                    </label>
                                </div>
                                <div>
                                    <label className="text-xs">
                                        <span className="w-40 text-right pr-3 pt-2 text-gray-400">Query</span>
                                        <div className="bg-gray-800 m-2 px-4 py-2 w-2/3 rounded-md text-xxs">
                                            <pre>{selectedAlert.query}</pre>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <label className="text-xs">
                                        <span className="w-40 text-right pr-3 pt-2 text-gray-400">Alert Message</span>
                                        <div className="bg-gray-800 m-2 px-4 py-2 w-2/3 rounded-md text-xxs">
                                            <pre>{selectedAlert.message}</pre>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6">
                            <h4 className="font-bold text-xs mb-2">Triggered Threshold</h4>
                            <div className="ml-2.5 space-y-2">
                                <label className="text-xs flex items-center w-64 justify-between">
                                    <span className="pr-3 text-gray-400">
                                        <span className="inline-block w-2 h-2 bg-red-500 mr-1" />
                                        Alert triggered where result {selectedAlert.value} {'>'} {selectedAlert.alert}
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="mt-6">
                            <h4 className="font-bold text-xs mb-2">Delivered Destinations</h4>
                            <div>
                                <ul className="space-y-1 ml-2 pr-1 text-xxs">
                                    {selectedAlert.destination.map((recipient) => {
                                        return (
                                            <li className="flex items-center justify-between" key={recipient.value}>
                                                <span className="flex items-center space-x-1 capitalize">
                                                    <CheckCircleIcon className="h-4 text-green-600" />
                                                    <h4>
                                                        {recipient.label}
                                                        <span className="text-gray-500">
                                                            {' '}
                                                            — {recipient.description}
                                                        </span>
                                                    </h4>
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-6 flex space-x-2">
                            <button type="submit" className="text-xs btn btn-primary rounded-md">
                                Resolve Alert
                            </button>
                            <button
                                type="submit"
                                className="text-xs btn  rounded-md"
                                onClick={() => setSelectedAlert(undefined)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="overflow-scroll flex-1 h-[calc(100vh-208px)] mt-6">
                    <table {...getTableProps()} className="w-full text-left text-xs mb-4">
                        <thead className="sticky top-0 bg-gray-700">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()} className="pt-2 px-2 align-middle bg-gray-700">
                                            <div className="flex items-center mb-2">
                                                <span>{column.render('Header')}</span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ChevronDownIcon
                                                            className="h-5 w-5"
                                                            {...column.getSortByToggleProps()}
                                                        />
                                                    ) : (
                                                        <ChevronUpIcon
                                                            className="h-5 w-5"
                                                            {...column.getSortByToggleProps()}
                                                        />
                                                    )
                                                ) : (
                                                    <ChevronUpDownIcon
                                                        className="h-5 w-5"
                                                        {...column.getSortByToggleProps()}
                                                    />
                                                )}
                                                <div
                                                    {...column.getResizerProps()}
                                                    className={column.isResizing ? 'resizerIsResizing' : 'resizer'}
                                                />
                                            </div>
                                            {toggleFilters && (
                                                <div className="pl-1">
                                                    {column.canFilter ? column.render('Filter') : null}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="overflow-y-hidden pb-[40px] bg-gray-600">
                            {activeAlerts.length > 0 &&
                                page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className="text-gray-300 hover:bg-gray-900 py-1"
                                            onClick={() => {
                                                setSelectedAlert(row.original);
                                            }}
                                        >
                                            {row.cells.map((cell, idx) => {
                                                switch (cell.column.id) {
                                                    case 'actions':
                                                        return (
                                                            <td {...cell.getCellProps()} className="px-2 ">
                                                                <div className="flex space-x-2 items-center ">
                                                                    <button className="btn py-1 px-2 rounded-md">
                                                                        Mute
                                                                    </button>
                                                                    <button className="btn py-1 px-2 rounded-md">
                                                                        Resolve
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        );
                                                    case 'status':
                                                        return (
                                                            <td {...cell.getCellProps()} className="px-2 ">
                                                                <div className="flex space-x-2 items-center uppercase text-xxs">
                                                                    {cell.value === 'Ok' && (
                                                                        <span className="bg-green-600 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                            Ok
                                                                        </span>
                                                                    )}
                                                                    {cell.value === 'Warning' && (
                                                                        <span className="bg-yellow-500 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                            Warning
                                                                        </span>
                                                                    )}
                                                                    {cell.value === 'Alert' && (
                                                                        <span className="bg-red-600 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                            Alert
                                                                        </span>
                                                                    )}
                                                                    {cell.value === 'No Data' && (
                                                                        <span className="bg-gray-700 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                            No Data
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        );
                                                    case 'muted':
                                                        return (
                                                            <td {...cell.getCellProps()} className="px-2 ">
                                                                <div className="flex space-x-2 items-center ">
                                                                    {cell.value === true ? (
                                                                        <span className=" py-1 px-2">
                                                                            <BellSlashIcon className="h-4 w-4 text-white" />
                                                                        </span>
                                                                    ) : (
                                                                        <span className="py-1 px-2">
                                                                            <BellIcon className="h-4 w-4 text-gray-700 hover:text-gray-200" />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        );
                                                    default:
                                                        return <DefaultCell cell={cell} key={idx} />;
                                                }
                                            })}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
