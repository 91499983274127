import { Link, Node, PolicyStats } from 'Types/types';
import { Layers, Visible } from 'Types/types';
import { ForceGraphMethods } from 'react-force-graph-2d';
import { getDisplayName, isNodeHidden } from 'Utilities/utils';
import { getNodeIconElement, getOSIconElement, getTagIconElement } from './Icons';

export const BASE_NODE_R = 1.5;

const RED = '#e11d48';
const AMBER = '#f59e0b';
const GREEN = '#65a30d';
const DARK_GRAY = '#414652';
const LIGHT_GRAY = '#b4bac2';
const BLUE = '#36a8fa';
const PURPLE = '#A249D5';

// Degrees to radians
const degToRad = (deg: number): number => {
    return deg * (Math.PI / 180);
};

const DEG_360 = degToRad(360);

export const paintNodePointerArea = (node: Node, color: string, ctx: CanvasRenderingContext2D): void => {
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.arc(node.x, node.y, node.__bckgDimensions || 0, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.closePath();
};

// Draw an arc with a stroke between x and y degrees
export const drawArc = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    radius: number,
    start: number,
    end: number,
    color: string,
    width: number,
): void => {
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.arc(x, y, radius * 2, start, end, false);
    ctx.stroke();
};

const getPointAlongPath = (distance: number, source: Node, target: Node) => {
    // (((1−𝑡)𝑥0+𝑡𝑥1),((1−𝑡)𝑦0+𝑡𝑦1))
    const x = (1 - distance) * source.x + distance * target.x;
    const y = (1 - distance) * source.y + distance * target.y;
    return { x, y };
};

export const paintLink = (
    link: Link,
    linkSelected: boolean,
    linkHighlighted: boolean,
    globalScale: number,
    layers: Layers,
    ctx: CanvasRenderingContext2D,
): void => {
    const source = link.source;
    const target = link.target as Node;

    if (linkHighlighted || linkSelected) {
        ctx.lineWidth = 0.25;
    } else {
        ctx.lineWidth = 0.1;
    }

    const stats = link.policyStats;
    if (
        (layers.events || linkHighlighted || linkSelected) &&
        (source.label === 'target' || target.label === 'target') &&
        stats
    ) {
        const { x, y } = getPointAlongPath(stats.critical / 100, source, target);
        ctx.beginPath();
        ctx.strokeStyle = RED;
        ctx.moveTo(source.x, source.y);
        ctx.lineTo(x, y);
        ctx.stroke();

        const { x: x2, y: y2 } = getPointAlongPath((stats.critical + stats.warning) / 100, source, target);
        ctx.beginPath();
        ctx.strokeStyle = AMBER;
        ctx.moveTo(x, y);
        ctx.lineTo(x2, y2);
        ctx.stroke();

        const { x: x3, y: y3 } = getPointAlongPath(
            (stats.critical + stats.warning + stats.success) / 100,
            source,
            target,
        );
        ctx.beginPath();
        ctx.strokeStyle = GREEN;
        ctx.moveTo(x2, y2);
        ctx.lineTo(x3, y3);
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = LIGHT_GRAY;
        ctx.moveTo(x3, y3);
        ctx.lineTo(target.x, target.y);
        ctx.stroke();
    } else {
        if (linkHighlighted || linkSelected) {
            ctx.strokeStyle = LIGHT_GRAY;
        } else {
            ctx.strokeStyle = DARK_GRAY;
        }
        ctx.beginPath();
        ctx.moveTo(source.x, source.y);
        ctx.lineTo(target.x, target.y);
        ctx.stroke();
    }
};

export const paintLinkPointerArea = (link: Link, color: string, ctx: CanvasRenderingContext2D): void => {
    const source = link.source;
    const target = link.target as Node;

    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.moveTo(source.x, source.y);
    ctx.lineTo(target.x, target.y);
    ctx.stroke();
    ctx.closePath();
};

const drawTargetWithPolicyStats = (
    ctx: CanvasRenderingContext2D,
    node: Node,
    stats: PolicyStats,
    selected: boolean,
    scale: number,
) => {
    const s = transformToDegrees(stats);
    const statsWidth = 0.7;

    // Draw "success" segment
    drawArc(ctx, node.x, node.y, scale + statsWidth / 4, 0, degToRad(s.success), GREEN, statsWidth);

    // Draw warning segment
    drawArc(
        ctx,
        node.x,
        node.y,
        scale + statsWidth / 4,
        degToRad(s.success),
        degToRad(s.success + s.warning),
        AMBER,
        statsWidth,
    );

    // Draw critical segment
    drawArc(
        ctx,
        node.x,
        node.y,
        scale + statsWidth / 4,
        degToRad(s.success + s.warning),
        degToRad(s.success + s.warning + s.critical),
        RED,
        statsWidth,
    );

    // Fill the rest with neutral
    drawArc(
        ctx,
        node.x,
        node.y,
        scale + statsWidth / 4,
        degToRad(s.success + s.warning + s.critical),
        degToRad(360),
        LIGHT_GRAY,
        statsWidth,
    );
    if (selected) {
        drawArc(ctx, node.x, node.y, scale + statsWidth / 2 + (0.15 / 4) * 3 - 0.01, 0, DEG_360, LIGHT_GRAY, 0.2);
    }
};

const drawTag = (
    ctx: CanvasRenderingContext2D,
    node: Node,
    nodeRadius: number,
    tagRadius: number,
    iconSize: number,
    positionOnNode: number,
    icon: HTMLImageElement,
) => {
    // formulas for finding a point on a circle from the center: x = r * cos(angle), y = r * sin(angle)
    const x = node.x + nodeRadius * 2.2 * Math.cos(positionOnNode);
    const y = node.y + nodeRadius * 2.2 * Math.sin(positionOnNode);
    ctx.beginPath();
    ctx.arc(x, y, tagRadius, 0, DEG_360, false);
    ctx.fillStyle = '#1f2937';
    ctx.lineWidth = 0.05;
    ctx.fill();
    ctx.stroke();
    ctx.drawImage(icon, x - iconSize / 2, y - iconSize / 2, iconSize, iconSize);
};

export const paintNode = (
    node: Node,
    nodeIsInExplorer: boolean,
    nodeHighlighted: boolean,
    nodeIsInQuery: boolean,
    globalScale: number,
    layers: Layers,
    ctx: CanvasRenderingContext2D,
    selectedNodes: Set<Node>,
    lockedNodes: Set<Node>,
): void => {
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    const { scale, icon } = getNodeDrawProperties(node);

    const highlightScale = nodeHighlighted ? 1.0 : 1;
    const strokeWidth = nodeIsInExplorer || nodeHighlighted ? 0.3 : 0.05;
    const clampedGlobalScale = Math.min(Math.max(globalScale / 10, 1), 1.2);
    const isLocked = lockedNodes.has(node);

    const SCALED_NODE_R = BASE_NODE_R * highlightScale * scale * clampedGlobalScale;
    const CIRCUMFERENCE = 2 * SCALED_NODE_R;
    const TAG_RADIUS = 1.1;
    const ICON_SIZE = TAG_RADIUS * 1.2;

    // Draw a solid circle in the color of the background, this creates a nice
    // solid background for the node and covers the links that  originate from
    // the center of the node
    ctx.beginPath();
    ctx.arc(node.x, node.y, CIRCUMFERENCE, 0, DEG_360, false);
    ctx.fillStyle = 'rgba(17, 24, 39)';
    ctx.fill();

    // Add the icon for the node
    const iconScale = node.label === 'target' ? 1.2 : 1;
    ctx.drawImage(
        icon,
        node.x - SCALED_NODE_R * iconScale,
        node.y - SCALED_NODE_R * iconScale,
        SCALED_NODE_R * 2 * iconScale,
        SCALED_NODE_R * 2 * iconScale,
    );

    isLocked && drawArc(ctx, node.x, node.y, SCALED_NODE_R * 1.1, 0, DEG_360, PURPLE, 0.4);
    nodeIsInQuery &&
        drawArc(ctx, node.x, node.y, SCALED_NODE_R * (nodeIsInExplorer ? 1.3 : 1.2), 0, DEG_360, BLUE, 0.2);
    switch (node.label) {
        case 'target':
            if ((nodeHighlighted || nodeIsInExplorer || layers.events) && node.policyStats) {
                const policyStats = node.policyStats;
                if (policyStats) {
                    drawTargetWithPolicyStats(
                        ctx,
                        node,
                        policyStats,
                        nodeIsInExplorer && !nodeIsInQuery,
                        SCALED_NODE_R,
                    );
                    isLocked && drawArc(ctx, node.x, node.y, SCALED_NODE_R * 1.3, 0, DEG_360, PURPLE, 0.35);
                }
            } else {
                drawArc(ctx, node.x, node.y, SCALED_NODE_R, 0, DEG_360, LIGHT_GRAY, strokeWidth);
            }

            break;
        case 'identity':
            if (nodeHighlighted || layers.posture) {
                switch (node.posture) {
                    case 'critical':
                        drawArc(ctx, node.x, node.y, SCALED_NODE_R, 0, DEG_360, 'rgba(251, 74, 74, 0.8)', strokeWidth);
                        break;
                    case 'warning':
                        drawArc(ctx, node.x, node.y, SCALED_NODE_R, 0, DEG_360, 'rgba(251, 201, 74, 0.8)', strokeWidth);
                        break;
                    default:
                        drawArc(ctx, node.x, node.y, SCALED_NODE_R, 0, DEG_360, LIGHT_GRAY, strokeWidth);
                }
            } else {
                drawArc(ctx, node.x, node.y, SCALED_NODE_R, 0, DEG_360, LIGHT_GRAY, strokeWidth);
            }
            break;
        case 'device':
            drawArc(ctx, node.x, node.y, SCALED_NODE_R, 0, DEG_360, LIGHT_GRAY, strokeWidth);
            break;
        case 'actor':
            drawArc(ctx, node.x, node.y, SCALED_NODE_R, 0, DEG_360, LIGHT_GRAY, strokeWidth);
            break;
        case 'application':
            drawArc(ctx, node.x, node.y, SCALED_NODE_R, 0, DEG_360, LIGHT_GRAY, strokeWidth);
            break;
        default:
            break;
    }

    // Draw the label (tag) layer for all nodes if enabled
    if (layers.labels) {
        // Calculate the total number of tags so we can evenly distribute them
        const numberOfTags =
            node.label == 'device' && node.props.deviceOperatingSystem ? node.tags.length + 1 : node.tags.length;

        // 45 degrees is the angle of the first tag (in radians)
        let angle = 0.785;

        // how many radians between each tag
        const step = (2 * Math.PI) / numberOfTags;

        // run through all tags and draw them
        node.tags.map((tag) => {
            const icon = getTagIconElement(tag);
            drawTag(ctx, node, SCALED_NODE_R, TAG_RADIUS, ICON_SIZE, angle, icon);
            angle += step;
        });

        // if the node is a device, draw the operating system tag
        if (node.label == 'device' && node.props.deviceOperatingSystem) {
            const icon = getOSIconElement(node);
            drawTag(ctx, node, SCALED_NODE_R, TAG_RADIUS, ICON_SIZE, angle, icon);
            angle += step;
        }
    }

    // Add a name label for the node if it is highlighted or the names layer is enabled
    if (layers.names || nodeHighlighted) {
        if (node.label !== 'identity') {
            ctx.font = '1.75px Poppins';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'darkgrey';
            const text = getDisplayName(node);
            ctx.fillText(text, node.x, node.y - SCALED_NODE_R - 4);
            const textWidth = ctx.measureText(text).width;

            if (nodeIsInExplorer || nodeIsInQuery) {
                // Draw a 5 px green circle to the left of the name label
                ctx.beginPath();
                ctx.arc(node.x - textWidth / 2 - 1.5, node.y - SCALED_NODE_R - 4, 0.5, 0, 2 * Math.PI, false);
                ctx.fillStyle = nodeIsInQuery ? BLUE : LIGHT_GRAY;
                ctx.fill();
            }
        }
    }

    // Set the background dimensions used for the mouse hover area
    node.__bckgDimensions = SCALED_NODE_R * 2 + strokeWidth / 2;
};

type DrawProperties = {
    scale: number;
    icon: HTMLImageElement;
};

const getNodeDrawProperties = (node: Node): DrawProperties => {
    const icon = getNodeIconElement(node);
    let scale = 0;
    switch (node.label) {
        case 'actor':
            scale = 1;
            break;
        case 'identity':
            scale = 0.5;
            break;
        case 'device':
            scale = 0.9;
            break;
        case 'target':
            scale = 1.1;
            break;
        case 'application':
            scale = 0.9;
            break;
        default:
            scale = 1;
            break;
    }
    return { scale, icon };
};

const transformToDegrees = (stats: PolicyStats): PolicyStats => {
    const tmpStats: PolicyStats = { success: 0, warning: 0, critical: 0, neutral: 0 };
    tmpStats.critical = stats.critical * 3.6;
    tmpStats.success = stats.success * 3.6;
    tmpStats.warning = stats.warning * 3.6;
    tmpStats.neutral = stats.neutral * 3.6;
    return tmpStats;
};

export const renderFramePost = (
    ctx: CanvasRenderingContext2D,
    globalScale: number,
    nodes: Node[],
    nodeTypesVisible: Visible,
    groups: string[],
    graph: ForceGraphMethods,
) => {
    groups.map((group) => {
        const nodesInGroup = [];

        // Ensure the nodes are in this group and that we should be drawing them
        const groupBox = graph.getGraphBbox((n) => {
            const node = n as Node;
            const inGroup = String(node.group) === group;
            const isHidden = isNodeHidden(node, nodeTypesVisible);

            if (inGroup && !isHidden) {
                nodesInGroup.push(node);
                return true;
            }
            return false;
        });

        // If there are no nodes in this group, skip it
        if (!groupBox) {
            return;
        }

        // Find the x and y center coordinates of all the nodes in group
        const centerPosition: [x: number, y: number] = [
            (groupBox.x[0] + groupBox.x[1]) / 2,
            (groupBox.y[0] + groupBox.y[1]) / 2,
        ];

        // Find the radius needed to cover all nodes in the group, and add a little bit of padding (10%)
        const radius = (Math.max(groupBox.x[1] - groupBox.x[0], groupBox.y[1] - groupBox.y[0]) / 2) * 1.1;

        ctx.save();
        ctx.translate(centerPosition[0], centerPosition[1]);

        // Draw the group perimeter
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, DEG_360, false);
        ctx.stroke();
        ctx.closePath();

        // Draw the group label
        let labelDistance = 10;
        if (nodesInGroup.length == 1) {
            ctx.font = '3px Poppins';
            labelDistance = 5;
        } else if (nodesInGroup.length > 1 && nodesInGroup.length <= 6) {
            ctx.font = '4px Poppins';
            labelDistance = 7;
        } else {
            ctx.font = '6px Poppins';
        }
        ctx.translate(0, -radius - labelDistance);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'darkgrey';
        ctx.fillText(group, 0, 0);
        ctx.restore();
    });
};
