import Form from './Form';
import { useState } from 'react';
import EntryPage from 'Onboarding/ExternalPage';
import TermsOfService from 'Onboarding/SignUp/ToS';
import { Helmet } from 'react-helmet-async';
import { SignUpStep } from './SignUpTypes';

const SignUp = (): JSX.Element => {
    const [step, setStep] = useState(SignUpStep.Form);

    return (
        <EntryPage>
            <Helmet>
                <title>Sign Up</title>
            </Helmet>
            {step === SignUpStep.Form && <Form setStep={setStep} />}
            {step === SignUpStep.TermsOfService && <TermsOfService setStep={setStep} />}
        </EntryPage>
    );
};

export default SignUp;
