import logo from 'assets/logo-192x192.png';
import { useAuth } from 'Hooks/Auth';
import ExternalPage from 'Onboarding/ExternalPage';
import { Helmet } from 'react-helmet-async';

const NoRoles = (): JSX.Element => {
    const { login, logout } = useAuth();
    return (
        <ExternalPage>
            <Helmet>
                <title>Account Activated</title>
            </Helmet>
            <div className="pb-10">
                <img className="h-12 w-auto" src={logo} alt="SailPoint Identity Risk Icon" />
                <h2 className="text-3xl font-extrabold text-gray-200">Welcome to SailPoint Identity Risk</h2>
                <p className="mt-4 text-sm text-gray-300">
                    You have successfully authenticated to SailPoint Identity Risk, however there are no roles assigned
                    to your account.
                    <br />
                    <br />
                    Please contact your administrator to assign a role before proceeding.
                    <br />
                    <br />
                    <span className="text-xs text-gray-500">
                        If you have any questions, please contact support@doublezero.io
                    </span>{' '}
                    <span className="mt-6 flex place-items-center">
                        <button onClick={login} className="btn btn-primary w-3/7 text-sm rounded-md">
                            Try Again
                        </button>
                        <span className="ml-2 text-gray-400 text-xs">or</span>
                        <button onClick={logout} className="ml-1 text-gray-400 text-xs hover:text-blue-600">
                            Logout
                        </button>
                    </span>
                    <br />
                    <br />
                    <br />
                </p>
            </div>
        </ExternalPage>
    );
};

export default NoRoles;
