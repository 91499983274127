import { useEffect, useContext, useState } from 'react';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { TourContext } from './TourProvider';
import { classNames } from 'Utilities/utils';
import { getTourStepName } from 'Map/State/TourReducer';
import { useLocalStorage, useProductTutorial } from 'Hooks/Hooks';
import { ToastContext } from './ToastContext';

type Data = {
    action: string;
    index: number;
    type: string;
    status: string;
};

// Define the Tour component
export const Tour = () => {
    const { mapState, dispatch: identityMapDispatch } = useContext(IdentityMapContext);
    const { productTourState, dispatch } = useContext(TourContext);
    const { dispatch: toastDispatch } = useContext(ToastContext);
    const [nodeXY, setNodeXY] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const { getScopedStorageKey } = useLocalStorage();
    const { startTutorial, runOnTutorialStep, moveToNextTutorialStep, centerMapOnNode } = useProductTutorial();

    const callback = (data: Data) => {
        const { action, index, type, status } = data;
        if (
            action === ACTIONS.CLOSE ||
            (status === STATUS.SKIPPED && productTourState.run) ||
            status === STATUS.FINISHED
        ) {
            dispatch({ type: 'stop-tour' });
            const key = getScopedStorageKey('tourSeen');
            if (key) {
                localStorage.setItem(key, 'yes');
                toastDispatch({
                    type: 'add-toast',
                    message: `Tutorial data has been removed from the Identity Map. Your data will be loaded if you have configured Providers.`,
                    status: 'information',
                    autoTimeout: true,
                    timeoutTimer: 15,
                });
            }
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            runOnTutorialStep(['Click', 'Drag', 'Move Around', 'Multi-select Nodes'], () => {
                centerMapOnNode('Star Patrick');
            });
            dispatch({
                type: 'move-between-steps',
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
            });
            if (getTourStepName(productTourState) == 'Last Step') {
                identityMapDispatch({ type: 'toggle-config' });
                identityMapDispatch({ type: 'toggle-views' });
            }
        }
    };

    useEffect(() => {
        const key = getScopedStorageKey('tourSeen');
        if (key) {
            const tourSeen = localStorage.getItem(key);
            if ((!tourSeen || tourSeen !== 'yes') && !productTourState.run) {
                startTutorial();
            }
        }
    }, [getScopedStorageKey, productTourState.run, startTutorial]);

    // On the multi-select tour step, we want to ensure 5 or more nodes have been selected
    // before moving onwards to the next step.
    useEffect(() => {
        runOnTutorialStep('Multi-select Nodes', () => {
            if (mapState.selectedNodes.size >= 5) {
                moveToNextTutorialStep();
            }
        });
    }, [mapState.selectedNodes.size, moveToNextTutorialStep, runOnTutorialStep]);

    /**
     * This effect is used to position the tour popup over the selected node for the
     * current step in the tour.
     *
     * The tour step will include either a nodeId or a node name. Based on the input the
     * effect will lookup the corresponding node and set the x and y coordinates for the
     * tour popup.
     *
     * The x/y coordinates need to be translated from the node's position on
     * the canvas to the position of the node on the screen. This is handled by the
     * graph2ScreenCoordinates function in the force-graph library.
     */
    useEffect(() => {
        if (productTourState.run) {
            const step = productTourState.steps[productTourState.stepIndex];
            if (step && step.target === '#NodeCanvasPointer') {
                let node: Node | undefined;

                if (step.nodeName) {
                    node = mapState.graphData.nodes.find((node) => node.props.displayName === step.nodeName);
                } else if (step.nodeId) {
                    node = mapState.graphData.nodes.find((node) => node.id === step.nodeId);
                }

                if (node && mapState.graphRef?.current) {
                    const screenDomainCoords = mapState.graphRef.current.graph2ScreenCoords(node.x, node.y);
                    setNodeXY(screenDomainCoords);
                    console.debug('Moving tour node pointer div to:', screenDomainCoords, 'over node:', node);
                } else {
                    console.log('No node found for step', step);
                }
            }
        }
    }, [
        mapState.graphData.nodes,
        mapState.graphRef,
        productTourState.run,
        productTourState.stepIndex,
        productTourState.steps,
    ]);

    return (
        <>
            <div className="absolute top-0 left-0 pointer-events-auto">
                <JoyRide
                    {...productTourState}
                    callback={callback}
                    showSkipButton={true}
                    styles={{
                        tooltipContainer: {
                            textAlign: 'left',
                        },

                        buttonBack: {
                            marginRight: 10,
                            backgroundColor: 'rgb(75 85 99)',
                            color: 'rgb(209, 213, 219)',
                        },
                        buttonSkip: {
                            backgroundColor: 'rgb(75 85 99)',
                        },
                        options: {
                            zIndex: 10000,
                            arrowColor: 'rgb(55 65 81)',
                            backgroundColor: 'rgb(55 65 81)',
                            overlayColor: 'rgb(65 65 65 / 40%)',
                            primaryColor: 'rgb(62 140 203)',
                            textColor: 'rgb(209 213 219)',
                            beaconSize: 36,
                            width: 400,
                        },
                        tooltipContent: {
                            padding: '10px',
                        },
                        tooltipFooter: {
                            marginTop: 8,
                            padding: '0px 10px',
                        },
                    }}
                    locale={{
                        last: 'End tour',
                    }}
                />
                <div
                    id="NodeCanvasPointer"
                    className={classNames('w-8 h-8 absolute')}
                    style={{ top: nodeXY.y - 15, left: nodeXY.x - 15 }}
                ></div>
            </div>
        </>
    );
};
export default Tour;
