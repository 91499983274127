import { capitalize, providerNameLookup, wallTypeLookup } from 'Utilities/utils';
import { GET_WALL_EVENTS_FOR_NODE } from 'Graph/queries';
import { formatRelative } from 'date-fns';

import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTenant } from 'Hooks/Hooks';

import { useGraphControls } from 'Hooks/GraphHooks';
import { List, ListItem } from 'Library/List';
import { WallEvent, getWallProviderIcon } from 'Dashboard/Wall/TheWallComponents';
import { AttributeProps } from '../ProfileTypes';

export const NodeTheWall = ({ node, startDate, endDate }: AttributeProps): JSX.Element => {
    const tenantId = useTenant();

    const { addNodeToExplorer } = useGraphControls();

    const [wallEvents, setWallEvents] = useState<WallEvent[]>([]);

    const { loading, error, data } = useQuery(GET_WALL_EVENTS_FOR_NODE, {
        variables: { tenantId, startDate, endDate, nodeId: node.id },
    });

    useEffect(() => {
        if (data) {
            if (data.getWallEvents && data.getWallEvents.events) {
                setWallEvents([...data.getWallEvents.events]);
            }
        }
    }, [data]);

    return (
        <div className="max-h-full">
            <List title="" loading={loading} error={Boolean(error)}>
                {wallEvents.slice(0, 17).map((event) => {
                    return (
                        <ListItem
                            key={event.key}
                            title={wallTypeLookup(event.type)}
                            description={`Observed by ${providerNameLookup(event.sourceProcessorType)}`}
                            secondDescription={capitalize(formatRelative(event.publishedAt, new Date()))}
                            icon={<img src={getWallProviderIcon(event.sourceProcessorType)} className="h-8 w-8" />}
                            onClick={() => {
                                const node = {
                                    id: event.nodeId,
                                    name: event.displayName,
                                    x: 0,
                                    y: 0,
                                    links: [],
                                    neighbors: [],
                                    group: 'actor',
                                    label: 'actor',
                                    props: { displayName: event.displayName, alternateId: event.alternateId },
                                    tags: [],
                                    attributes: [],
                                };
                                addNodeToExplorer(node, false, true);
                            }}
                        />
                    );
                })}
            </List>
        </div>
    );
};
