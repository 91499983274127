import { useFloatingPortalNode } from '@floating-ui/react-dom-interactions';
import ReactDOM from 'react-dom';

export const FloatingPortalRoot = (): JSX.Element => {
    // Create floating-ui portal node to lift tooltips to root-level
    const portalNode = useFloatingPortalNode();

    if (portalNode) {
        return ReactDOM.createPortal(<div className="FloatingPortalRoot"></div>, portalNode);
    }

    return <></>;
};
