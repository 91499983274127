import { cloneElement, useState, useMemo, memo } from 'react';
import {
    Placement,
    FloatingPortal,
    offset,
    flip,
    autoUpdate,
    useFloating,
    useInteractions,
    useHover,
    useFocus,
    useRole,
    useDismiss,
    useDelayGroupContext,
    useDelayGroup,
    shift,
    useClick,
} from '@floating-ui/react-dom-interactions';
import { mergeRefs } from 'react-merge-refs';
import { classNames } from 'Utilities/utils';

interface Props {
    className?: string;
    label: string;
    placement?: Placement;
    zIndex?: number;
    children: JSX.Element;
}

export const TooltipBase = ({ className, children, label, placement = 'top', zIndex = 9000 }: Props) => {
    const { setCurrentId } = useDelayGroupContext();
    const [open, setOpen] = useState(false);

    const { x, y, reference, floating, strategy, context } = useFloating({
        placement,
        open,
        onOpenChange(open: boolean) {
            setOpen(open);

            if (open) {
                setCurrentId(label);
            }
        },
        middleware: [offset(5), flip(), shift({ padding: 8 })],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, { delay: { open: 200, close: 0 } }),
        useClick(context, { enabled: false }),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
        useDelayGroup(context, { id: label }),
    ]);

    // Preserve the consumer's ref, which can be any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useMemo(() => mergeRefs([reference, (children as any).ref]), [reference, children]);

    return (
        <>
            {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
            <FloatingPortal>
                {open && (
                    <div
                        ref={floating}
                        className={classNames(
                            'Tooltip',
                            zIndex && zIndex ? `z-[${zIndex}]` : '',
                            'text-center px-2 py-1.5 rounded-md text-xs bg-black/90',
                            className ? className : '',
                        )}
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                        }}
                        {...getFloatingProps()}
                    >
                        {label}
                    </div>
                )}
            </FloatingPortal>
        </>
    );
};

export const Tooltip = memo(TooltipBase);
