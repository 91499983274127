import logo from 'assets/logo-192x192.png';
import ExternalPage from 'Onboarding/ExternalPage';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

const Confirmation = (): JSX.Element => {
    const history = useHistory();

    return (
        <ExternalPage>
            <Helmet>
                <title>Account Created - Welcome to SailPoint Identity Risk</title>
            </Helmet>
            <div className="pb-10">
                <img className="h-12 w-auto" src={logo} alt="SailPoint Identity Risk Icon" />
                <h2 className="text-3xl font-extrabold text-gray-200">Account Created</h2>
                <p className="mt-4 text-sm text-gray-300">
                    We have received your request to create a Trust Fabric
                    <br />
                    <br />
                    You will receive a password reset link via email shortly
                    <br />
                    <br />
                    You may sign in after setting your password
                </p>
                <div className="mt-8 space-y-8">
                    <button
                        onClick={() => history.push('/identity-map')}
                        className="btn btn-primary w-3/7 text-sm rounded-md"
                    >
                        Sign In
                    </button>
                </div>
            </div>
        </ExternalPage>
    );
};

export default Confirmation;
