import { ForwardIcon } from '@heroicons/react/24/solid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext, useState } from 'react';
import { Tooltip } from './Tooltip';

export const SkipToTime = ({ startDate, endDate }: { startDate: number; endDate: number }): JSX.Element => {
    const { maxTimeSelectorWindow } = useFlags();
    const { dispatch } = useContext(IdentityMapContext);
    const [clicked, setClicked] = useState(false);
    const distanceInHours = (endDate - startDate) / 1000 / 60 / 60;

    const disabled = distanceInHours > maxTimeSelectorWindow;

    let tooltip = 'Set the map time to this interval';
    if (disabled) {
        tooltip = 'This interval is too long to display on the map';
    }
    if (clicked) {
        tooltip = 'Map time set';
    }

    return (
        <Tooltip label={clicked ? 'Map time set!' : 'Set the map time to this interval'}>
            <button
                data-test="skip-to-time"
                disabled={disabled}
                onMouseEnter={() => setClicked(false)}
                onMouseLeave={() => {
                    setTimeout(() => setClicked(false), 1000);
                }}
                data-tooltip={tooltip}
                data-hover={!clicked}
                onClick={() => {
                    dispatch({ type: 'set-selected-time', time: [startDate, endDate] });
                    setClicked(true);
                }}
            >
                {disabled ? (
                    <ForwardIcon className="ml-1 h-4 w-4 text-gray-600" />
                ) : (
                    <ForwardIcon className="ml-1 h-4 w-4 hover:text-blue-700 active:text-blue-800" />
                )}
            </button>
        </Tooltip>
    );
};
