import { endOfToday, isToday, startOfMinute, subDays, subHours, subMinutes } from 'date-fns';
import type { Node } from 'Types/types';
import { groupingForceApplied } from 'Utilities/utils';
import { applyGraphFilter } from './GraphFiltering';
import { getGroupTrail, getParentGroupTrail, isAGroupLeafNode, isATargetGroup } from 'Utilities/NodeUtilities';
import { GroupSchema } from 'Map/Refine/GroupingTypes';
import { IdentityMapState } from 'Types/types';
import { Action } from 'Types/types';

const now = startOfMinute(new Date());
const twoMinutesAgo = startOfMinute(subMinutes(now, 2));
const oneHourAgo = startOfMinute(subHours(now, 1));
const fiveHoursAgo = startOfMinute(subHours(now, 5));
const today = endOfToday();
const fiveDaysAgo = subDays(today, 5);

const largeDatasetNodeCount = 500;

export const initialMapState: IdentityMapState = {
    graphData: { nodes: [], links: [] },
    unfilteredGraphData: { nodes: [], links: [] },
    graphFilters: [],
    selectedNodes: new Set(),
    queriedNodes: new Set(),
    scrollToNode: undefined,
    lockedNodes: new Set(),
    previouslyLockedNodes: new Set(),
    rootHoveredNode: null,
    identityMapContextMenuOpen: false,
    selectedLink: undefined,
    selectedTime: [+fiveHoursAgo, +oneHourAgo],
    timeWindowMin: +fiveDaysAgo,
    timeWindowMax: +twoMinutesAgo,
    selectedPolicyTarget: undefined,
    previouslySelectedNodes: new Set(),
    filterOpen: false,
    viewsOpen: false,
    adjustmentsOpen: false,
    actionsOpen: false,
    configOpen: false,
    policyOpen: false,
    alertsOpen: false,
    timelineOpen: false,
    dashboardOpen: false,
    groupingOpen: false,
    agentsOpen: false,
    dateTimeOpen: false,
    profileOpen: false,
    maxProfileOpen: false,
    navigatorOpen: false,
    dataBrowserOpen: false,
    selectedProfileNode: undefined,
    selectedPermissionsNode: undefined,
    visible: { actors: true, targets: true, identities: true, devices: true, applications: true },
    layers: { labels: true, events: true, posture: false, names: false },
    newGroupPending: true,
    firstEventAt: undefined,
    lastEventAt: undefined,
    group: {
        actor: undefined,
        device: undefined,
        identity: undefined,
        target: undefined,
    },
    strengths: {
        applicationStrength: -1,
        actorStrength: -1,
        deviceStrength: -1,
        identityStrength: -2,
        targetStrength: -20,
        linkStrength: 0.1,
    },
    graphPosition: {
        x: 0,
        y: 0,
        k: 0,
    },
    levelTrails: new Set(),
    pendingView: undefined,
    largeDatasetLoading: false,
    smallDatasetLoading: false,
    timelineDragging: false,
    blockZoom: false,
    hasProviderError: false,
    hasProviderInfo: false,
    blockIdentityMapUpdates: false,
};
export const reducer = (state: IdentityMapState, action: Action): IdentityMapState => {
    switch (action.type) {
        case 'toggle-filter':
            return {
                ...state,
                filterOpen: !state.filterOpen,
                viewsOpen: false,
                groupingOpen: false,
            };
        case 'toggle-grouping':
            return {
                ...state,
                groupingOpen: !state.groupingOpen,
                viewsOpen: false,
                filterOpen: false,
            };
        case 'toggle-views':
            return {
                ...state,
                viewsOpen: !state.viewsOpen,
            };
        case 'toggle-adjustments':
            return {
                ...state,
                adjustmentsOpen: !state.adjustmentsOpen,
                profileOpen: false,
                viewsOpen: false,
            };
        case 'toggle-config':
            return {
                ...state,
                configOpen: !state.configOpen,
                dashboardOpen: false,
                viewsOpen: false,
                filterOpen: false,
                agentsOpen: false,
                policyOpen: false,
                navigatorOpen: false,
                alertsOpen: false,
                maxProfileOpen: false,
            };
        case 'toggle-actions':
            return {
                ...state,
                actionsOpen: !state.actionsOpen,
            };
        case 'toggle-date-time':
            return {
                ...state,
                dateTimeOpen: !state.dateTimeOpen,
                filterOpen: false,
                groupingOpen: false,
            };
        case 'toggle-profile':
            return {
                ...state,
                profileOpen: !state.profileOpen,
                viewsOpen: false,
            };
        case 'close-profile':
            return {
                ...state,
                profileOpen: false,
                viewsOpen: false,
                selectedProfileNode: undefined,
            };
        case 'toggle-max-profile':
            return {
                ...state,
                maxProfileOpen: !state.maxProfileOpen,
                viewsOpen: false,
            };
        case 'toggle-policy':
            return {
                ...state,
                policyOpen: !state.policyOpen,
                filterOpen: false,
                viewsOpen: false,
                timelineOpen: false,
                dashboardOpen: false,
                agentsOpen: false,
                configOpen: false,
                navigatorOpen: false,
                dataBrowserOpen: false,
                alertsOpen: false,
                maxProfileOpen: false,
            };
        case 'toggle-alerts':
            return {
                ...state,
                alertsOpen: !state.alertsOpen,
                policyOpen: false,
                filterOpen: false,
                viewsOpen: false,
                timelineOpen: false,
                dashboardOpen: false,
                agentsOpen: false,
                configOpen: false,
                navigatorOpen: false,
                dataBrowserOpen: false,
                maxProfileOpen: false,
            };
        case 'toggle-timeline':
            return {
                ...state,
                timelineOpen: !state.timelineOpen,
                filterOpen: false,
                agentsOpen: false,
                policyOpen: false,
                configOpen: false,
                navigatorOpen: false,
                dashboardOpen: false,
                alertsOpen: false,
                maxProfileOpen: false,
            };
        case 'toggle-dashboard':
            return {
                ...state,
                timelineOpen: false,
                dashboardOpen: !state.dashboardOpen,
                filterOpen: false,
                agentsOpen: false,
                policyOpen: false,
                configOpen: false,
                navigatorOpen: false,
                dataBrowserOpen: false,
                alertsOpen: false,
                maxProfileOpen: false,
            };
        case 'toggle-navigator':
            return {
                ...state,
                timelineOpen: false,
                dashboardOpen: false,
                filterOpen: false,
                agentsOpen: false,
                policyOpen: false,
                configOpen: false,
                dataBrowserOpen: false,
                navigatorOpen: !state.navigatorOpen,
                alertsOpen: false,
                maxProfileOpen: false,
            };
        case 'toggle-data-browser':
            return {
                ...state,
                timelineOpen: false,
                dashboardOpen: false,
                filterOpen: false,
                agentsOpen: false,
                policyOpen: false,
                configOpen: false,
                navigatorOpen: false,
                dataBrowserOpen: !state.dataBrowserOpen,
                alertsOpen: false,
                maxProfileOpen: false,
            };
        case 'toggle-agents':
            return {
                ...state,
                agentsOpen: !state.agentsOpen,
                filterOpen: false,
                timelineOpen: false,
                dashboardOpen: false,
                policyOpen: false,
                configOpen: false,
                navigatorOpen: false,
                dataBrowserOpen: false,
                alertsOpen: false,
                maxProfileOpen: false,
            };

        case 'reset-map':
            return {
                ...state,
                configOpen: false,
                policyOpen: false,
                alertsOpen: false,
                timelineOpen: false,
                dashboardOpen: false,
                groupingOpen: false,
                agentsOpen: false,
                navigatorOpen: false,
                dataBrowserOpen: false,
                maxProfileOpen: false,
            };

        case 'click-background':
            return {
                ...state,
                previouslySelectedNodes: state.selectedNodes,
                selectedNodes: new Set(),
                queriedNodes: new Set(),
                selectedLink: undefined,
                actionsOpen: false,
                filterOpen: false,
                profileOpen: false,
                maxProfileOpen: false,
                navigatorOpen: false,
                alertsOpen: false,
            };

        case 'click-link':
            if (state.selectedLink === action.link) {
                return {
                    ...state,
                    selectedLink: undefined,
                    actionsOpen: false,
                };
            }
            return {
                ...state,
                selectedLink: action.link,
                actionsOpen: true,
            };

        case 'set-graph-data':
            const start = Date.now();
            // Create a copy of the incoming object so that we can mutate it
            const unfilteredGraphData = { nodes: [...action.data.nodes], links: [...action.data.links] };
            // Filter out any nodes based on the currently applied graph filters (if there are any)
            const newGraphData = state.graphFilters ? applyGraphFilter(action.data, state.graphFilters) : action.data;

            // If any grouping forces are applied, we should re-calculate the groupings based on the new graph data
            if (groupingForceApplied(state.group)) {
                Object.entries(state.group).forEach(([label, attribute]) => {
                    if (label && attribute) {
                        const nodesToGroup = newGraphData.nodes.filter((node) => node.label === label);
                        const groupingSchema = GroupSchema[label as keyof typeof GroupSchema];
                        if (groupingSchema) {
                            const setGroupFunction = groupingSchema[attribute].setGroupFunction;
                            setGroupFunction(nodesToGroup);
                        }
                    }
                });
            }

            // If there are existing nodes, we will need to persist state between graph updates
            // Here we'll create some objects that will be useful for this purpose
            const oldNodesById: Record<string, Node> = {};
            const groupParentNodesByLevelTrail: Record<string, Node> = {};
            const groupChildNodesByLevelTrail: Record<string, Node[]> = {};

            for (const node of state.graphData.nodes) {
                oldNodesById[node.id] = node;
                if (isATargetGroup(node)) {
                    const trail = getGroupTrail(node);
                    if (trail) {
                        groupParentNodesByLevelTrail[trail] = node;
                    }
                }
                if (isAGroupLeafNode(node)) {
                    const trail = getParentGroupTrail(node);
                    if (trail) {
                        if (!groupChildNodesByLevelTrail[trail]) {
                            groupChildNodesByLevelTrail[trail] = [];
                        }
                        groupChildNodesByLevelTrail[trail].push(node);
                    }
                }
            }

            // when setting the graph data if there are already selected nodes
            // we need to refresh the selected nodes set with the new references
            const newSelectedNodes = new Set<Node>();
            const selectedNodeIds: string[] = [];
            state.selectedNodes.forEach((node) => {
                selectedNodeIds.push(String(node.id));
            });

            const newSelectedNodesArr = Array.from(state.selectedNodes);

            // when setting the graph data if there are already locked nodes
            // we need to refresh the locked nodes set with the new references
            const newLockedNodes = new Set<Node>();
            const lockedNodes: Record<string, Node> = {};
            state.lockedNodes.forEach((node) => {
                lockedNodes[String(node.id)] = node;
            });
            const lockedNodeIds = Object.keys(lockedNodes);

            newGraphData.nodes.forEach((node) => {
                if (state.pendingView) {
                    const pos = state.pendingView.positions[node.id];
                    if (pos) {
                        node.x = pos.x;
                        node.y = pos.y;

                        if (pos.isLocked) {
                            newLockedNodes.add(node);
                            node.fx = pos.x;
                            node.fy = pos.y;
                        }
                        if (pos.selected) {
                            // If this was a selected node, and it's not in the existing selected nodes, add it to the new set
                            if (!selectedNodeIds.includes(String(node.id))) {
                                newSelectedNodesArr.push(node);
                            }
                        }
                    }
                    return;
                }

                // If this is a normal node and it was previously on the map, maintain it's position
                const oldNode = oldNodesById[String(node.id)];
                if (oldNode) {
                    node.x = oldNode.x;
                    node.y = oldNode.y;
                }

                // If this is a new leaf node that was previously a group, set the origin x/y to the
                // group's x/y so it animates cleanly
                if (isAGroupLeafNode(node)) {
                    const trail = getParentGroupTrail(node);
                    if (trail) {
                        const parentNode = groupParentNodesByLevelTrail[trail];
                        if (parentNode) {
                            node.x = parentNode.x;
                            node.y = parentNode.y;

                            // If the parent was a selected node, add this node to the selected nodes
                            if (selectedNodeIds.includes(String(parentNode.id))) {
                                newSelectedNodes.add(node);
                            }
                        }
                    }
                }

                // If this a new group node that was previously one (or more) leaf nodes, and if any leaf
                // nodes were selected, add this group node to the selected nodes
                if (isATargetGroup(node)) {
                    const id = String(node.id);
                    if (!selectedNodeIds.includes(id) && node.nextLevelBase64) {
                        const anyLeafNodesSelected = groupChildNodesByLevelTrail[node.nextLevelBase64]?.some(
                            (childNode) => {
                                return selectedNodeIds.includes(String(childNode.id));
                            },
                        );
                        if (anyLeafNodesSelected) {
                            newSelectedNodes.add(node);
                        }
                    }
                }

                // If this was a locked node, ensure it's still locked
                if (lockedNodeIds.includes(String(node.id))) {
                    const oldNode = lockedNodes[String(node.id)];
                    node.fx = oldNode.fx;
                    node.fy = oldNode.fy;
                    newLockedNodes.add(node);
                }
            });

            // If there are any selected nodes that are no longer in the graph, we can add them back to the explorer
            const newSelectedNodesSet = new Set<Node>([...newSelectedNodesArr]);

            const largeDatasetLoading = newGraphData.nodes.length > largeDatasetNodeCount;
            const smallDatasetLoading = !largeDatasetLoading;

            const end = Date.now();
            console.debug(`Loading graph data took ${end - start}ms`);

            return {
                ...state,
                graphData: newGraphData,
                unfilteredGraphData: unfilteredGraphData,
                selectedNodes: newSelectedNodesSet,
                lockedNodes: newLockedNodes,
                pendingView: undefined,
                largeDatasetLoading,
                smallDatasetLoading,
            };
        case 'set-selected-nodes':
            return {
                ...state,
                previouslySelectedNodes: state.selectedNodes,
                selectedNodes: action.nodes,
            };
        case 'set-queried-nodes':
            return {
                ...state,
                queriedNodes: action.nodes,
            };
        case 'set-scroll-to-node':
            return {
                ...state,
                scrollToNode: action.node,
            };
        case 'set-locked-nodes':
            return {
                ...state,
                previouslyLockedNodes: state.lockedNodes,
                lockedNodes: action.nodes,
            };
        case 'add-locked-node':
            const tempLockedNodes = new Set(state.lockedNodes);
            tempLockedNodes.add(action.node);
            action.node.fx = action.node.x;
            action.node.fy = action.node.y;
            return {
                ...state,
                previouslyLockedNodes: state.lockedNodes,
                lockedNodes: tempLockedNodes,
            };

        case 'remove-locked-node':
            const tmpLockedNode = new Set(state.lockedNodes);
            tmpLockedNode.delete(action.node);
            action.node.fx = undefined;
            action.node.fy = undefined;
            const g = state.graphRef?.current;
            g?.d3ReheatSimulation();
            return {
                ...state,
                previouslyLockedNodes: state.lockedNodes,
                lockedNodes: tmpLockedNode,
            };

        case 'release-all-nodes':
            const nodes = state.graphData.nodes;
            state.lockedNodes.clear();
            nodes.map((n) => {
                n.fx = undefined;
                n.fy = undefined;
            });
            const h = state.graphRef?.current;
            h?.d3ReheatSimulation();
            return {
                ...state,
                lockedNodes: state.lockedNodes,
            };
        case 'lock-all-selected-nodes':
            const nodesToLock = Array.from(state.selectedNodes);

            nodesToLock.map((n) => {
                n.fx = n.x;
                n.fy = n.y;
                state.lockedNodes.add(n);
            });

            return {
                ...state,
                lockedNodes: state.lockedNodes,
            };
        case 'set-selected-link':
            return {
                ...state,
                selectedLink: action.link,
            };
        case 'set-selected-policy-target':
            return {
                ...state,
                policyOpen: true,
                navigatorOpen: false,
                dashboardOpen: false,
                selectedPolicyTarget: action.target,
            };
        case 'set-visibility':
            return {
                ...state,
                visible: action.visible,
            };
        case 'set-layers':
            return {
                ...state,
                layers: action.layers,
            };
        case 'set-selected-time':
            if (!action.dragging) {
                console.log(
                    `Setting selected time window to [start=${new Date(
                        action.time[0],
                    ).toLocaleString()}, end=${new Date(action.time[1]).toLocaleString()}]`,
                );
            }
            return {
                ...state,
                selectedTime: [action.time[0], action.time[1]],
            };
        case 'set-time-window-min':
            return {
                ...state,
                timeWindowMin: action.min,
            };
        case 'set-time-window-max':
            // If the time window max is today then we want
            // to always shift it along a little to include the
            // hours elapsed from midnight til now
            let timeWindowMax;
            if (isToday(action.max)) {
                const twoMinutesAgo = +startOfMinute(new Date()) - 120000;
                timeWindowMax = twoMinutesAgo;
            } else {
                timeWindowMax = action.max;
            }
            return {
                ...state,
                timeWindowMax,
            };
        case 'set-group':
            return {
                ...state,
                group: {
                    ...state.group,
                    [action.label]: action.attribute,
                },
                newGroupPending: true,
            };
        case 'set-groups':
            return {
                ...state,
                group: action.group,
                newGroupPending: true,
            };
        case 'set-group-applied':
            return {
                ...state,
                newGroupPending: false,
            };
        case 'remove-selected-node':
            const tmpNodes = Array.from(state.selectedNodes).filter((n) => n.id !== action.node.id);
            return {
                ...state,
                previouslySelectedNodes: state.selectedNodes,
                selectedNodes: new Set(tmpNodes),
            };
        case 'remove-queried-node':
            const tmpQueriedNodes = Array.from(state.queriedNodes).filter((n) => n.id !== action.node.id);
            return {
                ...state,
                queriedNodes: new Set(tmpQueriedNodes),
            };
        case 'undo':
            return {
                ...state,
                selectedNodes: state.previouslySelectedNodes,
            };
        case 'set-events-observed-times':
            return {
                ...state,
                firstEventAt: action.firstEventAt,
                lastEventAt: action.lastEventAt,
            };
        case 'apply-graph-filter':
            const filters = action.filters;

            if (state.unfilteredGraphData) {
                const start = Date.now();

                // Get some unfettered source data to filter through
                let graphData = {
                    nodes: [...state.unfilteredGraphData.nodes],
                    links: [...state.unfilteredGraphData.links],
                };

                // Apply the graph filter
                graphData = applyGraphFilter(graphData, filters);

                // If there is a grouping applied, we should re-calculate the group data
                if (groupingForceApplied(state.group)) {
                    Object.entries(state.group).forEach(([label, attribute]) => {
                        if (label && attribute) {
                            const nodesToGroup = graphData.nodes.filter((node) => node.label === label);
                            const groupingSchema = GroupSchema[label as keyof typeof GroupSchema];
                            if (groupingSchema) {
                                const setGroupFunction = groupingSchema[attribute].setGroupFunction;
                                setGroupFunction(nodesToGroup);
                            }
                        }
                    });
                }

                const currentNodeCount = state.graphData.nodes.length;
                const filteredNodeCount = graphData.nodes.length;
                const showLoading = currentNodeCount / filteredNodeCount < 0.2 && filteredNodeCount > 1000;

                // Return the filtered graph data
                const end = Date.now();
                console.debug(`Graph filtering took ${end - start}ms`);
                return {
                    ...state,
                    graphData,
                    graphFilters: filters,
                    largeDatasetLoading: showLoading,
                    smallDatasetLoading: !showLoading,
                    newGroupPending: true,
                };
            }

            return {
                ...state,
            };

        case 'set-applications-strength':
            const tempApp = state.strengths;
            tempApp.applicationStrength = action.value;
            return {
                ...state,
                strengths: tempApp,
                newGroupPending: true,
            };
        case 'set-actors-strength':
            const tempActor = state.strengths;
            tempActor.actorStrength = action.value;
            return {
                ...state,
                strengths: tempActor,
                newGroupPending: true,
            };
        case 'set-devices-strength':
            const tempDevice = state.strengths;
            tempDevice.deviceStrength = action.value;
            return {
                ...state,
                strengths: tempDevice,
                newGroupPending: true,
            };
        case 'set-identities-strength':
            const tempId = state.strengths;
            tempId.identityStrength = action.value;
            return {
                ...state,
                strengths: tempId,
                newGroupPending: true,
            };
        case 'set-targets-strength':
            const tempTarget = state.strengths;
            tempTarget.targetStrength = action.value;
            return {
                ...state,
                strengths: tempTarget,
                newGroupPending: true,
            };
        case 'set-links-strength':
            const tempLink = state.strengths;
            tempLink.linkStrength = action.value;
            return {
                ...state,
                strengths: tempLink,
                newGroupPending: true,
            };
        case 'set-level-trails':
            const trails = new Set(action.trails);
            return {
                ...state,
                levelTrails: trails,
            };
        case 'add-level-trail':
            const trailsToAddTo = new Set(state.levelTrails);
            trailsToAddTo.add(action.trail);
            return {
                ...state,
                levelTrails: trailsToAddTo,
            };
        case 'remove-level-trail':
            const trailsToDeleteFrom = new Set(state.levelTrails);
            trailsToDeleteFrom.delete(action.trail);
            return {
                ...state,
                levelTrails: trailsToDeleteFrom,
            };
        case 'set-pending-view':
            const startTimesAreEqual = action.view.selectedTime[0] === state.selectedTime[0];
            const endTimesAreEqual = action.view.selectedTime[1] === state.selectedTime[1];
            if (startTimesAreEqual && endTimesAreEqual) {
                // nudge the selected time window to the next second, this will cause a graph refresh
                action.view.selectedTime[1] += 1;
            }
            return {
                ...state,
                pendingView: action.view,
            };
        case 'set-large-dataset-loading':
            return {
                ...state,
                largeDatasetLoading: action.loading,
            };
        case 'set-small-dataset-loading':
            return {
                ...state,
                smallDatasetLoading: action.loading,
            };
        case 'set-timeline-dragging':
            return {
                ...state,
                timelineDragging: action.dragging,
            };
        case 'set-block-zoom':
            return {
                ...state,
                blockZoom: action.blocked,
            };
        case 'set-provider-error':
            return {
                ...state,
                hasProviderError: action.hasProviderError,
            };
        case 'set-provider-info':
            return {
                ...state,
                hasProviderInfo: action.hasProviderInfo,
            };
        case 'set-profile-node':
            return {
                ...state,
                selectedProfileNode: action.node,
            };
        case 'set-profile-window':
            return {
                ...state,
                profileOpen: action.open,
            };
        case 'set-permissions-node':
            return {
                ...state,
                selectedPermissionsNode: action.node,
            };
        case 'set-root-hovered-node':
            return {
                ...state,
                rootHoveredNode: action.node,
            };
        case 'set-identity-map-context-menu-state':
            return {
                ...state,
                identityMapContextMenuOpen: action.open,
            };
        case 'set-block-identity-map-updates':
            return {
                ...state,
                blockIdentityMapUpdates: action.blocked,
            };
        default:
            return state;
    }
};
