import { useQuery } from '@apollo/client';
import { NoSymbolIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { GET_ENTITY_ATTRIBUTES } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { getEntityType } from 'Utilities/utils';
import { AttributeProps } from '../ProfileTypes';

export type JoinedObjectProps = {
    modifiedKeys: Record<string, string | number>;
    unmodifiedKeys: Record<string, string | number>;
};

export const NodeAttributeDisplay = ({
    node,
    startDate,
    endDate,
}: AttributeProps & { startDate: number; endDate: number }): JSX.Element => {
    const { enableDeviceProfileAttributeMock } = useFlags();
    const tenantId = useTenant();
    const nodeTypeHasAttributes = node.label == 'actor' || node.label == 'device';
    const isDevice = node.label == 'device';

    const { loading, error, data } = useQuery(GET_ENTITY_ATTRIBUTES, {
        variables: {
            tenantId: tenantId,
            entityId: node.id,
            entityType: getEntityType(node),
            startTimeInMs: startDate,
            endTimeInMs: endDate,
        },
        skip: !tenantId || !nodeTypeHasAttributes,
    });

    const chronologicallyOrderedAttributeObjects = useMemo(() => {
        if (data) {
            const attrs = data.getEntityAttributes as TimeSeriesAttributes;
            const uniqueTimestamps: number[] = [];
            const allKeys = new Set<string>();

            getUniqueTimestamps(attrs, uniqueTimestamps);

            const tempOutput = [];
            const lastKnownValues: Record<string, string | number> = {};

            for (const time of uniqueTimestamps) {
                const joinedObjects: JoinedObjectProps = { modifiedKeys: {}, unmodifiedKeys: {} };
                const keys = getAllTransformedKeys(attrs, time, allKeys);
                const vals = getAllTransformedValues(attrs, time);

                keys.map((key, index) => {
                    joinedObjects.modifiedKeys[key] = vals[index];
                    lastKnownValues[key] = vals[index];
                });
                allKeys.forEach((key) => {
                    if (!keys.includes(key)) {
                        const previousValue = lastKnownValues[key];
                        joinedObjects['unmodifiedKeys'][key] = previousValue;
                    }
                });
                tempOutput.push(joinedObjects);
            }
            return tempOutput.reverse();
        }
    }, [data]);

    const [attributeIndex, setAttributeIndex] = useState(0);

    const { attributes, subsetOfKeysAreModified } = useMemo(() => {
        const shouldRender =
            chronologicallyOrderedAttributeObjects && chronologicallyOrderedAttributeObjects[attributeIndex];
        if (shouldRender) {
            const attributes = chronologicallyOrderedAttributeObjects[attributeIndex];
            const subsetOfKeysAreModified = Object.keys(attributes.unmodifiedKeys).length != 0;
            return {
                attributes,
                subsetOfKeysAreModified,
            };
        }
        return {};
    }, [attributeIndex, chronologicallyOrderedAttributeObjects]);

    if (isDevice && enableDeviceProfileAttributeMock) {
        return (
            <div className="bg-gray-700 rounded-lg px-4 py-2">
                <div className="flex justify-between items-center mb-2 mt-2">
                    <div className="flex justify-end space-x-1.5">
                        <button
                            className="btn-gray bg-gray-600 rounded-md px-2 disabled:opacity-30"
                            type="button"
                            disabled={true}
                        >
                            <ArrowLeftIcon className="h-3 w-3 text-white" />
                        </button>
                        <button
                            className="btn-gray bg-gray-600 rounded-md px-2 disabled:opacity-30"
                            type="button"
                            disabled={true}
                        >
                            <ArrowRightIcon className="h-3 w-3 text-white" />
                        </button>
                    </div>
                    <p className="text-xs text-gray-600">Updated at {new Date().toLocaleString()}</p>
                </div>
                <ul className="grid grid-cols-2 gap-1 pb-1 text-xs text-gray-300">
                    <li>Device Name: DEVICE-ID-TEST</li>
                    <li>Device OS: DEVICE-OS-TEST</li>
                    <li>Device TPM: true</li>
                    <li>Device Disk Encryption: true</li>
                    <li>Device Valid Posture: true</li>
                </ul>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="flex flex-col justify-center items-center text-xs h-full p-4">
                <div className="h-4 w-4 loader" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col justify-center items-center text-xs h-full p-4">
                <NoSymbolIcon className="h-8 w-8 text-red-400 mb-2" />
                <span className="text-gray-400">Could not load Attributes</span>
            </div>
        );
    }

    if (!attributes || !chronologicallyOrderedAttributeObjects) {
        return (
            <div className="flex flex-col justify-center items-center text-xs h-full p-4">
                <p className="text-xs text-center text-gray-500">No Attributes</p>
            </div>
        );
    }

    return (
        <div className="h-full">
            <div className="flex justify-between items-center mb-2">
                <p className="text-xs text-gray-600">
                    Updated at {new Date(attributes.modifiedKeys['updatedAt']).toLocaleString()}
                </p>
                <div className="flex justify-end space-x-1.5">
                    <button
                        className="btn-gray bg-gray-600 rounded-md px-2 disabled:opacity-30"
                        type="button"
                        disabled={attributeIndex != chronologicallyOrderedAttributeObjects.length - 1 ? false : true}
                        onClick={() => {
                            setAttributeIndex(attributeIndex + 1);
                        }}
                    >
                        <ArrowLeftIcon className="h-3 w-3 text-white" />
                    </button>
                    <button
                        className="btn-gray bg-gray-600 rounded-md px-2 disabled:opacity-30"
                        type="button"
                        disabled={attributeIndex > 0 ? false : true}
                        onClick={() => {
                            setAttributeIndex(attributeIndex - 1);
                        }}
                    >
                        <ArrowRightIcon className="h-3 w-3 text-white" />
                    </button>
                </div>
            </div>
            <ul className="grid grid-cols-2 gap-2 pb-1 text-xs text-gray-300">
                {Object.entries(attributes['modifiedKeys']).map(([k, v]) => {
                    if (k != 'sid' && k != 'updatedAt') {
                        return (
                            <li className={subsetOfKeysAreModified ? 'text-blue-700' : ''} key={k + v}>
                                {convertCamelCaseToSpacedTitleCase(k) + ': ' + v + '\n'}
                            </li>
                        );
                    }
                })}

                {Object.entries(attributes['unmodifiedKeys']).map(([k, v]) => {
                    if (k != 'sid' && k != 'updatedAt') {
                        return (
                            <li className="" key={k + v}>
                                {convertCamelCaseToSpacedTitleCase(k) + ': ' + v + '\n'}
                            </li>
                        );
                    }
                })}
            </ul>
        </div>
    );
};

const convertCamelCaseToSpacedTitleCase = (str: string) => {
    return str
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, function (str) {
            return str.toUpperCase();
        })
        .trim();
};

const getUniqueTimestamps = (data: TimeSeriesAttributes, timestamps: number[]) => {
    for (const key of data) {
        const val1 = key.updatedAt;
        if (!timestamps.includes(val1)) {
            timestamps.push(val1);
        }
    }
};

const getAllTransformedKeys = (data: TimeSeriesAttributes, time: number, keys: Set<string>) => {
    const actualKeyArray = ['updatedAt'];

    for (const key of data) {
        if (time == key.updatedAt) {
            actualKeyArray.push(key.key);
        }
        keys.add(key.key);
    }

    return actualKeyArray;
};

const getAllTransformedValues = (data: TimeSeriesAttributes, time: number) => {
    const actualValueArray: Array<string | number> = [time];

    for (const key of data) {
        if (time == key.updatedAt) {
            actualValueArray.push(key.value);
        }
    }

    return actualValueArray;
};

type TimeSeriesAttribute = {
    updatedAt: number;
    key: string;
    value: string;
};

type TimeSeriesAttributes = TimeSeriesAttribute[];
