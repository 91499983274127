import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext, useCallback, Fragment, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { classNames } from 'Utilities/utils';
import {
    MapPinIcon,
    ArrowsPointingOutIcon,
    Squares2X2Icon,
    ArrowUturnLeftIcon,
    LockOpenIcon,
    LockClosedIcon,
    DocumentArrowDownIcon,
    TableCellsIcon,
} from '@heroicons/react/24/outline';
import { useGraphControls } from 'Hooks/GraphHooks';
import { Menu, Transition } from '@headlessui/react';
import { Tooltip } from 'Library/Tooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PAN_DISTANCE = 20;
const PAN_TIME = 120;
const ZOOM_IN_DISTANCE = 1.2;
const ZOOM_OUT_DISTANCE = 0.8;
const ZOOM_TIME = 180;

export const MapControls = ({ dataLoading, error }: { dataLoading: boolean; error: boolean }): JSX.Element => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { maxNodeCount } = useFlags();
    const {
        zoomGraph,
        centerGraph,
        getVisibleNodes,
        unlockAllNodes,
        lockSelectedNodes,
        selectAllNodes,
        gridTargets,
        undoLastAction,
        generateMapEventsCSV,
        generateMapObjectsCSV,
        clearSelectedAndKeepQueriedNodes,
        clearSelectedAndQueriedNodes,
    } = useGraphControls();

    const nodes = getVisibleNodes();
    const counts = {
        target: nodes.filter((n) => n.label === 'target').length,
        identity: nodes.filter((n) => n.label === 'identity').length,
    };
    const canGenerateEventsCSV = counts.target > 0 && counts.identity > 0;
    const canGenerateObjectsCSV = nodes.length > 0;

    const zoom = () => {
        zoomGraph();
    };

    useHotkeys('c', centerGraph, [mapState.graphData.nodes]);
    useHotkeys('z', zoom, [mapState.graphData.nodes]);
    useHotkeys('control+z', undoLastAction);
    useHotkeys('shift+x', clearSelectedAndKeepQueriedNodes, [mapState.queriedNodes]);
    useHotkeys('x', clearSelectedAndQueriedNodes);
    useHotkeys('control+a', selectAllNodes, [dispatch, mapState.graphData.nodes]);
    useHotkeys('u', unlockAllNodes);
    useHotkeys('l', lockSelectedNodes);

    // Reversed values otherwise controls will feel inverted
    const panUp = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.centerAt(mapState.graphPosition.x, (mapState.graphPosition.y -= PAN_DISTANCE), PAN_TIME);
    }, [mapState]);

    const panDown = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.centerAt(mapState.graphPosition.x, (mapState.graphPosition.y += PAN_DISTANCE), PAN_TIME);
    }, [mapState]);

    const panLeft = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.centerAt((mapState.graphPosition.x -= PAN_DISTANCE), mapState.graphPosition.y, PAN_TIME);
    }, [mapState]);

    const panRight = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.centerAt((mapState.graphPosition.x += PAN_DISTANCE), mapState.graphPosition.y, PAN_TIME);
    }, [mapState]);

    const panUpLeft = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.centerAt((mapState.graphPosition.x -= PAN_DISTANCE), (mapState.graphPosition.y -= PAN_DISTANCE), PAN_TIME);
    }, [mapState]);

    const panDownLeft = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.centerAt((mapState.graphPosition.x -= PAN_DISTANCE), (mapState.graphPosition.y += PAN_DISTANCE), PAN_TIME);
    }, [mapState]);

    const panUpRight = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.centerAt((mapState.graphPosition.x += PAN_DISTANCE), (mapState.graphPosition.y -= PAN_DISTANCE), PAN_TIME);
    }, [mapState]);

    const panDownRight = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.centerAt((mapState.graphPosition.x += PAN_DISTANCE), (mapState.graphPosition.y += PAN_DISTANCE), PAN_TIME);
    }, [mapState]);

    const zoomIn = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.zoom((mapState.graphPosition.k *= ZOOM_IN_DISTANCE), ZOOM_TIME);
    }, [mapState]);

    const zoomOut = useCallback(() => {
        const g = mapState.graphRef?.current;
        g?.zoom((mapState.graphPosition.k *= ZOOM_OUT_DISTANCE), ZOOM_TIME);
    }, [mapState]);

    // 8-way directional panning
    useHotkeys('w', panUp, {}, [mapState]);
    useHotkeys('s', panDown, {}, [mapState]);
    useHotkeys('a', panLeft, {}, [mapState]);
    useHotkeys('d', panRight, {}, [mapState]);
    useHotkeys('w+a, a+w', panUpLeft, {}, [mapState]);
    useHotkeys('s+a, a+s', panDownLeft, {}, [mapState]);
    useHotkeys('w+d, d+w', panUpRight, {}, [mapState]);
    useHotkeys('s+d, d+s', panDownRight, {}, [mapState]);

    // Zoom in/out
    useHotkeys('up', zoomIn, {}, [mapState]);
    useHotkeys('down', zoomOut, {}, [mapState]);

    // Helpers for testing
    const selectActor = useCallback(() => {
        const node = mapState.graphData.nodes.find((n) => n.label === 'actor');
        if (node) {
            dispatch({ type: 'set-selected-nodes', nodes: new Set([node]) });
            dispatch({ type: 'set-profile-node', node: node });
            dispatch({ type: 'set-profile-window', open: true });
        }
    }, [dispatch, mapState.graphData.nodes]);

    const percentUtilization = useMemo(() => {
        const total = mapState.graphData.nodes.length;
        const max = maxNodeCount;
        const percent = Math.round((total / max) * 100);
        return percent;
    }, [mapState.graphData.nodes.length, maxNodeCount]);

    const selectTarget = useCallback(() => {
        const node = mapState.graphData.nodes.find((n) => n.label === 'target');
        if (node) {
            dispatch({ type: 'set-selected-nodes', nodes: new Set([node]) });
        }
    }, [dispatch, mapState.graphData.nodes]);

    return !dataLoading && !error && nodes.length > 0 ? (
        <div className="flex rounded-md items-center justify-center bg-gray-900/75 border border-gray-700 p-1 space-x-2 opacity-50 hover:opacity-100">
            <Tooltip label="Open Data Browser">
                <TableCellsIcon
                    data-test="data-browser-toggle"
                    onClick={() => dispatch({ type: 'toggle-data-browser' })}
                    className="h-6 w-6 text-gray-400 hover:text-white p-1 rounded-sm  hover:bg-gray-700 cursor-pointer"
                />
            </Tooltip>
            <Menu as="div" className="flex item-center justify-center" id="CSV">
                <Menu.Button>
                    <Tooltip label="CSV Export Tools" placement="left">
                        <DocumentArrowDownIcon className="h-6 w-6 p-1 text-gray-400 hover:text-white rounded-sm  hover:bg-gray-700 cursor-pointer" />
                    </Tooltip>
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-bottom absolute bottom-10 left-0 w-44 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-30 focus:outline-none">
                        <div className="py-1 flex flex-col items-stretch bg-gray-700 rounded-md">
                            <Menu.Item>
                                {({ active }) => (
                                    <Tooltip
                                        label={
                                            canGenerateEventsCSV
                                                ? 'All events on the map will be exported. Each event starts from a target and traces back to the identity that generated it.'
                                                : 'Must have at least one target and identity visible on the map'
                                        }
                                    >
                                        <button
                                            disabled={!canGenerateEventsCSV}
                                            onClick={generateMapEventsCSV}
                                            className={classNames(
                                                active
                                                    ? canGenerateEventsCSV
                                                        ? 'bg-blue-800 text-white'
                                                        : 'bg-gray-500'
                                                    : 'text-gray-300',
                                                'group flex items-center px-4 py-2 text-xs bg-gray-700 cursor-pointer',
                                            )}
                                        >
                                            Export visible events
                                        </button>
                                    </Tooltip>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <Tooltip
                                        label={
                                            canGenerateObjectsCSV
                                                ? 'All objects on the map such as actors, devices, and targets are exported'
                                                : 'Must have at least one object visible on the map'
                                        }
                                    >
                                        <button
                                            disabled={!canGenerateObjectsCSV}
                                            onClick={generateMapObjectsCSV}
                                            className={classNames(
                                                active
                                                    ? canGenerateObjectsCSV
                                                        ? 'bg-blue-800 text-white'
                                                        : 'bg-gray-500'
                                                    : 'text-gray-300',
                                                'group flex items-center px-4 py-2 text-xs bg-gray-700 cursor-pointer',
                                            )}
                                        >
                                            Export visible objects
                                        </button>
                                    </Tooltip>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            <Tooltip label="Lock Selected Nodes">
                <LockClosedIcon
                    className="h-6 w-6 text-gray-400 hover:text-white p-1 rounded-sm  hover:bg-gray-700 cursor-pointer"
                    onClick={() => lockSelectedNodes()}
                />
            </Tooltip>
            <Tooltip label="Unlock All Nodes">
                <LockOpenIcon
                    className="h-6 w-6 text-gray-400 hover:text-white p-1 rounded-sm  hover:bg-gray-700 cursor-pointer"
                    onClick={() => unlockAllNodes()}
                />
            </Tooltip>
            <Tooltip label="Box Targets">
                <Squares2X2Icon
                    className="h-6 w-6 text-gray-400 hover:text-white p-1 rounded-sm  hover:bg-gray-700 cursor-pointer"
                    onClick={() => gridTargets()}
                />
            </Tooltip>
            <Tooltip label="Center Map">
                <MapPinIcon
                    className="h-6 w-6 text-gray-400 hover:text-white p-1 rounded-sm  hover:bg-gray-700 cursor-pointer"
                    onClick={() => centerGraph()}
                />
            </Tooltip>
            <Tooltip label="Zoom to Fit">
                <ArrowsPointingOutIcon
                    className="h-6 w-6 text-gray-400 hover:text-white p-1 rounded-sm  hover:bg-gray-700 cursor-pointer"
                    onClick={() => zoomGraph()}
                />
            </Tooltip>
            <Tooltip label="Undo Last Action">
                <ArrowUturnLeftIcon
                    className="h-6 w-6 text-gray-400 hover:text-white p-1 rounded-sm  hover:bg-gray-700 cursor-pointer"
                    onClick={() => undoLastAction()}
                />
            </Tooltip>

            <Tooltip
                label={`Above 100% the map will pause rendering until you reduce the number of nodes (${mapState.graphData.nodes.length}/${maxNodeCount})`}
            >
                <div className="h-6 w-7 text-xs flex place-items-center justify-center pr-2">{percentUtilization}%</div>
            </Tooltip>

            <div className="hidden" data-test="select-actor" onClick={selectActor} />
            <div className="hidden" data-test="select-target" onClick={selectTarget} />
            <div className="hidden" data-test="open-profile" onClick={selectTarget} />
        </div>
    ) : (
        <></>
    );
};
