import { useQuery } from '@apollo/client';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { GET_LOCATION_GRID } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useEffect, useState } from 'react';
import { AttributeProps } from '../ProfileTypes';
import { ComposableMap, Geographies, Geography, ProjectionConfig, ZoomableGroup } from 'react-simple-maps';
import WorldCountriesGeos from 'Dashboard/Charts/WorldCountriesGeos.json';
import { createGeoJSONFromGrid, GeoJSON } from 'Dashboard/Charts/WorldMapChart';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MapIcon } from '@heroicons/react/24/outline';
import { renderToString } from 'react-dom/server';
import { useGraphControls } from 'Hooks/GraphHooks';
import { Node } from 'Types/types';

export type JoinedObjectProps = {
    modifiedKeys: Record<string, string | number>;
    unmodifiedKeys: Record<string, string | number>;
};

export const NodeLocationDisplay = ({
    node,
    startDate,
    endDate,
    projectionConfig,
}: AttributeProps & { startDate: number; endDate: number; projectionConfig?: ProjectionConfig }): JSX.Element => {
    const { enableWorldMap, enableAdvancedQueries } = useFlags();
    const tenantId = useTenant();
    const { addNodeToExplorer, isNodeInExplorer, filterByNode, clearQueriedNodes, setQueriedNodes } =
        useGraphControls();
    const [geojson, setGeojson] = useState<GeoJSON | undefined>();

    const query =
        node.label === 'actor'
            ? {
                  actorQuery: {
                      actorIds: [node.id],
                  },
              }
            : {
                  targetQuery: {
                      targetIds: [node.id],
                  },
              };

    const { loading, error, data } = useQuery(GET_LOCATION_GRID, {
        variables: {
            tenantId,
            startTimeInMs: startDate,
            endTimeInMs: endDate,
            filter: {
                tenantId,
                groups: {
                    operator: 'FILTER_OPERATOR_OR',
                    elements: [query],
                },
            },
        },
        skip: !enableWorldMap,
    });

    useEffect(() => {
        if (data) {
            const grid = data.getLocationGrid.grid;
            if (grid) {
                const geoJson = createGeoJSONFromGrid(grid, -90, 90, -180, 180);
                console.log(geoJson);
                setGeojson(geoJson);
            } else {
                setGeojson(undefined);
            }
        } else {
            setGeojson(undefined);
        }
    }, [data]);

    return (
        <div className="w-full relative">
            {!geojson && !loading && !error && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70">
                    <MapIcon
                        className="h-6 w-6 text-grey-500"
                        data-tooltip-id="profile-heatmap"
                        data-tooltip-content="No location information available"
                    />
                </div>
            )}
            {loading && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70">
                    <div className="loader h-4 w-4"></div>
                </div>
            )}
            {error && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70">
                    <NoSymbolIcon
                        className="h-6 w-6 text-red-400"
                        data-tooltip-id="profile-heatmap"
                        data-tooltip-content="There was an error retrieving location information"
                    />
                </div>
            )}
            <ComposableMap
                id="map"
                projection="geoMercator"
                projectionConfig={
                    projectionConfig || {
                        scale: 180,
                        rotate: [-10, 0, 0],
                        center: [0, 18],
                    }
                }
                style={{ width: '100%', height: '350px' }}
            >
                <ZoomableGroup>
                    <Geographies geography={WorldCountriesGeos}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const fill = '#6B7280';
                                const strokeWidth = 0.5;

                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={fill}
                                        fillOpacity={0.05}
                                        stroke="#9DA3AD"
                                        strokeWidth={strokeWidth}
                                        style={{
                                            default: { outline: 'none' },
                                            hover: { outline: 'none' },
                                            pressed: { outline: 'none' },
                                        }}
                                    />
                                );
                            })
                        }
                    </Geographies>
                    {geojson && (
                        <Geographies geography={geojson}>
                            {({ geographies }) =>
                                geographies.map((geo) => {
                                    const { value, row, col, opacity, centerLat, centerLon, radius, coordinates } =
                                        geo.properties;
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            data-tooltip-id={'profile'}
                                            data-tooltip-html={renderToString(
                                                <div className="text-center">
                                                    <div>{value} Events</div>
                                                    {enableAdvancedQueries && (
                                                        <>
                                                            <div className="text-gray-400">
                                                                Click to add to Explorer
                                                            </div>
                                                            <div>
                                                                Note: all currently queried nodes will be disabled
                                                            </div>
                                                        </>
                                                    )}
                                                </div>,
                                            )}
                                            geography={geo}
                                            fill={'#36a8fa'}
                                            fillOpacity={opacity}
                                            stroke="#36a8fa"
                                            strokeOpacity={opacity + 0.1}
                                            style={{
                                                default: { outline: 'none' },
                                                hover: { outline: 'none' },
                                                pressed: { outline: 'none' },
                                            }}
                                            onMouseEnter={() => {
                                                console.log(
                                                    `Row: ${row}, Column: ${col}, Value: ${value}, Opacity: ${opacity}`,
                                                );
                                            }}
                                            onClick={() => {
                                                if (enableAdvancedQueries) {
                                                    // Round the center lat and lon to 4 decimal places, convert the radius to kilometers
                                                    const name = `${centerLat.toFixed(4)}, ${centerLon.toFixed(
                                                        4,
                                                    )}, Radius ${(radius / 1000).toFixed()} km`;

                                                    const geoLocationQueryNode: Node = {
                                                        id: `heatmap-${row}-${col}`,
                                                        label: 'query',
                                                        name: name,
                                                        queryAttributes: {
                                                            location: {
                                                                latitude: centerLat,
                                                                longitude: centerLon,
                                                                radius: radius,
                                                                coordinates,
                                                            },
                                                        },
                                                        x: 0,
                                                        y: 0,
                                                        props: { displayName: name },
                                                        tags: [],
                                                        attributes: [],
                                                        links: [],
                                                        neighbors: [],
                                                    };

                                                    clearQueriedNodes();

                                                    if (!isNodeInExplorer(geoLocationQueryNode)) {
                                                        addNodeToExplorer(geoLocationQueryNode);
                                                    }

                                                    setQueriedNodes(geoLocationQueryNode);

                                                    filterByNode(node, true);
                                                }

                                                console.log(
                                                    `Added Row: ${row}, Column: ${col}, Value: ${value}, Name: ${name}`,
                                                );
                                            }}
                                        />
                                    );
                                })
                            }
                        </Geographies>
                    )}
                </ZoomableGroup>
            </ComposableMap>
        </div>
    );
};
