import './00-theme.css';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';

import 'codemirror-rego/key-map'; // rego key map
import 'codemirror-rego/mode'; // rego syntax highlighting
import { useTenant } from 'Hooks/Hooks';
import { useMutation, useQuery } from '@apollo/client';
import {
    DELETE_POLICY_DOCUMENT,
    GET_POLICY_DOCUMENT,
    LIST_POLICY_DOCUMENTS,
    LIST_POLICY_PROFILES,
    PUBLISH_POLICY_DOCUMENT,
    STAGE_POLICY_DOCUMENT,
    UPDATE_POLICY_DOCUMENT,
} from 'Graph/typedQueries';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { SelectedNavItem } from '../PolicyModalTypes';
import { classNames } from 'Utilities/utils';
import { Tooltip } from 'Library/Tooltip';
import CodeMirror from '@uiw/react-codemirror';
import { PolicyAction, StagingStatus } from 'GeneratedGQL/graphql';

type TrustFactorProps = {
    policyDocumentId?: string;
    setSelectedNavItem: React.Dispatch<React.SetStateAction<SelectedNavItem | undefined>>;
};

type TrustFactorFormData = {
    name: string;
    description: string;
    policyDocument: string;
};

export const TrustFactor = ({ policyDocumentId, setSelectedNavItem }: TrustFactorProps): JSX.Element => {
    const tenantId = useTenant();
    const [cachedPolicyDocumentId, setCachedPolicyDocumentId] = useState<string | undefined>(policyDocumentId);
    const [policyDocumentLoaded, setPolicyDocumentLoaded] = useState<boolean>(false);

    const {
        data: policyDocumentData,
        loading: loadingGetPolicyDocument,
        error: errorGetPolicyDocument,
    } = useQuery(GET_POLICY_DOCUMENT, {
        variables: { tenantId: tenantId || '', policyId: policyDocumentId || '' },
        skip: !tenantId || !policyDocumentId,
    });

    const {} = useQuery(LIST_POLICY_DOCUMENTS, {
        variables: { tenantId: tenantId || '', filter: { policyDocumentId: policyDocumentId } },
        skip: !tenantId || !policyDocumentId,
        fetchPolicy: 'network-only',
    });

    const {
        data: dataTrustProfiles,
        loading: loadingTrustProfiles,
        error: errorTrustProfiles,
    } = useQuery(LIST_POLICY_PROFILES, {
        variables: { tenantId: tenantId || '', filter: { policyIds: policyDocumentId ? [policyDocumentId] : [] } },
        skip: !tenantId || !policyDocumentId,
        fetchPolicy: 'network-only',
    });

    const canDelete = useMemo(() => {
        return dataTrustProfiles?.listPolicyProfiles?.length === 0;
    }, [dataTrustProfiles]);

    const [stagePolicyDocument, { loading: loadingStagePolicyDocument, error: errorStagePolicyDocument }] =
        useMutation(STAGE_POLICY_DOCUMENT);
    const [publishPolicyDocument, { loading: loadingPublishPolicyDocument, error: errorPublishDocument }] =
        useMutation(PUBLISH_POLICY_DOCUMENT);
    const [updatePolicyDocument, { loading: loadingUpdatePolicyDocument, error: errorUpdateDocument }] =
        useMutation(UPDATE_POLICY_DOCUMENT);
    const [deletePolicyDocument, { loading: loadingDeletePolicyDocument, error: errorDeleteDocument }] =
        useMutation(DELETE_POLICY_DOCUMENT);

    const [policyDocumentErrorMessages, setPolicyDocumentErrorMessages] = useState<string[]>([]);

    const loading =
        loadingGetPolicyDocument ||
        loadingTrustProfiles ||
        loadingStagePolicyDocument ||
        loadingUpdatePolicyDocument ||
        loadingPublishPolicyDocument ||
        loadingDeletePolicyDocument;

    const error =
        errorGetPolicyDocument ||
        errorTrustProfiles ||
        errorStagePolicyDocument ||
        errorUpdateDocument ||
        errorPublishDocument ||
        errorDeleteDocument;

    const deleteExistingPolicyDocument = async () => {
        await deletePolicyDocument({
            variables: {
                tenantId: tenantId || '',
                policyDocumentId: policyDocumentId || '',
            },
            refetchQueries: ['listPolicyDocuments'],
        });

        setSelectedNavItem(undefined);
    };

    const createNewPolicyDocument = async (data: TrustFactorFormData) => {
        if (!data.policyDocument) {
            setPolicyDocumentErrorMessages(['Policy Document cannot be empty']);
            return;
        } else {
            setPolicyDocumentErrorMessages([]);
        }

        const result = await stagePolicyDocument({
            variables: {
                tenantId: tenantId || '',
                ...data,
            },
            refetchQueries: ['listPolicyDocuments', 'getPolicyDocument'],
            awaitRefetchQueries: true,
        });

        if (result.data?.stagePolicyDocument) {
            if (result.data.stagePolicyDocument.policyDocument === null) {
                if (result.data.stagePolicyDocument.errors) {
                    setPolicyDocumentErrorMessages(
                        result.data.stagePolicyDocument.errors.map((error) => error.message),
                    );
                }
            } else {
                setSelectedNavItem({
                    type: 'document',
                    id: result?.data?.stagePolicyDocument.policyDocument?.policyDocumentId,
                });
            }
        }
    };

    const updateExistingPolicyDocument = async (data: TrustFactorFormData) => {
        if (!data.policyDocument) {
            setPolicyDocumentErrorMessages(['Policy Document cannot be empty']);
            return;
        } else {
            setPolicyDocumentErrorMessages([]);
        }

        const isValid = await validatePolicyDocument(data.policyDocument);

        if (isValid) {
            await updatePolicyDocument({
                variables: {
                    tenantId: tenantId || '',
                    policyUpdates: {
                        policyDocumentId: policyDocumentId || '',
                        displayName: data.name,
                        description: data.description,
                        policyDocument: data.policyDocument,
                    },
                },
                refetchQueries: ['listPolicyDocuments', 'getPolicyDocument'],
                awaitRefetchQueries: true,
            });
        }
    };

    const validatePolicyDocument = async (policyDocument: string) => {
        const result = await stagePolicyDocument({
            variables: {
                tenantId: tenantId || '',
                policyDocument: policyDocument,
                validateOnly: true,
            },
        });

        if (result.data?.stagePolicyDocument) {
            if (result.data.stagePolicyDocument.status !== StagingStatus.StagingStatusValidateValid) {
                if (result.data.stagePolicyDocument.errors) {
                    setPolicyDocumentErrorMessages(
                        result.data.stagePolicyDocument.errors.map((error) => error.message),
                    );
                    console.log('Policy Document is invalid:', result.data.stagePolicyDocument.errors);
                }
            } else {
                setPolicyDocumentErrorMessages([]);
                console.log('Policy Document is valid');
                return true;
            }
        }

        return false;
    };

    const publishPolicyDocumentHandler = async () => {
        await publishPolicyDocument({
            variables: {
                tenantId: tenantId || '',
                policyDocumentId: policyDocumentId || '',
                version: policyDocumentData?.getPolicyDocument?.version || 0,
                action: PolicyAction.PolicyActionMonitor,
            },
            refetchQueries: ['listPolicyDocuments', 'getPolicyDocument'],
            awaitRefetchQueries: true,
        });
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty },
        control,
        getValues,
        getFieldState,
    } = useForm<TrustFactorFormData>({
        defaultValues: {
            name: '',
            description: '',
            policyDocument: '',
        },
    });

    useEffect(() => {
        if (policyDocumentData && policyDocumentId) {
            const policy = policyDocumentData.getPolicyDocument;
            if (policy) {
                console.log('Resetting trust factor form with policy', policy);
                reset({
                    name: policy.displayName,
                    description: policy.description,
                    policyDocument: policy.policyDocument,
                });
                setPolicyDocumentLoaded(true);
            }
        }
    }, [policyDocumentData, policyDocumentId, reset]);

    useEffect(() => {
        if (cachedPolicyDocumentId !== policyDocumentId && !policyDocumentId) {
            console.log('Resetting trust factor form to blank');
            reset({ name: '', description: '', policyDocument: '' });
        }
        setCachedPolicyDocumentId(policyDocumentId);
    }, [cachedPolicyDocumentId, policyDocumentId, reset]);

    const onSubmit = (data: TrustFactorFormData) => {
        console.log('Saving trust factor:', data);
        if (policyDocumentId) {
            updateExistingPolicyDocument(data);
        } else {
            createNewPolicyDocument(data);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-4">
                <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <p className="uppercase tracking-wider font-bold text-xs text-gray-400">Trust Factors</p>
                        {loading && <span className="h-4 w-4 loader" />}
                    </div>
                    <div className="space-x-2 flex">
                        {policyDocumentId && (
                            <Tooltip
                                label={
                                    canDelete
                                        ? 'Delete the factor. Cannot be undone.'
                                        : 'Factors can only be deleted when not associated with any Trust Profile'
                                }
                            >
                                <button
                                    className={classNames('btn text-xs rounded-md', canDelete ? '' : 'btn-disabled')}
                                    type="button"
                                    onClick={deleteExistingPolicyDocument}
                                    disabled={!canDelete}
                                >
                                    Delete Factor
                                </button>
                            </Tooltip>
                        )}
                        {policyDocumentId && (
                            <button
                                className={classNames('btn text-xs rounded-md', loading ? 'btn-disabled' : '')}
                                type="button"
                                onClick={() => publishPolicyDocumentHandler()}
                                disabled={loading}
                            >
                                Publish Factor
                            </button>
                        )}
                        <button
                            className={classNames('btn text-xs rounded-md', isDirty ? 'btn-primary' : 'btn-disabled')}
                            type="submit"
                            disabled={!isDirty}
                        >
                            {policyDocumentId ? 'Update Working Version' : 'Create Factor'}
                        </button>
                    </div>
                </div>

                {error && (
                    <ul className="text-red-500 font-medium text-xs py-2 list-disc space-y-2">
                        <span>There was an error performance the requested action:</span>
                        {errorGetPolicyDocument && (
                            <li className="text-gray-200 ml-6 font-normal">
                                Getting Trust Factor: {errorGetPolicyDocument.message}
                            </li>
                        )}
                        {errorTrustProfiles && (
                            <li className="text-gray-200 ml-6 font-normal">
                                Trust profiles: {errorTrustProfiles.message}
                            </li>
                        )}
                        {errorStagePolicyDocument && (
                            <li className="text-gray-200 ml-6 font-normal">
                                Staging Trust Factor: {errorStagePolicyDocument.message}
                            </li>
                        )}
                        {errorUpdateDocument && (
                            <li className="text-gray-200 ml-6 font-normal">
                                Updating Trust Factor: {errorUpdateDocument.message}
                            </li>
                        )}
                        {errorPublishDocument && (
                            <li className="text-gray-200 ml-6 font-normal">
                                Publishing Trust Factor: {errorPublishDocument.message}
                            </li>
                        )}
                    </ul>
                )}

                {(policyDocumentId == undefined || policyDocumentLoaded) && (
                    <div className="space-y-4 mt-2">
                        {errorStagePolicyDocument && (
                            <div>
                                <h4 className="text-xs leading font-medium text-red-500 ">
                                    There was an error saving the Trust Factor
                                </h4>
                            </div>
                        )}
                        <div className="flex">
                            <span className="pr-6">
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1 items-center flex">
                                    Published Version
                                    <span className="ml-2 h-2.5 w-2.5 inline-block rounded-full bg-gray-600 shadow-lg" />
                                </h4>
                                <div className="flex space-x-2 text-sm items-center justify-between">
                                    <span>0</span>
                                    <button type="button" className="btn text-xs px-4 py-1.5 rounded-md">
                                        Load
                                    </button>
                                </div>
                            </span>
                            <span className="border-l-2 border-gray-600 pl-6">
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1 items-center flex">
                                    Working Version
                                    <span className="ml-2 h-2.5 w-2.5 inline-block rounded-full bg-green-600 shadow-lg" />
                                </h4>
                                <div className="flex space-x-2 text-sm items-center justify-between">
                                    <span>{policyDocumentData?.getPolicyDocument?.version || 0}</span>
                                    <button
                                        type="button"
                                        disabled={true}
                                        className="btn btn-disabled text-xs px-4 py-1.5 rounded-md"
                                    >
                                        Viewing
                                    </button>
                                </div>
                            </span>
                        </div>
                        <div>
                            <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Trust Factor Name</h4>
                            <input
                                type="text"
                                className="w-full input-gray text-xs rounded-none"
                                {...register('name')}
                                required
                            />
                        </div>
                        <div>
                            <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">
                                Trust Factor Description
                            </h4>
                            <input
                                type="text"
                                className="w-full input-gray text-xs rounded-none"
                                {...register('description')}
                            />
                        </div>
                        <div>
                            <h4 className="text-xs leading-6 font-medium text-gray-300 mb-2 flex justify-between items-center">
                                <span>Trust Factor Policy</span>
                                <button
                                    disabled={!getFieldState('policyDocument').isDirty}
                                    className={classNames('btn rounded-md px-4 py-0.5', isDirty ? '' : 'btn-disabled')}
                                    type="button"
                                    onClick={async () => {
                                        validatePolicyDocument(getValues('policyDocument'));
                                    }}
                                >
                                    Validate
                                </button>
                            </h4>

                            {policyDocumentErrorMessages.length > 0 && (
                                <div className="mb-4">
                                    <h4 className="text-xs leading font-medium text-red-500 mb-2">
                                        There was an error with the Trust Factor Policy:
                                    </h4>
                                    <ul className="list-disc space-x-2">
                                        {policyDocumentErrorMessages.map((errorMessage) => (
                                            <li
                                                className="text-xs leading text-gray-300 capitalize ml-6"
                                                key={errorMessage}
                                            >
                                                {errorMessage}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <Controller
                                name="policyDocument"
                                control={control}
                                render={({ field }) => (
                                    <CodeMirror
                                        {...field}
                                        value={field.value}
                                        onChange={(instance) => field.onChange(instance.getValue())}
                                        options={{
                                            theme: '00-theme',
                                            mode: 'rego',
                                            keyMap: 'styra',
                                            matchBrackets: true,
                                            indentUnit: 2,
                                            indentWithTabs: false,
                                            cursorHeight: 0.75,
                                            cursorBlinkRate: 750,
                                        }}
                                        height={'750px'}
                                    />
                                )}
                            />
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
};
