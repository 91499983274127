import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';

interface SideNavProps {
    selectedWindow: 'overview' | 'triggered' | 'config' | 'destinations';
    setSelectedWindow: React.Dispatch<React.SetStateAction<'overview' | 'triggered' | 'config' | 'destinations'>>;
}

// const indentStyle =
//     'border-transparent  hover:bg-gray-700 text-gray-400 hover:text-gray-200 cursor-pointer group border-l-4 pl-10 pr-6 py-2 flex items-center justify-between text-sm font-medium';

const headerStyle = classNames(
    'border-transparent hover:bg-gray-700 text-gray-400 hover:text-gray-200',
    'cursor-pointer group border-l-4 px-6 py-2 flex items-center justify-between text-sm font-medium',
);

const headerStyleActive = classNames(
    'bg-blue-800 border-blue-700 text-gray-200',
    'cursor-pointer group border-l-4 px-6 py-2 flex items-center justify-between text-sm font-medium',
);

export const SideNav = ({ selectedWindow, setSelectedWindow }: SideNavProps): JSX.Element => {
    return (
        <div className="flex flex-col flex-grow pt-4 pb-4  relative">
            <div className="flex-grow flex flex-col">
                <nav className="flex-1 space-y-1 bg-gray-800" aria-label="Sidebar">
                    <p className="uppercase tracking-wider px-4 font-bold text-xs text-gray-400 mb-4 h-8 flex items-center">
                        Menu
                    </p>

                    <div>
                        <span
                            className={selectedWindow == 'triggered' ? headerStyleActive : headerStyle}
                            onClick={() => {
                                setSelectedWindow('triggered');
                            }}
                        >
                            <span className="flex align-center">Alerts</span>
                            <ChevronRightIcon width="18" height="18" className="mr-2 float-right" />
                        </span>
                    </div>
                    <div>
                        <span
                            className={selectedWindow == 'config' ? headerStyleActive : headerStyle}
                            onClick={() => {
                                setSelectedWindow('config');
                            }}
                        >
                            <span className="flex align-center">Definitions</span>
                            <ChevronRightIcon width="18" height="18" className="mr-2 float-right" />
                        </span>
                    </div>
                </nav>
            </div>
        </div>
    );
};
