import { useCallback, useContext } from 'react';
import {
    CheckIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    ShieldExclamationIcon,
    QuestionMarkCircleIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';
import { formatDistanceToNow } from 'date-fns';
import { ToastContext } from './ToastContext';
import { useTimer } from 'use-timer';
import { ToastProps } from './ToastTypes';

export const ToastList = (): JSX.Element => {
    //Add toastState hook in here so we can use the toasts.map
    const { toastState } = useContext(ToastContext);

    return (
        <>
            <div className="w-80 flex flex-col items-center space-y-3 sm:items-end">
                {toastState.toasts &&
                    toastState.toasts.map((event, idx) => (
                        <Toast
                            key={event.message + event.date + idx}
                            id={idx}
                            message={event.message}
                            date={event.date}
                            status={event.status}
                            autoTimer={event.autoTimer}
                            timeOutTimer={event.timeOutTimer}
                        />
                    ))}
            </div>
        </>
    );
};

export const Toast = ({
    message: content,
    date: date,
    status: status,
    id: idx,
    autoTimer: autoTimer,
    timeOutTimer: timeOutTimer,
}: ToastProps): JSX.Element => {
    const { dispatch } = useContext(ToastContext);

    const closeToast = useCallback(() => {
        if (idx != undefined) {
            dispatch({
                type: 'remove-toast',
                id: idx,
            });
        }
    }, [dispatch, idx]);

    useTimer({
        timerType: 'DECREMENTAL',
        initialTime: timeOutTimer,
        endTime: 0,
        autostart: autoTimer,
        onTimeOver: () => {
            closeToast();
        },
    });

    return (
        <>
            <div
                onMouseDown={(e) => e.stopPropagation()}
                className="max-w-sm w-full bg-gray-700 rounded-lg pointer-events-auto overflow-hidden border border-gray-400 shadow-lg;"
            >
                <div className="p-3">
                    <div className="flex items-start">
                        <div className="flex-shrink-0 mt-1">
                            <span
                                className={classNames(
                                    {
                                        success: 'bg-green-500',
                                        warning: 'bg-yellow-500',
                                        information: 'bg-blue-500',
                                        failure: 'bg-red-500',
                                        unknown: 'bg-gray-500',
                                    }[status],
                                    'h-6 w-6 rounded-full flex items-center justify-center',
                                )}
                            >
                                <span className="h-4 w-4 text-white" aria-hidden="true">
                                    {
                                        {
                                            success: <CheckIcon />,
                                            warning: <ShieldExclamationIcon />,
                                            information: <InformationCircleIcon />,
                                            failure: <ExclamationCircleIcon />,
                                            unknown: <QuestionMarkCircleIcon />,
                                        }[status]
                                    }
                                </span>
                            </span>
                        </div>
                        <div className="ml-3 w-0 flex-1">
                            <p className="text-xs font-medium text-gray-400">{content}</p>
                            <p className="text-left text-xs text-gray-500">
                                {formatDistanceToNow(new Date(date), { addSuffix: true })}
                            </p>
                        </div>
                        <div className="ml-3 flex-shrink-0 flex">
                            <button
                                data-test="toast-close-button"
                                type="button"
                                onClick={closeToast}
                                className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 shadow-md z-50"
                            >
                                <XMarkIcon className="h-3.5 w-3.5 text-gray-200" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
