import {
    filterGreaterThan,
    GreaterThanColumnFilter,
    SelectColumnFilter,
    SliderColumnFilter,
} from 'Library/TableComponents';
import { Node, PolicyStats } from 'Types/types';
import { Column } from 'react-table';

export type DataBrowserFields = {
    actorId: string;
    actor: Node;
    trustScore: number;
    deviceId: string;
    device: Node;
    applicationId: string;
    application: Node;
    identityId: string;
    identity: Node;
    targetId: string;
    target: Node;
    targetDomain: string;
    success: number;
    challenge: number;
    failure: number;
    accessRate: PolicyStats | undefined;
    successRate: number;
    challengeRate: number;
    failureRate: number;
};

export const getDataBrowserColumns = (policy: boolean) => {
    let columns = [
        {
            Header: 'Actor ID',
            accessor: 'actorId',
            filter: 'fuzzyText',
            width: 530,
        },
        {
            Header: 'Actor Name',
            accessor: 'actor',
            filter: 'fuzzyNodeDisplayName',
            sortType: 'nodeDisplayName',
        },
        {
            Header: 'Device ID',
            accessor: 'deviceId',
            filter: 'fuzzyText',
            width: 530,
        },
        {
            Header: 'Device Name',
            accessor: 'device',
            filter: 'fuzzyNodeDisplayName',
            sortType: 'nodeDisplayName',
            width: 170,
        },
        {
            Header: 'Application ID',
            accessor: 'applicationId',
            filter: 'fuzzyText',
            width: 530,
        },
        {
            Header: 'Application Name',
            accessor: 'application',
            filter: 'fuzzyNodeDisplayName',
            sortType: 'nodeDisplayName',
        },

        {
            Header: 'Identity ID',
            accessor: 'identityId',
            filter: 'fuzzyText',
            width: 530,
        },

        {
            Header: 'Target ID',
            accessor: 'targetId',
            filter: 'fuzzyText',
            width: 530,
        },
        {
            Header: 'Target Name',
            accessor: 'target',
            filter: 'fuzzyNodeDisplayName',
            sortType: 'nodeDisplayName',
        },
        {
            Header: 'Target Domain',
            accessor: 'targetDomain',
            filter: 'fuzzyText',
        },
        {
            Header: 'MFA Required',
            accessor: 'mfaRequired',
            Filter: SelectColumnFilter,
            filter: 'includesSome',
            width: 150,
        },
        {
            Header: 'MFA Status',
            accessor: 'mfaStatus',
            Filter: SelectColumnFilter,
            filter: 'includesSome',
            width: 150,
        },
        {
            Header: 'MFA Details',
            accessor: 'mfaDetails',
            filter: 'fuzzyText',
            width: 200,
        },
        {
            Header: 'Trust Score',
            accessor: 'trustScore',
            sortType: 'greaterThan',
            Filter: GreaterThanColumnFilter,
            filter: 'greaterThan',
            width: 150,
        },
        {
            Header: 'Success',
            accessor: 'success',
            sortType: 'greaterThan',
            Filter: GreaterThanColumnFilter,
            filter: 'greaterThan',
            width: 150,
        },
        {
            Header: 'Challenge',
            accessor: 'challenge',
            sortType: 'greaterThan',
            Filter: GreaterThanColumnFilter,
            filter: 'greaterThan',
            width: 150,
        },
        {
            Header: 'Failure',
            accessor: 'failure',
            sortType: 'greaterThan',
            Filter: GreaterThanColumnFilter,
            filter: 'greaterThan',
            width: 150,
        },
        {
            Header: 'Access Rate Breakdown (%)',
            accessor: 'accessRate',
            sortType: 'policyStats',
            filter: 'fuzzyText',
            Filter: <></>,
            width: 220,
        },
        {
            Header: 'Success Rate (%)',
            accessor: 'successRate',
            sortType: 'greaterThan',
            Filter: SliderColumnFilter,
            filter: filterGreaterThan,
            width: 170,
        },
        {
            Header: 'Challenge Rate (%)',
            accessor: 'challengeRate',
            sortType: 'greaterThan',
            Filter: SliderColumnFilter,
            filter: filterGreaterThan,
            width: 170,
        },
        {
            Header: 'Failure Rate (%)',
            accessor: 'failureRate',
            sortType: 'greaterThan',
            Filter: SliderColumnFilter,
            filter: filterGreaterThan,
            width: 170,
        },
    ] as Column<DataBrowserFields>[];

    if (!policy) {
        columns = columns.filter((column) => column.accessor !== 'trustScore');
    }
    return columns;
};
