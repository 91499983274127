import { useEffect, useMemo, useState } from 'react';
import {
    ResponsiveContainer,
    YAxis,
    XAxis,
    Tooltip,
    Legend,
    CartesianGrid,
    Bar,
    Cell,
    ComposedChart,
    Line,
} from 'recharts';
import { DocumentNode, useQuery } from '@apollo/client';
import { useTenant } from 'Hooks/Hooks';
import { UnitInterval } from 'Types/types';
import { format } from 'date-fns';
import { ChartLegend, ChartXAxis } from 'Utilities/ChartUtilities';
import { NoSymbolIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useHotkeys } from 'react-hotkeys-hook';
import { classNames, formatNumber } from 'Utilities/utils';
import { formatInTimeZone } from 'date-fns-tz';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type DashboardChartProps = {
    startDate: number;
    endDate: number;
    interval: UnitInterval;
    height?: number;
    legendHeight?: number;
    margin?: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
    onBarClick: (epoch: number | undefined, index?: number | undefined) => void;
    selectedBar: number;
    plotTrust?: boolean;
    trustData?: number[];
};

type BarProps = {
    name: string;
    dataKey: string;
    activeColor: string;
    inactiveColor: string;
};

type BaseChartProps<BackendRow extends { date: number }> = DashboardChartProps & {
    bars: BarProps[];
    query: DocumentNode;
    queryName: string;
    variables: object;
    processRow: (row: BackendRow, previousRow: Record<string, number>) => Record<string, number>;
};

type Data = {
    date: string;
    epoch: number;
};

export const BaseChart = <BackendRow extends { date: number }>({
    query,
    queryName,
    variables,
    height = 200,
    legendHeight = 36,
    margin = {
        top: 0,
        right: 0,
        left: -20,
        bottom: 0,
    },
    bars,
    startDate,
    endDate,
    interval,
    processRow,
    onBarClick,
    selectedBar,
    plotTrust = false,
    trustData,
}: BaseChartProps<BackendRow>): JSX.Element => {
    const { useCalculatedTrustScore } = useFlags();
    const tenantId = useTenant();
    const [data, setData] = useState<Data[]>([]);

    const [cachedStartDate, setCachedStartDate] = useState(startDate);
    const [cachedEndDate, setCachedEndDate] = useState(endDate);
    const [cachedInterval, setCachedInterval] = useState(interval);
    const [cachedVariables, setCachedVariables] = useState(variables);

    const {
        loading,
        error,
        data: gqlData,
    } = useQuery(query, {
        variables: {
            tenantId,
            unit: interval,
            startDate: startDate,
            endDate: endDate,
            ...variables,
        },
        skip: !tenantId,
    });

    useEffect(() => {
        if (
            startDate !== cachedStartDate ||
            endDate !== cachedEndDate ||
            interval !== cachedInterval ||
            !compareObjects(variables, cachedVariables)
        ) {
            setCachedStartDate(startDate);
            setCachedEndDate(endDate);
            setCachedInterval(interval);
            setCachedVariables(variables);
            setData([]);
        }
    }, [startDate, endDate, interval, variables, cachedStartDate, cachedEndDate, cachedInterval, cachedVariables]);

    useEffect(() => {
        if (gqlData && data.length === 0) {
            if (gqlData[queryName]) {
                const intervals: Data[] = [];

                const { items } = gqlData[queryName];

                if (items) {
                    let formatString = '';
                    switch (interval) {
                        case 'STATS_UNITS_HOUR':
                            formatString = `EEE, HH:mm`;
                            break;
                        case 'STATS_UNITS_DAY':
                            formatString = `EEE, MMM dd`;
                            break;

                        case 'STATS_UNITS_MONTH':
                            formatString = `MMM yyyy`;
                            break;
                    }

                    let previousRow: Record<string, number> = {};

                    items.map((item: BackendRow) => {
                        const processedRow = processRow(item, previousRow);

                        previousRow = processedRow;

                        let date;

                        // If we are showing hourly data we can translate to the user's timezone
                        if (interval === 'STATS_UNITS_HOUR') {
                            date = format(item.date, formatString);
                        }

                        // If we are showing daily or monthly data we need to keep it in UTC
                        else {
                            date = formatInTimeZone(item.date, 'UTC', formatString);
                        }

                        const row = {
                            epoch: item.date,
                            date: date,
                            ...processedRow,
                        };

                        intervals.push(row);
                    });

                    setData(intervals);
                } else {
                    setData([]);
                }
            }
        }
    }, [data, gqlData, interval, processRow, queryName]);

    useHotkeys(
        'right',
        () => {
            if (selectedBar >= data.length - 1) {
                selectBarByIndex(0);
            } else {
                selectBarByIndex(selectedBar + 1);
            }
        },
        {},
        [selectedBar, data],
    );
    useHotkeys(
        'left',
        () => {
            if (selectedBar == 0) {
                selectBarByIndex(data.length - 1);
            } else {
                selectBarByIndex(selectedBar - 1);
            }
        },
        {},
        [selectedBar, data],
    );

    const selectBarByIndex = (index: number) => {
        if (selectedBar === index) {
            if (onBarClick) {
                onBarClick(undefined, -1);
            }
        } else {
            const epoch = data[index].epoch;
            if (epoch) {
                if (onBarClick) {
                    onBarClick(epoch, index);
                }
            }
        }
    };

    const onClickChart = (event: { activeTooltipIndex: number }) => {
        if (event) {
            const index = event.activeTooltipIndex;
            selectBarByIndex(index);
        }
    };

    const [dataKeyEnabledState, setDataKeyEnabledState] = useState(
        bars.reduce(
            (enabledState, bar) => {
                return (enabledState[bar.dataKey] = true), enabledState;
            },
            {} as { [key: string]: Boolean },
        ),
    );

    const legendClickHandler = (dataKey: string, enabled: Boolean) => {
        const newState = { ...dataKeyEnabledState, [dataKey]: enabled };
        setDataKeyEnabledState(newState);
    };

    const trustMergedData = useMemo(() => {
        if (!plotTrust) {
            return data;
        }

        if (useCalculatedTrustScore) {
            return data;
        }

        return data.map((item, index) => {
            return {
                ...item,
                Trust: trustData ? trustData[index] : 0,
            };
        });
    }, [data, plotTrust, trustData, useCalculatedTrustScore]);

    if (loading) {
        return (
            <div
                className={classNames('text-center text-gray-300 flex flex-col items-center justify-center w-full')}
                style={{ height }}
            >
                <div className="h-8 w-8 loader mb-3" />
                Loading...
            </div>
        );
    }
    if (error) {
        return (
            <div
                className={classNames('text-center text-red-400 pt-2 flex flex-col items-center justify-center w-full')}
                style={{ height }}
            >
                <NoSymbolIcon className="h-8 w-8 text-red-400 mb-3" />
                Error loading results
            </div>
        );
    }
    if (data.length === 0) {
        return (
            <div
                className={classNames('text-center text-gray-400 flex flex-col items-center justify-center w-full')}
                style={{ height }}
            >
                <DocumentMagnifyingGlassIcon className="h-8 w-8 text-gray-400 mb-3" />
                No matching records in this time period
            </div>
        );
    }

    return (
        <ResponsiveContainer width={'100%'} height={height}>
            <ComposedChart data={trustMergedData} margin={margin} onClick={onClickChart}>
                <Legend
                    verticalAlign="top"
                    height={legendHeight}
                    content={<ChartLegend onClick={legendClickHandler} />}
                />
                <CartesianGrid stroke="rgba(0,0,0,0.15)" />
                <XAxis
                    dataKey="date"
                    stroke="rgba(0,0,0,0.15)"
                    style={{
                        fontSize: '11px',
                    }}
                    tick={<ChartXAxis />}
                />

                <YAxis
                    tickCount={5}
                    stroke="rgba(0,0,0,0.15)"
                    style={{
                        fontSize: '11px',
                    }}
                    tick={{ fill: 'rgba(255,255,255, 0.5)' }}
                    tickFormatter={formatNumber}
                />

                {plotTrust && (
                    <YAxis
                        tickCount={5}
                        stroke="rgba(0,0,0,0.15)"
                        style={{
                            fontSize: '11px',
                        }}
                        tick={{ fill: 'rgba(255,255,255, 0.5)' }}
                        tickFormatter={formatNumber}
                        yAxisId={2}
                        orientation="right"
                        domain={[0, 100]}
                    />
                )}

                <Tooltip
                    offset={25}
                    cursor={{ fill: 'rgba(255,255,255,0.15)', stroke: 'white', strokeWidth: 2 }}
                    contentStyle={{
                        background: 'rgb(31 41 55)',
                        borderRadius: '6px',
                        border: '1px solid rgb(107 114 128)',
                    }}
                />
                {bars.map((bar) => {
                    return (
                        <Bar
                            stackId="a"
                            key={bar.dataKey}
                            name={bar.name}
                            dataKey={bar.dataKey}
                            hide={!dataKeyEnabledState[bar.dataKey]}
                            fill={bar.activeColor}
                            isAnimationActive={false}
                        >
                            {data.map((_, index) => (
                                <Cell key={index} fill={selectedBar === index ? bar.activeColor : bar.inactiveColor} />
                            ))}
                        </Bar>
                    );
                })}
                {plotTrust && (
                    <Line
                        type="monotone"
                        dataKey={'Trust'}
                        stroke={DARK_BLUE}
                        dot={{ stroke: 'darkGray', strokeWidth: 0.1, r: 2.5, fill: BLUE }}
                        connectNulls={true}
                        name="Trust"
                        strokeWidth={1.5}
                        yAxisId={2}
                        activeDot={false}
                        isAnimationActive={false}
                    />
                )}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

const compareObjects = (obj1: object, obj2: object) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const BLUE = '#36a8fa';
const DARK_BLUE = '#0c8deb';
