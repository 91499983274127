import { List, ListItem } from '../Library/List';

import { DashboardTabProps } from './DashboardModalTypes';
import { Stat } from 'Library/Stat';
import { getProviderIconElement } from 'Map/Graph/Icons';
import { useEffect, useRef, useState } from 'react';
import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
} from 'recharts';
import { generateDataSeries } from 'Map/Graph/Data';
import { subHours } from 'date-fns';
import { ChartLegend } from 'Utilities/ChartUtilities';
import { formatNumber } from 'Utilities/utils';
import { renderToString } from 'react-dom/server';
import { ComposableMap, ZoomableGroup, Geographies, Geography } from 'react-simple-maps';
import WorldCountriesGeos from 'Dashboard/Charts/WorldCountriesGeos.json';
import { geojson } from './TrustDashboardGeoJson';

export const TrustPrototype = ({ open }: DashboardTabProps) => {
    if (!open) return <> </>;

    return (
        <div>
            <div>
                <div className="w-full grid lg:grid-cols-8 grid-cols-4 gap-4 mb-4">
                    <div className="col-span-4 justify-around bg-gray-700 rounded-lg flex p-2">
                        <Stat
                            title="Total Actors"
                            value={54053}
                            previousValue={53000}
                            dotColor="rgba(59, 130, 246, 1)" // Blue
                            className="w-1/2 md:w-auto flex flex-col items-center p-2"
                        />
                        <Stat
                            title="Internal Actors"
                            value={43015}
                            previousValue={42000}
                            dotColor="rgba(249, 115, 22, 1)" // Orange
                            className="w-1/2 md:w-auto flex flex-col items-center p-2"
                        />
                        <Stat
                            title="External Actors"
                            value={2285}
                            previousValue={2100}
                            dotColor="rgba(74, 222, 128, 1)" // Green
                            className="w-1/2 md:w-auto flex flex-col items-center p-2"
                        />
                        <Stat
                            title="Machine Actors"
                            value={8735}
                            previousValue={8700}
                            dotColor="rgba(219, 39, 119, 1)" // Pink
                            className="w-1/2 md:w-auto flex flex-col items-center p-2"
                        />
                    </div>
                    <div className="col-span-2 justify-around bg-gray-700 rounded-lg flex p-2">
                        <Stat
                            title="Devices"
                            value={2285}
                            previousValue={2100}
                            dotColor="rgba(139, 92, 246, 1)" // Purple
                            className="w-1/2 md:w-auto flex flex-col items-center p-2"
                        />
                        <Stat
                            title="Managed Devices"
                            value={1430}
                            previousValue={1540}
                            dotColor="rgba(252, 211, 77, 1)" // Yellow
                            className="w-1/2 md:w-auto flex flex-col items-center p-2"
                        />
                    </div>
                    <div className="col-span-2 justify-around bg-gray-700 rounded-lg flex p-2">
                        <Stat
                            title="Targets"
                            value={2285}
                            previousValue={2100}
                            dotColor="rgba(96, 165, 250, 1)" // Light blue
                            className="w-1/2 md:w-auto flex flex-col items-center p-2"
                        />
                        <Stat
                            title="Managed Targets"
                            value={153}
                            previousValue={140}
                            dotColor="rgba(248, 113, 113, 1)" // Red
                            className="w-1/2 md:w-auto flex flex-col items-center p-2"
                        />
                    </div>
                </div>
            </div>

            <div className="grid xl:grid-cols-6 grid-cols-2 gap-4">
                <div className="bg-gray-700 rounded-lg">
                    <List title="Trust Score">
                        <></>
                    </List>
                    <div className="w-full relative">
                        {/* <TrustScore /> */}
                        <GlobalTrustScore />
                        <div className="absolute bottom-8 w-full font-medium text-6xl flex place-content-center ">
                            <span style={{ textShadow: '1px 1px 2px black' }}>85</span>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-700 rounded-lg">
                    <List title="Actors By Trust">
                        <></>
                    </List>
                    <div className="w-full">
                        <TrustScorePie />
                    </div>
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <h1 className="text-sm font-medium p-4">Actors By Trust</h1>
                    <div className="px-4 pb-2">
                        <table className="w-full text-left border-collapse">
                            <thead className="bg-gray-600 text-gray-100 rounded-t-lg">
                                <tr>
                                    <th className="p-2">Name</th>
                                    <th className="p-2">Email</th>
                                    <th className="p-2">Activity</th>
                                    <th className="p-2">Trust</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-200">
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Lee Gu</td>
                                    <td className="p-2 border-b border-gray-500">leeg@example.com</td>
                                    <td className="p-2 border-b border-gray-500">20</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">0</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Pradeep Gupta</td>
                                    <td className="p-2 border-b border-gray-500">pradeepg@example.com</td>
                                    <td className="p-2 border-b border-gray-500">15,967</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">4</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Joni Sherman</td>
                                    <td className="p-2 border-b border-gray-500">jonis@example.com</td>
                                    <td className="p-2 border-b border-gray-500">72</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">12</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Roman Smith</td>
                                    <td className="p-2 border-b border-gray-500">romans@example.com</td>
                                    <td className="p-2 border-b border-gray-500">568</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">16</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Amanda Hosking</td>
                                    <td className="p-2 border-b border-gray-500">amandah@example.com</td>
                                    <td className="p-2 border-b border-gray-500">248</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">19</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Fiona Wright</td>
                                    <td className="p-2">fionaw@example.cm</td>
                                    <td className="p-2">5,417</td>
                                    <td className="p-2 text-red-400">21</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2 row-span-3 lg:-order-last order-last">
                    <List title="Activity Log">
                        <ListItem
                            title="New Actor Created"
                            height="h-16"
                            description="Bob Sponge, Account Manager, London, UK"
                            iconHTMLImageElement={getProviderIconElement('Azure AD')}
                        />
                        <ListItem
                            title="Actor Disabled"
                            height="h-16"
                            description="Joni Sherman, VP Marketing, San Francisco, USA"
                            iconHTMLImageElement={getProviderIconElement('Azure AD')}
                        />
                        <ListItem
                            title="Actor Transition to Dormant"
                            height="h-16"
                            description="Rajesh Chandra, Contractor, New York, USA"
                            iconHTMLImageElement={getProviderIconElement('Azure AD')}
                        />
                        <ListItem
                            title="Privileged PAM Session From Unmanaged Device"
                            height="h-16"
                            description="Session reported through CyberArk for Alex Wilber"
                            iconHTMLImageElement={getProviderIconElement('CyberArk')}
                        />
                        <ListItem
                            title="Admin Privileges Granted"
                            height="h-16"
                            description="Alex Wilber, Marketing manager, Chicago, USA"
                            iconHTMLImageElement={getProviderIconElement('Azure AD')}
                        />
                        <ListItem
                            title="New Target Discovered"
                            height="h-16"
                            description="Zoom"
                            iconHTMLImageElement={getProviderIconElement('Okta')}
                        />
                        <ListItem
                            title="Actor Trust Reached Low"
                            height="h-16"
                            description="Pradeep Gupta, Software Engineer, Bangalore, India"
                            iconHTMLImageElement={getProviderIconElement('Okta')}
                        />
                        <ListItem
                            title="New Behavior - Admin Console"
                            height="h-16"
                            description="Pradeep Gupta, Software Engineer, Bangalore, India"
                            iconHTMLImageElement={getProviderIconElement('Okta')}
                        />
                        <ListItem
                            title="Target Directly Accessed"
                            height="h-16"
                            description="Box accessed by Howard Chan without SSO"
                            iconHTMLImageElement={getProviderIconElement('Box')}
                        />
                        <ListItem
                            title="Credential Stuffing Detected"
                            height="h-16"
                            description="1,412 attempts in last 1 hour"
                            iconHTMLImageElement={getProviderIconElement('Azure AD')}
                        />
                        <ListItem
                            title="Activity from High Risk Location"
                            height="h-16"
                            description="Fiona Wright in Wuhan, China"
                            iconHTMLImageElement={getProviderIconElement('Azure AD')}
                        />
                        <ListItem
                            title="New MFA registered to an Admin Account"
                            height="h-16"
                            description="Fiona Wright, Developer, San Jose, USA"
                            iconHTMLImageElement={getProviderIconElement('Azure AD')}
                        />
                        <ListItem
                            title="At Risk User Accessed Sensitive Data"
                            height="h-16"
                            description="Amanda Hosking, Account Manager, London, UK"
                            iconHTMLImageElement={getProviderIconElement('Workday')}
                        />
                        <ListItem
                            title="Box Connected"
                            height="h-16"
                            description="Box has been enabled as a new provider"
                            iconHTMLImageElement={getProviderIconElement('Box')}
                        />
                    </List>
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <List title="Trust Profiles">
                        <></>
                    </List>
                    <TrustProfiles />
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <h1 className="text-sm font-medium p-4">Sessions At Risk</h1>
                    <div className="px-4">
                        <table className="w-full text-left border-collapse">
                            <thead className="bg-gray-600 text-gray-100">
                                <tr>
                                    <th className="p-2">Actor</th>
                                    <th className="p-2">Device</th>
                                    <th className="p-2">Target</th>
                                    <th className="p-2">Trust</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-200">
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Lee Gu</td>
                                    <td className="p-2 border-b border-gray-500">Lee's MacBook Pro</td>
                                    <td className="p-2 border-b border-gray-500">Salesforce.com</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">0</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Pradeep Gupta</td>
                                    <td className="p-2 border-b border-gray-500">win-943827-hkg</td>
                                    <td className="p-2 border-b border-gray-500">Exchange Online</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">4</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Joni Sherman</td>
                                    <td className="p-2 border-b border-gray-500">Joni's iPhone</td>
                                    <td className="p-2 border-b border-gray-500">Slack</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">12</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Roman Smith</td>
                                    <td className="p-2 border-b border-gray-500">win-193042-lon</td>
                                    <td className="p-2 border-b border-gray-500">Okta Admin Portal</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">16</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Amanda Hosking</td>
                                    <td className="p-2 border-b border-gray-500">ah-ipad</td>
                                    <td className="p-2 border-b border-gray-500">Workday</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">19</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Fiona Wright</td>
                                    <td className="p-2 border-b border-gray-500">win-102948-bri</td>
                                    <td className="p-2 border-b border-gray-500">AWS</td>
                                    <td className="p-2 border-b border-gray-500 text-red-400">21</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Mandeep Singh</td>
                                    <td className="p-2">corp-mac-10934</td>
                                    <td className="p-2">Zoom</td>
                                    <td className="p-2 text-red-400">24</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <List title="Activity By Location">
                        <></>
                    </List>
                    <TrustWorldMap />
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <h1 className="text-sm font-medium p-4">Locations By Trust</h1>
                    <div className="px-4">
                        <table className="w-full text-left border-collapse">
                            <thead className="bg-gray-600 text-gray-100 rounded-t-lg">
                                <tr>
                                    <th className="p-2">Country</th>
                                    <th className="p-2">City</th>
                                    <th className="p-2">Activity</th>
                                    <th className="p-2">Trust</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-200">
                                <tr>
                                    <td className="p-2 border-b border-gray-500">United States</td>
                                    <td className="p-2 border-b border-gray-500">Sunnyvale</td>
                                    <td className="p-2 border-b border-gray-500">547</td>
                                    <td className="p-2 border-b border-gray-500 text-green-400">97</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">United States</td>
                                    <td className="p-2 border-b border-gray-500">Alexandria</td>
                                    <td className="p-2 border-b border-gray-500">423</td>
                                    <td className="p-2 border-b border-gray-500 text-green-400">94</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">United States</td>
                                    <td className="p-2 border-b border-gray-500">Palo Alto</td>
                                    <td className="p-2 border-b border-gray-500">357</td>
                                    <td className="p-2 border-b border-gray-500 text-green-400">91</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Brazil</td>
                                    <td className="p-2 border-b border-gray-500">Sao Paulo</td>
                                    <td className="p-2 border-b border-gray-500">324</td>
                                    <td className="p-2 border-b border-gray-500 text-green-400">90</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">France</td>
                                    <td className="p-2 border-b border-gray-500">Paris</td>
                                    <td className="p-2 border-b border-gray-500">248</td>
                                    <td className="p-2 border-b border-gray-500 text-green-400">88</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border-b border-gray-500">Portugal</td>
                                    <td className="p-2 border-b border-gray-500">Lisbon</td>
                                    <td className="p-2 border-b border-gray-500">227</td>
                                    <td className="p-2 border-b border-gray-500 text-yellow-400">81</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Australia</td>
                                    <td className="p-2">Melbourne</td>
                                    <td className="p-2">215</td>
                                    <td className="p-2 text-yellow-400">78</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const data = [
    { name: 'High', value: 973, color: '#29AB65' },
    { name: 'Medium', value: 57, color: '#F5CD19' },
    { name: 'Low', value: 23, color: '#B64C45' },
];

const TrustScorePie = () => {
    return (
        <div className="flex justify-center items-center w-full h-52 pb-2 -mt-4">
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        data={data}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        isAnimationActive={false}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} stroke="none" />
                        ))}
                    </Pie>
                    <Legend verticalAlign="bottom" height={8} content={<ChartLegend />} />
                    <Tooltip
                        offset={25}
                        cursor={{ fill: 'rgba(255,255,255,0.15)', stroke: 'white', strokeWidth: 2 }}
                        contentStyle={{
                            background: 'rgb(31 41 55)',
                            borderRadius: '6px',
                            border: '1px solid rgb(107 114 128)',
                        }}
                        itemStyle={{ color: 'white' }}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

const profiles = ['Baseline', 'Administrators', 'Employees', 'Executives', 'Contractors'];

const colors = ['#8B909B', '#E697FF', '#007DC1', '#29AB65', '#FBE76A']; // Define unique colors for each profile

const now = new Date();

const series = profiles.map((p) => {
    const data = generateDataSeries();
    return {
        name: p,
        data: data.map((d, index) => {
            return { date: subHours(now, index).getTime(), value: d };
        }),
    };
});

const TrustProfiles = () => {
    // Recharts ine chart with
    // x axis: time
    // y axis: trust score per profile
    // profiles:
    // - baseline
    // - administrators
    // - us employees
    // - executives
    // - contractors

    return (
        <div className="flex justify-center items-center pb-8 pr-8 ">
            <ResponsiveContainer width="100%" height={230}>
                <LineChart height={230}>
                    <XAxis
                        dataKey="date"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={(time) => new Date(time).toLocaleTimeString()}
                        tick={false}
                        axisLine={false}
                    />

                    <CartesianGrid stroke="rgba(0,0,0,0.15)" />

                    <YAxis
                        stroke="rgba(0,0,0,0.15)"
                        style={{
                            fontSize: '11px',
                        }}
                        tick={{ fill: 'rgba(255,255,255, 0.5)' }}
                        tickFormatter={formatNumber}
                    />

                    <Tooltip
                        labelFormatter={(label) => new Date(label).toLocaleTimeString()}
                        offset={25}
                        cursor={{ fill: 'rgba(255,255,255,0.15)', stroke: 'white', strokeWidth: 2 }}
                        contentStyle={{
                            background: 'rgb(31 41 55)',
                            borderRadius: '6px',
                            border: '1px solid rgb(107 114 128)',
                        }}
                    />
                    {series.map((s, index) => (
                        <Line
                            dot={false}
                            type="monotone"
                            dataKey="value"
                            data={s.data}
                            name={s.name}
                            key={s.name}
                            stroke={colors[index % colors.length]}
                            strokeWidth={1.5}
                            isAnimationActive={false}
                        />
                    ))}
                    <Legend verticalAlign="bottom" height={8} content={<ChartLegend />} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export const TrustWorldMap = () => {
    return (
        <ComposableMap
            id="map"
            projection="geoMercator"
            projectionConfig={{
                scale: 180,
                rotate: [-10, 0, 0],
                center: [0, 18],
            }}
            style={{ width: '100%', height: '270px' }}
        >
            <ZoomableGroup>
                <Geographies geography={WorldCountriesGeos}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const fill = '#6B7280';
                            const strokeWidth = 0.5;

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={fill}
                                    fillOpacity={0.05}
                                    stroke="#9DA3AD"
                                    strokeWidth={strokeWidth}
                                    style={{
                                        default: { outline: 'none' },
                                        hover: { outline: 'none' },
                                        pressed: { outline: 'none' },
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>

                <Geographies geography={geojson}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const { value, row, col, opacity } = geo.properties;

                            let fill = '#36a8fa';
                            let stroke = '#36a8fa';

                            if (row === 9 && col === 15) {
                                fill = '#EBAB07';
                                stroke = '#EBAB07';
                            }
                            if (row === 22 && col === 28) {
                                fill = '#EBAB07';
                                stroke = '#EBAB07';
                            }

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    data-tooltip-id={'heatmap'}
                                    data-tooltip-html={renderToString(
                                        <div className="text-center">
                                            <div>{value} Events</div>
                                        </div>,
                                    )}
                                    geography={geo}
                                    fill={fill}
                                    fillOpacity={value > 0 ? opacity : 0.8}
                                    stroke={stroke}
                                    strokeOpacity={value > 0 ? opacity + 0.1 : 0.9}
                                    className="cursor-pointer"
                                    style={{
                                        default: { outline: 'none' },
                                        hover: { outline: 'none' },
                                        pressed: { outline: 'none' },
                                    }}
                                    onMouseEnter={() => {
                                        console.log(
                                            `Row: ${row}, Column: ${col}, Value: ${value}, Opacity: ${opacity}`,
                                        );
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>
            </ZoomableGroup>
        </ComposableMap>
    );
};

const RADIAN = Math.PI / 180;
const piedata = [
    { name: 'A', value: 33, color: '#B54C45' },
    { name: 'B', value: 33, color: '#EBAB07' },
    { name: 'C', value: 33, color: '#29AB65' },
];
const iR = 60;
const oR = 80;
const value = 800;

interface DataItem {
    name: string;
    value: number;
    color: string;
}

const needle = (value: number, data: DataItem[], cx: number, cy: number, iR: number, oR: number, color: string) => {
    let total = 0;
    data.forEach((v) => {
        total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle key="circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
        <path key="path" d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="none" fill={color} />,
    ];
};

const GlobalTrustScore = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [center, setCenter] = useState({ cx: 0, cy: 0 });

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (entries.length === 0 || !entries[0].target) return;
            const { width, height } = entries[0].target.getBoundingClientRect();
            setCenter({ cx: width / 2, cy: height / 2 });
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div className="flex justify-center items-center w-full h-56 -mt-4" ref={containerRef}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        isAnimationActive={false}
                        dataKey="value"
                        startAngle={180}
                        endAngle={0}
                        data={piedata}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        stroke="none"
                    >
                        {piedata.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    {needle(value, data, center.cx, center.cy, iR, oR, '#ffffff')}
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};
