import { LinkIcon, MinusIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { ReactNode, useContext, useEffect, useState, useCallback, MouseEvent, useMemo, memo } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';
import { useLocalStorage, useTenant, useURLParameter } from 'Hooks/Hooks';
import { useClipboard } from 'use-clipboard-copy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { startOfDay, startOfMinute, subDays, subHours } from 'date-fns';
import { Tooltip } from 'Library/Tooltip';
import { useToasts } from 'Hooks/Toasts';
import { generateDataSeries } from 'Map/Graph/Data';
import { View } from 'Types/types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DELETE_STORAGE_KEY, GET_STORAGE_KEY, GET_STORAGE_KEYS, SET_STORAGE_KEY } from 'Graph/typedQueries';
import { useAuth } from 'Hooks/Auth';
import { useForm } from 'react-hook-form';
import { User, useUsers } from 'Hooks/User';

type DecodedKey = {
    id: string;
    key: string;
    tenantId: string;
    userId: string;
    type: string;
    displayName: string;
    userName: string;
    shared: boolean;
};

const shallowCloneNode = (node: Node): Node => {
    return {
        id: node.id,
        name: node.name,
        label: node.label,
        x: node.x,
        y: node.y,
        links: [],
        neighbors: [],
        props: node.props,
        tags: node.tags,
        attributes: node.attributes,
        queryAttributes: node.queryAttributes,
    };
};

export const Views = (): JSX.Element => {
    const { disableCachedTime, maxNodeCount } = useFlags();

    const tenantId = useTenant();
    const { userId } = useAuth();
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { addToast } = useToasts();
    const { viewsOpen } = mapState;
    const { getUserById } = useUsers();

    const [selectedView, setSelectedView] = useState('Default');

    const [loadedSharedView, setLoadedSharedView] = useState(false);
    const [loadedTempView, setLoadedTempView] = useState<boolean>(false);
    const [tempViewCachedTimeReset, setTempViewCachedTimeReset] = useState(false);

    const urlQuery = useURLParameter();

    const { getScopedStorageKey } = useLocalStorage();

    const { register, handleSubmit, reset } = useForm<{ name: string }>();

    const { data: dataGetStorageKeys } = useQuery(GET_STORAGE_KEYS, {
        variables: { tenantId: tenantId || '' },
        skip: !tenantId,
    });

    const { usersSavedViewKeys, usersSharedViewKeys, allSharedViewKeys } = useMemo(() => {
        const usersSavedViewKeys: DecodedKey[] = [];
        const usersSharedViewKeys: DecodedKey[] = [];
        const allSharedViewKeys: DecodedKey[] = [];

        if (tenantId && userId && dataGetStorageKeys && dataGetStorageKeys.getStorageKeys) {
            dataGetStorageKeys.getStorageKeys.keys?.map((key) => {
                // split the key at the dollar sign to get the tenantId, userId, key type, and key name
                const splitKey = key.key.split('$');

                if (splitKey.length < 4) {
                    console.error('Invalid key', key);
                    return;
                }

                const keyTenantId = splitKey[0];
                const keyUserId = splitKey[1];
                const keyType = splitKey[2];
                const keyName = splitKey[3];

                if (keyTenantId != tenantId) {
                    console.error('TenantId does not match', key);
                    return;
                }

                if (keyType != 'views') {
                    console.error('Invalid key type', key);
                    return;
                }

                if (keyUserId != userId && !key.shared) {
                    console.error('UserId does not match', key);
                    return;
                }

                if (!keyName) {
                    console.error('Invalid key name', key);
                    return;
                }

                if (userId == key.userId) {
                    if (key.shared) {
                        usersSharedViewKeys.push({
                            id: key.key,
                            key: key.key,
                            userId: key.userId,
                            tenantId: keyTenantId,
                            type: keyType,
                            displayName: keyName,
                            userName: '',
                            shared: key.shared,
                        });
                    } else {
                        usersSavedViewKeys.push({
                            id: key.key,
                            key: key.key,
                            userId: key.userId,
                            tenantId: keyTenantId,
                            type: keyType,
                            displayName: keyName,
                            userName: '',
                            shared: key.shared,
                        });
                    }
                } else {
                    allSharedViewKeys.push({
                        id: key.key,
                        key: key.key,
                        userId: key.userId,
                        tenantId: keyTenantId,
                        type: keyType,
                        displayName: keyName,
                        userName: '',
                        shared: key.shared,
                    });
                }
            });
        }

        return {
            usersSavedViewKeys,
            usersSharedViewKeys,
            allSharedViewKeys,
        };
    }, [dataGetStorageKeys, tenantId, userId]);

    const [setStorageKey, { loading: loadingSetStorageKey }] = useMutation(SET_STORAGE_KEY);

    const makeView = useCallback(
        (name: string) => {
            const { graphData } = mapState;
            const selectedNodes = [...mapState.selectedNodes].map((n) => {
                return shallowCloneNode(n);
            });
            const queriedNodes = [...mapState.queriedNodes].map((n) => {
                return shallowCloneNode(n);
            });
            const view: View = {
                name: name,
                createdAt: +new Date(),
                positions: {},
                group: mapState.group,
                layers: {
                    labels: mapState.layers.labels,
                    events: mapState.layers.events,
                    posture: mapState.layers.posture,
                    names: mapState.layers.names,
                },
                visible: {
                    actors: mapState.visible.actors,
                    targets: mapState.visible.targets,
                    identities: mapState.visible.identities,
                    devices: mapState.visible.devices,
                    applications: mapState.visible.applications,
                },
                graphFilters: mapState.graphFilters,
                selectedTime: [mapState.selectedTime[0], mapState.selectedTime[1]],
                timeWindowMin: mapState.timeWindowMin,
                timeWindowMax: mapState.timeWindowMax,
                levelTrails: Array.from(mapState.levelTrails),
                selectedNodes: selectedNodes,
                queriedNodes: queriedNodes,
                profileNode: mapState.selectedProfileNode ? shallowCloneNode(mapState.selectedProfileNode) : undefined,
            };

            // Only save the node positions if the graph is below the max node count
            if (graphData.nodes.length <= maxNodeCount) {
                graphData.nodes.map((n) => {
                    view.positions[n.id] = {
                        x: n.x,
                        y: n.y,
                        isLocked: mapState.lockedNodes.has(n),
                        selected: mapState.selectedNodes.has(n),
                    };
                });
            }
            return view;
        },
        [mapState, maxNodeCount],
    );

    const saveViewToBackend = useCallback(
        async (name: string): Promise<string | undefined> => {
            // Make a view object from the current state
            const view = makeView(name);

            // Get a base key for the view that is scoped to this tenant and user
            const key = getScopedStorageKey('views');

            if (key) {
                const fullKey = `${key}$${name}`;

                const result = await setStorageKey({
                    variables: {
                        key: fullKey,
                        shared: false,
                        tenantId: tenantId || '',
                        data: JSON.stringify(view),
                    },
                    refetchQueries: ['getStorageKeys'],
                    awaitRefetchQueries: true,
                });

                console.log(result);

                if (result.data?.setStorage) {
                    addToast(`The view ${name} has been saved`, 'success');
                    reset();
                    return fullKey;
                } else {
                    addToast(`Failed to save the view ${name}`, 'failure');
                }
            }
        },
        [addToast, getScopedStorageKey, makeView, reset, setStorageKey, tenantId],
    );

    const createNewView = useCallback(
        async (formData: { name: string }) => {
            const { name } = formData;

            // If the name is empty, we should not save it
            if (!name) {
                return;
            }

            // If the name contains any non-alphanumeric characters, we should not save it
            if (!/^[a-zA-Z0-9 ]*$/.test(name)) {
                addToast('The view name can only contain letters and numbers', 'failure');
                return;
            }

            // Trim any whitespace from the name
            const trimmedName = name.trim();

            setSelectedView('');

            const key = await saveViewToBackend(trimmedName);

            if (key) {
                setSelectedView(key);
            }
        },
        [addToast, saveViewToBackend],
    );

    // Restoring a view is a multi-step process, it unfolds as such:
    // 1 - dispatch a set-pending-view action with the view object, this lets the reducer know we want to apply a view
    // 2 - an effect will run (below) that will set the time-window, selected time, graph filters, visible layers and nodes
    // 3 - the GQL client will detect the changes in time which in turn will make a request to the gql backend for new data
    // 4 - the GQL client will pass the new data into the reducer set-graph-data function
    // 5 - the set-graph-data function will load the incoming data for the view, and then apply the view afterwards
    //
    // This process is necessary to ensure that we have given the server time to load all of the required nodes before trying
    // to load a view. If we do not execute in this fashion it can lead to inconsistent behavior when restoring.

    const restoreView = useCallback(
        (view: View, key: string) => {
            console.debug('Restoring view', view);
            dispatch({ type: 'set-block-identity-map-updates', blocked: true });
            dispatch({ type: 'set-pending-view', view: view });
            setSelectedView(key);
        },
        [dispatch],
    );

    useEffect(() => {
        const view = mapState.pendingView;
        if (view) {
            if (view.graphFilters) {
                dispatch({ type: 'apply-graph-filter', filters: [...view.graphFilters] });
            } else {
                dispatch({ type: 'apply-graph-filter', filters: [] });
            }
            if (view.levelTrails) {
                dispatch({ type: 'set-level-trails', trails: view.levelTrails });
            }
            if (view.group) {
                dispatch({ type: 'set-groups', group: view.group });
            }
            dispatch({ type: 'set-time-window-max', max: view.timeWindowMax });
            dispatch({ type: 'set-time-window-min', min: view.timeWindowMin });
            dispatch({ type: 'set-selected-time', time: [...view.selectedTime] });
            dispatch({
                type: 'set-selected-nodes',
                nodes: new Set(
                    view.selectedNodes.map((n) => {
                        n.trustData = generateDataSeries();
                        return n;
                    }),
                ),
            });
            dispatch({ type: 'set-queried-nodes', nodes: new Set(view.queriedNodes) });
            if (view.profileNode) {
                dispatch({ type: 'set-profile-node', node: view.profileNode });
                dispatch({ type: 'set-profile-window', open: true });
            }
            dispatch({ type: 'set-layers', layers: { ...view.layers } });
            dispatch({ type: 'set-visibility', visible: { ...view.visible } });

            dispatch({ type: 'set-block-identity-map-updates', blocked: false });
        }
    }, [dispatch, mapState.pendingView]);

    // This memo will unpack and parse the view that has been added to the
    // URL if it is present. The parameter looks like https://fabric.doublezero.io?view=<foo bar>
    const sharedViewKey = useMemo((): string | undefined => {
        const encodedKey = urlQuery.get('view');

        const decodedKey = encodedKey ? atob(encodedKey) : undefined;

        if (decodedKey) {
            console.log('Shared view key', decodedKey);
            return decodedKey;
        }
    }, [urlQuery]);

    // This effect handles keeping the temporary view cache in sync with the current view state
    useEffect(() => {
        const key = getScopedStorageKey('tempView');
        // When the view cache changes, we should save it to local storage
        if (key && loadedTempView) {
            const view = makeView('tempView');
            localStorage.setItem(key, JSON.stringify(view));
        }
    }, [getScopedStorageKey, loadedTempView, makeView, mapState]);

    // When the page loads, we want to check for a cached temp view
    // If it exists, we should restore it so the user can pickup from where they left off
    // If there is a shared view in the URL, we skip this effect to prioritize the shared view
    useEffect(() => {
        const key = getScopedStorageKey('tempView');
        if (key && !loadedTempView) {
            const urlView = urlQuery.get('view');
            if (urlView) {
                console.log('Not loading temp view cache as we are loading a shared view from the URL');
                setLoadedTempView(true);
                return;
            }
            const view = localStorage.getItem(key);
            if (view && !loadedTempView) {
                console.debug('Restoring tempView from localStorage');
                const parsedView = JSON.parse(view);
                if (parsedView) {
                    const view = parsedView as View;
                    // we bring the time window max to right now to ensure the user
                    // knows there is fresh data available to load
                    const now = +startOfMinute(new Date());
                    const twoMinutesAgo = now - 120000;
                    view.timeWindowMax = twoMinutesAgo;

                    // Some older view may not have a createdAt timestamp, so we will skip this if not present
                    if (view.createdAt) {
                        // If the view is older than 72 hours, we should set the time bounds to the last 6 hours
                        // This is to ensure that the user is not looking at stale data
                        const viewAge = now - view.createdAt;

                        if (viewAge > 259200000) {
                            console.log(
                                'Cached temp view is older than 72 hours, setting time bounds to last 6 hours and the time window minimum to 5 days ago',
                            );

                            // Set the time window minimum to today -5 days
                            view.timeWindowMin = +startOfDay(subDays(now, 5));
                            view.timeWindowMax = twoMinutesAgo;

                            // Set the selected time to the last 6 hours
                            view.selectedTime = [+subHours(now, 6), twoMinutesAgo];

                            addToast(
                                'Your last session is older than 3 days, so we have adjusted the time bounds to the last 6 hours to show you fresh data',
                                'information',
                            );
                        } else {
                            console.debug('Cached temp view is less than 72 hours old, not adjusting time bounds');
                        }
                    }

                    restoreView(view, 'tempView');
                    setLoadedTempView(true);
                }
            } else if (!view && !loadedTempView) {
                console.debug('No tempView in localStorage');
                setLoadedTempView(true);
                dispatch({ type: 'set-block-identity-map-updates', blocked: false });
            }
        }
    }, [addToast, dispatch, getScopedStorageKey, loadedTempView, restoreView, urlQuery]);

    // Internal users sometimes require the cached view time window to be set to the current time
    // This effect will set the time window to the current time if the user has set the
    // disableCachedTime flag in LaunchDarkly and the view is not a shared view
    useEffect(() => {
        const sharedViewLoading = urlQuery.get('view');
        if (loadedTempView && !sharedViewLoading && !tempViewCachedTimeReset && disableCachedTime) {
            setTempViewCachedTimeReset(true);
            console.log('Disabling cached time');
            const now = +startOfMinute(new Date());
            dispatch({ type: 'set-selected-time', time: [now - 7200000, now] });
        }
    }, [disableCachedTime, dispatch, loadedTempView, tempViewCachedTimeReset, urlQuery]);

    const ViewsComponent = () => {
        return (
            <div className="space-y-6">
                <ViewsSection title="Your Private Views">
                    {usersSavedViewKeys.map((key) => (
                        <ViewChip
                            key={key.key}
                            decodedKey={key}
                            restoreView={restoreView}
                            active={selectedView == key.key}
                            autoLoad={false}
                            setLoadedSharedView={setLoadedSharedView}
                            saveViewToBackend={saveViewToBackend}
                            savingView={loadingSetStorageKey && key.key == selectedView}
                        />
                    ))}
                </ViewsSection>

                <ViewsSection title="Your Shared Views">
                    {usersSharedViewKeys.map((key) => (
                        <ViewChip
                            key={key.key}
                            decodedKey={key}
                            user={getUserById(key.userId)}
                            restoreView={restoreView}
                            active={selectedView == `${key.key}$shared`}
                            autoLoad={!loadedSharedView && key.key == sharedViewKey}
                            setLoadedSharedView={setLoadedSharedView}
                            saveViewToBackend={saveViewToBackend}
                            savingView={loadingSetStorageKey && key.key == selectedView}
                        />
                    ))}
                </ViewsSection>

                <ViewsSection title="All Shared Views">
                    {allSharedViewKeys.map((key) => (
                        <ViewChip
                            key={key.key}
                            decodedKey={key}
                            user={getUserById(key.userId)}
                            restoreView={restoreView}
                            active={selectedView == `${key.key}$shared`}
                            autoLoad={!loadedSharedView && key.key == sharedViewKey}
                            setLoadedSharedView={setLoadedSharedView}
                            saveViewToBackend={saveViewToBackend}
                            savingView={loadingSetStorageKey && key.key == selectedView}
                        />
                    ))}
                </ViewsSection>

                <ViewsSection title="Create New View">
                    <div className="relative" id="ViewInput">
                        <form onSubmit={handleSubmit(createNewView)}>
                            <input
                                data-test="view-input"
                                type="text"
                                className="w-full py-2.5 z-10 text-xs bg-gray-900 border-0 rounded-md m-0 px-2"
                                placeholder="Name this view"
                                {...register('name')}
                                disabled={loadingSetStorageKey}
                                autoComplete="off"
                                autoFocus={true}
                                pattern="[a-zA-Z0-9 ]*"
                                data-1p-ignore
                            />
                            <button
                                data-test="view-save-button"
                                className={classNames(
                                    'btn rounded-md text-xs px-3 py-1 m-0 absolute right-1.5 top-1.5',
                                    loadingSetStorageKey ? 'btn-disabled' : '',
                                )}
                                type="submit"
                                disabled={loadingSetStorageKey}
                            >
                                {loadingSetStorageKey ? 'Saving...' : 'Save'}
                            </button>
                        </form>
                    </div>
                </ViewsSection>
            </div>
        );
    };

    return (
        <div className="relative inline-block text-left pointer-events-auto">
            <div>
                <button
                    type="button"
                    onClick={() => dispatch({ type: 'toggle-views' })}
                    className={classNames(
                        'inline-flex justify-center w-full rounded-full shadow-sm px-4 py-2 text-sm  focus:outline-none',
                        viewsOpen ? 'bg-blue-700 text-gray-200' : 'bg-gray-900 text-gray-400 hover:bg-gray-800',
                    )}
                >
                    Views
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </button>
            </div>
            <div
                id="Views"
                className={classNames(
                    'origin-top-right absolute right-0 mt-2 w-200 rounded-md shadow-xl shadow-gray-950 bg-gray-800 border border-gray-400 p-3 ring-1 ring-black ring-opacity-5 focus:outline-none',
                    viewsOpen ? 'block' : 'hidden',
                )}
            >
                <ViewsComponent />
            </div>
        </div>
    );
};

type ViewChipProps = {
    decodedKey: DecodedKey;
    user?: User;
    restoreView: (view: View, key: string) => void;
    active: boolean;
    autoLoad: boolean;
    setLoadedSharedView: (loaded: boolean) => void;
    saveViewToBackend: (name: string) => Promise<string | undefined>;
    savingView: boolean;
};

const ViewChip = memo(
    ({
        decodedKey: key,
        user,
        restoreView,
        active,
        autoLoad,
        setLoadedSharedView,
        saveViewToBackend,
        savingView,
    }: ViewChipProps): JSX.Element => {
        const tenantId = useTenant();
        const { userId } = useAuth();

        const { addToast } = useToasts();

        const isUsersOwnView = key.userId == userId;

        const clipboard = useClipboard({
            copiedTimeout: 2000,
        });

        const [deleteStorageKey, { loading: loadingDeleteStorageKey }] = useMutation(DELETE_STORAGE_KEY);
        const [getStorageKey, { loading: loadingGetStorageKey }] = useLazyQuery(GET_STORAGE_KEY);
        const [setStorageKey, { loading: loadingSetStorageKey }] = useMutation(SET_STORAGE_KEY);

        const deleteView = async (key: DecodedKey, event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();

            const result = await deleteStorageKey({
                variables: {
                    key: key.key,
                    shared: key.shared,
                    tenantId: tenantId || '',
                },
                refetchQueries: ['getStorageKeys'],
                awaitRefetchQueries: true,
            });

            console.log(result);
        };

        const restoreViewFromBackend = useCallback(
            async (key: DecodedKey, event?: MouseEvent<HTMLDivElement>, showToast?: boolean) => {
                if (event) {
                    event.stopPropagation();
                }

                const result = await getStorageKey({
                    variables: {
                        key: key.key,
                        shared: key.shared,
                        tenantId: tenantId || '',
                        userId: key.userId,
                    },
                });

                console.log(result);

                if (result.data?.getStorage) {
                    const view = JSON.parse(result.data.getStorage.data) as View;
                    const viewKey = key.shared ? `${key.key}$shared` : key.key;

                    if (showToast) {
                        key.shared
                            ? addToast(`The shared view ${view.name} has been loaded`, 'information')
                            : addToast(`The view ${view.name} has been loaded`, 'information');
                    }

                    restoreView(view, viewKey);
                }
            },
            [addToast, getStorageKey, restoreView, tenantId],
        );

        const updateView = async (key: DecodedKey, event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            console.log('Updating view', key);

            saveViewToBackend(key.displayName);
        };

        const shareView = async (key: DecodedKey, event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            console.log('Sharing view', key);

            const getResult = await getStorageKey({
                variables: {
                    key: key.key,
                    shared: key.shared,
                    tenantId: tenantId || '',
                    userId: key.userId,
                },
            });

            if (!getResult.data?.getStorage) {
                console.error('No view found for key', key);
                return;
            }

            const viewData = getResult.data.getStorage.data;

            const setResult = await setStorageKey({
                variables: {
                    key: key.key,
                    shared: true,
                    tenantId: tenantId || '',
                    data: viewData,
                },
                refetchQueries: ['getStorageKeys'],
                awaitRefetchQueries: true,
            });

            if (setResult.data?.setStorage) {
                console.log('View shared', key);
            } else {
                console.error('Failed to share view', key);
            }
        };

        const copyShareLink = async (key: DecodedKey, event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();

            const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/`;

            // base64 encoded the key to ensure it is URL safe
            const encodedKey = btoa(key.key);

            const shareUrl = baseUrl + `?view=${encodedKey}`;

            clipboard.copy(shareUrl);

            console.log('Copying share link', shareUrl);
        };

        useEffect(() => {
            if (autoLoad) {
                console.log('Auto loading view', key);
                setLoadedSharedView(true);
                restoreViewFromBackend(key, undefined, true);
            }
        }, [autoLoad, key, restoreViewFromBackend, setLoadedSharedView]);

        return (
            <div
                data-test={`saved-view-${key.displayName}`}
                className={classNames(
                    'text-xs flex w-full items-center justify-between pl-2 pr-1.5 py-1.5 rounded-md mb-1 hover:bg-gray-600 cursor-pointer',
                    active ? 'bg-blue-700 text-white' : 'bg-gray-900',
                )}
                key={key.key}
                onClick={(e) => restoreViewFromBackend(key, e, true)}
            >
                <div className="flex items-center space-x-2 w-full">
                    {isUsersOwnView && (
                        <Tooltip label="Delete View">
                            {loadingDeleteStorageKey ? (
                                <div className="h-4 w-4 loader" />
                            ) : (
                                <button
                                    data-test="delete-view"
                                    type="button"
                                    onClick={(e) => deleteView(key, e)}
                                    className="rounded-full bg-gray-800 border border-gray-400 flex items-center justify-center p-0.5 hover:bg-red-800 active:bg-red-700"
                                >
                                    <MinusIcon className="w-3 h-3 text-gray-300" />
                                </button>
                            )}
                        </Tooltip>
                    )}

                    <div className="flex-1 flex place-items-center">
                        {key.displayName}
                        {key.shared && user && (
                            <span className={classNames('ml-1', active ? 'text-gray-300' : 'text-gray-500')}>
                                by {user.name || user.email}
                            </span>
                        )}
                        {loadingGetStorageKey && <div className="ml-2 h-4 w-4 loader" />}
                    </div>

                    {isUsersOwnView && !key.shared && (
                        <div className="flex">
                            {active && (
                                <Tooltip label="Update this view">
                                    <button
                                        type="button"
                                        onClick={(e) => updateView(key, e)}
                                        className={classNames(
                                            'btn flex items-center justify-center py-1 px-2 rounded-md mr-1 w-16',
                                            savingView ? 'btn-disabled hover:bg-gray-700' : '',
                                        )}
                                        disabled={savingView}
                                    >
                                        {savingView ? <div className="loader h-4 w-4" /> : 'Update'}
                                    </button>
                                </Tooltip>
                            )}
                            <Tooltip label="Publish a copy to shared views">
                                {loadingSetStorageKey ? (
                                    <div className="flex items-center justify-center p-1 mr-1">
                                        <div className="h-4 w-4 loader" />
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={(e) => shareView(key, e)}
                                        className="btn rounded-sm flex items-center justify-center p-1 mr-1"
                                    >
                                        <ArrowTopRightOnSquareIcon className="w-4 h-4 text-gray-300" />
                                    </button>
                                )}
                            </Tooltip>
                        </div>
                    )}
                    {key.shared && (
                        <Tooltip label={clipboard.copied ? 'Copied!' : 'Copy shareable link to clipboard'}>
                            <button
                                type="button"
                                onClick={(e) => copyShareLink(key, e)}
                                className="btn rounded-sm flex items-center justify-center p-1 mr-2"
                            >
                                <LinkIcon className="w-4 h-4 text-gray-300" />
                            </button>
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    },
);

export type ViewsSectionProps = {
    title: string;
    children: ReactNode;
};
export const ViewsSection = ({ title, children }: ViewsSectionProps): JSX.Element => (
    <div className="flex-1">
        <h2 className="uppercase tracking-wider font-bold text-xs text-gray-500 mb-3">{title}</h2>
        <div className="">{children}</div>
    </div>
);
