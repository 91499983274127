import { useQuery } from '@apollo/client';
import { GET_USER, LIST_USERS } from 'Graph/queries';
import { useAuth } from './Auth';
import { useCallback, useEffect, useState } from 'react';

export const useCurrentUser = (): User => {
    const { userId, tenantId } = useAuth();

    const { data, error } = useQuery(GET_USER, { variables: { userId, tenantId }, skip: !tenantId || !userId });

    if (data) {
        return {
            userId,
            tenantId,
            name: data.getUser.name,
            email: data.getUser.email,
        };
    } else if (error) {
        return {
            userId,
            tenantId,
            name: null,
            email: null,
        };
    } else {
        return {
            userId,
            tenantId,
            name: undefined,
            email: undefined,
        };
    }
};

export type User = {
    userId: string | undefined | null;
    tenantId: string | undefined | null;
    name: string | null | undefined;
    email: string | null | undefined;
};

type UseUsers = {
    getUserById: (userId: string) => User | undefined;
};

export const useUsers = (): UseUsers => {
    const { tenantId } = useAuth();

    const { data } = useQuery(LIST_USERS, { variables: { tenantId }, skip: !tenantId });

    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        if (data) {
            setUsers(
                data.listUsers.map((user: any) => ({
                    userId: user.userId,
                    tenantId: user.tenantId,
                    name: user.name,
                    email: user.email,
                })),
            );
        }
    }, [data]);

    const getUserById = useCallback(
        (userId: string) => {
            return users.find((user) => user.userId === userId);
        },
        [users],
    );

    return {
        getUserById,
    };
};
