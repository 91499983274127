import { useContext, useMemo, useReducer, useRef } from 'react';
import { ForceGraphMethods } from 'react-force-graph-2d';

import { VirtualAgentsModal } from 'Agents/VirtualAgentsModal';
import { AlertsModal } from 'Alerts/AlertsModal';
import { useBoxSelect, useIdentityMapData } from 'Hooks/Hooks';
import { AlertsModalMock } from 'Mocks/AlertsMock/AlertsModalMock';
import { NavigatorModal } from 'Navigator/NavigatorModal';
import { MaxNodeProfile } from 'NodeProfiles/MaxNodeProfile';
import { PolicyModalImplemented } from 'Policy/PolicyImplemented/PolicyModalImplemented';
import { classNames, groupingForceApplied } from 'Utilities/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { Helmet } from 'react-helmet-async';
import { useHotkeys } from 'react-hotkeys-hook';
import { Portal } from 'react-portal';
import { useMediaQuery } from 'react-responsive';
import { ConfigurationModal } from '../Configuration/ConfigurationModal';
import { DashboardModal } from '../Dashboard/DashboardModal';
import { TimelineModal } from '../Timeline/TimelineModal';
import { Adjustments } from './Components/Adjustments';
import { ContextMenu } from './Components/ContextMenu';
import { Events } from './Components/DetailedAccessEvents/DetailedAccessEvents';
import { Explorer } from './Components/Explorer';
import { Header } from './Components/Header';
import { HighlightOptions } from './Components/HighlightOptions';
import { FilterIndicator, GroupIndicator } from './Components/Indicators';
import { MapControls } from './Components/MapControls';
import { Refinements } from './Components/Refinements';
import { StatusOverlay } from './Components/StatusOverlay';
import { TimeWindowSelector } from './Components/TimeWindowSelector';
import { ToastContext } from './Components/ToastContext';
import { ToastList } from './Components/Toasts';
import { Tour } from './Components/Tour';
import { TourContext } from './Components/TourProvider';
import { DataBrowser } from './DataBrowser/DataBrowserModal';
import { Graph } from './Graph/Graph';
import { Profile } from './Profiles/Profile';
import { FilterPanel } from './Refine/FilterPanel';
import { GroupingPanel } from './Refine/GroupingPanel';
import { IdentityMapContext } from './State/IdentityMapContext';
import { initialMapState, reducer } from './State/Reducer';
import { PolicyModalStreamlined } from 'Policy/PolicyStreamlined/PolicyModalStreamlined';

const IDENTITY_MAP_MENU_ID = 'identity-map-menu';

function IdentityMap(): JSX.Element {
    const graphRef = useRef<ForceGraphMethods>();
    initialMapState.graphRef = graphRef;
    const [mapState, dispatch] = useReducer(reducer, initialMapState);
    const contextValue = useMemo(() => {
        return { mapState, dispatch };
    }, [mapState, dispatch]);

    const {
        enableTour,
        enableNewPolicyModal,
        enableFiltering,
        enableGrouping,
        enableNavigator,
        enableAlerts,
        useAlertsMock,
    } = useFlags();
    const { loading, extendedLoading, error, data } = useIdentityMapData(mapState, dispatch);
    const { pointerDown, pointerMove, pointerUp } = useBoxSelect(mapState, dispatch);
    const { toastState } = useContext(ToastContext);
    const { productTourState } = useContext(TourContext);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useHotkeys('`', () => dispatch({ type: 'toggle-filter' }));
    useHotkeys('p', () => dispatch({ type: 'toggle-profile' }));
    useHotkeys('o', () => dispatch({ type: 'toggle-dashboard' }));
    useHotkeys('b', () => dispatch({ type: 'toggle-data-browser' }));
    useHotkeys('control+=', () => dispatch({ type: 'toggle-adjustments' }));

    const { show } = useContextMenu({
        id: IDENTITY_MAP_MENU_ID,
    });

    const displayContextMenu = (e: TriggerEvent & { clientX: number; clientY: number }) => {
        // prevent default action
        e.stopPropagation();
        e.preventDefault();

        dispatch({ type: 'set-identity-map-context-menu-state', open: true });

        // show context menu
        show(e, {
            position: {
                x: e.clientX,
                y: e.clientY,
            },
        });

        // in the case where the user already had the context menu open, we receive
        // a close event after the open event, so we need to set the state to open 2x
        setTimeout(() => {
            dispatch({ type: 'set-identity-map-context-menu-state', open: true });
        }, 100);
    };

    return (
        <IdentityMapContext.Provider value={contextValue}>
            <Helmet>
                <title>Identity Map</title>
            </Helmet>

            <div id="IdentityMap">
                <section className="relative text-gray-400 bg-gray-900 body-font overflow-hidden">
                    <div className="w-full absolute top-0 z-40">
                        <Header />
                    </div>
                    {mapState.graphData && (
                        <>
                            <section
                                className={classNames(
                                    isMobile ? 'top-32' : 'top-14',
                                    'absolute mt-3 w-screen mx-auto text-center z-10 pointer-events-none flex space-x-2 justify-center',
                                )}
                            >
                                {mapState.graphFilters && mapState.graphFilters.length > 0 && <FilterIndicator />}
                                {groupingForceApplied(mapState.group) && <GroupIndicator />}
                            </section>
                            <section
                                className={classNames(
                                    isMobile ? 'top-32' : 'top-20',
                                    'absolute left-3 z-10 pointer-events-none hover:z-20',
                                )}
                            >
                                <Explorer />
                            </section>
                            {mapState.profileOpen && !mapState.maxProfileOpen && (
                                <section
                                    className={classNames(
                                        isMobile ? 'top-32' : 'top-20',
                                        'absolute right-3 z-10 hover:z-20',
                                    )}
                                >
                                    <Profile />
                                </section>
                            )}
                            <section
                                className={classNames(
                                    'absolute z-10 pointer-events-none',
                                    mapState.dateTimeOpen ? 'bottom-36' : 'bottom-28',
                                )}
                            >
                                {mapState.actionsOpen && <Events />}
                            </section>

                            <section className="absolute bottom-6 p-1 z-10 flex">
                                <TimeWindowSelector dataLoading={loading} />
                            </section>

                            {!mapState.dateTimeOpen && (
                                <section className="absolute left-[50%] translate-x-[-50%] bottom-20 z-10 flex justify-center">
                                    <MapControls dataLoading={Boolean(loading)} error={Boolean(error)} />
                                </section>
                            )}
                            <section className="absolute bottom-3 left-3 z-10 pointer-events-none">
                                <HighlightOptions />
                            </section>
                            <section
                                className="absolute bottom-96 -right-8 z-10 h-4 w-4 pointer-events-none"
                                id="TutorialAnchorPoint"
                            />
                            <section className="absolute bottom-3 right-3 z-10 pointer-events-none">
                                <Refinements />
                            </section>
                            <section
                                className={classNames(
                                    isMobile ? 'bottom-36' : 'bottom-36',
                                    'absolute left-[50%] translate-x-[-50%] z-10 hover:z-20',
                                )}
                            >
                                {enableFiltering && <FilterPanel />}
                                {enableGrouping && <GroupingPanel />}
                            </section>
                            <section className={classNames(isMobile ? 'top-36' : 'top-20', 'absolute right-3 z-10')}>
                                <Adjustments />
                            </section>
                            <section className="absolute top-0 left-0 w-screen h-screen pointer-events-none">
                                {enableTour && productTourState && <Tour />}
                            </section>

                            <section
                                className="z-10"
                                id="graphContainer"
                                onPointerMove={pointerMove}
                                onPointerDown={pointerDown}
                                onPointerUp={pointerUp}
                                onContextMenu={displayContextMenu}
                            >
                                <Graph />
                            </section>

                            {toastState && (
                                <Portal>
                                    <div
                                        className={classNames(
                                            isMobile ? 'top-36' : 'top-16',
                                            'absolute right-1 z-40 pointer-events-auto',
                                        )}
                                    >
                                        <ToastList />
                                    </div>
                                </Portal>
                            )}
                        </>
                    )}
                    <StatusOverlay loading={loading} error={error} data={data} extendedLoading={extendedLoading} />
                </section>
            </div>

            <section className="absolute top-36 z-20">
                {mapState.policyOpen &&
                    (enableNewPolicyModal ? <PolicyModalStreamlined /> : <PolicyModalImplemented />)}
            </section>

            <section className="absolute top-36 z-20">
                {enableAlerts && mapState.alertsOpen && (useAlertsMock ? <AlertsModalMock /> : <AlertsModal />)}
            </section>

            <section className="absolute top-36 z-20">{mapState.timelineOpen && <TimelineModal />}</section>

            <section className="absolute top-0 z-20">
                <DashboardModal open={mapState.dashboardOpen} />
            </section>

            <section className="absolute top-0 z-20">
                {mapState.maxProfileOpen && <MaxNodeProfile open={mapState.maxProfileOpen} />}
            </section>

            <section className="absolute top-0 z-20">
                {enableNavigator && mapState.navigatorOpen && <NavigatorModal />}
            </section>

            <section className="absolute top-0 z-20">
                <DataBrowser />
            </section>

            <ContextMenu
                id={IDENTITY_MAP_MENU_ID}
                node={mapState.rootHoveredNode}
                onHidden={() => {
                    dispatch({ type: 'set-identity-map-context-menu-state', open: false });
                    dispatch({ type: 'set-root-hovered-node', node: null });
                }}
            />

            <section className="absolute top-36 z-20">{mapState.agentsOpen && <VirtualAgentsModal />}</section>

            <section>{mapState.configOpen && <ConfigurationModal />}</section>
        </IdentityMapContext.Provider>
    );
}

export default IdentityMap;
