import { tourSteps } from 'Tour/Steps';
import { DoubleZeroTourStep } from './TourTypes';

export const getTourStepName = (tourState: TourState): string | undefined => {
    if (tourState) {
        const step = tourState.steps[tourState.stepIndex];
        if (step) {
            return step.stepName;
        }
    }
};

export type TourState = {
    run: boolean;
    continuous: boolean;
    loading: boolean;
    stepIndex: number;
    steps: DoubleZeroTourStep[];
};

export const initialTourState: TourState = {
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: tourSteps,
};

export type Action =
    | { type: 'start-tour' }
    | { type: 'reset-tour' }
    | { type: 'stop-tour' }
    | { type: 'move-between-steps'; payload: Object }
    | { type: 'restart-tour' };

export const reducer = (state = initialTourState, action: Action): TourState => {
    switch (action.type) {
        case 'start-tour':
            return { ...state, run: true };
        case 'reset-tour':
            return { ...state, stepIndex: 0 };
        case 'stop-tour':
            return { ...state, run: false };
        case 'move-between-steps':
            return { ...state, ...action.payload };
        case 'restart-tour':
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
            };
        default:
            return state;
    }
};
