import { GET_ENTITY_COUNT_HISTORY } from 'Graph/queries';
import { BackendTagHistoryData, BackendTagHistoryTag } from 'Types/types';
import { BaseChart, DashboardChartProps } from './BaseChart';

const TAGS = ['TAG_ACTOR_EXTERNAL', 'TAG_ACTOR_INTERNAL', 'TAG_ACTOR_MACHINE'];

export const ActorsTagsChart = ({ startDate, endDate, interval, onBarClick, selectedBar }: DashboardChartProps) => {
    return (
        <BaseChart
            query={GET_ENTITY_COUNT_HISTORY}
            queryName="getEntityCountHistory"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_ACTOR',
                tags: TAGS,
            }}
            processRow={(row: BackendTagHistoryData) => {
                const tempRow: Record<string, number> = {};
                if (row.tags) {
                    row.tags.map((tag: BackendTagHistoryTag) => {
                        if (TAGS.includes(tag.tag)) {
                            tempRow[tag.tag] = tag.count;
                        }
                    });
                }
                return tempRow;
            }}
            bars={[
                {
                    name: 'Internal Actors',
                    dataKey: 'TAG_ACTOR_INTERNAL',
                    activeColor: 'rgba(75, 192, 192, 1)',
                    inactiveColor: 'rgba(75, 192, 192, 0.5)',
                },
                {
                    name: 'Machine Actors',
                    dataKey: 'TAG_ACTOR_MACHINE',
                    activeColor: 'rgba(53, 162, 235, 1)',
                    inactiveColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    name: 'External Actors',
                    dataKey: 'TAG_ACTOR_EXTERNAL',
                    activeColor: 'rgba(255, 99, 132, 1)',
                    inactiveColor: 'rgba(255, 99, 132, 0.5)',
                },
            ]}
            startDate={startDate}
            endDate={endDate}
            interval={interval}
            onBarClick={onBarClick}
            selectedBar={selectedBar}
        />
    );
};
