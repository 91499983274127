import Select from 'react-select';
import { GET_ENTITIES_BY_TYPE_AS_NODES } from 'Graph/queries';
import { useDebounce, useTenant } from 'Hooks/Hooks';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_ACTORS_TO_POLICY_PROFILE,
    LIST_POLICY_PROFILES,
    REMOVE_ACTORS_FROM_POLICY_PROFILE,
} from 'Graph/typedQueries';
import { BackendNode } from 'Map/Graph/Data';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ListPolicyProfilesQuery } from 'GeneratedGQL/graphql';
import {
    Column,
    FilterValue,
    IdType,
    Row,
    useBlockLayout,
    useFilters,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable,
} from 'react-table';
import { DefaultColumnFilter, fuzzyTextFilterFn, SelectPolicyStatusFilter } from 'Library/TableComponents';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronUpDownIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ExclamationTriangleIcon,
    FunnelIcon,
} from '@heroicons/react/24/solid';
import { DefaultCell, Skeleton } from 'Map/DataBrowser/DataBrowserCells';
import { useUserPermissions } from 'Utilities/UserPermissions';
import { classNames, tagNameLookup } from 'Utilities/utils';
import { Tooltip } from 'Library/Tooltip';
import { Switch } from '@headlessui/react';

const now = new Date().getTime();

type TrustProfile = ListPolicyProfilesQuery['listPolicyProfiles'][number];

type ActorMappingFields = {
    actor: BackendNode;
    id: string;
    displayName: string;
    alternateId: string;
    tags: string[];
};

export const TrustProfileMappings = (): JSX.Element => {
    const tenantId = useTenant();

    const [actorPagesExhausted, setActorPagesExhausted] = useState(false);

    const [allActorsEnabled, setAllActorsEnabled] = useState(false);
    const [toggleFilters, setToggleFilters] = useState(false);

    const [addActorsToPolicyProfile, { loading: loadingAdd, error: errorAdd }] =
        useMutation(ADD_ACTORS_TO_POLICY_PROFILE);
    const [removeActorsFromPolicyProfile, { loading: loadingRemove, error: errorRemove }] = useMutation(
        REMOVE_ACTORS_FROM_POLICY_PROFILE,
    );

    const columns = useMemo(
        () =>
            [
                {
                    Header: 'Actor Name',
                    accessor: 'displayName',
                    filter: 'fuzzyText',
                    width: 250,
                },
                {
                    Header: 'Alternate ID',
                    accessor: 'alternateId',
                    filter: 'fuzzyText',
                    width: 250,
                },
                {
                    Header: 'Tags',
                    accessor: 'tags',
                    filter: 'fuzzyText',
                    width: 300,
                },
                {
                    Header: 'Enable Trust Calculation',
                    id: 'trustProfiles',
                    width: 250,
                    sortType: 'trustSort',
                    filter: 'trustEnabledStatus',
                    Filter: SelectPolicyStatusFilter,
                    defaultCanFilter: true,
                    defaultCanSort: true,
                },
            ] as Column<ActorMappingFields>[],
        [],
    );

    const {
        loading: loadingActors,
        data: dataActors,
        error: errorActors,
        fetchMore: fetchMoreActors,
    } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_ACTOR',
            permissionsOnly: false,
            dateInMs: now,
        },
    });

    // Fetch all actor pages.
    useEffect(() => {
        if (dataActors) {
            if (dataActors.getEntitiesByTypeAsNodes.cursor !== null) {
                console.log('Fetching actor page', dataActors.getEntitiesByTypeAsNodes.cursor);

                fetchMoreActors({
                    variables: {
                        cursor: dataActors.getEntitiesByTypeAsNodes.cursor,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;

                        if (!fetchMoreResult.getEntitiesByTypeAsNodes.nodes) {
                            console.log('Page had no nodes');
                            setActorPagesExhausted(true);
                            return prev;
                        }

                        return {
                            ...prev,
                            getEntitiesByTypeAsNodes: {
                                ...fetchMoreResult.getEntitiesByTypeAsNodes,
                                // Combine data from previous pages with new page
                                nodes: [
                                    ...prev.getEntitiesByTypeAsNodes.nodes,
                                    ...fetchMoreResult.getEntitiesByTypeAsNodes.nodes,
                                ],
                            },
                        };
                    },
                });
            } else {
                console.log('Actor pages exhausted');
                setActorPagesExhausted(true);
            }
        }
    }, [dataActors, fetchMoreActors]);

    const {
        loading: loadingTrustProfiles,
        data: dataTrustProfiles,
        error: errorTrustProfiles,
    } = useQuery(LIST_POLICY_PROFILES, { variables: { tenantId: tenantId || '' } });

    const actors = useMemo(() => {
        if (!dataActors) {
            return undefined;
        }

        if (dataActors && !actorPagesExhausted) {
            return undefined;
        }

        const nodes = dataActors?.getEntitiesByTypeAsNodes.nodes;

        const sortedNodes = nodes?.toSorted((a: BackendNode, b: BackendNode) => {
            if (!a.props.displayName || !b.props.displayName) {
                return 0;
            }
            if (a.props.displayName < b.props.displayName) {
                return -1;
            }
            if (a.props.displayName > b.props.displayName) {
                return 1;
            }
            return 0;
        });

        return sortedNodes;
    }, [actorPagesExhausted, dataActors]);

    const data = useMemo(() => {
        if (!actors) {
            return [];
        }

        const filteredActors = actors.filter((actor: { props: { displayName: string }; nodeType: string }) => {
            // Filter out nodes we don't want to enable trust calculation on
            if (actor.props.displayName === '') {
                return false;
            }
            if (actor.props.displayName === 'any') {
                return false;
            }

            // Keep all other nodes
            return true;
        });

        return filteredActors.map(
            (actor: { nodeId: string; props: { displayName: string; alternateId: string }; tags: string[] }) => {
                return {
                    actor: actor,
                    id: actor.nodeId,
                    displayName: actor.props.displayName,
                    alternateId: actor.props.alternateId,
                    tags: actor.tags,
                };
            },
        );
    }, [actors]);

    const trustProfiles = dataTrustProfiles?.listPolicyProfiles;

    const trustProfileByActorId = useMemo(() => {
        const trustProfileByActorId: Record<string, TrustProfile[]> = {};

        trustProfiles?.map((profile) => {
            profile.actorIds?.map((actorId) => {
                if (!trustProfileByActorId[actorId]) {
                    trustProfileByActorId[actorId] = [];
                }
                trustProfileByActorId[actorId].push(profile);
            });
        });

        console.log('Trust profiles by ActorId:', trustProfileByActorId);

        return trustProfileByActorId;
    }, [trustProfiles]);

    useEffect(() => {
        console.log('Trust Profiles:', trustProfiles);
        if (trustProfiles && trustProfiles.length > 0) {
            const trustProfile = trustProfiles[0];
            const profileHasAnyActor = trustProfile.actorIds.includes(`${tenantId}:any`);
            if (profileHasAnyActor) {
                setAllActorsEnabled(true);
            } else {
                setAllActorsEnabled(false);
            }
        }
    }, [tenantId, trustProfiles]);

    const toggleAllActors = async (enabled: boolean) => {
        if (!trustProfiles || trustProfiles.length == 0) {
            return;
        }
        const profileId = trustProfiles[0]?.profileId;

        if (enabled) {
            await addActorsToPolicyProfile({
                variables: {
                    tenantId: tenantId || '',
                    policyProfileId: profileId,
                    actorIds: [`${tenantId}:any`],
                },
                refetchQueries: ['listPolicyProfiles'],
                awaitRefetchQueries: true,
            });
        } else {
            await removeActorsFromPolicyProfile({
                variables: {
                    tenantId: tenantId || '',
                    policyProfileId: profileId,
                    actorIds: [`${tenantId}:any`],
                },
                refetchQueries: ['listPolicyProfiles'],
                awaitRefetchQueries: true,
            });
        }
    };

    const loading = loadingActors || loadingTrustProfiles || loadingAdd || loadingRemove;
    const error = errorActors || errorTrustProfiles;
    const errorChangingAnyActor = errorAdd || errorRemove;
    const canConfigure = dataTrustProfiles && trustProfiles && trustProfiles.length == 1;

    const trustEnabledStatusFn = useCallback(
        (rows: Array<Row<ActorMappingFields>>, _: Array<IdType<ActorMappingFields>>, filterValue: FilterValue) => {
            const keys = Object.keys(trustProfileByActorId);

            const matchedRows = rows.filter((row) => {
                if (filterValue === 'ENABLED') {
                    return keys.includes(row.original.id);
                } else {
                    return !keys.includes(row.original.id);
                }
            });

            console.log('Matched Rows:', matchedRows);

            return matchedRows;
        },
        [trustProfileByActorId],
    );

    const trustEnabledSort = useCallback(
        (rowA: Row<ActorMappingFields>, rowB: Row<ActorMappingFields>) => {
            const a = trustProfileByActorId[rowA.original.id];
            const b = trustProfileByActorId[rowB.original.id];

            return a ? (b ? 0 : -1) : b ? 1 : 0;
        },
        [trustProfileByActorId],
    );

    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            trustEnabledStatus: trustEnabledStatusFn,
        }),
        [trustEnabledStatusFn],
    );

    const sortTypes = useMemo(
        () => ({
            trustSort: trustEnabledSort,
        }),
        [trustEnabledSort],
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            minWidth: 100,
            width: 300,
            maxWidth: 800,
        }),
        [],
    );

    const loadingData = useMemo(() => Array(16).fill({}), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
        rows,
        selectedFlatRows,
        setPageSize,
    } = useTable<ActorMappingFields>(
        {
            columns,
            data: actorPagesExhausted || error ? data : loadingData,
            defaultColumn,
            filterTypes,
            sortTypes,
            disableMultiSort: true,
            defaultCanSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: 25,
                sortBy: [{ id: 'displayName' }],
            },
        },
        useFilters,
        useSortBy,
        usePagination,
        useFilters,
        useBlockLayout,
        useResizeColumns,
    );

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <div className="flex items-center space-x-2">
                    <p className="uppercase tracking-wider font-bold text-xs text-gray-400">
                        Actors To Calculate Trust On
                    </p>
                    {loading && <span className="h-4 w-4 loader" />}
                </div>
            </div>
            <div className="w-full my-2 text-xs flex space-x-4">
                <div className="bg-gray-700 rounded-md p-4 space-x-4 flex">
                    <div className="space-y-2">
                        <div className="">Pick a specific Actor</div>
                        <div className="text-gray-400">
                            Trust scores will only be calculated for these specific actors
                        </div>
                    </div>
                    <div className="flex items-center">
                        <input
                            className="chk-box h-5 w-5"
                            type="checkbox"
                            checked={!allActorsEnabled}
                            onChange={() => toggleAllActors(!allActorsEnabled)}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-center text-gray-300">OR</div>
                <div className="bg-gray-700 rounded-md p-4 space-x-4 flex">
                    <div className="flex items-center">
                        <input
                            className="chk-box h-5 w-5"
                            type="checkbox"
                            checked={allActorsEnabled}
                            onChange={() => toggleAllActors(!allActorsEnabled)}
                            disabled={loading}
                        />
                    </div>
                    <div className="space-y-2">
                        <div className="">Enable For All Actors</div>
                        <div className="text-gray-400">
                            Trust scores will be calculated for each access every actor makes
                        </div>
                    </div>
                </div>
            </div>

            {(error || errorChangingAnyActor) && (
                <div className="flex text-xs mt-6">
                    {error && <div>There was an issue retrieving the configured Trust mappings</div>}
                    {errorChangingAnyActor && (
                        <div>
                            There was an issue switching between "Pick a specific actor" and "Enable for all Actors"
                        </div>
                    )}
                </div>
            )}

            {!allActorsEnabled && (
                <div className="mt-6">
                    {canConfigure ? (
                        <table {...getTableProps()} className="w-full text-left text-xs mb-4">
                            <thead className="sticky top-0 bg-gray-700 z-10">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps()}
                                                className="pt-2 px-2 align-middle bg-gray-700"
                                            >
                                                <div className="flex items-center mb-2">
                                                    <span>{column.render('Header')}</span>
                                                    {column.canSort &&
                                                        (column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <ChevronDownIcon
                                                                    className="h-5 w-5"
                                                                    {...column.getSortByToggleProps()}
                                                                />
                                                            ) : (
                                                                <ChevronUpIcon
                                                                    className="h-5 w-5"
                                                                    {...column.getSortByToggleProps()}
                                                                />
                                                            )
                                                        ) : (
                                                            <ChevronUpDownIcon
                                                                className="h-5 w-5"
                                                                {...column.getSortByToggleProps()}
                                                            />
                                                        ))}
                                                    <div
                                                        {...column.getResizerProps()}
                                                        className={column.isResizing ? 'resizerIsResizing' : 'resizer'}
                                                    />
                                                </div>
                                                {toggleFilters && (
                                                    <div className="pl-1">
                                                        {column.canFilter ? column.render('Filter') : null}
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="overflow-y-hidden pb-[40px] bg-gray-600">
                                {!loading && !error && data.length === 0 && (
                                    <tr className="text-gray-400 py-1">
                                        <td colSpan={columns.length} className="px-2 py-4 text-center">
                                            {data.length === 0 ? (
                                                <div className="space-y-2">
                                                    <div>No actors</div>
                                                    <div>Please connect a Provider to discover actors</div>
                                                </div>
                                            ) : (
                                                'No matching actors'
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {error && data.length === 0 && (
                                    <tr className="py-1 text-gray-400">
                                        <td
                                            colSpan={columns.length}
                                            className="px-2 py-2 flex items-center justify-center"
                                        >
                                            <ExclamationTriangleIcon className="h-8 w-8 pr-2" />
                                            There was an issue loading actors, please try again later.
                                        </td>
                                    </tr>
                                )}
                                {rows.length === 0 && data.length > 0 && (
                                    <tr className="py-1 text-gray-400">
                                        <td
                                            colSpan={columns.length}
                                            className="px-2 py-2 flex items-center justify-center"
                                        >
                                            There are no matching actors
                                        </td>
                                    </tr>
                                )}
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <Fragment key={row.id}>
                                            <tr {...row.getRowProps()} className="text-gray-300 hover:bg-gray-900">
                                                {row.cells.map((cell, idx) => {
                                                    if (!actorPagesExhausted) {
                                                        return <Skeleton cell={cell} key={idx} />;
                                                    }

                                                    switch (cell.column.id) {
                                                        case 'tags':
                                                            return (
                                                                <td {...cell.getCellProps()} className="py-1 px-2">
                                                                    <div className="flex w-full h-full items-center justify-start space-x-0.5">
                                                                        {cell.value.map((tag: string, idx: number) => {
                                                                            return (
                                                                                <span
                                                                                    key={idx}
                                                                                    className="px-1 py-0.5 bg-gray-700 text-xs rounded-md"
                                                                                >
                                                                                    {tagNameLookup(tag)}
                                                                                </span>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </td>
                                                            );
                                                        case 'trustProfiles':
                                                            return (
                                                                <td {...cell.getCellProps()} className="py-1 px-2">
                                                                    <div className="flex w-full h-full items-center justify-start">
                                                                        {trustProfiles ? (
                                                                            <TrustProfileStreamlinedSwitch
                                                                                key={row.original.actor.nodeId}
                                                                                actor={row.original.actor}
                                                                                availableTrustProfiles={trustProfiles}
                                                                                attachedTrustProfiles={
                                                                                    trustProfileByActorId[
                                                                                        row.original.id
                                                                                    ]
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <>Loading</>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            );

                                                        default:
                                                            return <DefaultCell cell={cell} key={idx} />;
                                                    }
                                                })}
                                            </tr>
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                            {
                                <tfoot>
                                    <tr className="font-light">
                                        <th colSpan={columns.length} className="p-0">
                                            <div className="bg-gray-700 text-xs flex items-center p-2 justify-between">
                                                {true ? (
                                                    <>
                                                        {rows.length > 0 ? (
                                                            <div className="flex items-center">
                                                                <span className="ml-1">
                                                                    Showing {pageIndex * pageSize + 1} to{' '}
                                                                    {Math.min(
                                                                        pageIndex * pageSize + pageSize,
                                                                        rows.length,
                                                                    )}{' '}
                                                                    of {rows.length} records
                                                                </span>
                                                                {selectedFlatRows && selectedFlatRows.length > 0 && (
                                                                    <span className="ml-1">
                                                                        - {selectedFlatRows.length} selected
                                                                    </span>
                                                                )}{' '}
                                                                <span className="ml-1">
                                                                    - Page{' '}
                                                                    {pageOptions.length === 0 ? 0 : pageIndex + 1} of{' '}
                                                                    {pageOptions.length}
                                                                </span>
                                                                {loading && <span className="ml-2 h-4 w-4 loader" />}
                                                                {error && (
                                                                    <span className="ml-2 text-red-500">
                                                                        There was an issue loading results.
                                                                    </span>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="text-gray-400"></div>
                                                        )}

                                                        {pageCount > 1 ? (
                                                            <div className="flex items-center space-x-0.5">
                                                                <Tooltip label="Toggle Table Filters" placement="top">
                                                                    <button
                                                                        className="btn bg-gray-800 text-xs p-1"
                                                                        onClick={() => setToggleFilters(!toggleFilters)}
                                                                    >
                                                                        <FunnelIcon className="h-4 w-4" />
                                                                    </button>
                                                                </Tooltip>
                                                                <select
                                                                    value={pageSize}
                                                                    className="input-gray text-xxs px-5 py-0.5 rounded-none bg-gray-800 shadow-md border-0"
                                                                    onChange={
                                                                        // set the number of rows per page
                                                                        (e) => {
                                                                            setPageSize(Number(e.target.value));
                                                                        }
                                                                    }
                                                                >
                                                                    <option>16</option>
                                                                    <option>25</option>
                                                                    <option>50</option>
                                                                    <option>100</option>
                                                                    <option>250</option>
                                                                    <option>500</option>
                                                                </select>
                                                                <button
                                                                    className="btn bg-gray-800 text-xs p-1"
                                                                    onClick={() => gotoPage(0)}
                                                                    disabled={!canPreviousPage}
                                                                >
                                                                    <ChevronDoubleLeftIcon className="h-4 w-4" />
                                                                </button>
                                                                <button
                                                                    className="btn bg-gray-800 text-xs p-1"
                                                                    onClick={() => previousPage()}
                                                                    disabled={!canPreviousPage}
                                                                >
                                                                    <ChevronLeftIcon className="h-4 w-4" />
                                                                </button>
                                                                <button
                                                                    className="btn bg-gray-800 text-xs p-1"
                                                                    onClick={() => nextPage()}
                                                                    disabled={!canNextPage}
                                                                >
                                                                    <ChevronRightIcon className="h-4 w-4" />
                                                                </button>
                                                                <button
                                                                    className="btn bg-gray-800 text-xs p-1"
                                                                    onClick={() => gotoPage(pageCount - 1)}
                                                                    disabled={!canNextPage}
                                                                >
                                                                    <ChevronDoubleRightIcon className="h-4 w-4" />
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div className="h-5 w-5" />
                                                        )}
                                                    </>
                                                ) : (
                                                    <div className="h-5 w-5" />
                                                )}
                                            </div>
                                        </th>
                                    </tr>
                                </tfoot>
                            }
                        </table>
                    ) : (
                        <div className="text-xs space-y-2 bg-gray-700 p-4">
                            {dataActors && actorPagesExhausted && !actors && (
                                <div>No Actors Found - Please configure a Provider</div>
                            )}
                            {dataTrustProfiles && trustProfiles && trustProfiles?.length == 0 && (
                                <div>No Trust Profiles Found - Please contact support</div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

type TrustProfileStreamlinedSwitchProps = {
    actor: BackendNode;
    availableTrustProfiles: TrustProfile[];
    attachedTrustProfiles: TrustProfile[];
};

const TrustProfileStreamlinedSwitch = ({
    actor,
    availableTrustProfiles = [],
    attachedTrustProfiles = [],
}: TrustProfileStreamlinedSwitchProps): JSX.Element => {
    const tenantId = useTenant();

    const { userCan } = useUserPermissions();

    const canCreateProfiles = userCan('create', '/policy/profile');

    const [addActorsToPolicyProfile, { loading: loadingAdd, error: errorAdd }] =
        useMutation(ADD_ACTORS_TO_POLICY_PROFILE);
    const [removeActorsFromPolicyProfile, { loading: loadingRemove, error: errorRemove }] = useMutation(
        REMOVE_ACTORS_FROM_POLICY_PROFILE,
    );

    const loading = useDebounce(loadingAdd || loadingRemove, 300);
    const error = errorAdd || errorRemove;

    const disableSearchToggle = !canCreateProfiles;

    const profileAttached = useMemo(() => {
        if (attachedTrustProfiles.length > 0 && availableTrustProfiles.length > 0) {
            if (attachedTrustProfiles[0] === availableTrustProfiles[0]) {
                return true;
            }
        }
        return false;
    }, [attachedTrustProfiles, availableTrustProfiles]);

    const tooManyAttachedProfiles = attachedTrustProfiles.length > 1;

    const toggleTrustProfile = async (enabled: boolean) => {
        console.log('Toggle Trust Profile:', enabled);

        const profileId = availableTrustProfiles[0]?.profileId;
        const actorId = actor.nodeId;

        if (enabled) {
            await addActorsToPolicyProfile({
                variables: {
                    tenantId: tenantId || '',
                    policyProfileId: profileId,
                    actorIds: [actorId],
                },
                refetchQueries: ['listPolicyProfiles'],
                awaitRefetchQueries: true,
            });
        } else {
            await removeActorsFromPolicyProfile({
                variables: {
                    tenantId: tenantId || '',
                    policyProfileId: profileId,
                    actorIds: [actorId],
                },
                refetchQueries: ['listPolicyProfiles'],
                awaitRefetchQueries: true,
            });
        }
    };

    if (tooManyAttachedProfiles) {
        return <div>Warning: too many profiles to manage</div>;
    }

    return (
        <Tooltip
            label={
                disableSearchToggle
                    ? 'You do not have permission to toggle Trust calculation for this Actor'
                    : 'Toggle Trust calculation for this Actor'
            }
        >
            <div className="flex items-center space-x-1">
                <div className="bg-gray-700 rounded-full">
                    <Switch.Group
                        as="div"
                        className={classNames('flex items-center', disableSearchToggle ? 'opacity-50' : '')}
                    >
                        <Switch
                            disabled={disableSearchToggle}
                            checked={profileAttached}
                            onChange={toggleTrustProfile}
                            className={classNames(
                                profileAttached ? 'bg-blue-800' : 'bg-gray-700',
                                'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0',
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    profileAttached ? 'translate-x-6' : 'translate-x-0',
                                    'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-gray-300 shadow ring-0 transition duration-200 ease-in-out',
                                )}
                            />
                        </Switch>
                    </Switch.Group>
                </div>
                {loading && <span className="loader h-4 w-4" />}
                {error && <span className="text-red-500">Error changing trust status</span>}
            </div>
        </Tooltip>
    );
};

type TrustProfileMappingSelectorProps = {
    actor: BackendNode;
    availableTrustProfiles: TrustProfile[];
    attachedTrustProfiles: TrustProfile[];
};

export const TrustProfileMappingSelector = ({
    actor,
    availableTrustProfiles = [],
    attachedTrustProfiles = [],
}: TrustProfileMappingSelectorProps): JSX.Element => {
    const tenantId = useTenant();

    const { userCan } = useUserPermissions();

    const canCreateProfiles = userCan('create', '/policy/profile');

    const [addActorsToPolicyProfile] = useMutation(ADD_ACTORS_TO_POLICY_PROFILE);
    const [removeActorsFromPolicyProfile] = useMutation(REMOVE_ACTORS_FROM_POLICY_PROFILE);

    const options = useMemo(() => {
        return availableTrustProfiles.map((profile) => {
            return {
                value: profile.profileId,
                label: profile.displayName,
            };
        });
    }, [availableTrustProfiles]);

    const defaultValue = useMemo(() => {
        return attachedTrustProfiles.map((profile) => {
            return {
                value: profile.profileId,
                label: profile.displayName,
            };
        });
    }, [attachedTrustProfiles]);

    const addActorToProfile = async (actorId: string, profileId?: string) => {
        if (profileId) {
            await addActorsToPolicyProfile({
                variables: {
                    tenantId: tenantId || '',
                    policyProfileId: profileId,
                    actorIds: [actorId],
                },
                refetchQueries: ['listPolicyProfiles'],
            });
        }
    };

    const removeActorFromProfile = async (actorId: string, profileId?: string) => {
        if (profileId) {
            await removeActorsFromPolicyProfile({
                variables: {
                    tenantId: tenantId || '',
                    policyProfileId: profileId,
                    actorIds: [actorId],
                },
                refetchQueries: ['listPolicyProfiles'],
            });
        }
    };

    return (
        <Select
            isDisabled={!canCreateProfiles}
            isMulti={true}
            classNamePrefix="select-xs"
            className="w-full"
            isSearchable={false}
            name="dataType"
            options={options}
            onChange={(incomingValues) => {
                // Default Value is a list of Trust Profiles that are currently attached to the Actor
                // incomingValues is a list of Trust Profiles that are currently selected in the dropdown
                // We need to compare the two to determine which Trust Profiles need to be added or removed

                // Because the values are objects, we need to compare the value (id) property
                const previousIds = defaultValue?.map((profile) => {
                    return profile.value;
                });

                const incomingIds = incomingValues?.map((profile) => {
                    return profile.value;
                });

                const added = incomingIds?.filter((id) => {
                    return !previousIds.includes(id);
                });

                const removed = previousIds?.filter((previousId) => {
                    return !incomingIds.includes(previousId);
                });

                console.log('added:', added);
                console.log('removed:', removed);

                if (added.length > 0) {
                    added.map((profileId) => {
                        addActorToProfile(actor.nodeId, profileId);
                    });
                }

                if (removed.length > 0) {
                    removed.map((profileId) => {
                        removeActorFromProfile(actor.nodeId, profileId);
                    });
                }
            }}
            defaultValue={defaultValue}
        />
    );
};
