import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext } from 'react';
import { groupingForceApplied } from 'Utilities/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FunnelIcon, SquaresPlusIcon } from '@heroicons/react/24/outline';
import { useProductTutorial } from 'Hooks/Hooks';
import { SquaresPlusIcon as SquaresPlusIconSolid, FunnelIcon as FunnelIconSolid } from '@heroicons/react/24/solid';

export const Refinements = (): JSX.Element => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { runOnTutorialStep, moveToNextTutorialStep } = useProductTutorial();
    const { filterOpen, groupingOpen } = mapState;

    const { enableFiltering } = useFlags();

    const toggleFilters = () => {
        dispatch({ type: 'toggle-filter' });
    };

    const toggleGrouping = () => {
        dispatch({ type: 'toggle-grouping' });
    };

    const filterEnabled = mapState.graphFilters && mapState.graphFilters.length != 0;
    const groupingEnabled = groupingForceApplied(mapState.group);

    return (
        <div className="flex flex-col-reverse space-y-3 pointer-events-none" id="map-controls-widget">
            {enableFiltering && (
                <button
                    id="FilterPanel"
                    type="button"
                    className="h-20 w-20 flex items-center justify-center rounded-md bg-gray-800 border border-gray-600 hover:border-white mt-3 focus:ring-0 focus:outline-none pointer-events-auto relative"
                    onClick={() => {
                        toggleFilters();

                        runOnTutorialStep(['Refine Panel Open', 'Reopen Panel'], () => {
                            moveToNextTutorialStep();
                        });
                    }}
                >
                    <div>
                        {filterOpen ? (
                            <FunnelIconSolid className="h-6 w-6 my-2 text-blue-500 mx-auto" />
                        ) : (
                            <FunnelIcon className="h-6 w-6 my-2 text-gray-400 mx-auto" />
                        )}
                        <span className="mt-2 block text-xs text-gray-400">Filters</span>
                    </div>
                    {filterEnabled ? (
                        <div className="h-2.5 w-2.5 rounded-full bg-blue-500 absolute top-2 left-2 flex justify-center items-center">
                            <div className="animate-ping-delayed h-2.5 w-2.5 rounded-full bg-blue-500" />
                        </div>
                    ) : (
                        ''
                    )}
                </button>
            )}
            <button
                id="GroupingPanel"
                type="button"
                className="h-20 w-20 flex items-center justify-center rounded-md bg-gray-800 border border-gray-600 hover:border-white mt-3 focus:ring-0 focus:outline-none pointer-events-auto relative"
                onClick={() => {
                    toggleGrouping();
                }}
            >
                <div>
                    {groupingOpen ? (
                        <SquaresPlusIconSolid className="h-6 w-6 my-2 text-orange-500 mx-auto" />
                    ) : (
                        <SquaresPlusIcon className="h-6 w-6 my-2 text-gray-400 mx-auto" />
                    )}
                    <span className="mt-2 block text-xs text-gray-400">Grouping</span>
                    {groupingEnabled ? (
                        <div className="h-2.5 w-2.5 rounded-full bg-orange-500 absolute top-2 left-2 flex justify-center items-center">
                            <div className="animate-ping-delayed h-2.5 w-2.5 rounded-full bg-orange-500" />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </button>
        </div>
    );
};
