import { useMemo, useState, useContext, useEffect } from 'react';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DELETE_AGENT, LIST_AGENTS } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';

import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ArrowTopRightOnSquareIcon,
    FunnelIcon,
    MagnifyingGlassIcon,
    ChevronUpDownIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid';
import {
    AggregatedValue,
    Column,
    FilterProps,
    FilterValue,
    IdType,
    Row,
    useFilters,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import {
    classNames,
    deviceTypeNameLookup,
    deviceTypes,
    operatingSystemNameLookup,
    providerNameLookup,
} from 'Utilities/utils';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';

export type Agent = {
    agentId: string;
    ipAddress: string;
    arch: string;
    hostname: string;
    deviceType: string;
    os: string;
    status: 'connected' | 'disconnected' | 'error' | 'unknown';
    lastSeen: number;
    lastLocation: {
        latitude: number;
        longitude: number;
        latlong?: string[];
    };
    seenBy: string[];
};

const TEST_LOCATIONS = [
    {
        latitude: 37.7749,
        longitude: -122.4194,
        latlong: ['37.7749,-122.4194', 'San Francisco, CA'],
    },
    { latitude: 40.7128, longitude: -74.006, latlong: ['40.7128,-74.0060', 'New York, NY'] },
    { latitude: 51.5074, longitude: -0.1278, latlong: ['51.5074,-0.1278', 'London, UK'] },
    { latitude: 48.8566, longitude: 2.3522, latlong: ['48.8566,2.3522', 'Paris, France'] },
    { latitude: 35.6895, longitude: 139.6917, latlong: ['35.6895,139.6917', 'Tokyo, Japan'] },
    { latitude: -33.8688, longitude: 151.2093, latlong: ['-33.8688,151.2093', 'Sydney, Australia'] },
    { latitude: 55.7558, longitude: 37.6173, latlong: ['55.7558,37.6173', 'Moscow, Russia'] },
    { latitude: -23.5505, longitude: -46.6333, latlong: ['-23.5505,-46.6333', 'São Paulo, Brazil'] },
    { latitude: 39.9042, longitude: 116.4074, latlong: ['39.9042,116.4074', 'Beijing, China'] },
    { latitude: 28.6139, longitude: 77.209, latlong: ['28.6139,77.2090', 'New Delhi, India'] },
];

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }: FilterProps<Agent>) {
    return (
        <div className="relative inline-block">
            <input
                type="text"
                value={filterValue || ''}
                className="input-gray text-xs rounded-none bg-gray-700 pr-5 pl-1.5 py-1 mb-2 relative -left-1.5"
                placeholder={`Search...`}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}
            />
            {filterValue == null ? (
                <MagnifyingGlassIcon className="h-3.5 w-3.5 text-gray-400 absolute top-1.5 right-3" />
            ) : (
                <XMarkIcon
                    className="h-3.5 w-3.5 text-gray-400 absolute top-1.5 right-3 cursor-pointer"
                    onClick={() => {
                        setFilter(null);
                    }}
                />
            )}
        </div>
    );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter } }: FilterProps<Agent>) {
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            className="input-gray text-xs rounded-none bg-gray-700 pr-7 pl-1.5 py-1 mb-2 relative -left-1.5 w-32"
        >
            <option value="">All</option>
            <option value="Connected">Connected</option>
            <option value="Unauthenticated">Unauthenticated</option>
            <option value="Disconnected">Disconnected</option>
            <option value="Error">Error</option>
        </select>
    );
}

function fuzzyTextFilterFn<Agent extends object>(
    rows: Array<Row<Agent>>,
    columnIds: Array<IdType<Agent>>,
    filterValue: FilterValue,
) {
    return matchSorter<Row<Agent>>(rows, filterValue, {
        keys: [(row: Row<Agent>) => row.values[columnIds[0]]],
    });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: unknown) => !val;

interface Table<T extends object> {
    columns: Array<Column<T>>;
    data: T[];
    updateMyData?: unknown;
    skipPageReset?: boolean | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}

function Table({ columns, data, updateMyData, skipPageReset, loading }: Table<Agent>) {
    const [toggleFilters, setToggleFilters] = useState(false);

    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows: Array<Row<Agent>>, ids: Array<IdType<Agent>>, filterValue: FilterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[ids[0]];
                    return rowValue !== undefined
                        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        [],
    );
    const tenantId = useTenant();
    const client = useApolloClient();
    const [deleteAgent] = useMutation(DELETE_AGENT);
    const [errorDeletingAgent, setErrorDeletingAgent] = useState('');
    const [terminatingAgents, setTerminatingAgents] = useState(new Set<string>());

    const deleteThisAgent = (agent: Agent) => {
        const agentId = agent.agentId;
        deleteAgent({
            variables: { tenantId, agentId },
            onCompleted: () => {
                terminatingAgents.delete(agentId);
                setTerminatingAgents(terminatingAgents);
                const agents = client.readQuery({ query: LIST_AGENTS, variables: { tenantId } });
                const newAgents = agents.listAgents.filter((agent: Agent) => agent.agentId !== agentId);
                client.writeQuery({
                    query: LIST_AGENTS,
                    variables: { tenantId },
                    data: { listAgents: newAgents },
                });
            },
            onError: () => {
                setErrorDeletingAgent('Error deleting agent');
            },
        });
    };

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        [],
    );
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable<Agent>(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            updateMyData,
            autoResetPage: !skipPageReset,
            autoResetSelectedRows: !skipPageReset,
            disableMultiSort: true,
            initialState: { pageIndex: 0, pageSize: 40 },
        },
        useFilters,
        useSortBy,
        usePagination,
        useFilters,
    );

    let content = <></>;

    if (loading) {
        content = <h4 className="text-xs text-gray-300">Loading Virtual Agents...</h4>;
        // } else if (error) {
        //     content = <h4 className="text-xs text-red-500">Could not load agents. Please retry later.</h4>;
    } else {
        // Render the UI for your table
        content = (
            <div className="-mt-2">
                {errorDeletingAgent && (
                    <h4 className="text-xs text-red-500">Could not delete agent. Please retry later.</h4>
                )}
                <table {...getTableProps()} className="w-full text-left text-xs mb-0">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-600">
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="pb-1 px-2 w-1/8 align-middle whitespace-nowrap"
                                    >
                                        <div className="flex items-center mb-2" {...column.getSortByToggleProps()}>
                                            {column.render('Header')}
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <ChevronDownIcon className="h-4 w-4 ml-1" />
                                                ) : (
                                                    <ChevronUpIcon className="h-4 w-4 ml-1" />
                                                )
                                            ) : (
                                                <ChevronUpDownIcon className="h-4 w-4 ml-1" />
                                            )}
                                        </div>
                                        {toggleFilters && (
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        )}
                                    </th>
                                ))}
                                <th className="pb-1 px-2 w-1/8 align-top">Actions</th>
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="text-gray-300">
                                    {row.cells.map((cell) => {
                                        return cell.column.id == 'status' ||
                                            cell.column.id == 'seenByIdp' ||
                                            cell.column.id == 'seenByMdm' ||
                                            cell.column.id == 'seenByXdr' ||
                                            cell.column.id == 'seenByTarget' ? (
                                            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                <span
                                                    className={classNames(
                                                        cell.value == 'connected'
                                                            ? 'bg-green-400'
                                                            : cell.value == 'active'
                                                              ? 'bg-green-400'
                                                              : cell.value == 'inactive'
                                                                ? 'bg-yellow-400'
                                                                : cell.value == 'stale'
                                                                  ? 'bg-yellow-400'
                                                                  : cell.value == 'never'
                                                                    ? 'bg-red-400'
                                                                    : cell.value == 'disconnected'
                                                                      ? 'bg-yellow-400'
                                                                      : cell.value == 'error'
                                                                        ? 'bg-red-400'
                                                                        : 'bg-gray-400',
                                                        'h-2 w-2 rounded-full inline-block mr-1',
                                                    )}
                                                ></span>
                                                {cell.render('Cell')}
                                            </td>
                                        ) : cell.column.id == 'lastLocation.latlong' ? (
                                            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                <a
                                                    target="_blank"
                                                    href={`http://maps.google.com/maps?q=loc:${cell.value[0]}`}
                                                    className="font-medium text-blue-400 hover:text-blue-500 flex items-center"
                                                >
                                                    {cell.value[1]}
                                                    <ArrowTopRightOnSquareIcon className="h-4 w-4 relative ml-2 bottom-[1px]" />
                                                </a>
                                            </td>
                                        ) : cell.column.id == 'lastSeen' ? (
                                            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                {new Date(cell.value).toLocaleString()}
                                            </td>
                                        ) : cell.column.id == 'seenBy' ? (
                                            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                {cell.value.map((seenBy: string) => (
                                                    <span
                                                        key={seenBy}
                                                        className="mr-1 p-1 bg-gray-700 text-gray-200 rounded-sm"
                                                    >
                                                        {providerNameLookup(seenBy)}
                                                    </span>
                                                ))}
                                            </td>
                                        ) : (
                                            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                    <td className="px-2 w-32">
                                        <button
                                            type="button"
                                            className={classNames(
                                                'text-gray-300 cursor-pointer',
                                                terminatingAgents.has(row.original.agentId) ? 'opacity-50' : '',
                                            )}
                                            disabled={terminatingAgents.has(row.original.agentId)}
                                            onClick={() => {
                                                setTerminatingAgents(terminatingAgents.add(row.original.agentId));
                                                deleteThisAgent(row.original);
                                            }}
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div className="pagination bg-gray-800 text-xs flex items-center justify-between border-t border-gray-600 w-full pt-4">
                    <div className="w-28">
                        <button
                            type="button"
                            className="btn text-xs p-1.5"
                            onClick={() => {
                                setToggleFilters(!toggleFilters);
                            }}
                        >
                            <FunnelIcon className="h-4 w-4 mr-1" /> {toggleFilters ? 'Hide Filters' : 'Show Filters'}
                        </button>
                    </div>

                    <div>
                        Page
                        <strong className="ml-1">
                            {pageOptions.length === 0 ? 0 : pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </div>

                    <div className="flex items-center space-x-0.5">
                        <button className="btn text-xs p-1.5" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <ChevronDoubleLeftIcon className="h-4 w-4" />
                        </button>
                        <button
                            className="btn text-xs p-1.5"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <ChevronLeftIcon className="h-4 w-4" />
                        </button>
                        <button className="btn text-xs p-1.5" onClick={() => nextPage()} disabled={!canNextPage}>
                            <ChevronRightIcon className="h-4 w-4" />
                        </button>
                        <button
                            className="btn text-xs p-1.5"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            <ChevronDoubleRightIcon className="h-4 w-4" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    return content;
}

// const now = Date.now();

function AgentsList(updateMyData: unknown, skipPageReset: boolean | undefined) {
    // const { enableGeoLocate } = useFlags();
    // const tenantId = useTenant();

    // const { loading, data } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
    //     variables: {
    //         tenantId,
    //         entityType: 'STATS_ENTITY_TYPE_DEVICE',
    //         dateInMs: +now,
    //     },
    // });

    // const { getCityAndState } = useGeocode();

    const [agentList, setAgentList] = useState([] as Agent[]);

    const transformOS = (agentOS: string) => {
        return operatingSystemNameLookup(agentOS);
    };

    const transformType = (type: string) => {
        return deviceTypeNameLookup(type as keyof deviceTypes);
    };

    const columns: Column<Agent>[] = useMemo(
        () =>
            [
                {
                    Header: 'Host Name',
                    accessor: 'hostname',
                    filter: 'fuzzyText',
                    aggregate: 'count',
                    Aggregated: ({ value }: AggregatedValue) => `${value} Host Names`,
                },
                {
                    Header: 'IP Address',
                    accessor: 'ipAddress',
                    filter: 'fuzzyText',
                    aggregate: 'count',
                    Aggregated: ({ value }: AggregatedValue) => `${value} IP Addresses`,
                },

                {
                    Header: 'Device Type',
                    accessor: 'deviceType',
                    filter: 'fuzzyText',
                    aggregate: 'count',
                    Aggregated: ({ value }: AggregatedValue) => `${value} Device Types`,
                },
                {
                    Header: 'OS',
                    accessor: 'os',
                    filter: 'fuzzyText',
                    aggregate: 'count',
                    Aggregated: ({ value }: AggregatedValue) => `${value} Operating Systems`,
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    Filter: SelectColumnFilter,
                    filter: 'includes',
                },
                {
                    Header: 'Last Seen',
                    accessor: 'lastSeen',
                    filter: 'fuzzyText',
                    aggregate: 'count',
                    Aggregated: ({ value }: AggregatedValue) => `${value} Last Seen Times`,
                },
                {
                    Header: 'Location',
                    accessor: 'lastLocation.latlong',
                    filter: 'fuzzyText',
                    aggregate: 'count',
                    Aggregated: ({ value }: AggregatedValue) => `${value} LatLong`,
                },
                {
                    Header: 'Seen By',
                    accessor: 'seenBy',
                    Filter: SelectColumnFilter,
                    filter: 'includes',
                },
            ] as unknown as Column<Agent>[],
        [],
    );

    useEffect(() => {
        const agents = Array.from({ length: 260 }, () => {
            return {
                agentId: faker.string.uuid(),
                ipAddress: faker.internet.ip(),
                arch: faker.helpers.arrayElement(['x86', 'x86_64', 'arm', 'arm64']),
                hostname: faker.internet.domainWord(),
                deviceType: transformType(
                    faker.helpers.arrayElement(['DEVICE_MOBILE', 'DEVICE_COMPUTER', 'DEVICE_TABLET', 'DEVICE_LAPTOP']),
                ),
                os: transformOS(
                    faker.helpers.arrayElement(['OS_WINDOWS', 'OS_MACOS', 'OS_LINUX', 'OS_IOS', 'OS_ANDROID']),
                ),
                status: faker.helpers.weightedArrayElement([
                    { weight: 7, value: 'connected' },
                    { weight: 2, value: 'disconnected' },
                    { weight: 1, value: 'error' },
                ]),
                lastSeen: faker.date.recent({ days: 30 }).getTime(),
                lastLocation: faker.helpers.arrayElement(TEST_LOCATIONS),
                seenBy: [
                    'okta',
                    'azuread',
                    ...faker.helpers.arrayElements(['office365', 'box', 'sentinelone', 'jamf', 'aws', 'github']),
                ],
            };
        }) as Agent[];

        console.log(agents);

        setAgentList(agents);
    }, []);

    return (
        <Table
            columns={columns}
            data={agentList}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            loading={false}
            error={undefined}
        />
    );
}

export const VirtualAgentsModal = (): JSX.Element => {
    const { dispatch } = useContext(IdentityMapContext);
    const closeAgents = () => dispatch({ type: 'toggle-agents' });
    const { agentsPanel } = useFlags();

    return (
        agentsPanel && (
            <div className="fixed bottom-0 z-10 inset-0 overflow-y-auto top-[56px] h-[calc(100vh-56px)]">
                <Helmet>
                    <title>Virtual Agents</title>
                </Helmet>
                <div className="flex items-end justify-center text-center sm:block sm:p-0">
                    <div
                        className="fixed top-[56px] h-[calc(100vh-56px)] w-[100vw] after:inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                        onClick={closeAgents}
                    />

                    <div
                        id="VirtualAgents"
                        className="absolute left-[50%] translate-x-[-50%] inline-block bg-gray-800 rounded-lg text-left shadow-xl transform transition-all w-[90vw] xxl:w-[75vw] mt-14 align-top md:mt-5"
                    >
                        <div id="Header" className="flex flex-col p-4 pb-2">
                            <h2 className="font-bold text-md">Virtual Agent List</h2>
                            <p className="text-xs text-gray-500">Manage the sources of your data collection</p>
                        </div>
                        <div className="p-4 text-xs overflow-y-auto max-h-[calc(100vh-200px)] rounded-b-lg">
                            <AgentsList />
                        </div>
                        <button
                            type="button"
                            className="text-white text-xs rounded-full p-1 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md"
                            onClick={closeAgents}
                        >
                            <XMarkIcon className="h-4 w-4 text-gray-200" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};
