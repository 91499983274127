import { useQuery } from '@apollo/client';
import { GET_AUTH_FIELDS_PROVIDER, GET_PROVIDER_FIELD_MAP, UPDATE_PROVIDER } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { DynamicProvider, Field } from 'Types/types';
import { CREATE_PROVIDER, LIST_PROVIDERS } from 'Graph/queries';
import { ProviderAuthResponse } from 'Configuration/Providers/ProviderAuthResponse';
import { useMutation } from '@apollo/client';
import { useState, useContext, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ToastContext } from 'Map/Components/ToastContext';
import { getIconSourceURL, getProviderIconElement } from 'Map/Graph/Icons';
import Select from 'react-select';
import { Tooltip } from 'Library/Tooltip';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'Utilities/utils';

const futureProviders = [
    {
        value: 'aws',
        label: 'Amazon Web Services',
    },
    {
        value: 'box',
        label: 'Box',
    },
    {
        value: 'jamf',
        label: 'Jamf Pro',
    },
    {
        value: 'workday',
        label: 'Workday',
    },
    {
        value: 'github',
        label: 'GitHub',
    },
    {
        value: 'ad',
        label: 'Active Directory',
    },
    {
        value: 'auth0',
        label: 'Auth0',
    },
    {
        value: 'onelogin',
        label: 'One Login',
    },
    {
        value: 'crowdstrike',
        label: 'CrowdStrike',
    },
    // {
    //     value: 'sailpoint',
    //     label: 'SailPoint',
    // },
];

const getIcon = (provider: string) => {
    return getIconSourceURL(getProviderIconElement(provider));
};

export const AvailableDynamicProviders = ({
    handleClick,
}: {
    handleClick: (provider: DynamicProvider) => void;
}): JSX.Element => {
    const tenantId = useTenant();
    const { loading, error, data } = useQuery(GET_PROVIDER_FIELD_MAP, { variables: { tenantId } });
    const { availableProviders, settingsShowFutureProvidersAsAvailable } = useFlags();

    let content = <></>;

    if (loading) {
        content = (
            <h4 className="text-xs text-gray-300 flex">
                <div className="loader mr-2" />
                Loading Providers...
            </h4>
        );
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not load providers. Please retry later.</h4>;
    } else if (data) {
        const providers: DynamicProvider[] = data.getProviderFieldMap;

        // filter out providers that are not available using the Launch Darkly flag
        // if the flag is not set, then show all providers
        let filteredProviders: DynamicProvider[] = [];
        if (availableProviders) {
            filteredProviders = providers.filter((provider) => availableProviders.includes(provider.Name));
        } else {
            filteredProviders = providers;
        }

        // sort the providers alphabetically
        const sortedProviders = filteredProviders.slice().sort((a, b) => a.Name.localeCompare(b.Name));

        // filter out providers that are already available
        const availableProviderNames = sortedProviders.map((provider) => provider.Name);

        const filteredFutureProviders = futureProviders.filter(
            (provider) => !availableProviderNames.includes(provider.value),
        );

        content = (
            <>
                {sortedProviders.map((provider) => {
                    return (
                        <button
                            className="btn w-full capitalize flex flex-col justify-center items-center rounded-md shadow-md hover:bg-gray-600 hover:shadow-xl hover:-translate-y-1 active:scale-95 active:shadow-none active:bg-gray-700 active:translate-y-0.5 transition-all"
                            key={provider.Name}
                            onClick={() => handleClick(provider)}
                            data-test={`add-provider-${provider.Name}`}
                        >
                            <img className="h-8 w-8 mb-2" src={getIcon(provider.DisplayName)} />
                            {provider.DisplayName}
                        </button>
                    );
                })}
                {filteredFutureProviders.map((provider) =>
                    settingsShowFutureProvidersAsAvailable ? (
                        <button className="btn w-full capitalize flex flex-col justify-center items-center hover:bg-gray-700 rounded-md focus-none cursor-default">
                            <img className="h-8 w-8 mb-2" src={getIcon(provider.label)} />
                            {provider.label}
                        </button>
                    ) : (
                        <Tooltip label="Contact support to enable this provider" key={provider.value}>
                            <button className="btn w-full capitalize flex flex-col justify-center items-center opacity-30 hover:bg-gray-700 rounded-md focus-none cursor-default">
                                <img className="h-8 w-8 mb-2" src={getIcon(provider.label)} />
                                {provider.label}
                            </button>
                        </Tooltip>
                    ),
                )}
            </>
        );
    }

    return content;
};

interface NewDynamicProviderProps {
    provider: DynamicProvider;
    onClose: () => void;
}

type ProviderInput = {
    tenantId?: string | null;
    name?: string;
    type?: string;
    AuthField_1?: string;
    AuthField_2?: string;
    AuthField_3?: string;
    AuthField_4?: string;
    AuthField_5?: string;
    AuthField_6?: string;
    AuthField_7?: string;
};

const sanitizeProviderInput = (input: ProviderInput): ProviderInput => {
    // Take the provider input and show only the first few characters of the auth fields

    const sanitizedInput = { ...input };

    if (sanitizedInput.AuthField_1) {
        sanitizedInput.AuthField_1 =
            sanitizedInput.AuthField_1.length > 0 ? `${sanitizedInput.AuthField_1.slice(0, 6)}*****` : '';
    }
    if (sanitizedInput.AuthField_2) {
        sanitizedInput.AuthField_2 =
            sanitizedInput.AuthField_2.length > 0 ? `${sanitizedInput.AuthField_2.slice(0, 6)}*****` : '';
    }
    if (sanitizedInput.AuthField_3) {
        sanitizedInput.AuthField_3 =
            sanitizedInput.AuthField_3.length > 0 ? `${sanitizedInput.AuthField_3.slice(0, 6)}*****` : '';
    }
    if (sanitizedInput.AuthField_4) {
        sanitizedInput.AuthField_4 =
            sanitizedInput.AuthField_4.length > 0 ? `${sanitizedInput.AuthField_4.slice(0, 6)}*****` : '';
    }
    if (sanitizedInput.AuthField_5) {
        sanitizedInput.AuthField_5 =
            sanitizedInput.AuthField_5.length > 0 ? `${sanitizedInput.AuthField_5.slice(0, 6)}*****` : '';
    }
    if (sanitizedInput.AuthField_6) {
        sanitizedInput.AuthField_6 =
            sanitizedInput.AuthField_6.length > 0 ? `${sanitizedInput.AuthField_6.slice(0, 6)}*****` : '';
    }
    if (sanitizedInput.AuthField_7) {
        sanitizedInput.AuthField_7 =
            sanitizedInput.AuthField_7.length > 0 ? `${sanitizedInput.AuthField_7.slice(0, 6)}*****` : '';
    }

    return sanitizedInput;
};

export const NewDynamicProvider = ({ provider, onClose }: NewDynamicProviderProps): JSX.Element => {
    const [formData, setFormData] = useState<ProviderInput>({} as ProviderInput);
    const { dispatch } = useContext(ToastContext);

    const handleForm = (e: React.FormEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };
    const submitForm = async (e: React.FormEvent, formData: ProviderInput) => {
        e.preventDefault();
        try {
            // Due to a limitation of graphql-codegen,
            // we need to map the AuthField_* to authField* input variables
            // This is a workaround for now, until we can get a fix for graphql-codegen
            console.log('Creating provider with input: ', sanitizeProviderInput(formData));
            const f = formData;
            const input = {
                tenantId,
                name: f.name,
                type: provider.Name,
                authField1: f.AuthField_1,
                authField2: f.AuthField_2,
                authField3: f.AuthField_3,
                authField4: f.AuthField_4,
                authField5: f.AuthField_5,
                authField6: f.AuthField_6,
                authField7: f.AuthField_7,
            };
            await createProvider({ variables: { input: input } });
            dispatch({
                type: 'add-toast',
                message: `Added provider ${f.name}`,
                status: 'success',
                autoTimeout: true,
                timeoutTimer: 10,
            });
        } catch (e) {
            console.log(e);
            dispatch({
                type: 'add-toast',
                message: `Failed creating provider ${formData.name}`,
                status: 'failure',
                autoTimeout: false,
            });
        }
    };

    const tenantId = useTenant();

    const [createProvider, { loading, data, error }] = useMutation(CREATE_PROVIDER, {
        refetchQueries: [{ query: LIST_PROVIDERS, variables: { tenantId } }],
    });

    if (provider) {
        const sortedFields = provider.Fields.slice().sort((a, b) => a.InputName.localeCompare(b.InputName));
        if (error) {
            return (
                <h4 className="text-red-500 font-medium mb-4">
                    There was an issue creating your new provider. <br />
                    <br />
                    Please retry. If this persists, please contact support.
                </h4>
            );
        }
        return (
            <>
                {data ? (
                    <>
                        {provider.Hook ? (
                            <>
                                <ProviderAuthResponse
                                    authData={{
                                        provider: data.createProvider.provider,
                                        authHeader: data.createProvider.authHeader,
                                        url: data.createProvider.url,
                                    }}
                                />

                                <button className="btn btn-primary mt-4" onClick={onClose}>
                                    Finished
                                </button>
                            </>
                        ) : (
                            <>
                                <span className="text-green-500 font-semibold">Success!</span> Your new integration was
                                created.
                                <button className="btn mt-4" onClick={onClose} data-test="return">
                                    Return
                                </button>
                            </>
                        )}
                    </>
                ) : (
                    <form onSubmit={(e) => submitForm(e, formData)}>
                        <div>
                            <p className="mt-2">
                                Setting up a new integration with{' '}
                                <span className="capitalize">{provider.DisplayName || provider.Name}</span>
                                {provider.HelpURI && provider.HelpURI !== '' && (
                                    <span className="ml-1">
                                        -
                                        <a className="ml-1 hover:text-blue-600" href={provider.HelpURI} target="_blank">
                                            view configuration guide
                                        </a>
                                    </span>
                                )}
                            </p>

                            <p className="mt-4 mb-2">
                                Please fill in the following fields to create a new integration:
                            </p>

                            <div className="space-y-4 ml-4 py-2">
                                <input
                                    data-test="provider-name"
                                    type="text"
                                    placeholder={`Provider Name, e.g. ${provider.DisplayName}`}
                                    name="name"
                                    className="input-gray text-xs w-2/3 rounded-none"
                                    onChange={handleForm}
                                    required={true}
                                    data-1p-ignore
                                />
                                {sortedFields.map((field) => {
                                    if (!field.Generated && !field.Output) {
                                        if (field.Enum) {
                                            return (
                                                <Select
                                                    data-test={field.Name}
                                                    key={field.InputName}
                                                    getOptionLabel={(option) => {
                                                        return option.value;
                                                    }}
                                                    getOptionValue={(option) => {
                                                        return option.key;
                                                    }}
                                                    closeMenuOnSelect={true}
                                                    options={[
                                                        ...field.Enum.slice().sort((a, b) =>
                                                            a.value.localeCompare(b.value),
                                                        ),
                                                    ]}
                                                    classNamePrefix="multiselect-sm"
                                                    className="w-2/3"
                                                    isClearable={false}
                                                    placeholder={`Select ${field.DisplayName}...`}
                                                    menuPlacement="top"
                                                    autoFocus={false}
                                                    onChange={(choice) => {
                                                        setFormData({
                                                            ...formData,
                                                            [field.InputName]: choice?.key,
                                                        });
                                                    }}
                                                />
                                            );
                                        } else {
                                            return (
                                                <input
                                                    data-test={field.Name}
                                                    onChange={handleForm}
                                                    placeholder={field.DisplayName || field.Name}
                                                    key={field.InputName}
                                                    name={field.InputName}
                                                    required={field.Mandatory}
                                                    type="text"
                                                    className="input-gray text-xs w-2/3 rounded-none"
                                                    data-1p-ignore
                                                />
                                            );
                                        }
                                    }
                                })}
                            </div>
                            <div className="space-x-2 mt-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary inline"
                                    disabled={loading}
                                    data-test="create-provider"
                                >
                                    {loading ? 'Creating...' : 'Create'}
                                </button>
                                <button
                                    type="button"
                                    className="btn inline"
                                    onClick={onClose}
                                    disabled={loading}
                                    data-test="cancel-create-provider"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </>
        );
    } else {
        return <>Please select a provider.</>;
    }
};

interface EditDynamicProviderProps {
    providerName: string;
    providerId: string;
    onClose: () => void;
}

export const EditDynamicProvider = ({ providerName, providerId, onClose }: EditDynamicProviderProps): JSX.Element => {
    const tenantId = useTenant();
    const { dispatch } = useContext(ToastContext);
    const [provider, setProvider] = useState<DynamicProvider>();
    const [currentValues, setCurrentValues] = useState<ProviderInput>({} as ProviderInput);

    const {
        loading: loadingProvider,
        error: errorProvider,
        data,
    } = useQuery(GET_AUTH_FIELDS_PROVIDER, { variables: { tenantId, providerId }, fetchPolicy: 'network-only' });

    const [updateProvider, { loading, error }] = useMutation(UPDATE_PROVIDER, {
        refetchQueries: [{ query: LIST_PROVIDERS, variables: { tenantId } }],
    });

    const { register, setValue, handleSubmit, control } = useForm<ProviderInput>({
        defaultValues: { name: providerName },
    });

    useEffect(() => {
        if (data) {
            if (data.getAuthFieldsProvider) {
                data.getAuthFieldsProvider.Fields.map((field: Field & { Value: string }) => {
                    setValue(field.InputName as keyof ProviderInput, field.Value);
                    setCurrentValues((currentValues) => {
                        return {
                            ...currentValues,
                            [field.InputName]: field.Value,
                        };
                    });
                });
                setCurrentValues((currentValues) => {
                    return {
                        ...currentValues,
                        name: data.getAuthFieldsProvider.Name,
                    };
                });
                setProvider(data.getAuthFieldsProvider);
            }
        }
    }, [data, setValue]);

    const submitForm = async (formData: ProviderInput) => {
        // There should always be a provider here, but just in case
        if (!provider) {
            return;
        }

        try {
            // Due to a limitation of graphql-codegen,
            // we need to map the AuthField_* to authField* input variables
            // This is a workaround for now, until we can get a fix for graphql-codegen
            console.log('Updating provider with input: ', sanitizeProviderInput(formData));
            const f = formData;
            const input = {
                tenantId,
                type: provider.Name,
                name: f.name,
                authField1: f.AuthField_1 == currentValues.AuthField_1 ? undefined : f.AuthField_1,
                authField2: f.AuthField_2 == currentValues.AuthField_2 ? undefined : f.AuthField_2,
                authField3: f.AuthField_3 == currentValues.AuthField_3 ? undefined : f.AuthField_3,
                authField4: f.AuthField_4 == currentValues.AuthField_4 ? undefined : f.AuthField_4,
                authField5: f.AuthField_5 == currentValues.AuthField_5 ? undefined : f.AuthField_5,
                authField6: f.AuthField_6 == currentValues.AuthField_6 ? undefined : f.AuthField_6,
                authField7: f.AuthField_7 == currentValues.AuthField_7 ? undefined : f.AuthField_7,
            };

            await updateProvider({ variables: { providerId, input } });
            dispatch({
                type: 'add-toast',
                message: `Updated provider ${f.name}`,
                status: 'success',
                autoTimeout: true,
                timeoutTimer: 10,
            });
            onClose();
        } catch (e) {
            console.log(e);
            dispatch({
                type: 'add-toast',
                message: `Failed updating provider ${formData.name}`,
                status: 'failure',
                autoTimeout: false,
            });
        }
    };

    if (errorProvider) {
        return (
            <h4 className="text-red-500 font-medium mt-2">
                There was an issue retrieving your provider. <br />
                <br />
                Please retry. If this persists, please contact support.
            </h4>
        );
    }

    if (error) {
        return (
            <h4 className="text-red-500 font-medium mt-2">
                There was an issue updating your provider. <br />
                <br />
                Please retry. If this persists, please contact support.
            </h4>
        );
    }

    if (loadingProvider || !provider) {
        return (
            <h4 className="text-xs text-gray-300 flex items-center mt-4">
                <div className="loader mr-2 h-4 w-4" />
                Retrieving Existing Configuration...
            </h4>
        );
    }

    if (provider) {
        const sortedFields = provider.Fields.slice().sort((a, b) => a.InputName.localeCompare(b.InputName));

        return (
            <form onSubmit={handleSubmit(submitForm)}>
                <div>
                    <p className="mt-2">
                        Integration with <span className="capitalize">{provider.DisplayName || provider.Name}</span>
                        {provider.HelpURI && provider.HelpURI !== '' && (
                            <span className="ml-1">
                                -
                                <a className="ml-1 hover:text-blue-600" href={provider.HelpURI} target="_blank">
                                    view configuration guide
                                </a>
                            </span>
                        )}
                    </p>

                    <p className="mt-4 mb-2">Update the desired fields as necessary</p>

                    <div className="space-y-4 ml-4 py-2">
                        <Tooltip label="Provider name cannot be changed">
                            <input
                                {...register('name')}
                                data-test="provider-name"
                                type="text"
                                placeholder={`Provider Name, e.g. ${provider.DisplayName}`}
                                name="name"
                                className="input-gray text-xs w-2/3 rounded-none input-disabled opacity-50"
                                required={true}
                                disabled={true}
                            />
                        </Tooltip>
                        {sortedFields.map((field) => {
                            if (!field.Generated && !field.Output) {
                                if (field.Enum) {
                                    return (
                                        <Controller
                                            key={field.InputName}
                                            name={field.InputName as keyof ProviderInput}
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                const options = field.Enum ? field.Enum.slice() : [];

                                                const mappedOptions = options
                                                    .sort((a, b) => a.value.localeCompare(b.value))
                                                    .map((option) => {
                                                        return {
                                                            label: option.value,
                                                            value: option.key,
                                                        };
                                                    });

                                                const selectedOption = mappedOptions.find(
                                                    (option) => option.value === value,
                                                );

                                                return (
                                                    <Tooltip
                                                        label={field.DisplayName}
                                                        key={field.InputName}
                                                        placement="left"
                                                    >
                                                        <div>
                                                            <Select
                                                                key={field.InputName}
                                                                closeMenuOnSelect={true}
                                                                options={mappedOptions}
                                                                classNamePrefix="multiselect-sm"
                                                                className="w-2/3"
                                                                isClearable={false}
                                                                placeholder={`Select ${field.DisplayName}...`}
                                                                menuPlacement="top"
                                                                autoFocus={false}
                                                                value={selectedOption ? selectedOption : null}
                                                                onChange={(option) =>
                                                                    onChange(option ? option.value : '')
                                                                }
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                );
                                            }}
                                        />
                                    );
                                } else {
                                    return (
                                        <Tooltip label={field.DisplayName} key={field.InputName} placement="left">
                                            <input
                                                {...register(field.InputName as keyof ProviderInput)}
                                                data-test={field.Name}
                                                placeholder={field.DisplayName || field.Name}
                                                key={field.InputName}
                                                name={field.InputName}
                                                required={field.Mandatory}
                                                type="text"
                                                className="input-gray text-xs w-2/3 rounded-none"
                                                onFocus={(e) => {
                                                    // If this is a secret field (has 7 stars), clear the value
                                                    if (e.currentTarget.value === '*******') {
                                                        e.currentTarget.value = '';
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                    );
                                }
                            }
                        })}
                    </div>
                    <div className="space-x-2 mt-3">
                        <button
                            type="submit"
                            className={classNames('btn btn-primary inline', loading ? 'btn-disabled' : '')}
                            disabled={loading}
                            data-test="update-provider"
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                        <button
                            type="button"
                            className={classNames('btn inline', loading ? 'btn-disabled' : '')}
                            onClick={onClose}
                            disabled={loading}
                            data-test="cancel-update-provider"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    return <></>;
};
