import { Node } from 'Types/types';
import { deviceTypeNameLookup, operatingSystemNameLookup } from 'Utilities/utils';
import {
    fromNodeProp,
    fromDomain,
    fromUserCount,
    fromWellKnownTargets,
    fromPolicyViolations,
    fromNodeTags,
    fromTargetAccessCount,
    fromEmail,
    fromActorType,
} from './GroupingFunctions';

export type GroupSchemaRecords = Record<string, { description: string; setGroupFunction: (nodes: Node[]) => void }>;

export type GroupSchema = {
    target: GroupSchemaRecords;
    identity: GroupSchemaRecords;
    actor: GroupSchemaRecords;
    device: GroupSchemaRecords;
};

export const GroupSchema: GroupSchema = {
    target: {
        displayName: {
            description: 'Display Name',
            setGroupFunction: (nodes: Node[]) => {
                fromNodeProp(nodes, 'displayName');
            },
        },
        domain: {
            description: 'Domain',
            setGroupFunction: (nodes) => {
                fromDomain(nodes, 'serviceDomain');
            },
        },
        count: { description: 'User Count', setGroupFunction: fromUserCount },
        known: { description: 'Well-known', setGroupFunction: fromWellKnownTargets },
        policy: {
            description: 'Policy Violations',
            setGroupFunction: (nodes) => fromPolicyViolations(nodes, 'target'),
        },
        tags: { description: 'Tags', setGroupFunction: fromNodeTags },
    },
    identity: {
        count: { description: 'Access Count', setGroupFunction: fromTargetAccessCount },
        policy: {
            description: 'Policy Violations',
            setGroupFunction: (nodes) => fromPolicyViolations(nodes, 'identity'),
        },
        tags: { description: 'Tags', setGroupFunction: fromNodeTags },
    },
    device: {
        type: {
            description: 'Device Type',
            setGroupFunction: (nodes) => {
                fromNodeProp(nodes, 'deviceType', deviceTypeNameLookup);
            },
        },
        os: {
            description: 'Device OS',
            setGroupFunction: (nodes) => {
                fromNodeProp(nodes, 'deviceOperatingSystem', operatingSystemNameLookup);
            },
        },
        tags: { description: 'Tags', setGroupFunction: fromNodeTags },
    },
    actor: {
        email: {
            description: 'Email Domain',
            setGroupFunction: (nodes) => {
                fromEmail(nodes, 'alternateId');
            },
        },
        count: { description: 'Access Count', setGroupFunction: fromTargetAccessCount },
        type: { description: 'Self-awareness', setGroupFunction: fromActorType },
        policy: {
            description: 'Policy Violations',
            setGroupFunction: (nodes) => fromPolicyViolations(nodes, 'actor'),
        },
        tags: { description: 'Tags', setGroupFunction: fromNodeTags },
    },
};
