import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import ReactFlow, {
    Edge,
    MarkerType,
    MiniMap,
    Node,
    PanOnScrollMode,
    Panel,
    Position,
    Viewport,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { createIdentityMapNodeFromNodeData, createReactFlowNodeFromIdentityMapNode } from './Chip';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
    GET_ENTITIES_AS_NODES,
    GET_ENTITIES_BY_TYPE_AS_NODES,
    GET_PERMISSIONS_MAP,
    LIST_PROVIDERS,
} from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { ContextMenu } from 'Map/Components/ContextMenu';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { headerChips } from './OktaMockData';

import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { Node as IdentityMapNode } from 'Types/types';
import User from 'assets/icons/Actors/User.png';
import Permission from 'assets/icons/Permissions/Permission.png';
import Target from 'assets/icons/Targets/Target.png';
import UserGroup from 'assets/icons/UserGroups/UserGroup.png';
import { PermissionsControls } from './NavigatorUtils';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Tooltip } from 'Library/Tooltip';
import { getDisplayName, providerNameLookup } from 'Utilities/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { matchSorter } from 'match-sorter';
import { NavigatorProps, NodeData, EdgeData } from './NavigatorTypes';
import { nodeOffset, nodeSpacing, nodeHeaderText, EDGE_COLOR, snapGrid, nodeTypes } from './NavigatorConstants';

const now = +new Date();

export const Navigator = ({
    quickSearch,
    clearQuickSearch,
    autoHide,
    hideAllPermissions,
    providerIds,
}: NavigatorProps): JSX.Element => {
    const {
        enablePermissionsOnlyNodes = true,
        enableRenderOnlyVisibleNodes,
        enablePermissionsMinimap,
        navigatorPageActors,
        navigatorPageGroups,
    } = useFlags();
    const tenantId = useTenant();

    const { setViewport, fitView, getViewport, viewportInitialized } = useReactFlow();
    const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeData>([]);
    const [selectedNode, setSelectedNode] = useState<Node<NodeData> | null>(null);
    const [actorAnyNode, setActorAnyNode] = useState<(object & { nodeId?: string }) | null>(null);
    const [targetAnyNode, setTargetAnyNode] = useState<(object & { nodeId?: string }) | null>(null);

    // const [requiredActorIds, setRequiredActorIds] = useState<string[]>([]);
    // const [requiredTargetIds, setRequiredTargetIds] = useState<string[]>([]);

    const [firstFitView, setFirstFitView] = useState<boolean>(false);
    const [actorPagesExhausted, setActorPagesExhausted] = useState<boolean>(false);
    const [groupPagesExhausted, setGroupPagesExhausted] = useState<boolean>(false);
    const [nodesLoaded, setNodesLoaded] = useState<boolean>(false);
    const [anyPermissionsLoaded, setAnyPermissionsLoaded] = useState<boolean>(false);
    const [actorAnyPermissions, setActorAnyPermissions] = useState<object[]>([]);
    const [targetAnyPermissions, setTargetAnyPermissions] = useState<object[]>([]);
    const [permissionsLoaded, setPermissionsLoaded] = useState<boolean>(false);
    const [cachedQuickSearch, setCachedQuickSearch] = useState<string>('');
    const [cachedProviderIds, setCachedProviderIds] = useState<string[]>([]);
    const [cachedAutoHide, setCachedAutoHide] = useState<boolean>(false);
    const [cachedHideAllPermissions, setCachedHideAllPermissions] = useState<boolean>(false);
    const [contextMenuNode, setContextMenuNode] = useState<IdentityMapNode | null>(null);

    const { dispatch, mapState } = useContext(IdentityMapContext);
    const { show, hideAll } = useContextMenu({ id: 'permissionsNavigator' });

    const {
        loading: loadingPermissions,
        data: dataPermissions,
        error: errorPermissions,
    } = useQuery(GET_PERMISSIONS_MAP, {
        variables: {
            tenantId,
            date: now,
            limit: 1000,
            entryEntityId: selectedNode?.id,
        },
        skip: !selectedNode,
    });

    const {
        loading: loadingActorAnyPermissions,
        data: dataActorAnyPermissions,
        error: errorActorAnyPermissions,
    } = useQuery(GET_PERMISSIONS_MAP, {
        variables: {
            tenantId,
            date: now,
            limit: 1000,
            entryEntityId: actorAnyNode?.nodeId,
        },
        skip: !actorAnyNode,
    });

    const {
        loading: loadingTargetAnyPermissions,
        data: dataTargetAnyPermissions,
        error: errorTargetAnyPermissions,
    } = useQuery(GET_PERMISSIONS_MAP, {
        variables: {
            tenantId,
            date: now,
            limit: 1000,
            entryEntityId: targetAnyNode?.nodeId,
        },
        skip: !targetAnyNode,
    });

    const {
        loading: loadingProviders,
        error: errorProviders,
        data: dataProviders,
    } = useQuery(LIST_PROVIDERS, { variables: { tenantId } });

    // const {
    //     loading: loadingRequiredActors,
    //     data: dataRequiredActors,
    //     error: errorRequiredActors,
    // } = useQuery(GET_ENTITIES_AS_NODES, {
    //     variables: {
    //         tenantId,
    //         entityType: 'STATS_ENTITY_TYPE_ACTOR',
    //         entityIds: requiredActorIds,
    //     },
    // });

    const [getEntitiesAsNodes] = useLazyQuery(GET_ENTITIES_AS_NODES);

    const {
        loading: loadingActors,
        data: dataActors,
        error: errorActors,
        fetchMore: fetchMoreActors,
    } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_ACTOR',
            permissionsOnly: enablePermissionsOnlyNodes,
            dateInMs: now,
        },
    });

    const {
        loading: loadingGroups,
        data: dataGroups,
        error: errorGroups,
        fetchMore: fetchMoreGroups,
    } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_GROUP',
            permissionsOnly: enablePermissionsOnlyNodes,
            dateInMs: now,
        },
    });

    const {
        loading: loadingPolicy,
        data: dataPolicies,
        error: errorPolicy,
    } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_POLICY',
            permissionsOnly: enablePermissionsOnlyNodes,
            dateInMs: now,
        },
    });
    const {
        loading: loadingTargets,
        data: dataTargets,
        error: errorTargets,
    } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_TARGET',
            permissionsOnly: enablePermissionsOnlyNodes,
            dateInMs: now,
        },
    });

    // const {
    //     loading: loadingRequiredTargets,
    //     data: dataRequiredTargets,
    //     error: errorRequiredTargets,
    // } = useQuery(GET_ENTITIES_AS_NODES, {
    //     variables: {
    //         tenantId,
    //         entityType: 'STATS_ENTITY_TYPE_TARGET',
    //         entityIds: requiredTargetIds,
    //     },
    // });

    // Automatically fetch all actor pages.
    useEffect(() => {
        if (!navigatorPageActors && dataActors) {
            console.debug('Not fetching actor pages');
            setActorPagesExhausted(true);
            return;
        }

        if (navigatorPageActors && dataActors) {
            if (dataActors.getEntitiesByTypeAsNodes.cursor === null) {
                console.debug('fetching actor page', dataActors.getEntitiesByTypeAsNodes.cursor);

                fetchMoreActors({
                    variables: {
                        cursor: dataActors.getEntitiesByTypeAsNodes.cursor,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;

                        return {
                            ...prev,
                            getEntitiesByTypeAsNodes: {
                                ...fetchMoreResult.getEntitiesByTypeAsNodes,
                                // Combine data from previous pages with new page
                                nodes: [
                                    ...prev.getEntitiesByTypeAsNodes.nodes,
                                    ...fetchMoreResult.getEntitiesByTypeAsNodes.nodes,
                                ],
                            },
                        };
                    },
                });
            } else {
                console.debug('actor pages exhausted');
                setActorPagesExhausted(true);
            }
        }
    }, [dataActors, fetchMoreActors, navigatorPageActors]);

    // Automatically fetch all group pages.
    useEffect(() => {
        if (!navigatorPageGroups && dataGroups) {
            console.debug('Not fetching group pages');
            setGroupPagesExhausted(true);
            return;
        }

        if (navigatorPageGroups && dataGroups) {
            if (dataGroups.getEntitiesByTypeAsNodes.cursor !== null) {
                console.debug('fetching group page', dataGroups.getEntitiesByTypeAsNodes.cursor);

                fetchMoreGroups({
                    variables: {
                        cursor: dataGroups.getEntitiesByTypeAsNodes.cursor,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;

                        return {
                            ...prev,
                            getEntitiesByTypeAsNodes: {
                                ...fetchMoreResult.getEntitiesByTypeAsNodes,
                                // Combine data from previous pages with new page
                                nodes: [
                                    ...prev.getEntitiesByTypeAsNodes.nodes,
                                    ...fetchMoreResult.getEntitiesByTypeAsNodes.nodes,
                                ],
                            },
                        };
                    },
                });
            } else {
                console.debug('group pages exhausted');
                setGroupPagesExhausted(true);
            }
        }
    }, [dataGroups, fetchMoreGroups, navigatorPageGroups]);

    const scrollToTop = useCallback(() => {
        fitView();
        const { x, zoom } = getViewport();
        setViewport({ x, y: 80, zoom });
    }, [fitView, getViewport, setViewport]);

    useEffect(() => {
        if (nodesLoaded && anyPermissionsLoaded && nodes.length > 0 && !firstFitView) {
            setTimeout(() => {
                console.debug('running first fit view');
                scrollToTop();
                scrollToTop();
                setFirstFitView(true);
            }, 15);
        }
    }, [viewportInitialized, scrollToTop, nodes.length, firstFitView, nodesLoaded, anyPermissionsLoaded]);

    const loadingNodes = loadingGroups || loadingActors || loadingPolicy || loadingTargets || loadingProviders;
    const errorLoadingNodes = errorGroups || errorActors || errorPolicy || errorTargets || errorProviders;
    const loadingAnyPermissions = loadingActorAnyPermissions || loadingTargetAnyPermissions;
    const errorLoadingAnyPermissions = errorActorAnyPermissions || errorTargetAnyPermissions;

    const loadingBootstrap = loadingNodes || loadingAnyPermissions;
    const errorLoadingBootstrap = errorLoadingNodes || errorLoadingAnyPermissions;

    const [savedViewport, setSavedViewport] = useState<Viewport | null>(null);

    const [nodeLimit, setNodeLimit] = useState<Record<string, number>>({
        actor: 7,
        group: 7,
        policy: 7,
        target: 7,
    });

    const filterProviders = useCallback(
        (
            nodes: Node<NodeData>[],
            edges: Edge<EdgeData>[],
            providerIds: string[],
        ): [Node<NodeData>[], Edge<EdgeData>[]] => {
            let newNodes = [...nodes];
            let newEdges = [...edges];
            console.debug('filtering providers', providerIds);

            const includedPolicyNodeIds = new Set<string>();

            newNodes = nodes.map((node) => {
                if (node.type !== 'chip') {
                    return node;
                }
                if (node.data.label === 'policy') {
                    if (node.data.providerId && providerIds.includes(node.data.providerId)) {
                        node.hidden = false;
                        includedPolicyNodeIds.add(node.id);
                    } else {
                        console.debug('hiding policy node', node);
                        node.hidden = true;
                    }
                }

                return node;
            });

            newEdges = edges.map((edge) => {
                if (edge.source.includes('policy')) {
                    if (includedPolicyNodeIds.has(edge.source)) {
                        edge.hidden = false;
                    } else {
                        edge.hidden = true;
                    }
                }
                if (edge.target.includes('policy')) {
                    if (includedPolicyNodeIds.has(edge.target)) {
                        edge.hidden = false;
                    } else {
                        edge.hidden = true;
                    }
                }
                return edge;
            });

            return [newNodes, newEdges];
        },
        [],
    );

    const layoutNodes = useCallback(
        (
            nodes: Node<NodeData>[],
            edges: Edge<EdgeData>[],
            overrideLimit?: Record<string, number>,
        ): [Node<NodeData>[], Edge<EdgeData>[]] => {
            const counts: Record<string, number> = {
                actor: 0,
                group: 0,
                policy: 0,
                target: 0,
            };
            const hiddenNodeIds = new Set<string>();
            const hiddenNodesById = new Map<string, Node<NodeData>>();
            const hasOutgoingEdgesNodeIds = new Set<string>();

            let newNodes = [...nodes].map((n) => {
                n.hidden = false;
                if (n.type === 'chip') {
                    // move the node to the root location
                    n.position = { x: 0, y: 0 };
                }
                return n;
            });

            let newEdges = [...edges].map((e) => {
                e.hidden = false;
                return e;
            });

            [newNodes, newEdges] = filterProviders(nodes, edges, providerIds);

            // Layout the main chips
            newNodes = nodes?.map((n) => {
                if (n.type !== 'chip') return n;

                if (n.hidden) {
                    hiddenNodeIds.add(n.id);
                    hiddenNodesById.set(n.id, n);
                    return n;
                }

                if (!n.data.related) {
                    n.hidden = true;
                    hiddenNodeIds.add(n.id);
                    hiddenNodesById.set(n.id, n);
                    return n;
                }

                const label = n.data.label;
                let limit = nodeLimit[label];
                if (overrideLimit) {
                    limit = overrideLimit[label];
                }

                counts[label] += 1;

                if (counts[label] > limit) {
                    n.hidden = true;
                    hiddenNodeIds.add(n.id);
                    hiddenNodesById.set(n.id, n);
                } else {
                    n.hidden = false;
                    n.position = {
                        x: nodeOffset[label].x,
                        y: nodeOffset[label].y + counts[label] * nodeSpacing[label],
                    };
                }

                return n;
            });

            // Layout the load more chips
            const loadMore: Record<string, boolean> = {
                actor: false,
                group: false,
                policy: false,
                target: false,
            };
            newNodes = newNodes?.map((n) => {
                if (n.type !== 'loadMore') return n;

                const label = n.data.label;
                let limit = nodeLimit[label];
                if (overrideLimit) {
                    limit = overrideLimit[label];
                }

                if (counts[label] > limit) {
                    n.data.name = `+  ${counts[label] - limit} more...`;
                    n.hidden = false;
                    n.position = { x: nodeOffset[label].x, y: nodeOffset[label].y + (limit + 1) * nodeSpacing[label] };
                    loadMore[label] = true;
                } else {
                    n.hidden = true;
                }

                return n;
            });

            // Layout the info chips
            newNodes = newNodes?.map((n) => {
                if (n.type !== 'info') return n;

                const label = n.data.label;
                const increment = loadMore[label] ? 2 : 1;
                let limit = nodeLimit[label];
                if (overrideLimit) {
                    limit = overrideLimit[label];
                }

                n.position = {
                    x: nodeOffset[label].x,
                    y:
                        nodeOffset[label].y +
                        Math.min(counts[label] + increment, limit + increment) * nodeSpacing[label],
                };

                return n;
            });

            // Update the header chips
            newNodes = newNodes?.map((n) => {
                if (n.type !== 'groupChip') return n;

                const label = n.data.label;
                const count = counts[label];
                const header = nodeHeaderText[label];

                n.data.name = `${count} total ${header}`;

                return n;
            });

            const loadMoreEdges: Edge<EdgeData>[] = [];
            const loadMoreEdgeIds = new Set<string>();

            // Remove the existing load more edges
            newEdges = edges?.filter((e) => {
                if (e.id.includes('loadMore')) {
                    return false;
                }
                return e;
            });

            // Create the new load more edges
            newEdges = newEdges.map((e) => {
                if ((hiddenNodeIds.has(e.source) || hiddenNodeIds.has(e.target)) && !e.hidden) {
                    // Create load more edges if the source is visible and the target is hidden
                    if (!hiddenNodeIds.has(e.source) && hiddenNodeIds.has(e.target)) {
                        e.hidden = true;
                        const targetNode = hiddenNodesById.get(e.target);
                        if (!targetNode) return e;

                        if (!targetNode.data.related) return e;

                        const id = `${e.source}-loadMore`;
                        if (loadMoreEdgeIds.has(id)) return e;

                        const targetId = `${targetNode.data.label}-loadMore`;

                        loadMoreEdges.push({
                            id,
                            source: e.source,
                            target: targetId,
                            markerEnd: {
                                type: MarkerType.Arrow,
                                strokeWidth: 1,
                                color: EDGE_COLOR,
                            },
                            style: {
                                strokeWidth: 1,
                                stroke: EDGE_COLOR,
                            },
                        });

                        hasOutgoingEdgesNodeIds.add(e.source);
                        loadMoreEdgeIds.add(id);
                        return e;
                    }
                    // Create load more edges if the target is visible and the source is hidden
                    if (hiddenNodeIds.has(e.source) && !hiddenNodeIds.has(e.target)) {
                        e.hidden = true;
                        const sourceNode = hiddenNodesById.get(e.source);
                        if (!sourceNode) return e;

                        if (!sourceNode.data.related) return e;

                        const id = `loadMore-${e.target}`;
                        if (loadMoreEdgeIds.has(id)) return e;

                        const sourceId = `${sourceNode.data.label}-loadMore`;

                        loadMoreEdges.push({
                            id,
                            source: sourceId,
                            target: e.target,
                            markerEnd: {
                                type: MarkerType.Arrow,
                                strokeWidth: 1,
                                color: EDGE_COLOR,
                            },
                            style: {
                                strokeWidth: 1,
                                stroke: EDGE_COLOR,
                            },
                        });

                        hasOutgoingEdgesNodeIds.add(sourceId);
                        loadMoreEdgeIds.add(id);
                        return e;
                    }
                    // Create load more edges if both the source and target are hidden
                    if (hiddenNodeIds.has(e.source) && hiddenNodeIds.has(e.target)) {
                        e.hidden = true;
                        const sourceNode = hiddenNodesById.get(e.source);
                        const targetNode = hiddenNodesById.get(e.target);

                        if (!sourceNode || !targetNode) return e;

                        if (!sourceNode.data.related || !targetNode.data.related) return e;

                        const id = `${e.source}-${e.target}-loadMore`;
                        if (loadMoreEdgeIds.has(id)) return e;

                        const sourceId = `${sourceNode.data.label}-loadMore`;
                        const targetId = `${targetNode.data.label}-loadMore`;

                        loadMoreEdges.push({
                            id,
                            source: sourceId,
                            target: targetId,
                            markerEnd: {
                                type: MarkerType.Arrow,
                                strokeWidth: 1,
                                color: EDGE_COLOR,
                            },
                            style: {
                                strokeWidth: 1,
                                stroke: EDGE_COLOR,
                            },
                        });

                        hasOutgoingEdgesNodeIds.add(sourceId);
                        loadMoreEdgeIds.add(id);
                        return e;
                    }
                } else if (!e.hidden) {
                    hasOutgoingEdgesNodeIds.add(e.source);
                }
                return e;
            });

            newEdges?.push(...loadMoreEdges);

            // Update chips that have outgoing edges
            newNodes = newNodes?.map((n) => {
                if (n.type && !['chip', 'loadMore'].includes(n.type)) return n;

                if (hasOutgoingEdgesNodeIds.has(n.id)) {
                    n.data.hasOutgoingEdges = true;
                } else {
                    n.data.hasOutgoingEdges = false;
                }
                return n;
            });

            return [newNodes, newEdges];
        },
        [filterProviders, nodeLimit, providerIds],
    );

    useEffect(() => {
        // Check the selectedNodes for any nodes that are not in the current nodes list
        // For any missing nodes, add them to the nodes list
        const incomingNodes: Node<NodeData>[] = [];

        const existingNodeIds = new Set<string>();
        nodes.forEach((n) => existingNodeIds.add(n.id));

        mapState.selectedNodes.forEach((node) => {
            if (node.label !== 'actor' && node.label !== 'target') return;

            if (!existingNodeIds.has(String(node.id))) {
                incomingNodes.push(createReactFlowNodeFromIdentityMapNode(node));
            }
        });

        if (incomingNodes.length > 0) {
            const [newNodes, newEdges] = layoutNodes([...nodes, ...incomingNodes], edges);
            setNodes(newNodes);
            setEdges(newEdges);
        }
    }, [edges, layoutNodes, mapState.selectedNodes, nodes, setEdges, setNodes]);

    useEffect(() => {
        if (
            !nodesLoaded &&
            dataGroups &&
            dataTargets &&
            dataActors &&
            dataPolicies &&
            dataProviders &&
            actorPagesExhausted &&
            groupPagesExhausted
        ) {
            console.debug('processing nodes', dataGroups, dataTargets, dataActors, dataPolicies, dataProviders);
            const actors: object[] = [...(dataActors.getEntitiesByTypeAsNodes.nodes || [])];
            const groups: object[] = [...(dataGroups.getEntitiesByTypeAsNodes.nodes || [])];
            const policies: object[] = [...(dataPolicies.getEntitiesByTypeAsNodes.nodes || [])];
            const targets: object[] = [...(dataTargets.getEntitiesByTypeAsNodes.nodes || [])];

            const providers: object[] = [...dataProviders.listProviders];

            const providersById = new Map<string, any>();
            providers.forEach((p: any) => providersById.set(p.providerId, p));

            // sort all nodes by name
            actors.sort((a: any, b: any) => a.props.displayName.localeCompare(b.props.displayName));
            groups.sort((a: any, b: any) => a.props.displayName.localeCompare(b.props.displayName));
            policies.sort((a: any, b: any) => a.props.displayName.localeCompare(b.props.displayName));
            targets.sort((a: any, b: any) => a.props.displayName.localeCompare(b.props.displayName));

            // move the actor with displayName 'any' to the top of the list
            const anyActorIndex = actors.findIndex((a: any) => a.props.displayName === 'any');
            if (anyActorIndex > -1) {
                const anyActor = actors.splice(anyActorIndex, 1)[0];
                console.debug('setting any actor node', anyActor);
                setActorAnyNode(anyActor);
                actors.unshift(anyActor);
            }

            // move the target with displayName 'any' to the top of the list
            const anyTargetIndex = targets.findIndex((a: any) => a.props.displayName === 'any');
            if (anyTargetIndex > -1) {
                const anyTarget = targets.splice(anyTargetIndex, 1)[0];
                console.debug('setting any target node', anyTarget);
                setTargetAnyNode(anyTarget);
                targets.unshift(anyTarget);
            }

            const actorGroupChip = {
                id: 'a-0',
                type: 'groupChip',
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                data: { label: 'actor', name: `${actors.length} Total Actors`, icon: User },
                position: { x: 0, y: 0 },
            };

            const actorNodes = actors.slice(0, 1).map((a: any) => {
                const name = a.props.displayName === 'any' ? 'All Actors' : a.props.displayName;
                return {
                    id: a.nodeId,
                    type: 'chip',
                    hidden: false,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    data: { label: a.label, name, icon: User, related: true },
                    position: { x: 0, y: 0 },
                };
            });

            // actorNodes = actorNodes.concat(
            //     requiredActorsWithoutAny.map((a: any, index) => {
            //         return {
            //             id: a.nodeId,
            //             type: 'chip',
            //             hidden: false,
            //             sourcePosition: Position.Right,
            //             targetPosition: Position.Left,
            //             data: { label: a.label, name: getDisplayName(a), icon: User, related: true },
            //             position: { x: 0, y: 0 },
            //         };
            //     }),
            // );

            const actorInfo = {
                id: 'a-info',
                type: 'info',
                data: { label: 'actor', name: 'Actors you add to the explorer will appear here', icon: User },
                position: { x: -3, y: 125 },
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
            };

            const actorLoadMore = {
                id: 'actor-loadMore',
                type: 'loadMore',
                data: { label: 'actor', name: `Load More`, icon: User },
                position: { x: 0, y: 0 },
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                selectable: false,
            };

            const groupGroupChip = {
                id: 'g-0',
                type: 'groupChip',
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                data: { label: 'group', name: `${groups.length} Total Groups`, icon: UserGroup },
                position: { x: 400, y: 0 },
            };

            const groupNodes = groups.map((g: any) => {
                return {
                    id: g.nodeId,
                    type: 'chip',
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    data: { label: g.label, name: g.props.displayName, icon: UserGroup, related: true },
                    position: { x: 0, y: 0 },
                };
            });

            const groupLoadMore = {
                id: 'group-loadMore',
                type: 'loadMore',
                data: { label: 'group', name: `Load More`, icon: UserGroup },
                position: { x: 0, y: 0 },
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                selectable: false,
            };

            const policyGroupChip = {
                id: 'p-0',
                type: 'groupChip',
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                data: { label: 'policy', name: `${policies.length} Total Policies`, icon: Permission },
                position: { x: 800, y: 0 },
            };

            const policyNodes = policies.map((p: any) => {
                const provider = providersById.get(p.props.providerId);
                let providerTooltip;
                if (provider) {
                    providerTooltip = `${p.props.displayName} [From ${provider.name} (${providerNameLookup(
                        provider.type,
                    )})]`;
                }

                let providerId = p.props.providerId;
                if (!providerIds.includes(providerId)) {
                    console.debug('setting providerId to unknown for policy', p);
                    providerId = 'unknown';
                }

                return {
                    id: p.nodeId,
                    type: 'chip',
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    data: {
                        label: p.label,
                        name: p.props.displayName,
                        icon: Permission,
                        tooltip: providerTooltip,
                        providerId: providerId,
                        related: true,
                    },
                    position: { x: 0, y: 0 },
                };
            });

            const policyLoadMore = {
                id: 'policy-loadMore',
                type: 'loadMore',
                data: { label: 'policy', name: `Load More`, icon: Permission },
                position: { x: 0, y: 0 },
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                selectable: false,
            };

            const targetGroupChip = {
                id: 't-0',
                type: 'groupChip',
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                data: { label: 'target', name: `${targets.length} Total Targets`, icon: Target },
                position: { x: 1200, y: 0 },
            };

            const validTargetNodes = targets.filter((t: any) => {
                // Filter out objects that we do not support permissions on
                if (['NODE_TYPE_MAILBOX', 'NODE_TYPE_FILE', 'NODE_TYPE_GROUP'].includes(t.nodeType)) {
                    return false;
                }
                return true;
            });

            const targetNodes = validTargetNodes.slice(0, 1).map((t: any) => {
                const name: string = t.props.displayName === 'any' ? 'All Targets' : t.props.displayName;

                return {
                    id: t.nodeId,
                    type: 'chip',
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    data: { label: t.label, name, icon: Target, related: true },
                    position: { x: 0, y: 0 },
                };
            });

            // targetNodes = targetNodes.concat(
            //     requiredTargets.map((t: any, index) => {
            //         return {
            //             id: t.nodeId,
            //             type: 'chip',
            //             sourcePosition: Position.Right,
            //             targetPosition: Position.Left,
            //             data: { label: t.label, name: getDisplayName(t), icon: Target, related: true },
            //             position: { x: 0, y: 0 },
            //         };
            //     }),
            // );

            const targetLoadMore = {
                id: 'target-loadMore',
                type: 'loadMore',
                data: { label: 'target', name: `Load More`, icon: Target },
                position: { x: 0, y: 0 },
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                selectable: false,
            };

            const targetInfo = {
                id: 't-info',
                type: 'info',
                data: { label: 'target', name: 'Targets you add to the explorer will appear here', icon: User },
                position: { x: 0, y: 0 },
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
            };

            let newNodes = [
                ...headerChips,
                actorGroupChip,
                ...actorNodes,
                actorInfo,
                actorLoadMore,
                groupGroupChip,
                ...groupNodes,
                groupLoadMore,
                policyGroupChip,
                ...policyNodes,
                policyLoadMore,
                targetGroupChip,
                ...targetNodes,
                targetInfo,
                targetLoadMore,
            ];

            let newEdges = [];

            // [newNodes, newEdges] = filterProviders(newNodes, edges, providerIds);

            [newNodes, newEdges] = layoutNodes(newNodes, edges);

            setNodesLoaded(true);
            setNodes(newNodes);
            setEdges(newEdges);
        }
    }, [
        actorPagesExhausted,
        dataActors,
        dataGroups,
        dataPolicies,
        dataProviders,
        dataTargets,
        edges,
        filterProviders,
        groupPagesExhausted,
        layoutNodes,
        nodesLoaded,
        providerIds,
        setEdges,
        setNodes,
    ]);

    // Handle loading the graph of permissions for the ANY actor and target nodes
    useEffect(() => {
        if (!anyPermissionsLoaded && dataActorAnyPermissions && dataTargetAnyPermissions) {
            console.debug('processing any permissions', dataActorAnyPermissions, dataTargetAnyPermissions);
            const actorPermissions = dataActorAnyPermissions.getPermissionsMap.permissions;
            const targetPermissions = dataTargetAnyPermissions.getPermissionsMap.permissions;
            setActorAnyPermissions(actorPermissions);
            setTargetAnyPermissions(targetPermissions);
            setAnyPermissionsLoaded(true);
        }
    }, [dataActorAnyPermissions, dataTargetAnyPermissions, anyPermissionsLoaded, setAnyPermissionsLoaded]);

    // Handle loading the graph of permissions for a selected node
    useEffect(() => {
        async function processPermissions() {
            if (!permissionsLoaded && dataPermissions) {
                console.debug('processing permissions', dataPermissions);
                let permissions = dataPermissions.getPermissionsMap.permissions;

                if (!cachedHideAllPermissions) {
                    if (selectedNode?.data.label === 'actor' && selectedNode?.data.name !== 'any') {
                        permissions = [...actorAnyPermissions, ...permissions];
                    } else if (selectedNode?.data.label === 'target' && selectedNode?.data.name !== 'any') {
                        permissions = [...targetAnyPermissions, ...permissions];
                    }
                }

                const nodeIds = new Set<string>([]);
                const edgeIds = new Set<string>([]);
                const nodeIdsByLabel: Record<string, Set<string>> = {
                    actor: new Set<string>(),
                    group: new Set<string>(),
                    policy: new Set<string>(),
                    target: new Set<string>(),
                };

                let newEdges: Edge<EdgeData>[] = permissions
                    .map((p: any) => {
                        const source = p.outV;
                        const target = p.inV;
                        const sourceType = source.split('/')[1];
                        const targetType = target.split('/')[1];
                        const id = `${source}-${target}`;
                        nodeIds.add(source);
                        nodeIds.add(target);
                        nodeIdsByLabel[sourceType].add(source);
                        nodeIdsByLabel[targetType].add(target);

                        if (edgeIds.has(id)) {
                            console.debug('edgeId already exists', id);
                            return;
                        } else {
                            edgeIds.add(id);
                        }

                        let operationType = 'inclusion';
                        if (p.props && p.props.operationType) {
                            operationType = p.props.operationType;
                        }

                        let sourceHandle = 'default';
                        let type = 'default';
                        if (sourceType == targetType) {
                            sourceHandle = 'nested';
                            type = 'smoothstep';
                        }

                        return {
                            id,
                            source,
                            target,
                            sourceHandle,
                            type,
                            markerEnd: {
                                type: MarkerType.Arrow,
                                strokeWidth: 1,
                                color: EDGE_COLOR,
                            },
                            style: {
                                strokeWidth: 1,
                                stroke: EDGE_COLOR,
                                strokeDasharray: operationType === 'inclusion' ? '0' : '10 5',
                            },
                        };
                    })
                    .filter(Boolean);

                const existingNodeIds = new Set<string>(nodes.map((n) => n.id));

                const requiredActorIds: string[] = [];
                const requiredTargetIds: string[] = [];

                Array.from(mapState.selectedNodes)
                    .filter((node) => node.label === 'actor')
                    .map((node) => {
                        if (!existingNodeIds.has(String(node.id))) {
                            requiredActorIds.push(String(node.id));
                        }
                    });

                nodeIdsByLabel.actor.forEach((id) => {
                    if (!existingNodeIds.has(id)) {
                        requiredActorIds.push(id);
                    }
                });

                const actorResult = await getEntitiesAsNodes({
                    variables: { tenantId, entityIds: requiredActorIds, entityType: 'STATS_ENTITY_TYPE_ACTOR' },
                });

                const actors = actorResult.data?.getEntitiesAsNodes || [];

                Array.from(mapState.selectedNodes)
                    .filter((node) => node.label === 'target')
                    .map((node) => {
                        if (!existingNodeIds.has(String(node.id))) {
                            requiredTargetIds.push(String(node.id));
                        }
                    });

                nodeIdsByLabel.target.forEach((id) => {
                    if (!existingNodeIds.has(id)) {
                        requiredTargetIds.push(id);
                    }
                });

                const targetResult = await getEntitiesAsNodes({
                    variables: { tenantId, entityIds: requiredTargetIds, entityType: 'STATS_ENTITY_TYPE_TARGET' },
                });

                const targets = targetResult.data?.getEntitiesAsNodes || [];

                let newNodes = [...nodes];

                actors.map((a: any) => {
                    if (a.nodeId === actorAnyNode?.nodeId) {
                        return;
                    }
                    newNodes.push({
                        id: a.nodeId,
                        type: 'chip',
                        hidden: false,
                        sourcePosition: Position.Right,
                        targetPosition: Position.Left,
                        data: { label: a.label, name: getDisplayName(a), icon: User, related: true },
                        position: { x: 0, y: 0 },
                    });
                });

                targets.map((t: any) => {
                    if (t.nodeId === targetAnyNode?.nodeId) {
                        return;
                    }
                    newNodes.push({
                        id: t.nodeId,
                        type: 'chip',
                        hidden: false,
                        sourcePosition: Position.Right,
                        targetPosition: Position.Left,
                        data: { label: t.label, name: getDisplayName(t), icon: Target, related: true },
                        position: { x: 0, y: 0 },
                    });
                });

                if (autoHide) {
                    newNodes = newNodes?.map((n) => {
                        if (n.type !== 'chip') return n;
                        if (nodeIds.has(n.id) || n.id === selectedNode?.id) {
                            n.data.related = true;
                        } else {
                            n.data.related = false;
                        }
                        return n;
                    });
                } else {
                    newNodes = newNodes?.map((n) => {
                        if (n.type !== 'chip') return n;
                        n.data.related = true;
                        return n;
                    });
                }

                [newNodes, newEdges] = layoutNodes(newNodes, newEdges);

                setPermissionsLoaded(true);
                setNodes(newNodes);
                setEdges(newEdges);
                // setNodesLoaded(false);

                if (mapState.selectedPermissionsNode) {
                    dispatch({ type: 'set-permissions-node', node: undefined });
                }

                setSavedViewport(getViewport());
                setTimeout(() => {
                    scrollToTop();
                }, 2);
            }
        }

        processPermissions();
    }, [
        actorAnyPermissions,
        autoHide,
        dataPermissions,
        mapState.selectedNodes,
        nodes,
        permissionsLoaded,
        selectedNode,
        setNodes,
        setNodesLoaded,
        setPermissionsLoaded,
        targetAnyPermissions,
        dispatch,
        mapState.selectedPermissionsNode,
        layoutNodes,
        setEdges,
        getEntitiesAsNodes,
        tenantId,
        getViewport,
        scrollToTop,
        actorAnyNode?.nodeId,
        cachedHideAllPermissions,
        targetAnyNode?.nodeId,
    ]);

    useEffect(() => {
        // This effect handles the incoming selection of a node that the user wishes to view the permissions on
        // We must wait until the nodes, any permissions, providers and the first layout is performed before
        // selecting the node and loading the permissions
        if (
            nodesLoaded &&
            anyPermissionsLoaded &&
            cachedProviderIds &&
            firstFitView &&
            mapState.selectedPermissionsNode
        ) {
            setNodes((prevNodes) => {
                let selectedNode: Node<NodeData> | undefined;

                const newNodes = prevNodes?.map((elem) => {
                    if (elem.id === mapState.selectedPermissionsNode?.id) {
                        elem.selected = true;
                        selectedNode = elem;
                    } else {
                        elem.selected = false;
                    }
                    return elem;
                });

                if (!selectedNode) {
                    const mapNode = mapState.selectedPermissionsNode;
                    console.debug('selected node not found', mapNode);
                    if (mapNode) {
                        selectedNode = {
                            id: mapNode.id.toString(),
                            type: 'chip',
                            hidden: false,
                            sourcePosition: Position.Right,
                            targetPosition: Position.Left,
                            data: {
                                label: mapNode.label || 'actor',
                                name: getDisplayName(mapNode),
                                icon: User,
                                related: true,
                            },
                            position: { x: 0, y: 0 },
                        };

                        newNodes?.push(selectedNode);
                    }
                }

                if (selectedNode) {
                    setEdges([]);
                    setSelectedNode(selectedNode);
                    setPermissionsLoaded(false);
                }

                return newNodes;
            });
        }
    }, [
        anyPermissionsLoaded,
        cachedProviderIds,
        dispatch,
        firstFitView,
        mapState.selectedPermissionsNode,
        nodesLoaded,
        setEdges,
        setNodes,
    ]);

    const applySearch = useCallback(
        (
            nodes: Node<NodeData>[],
            edges: Edge<EdgeData>[],
            quickSearch: string,
        ): [Node<NodeData>[], Edge<EdgeData>[]] => {
            const connectedNodeIds = new Set<string>([]);

            edges.map((edge) => {
                connectedNodeIds.add(edge.source);
                connectedNodeIds.add(edge.target);
            });

            let newNodes = [...nodes];
            let newEdges = [...edges];

            if (quickSearch.length > 0) {
                const nodeIds = new Set<string>([]);

                const matchingNodes = fuzzySearchMultipleWords(nodes, ['data.name'], quickSearch);

                const matchingNodeIds = new Set<string>([]);
                matchingNodes.forEach((node) => {
                    matchingNodeIds.add(node.id);
                });

                newNodes = nodes.map((node) => {
                    if (node.type != 'chip') {
                        return node;
                    }
                    if (node.id === selectedNode?.id) {
                        nodeIds.add(node.id);
                        return node;
                    }

                    const nodeIsConnected = connectedNodeIds.has(node.id);

                    const nodeIsMatched = matchingNodeIds.has(node.id);

                    if (nodeIsMatched && (!selectedNode || nodeIsConnected)) {
                        node.data.related = true;
                        nodeIds.add(node.id);
                    } else {
                        node.data.related = false;
                    }
                    return node;
                });

                newEdges = newEdges.map((edge) => {
                    if (nodeIds.has(edge.source) && nodeIds.has(edge.target)) {
                        edge.hidden = false;
                    } else {
                        edge.hidden = true;
                    }
                    return edge;
                });
            } else {
                newNodes.map((node) => {
                    if (node.type != 'chip') {
                        return node;
                    }
                    const nodeIsConnected = connectedNodeIds.has(node.id);
                    if (!selectedNode || nodeIsConnected || autoHide === false) {
                        node.data.related = true;
                    }
                    return node;
                });
                newEdges = edges.map((edge) => {
                    edge.hidden = false;
                    return edge;
                });
            }

            return [newNodes, newEdges];
        },
        [autoHide, selectedNode],
    );

    // Handle if the selected providers change
    useEffect(() => {
        // do a deep compare of the providerIds
        const setCached = new Set(cachedProviderIds);
        const setIncoming = new Set(providerIds);

        const equal =
            cachedProviderIds.length === providerIds.length && [...setCached].every((value) => setIncoming.has(value));

        if (!equal && nodesLoaded && anyPermissionsLoaded) {
            console.log('providerIds changed', providerIds);
            const [newNodes, newEdges] = layoutNodes(nodes, edges);
            setNodes(newNodes);
            setEdges(newEdges);
            setCachedProviderIds(providerIds);
        }
    }, [
        anyPermissionsLoaded,
        cachedProviderIds,
        edges,
        layoutNodes,
        nodes,
        nodesLoaded,
        providerIds,
        setEdges,
        setNodes,
    ]);

    // Handle show all permissions change
    useEffect(() => {
        if (hideAllPermissions != cachedHideAllPermissions) {
            setPermissionsLoaded(false);
            setCachedHideAllPermissions(hideAllPermissions);
        }
    }, [cachedHideAllPermissions, hideAllPermissions, setPermissionsLoaded]);

    // Handle autoHide change
    useEffect(() => {
        if (autoHide !== cachedAutoHide) {
            // const [newNodes, newEdges] = layoutNodes(nodes, edges);
            // setNodes(newNodes);
            // setEdges(newEdges);
            setPermissionsLoaded(false);
            setCachedAutoHide(autoHide);
        }
    }, [autoHide, cachedAutoHide, edges, layoutNodes, nodes, setEdges, setNodes]);

    useEffect(() => {
        if (quickSearch !== cachedQuickSearch) {
            let [newNodes, newEdges] = applySearch(nodes, edges, quickSearch);

            [newNodes, newEdges] = layoutNodes(newNodes, newEdges);
            setNodes(newNodes);
            setEdges(newEdges);
            setCachedQuickSearch(quickSearch);
        }
    }, [applySearch, cachedQuickSearch, edges, layoutNodes, nodes, quickSearch, setEdges, setNodes]);

    const clearSelectedNode = useCallback(() => {
        clearQuickSearch();
        hideAll();
        if (selectedNode) {
            let newNodes = nodes.map((node) => {
                node.data.related = true;
                node.hidden = false;
                node.selected = false;
                return node;
            });
            const explorerNodeIds = new Set<string>([]);
            mapState.selectedNodes.forEach((n) => explorerNodeIds.add(String(n.id)));

            newNodes = newNodes.filter((node) => {
                if (
                    node.id !== actorAnyNode?.nodeId &&
                    node.id !== targetAnyNode?.nodeId &&
                    node.type == 'chip' &&
                    (node.data.label == 'actor' || node.data.label == 'target') &&
                    !explorerNodeIds.has(node.id)
                ) {
                    return false;
                }
                return true;
            });

            [newNodes] = layoutNodes(newNodes, []);
            setNodes(newNodes);
            setEdges([]);
            setSelectedNode(null);
            if (savedViewport) {
                setViewport(savedViewport);
            }
        }
    }, [
        actorAnyNode?.nodeId,
        clearQuickSearch,
        hideAll,
        layoutNodes,
        mapState.selectedNodes,
        nodes,
        savedViewport,
        selectedNode,
        setEdges,
        setNodes,
        setViewport,
        targetAnyNode?.nodeId,
    ]);

    const resetView = useCallback(() => {
        const newLimit = {
            actor: 7,
            group: 7,
            policy: 7,
            target: 7,
        };

        setNodeLimit(newLimit);
        clearSelectedNode();

        setTimeout(() => {
            scrollToTop();
        }, 5);
    }, [clearSelectedNode, scrollToTop]);

    const onNodeClick = useCallback(
        (e: React.MouseEvent<Element, MouseEvent>, node: Node<NodeData>) => {
            e.stopPropagation();
            e.preventDefault();
            hideAll();
            if (node.type === 'loadMore') {
                // update nodeLimit record based on the node.data.label
                const newLimit = { ...nodeLimit };
                newLimit[node.data.label] = nodeLimit[node.data.label] + 10;

                let newNodes = [...nodes];
                let newEdges = [...edges];

                if (autoHide) {
                    if (selectedNode) {
                        const nodeIds = new Set<string>([]);

                        edges.map((edge) => {
                            nodeIds.add(edge.source);
                            nodeIds.add(edge.target);
                        });

                        newNodes = newNodes.map((n) => {
                            if (n.type !== 'chip') return n;

                            if (nodeIds.has(n.id)) {
                                n.data.related = true;
                            } else {
                                n.data.related = false;
                            }

                            return n;
                        });
                    } else {
                        newNodes = newNodes.map((n) => {
                            if (n.type !== 'chip') return n;
                            n.data.related = true;
                            return n;
                        });
                    }
                }

                if (cachedQuickSearch) {
                    [newNodes, newEdges] = applySearch(newNodes, newEdges, quickSearch);
                }

                [newNodes, newEdges] = layoutNodes(newNodes, newEdges, newLimit);

                setNodes(newNodes);
                setEdges(newEdges);
                setNodeLimit(newLimit);
            }

            if (node.type === 'chip') {
                if (node) {
                    if (node.id === selectedNode?.id) {
                        clearSelectedNode();
                        return;
                    }
                    clearQuickSearch();
                    setEdges([]);
                    setSelectedNode(node);
                    setPermissionsLoaded(false);
                }
            }

            if (node.type === 'groupChip') {
                clearSelectedNode();
            }
        },
        [
            applySearch,
            autoHide,
            cachedQuickSearch,
            clearQuickSearch,
            clearSelectedNode,
            edges,
            hideAll,
            layoutNodes,
            nodeLimit,
            nodes,
            quickSearch,
            selectedNode,
            setEdges,
            setNodes,
        ],
    );

    const onNodeContextMenu = useCallback(
        (e: React.MouseEvent<Element, MouseEvent>, node: Node<NodeData>) => {
            e.stopPropagation();
            e.preventDefault();

            if (node.type !== 'chip') {
                return;
            }

            if (node.data.name === 'any') {
                return;
            }

            if (node.data.label === 'actor' || node.data.label === 'target') {
                // Do not show the context menu for the All Actor or All Target nodes
                if (node.data.name === 'All Actors' || node.data.name === 'All Targets') {
                    return;
                }

                const identityMapNode = createIdentityMapNodeFromNodeData({ data: node.data, id: node.id });

                setContextMenuNode(identityMapNode);

                // show context menu
                show(e, {
                    position: {
                        x: e.clientX,
                        y: e.clientY,
                    },
                });
            }
        },
        [show],
    );

    return (
        <div className="flex w-full h-full relative">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                <div className="flex flex-col text-xs text-gray-200 bg-gray-800 rounded-md">
                    {loadingBootstrap && (
                        <div className="p-4">
                            <div className="flex justify-center">
                                <div className="loader h-8 w-8" />
                            </div>
                            <div className="mt-3">Loading permissions data...</div>
                        </div>
                    )}
                    {errorLoadingBootstrap && (
                        <div className="p-4">
                            <div className="flex justify-center">
                                <NoSymbolIcon className="h-8 w-8 text-red-400" />
                            </div>
                            <div className="mt-3 text-red-400">Error loading permissions data</div>
                        </div>
                    )}
                    {loadingPermissions && (
                        <div className="p-4">
                            <div className="flex justify-center">
                                <div className="loader h-8 w-8" />
                            </div>
                            <div className="mt-3"> Loading permissions...</div>
                        </div>
                    )}

                    {errorPermissions && (
                        <div className="p-4">
                            <div className="flex justify-center">
                                <NoSymbolIcon className="h-8 w-8 text-red-400" />
                            </div>
                            <div className="mt-3 text-red-400">Error loading permissions</div>
                        </div>
                    )}
                </div>
            </div>
            {nodesLoaded && anyPermissionsLoaded && !errorLoadingBootstrap && !loadingBootstrap && (
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    snapToGrid={true}
                    snapGrid={snapGrid}
                    panOnScroll={true}
                    panOnScrollMode={PanOnScrollMode.Vertical}
                    maxZoom={1}
                    proOptions={{ hideAttribution: true }}
                    nodeTypes={nodeTypes}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    edgesFocusable={false}
                    selectionOnDrag={false}
                    onlyRenderVisibleElements={enableRenderOnlyVisibleNodes}
                    onPaneContextMenu={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onNodeContextMenu={onNodeContextMenu}
                    onNodeClick={onNodeClick}
                    onPaneClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        clearSelectedNode();
                    }}
                    zoomOnScroll={false}
                    translateExtent={[
                        [-10000, -80],
                        [10000, 10000],
                    ]}
                >
                    <>
                        <Panel position="bottom-center">
                            <div className="flex flex-col text-xs text-gray-200 bg-gray-800 rounded-md">
                                {selectedNode && (
                                    <div className="p-4">
                                        <div className="flex">
                                            {permissionsLoaded && edges.length === 0 ? (
                                                <div className="flex">
                                                    <ExclamationTriangleIcon className="h-4 w-4 mr-2 text-gray-300" />
                                                    No permissions found for {selectedNode?.data.name}
                                                </div>
                                            ) : (
                                                <div>
                                                    Viewing permissions related to {selectedNode.data.label}{' '}
                                                    {selectedNode?.data.name}
                                                </div>
                                            )}
                                            <Tooltip label="Clear selection" placement="top">
                                                <button onClick={clearSelectedNode} className="ml-2">
                                                    <XMarkIcon className="h-4 w-4 text-gray-400 hover:text-gray-200" />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Panel>
                        <PermissionsControls scrollToTop={scrollToTop} resetView={resetView} />
                        <ContextMenu id={'permissionsNavigator'} node={contextMenuNode} />
                        {enablePermissionsMinimap && (
                            <MiniMap
                                style={{ backgroundColor: '#2b3544' }}
                                nodeColor={'#4b5563'}
                                maskColor={'rgb(31 41 55 / 80%)'}
                            />
                        )}
                    </>
                </ReactFlow>
            )}
        </div>
    );
};

const fuzzySearchMultipleWords = (items: Node<NodeData>[], keys: string[], filterValue: string) => {
    if (!filterValue || !filterValue.length) {
        return items;
    }

    const terms = filterValue.split(' ');
    if (!terms) {
        return items;
    }

    // reduceRight will mean sorting is done by score for the _first_ entered word.
    return terms.reduceRight((results, term) => matchSorter(results, term, { keys }), items);
};
