import { useEnvironment } from 'Hooks/Hooks';
import { Helmet } from 'react-helmet-async';

const Favicon = (): JSX.Element => {
    const env = useEnvironment();

    return (
        <Helmet>
            {env == 'dev' && <link rel="icon" href="/favicon-dev.ico" />}
            {env == 'staging' && <link rel="icon" href="/favicon-staging.ico" />}
            {env == 'prod' && <link rel="icon" href="/favicon.ico" />}
        </Helmet>
    );
};

export default Favicon;
