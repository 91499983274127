type DashboardStatProps = {
    title: string;
    value: number;
    previousValue?: number;
    dotColor?: string;
    className?: string;
};

export const Stat: React.FunctionComponent<DashboardStatProps> = ({
    title,
    value,
    previousValue,
    dotColor,
    className,
}) => {
    let percentChange;
    let arrow;
    let arrowColor;
    if (previousValue) {
        percentChange = Math.round(((value - previousValue) / previousValue) * 100);
        if (percentChange === 0) {
            arrow = '↔';
            arrowColor = 'text-gray-500';
        } else {
            arrow = percentChange > 0 ? '↑' : '↓';
            arrowColor = percentChange > 0 ? 'text-green-300' : 'text-red-400';
        }
    }

    return (
        <div
            className={className}
            data-tooltip={previousValue ? `Previous ${title}: ${previousValue?.toLocaleString()}` : undefined}
            data-hover
        >
            <h1>
                {dotColor && (
                    <span className="inline-flex">
                        <svg
                            className="inline-block h-2 w-2 rounded-full mr-1"
                            viewBox="0 0 8 8"
                            xmlns="http://www.w3.org/2000/svg"
                            fill={dotColor}
                        >
                            <circle cx="4" cy="4" r="4" />
                        </svg>
                    </span>
                )}
                <span>{title}</span>
            </h1>
            <h1 className="text-xl font-bold">{value.toLocaleString()}</h1>
            {previousValue ? (
                <h4 className={arrowColor}>
                    {arrow} {percentChange}%
                </h4>
            ) : null}
        </div>
    );
};
