import { useApolloClient, useMutation } from '@apollo/client';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { DELETE_PROVIDER, LIST_PROVIDERS } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useState, useContext } from 'react';
import { Provider } from 'Types/types';
import { epochInSecondsToLocaleString, formatBytes } from 'Utilities/utils';
import { ToastContext } from 'Map/Components/ToastContext';
import { useUserPermissions } from 'Utilities/UserPermissions';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ProviderDetailProps {
    data: Provider;
    omitDelete?: boolean;
    onEdit: (providerId: string, providerName: string) => void;
}
const ProviderDetail = ({ data, omitDelete, onEdit }: ProviderDetailProps): JSX.Element => {
    const { enableEditProvider } = useFlags();

    const tenantId = useTenant();

    const { userCan } = useUserPermissions();

    const canDeleteProviders = userCan('delete', '/provider/*');
    const canUpdateProviders = userCan('update', '/provider/*');

    const client = useApolloClient();
    const { dispatch } = useContext(ToastContext);

    const [errorDeletingProvider, setErrorDeletingProvider] = useState(false);

    const [deleteProvider, { loading: deleting }] = useMutation(DELETE_PROVIDER);

    const deleteSelf = async () => {
        try {
            await deleteProvider({ variables: { tenantId, providerId: data.providerId } });
            const providers = client.readQuery({ query: LIST_PROVIDERS, variables: { tenantId } });
            const newProviders = providers.listProviders.filter(
                (provider: Provider) => provider.providerId !== data.providerId,
            );
            client.writeQuery({
                query: LIST_PROVIDERS,
                variables: { tenantId },
                data: { listProviders: newProviders },
            });

            dispatch({
                type: 'add-toast',
                message: `Deleted provider ${data.name}`,
                status: 'success',
                autoTimeout: true,
                timeoutTimer: 10,
            });
        } catch (error) {
            console.log(error);
            setErrorDeletingProvider(true);
            dispatch({
                type: 'add-toast',
                message: `Failed to delete provider ${data.name}`,
                status: 'failure',
                autoTimeout: false,
            });
        }
    };

    return (
        <>
            <div className="space-y-4">
                <ProviderStatus status={data.status} />
                <ProviderError status={data.status} lastError={data.lastError} lastErrorAt={data.lastErrorAt} />
                <ProviderEvents firstEventAt={data.firstEventAt} lastEventAt={data.lastEventAt} />
                <ProviderStorage storageUsed={data.storageUsed} />

                <div className="flex space-x-2 items-center justify-end">
                    {enableEditProvider && canUpdateProviders && (
                        <Edit
                            onEdit={() => {
                                onEdit(data.providerId, data.name);
                            }}
                        />
                    )}
                    {errorDeletingProvider ? (
                        <h4>Could not delete Provider</h4>
                    ) : (
                        omitDelete || (canDeleteProviders && <Delete onDelete={deleteSelf} deleting={deleting} />)
                    )}
                </div>
            </div>
        </>
    );
};

const Edit = ({ onEdit }: { onEdit: () => void }) => {
    return (
        <div className="flex justify-end items-center space-x-2 text-xs font-medium">
            <button className="btn opacity-70 hover:opacity-100" onClick={onEdit} data-test="edit-provider">
                Edit
            </button>
        </div>
    );
};

const Delete = ({ onDelete, deleting }: { onDelete: () => void; deleting: boolean }) => {
    const [askingConformation, setAskingConformation] = useState(false);

    return (
        <div className="flex justify-end items-center space-x-2 text-xs font-medium">
            {askingConformation ? (
                <>
                    <button
                        className="btn btn-danger"
                        onClick={onDelete}
                        data-test="delete-provider-confirm"
                        disabled={deleting}
                    >
                        {deleting ? 'Deleting...' : 'Yes'}
                    </button>
                    <button
                        className="btn"
                        onClick={() => setAskingConformation(false)}
                        data-test="delete-provider-cancel"
                        disabled={deleting}
                    >
                        No
                    </button>
                    <h4>Are you sure?</h4>
                </>
            ) : (
                <button
                    className="btn opacity-70 hover:opacity-100"
                    onClick={() => setAskingConformation(true)}
                    data-test="delete-provider"
                >
                    Delete
                </button>
            )}
        </div>
    );
};

const ProviderEvents = ({
    firstEventAt,
    lastEventAt,
}: {
    firstEventAt?: number;
    lastEventAt?: number;
}): JSX.Element => {
    if (!firstEventAt || !lastEventAt) {
        return (
            <div>
                <p className="font-semibold">Events</p>
                <div className="space-y-2">
                    <p>No events observed</p>
                </div>
            </div>
        );
    }
    return (
        <div>
            <p className="font-semibold">Events</p>
            <div className="space-y-2">
                <div className="">
                    <p className="">
                        The first observed event was at {new Date(firstEventAt / 1000000).toLocaleString()}.
                    </p>
                    <p className="mt-2 block">
                        The most recent observed event was at {new Date(lastEventAt / 1000000).toLocaleString()}.
                    </p>
                </div>
            </div>
        </div>
    );
};

const ProviderStorage = ({ storageUsed }: { storageUsed?: number }): JSX.Element => {
    return (
        <div>
            <p className="font-semibold">Storage</p>
            <div className="space-y-2">
                <div className="flex items-center justify-between">{formatBytes(storageUsed || 0)}</div>
            </div>
        </div>
    );
};

const ProviderStatus = ({ status }: { status: string }): JSX.Element => {
    let content = <></>;
    switch (status) {
        case 'ACTIVE':
            content = (
                <>
                    <p>Connected</p>
                    <CheckCircleIcon className="h-5 text-green-600" />
                </>
            );
            break;
        case 'READY':
            content = (
                <>
                    <p>Waiting</p>
                    <span className="mr-4 dot-flashing"></span>
                </>
            );
            break;
        case 'ERROR':
            content = (
                <>
                    <p className="align-bottom">Error in provider. Contact support if this message persists.</p>
                    <ExclamationTriangleIcon className="h-5 text-yellow-500" />
                </>
            );
            break;
        case 'CONFIG_ERROR':
            content = (
                <>
                    <p className="align-bottom">Error in provider configuration, please check all supplied values.</p>
                    <ExclamationTriangleIcon className="h-5 text-yellow-500" />
                </>
            );
            break;
        case 'DEGRADED':
            content = (
                <>
                    <p className="align-bottom">
                        Provider is configured correctly, but full capability is not available. <br />
                        Please see the message provided below for details.
                    </p>
                    <ExclamationTriangleIcon className="h-5 text-yellow-500" />
                </>
            );
            break;
        case 'UNKNOWN':
            content = (
                <>
                    <p className="align-bottom">Enabled</p>
                    <CheckCircleIcon className="h-5 text-blue-600" />
                </>
            );
            break;
        default:
            break;
    }

    return (
        <div>
            <p className="font-semibold">Status</p>
            <div className="space-y-2">
                <div className="flex items-center justify-between">{content}</div>
            </div>
        </div>
    );
};

const ProviderError = ({
    status,
    lastError,
    lastErrorAt,
}: {
    status: string;
    lastError?: string;
    lastErrorAt?: number;
}): JSX.Element => {
    const shouldShowError = !['ACTIVE'].includes(status);

    if (shouldShowError) {
        return (
            <div>
                <p className="font-semibold">Error Details</p>
                {lastErrorAt && lastErrorAt != 0 && (
                    <div className="text-gray-600">Updated at {epochInSecondsToLocaleString(lastErrorAt)}</div>
                )}
                <div className="w-1/2">{lastError} </div>
            </div>
        );
    }
    return <></>;
};

export default ProviderDetail;
