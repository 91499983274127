import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { SliderRail, Handle, Track, Tick } from './TimeWindowComponents';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
    subDays,
    addDays,
    format,
    startOfMinute,
    isBefore,
    startOfDay,
    addHours,
    subHours,
    getTime,
    isToday,
    endOfDay,
    getHours,
} from 'date-fns';
import { scaleTime } from 'd3-scale';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useQuery } from '@apollo/client';
import { GET_TENANT } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CalendarIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { TourContext } from './TourProvider';
import { classNames, getUsersTimezone } from 'Utilities/utils';
import { useDrag } from '@use-gesture/react';
import { ToastContext } from './ToastContext';

const now = startOfMinute(new Date());

export const formatTick = (ms: number): string => {
    const d = new Date(ms);

    // Deal with dates that don't fall on midnight
    if (getHours(d) != 0) {
        return format(d, 'HH:mm');
    }

    // Just show the date for the midnight ticks
    return format(d, 'MMM dd');
};

const oneHour = 1000 * 60 * 60;
const sixHours = oneHour * 6;

export const TimeWindowSelector = ({ dataLoading }: { dataLoading: boolean }): JSX.Element => {
    const tenantId = useTenant();

    const { dispatch, mapState } = useContext(IdentityMapContext);
    const { productTourState } = useContext(TourContext);
    const { dispatch: toastDispatch } = useContext(ToastContext);

    const [previousValues, setPreviousValues] = useState<number[]>([
        mapState.selectedTime[0],
        mapState.selectedTime[1],
    ]);

    const {
        loading: timeBoundsLoading,
        error,
        data,
    } = useQuery(GET_TENANT, { variables: { tenantId }, skip: !tenantId, pollInterval: 1800000 });

    const { useLocallyGeneratedData, maxTimeSelectorWindow } = useFlags();
    const maxTimeWindow = maxTimeSelectorWindow * oneHour || sixHours;

    // Shows five days behind, by default
    // If older date appears, extend the domain
    const oldestData = useMemo(() => (data ? new Date(data.getTenant.firstEventAt / 1000000) : undefined), [data]);

    // Set the beginning date on the time window selector.
    // If the default date (5 days prior to day) is before the earliest data available, use the date of earliest data.
    const min = startOfDay(
        oldestData && !isBefore(oldestData, mapState.timeWindowMin) ? oldestData : mapState.timeWindowMin,
    );
    const max = mapState.timeWindowMax;

    // Date ticks will return an array of date time epochs that are evenly spaced
    // between the oldest and newest data points based on the selected time range
    // The number of ticks return is suggested to be either:
    //
    // 1 - the number of days in the range (if less than 7)
    // 2 - at most 7 ticks, however this can actually become closer to 12-13 since
    //     the ticks() has some leeway to make sure the ticks are evenly spaced
    //
    const dateTicks = useMemo(() => {
        const tickCount = 7; //Math.min(7, Math.ceil((+max - +min) / oneDay));
        return scaleTime()
            .domain([min, max])
            .ticks(tickCount)
            .map((d) => +d);
    }, [min, max]);

    // adjust handles when they are out-of-bounds
    useEffect(() => {
        const previousLeftHandle = previousValues[0];
        const previousRightHandle = previousValues[1];

        const leftHandle = mapState.selectedTime[0];
        const rightHandle = mapState.selectedTime[1];

        let newLeftHandle = 0;
        let newRightHandle = 0;

        if (leftHandle < +min) {
            // slide to the left
            newLeftHandle = +min;

            toastDispatch({
                type: 'add-toast',
                message: `The start time of your query has been modified as the previous value was outside of the current date range.`,
                status: 'information',
                autoTimeout: true,
                timeoutTimer: 5,
            });

            if (rightHandle < +min) {
                // slide to the right
                newRightHandle = +addHours(min, maxTimeSelectorWindow);
            } else {
                newRightHandle = rightHandle;
            }

            if (previousLeftHandle == newLeftHandle) newLeftHandle++;

            // take it back now y'all
            setPreviousValues([newLeftHandle, newRightHandle]);
            dispatch({ type: 'set-selected-time', time: [newLeftHandle, newRightHandle] });
            return;
        }
        if (rightHandle > +max) {
            // slide to the right
            newRightHandle = +max;

            toastDispatch({
                type: 'add-toast',
                message: `The end time of your query has been modified as the previous value was outside of the current date range.`,
                status: 'information',
                autoTimeout: true,
                timeoutTimer: 5,
            });

            if (leftHandle > +max) {
                // slide to the left
                newLeftHandle = +subHours(max, maxTimeSelectorWindow);
            } else {
                newLeftHandle = leftHandle;
            }

            if (previousRightHandle == newRightHandle) newRightHandle++;

            // take it back now y'all
            setPreviousValues([newLeftHandle, newRightHandle]);
            dispatch({ type: 'set-selected-time', time: [newLeftHandle, newRightHandle] });
            return;
        }
    }, [dispatch, mapState.selectedTime, max, maxTimeSelectorWindow, min, previousValues, toastDispatch]);

    const onSlideEnd = useCallback(
        (values: number[]) => {
            const previousLeftHandle = mapState.selectedTime[0];
            const previousRightHandle = mapState.selectedTime[1];

            const leftHandle = values[0];
            const rightHandle = values[1];

            let newLeftHandle = 0;
            let newRightHandle = 0;

            //there is a time-bound from the backend data
            if (oldestData && leftHandle < +oldestData) {
                // backend data time-bound is younger than the selection
                newLeftHandle = +oldestData;
                newRightHandle = rightHandle;

                if (newRightHandle - newLeftHandle > maxTimeWindow) {
                    newRightHandle = newLeftHandle + maxTimeWindow;
                }

                // if we are already pinned to the oldest data, we must increase the value of the
                // left handle by 1ms to force a re-render of the time selector component
                // (else it will appear to the user that the time window is beyond the oldest data)
                if (previousLeftHandle == newLeftHandle) newLeftHandle++;

                setPreviousValues([newLeftHandle, newRightHandle]);
                dispatch({ type: 'set-selected-time', time: [newLeftHandle, newRightHandle] });
                return;
            }

            // constrain the maximum time range to whatever the feature flag is set to for this user
            // e.g. 2 hours, 6 hours, 24 hours, etc.
            if (rightHandle - leftHandle > maxTimeWindow) {
                console.debug('Max time window exceeded, constraining time window');

                if (previousLeftHandle == leftHandle) {
                    newLeftHandle = rightHandle - maxTimeWindow;
                    newRightHandle = rightHandle;

                    toastDispatch({
                        type: 'add-toast',
                        message: `The start time of your query has been modified to retain a maximum time window of ${maxTimeSelectorWindow} hours.`,
                        status: 'information',
                        autoTimeout: true,
                        timeoutTimer: 5,
                    });
                } else if (previousRightHandle == rightHandle) {
                    newLeftHandle = leftHandle;
                    newRightHandle = leftHandle + maxTimeWindow;

                    toastDispatch({
                        type: 'add-toast',
                        message: `The end time of your query has been modified to retain a maximum time window of ${maxTimeSelectorWindow} hours.`,
                        status: 'information',
                        autoTimeout: true,
                        timeoutTimer: 5,
                    });
                } else {
                    // this should not happen, but just in case
                    newLeftHandle = leftHandle;
                    newRightHandle = rightHandle;
                }

                setPreviousValues([newLeftHandle, newRightHandle]);
                dispatch({ type: 'set-selected-time', time: [newLeftHandle, newRightHandle] });
                return;
            }

            dispatch({ type: 'set-selected-time', time: [leftHandle, rightHandle] });
            setPreviousValues([leftHandle, rightHandle]);
        },
        [dispatch, mapState.selectedTime, maxTimeSelectorWindow, maxTimeWindow, oldestData, toastDispatch],
    );

    useEffect(() => {
        if (data && data.getTenant.firstEventAt !== 0) {
            dispatch({
                type: 'set-events-observed-times',
                firstEventAt: data.getTenant.firstEventAt,
                lastEventAt: data.getTenant.lastEventAt,
            });
        }
    }, [dispatch, data]);

    // Every 5 minutes, if the datetime bounds are set to today, we can inch the max time window forwards
    // so the user can detect there might be new data to load
    useEffect(() => {
        const interval = setInterval(() => {
            if (isToday(max)) {
                console.debug('Max time window selection lands on today, incrementing bounds to the latest time');
                const twoMinutesAgo = +startOfMinute(new Date()) - 120000;
                dispatch({ type: 'set-time-window-max', max: twoMinutesAgo });
            }
        }, 300000);
        return () => clearInterval(interval);
    }, [dispatch, max]);

    const railRef = useRef<HTMLDivElement>(null);

    const TIMELINE_SELECTION_RANGE = useMemo(() => {
        const range = max - +min;
        return range;
    }, [max, min]);

    const trackDragHandler = useDrag(({ initial: [initialX], xy: [currentX], first, last, memo }) => {
        const initialRange = memo || mapState.selectedTime;

        if (first) {
            console.log('Beginning timeline drag');
            dispatch({ type: 'set-timeline-dragging', dragging: true });
        }
        if (last) {
            console.log('Finished timeline drag');
            dispatch({ type: 'set-timeline-dragging', dragging: false });
        }

        if (railRef.current) {
            const { left: sliderLeft, width: sliderWidth } = railRef.current.getBoundingClientRect();

            const initialClickOffsetLeft = initialX - sliderLeft;
            const currentMousePositionOffsetLeft = currentX - sliderLeft;
            const initialPercentageWithinContainer = initialClickOffsetLeft / sliderWidth;
            const currentPercentageWithinContainer = currentMousePositionOffsetLeft / sliderWidth;
            const initialStep = TIMELINE_SELECTION_RANGE * initialPercentageWithinContainer;
            const currentStep = TIMELINE_SELECTION_RANGE * currentPercentageWithinContainer;
            const initialToCurrentStepDelta = Math.floor(currentStep - initialStep);

            let leftHandle = initialRange[0] + initialToCurrentStepDelta;
            let rightHandle = initialRange[1] + initialToCurrentStepDelta;

            // stop the handles from going past the min/max bounds
            if (leftHandle < +min) {
                leftHandle = +min;
            }
            if (leftHandle > max) {
                leftHandle = +max;
            }
            if (rightHandle < +min) {
                rightHandle = +min;
            }
            if (rightHandle > +max) {
                rightHandle = +max;
            }

            const newValues = [leftHandle, rightHandle];

            dispatch({
                type: 'set-selected-time',
                time: newValues,
                dragging: last ? false : true,
            });
            setPreviousValues(newValues);
        }

        return initialRange;
    });

    // If we don't know the time bounds, don't show the slider
    if ((!data || (data && data.getTenant.firstEventAt === 0)) && !useLocallyGeneratedData && !productTourState.run) {
        return <></>;
    }

    return (
        <div className="relative w-screen">
            <div
                className={classNames(
                    'flex flex-col items-center justify-center opacity-70',
                    dataLoading ? '' : 'hover:opacity-100',
                )}
            >
                {(timeBoundsLoading || dataLoading) && (
                    <div className="absolute top-3.5 space-x-1 flex justify-center">
                        <div
                            className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                            style={{ animationDelay: '0.15s' }}
                        ></div>
                        <div
                            className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                            style={{ animationDelay: '0.30s' }}
                        ></div>
                        <div
                            className="h-1 w-1 rounded-full bg-white opacity-25 wave"
                            style={{ animationDelay: '0.45s' }}
                        ></div>
                    </div>
                )}
                {error && <p className="absolute h-0 text-xs"></p>}
                <div className="w-5/12">
                    <div id="TimeWindowSelector" className="relative">
                        <Slider
                            mode={3}
                            step={1}
                            domain={[+min, +max]}
                            rootStyle={sliderStyle}
                            onSlideEnd={onSlideEnd}
                            values={[mapState.selectedTime[0], mapState.selectedTime[1]]}
                            disabled={dataLoading}
                        >
                            <div ref={railRef}>
                                <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
                            </div>
                            {!dataLoading && (
                                <Handles>
                                    {({ handles, activeHandleID, getHandleProps }) => (
                                        <div>
                                            {handles.map((handle) => (
                                                <Handle
                                                    key={handle.id}
                                                    handle={handle}
                                                    domain={[+min, +max]}
                                                    getHandleProps={getHandleProps}
                                                    disabled={false}
                                                    isActive={handle.id === activeHandleID}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </Handles>
                            )}
                            <Tracks right={false} left={false}>
                                {({ tracks, getTrackProps }) => (
                                    <div {...trackDragHandler()}>
                                        {tracks.map(({ id, source, target }) => (
                                            <div key={id}>
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={getTrackProps}
                                                    disabled={true}
                                                    dragging={mapState.timelineDragging}
                                                    dateText={`${format(
                                                        new Date(mapState.selectedTime[0]),
                                                        'MMM dd HH:mm',
                                                    )} - ${format(new Date(mapState.selectedTime[1]), 'MMM dd HH:mm')}`}
                                                    timezoneText={`(${getUsersTimezone()})`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Tracks>
                            <Ticks values={dateTicks}>
                                {({ ticks }) => (
                                    <div>
                                        {ticks.map((tick) => (
                                            <Tick key={tick.id} tick={tick} count={ticks.length} format={formatTick} />
                                        ))}
                                    </div>
                                )}
                            </Ticks>
                        </Slider>
                        <div className="absolute -right-10 -top-3" id="date-selector">
                            <div
                                onClick={() => {
                                    dispatch({ type: 'toggle-date-time' });
                                }}
                            >
                                <CalendarIcon className="h-5 w-5 text-gray-500 cursor-pointer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {mapState.dateTimeOpen && (
                <div className="absolute bottom-14 left-[50%] transform translate-x-[-50%]">
                    <label className="text-xs block text-center mb-2">Select a date range</label>
                    <div className="relative flex justify-center items-start">
                        <DatePicker
                            selected={new Date(min)}
                            onChange={(date) =>
                                date && dispatch({ type: 'set-time-window-min', min: getTime(startOfDay(date)) })
                            }
                            minDate={oldestData}
                            maxDate={subDays(max, 0)}
                            dateFormat="MMM d, yyyy"
                            className="w-32 bg-gray-700 text-white text-xs rounded-l-md outline-none focus:border-blue-500 text-center pr-5 relative focus:z-10"
                        />
                        <div className="p-1 rounded-full bg-gray-800 border border-gray-500 text-gray-300 z-20 absolute top-[7px]">
                            <ArrowRightIcon className="h-3 w-3" />
                        </div>
                        <DatePicker
                            selected={new Date(max)}
                            onChange={(date) =>
                                date && dispatch({ type: 'set-time-window-max', max: getTime(endOfDay(date)) })
                            }
                            minDate={addDays(min, 0)}
                            maxDate={now}
                            dateFormat="MMM d, yyyy"
                            className="w-32 bg-gray-700 text-white text-xs rounded-r-md outline-none focus:border-blue-500 text-center pl-5 relative focus:z-10 left-[-1px]"
                        />
                    </div>
                </div>
            )}
            <div
                data-test="time-window-test-adjuster"
                onClick={() => {
                    // Dispatch an event to set the time window to 4 hours offset by 4 hours
                    dispatch({
                        type: 'set-selected-time',
                        time: [+subHours(new Date(), 8), +subHours(new Date(), 4)],
                    });
                }}
            />
        </div>
    );
};

const sliderStyle = {
    // Give the slider some width
    position: 'relative',
    width: '100%',
};
