import { useState } from 'react';
import { getDisplayName, getUsersTimezone, tagNameLookup } from 'Utilities/utils';
import { ListItem } from '../Library/List';
import Select from 'react-select';
import {
    GET_ENTITY_LIST,
    GET_ENTITY_RISKY,
    GET_ENTITY_BY_NEIGHBOR_COUNT,
    GET_ENTITY_BY_STATUS,
    GET_ENTITY_LAST_CREATED,
} from 'Graph/queries';
import { format, formatDistanceToNow } from 'date-fns';
import { TimeIntervalProps } from 'Types/types';
import { TargetsCountChart } from './Charts/TargetsCountChart';
import { TargetsOutcomeChart } from './Charts/TargetsOutcomeChart';
import { TargetsTagsChart } from './Charts/TargetsTagsChart';
import { SkipToTime } from 'Library/SkipToTime';
import { AccessCountItem, BasePanel, CountItem } from './Library/BasePanel';
import { Node } from 'Types/types';
import { NodeAvatar } from 'Library/NodeAvatar';
import { TargetStats } from './Stats/TargetStats';
import { DashboardTabProps } from './DashboardModalTypes';

const chartOptions = [
    { value: 'count', label: 'Target Count' },
    { value: 'tag', label: 'Target Count By Tag' },
    { value: 'outcome', label: 'Access Counts by Outcome' },
];

export const Targets = ({
    open,
    startDate,
    endDate,
    selectedStartDate,
    selectedEndDate,
    interval,
    onBarClick,
    selectedBar,
}: DashboardTabProps) => {
    const [chartType, setChartType] = useState(chartOptions[0]);

    if (!open) return <></>;

    return (
        <>
            <div className=" bg-gray-700 p-4 rounded-md">
                <div className="flex items-center justify-between mb-3">
                    <div>
                        <h3 className="text-xs text-gray-400 flex  my-3 ">
                            <span className="uppercase tracking-widest ">
                                {format(selectedStartDate, 'EEE do, HH:mm')} -{' '}
                                {format(selectedEndDate, 'EEE do, HH:mm')}{' '}
                                <span className="text-gray-500">({getUsersTimezone()})</span>
                            </span>
                            <SkipToTime startDate={selectedStartDate} endDate={selectedEndDate} />
                        </h3>
                    </div>

                    <div className="flex items-center space-x-2">
                        <span className="text-xs text-gray500 flex mb-3" data-test="chart-selector">
                            <Select
                                className="w-48"
                                classNamePrefix="select-xs"
                                name="dataType"
                                options={chartOptions}
                                value={chartType}
                                onChange={(option) => {
                                    if (option) {
                                        setChartType(option);
                                    }
                                }}
                            />
                        </span>
                    </div>
                </div>

                <div className="">
                    {chartType.value === 'count' && (
                        <TargetsCountChart
                            startDate={startDate}
                            endDate={endDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                    )}
                    {chartType.value === 'tag' && (
                        <TargetsTagsChart
                            startDate={startDate}
                            endDate={endDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                    )}
                    {chartType.value === 'outcome' && (
                        <TargetsOutcomeChart
                            startDate={startDate}
                            endDate={endDate}
                            interval={interval}
                            onBarClick={onBarClick}
                            selectedBar={selectedBar}
                        />
                    )}
                </div>
            </div>

            <div className="flex flex-wrap sm:flex-nowrap justify-around items-center bg-gray-700 pt-2 pb-1 rounded-md mt-4 md:h-24">
                <TargetStats
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    interval={interval}
                />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 text-xs mt-4">
                <TopActivityTargets startDate={selectedStartDate} endDate={selectedEndDate} interval={interval} />
                <TargetsWithMostIdentities
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    interval={interval}
                />
                <RecentlyDiscoveredTargets
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    interval={interval}
                />
                <TopFailingTargets startDate={selectedStartDate} endDate={selectedEndDate} interval={interval} />
                <TopRiskyTargets startDate={selectedStartDate} endDate={selectedEndDate} interval={interval} />
            </div>
        </>
    );
};

export const TopFailingTargets = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Targets with Most Failures"
            className="bg-gray-700 row-span-1 col-span-1 rounded-md p-3"
            startDate={startDate}
            endDate={endDate}
            query={GET_ENTITY_BY_STATUS}
            queryName="getEntityByStatus"
            emptyIsPositive={true}
            variables={{
                entityType: 'STATS_ENTITY_TYPE_TARGET',
                status: 'OUTCOME_DENY',
                order: 'STATS_ORDER_DESC',
            }}
            resolveNodes={true}
            renderItem={(idx, item: CountItem, node: Node, isNodeInExplorer, addNodeToExplorer) => {
                return (
                    <ListItem
                        key={idx}
                        node={node}
                        title={getDisplayName(node)}
                        description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                        secondTitle="Events"
                        secondDescription={`${item.count}`}
                        thirdTitle="Failure"
                        thirdDescription={`${item.rate}%`}
                        thirdDescriptionColor="text-gray-500"
                        highlighted={isNodeInExplorer}
                        icon={<NodeAvatar node={node} />}
                        onClick={() => {
                            if (addNodeToExplorer) {
                                addNodeToExplorer(node, false, true);
                            }
                        }}
                    />
                );
            }}
        />
    );
};

export const TopActivityTargets = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Target Activity"
            startDate={startDate}
            endDate={endDate}
            height="h-200"
            defaultLimit={13}
            query={GET_ENTITY_LIST}
            queryName="getEntityList"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_TARGET',
                order: 'STATS_ORDER_DESC',
            }}
            resolveNodes={true}
            renderItem={(idx, item: AccessCountItem, node: Node, isNodeInExplorer, addNodeToExplorer) => {
                return (
                    <ListItem
                        key={idx}
                        node={node}
                        title={getDisplayName(node)}
                        description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                        secondTitle="Events"
                        secondDescription={item.accessCount.toString()}
                        highlighted={isNodeInExplorer}
                        icon={<NodeAvatar node={node} />}
                        onClick={() => {
                            if (addNodeToExplorer) {
                                addNodeToExplorer(node, false, true);
                            }
                        }}
                    />
                );
            }}
        />
    );
};
type RiskyCountItem = {
    id: string;
    riskyCount: string;
};

export const TopRiskyTargets = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Risk-Associated Target Activity"
            className="bg-gray-700 rounded-md row-span-1 col-span-1 p-3"
            startDate={startDate}
            endDate={endDate}
            query={GET_ENTITY_RISKY}
            queryName="getEntityRisky"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_TARGET',
                order: 'STATS_ORDER_DESC',
            }}
            resolveNodes={true}
            emptyIsPositive={true}
            renderItem={(idx, item: RiskyCountItem, node: Node, isNodeInExplorer, addNodeToExplorer) => {
                return (
                    <ListItem
                        key={idx}
                        node={node}
                        title={getDisplayName(node)}
                        description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                        secondTitle="Risky Events"
                        secondDescription={item.riskyCount.toString()}
                        highlighted={isNodeInExplorer}
                        icon={<NodeAvatar node={node} />}
                        onClick={() => {
                            if (addNodeToExplorer) {
                                addNodeToExplorer(node, false, true);
                            }
                        }}
                    />
                );
            }}
        />
    );
};

type NeighborCountItem = {
    id: string;
    neighborCount: number;
};

export const TargetsWithMostIdentities = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Targets Accessed By Most Identities"
            className="bg-gray-700 rounded-md row-span-1 col-span-1 p-3"
            startDate={startDate}
            endDate={endDate}
            query={GET_ENTITY_BY_NEIGHBOR_COUNT}
            queryName="getEntityByNeighborCount"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_TARGET',
                neighborType: 'STATS_ENTITY_TYPE_IDENTITY',
                order: 'STATS_ORDER_DESC',
            }}
            resolveNodes={true}
            renderItem={(idx, item: NeighborCountItem, node: Node, isNodeInExplorer, addNodeToExplorer) => {
                return (
                    <ListItem
                        key={idx}
                        node={node}
                        title={getDisplayName(node)}
                        description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                        secondTitle="Identities"
                        secondDescription={item.neighborCount.toString()}
                        highlighted={isNodeInExplorer}
                        icon={<NodeAvatar node={node} />}
                        onClick={() => {
                            if (addNodeToExplorer) {
                                addNodeToExplorer(node, false, true);
                            }
                        }}
                    />
                );
            }}
        />
    );
};

type RecentlyCreatedItem = {
    id: string;
    creationDate: number;
};

const RecentlyDiscoveredTargets = ({ startDate, endDate }: TimeIntervalProps) => {
    return (
        <BasePanel
            title="Recently Discovered Targets"
            className="bg-gray-700 rounded-md row-span-1 col-span-1 p-3"
            startDate={startDate}
            endDate={endDate}
            query={GET_ENTITY_LAST_CREATED}
            queryName="getEntityLastCreated"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_TARGET',
                order: 'STATS_ORDER_DESC', // DESC: date is a timestamp, so DESC goes from most recently created to oldest
                limit: 5,
            }}
            resolveNodes={true}
            renderItem={(idx, item: RecentlyCreatedItem, node: Node, isNodeInExplorer, addNodeToExplorer) => {
                return (
                    <ListItem
                        key={idx}
                        node={node}
                        title={getDisplayName(node)}
                        description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                        secondTitle=""
                        secondDescription={`${formatDistanceToNow(item.creationDate)}`}
                        highlighted={isNodeInExplorer}
                        icon={<NodeAvatar node={node} />}
                        onClick={() => {
                            if (addNodeToExplorer) {
                                addNodeToExplorer(node, false, true);
                            }
                        }}
                    />
                );
            }}
        />
    );
};
