import { useState } from 'react';
import { WelcomeComponent, Header, NextButton } from './WelcomeComponents';
import { DynamicProvider } from 'Types/types';
import {
    AvailableDynamicProviders,
    EditDynamicProvider,
    NewDynamicProvider,
} from 'Configuration/Providers/DynamicProviders';
import { Providers } from 'Configuration/Providers/Providers';
import { useUserPermissions } from 'Utilities/UserPermissions';

const CreateProviders = ({ prevStep, nextStep }: WelcomeComponent): JSX.Element => {
    const [requestedNewProvider, setRequestedNewProvider] = useState<DynamicProvider>();
    const [requestedEditProviderId, setRequestedEditProviderId] = useState<string | undefined>();
    const [requestedEditProviderName, setRequestedEditProviderName] = useState<string | undefined>();

    const editProvider = (providerId: string, providerName: string) => {
        setRequestedEditProviderId(providerId);
        setRequestedEditProviderName(providerName);
    };

    const { userCan } = useUserPermissions();

    const canCreateProviders = userCan('create', '/provider');

    return (
        <div>
            <Header title="Connect Providers" previous={prevStep} />
            <div className="mt-6 text-xs text-gray-300">
                <ol className="space-y-6">
                    {!requestedNewProvider && (
                        <div>
                            <li>Choose a provider to connect</li>
                            <div className="mt-6 grid grid-cols-3 gap-3 items-stretch justify-center text-gray-100">
                                <AvailableDynamicProviders handleClick={setRequestedNewProvider} />
                            </div>
                        </div>
                    )}

                    {requestedNewProvider && !requestedEditProviderId && canCreateProviders && (
                        <NewDynamicProvider
                            provider={requestedNewProvider}
                            onClose={() => setRequestedNewProvider(undefined)}
                        />
                    )}

                    {requestedEditProviderId &&
                        requestedEditProviderName &&
                        !requestedNewProvider &&
                        canCreateProviders && (
                            <EditDynamicProvider
                                providerName={requestedEditProviderName}
                                providerId={requestedEditProviderId}
                                onClose={() => {
                                    setRequestedEditProviderId(undefined);
                                    setRequestedEditProviderName(undefined);
                                }}
                            />
                        )}

                    {!requestedNewProvider && !requestedEditProviderId && <Providers onEdit={editProvider} />}
                </ol>
            </div>
            <NextButton title="Next Step" next={nextStep} />
        </div>
    );
};

export default CreateProviders;
