import { Action, initialTourState, reducer, TourState } from 'Map/State/TourReducer';
import { createContext, useMemo, useReducer } from 'react';

export type TourContextProps = {
    productTourState: TourState;
    dispatch: React.Dispatch<Action> | (() => undefined);
};

export const TourContext = createContext<TourContextProps>({
    productTourState: initialTourState,
    dispatch: () => undefined,
});

type TourProviderProps = {
    children: JSX.Element;
};

export const TourProvider = ({ children }: TourProviderProps): JSX.Element => {
    const [productTourState, dispatch] = useReducer(reducer, initialTourState);
    const contextValue = useMemo(() => {
        return { productTourState, dispatch };
    }, [productTourState, dispatch]);
    return <TourContext.Provider value={contextValue}>{children}</TourContext.Provider>;
};
