import { DoubleZeroTourStep } from 'Map/State/TourTypes';

export const tourSteps: DoubleZeroTourStep[] = [
    {
        target: '#IdentityMap',
        title: 'Welcome to SailPoint Identity Risk',
        content: (
            <div>
                This is the Identity Map, an interactive visualization of your organization, where you can see the
                connections and interactions between Identities and Target resources.
                <br />
                <br />
                Each unique triplet of Actor, Client Application and Device are combined to form a SailPoint Identity
                Risk Identity.
                <br />
                <br />
                Access events are streamed, analyzed, tied to SailPoint Identity Risk Identities, and the resulting
                trust graph is visualized.
                <br />
                <br />
                Follow on to learn more about the power of the Identity Map...
            </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        disableOverlayClose: true,
        disableOverlay: true,
        placement: 'center',
        showSkipButton: false,
        styles: {
            tooltip: {
                width: '400px',
            },
        },
    },
    {
        target: '#NodeCanvasPointer',
        title: 'Actors',
        content: (
            <div>
                An Actor is a representation of credentials in use.
                <br />
                <br />
                The credentials could be those assigned to a human, for example a username and password account, or to a
                machine, such as an API key and secret.
                <br />
                <br />
                Actors are always connected to Identities.
            </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        disableOverlayClose: true,
        nodeName: 'Star Patrick',
        showSkipButton: false,
        hideBackButton: true,
    },
    {
        target: '#NodeCanvasPointer',
        title: 'Devices',
        content: (
            <div>
                Each Device, paired with an Actor is connected to an Identity and shows the machine being used in
                conjunction with the actors credentials to access a Target resource.
            </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        disableOverlayClose: true,
        nodeName: 'Mobile',
        showSkipButton: false,
    },
    {
        target: '#NodeCanvasPointer',
        title: 'Applications',
        content: (
            <div>
                Each Client Application is paired with an Actor and Device and shows the application being used to
                access the Target resource, for example this could be Microsoft Outlook or the Chrome browser.
            </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        disableOverlayClose: true,
        nodeName: 'Safari v15.2',
        showSkipButton: false,
    },
    {
        target: '#NodeCanvasPointer',
        title: 'Identities',
        content: (
            <div>
                Identities are the link between all of the other node types, and they represent the unique triplet of
                Actor, Client Application and Device.
                <br />
                <br />
                An Identity may be used to access one or more Target resources.
            </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        disableOverlayClose: true,
        nodeName: 'Star Patrick on Desktop',
        showSkipButton: false,
    },
    {
        target: '#NodeCanvasPointer',
        title: 'Targets',
        content: (
            <div>
                The Target resource is the entity within your organization that is being accessed.
                <br />
                <br />
                Target resources come in many forms, but the most common are software services, collaboration tools,
                shared drives, files, and other data.
            </div>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        disableOverlayClose: true,
        nodeName: 'Github Enterprise Cloud - Organization',
        showSkipButton: false,
    },
    {
        target: '#TutorialAnchorPoint',
        title: 'Move Around!',
        content: (
            <div>
                You can move around the map by clicking and dragging with the mouse, and zooming with the scroll wheel.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        disableOverlay: true,
        placement: 'left',
        showSkipButton: false,
        stepName: 'Move Around',
    },
    {
        target: '#NodeCanvasPointer',
        title: 'Click on a Node',
        content: (
            <div>
                There are also many different ways you can interact with nodes on the map.
                <br />
                <br />
                For example, you can click on a node to see its details.
                <br />
                <br />
                Click on "Star Patrick" to continue.
            </div>
        ),
        disableOverlayClose: true,
        hideCloseButton: true,
        hideBackButton: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideFooter: true,
        spotlightPadding: 50,
        nodeName: 'Star Patrick',
        stepName: 'Click',
        showSkipButton: false,
    },
    {
        target: '#NodeCanvasPointer',
        title: 'Drag, and Lock',
        content: (
            <div>
                You can drag the nodes around to different positions.
                <br />
                <br />
                When a node is manually positioned, it stays locked in placed and is not affected by the map forces.
                <br />
                <br />
                Please drag the actor, "Star Patrick" to lock in place.
            </div>
        ),
        disableOverlayClose: true,
        hideCloseButton: true,
        hideBackButton: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideFooter: true,
        spotlightPadding: 50,
        nodeName: 'Star Patrick',
        stepName: 'Drag',
        showSkipButton: false,
    },
    {
        target: '#Explorer',
        title: 'Node Explorer Panel',
        content: (
            <div>
                The Node Explorer Panel shows a list of all nodes that have been selected on the map.
                <br />
                <br />
                Each node has a name, a list of informational tags, and available actions.
                <br />
                <br />
                Let's learn more about the Node Explorer Panel...
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        placement: 'right',
        disableOverlay: true,
        showSkipButton: false,
    },
    {
        target: '#Explorer',
        title: 'Expand Node Details',
        content: (
            <div>If you wish to see which nodes are connected to the node selected, you can click on the panel.</div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'right',
        disableOverlay: true,
        showSkipButton: false,
    },
    {
        target: '#Unlock',
        title: 'Unlocking',
        content: (
            <div>
                This button here only shows up if you've locked the node in question through dragging and placing on the
                map.
                <br />
                <br />
                Click to unlock the node and allow it to be moved around.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'right',
        showSkipButton: false,
    },
    {
        target: '#TutorialAnchorPoint',
        title: 'Multi-select Nodes',
        content: (
            <div>
                If you want to open multiple nodes at once, you can shift+click and drag on the fabric.
                <br />
                <br />
                Alternatively, you may click each node one-by-one to add to the selection.
                <br />
                <br />
                Select 5 nodes from the map to continue.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        placement: 'left',
        disableOverlay: true,
        showSkipButton: false,
        stepName: 'Multi-select Nodes',
        hideFooter: true,
    },
    {
        target: '#Explorer',
        title: 'Scrolling through Node Explorer',
        content: <div>You can have many nodes selected at once, and you can scroll within the panel.</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'right',
        disableOverlay: true,
        showSkipButton: false,
        floaterProps: {
            disableAnimation: true,
        },
    },
    {
        target: '#Explorer',
        title: 'Removing a Node',
        content: (
            <div>
                You can remove individual nodes by clicking the - button, or the entire list by clicking on the
                background.
                <br />
                <br />
                If you accidentally remove a node (or clear the entire list), you can click the "Undo" button under the
                "Controls" widget or use the keyboard shortcut "U"
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'right',
        disableOverlay: true,
        showSkipButton: false,
        floaterProps: {
            disableAnimation: true,
        },
    },
    {
        target: '#Minimize',
        title: 'Toggling the Node Explorer Panel',
        content: <div>This tab on the right can be used to minimize the set of opened nodes, and reopen it. </div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'right',
        showSkipButton: false,
    },
    {
        target: '#TimeWindowSelector',
        title: 'Time Selector',
        content: (
            <div>
                This is the Time Window Selector. It shows you a snapshot of your organization’s connections across your
                services at a specified time and date range. You can click and drag the handles to adjust the time range
                you would like to view.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'top',
        showSkipButton: false,
    },
    {
        target: '#date-selector',
        title: 'Calendar',
        content: (
            <div>
                This button opens a date range picker where you can alter the dates of the range of time you want to
                navigate.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'top',
        showSkipButton: false,
    },
    {
        target: '#Layers',
        title: 'Layers',
        content: (
            <div>
                This is the Layers panel; layers can provide additional information on the map. Hover over it to see the
                options. You can also click the button to lock the panel open. (Please click to proceed)
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideFooter: true,
        placement: 'right',
        stepName: 'Layers Button',
        showSkipButton: false,
    },
    {
        target: '#Labels',
        title: 'Labels',
        content: (
            <div>
                Next are the buttons for toggling the layers; This one is for Labels. Labels show extra information
                about a node such as operating system for devices
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        placement: 'top',
        showSkipButton: false,
    },
    {
        target: '#Events',
        title: 'Events',
        content: (
            <div>
                Events will highlight connections between Targets and their Identities and provides visualization of
                policy trust levels
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'top',
        showSkipButton: false,
    },
    {
        target: '#Names',
        title: 'Names',
        content: <div>Enables node names to be displayed on the map above the nodes</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'top',
        showSkipButton: false,
    },
    {
        target: '#Posture',
        title: 'Posture',
        content: (
            <div>Shows the level of trust of Actors, Identities and Devices that are connected to your services</div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'top',
        showSkipButton: false,
    },
    {
        target: '#NodeTypes',
        title: 'Enable Different Nodes',
        content: (
            <div>
                This is the display panel, here you can toggle which types of nodes you would like displayed on the map;
                you can hover or click to lock this panel as well.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        placement: 'top',
        showSkipButton: false,
        stepName: 'Node Types Button',
    },
    {
        target: '#Controls',
        title: 'Map Controls',
        content: (
            <div>
                This is the controls panel/toolbox. Here you can explore different functions that the fabric has. Please
                click to continue
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        hideFooter: true,
        placement: 'left',
        stepName: 'Toolbox Open',
        showSkipButton: false,
    },
    {
        target: '#CSV',
        title: 'CSV',
        content: <div>This button will generate a CSV file of all of your currently selected nodes' data</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        placement: 'left',
        showSkipButton: false,
    },
    {
        target: '#Lock',
        title: 'Lock Selected',
        content: <div>The lock button will lock all currently selected nodes</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'left',
        showSkipButton: false,
        floaterProps: {
            disableAnimation: true,
        },
    },
    {
        target: '#UnlockAll',
        title: 'Unlock All',
        content: <div>The release button can be used to unlock all the locked nodes on the map at once</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'left',
        showSkipButton: false,
        floaterProps: {
            disableAnimation: true,
        },
    },
    {
        target: '#Box',
        title: 'Box View',
        content: (
            <div>
                This button takes all of the Targets and aligns them in a grid so you can easily see which Targets are
                on the map (Can be undone with release)
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'left',
        showSkipButton: false,
        floaterProps: {
            disableAnimation: true,
        },
    },
    {
        target: '#Center',
        title: 'Center',
        content: <div>This button brings you back to the center of the map</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'left',
        showSkipButton: false,
        floaterProps: {
            disableAnimation: true,
        },
    },
    {
        target: '#Zoom',
        title: 'Zoom',
        content: (
            <div>
                This can be used to reset the map’s zoom and recenter it so you can see the entirety of the map on your
                screen
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'left',
        showSkipButton: false,
        floaterProps: {
            disableAnimation: true,
        },
    },

    {
        target: '#Undo',
        title: 'Undo',
        content: (
            <div>
                If you accidentally close the node panel for the selected nodes, you can hit undo to reopen and select
                them.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'left',
        showSkipButton: false,
        floaterProps: {
            disableAnimation: true,
        },
    },
    {
        target: '#RefinePanel',
        title: 'Refine',
        content: (
            <div>
                This is the refine panel, here you can filter or group nodes by specific properties (click to continue)
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        hideFooter: true,
        placement: 'top',
        stepName: 'Refine Panel Open',
        showSkipButton: false,
    },
    {
        target: '#Grouping',
        title: 'Grouping',
        content: (
            <div>
                This is the grouping section. You can group nodes of the same type based on different parameters. Click
                on the tabs to change which node type you would like to group by.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        placement: 'bottom',
        showSkipButton: false,
    },
    {
        target: '#Filters',
        title: 'Filtering',
        content: (
            <div>
                This is the filtering section. Hit the add filter button to add a new filter and fill out the different
                fields
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        placement: 'bottom',
        showSkipButton: false,
    },
    {
        target: '#Filters',
        title: 'Filtering',
        content: (
            <div>
                <ol className="list-decimal">
                    <li>
                        <header>Node Type: </header>The node type you wish to filter by
                    </li>
                    <br />
                    <li>
                        <header>Property: </header>A specific field of the node
                    </li>
                    <br />
                    <li>
                        <header>Matching case: </header>Type of filter expression (You can also pick regex for some
                        options)
                    </li>
                    <br />
                    <li>
                        <header>Filter Value: </header> What value you want to filter for (you can put regex in here if
                        the matching case chosen is regex)
                    </li>
                    <br />
                    <li>
                        Hit the apply button to use the filter created on your map. You can apply up to 3 filters at
                        once. Hit the minus icon to remove one of the filters.
                    </li>
                </ol>
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        placement: 'bottom',
        showSkipButton: false,
    },
    {
        target: '#CommandPalette',
        title: 'Command Palette',
        content: (
            <div>
                This is the command palette, which can be used to find certain nodes on the map or to apply a predefined
                filter. You can click here and type in a keyword to display a list of all related nodes. If you click on
                or press Enter on one of the results, it will select the node on the map. (Click to continue)
            </div>
        ),
        disableOverlayClose: true,
        hideCloseButton: true,
        hideBackButton: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideFooter: true,
        stepName: 'Command Palette Open',
        showSkipButton: false,
    },
    {
        target: '#NodeCounts',
        title: 'Command Palette Node Counts',
        content: (
            <div>
                These buttons show the count of how many of each node type are currently present on the fabric. Clicking
                one lets you select all nodes of a specific type.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: false,
        stepName: 'Command Palette Node Counts',
        showSkipButton: false,
    },
    {
        target: '#RefinementFilters',
        title: 'Command Palette Refinements',
        content: (
            <div>
                This section has different insights (predefined filters) you can use to filter your map or display
                certain data Here you can select one of the pre-made filters to instantly apply the filter settings. You
                can also click create insight to open the refine panel, or clear all to reset the filters on your map.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        stepName: 'Command Palette Refinements',
        showSkipButton: false,
    },
    {
        target: '#DeviceFilter',
        title: 'Command Palette Refinement',
        content: <div>Let's try clicking on this refinement option to apply this filter!</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideFooter: true,
        placement: 'right',
        stepName: 'Command Palette Filter',
        showSkipButton: false,
    },
    {
        target: '#ViewsHeader',
        title: 'Views',
        content: <div>This button opens the views panel! Click to continue</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideFooter: true,
        placement: 'bottom',
        stepName: 'ViewsButton',
        showSkipButton: false,
    },
    {
        target: '#Views',
        title: 'Views',
        content: (
            <div>
                This is the views panel. Here you can create a snapshot of the fabric with your given settings and
                filters, and reload it any time you want! With the filters and settings that we have changed so far,
                we’re going to save a view by giving it a name in this box, and then hitting the save button. Now it
                should be in your list of saved views!
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        disableOverlay: true,
        showSkipButton: false,
    },
    {
        target: '#RefinePanel',
        title: 'Refine',
        content: <div>Now let's reopen the Refine panel and see what's changed!</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        disableOverlay: true,
        hideFooter: true,
        spotlightClicks: true,
        stepName: 'Reopen Panel',
        placement: 'left',
        showSkipButton: false,
    },
    {
        target: '#Filters',
        title: 'New Filter',
        content: (
            <div>
                Now our selected insight filter is here. Hit the minus button on the filter then click apply to clear
                our filter.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        disableOverlay: true,
        spotlightClicks: true,
        placement: 'bottom',
        showSkipButton: false,
    },
    {
        target: '#ViewsHeader',
        title: 'Views',
        content: <div>Now let's reopen the Views panel</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        hideFooter: true,
        placement: 'bottom',
        stepName: 'ViewsButton',
        showSkipButton: false,
    },
    {
        target: '#Views',
        title: 'Views',
        content: (
            <div>
                Click on the view you created, it should reapply the filters and the map should look exactly the same as
                it did when we saved it. Views can save the time selected, filters, layers and display panel settings,
                node positions on the map and if they are locked.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        disableOverlay: true,
        spotlightClicks: true,
        showSkipButton: false,
    },
    {
        target: '#ConfigButton',
        title: 'Configuration',
        content: <div>This button opens the config modal, click here to continue</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        hideCloseButton: true,
        spotlightClicks: true,
        hideFooter: true,
        stepName: 'Config Open',
        showSkipButton: false,
    },
    {
        target: '#Config',
        title: 'Configuration',
        content: (
            <div>
                This is the config modal, which mostly contains administrative settings and functionality. There are
                many different panels available, but for now we’re going to focus on only the users and providers panel.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        hideBackButton: true,
        spotlightClicks: true,
        placement: 'bottom',
        showSkipButton: false,
    },
    {
        target: '#Users',
        title: 'Users Panel',
        content: (
            <div>
                Click here to access the user panel. This panel lists all of the users connected to your account, and
                lets you manage them. Here you can already see your own user that was created when you signed up!
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        hideBackButton: true,
        hideFooter: true,
        spotlightClicks: true,
        placement: 'left',
        stepName: 'Users Nav',
        showSkipButton: false,
    },
    {
        target: '#Config',
        title: 'Users Panel',
        content: (
            <div>
                We can make new users to with the add new user button. It will prompt you for values for their full
                name, email, and a role from the dropdown menu. When every field is complete, hit save and your new user
                will be created! If we want to, you can also delete users using this button. (You will not be able to
                delete the first user in the list which is yourself!)
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        hideBackButton: true,
        spotlightClicks: true,
        placement: 'center',
        showSkipButton: false,
    },
    {
        target: '#Providers',
        title: 'Providers Panel',
        content: (
            <div>
                Next let’s take a look at the providers panel, which you can open by clicking here. The providers panel
                lets you connect different targets to your account so you can see its connections on the fabric.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        hideBackButton: true,
        spotlightClicks: true,
        hideFooter: true,
        stepName: 'Providers Nav',
        placement: 'left',
        showSkipButton: false,
    },
    {
        target: '#Config',
        title: 'Providers Panel',
        content: (
            <div>
                We can add a new provider by clicking on one of the buttons in this grid. It will then have you fill in
                all the fields and once that’s done, you can save it and your provider will be set up. If you want to,
                you can also remove providers connected to your organization by clicking on the now connected
                application and finding the delete button.
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'center',
        showSkipButton: false,
    },
    {
        target: '#Keybindings',
        title: 'Keybindings Panel',
        content: <div>If we click here, we can open the Keybindings panel.</div>,
        disableOverlayClose: true,
        disableBeacon: true,
        hideBackButton: true,
        spotlightClicks: true,
        hideFooter: true,
        stepName: 'Keybinds Nav',
        placement: 'left',
        showSkipButton: false,
    },
    {
        target: '#Config',
        title: 'Keybindings Panel',
        content: (
            <div>
                This panel provides a list of all the different Hotkeys available throughout the UI. It can also serve
                as a helpful list of different functionalities throughout the Fabric as well!
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: true,
        placement: 'center',
        hideBackButton: true,
        showSkipButton: false,
    },
    {
        target: '#IdentityMap',
        title: 'Conclusion',
        content: (
            <div>
                This concludes the tutorial! Please check the Profile Panel in the Configuration Modal if you wish to
                view it again
            </div>
        ),
        disableOverlayClose: true,
        disableBeacon: true,
        hideBackButton: true,
        spotlightClicks: true,
        placement: 'center',
        showSkipButton: false,
        stepName: 'Last Step',
    },
];
