import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { ControlButton, Controls, Edge, Node, isNode } from 'reactflow';

export const getTracedNode = (node: Node, nodes: Node[], edges: Edge[], isIncomer: boolean) => {
    if (!isNode(node)) {
        return [];
    }

    const tracedEdgeIds = edges
        .filter((e) => {
            const id = isIncomer ? e.target : e.source;

            return id === node.id;
        })
        .map((e) => (isIncomer ? e.source : e.target));

    return nodes.filter((n) =>
        tracedEdgeIds
            .map((id) => {
                const matches = /([\w-^]+)__([\w-]+)/.exec(id);
                if (matches === null) {
                    return id;
                }

                return matches[1];
            })
            .includes(n.id),
    );
};

export const getAllTracedNodes = (
    node: Node,
    nodes: Node[],
    edges: Edge[],
    prevTraced = [] as Node[],
    isIncomer: boolean,
) => {
    const tracedNodes = getTracedNode(node, nodes, edges, isIncomer);

    return tracedNodes.reduce((memo, tracedNode) => {
        memo.push(tracedNode);

        if (prevTraced.findIndex((n) => n.id == tracedNode.id) === -1) {
            prevTraced.push(tracedNode);

            getAllTracedNodes(tracedNode, nodes, edges, prevTraced, isIncomer).forEach((foundNode) => {
                memo.push(foundNode);

                if (prevTraced.findIndex((n) => n.id == foundNode.id) === -1) {
                    prevTraced.push(foundNode);
                }
            });
        }

        return memo;
    }, [] as Node[]);
};

// const highlightPath = useCallback(
//     (node: Node, nodes?: Node[], edges?: Edge[]) => {
//         if (node && nodes && edges) {
//             const allIncomers = getAllTracedNodes(node, nodes, edges, [], true);
//             const allOutgoers = getAllTracedNodes(node, nodes, edges, [], false);
//             const incomerIds = allIncomers.map((i) => i.id);
//             const outgoerIds = allOutgoers.map((o) => o.id);

//             setNodes((prevNodes) => {
//                 return prevNodes?.map((elem) => {
//                     if (allOutgoers.length > 0 || allIncomers.length > 0) {
//                         const highlight =
//                             elem.id === node.id || incomerIds.includes(elem.id) || outgoerIds.includes(elem.id);

//                         elem.data = {
//                             ...elem.data,
//                             disabled: !highlight,
//                         };
//                     }

//                     return elem;
//                 });
//             });

//             setEdges((prevEdges) => {
//                 return prevEdges?.map((elem) => {
//                     if (elem.type === 'smoothstep') {
//                         return elem;
//                     }
//                     const highlighted =
//                         (incomerIds.includes(elem.source) &&
//                             (incomerIds.includes(elem.target) || node.id === elem.target)) ||
//                         (outgoerIds.includes(elem.target) &&
//                             (outgoerIds.includes(elem.target) || node.id === elem.target));

//                     elem.style = {
//                         ...elem.style,
//                         stroke: highlighted ? '#ffffff' : 'rgb(75 85 99)',
//                     };
//                     elem.markerEnd = {
//                         type: MarkerType.Arrow,
//                         strokeWidth: 2,
//                         color: highlighted ? '#ffffff' : '#4B5563',
//                     };
//                     return elem;
//                 });
//             });
//         }
//     },
//     [setEdges, setNodes],
// );

// const moveEdgesToParent = (node: Node, nodes: Node[], edges: Edge[]) => {
//     const children = getOutgoers(node, nodes, edges);

//     if (children.length > 0) {
//         children.map((child) => {
//             getConnectedEdges([child], edges).filter((edge) => {
//                 if (edge.data?.label !== 'group') {
//                     edge.source = node.id;
//                     updateEdge(
//                         edge,
//                         {
//                             source: node.id,
//                             target: edge.target,
//                             sourceHandle: null,
//                             targetHandle: null,
//                         },
//                         edges,
//                     );
//                     return edge;
//                 }
//             });
//         });
//     }
// };

export const PermissionsControls = ({ scrollToTop, resetView }: { scrollToTop: () => void; resetView: () => void }) => {
    return (
        <Controls showInteractive={false}>
            <ControlButton title="Scroll to top" onClick={scrollToTop}>
                <ChevronUpIcon />
            </ControlButton>
            <ControlButton title="Reset View" onClick={resetView}>
                <ArrowUturnLeftIcon />
            </ControlButton>
        </Controls>
    );
};
