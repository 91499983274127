import { WelcomeComponent, Header, NextButton } from './WelcomeComponents';

const Intro = ({ nextStep }: WelcomeComponent): JSX.Element => {
    return (
        <div>
            <Header title="Getting Started with SailPoint Identity Risk" />
            <div className="mt-6 text-xs text-gray-300">
                <ol className="space-y-6">
                    <li>
                        SailPoint Identity Risk unifies your identity sources to create strong identities representing
                        users, service accounts, and machines.
                    </li>
                    <li>
                        Strong identities will be linked to access records, visualized in a powerful map that you will
                        be able to inspect, model, visualize, and ultimately enforce access through.
                    </li>
                    <li>
                        <strong>Step One</strong>
                        <br />
                        Add providers, like Okta, Active Directory, GitHub or Office 365.
                    </li>
                    <li>
                        <strong>Step Two</strong> <br />
                        Invite users from your team to SailPoint Identity Risk.
                    </li>
                    <li>
                        <strong>Step Three</strong>
                        <br />
                        Identity and access data will be collected from your environment.
                    </li>
                    <li>Ready? Lets get started ➡️</li>
                </ol>
            </div>
            <NextButton title="Proceed" next={nextStep} />
        </div>
    );
};

export default Intro;
