import { XMarkIcon } from '@heroicons/react/24/solid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext } from 'react';
import { classNames } from 'Utilities/utils';

export const Adjustments = (): JSX.Element => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { adjustmentsOpen } = mapState;
    const { enableAdjustments } = useFlags();

    const setTargetsStrength = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'set-targets-strength', value: e.target.valueAsNumber });
    };
    const setApplicationsStrength = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'set-applications-strength', value: e.target.valueAsNumber });
    };
    const setIdentityStrength = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'set-identities-strength', value: e.target.valueAsNumber });
    };
    const setActorsStrength = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'set-actors-strength', value: e.target.valueAsNumber });
    };
    const setDevicesStrength = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'set-devices-strength', value: e.target.valueAsNumber });
    };
    const setLinksStrength = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'set-links-strength', value: e.target.valueAsNumber });
    };

    return enableAdjustments ? (
        <div className={classNames('flex items-center justify-center w-52', adjustmentsOpen ? '' : 'hidden')}>
            <div className="w-full rounded-md bg-gray-800 border border-gray-400 border-opacity-60 text-xs text-gray-200 hover:border-gray-400  pointer-events-auto">
                <button
                    onClick={() => dispatch({ type: 'toggle-adjustments' })}
                    className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-2.5 -right-2.5 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                >
                    <XMarkIcon className="h-3.5 w-3.5 text-gray-200" />
                </button>
                <div className="p-4 text-xs text-gray-400 overflow-hidden">
                    <div className="flex flex-col space-y-4">
                        <h4 className="text-xs font-medium text-white">Node Strength</h4>
                        <label className="flex justify-between items-center">
                            Target
                            <input
                                className="input-gray w-24 text-xs"
                                type="number"
                                name="target"
                                step={0.1}
                                max={1}
                                value={mapState.strengths.targetStrength}
                                onChange={(e) => setTargetsStrength(e)}
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            Application
                            <input
                                className="input-gray w-24 text-xs"
                                type="number"
                                name="application"
                                step={0.1}
                                max={1}
                                value={mapState.strengths.applicationStrength}
                                onChange={(e) => setApplicationsStrength(e)}
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            Identity
                            <input
                                className="input-gray w-24 text-xs"
                                type="number"
                                name="identity"
                                step={0.1}
                                max={1}
                                value={mapState.strengths.identityStrength}
                                onChange={(e) => setIdentityStrength(e)}
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            Actor
                            <input
                                className="input-gray w-24 text-xs"
                                type="number"
                                name="actor"
                                step={0.1}
                                max={1}
                                value={mapState.strengths.actorStrength}
                                onChange={(e) => setActorsStrength(e)}
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            Device
                            <input
                                className="input-gray w-24 text-xs"
                                type="number"
                                name="device"
                                step={0.1}
                                max={1}
                                value={mapState.strengths.deviceStrength}
                                onChange={(e) => setDevicesStrength(e)}
                            />
                        </label>
                        <hr />
                        <h4 className="text-xs font-medium text-white">Link Strength</h4>
                        <label className="flex justify-between items-center">
                            Edges
                            <input
                                className="input-gray w-24 text-xs"
                                type="number"
                                name="target"
                                step={0.1}
                                value={mapState.strengths.linkStrength}
                                onChange={(e) => setLinksStrength(e)}
                            />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};
