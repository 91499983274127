import logo from 'assets/logo-192x192.png';
import ExternalPage from 'Onboarding/ExternalPage';
import { Helmet } from 'react-helmet-async';

import { useLazyQuery } from '@apollo/client';
import { GET_HELPSCOUT_TOKEN } from 'Graph/queries';
import { getEnvironment, useTenant, useURLParameter } from 'Hooks/Hooks';
import { useCurrentUser } from 'Hooks/User';
import { useCallback, useEffect, useState } from 'react';

const env = getEnvironment();

const docsSiteUrl = env === 'prod' ? 'https://docs.doublezero.io' : 'https://docs.staging.doublezero.io';

const DocAuth = (): JSX.Element => {
    const urlQuery = useURLParameter();
    const tenantId = useTenant();
    const { email, userId } = useCurrentUser();
    const [redirectInitiated, setRedirectInitiated] = useState(false);

    const [getHelpScoutToken, { error: tokenError }] = useLazyQuery(GET_HELPSCOUT_TOKEN, {
        variables: { userId: userId, tenantId: tenantId },
    });

    const redirectToDocs = useCallback(async () => {
        try {
            const tokenPayload = await getHelpScoutToken();
            const token = tokenPayload.data.getHelpscoutToken.token;

            const returnTo = urlQuery.get('returnTo') || '/';
            const redirectUrl = `${docsSiteUrl}/authcallback?token=${token}&returnTo=${returnTo}`;

            console.log('Redirecting user to docs with returnTo:', returnTo, ' and token:', token);

            // Redirect to docs site
            window.location.replace(redirectUrl);
        } catch (error) {
            console.error('Error creating token:', error);
        }
    }, [getHelpScoutToken, urlQuery]);

    useEffect(() => {
        if (tenantId && userId && !redirectInitiated) {
            redirectToDocs();
            setRedirectInitiated(true);
        }
    }, [redirectInitiated, redirectToDocs, tenantId, userId]);

    return (
        <ExternalPage>
            <Helmet>
                <title>Authenticating to Product Documentation</title>
            </Helmet>
            <div className="pb-10">
                <img className="h-12 w-auto" src={logo} alt="SailPoint Identity Risk Icon" />
                <h2 className="text-3xl font-extrabold text-gray-200">Product Documentation</h2>
                {!email ? (
                    <p className="mt-4 text-sm text-gray-300">Authenticating...</p>
                ) : tokenError ? (
                    <p className="mt-4 text-sm text-gray-300">
                        We encountered an error authenticating you to the documentation site.
                        <br />
                        <br />
                        Please try again.
                        <br />
                        <br />
                        If this persists, please contact support.
                        <br />
                        <br />
                        <span className="text-xs text-gray-500">{tokenError.message}</span>
                    </p>
                ) : (
                    <p className="mt-4 text-sm text-gray-300">
                        You have been authenticated and are being <br />
                        returned to the product documentation.
                        <br />
                        <br />
                        <span className="text-xs text-gray-500">
                            If you are not automatically redirected, <br />
                            please click the Continue button.
                        </span>
                        <span className="mt-6 flex place-items-center">
                            <button className="btn btn-primary w-3/7 text-sm rounded-md" onClick={redirectToDocs}>
                                Continue
                            </button>
                        </span>
                        <br />
                        <br />
                        <br />
                    </p>
                )}
            </div>
        </ExternalPage>
    );
};

export default DocAuth;
