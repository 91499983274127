import { Transition } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Fragment, useContext } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Chart } from './Components/Chart';
import { Details } from './Components/Details';

import Select from 'react-select';

const selectOptions = [
    { value: 'annunzip@doublezero.io', label: 'Patrick Ann' },
    { value: 'jeff@doublezero.io', label: 'Jeff Ham' },
    { value: 'tim@doublezero.io', label: 'Tim Garner' },
    { value: 'remi@doublezero.io', label: 'Remi Philippe' },
    { value: 'sylvain@doublezero.io', label: 'Sylvain Blot' },
    { value: 'vijay@doublezero.io', label: 'Vijay Dogra' },
    { value: 'grant@doublezero.io', label: 'Grant Keaton' },
];

export const TimelineModal = (): JSX.Element => {
    // const [selectedTarget, setSelectedTarget] = useState<Node>();
    const { dispatch } = useContext(IdentityMapContext);
    const { timeline } = useFlags();
    const closeTimeline = () => dispatch({ type: 'toggle-timeline' });
    useHotkeys('esc', closeTimeline);

    return (
        timeline && (
            <Transition.Root show={true} as={Fragment}>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                        <div
                            className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity top-[64px] h-[calc(100vh-64px)]"
                            onClick={closeTimeline}
                        />
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left shadow-xl transform transition-all w-[90vw] max-w-[1400px] h-[75vh] max-h-[800px] sm:my-8 sm:align-middle sm:max-w-3/4">
                            <div className="h-full overflow-hidden">
                                <div className="h-full flex items-stretch">
                                    <div className="border-gray-600 border-r py-5 flex-1 flex flex-col">
                                        <div className="px-5 flex justify-between items-center">
                                            <p className="uppercase tracking-wider font-bold text-xs text-gray-400 mb-3 flex items-center">
                                                Trust Timeline
                                            </p>
                                        </div>
                                        <div id="trust-graph" className="flex-1 min-w-0">
                                            <Chart />
                                        </div>
                                        <div className="flex-auto flex-grow-0 flex-shrink-0 border-t border-gray-500 pt-6 flex justify-between items-center px-8 my-4 space-x-8">
                                            <div className="flex-1">
                                                <p className="text-xs text-gray-400 mb-2 text-left">Selected Actors</p>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                    options={selectOptions}
                                                    defaultValue={[selectOptions[1], selectOptions[0]]}
                                                    classNamePrefix="multiselect"
                                                    className="relative z-50"
                                                    isClearable={false}
                                                    placeholder="Search for an actor"
                                                    menuPlacement="top"
                                                    autoFocus={false}
                                                />
                                            </div>
                                            <div>
                                                <p className="text-xs text-gray-400 mb-2 text-left">Date Range</p>
                                                <div className="relative flex justify-between items-start datepicker-group">
                                                    <div className="p-1 rounded-full bg-gray-800 border border-gray-500 text-gray-300 absolute top-2 right-[108px] z-20">
                                                        <ArrowRightIcon className="h-3 w-3" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 flex-grow-0 p-5 w-96 h-full overflow-y-scroll">
                                        <Details />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="text-white text-xs rounded-full p-1 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md"
                                onClick={closeTimeline}
                            >
                                <XMarkIcon className="h-4 w-4 text-gray-200" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </Transition.Root>
        )
    );
};
