export const JOB_DESCRIPTORS: Record<string, JobDescriptor> = {
    SUSPICIOUS_LOCATIONS: {
        name: 'Suspicious Locations',
        description:
            'Actors with a location assigned to their directory profile will have their access events location compared to their directory profile location. If the locations are different, the access event will be flagged as suspicious.',
        specificNodes: false,
        nodeType: 'STATS_ENTITY_TYPE_ACTOR',
        nodeSelectorTitle: 'Actors of interest',
        nodeSelectorDescription: 'All actors',
    },
    FAILED_ACCESS_FROM_ACTORS: {
        name: 'Failed Access From Actors',
        description:
            'Access events for each actor of interest that are flagged as failed will be compared to the thresholds set in the alert definition. If the number of failed access events exceeds the threshold, an alert will be generated.',
        specificNodes: true,
        nodeType: 'STATS_ENTITY_TYPE_ACTOR',
        nodeSelectorTitle: 'Actors of interest',
        nodeSelectorDescription: '',
    },
    FAILED_ACCESS_TO_TARGETS: {
        name: 'Failed Access To Targets',
        description:
            'Access events for each target of interest that are flagged as failed will be compared to the thresholds set in the alert definition. If the number of failed access events exceeds the threshold, an alert will be generated.',
        specificNodes: true,
        nodeType: 'STATS_ENTITY_TYPE_TARGET',
        nodeSelectorTitle: 'Targets of interest',
        nodeSelectorDescription: '',
    },
    UNKNOWN: {
        name: 'Unknown',
        description: 'Please select an alert type',
        specificNodes: false,
        nodeType: 'STATS_ENTITY_TYPE_ACTOR',
        nodeSelectorTitle: 'Actors of interest',
        nodeSelectorDescription: 'All actors',
    },
};

type JobDescriptor = {
    name: string;
    description: string;
    specificNodes: boolean;
    nodeType: string;
    nodeSelectorTitle: string;
    nodeSelectorDescription: string;
};

export const getJobDescriptor = (jobType: string | undefined): JobDescriptor => {
    if (!jobType) return JOB_DESCRIPTORS.UNKNOWN;
    const jobDescriptor = JOB_DESCRIPTORS[jobType];
    if (!jobDescriptor) {
        return JOB_DESCRIPTORS.UNKNOWN;
    }
    return jobDescriptor;
};
