import { useGraphControls } from 'Hooks/GraphHooks';
import { useNodes } from 'Hooks/Hooks';
import { List, ListItem } from 'Library/List';
import { getIconSourceURL, getNodeIconElement } from 'Map/Graph/Icons';
import { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { useMediaQuery } from 'react-responsive';
import { classNames, getDisplayName, tagNameLookup } from 'Utilities/utils';

type Item = {
    id: string;
    count: number;
};

export const AccessorList = ({
    items,
    entityType,
    sort = 'DESC',
    maxItems = 100,
    dynamicHeight = true,
}: {
    items: Item[];
    entityType: string;
    sort?: 'ASC' | 'DESC';
    maxItems?: number;
    dynamicHeight?: boolean;
}) => {
    const { addNodeToExplorer, isNodeInExplorer } = useGraphControls();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [nodeIds, setNodeIds] = useState<string[]>([]);
    const [sortedItems, setSortedItems] = useState<Item[]>(items);
    const { loading: nodesLoading, nodes } = useNodes(nodeIds, entityType);

    useEffect(() => {
        // Sort the items alphabetically
        let sortedItems = [...items];
        sortedItems.sort((a, b) => {
            return sort == 'DESC' ? b.count - a.count : a.count - b.count;
        });

        // Take the maxItems
        sortedItems = sortedItems.slice(0, maxItems);

        // Populate the nodeIds
        const nodeIds: string[] = [];
        sortedItems.map((entity: Item) => {
            nodeIds.push(entity.id);
        });

        setNodeIds(nodeIds);
        setSortedItems(sortedItems);
    }, [items, maxItems, sort]);

    return (
        <List
            className={classNames(
                'bg-gray-700 rounded-lg px-3 pt-1 pb-2',
                isMobile || dynamicHeight
                    ? 'max-h-[calc(100vh-728px)] overflow-y-auto'
                    : 'h-[calc(100vh-728px)] overflow-y-auto',
            )}
            loading={nodesLoading}
        >
            {sortedItems && Object.keys(nodes).length > 0 ? (
                sortedItems.map((item: Item, idx) => {
                    const node = nodes[item.id];
                    if (node) {
                        return (
                            <ListItem
                                key={idx}
                                node={node}
                                title={getDisplayName(node)}
                                description={node.tags.map((tag) => tagNameLookup(tag)).join(', ')}
                                secondTitle="Events"
                                secondDescription={item.count ? item.count.toString() : 'Unknown'}
                                highlighted={isNodeInExplorer(node)}
                                icon={
                                    node.label == 'actor' ? (
                                        <div className="h-6 w-6 flex-shrink-0 flex-grow-0">
                                            <Avatar
                                                size="100%"
                                                name={getDisplayName(node)}
                                                round={true}
                                                maxInitials={2}
                                            />
                                        </div>
                                    ) : (
                                        <img
                                            src={getIconSourceURL(getNodeIconElement(node))}
                                            className="h-6 w-6 flex-shrink-0"
                                        />
                                    )
                                }
                                onClick={() => {
                                    addNodeToExplorer(node);
                                }}
                            />
                        );
                    }
                })
            ) : (
                <></>
            )}
        </List>
    );
};
