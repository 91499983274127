import { ReactNode } from 'react';

interface PanelProps {
    children: ReactNode;
    title: string;
    subtitle?: string;
    id?: string;
}

export const Panel = ({ title, subtitle, children }: PanelProps): JSX.Element => (
    <>
        <div className="bg-gray-800 p-6 lg:h-512 lg:max-h-full max-h-96 overflow-y-scroll relative">
            <div className="flex items-start">
                <div className="text-gray-200 w-full">
                    <div className="mb-4">
                        <h3 className="text-md leading-6 font-bold tracking-wider" id="modal-title">
                            {title}
                        </h3>
                        {subtitle && <p className="mt-1 text-xs text-gray-500">{subtitle}</p>}
                    </div>
                    <div className="space-y-5">{children}</div>
                </div>
            </div>
        </div>
    </>
);

interface PanelItemProps {
    title: string;
    children: ReactNode;
}

export const PanelItem = ({ title, children }: PanelItemProps): JSX.Element => (
    <div>
        <h4 className="text-xs leading-6 font-medium text-gray-300">{title}</h4>
        <div className="text-xs text-gray-400">{children}</div>
    </div>
);
