import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useGraphControls } from 'Hooks/GraphHooks';
import { useNodes, useTenant } from 'Hooks/Hooks';
import { List } from 'Library/List';
import { useEffect, useState } from 'react';
import { Node } from 'Types/types';
import { classNames } from 'Utilities/utils';

type BasePanelProps<Item> = {
    title: string;
    className?: string;
    height?: string;
    columns?: string;
    startDate: number;
    endDate: number;
    query: DocumentNode;
    queryName: string;
    variables: Record<string, string | number>;
    resolveNodes: boolean;
    alternateNodeKey?: string;
    emptyIsPositive?: boolean;
    defaultLimit?: number;
    processItem?: (item: Item) => Item;
    renderItem: (
        idx: number,
        item: Item,
        node?: Node,
        isNodeInExplorer?: boolean,
        addNodeToExplorer?: (node: Node, query?: boolean, closeDashboard?: boolean) => void,
    ) => JSX.Element | undefined;
};

export const BasePanel = <Item extends { id: string }>({
    title,
    className,
    height = 'h-54',
    columns = '',
    startDate,
    endDate,
    query,
    queryName,
    variables,
    renderItem,
    resolveNodes,
    alternateNodeKey,
    emptyIsPositive = false,
    defaultLimit = 5,
}: BasePanelProps<Item>) => {
    const { addNodeToExplorer, isNodeInExplorer } = useGraphControls();

    const [limit, setLimit] = useState(defaultLimit);

    const tenantId = useTenant();

    const [items, setItems] = useState<Item[]>([]);
    const [nodeIds, setNodeIds] = useState<string[]>([]);
    const {
        loading: nodesLoading,
        error: nodeLoadingError,
        nodes,
    } = useNodes(nodeIds, String(variables['entityType']) || '');

    const [cachedNodes, setCachedNodes] = useState<Record<string, Node>>({});
    const [cachedItems, setCachedItems] = useState<Item[]>([]);

    const {
        data,
        loading: itemsLoading,
        error,
    } = useQuery(query, {
        variables: {
            tenantId,
            startDate: startDate,
            endDate: endDate,
            ...variables,
            limit,
        },
    });

    // Reset items if the date ranges change
    useEffect(() => {
        setItems([]);
        setNodeIds([]);
    }, [startDate, endDate]);

    // Callback to increase the default limit
    const increaseLimit = () => {
        setCachedItems(items);
        setCachedNodes(nodes);
        setItems([]);
        setNodeIds([]);
        setLimit(limit + defaultLimit);
    };

    // Populate the items array from the backend
    useEffect(() => {
        if (data && data[queryName]) {
            const nodeIds: string[] = [];
            const items: Item[] = [];

            const { items: backendItems } = data[queryName];

            if (backendItems) {
                backendItems.map((entity: Item) => {
                    if (resolveNodes) {
                        if (alternateNodeKey) {
                            const id = entity[alternateNodeKey as keyof Item] as string;
                            if (id) {
                                nodeIds.push(id);
                            }
                        } else {
                            nodeIds.push(entity.id);
                        }
                    }
                    items.push(entity);
                });
                if (resolveNodes) {
                    setNodeIds(nodeIds);
                }
                setItems(items);
            }
        }
    }, [alternateNodeKey, data, queryName, resolveNodes]);

    const readyToRender = items && items.length > 0 && (!resolveNodes || Object.keys(nodes).length > 0);
    const renderCached =
        !readyToRender &&
        cachedItems &&
        cachedItems.length > 0 &&
        (!resolveNodes || Object.keys(cachedNodes).length > 0);

    return (
        <List
            title={title}
            className={className || 'bg-gray-700 rounded-md col-span-1 row-span-1 xl:row-span-2 p-3'}
            loading={(itemsLoading || nodesLoading) && !readyToRender && !renderCached}
            error={Boolean(error || nodeLoadingError)}
            emptyIsPositive={emptyIsPositive}
        >
            {readyToRender && (
                <>
                    <div
                        className={classNames(
                            'overflow-y-auto flex',
                            limit > defaultLimit ? 'flex-col-reverse' : 'flex-col',
                            height,
                        )}
                    >
                        <div className={classNames('space-y-1', columns)}>
                            {items.map((item: Item, idx) => {
                                if (resolveNodes) {
                                    let node: Node | undefined;
                                    if (alternateNodeKey) {
                                        const id = item[alternateNodeKey as keyof Item] as string;
                                        if (id) {
                                            node = nodes[id];
                                        }
                                    } else {
                                        node = nodes[item.id];
                                    }
                                    if (node) {
                                        return renderItem(idx, item, node, isNodeInExplorer(node), addNodeToExplorer);
                                    }
                                } else {
                                    return renderItem(idx, item);
                                }
                            })}
                        </div>
                    </div>
                    {items.length == limit ? (
                        <div className="flex w-full items-center justify-center text-gray-500">
                            <button
                                className="rounded-md hover:bg-gray-500 hover:text-gray-100 px-2 py-2 flex items-center justify-center"
                                onClick={increaseLimit}
                            >
                                Load More...
                            </button>
                        </div>
                    ) : (
                        <div className="h-8 flex items-center justify-center text-gray-600">All results loaded</div>
                    )}
                </>
            )}
            {renderCached ? (
                <>
                    <div
                        className={classNames(
                            'overflow-y-auto flex',
                            limit > defaultLimit ? 'flex-col-reverse' : 'flex-col',
                            height,
                        )}
                    >
                        <div className={classNames('space-y-1', columns)}>
                            {cachedItems.map((item: Item, idx) => {
                                if (resolveNodes) {
                                    let node: Node | undefined;
                                    if (alternateNodeKey) {
                                        const id = item[alternateNodeKey as keyof Item] as string;
                                        if (id) {
                                            node = cachedNodes[id];
                                        }
                                    } else {
                                        node = cachedNodes[item.id];
                                    }
                                    if (node) {
                                        return renderItem(idx, item, node, isNodeInExplorer(node), addNodeToExplorer);
                                    }
                                } else {
                                    return renderItem(idx, item);
                                }
                            })}
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-center text-gray-500">
                        <div className="flex items-center justify-center w-8 h-8">
                            <div className="loader h-4 w-4" />
                        </div>
                    </div>
                </>
            ) : null}
        </List>
    );
};

export type AccessCountItem = {
    id: string;
    accessCount: number;
};

export type CountItem = {
    id: string;
    count: number;
    totalCount: number;
    rate: number;
};

export type ReasonCountItem = {
    id: string;
    reason: string;
    count: number;
};
