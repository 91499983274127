// @flow weak

import { CSSProperties, Fragment, useState } from 'react';
import { SliderItem, GetHandleProps, GetTrackProps, GetRailProps } from 'react-compound-slider';
import format from 'date-fns/format';
import { getHours } from 'date-fns';
import { getUsersTimezone } from 'Utilities/utils';

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle: CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: 40,
    transform: 'translate(0%, -50%)',
    cursor: 'pointer',
};

const railInnerStyle: CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: 8,
    transform: 'translate(0%, -50%)',
    pointerEvents: 'none',
};

interface Rail {
    getRailProps: GetRailProps;
}

export function SliderRail({ getRailProps }: Rail): JSX.Element {
    return (
        <Fragment>
            <div style={railOuterStyle} {...getRailProps} />
            <div style={railInnerStyle} className="bg-gray-800" />
        </Fragment>
    );
}

interface Handle {
    domain: [min: number, max: number];
    handle: SliderItem;
    isActive: boolean;
    disabled: boolean;
    getHandleProps: GetHandleProps;
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({ handle, domain, isActive, getHandleProps }: Handle): JSX.Element {
    const { id, value, percent } = handle;

    const [mouseOver, setMouseOver] = useState(false);

    const onMouseEnter = () => {
        setMouseOver(true);
    };

    const onMouseLeave = () => {
        setMouseOver(false);
    };

    return (
        <Fragment>
            {mouseOver || isActive ? (
                <div
                    style={{
                        left: `${percent}%`,
                        position: 'absolute',
                        marginLeft: '-11px',
                        marginTop: '-35px',
                    }}
                >
                    <div className="tooltip bg-gray-700">
                        <span className="tooltiptext text-xs">
                            {format(new Date(value), 'MMM dd, HH:mm')}{' '}
                            <span className="text-gray-400">({getUsersTimezone()})</span>
                        </span>
                    </div>
                </div>
            ) : null}
            <div
                style={{
                    left: `${handle.percent}%`,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                    zIndex: 5,
                    width: 24,
                    height: 36,
                    cursor: 'pointer',
                    // border: "1px solid white",
                    backgroundColor: 'none',
                }}
                {...getHandleProps(id as unknown as string, {
                    onMouseEnter: onMouseEnter,
                    onMouseLeave: onMouseLeave,
                })}
                className="slider-handle"
            />
            <div
                role="slider"
                aria-valuemin={domain[0]}
                aria-valuemax={domain[1]}
                aria-valuenow={handle.value}
                className="bg-gray-600"
                style={{
                    left: `${handle.percent}%`,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    width: 6,
                    height: 16,
                    borderRadius: '25%',
                    // boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
                    // backgroundColor: disabled ? '#666' : '#333',
                }}
            />
        </Fragment>
    );
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************

interface TrackProps {
    source: SliderItem;
    target: SliderItem;
    getTrackProps: GetTrackProps;
    disabled?: boolean;
    dragging?: boolean;
    dateText?: string;
    timezoneText?: string;
}
export function Track({ source, target, getTrackProps, dateText, timezoneText, dragging }: TrackProps): JSX.Element {
    return (
        <Fragment>
            {dragging && (
                <div
                    style={{
                        left: `${(source.percent + target.percent) / 2}%`,
                        position: 'absolute',
                        marginLeft: '-62px',
                        marginTop: '-35px',
                    }}
                >
                    <div className="tooltip bg-gray-700">
                        <span className="tooltiptext text-xs tabular-nums" style={{ width: '200px' }}>
                            {dateText}
                            <div className="text-gray-400">{timezoneText}</div>
                        </span>
                    </div>
                </div>
            )}
            <div
                className="bg-gray-600"
                style={{
                    position: 'absolute',
                    transform: 'translate(0%, -50%)',
                    height: 8,
                    zIndex: 1,
                    // backgroundColor: disabled ? '#999' : '#444',
                    borderRadius: 4,
                    cursor: 'pointer',
                    left: `${source.percent}%`,
                    width: `${target.percent - source.percent}%`,
                }}
                {...getTrackProps}
            />
        </Fragment>
    );
}

// *******************************************************
// TICK COMPONENT
// *******************************************************

interface TickProps {
    tick: SliderItem;
    count: number;
    format: (value: number) => string;
}

export function Tick({ tick, count, format }: TickProps): JSX.Element {
    const isAnHourlyTick = getHours(new Date(tick.value)) != 0;

    return (
        <div>
            <div
                className="bg-gray-400"
                style={{
                    position: 'absolute',
                    marginTop: -18,
                    width: 1,
                    height: 5,
                    left: `${tick.percent}%`,
                }}
            />
            <div
                className="text-gray-400"
                style={{
                    position: 'absolute',
                    marginTop: isAnHourlyTick ? -34 : -36,
                    fontSize: isAnHourlyTick ? 9 : 10,
                    textAlign: 'center',
                    fontFamily: 'Poppins, san-serif',
                    marginLeft: `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                {format(tick.value)}
            </div>
        </div>
    );
}
