import { FactorCard } from './FactorCard';
import { GET_POLICY_PROFILE, LIST_POLICY_DOCUMENTS } from 'Graph/typedQueries';
import { useQuery } from '@apollo/client';
import { useTenant } from 'Hooks/Hooks';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useMemo } from 'react';
import { ListPolicyDocumentsQuery, PolicyWeight } from 'GeneratedGQL/graphql';
import { SelectedNavItem } from '../PolicyModalTypes';

type TrustProfileProps = {
    trustProfileId?: string;
    setSelectedNavItem: React.Dispatch<React.SetStateAction<SelectedNavItem | undefined>>;
};

export const TrustProfile = ({ trustProfileId }: TrustProfileProps): JSX.Element => {
    const tenantId = useTenant();

    const { data, loading: loadingGetPolicyProfile } = useQuery(GET_POLICY_PROFILE, {
        variables: {
            tenantId: tenantId || '',
            profileId: trustProfileId || '',
        },
        skip: !tenantId || !trustProfileId,
        fetchPolicy: 'network-only',
    });

    // List Policy Documents
    const { data: policyDocumentsData, loading: loadingListPolicyDocuments } = useQuery(LIST_POLICY_DOCUMENTS, {
        variables: {
            tenantId: tenantId || '',
        },
        skip: !tenantId,
    });

    const policyDocumentsById = useMemo(() => {
        const pd: Record<string, ListPolicyDocumentsQuery['listPolicyDocuments'][number]> = {};

        policyDocumentsData?.listPolicyDocuments.map((policyDocument) => {
            pd[policyDocument?.policyDocumentId || ''] = policyDocument;
        });

        return pd;
    }, [policyDocumentsData?.listPolicyDocuments]);

    const profile = data?.getPolicyProfile;

    const loading = loadingGetPolicyProfile || loadingListPolicyDocuments;

    const orderedPolicies = useMemo(() => {
        const policyDocuments = profile?.policies;

        if (!policyDocuments) return [];

        const ordered = policyDocuments.toSorted((a, b) => {
            if (!a || !b) return 0;
            const aDoc = policyDocumentsById[a.policyId];
            const bDoc = policyDocumentsById[b.policyId];
            if (!aDoc || !bDoc) return 0;
            return aDoc.displayName.localeCompare(bDoc.displayName);
        });

        return ordered;
    }, [policyDocumentsById, profile?.policies]);

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <div className="flex items-center space-x-2">
                    <p className="uppercase tracking-wider font-bold text-xs text-gray-400">How Trust is Calculated</p>
                    {loading && <span className="h-4 w-4 loader" />}
                </div>
            </div>
            {!trustProfileId && <div className="text-xs pt-2">Trust Profile not found - please contact support.</div>}
            {!loading && profile && Object.keys(policyDocumentsById).length > 0 && (
                <div>
                    <div className="pt-0 pb-4">
                        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-4">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0"></div>
                                    <div className="ml-1">
                                        <h3 className="text-md leading-6 font-medium text-gray-200">
                                            {profile.displayName}
                                        </h3>
                                        {profile.description && (
                                            <p className="text-xs text-gray-500 mt-1">{profile.description}</p>
                                        )}
                                        <p className="text-xs text-gray-400 mt-1">
                                            {profile.policies.length > 0 ? (
                                                <span>Contains {profile.policies.length} Trust Factor(s)</span>
                                            ) : (
                                                <span>Contains no Trust Factors currently</span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="space-y-3">
                        {policyDocumentsById &&
                            orderedPolicies.map((policy) => {
                                const document = policyDocumentsById[policy?.policyId || ''];
                                return (
                                    <FactorCard
                                        profileId={profile?.profileId || ''}
                                        icon={
                                            policy?.weight === PolicyWeight.PolicyWeightDisabled ? (
                                                <span />
                                            ) : (
                                                <CheckIcon />
                                            )
                                        }
                                        key={document?.policyDocumentId || ''}
                                        name={document?.displayName || ''}
                                        description={document?.description || ''}
                                        author="SailPoint"
                                        policyDocumentId={document?.policyDocumentId || ''}
                                        weight={policy?.weight || PolicyWeight.PolicyWeightUnknown}
                                    />
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
};
