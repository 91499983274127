import { useForm } from 'react-hook-form';
import { AlertDefinition } from 'Alerts/AlertTypes';
import {
    ADD_ALERT_DEFINITION,
    LIST_ALERT_DEFINITIONS,
    SEARCH_ATTRIBUTES,
    UPDATE_ALERT_DEFINITION,
} from 'Graph/queries';
import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';
import { useNodes, useTenant } from 'Hooks/Hooks';
import { classNames } from 'Utilities/utils';
import { useToasts } from 'Hooks/Toasts';
import { useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { JOB_DESCRIPTORS } from './JobDescriptors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUserPermissions } from 'Utilities/UserPermissions';

export const AddAlertDefinition = ({
    selectedAlert,
    finished,
}: {
    selectedAlert: AlertDefinition;
    finished: () => void;
}): JSX.Element => {
    const tenantId = useTenant();
    const { addToast } = useToasts();
    const { enabledAlertDefinitions } = useFlags();

    const { userCan } = useUserPermissions();
    const canCreateAlertDefinitions = userCan('create', '/alerts/definitions');

    const [existingNodeIds, setExistingNodeIds] = useState<string[]>([]);
    const [selectedNodes, setSelectedNodes] = useState<readonly { value: string; label: string }[]>([]);

    const [searchAttributes, { error: errorSearchingAttributes }] = useLazyQuery(SEARCH_ATTRIBUTES);

    const [addAlertDefinition, { loading: loadingAdd }] = useMutation(ADD_ALERT_DEFINITION, {
        refetchQueries: [{ query: LIST_ALERT_DEFINITIONS, variables: { tenantId } }],
    });

    const [updateAlertDefinition, { loading: loadingUpdate }] = useMutation(UPDATE_ALERT_DEFINITION, {
        refetchQueries: [{ query: LIST_ALERT_DEFINITIONS, variables: { tenantId } }],
    });

    const loading = loadingAdd || loadingUpdate;

    const { register, handleSubmit, watch } = useForm<AlertDefinition>({ defaultValues: selectedAlert });

    const jobType = watch('jobType') || 'UNKNOWN';
    const jobDescriptor = useMemo(() => JOB_DESCRIPTORS[jobType], [jobType]);

    const {
        loading: nodesLoading,
        error: nodeLoadingError,
        nodes: existingNodes,
    } = useNodes(existingNodeIds, jobDescriptor.nodeType);

    useEffect(() => {
        if (selectedAlert.jobPayload) {
            const parsedPayload = JSON.parse(selectedAlert.jobPayload);

            setExistingNodeIds(parsedPayload.nodeIds);
        }
    }, [selectedAlert]);

    const previouslySelectedNodesOptions = useMemo(() => {
        return Object.values(existingNodes).map((node) => {
            return {
                value: String(node.id),
                label: String(node.props.displayName || node.props.alternateId || node.id),
            };
        });
    }, [existingNodes]);

    useEffect(() => {
        console.log('Job type changed, resetting node ids');

        if (jobType === selectedAlert.jobType) {
            if (selectedAlert.jobPayload) {
                setSelectedNodes(previouslySelectedNodesOptions);
            }
        } else {
            setSelectedNodes([]);
        }
    }, [jobType, selectedAlert.jobPayload, selectedAlert.jobType, previouslySelectedNodesOptions]);

    const handleNodeSelectorChange = (options: readonly { value: string; label: string }[]) => {
        setSelectedNodes(options);
    };

    const onSubmit = async (data: AlertDefinition) => {
        console.log('Alert input:', data);

        let result: FetchResult;

        const jobPayload = jobDescriptor.specificNodes
            ? JSON.stringify({
                  nodeIds: selectedNodes.map((node) => node.value),
              })
            : '';

        // If the alert already has an ID, we are updating an existing alert
        if (selectedAlert.id) {
            console.log('Updating alert');
            try {
                result = await updateAlertDefinition({
                    variables: {
                        tenantId,
                        updateAlertDefinitionInput: {
                            id: selectedAlert.id,
                            name: data.name,
                            jobType: data.jobType,
                            jobPayload: jobPayload,
                            query: data.query,
                            message: data.message,
                            alertThreshold: data.alertThreshold,
                            warningThreshold: data.warningThreshold,
                        },
                    },
                });

                if (result.data?.updateAlertDefinition?.success) {
                    addToast('Alert updated successfully', 'success');
                    finished();
                }
            } catch (e) {
                console.log(e);
                addToast('Error updating alert, please try again', 'failure');
            }
        } else {
            console.log('Creating a new alert');
            try {
                result = await addAlertDefinition({
                    variables: {
                        tenantId,
                        addAlertDefinitionInput: {
                            name: data.name,
                            jobType: data.jobType,
                            jobPayload: jobPayload,
                            query: data.query,
                            message: data.message,
                            alertThreshold: data.alertThreshold,
                            warningThreshold: data.warningThreshold,
                        },
                    },
                });

                if (result.data?.addAlertDefinition?.id) {
                    addToast('Alert created successfully', 'success');
                    finished();
                }
            } catch (e) {
                console.log(e);
                addToast('Error creating alert, please try again', 'failure');
            }
        }
    };

    const loadOptions = async (inputValue: string) => {
        const now = new Date();
        const nowMinus30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        const results = await searchAttributes({
            variables: {
                tenantId: tenantId,
                startDate: +nowMinus30Days,
                endDate: +now,
                entity: jobDescriptor.nodeType,
                sortField: 'displayName',
                search: inputValue,
            },
        });
        if (results.data?.searchAttributes?.items) {
            return results.data?.searchAttributes?.items.map(
                (attribute: { id: string; displayName: string; email: string }) => {
                    return {
                        value: attribute.id,
                        label: attribute.displayName || attribute.email || attribute.id,
                    };
                },
            );
        }
    };

    return (
        <div className="mt-6 ml-4">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="">
                    <h4 className="font-bold text-xs mb-2">Alert Details</h4>
                    <div className="space-y-2">
                        <div className="">
                            <label className="text-xs">
                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">Alert Type</span>
                                <select
                                    {...register('jobType')}
                                    required
                                    className="input-gray text-xs w-80 rounded-none mt-1"
                                >
                                    {Object.entries(JOB_DESCRIPTORS)
                                        .map(([jobType, descriptor]) => {
                                            if (jobType !== 'UNKNOWN' && enabledAlertDefinitions.includes(jobType)) {
                                                return (
                                                    <option key={jobType} value={jobType}>
                                                        {descriptor.name}
                                                    </option>
                                                );
                                            }
                                        })
                                        .filter(Boolean)}
                                </select>
                            </label>
                        </div>
                        <div>
                            <p className="text-xs text-gray-500 w-1/2">{jobDescriptor.description}</p>
                        </div>
                        <div>
                            <label className="text-xs">
                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">Alert Name</span>
                                <input
                                    {...register('name')}
                                    type="text"
                                    required
                                    className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1"
                                    placeholder="Enter Alert Name"
                                />
                            </label>
                        </div>
                        <div>
                            <label className="text-xs">
                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">
                                    {jobDescriptor.nodeSelectorTitle}
                                </span>

                                {jobDescriptor.specificNodes ? (
                                    nodesLoading ? (
                                        <div className="pt-1 px-3 text-gray-300">Loading nodes...</div>
                                    ) : nodeLoadingError ? (
                                        <div className="pt-1 px-3 text-red-500">Error loading selected nodes</div>
                                    ) : (
                                        <AsyncSelect
                                            isMulti
                                            required
                                            classNamePrefix="multiselect-sm"
                                            className="w-2/3"
                                            placeholder="Select nodes(s)"
                                            menuPlacement="bottom"
                                            onChange={handleNodeSelectorChange}
                                            value={selectedNodes}
                                            noOptionsMessage={({ inputValue }) => {
                                                if (errorSearchingAttributes) {
                                                    return 'Error searching for nodes';
                                                }
                                                return inputValue
                                                    ? 'No matching nodes'
                                                    : 'Start typing to search for nodes';
                                            }}
                                            loadOptions={loadOptions}
                                        />
                                    )
                                ) : (
                                    <div className="pt-2 pb-1 text-gray-300">
                                        <span className="px-4 py-1 bg-gray-600 border-gray-500 border">
                                            {jobDescriptor.nodeSelectorDescription}
                                        </span>
                                    </div>
                                )}
                            </label>
                        </div>
                        <div>
                            <label className="text-xs">
                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">Alert Message</span>
                                <textarea
                                    {...register('message')}
                                    required
                                    rows={5}
                                    className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1"
                                    placeholder="Enter Alert Message"
                                />
                            </label>
                        </div>
                    </div>
                </div>

                <div className="mt-6">
                    <h4 className="font-bold text-xs">Select Thresholds</h4>
                    <p className="text-gray-500 text-xs mb-4">
                        Matching access events are compared hourly against these thresholds
                    </p>
                    <div className="space-y-2 w-80 pr-1">
                        <label className="text-xs flex items-center justify-between">
                            <span className="pr-3 text-gray-400">
                                <span className="inline-block w-2 h-2 bg-red-500 mr-1" />
                                Alert threshold
                            </span>
                            <input
                                {...register('alertThreshold')}
                                type="number"
                                required
                                className="input-gray text-xs w-20"
                                placeholder="1"
                                min={Number(watch('warningThreshold')) + 1}
                            />
                        </label>
                        <label className="text-xs flex items-center justify-between">
                            <span className="pr-3 text-gray-400">
                                <span className="inline-block w-2 h-2 bg-yellow-500 mr-1" />
                                Warning threshold
                            </span>
                            <input
                                {...register('warningThreshold')}
                                type="number"
                                required
                                className="input-gray text-xs w-20"
                                placeholder="0"
                                max={Math.max(Number(watch('alertThreshold')) - 1, 0)}
                                min={0}
                            />
                        </label>
                    </div>
                </div>

                <div className="mt-6 flex space-x-2">
                    {canCreateAlertDefinitions && (
                        <button
                            type="submit"
                            className={classNames('text-xs btn btn-primary rounded-md', loading ? 'btn-disabled' : '')}
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save Alert'}
                        </button>
                    )}
                    <button
                        type="button"
                        className={classNames('text-xs btn  rounded-md', loading ? 'btn-disabled' : '')}
                        onClick={finished}
                        disabled={loading}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};
