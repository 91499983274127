import { Data, GraphFilter, GraphFilterLabels, Node, NodeProps, NodeTags, PolicyStats } from 'Types/types';
import { tagDescriptionLookup } from 'Utilities/utils';

const specialProperties = ['deviceCount', 'tags', 'id'];

export const applyGraphFilter = (graphData: Data, filters: GraphFilter[]): Data => {
    if (filters.length == 0) {
        console.debug('No filters applied, returning graph data');

        // The graph data is still refreshed to ensure that the graph is in a consistent state
        // since we may have had a filter applied before
        return refreshGraph(graphData);
    }
    console.debug('Applying graph filter', filters);

    // refresh the graph so we are operating on the most up-to-date data
    graphData = refreshGraph(graphData);
    // select nodes that match the filter
    graphData.nodes = graphData.nodes.filter((node) => {
        /*
        For each node, we check if it matches the filter

        There can be 1 or more filters to search through

        Right now, we only support AND logic, so we check if all the filters match the node
        */

        const allFiltersMatch = filters.every((filter) => {
            // This function will execute for each filter against each node
            //
            // if the filter is not set to match this node type, skip the filter by returning true
            const shouldBeApplied = node.label == filter.label;
            if (!shouldBeApplied) return true;

            // This filter compares a property of the node to a value
            if (!specialProperties.includes(filter.property)) {
                const property = node.props[filter.property as keyof NodeProps];
                // If the node does not have this property it cannot match the filter
                if (!property) {
                    return false;
                }
                // If the property is a string, and the value is a string, we can do some comparisons
                if (typeof property === 'string' && typeof filter.value === 'string' && filter.property != 'id') {
                    const prop = property.toLowerCase();
                    const val = filter.value.toLowerCase();

                    switch (filter.operation) {
                        case 'contains':
                            return prop.includes(val);
                        case 'equals':
                            return prop === val;
                        case 'not-equals':
                            return prop !== val;
                        case 'not-contains':
                            return !prop.includes(val);
                        case 'regex':
                            return RegExp(val).test(prop);
                    }
                } else {
                    // The property was not a string, or did match any of the criteria, so this node is filtered out
                    return false;
                }
            }

            //This filter checks if the node has a specific tag
            if (filter.property == 'tags') {
                const property = node.tags;
                if (!property) {
                    return false;
                }
                if (typeof filter.value === 'string') {
                    const val = filter.value.toUpperCase();
                    switch (filter.operation) {
                        case 'has':
                            //will check if the exact tag or the translated tag exists on the node
                            return (
                                property.includes(val as NodeTags) ||
                                property.includes(tagDescriptionLookup(val) as NodeTags)
                            );
                        case 'does-not-have':
                            return (
                                !property.includes(val as NodeTags) &&
                                !property.includes(tagDescriptionLookup(val) as NodeTags)
                            );
                    }
                } else {
                    return false;
                }
            }

            //When adding new filters, if the property is not a node.props property, add it to the special Properties array at the top of this file
            if (filter.property == 'id') {
                const property = node.id.toString();
                if (!property) {
                    return false;
                }
                if (typeof filter.value === 'string') {
                    const val = filter.value.toLowerCase();
                    switch (filter.operation) {
                        case 'contains':
                            return property.includes(val);
                        case 'equals':
                            return property === val;
                        case 'not-equals':
                            return property !== val;
                        case 'not-contains':
                            return !property.includes(val);
                        case 'regex':
                            return RegExp(val).test(property);
                    }
                } else {
                    return false;
                }
            }

            // This filter calculates the number of devices an actor has, then filters based on boolean logic
            if (filter.label === 'actor' && filter.property === 'deviceCount') {
                const deviceSet = new Set<Node>();
                node.neighbors
                    .filter((node) => node.label == 'identity')
                    .map((node) =>
                        node.neighbors.filter((node) => node.label == 'device').map((node) => deviceSet.add(node)),
                    );

                let deviceCount = 0;
                if (deviceSet) {
                    deviceCount = deviceSet.size;
                }

                const val = filter.value;
                const valAsNumber = Number(val);
                const deviceCountAsNumber = Number(deviceCount);

                switch (filter.operation) {
                    case 'equals':
                        return deviceCountAsNumber == valAsNumber;
                    case 'not-equals':
                        return deviceCountAsNumber != valAsNumber;
                    case 'greater-than':
                        return deviceCountAsNumber > valAsNumber;
                    case 'less-than':
                        return deviceCountAsNumber < valAsNumber;
                }
            }

            // No filters were matched, so this node is filtered out
            return false;
        });

        return allFiltersMatch;
    });

    // select links that match the filter
    graphData.links = graphData.links.filter((link) => {
        return filters.every((filter) => {
            // If this is a link and it has policy stats, we should filter on it
            if (filter.label === 'link' && link.source.label == 'target') {
                if (!link.policyStats) return false;

                const prop = link.policyStats[filter.property as keyof PolicyStats];
                const val = Number(filter.value);

                switch (filter.operation) {
                    case 'equals':
                        return prop == val;
                    case 'not-equals':
                        return prop != val;
                    case 'greater-than':
                        return prop > val;
                    case 'less-than':
                        return prop < val;
                    default:
                        return false;
                }
            }

            // We keep links that are not originating from a target since these make up the
            // remaining links in the graph that are not related to policy
            return true;
        });
    });

    const filteredLabels = filters.map((f) => f.label);

    graphData = pruneAndRefreshGraph(graphData, filteredLabels);

    return graphData;
};

const checkIdentityComponentPresence = (node: Node, filteredLabels: GraphFilterLabels[]) => {
    // Define the identity components to check
    const components = ['actor', 'device', 'application'] as const;

    // Iterate through each component to check if it has been filtered
    const results = components.map((component) => {
        // Determine if the component type is being filtered.
        // e.g. this is an actor and we have a filter for actors
        const isComponentTypeFiltered = filteredLabels.includes(component);

        // If the component type is not being filtered, return true.
        if (!isComponentTypeFiltered) {
            return true;
        }

        // Determine if the component was part of the complete identity
        // This is usually true, but sometimes identities are incomplete and may be missing a component
        const isComponentPresentInFullIdentity = node.identityComponents && node.identityComponents[component] === true;

        // If the component is being filtered, but the identity does not have the component
        // we will filter out the identity
        if (isComponentTypeFiltered && !isComponentPresentInFullIdentity) {
            return false;
        }

        // Check if a neighbor of the component type exists, if so, this means we have satisfied the filter
        const hasComponentNeighbor = node.neighbors.some((n) => n.label === component);

        // If the component type exists in the identity, but there is no neighbor of that type, we will filter out the identity
        if (isComponentPresentInFullIdentity && !hasComponentNeighbor) {
            return false;
        }

        // If the component type is being filtered, and the identity has the component, and the component is seen as a neighbor
        return true;
    });

    // If all required components are present or none are required, return true.
    return results.every((result) => result === true);
};

const pruneAndRefreshGraph = (graphData: Data, filteredLabels: GraphFilterLabels[]): Data => {
    graphData = refreshGraph(graphData);

    // remove all identities that do not have all their neighbors (e.g. the actor, target, or device has been filtered out)
    graphData.nodes = graphData.nodes.filter((node) => {
        // We only need to check identities
        if (node.label == 'identity') {
            const identityComponents = node.identityComponents;

            if (!identityComponents) {
                console.warn('Pruning identity with no identity components', node);
                return false;
            }

            if (node.neighbors.filter((n) => n.label == 'target').length == 0) {
                logIncompleteIdentity(node, 'target');
                return false;
            }

            return checkIdentityComponentPresence(node, filteredLabels);
        }

        return true;
    });

    graphData = refreshGraph(graphData);

    // remove nodes that have no links
    graphData.nodes = graphData.nodes.filter((node) => node.neighbors.length != 0);

    return graphData;
};

const refreshGraph = (graphData: Data): Data => {
    graphData = refreshLinks(graphData);
    graphData = refreshNeighborship(graphData);

    return graphData;
};

const refreshLinks = (graphData: Data): Data => {
    const { nodes, links } = graphData;
    const newLinks = links.filter((link) => {
        const source = link.source;
        const target = link.target;
        return nodes.includes(source) && nodes.includes(target as Node);
    });
    return { nodes, links: newLinks };
};

const refreshNeighborship = (graphData: Data): Data => {
    const { nodes, links } = graphData;
    nodes.map((n) => {
        n.neighbors = [];
        n.links = [];
    });
    links.map((l) => {
        const source = l.source;
        const target = l.target as Node;

        if (source && target) {
            if (source.neighbors.indexOf(target) == -1) source.neighbors.push(target);
            if (target.neighbors.indexOf(source) == -1) target.neighbors.push(source);
            source.links.push(l);
            target.links.push(l);
        } else {
            console.debug('Issue: pruning link with no source or target', l);
        }
    });
    return { nodes, links };
};

const logIncompleteIdentity = (node: Node, missingNodeLabel: string) => {
    console.debug(`Pruning incomplete identity from the graph due to lack of a ${missingNodeLabel}`, node);
};
