import { useQuery } from '@apollo/client';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { intervalToDuration, sub } from 'date-fns';
import { GET_GLOBAL_EVENTS_HISTORY } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { Stat } from 'Library/Stat';
import { useMemo } from 'react';
import { TimeIntervalProps, BackendEventHistoryData } from 'Types/types';

type OutcomeStats = {
    success: number;
    challenge: number;
    failure: number;
    total: number;
};

export const TargetStats = ({
    selectedStartDate,
    selectedEndDate,
    interval,
}: TimeIntervalProps & { selectedStartDate: number; selectedEndDate: number }) => {
    const tenantId = useTenant();

    const duration = intervalToDuration({ start: selectedStartDate, end: selectedEndDate });

    const currentStartDate = selectedStartDate;
    const currentEndDate = selectedEndDate;

    const previousStartDate = sub(currentStartDate, duration);
    const previousEndDate = sub(currentEndDate, duration);

    const {
        data: currentStats,
        loading: currentStatsLoading,
        error: currentStatsError,
    } = useQuery(GET_GLOBAL_EVENTS_HISTORY, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_TARGET',
            unit: interval,
            startDate: +currentStartDate,
            endDate: +currentEndDate,
            detailed: false,
        },
        skip: !tenantId,
    });

    const {
        data: previousStats,
        loading: previousStatsLoading,
        error: previousStatsError,
    } = useQuery(GET_GLOBAL_EVENTS_HISTORY, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_TARGET',
            unit: interval,
            startDate: +previousStartDate,
            endDate: +previousEndDate,
            detailed: false,
        },
        skip: !tenantId,
    });

    const { success, challenge, failure, total } = useMemo(() => {
        if (currentStats && previousStats) {
            const currentItems = currentStats.getEntityHistory.items;
            const previousItems = previousStats.getEntityHistory.items;

            let current = { success: 0, challenge: 0, failure: 0, total: 0 };
            if (currentItems && currentItems.length > 0) {
                current = currentItems.reduce((acc: OutcomeStats, item: BackendEventHistoryData) => {
                    return {
                        success: acc.success + item.successCount,
                        challenge: acc.challenge + item.challengeCount,
                        failure: acc.failure + item.denyCount,
                        total: acc.total + (item.successCount + item.challengeCount + item.denyCount),
                    };
                }, current);
            }

            let previous = { success: 0, challenge: 0, failure: 0, total: 0 };
            if (previousItems && previousItems.length > 0) {
                previous = previousItems.reduce((acc: OutcomeStats, item: BackendEventHistoryData) => {
                    return {
                        success: acc.success + item.successCount,
                        challenge: acc.challenge + item.challengeCount,
                        failure: acc.failure + item.denyCount,
                        total: acc.total + (item.successCount + item.challengeCount + item.denyCount),
                    };
                }, previous);
            }

            return {
                success: { current: current.success, previous: previous.success },
                challenge: { current: current.challenge, previous: previous.challenge },
                failure: { current: current.failure, previous: previous.failure },
                total: {
                    current: current.total,
                    previous: previous.total,
                },
            };
        }
        return {
            success: undefined,
            challenge: undefined,
            failure: undefined,
            total: undefined,
        };
    }, [currentStats, previousStats]);

    if (previousStatsLoading || currentStatsLoading) {
        return (
            <div className="text-center text-gray-300 flex flex-col items-center justify-center">
                <div className="h-8 w-8 loader mb-3" />
                Loading...
            </div>
        );
    }
    if (previousStatsError || currentStatsError) {
        return (
            <div className="text-center text-red-400 pt-2 flex flex-col items-center justify-center">
                <NoSymbolIcon className="h-8 w-8 text-red-400 mb-3" />
                Error loading results
            </div>
        );
    }

    return (
        <>
            <Stat
                title="Success Events"
                value={success?.current || 0}
                previousValue={success?.previous || 0}
                dotColor="rgba(101, 163, 13, 1)"
                className="w-1/2 md:w-auto flex flex-col items-center p-2"
            />
            <Stat
                title="Challenge Events"
                value={challenge?.current || 0}
                previousValue={challenge?.previous || 0}
                dotColor="rgba(245, 158, 11, 1)"
                className="w-1/2 md:w-auto flex flex-col items-center p-2"
            />
            <Stat
                title="Failure Events"
                value={failure?.current || 0}
                previousValue={failure?.previous || 0}
                dotColor="rgba(255, 99, 132, 1)"
                className="w-1/2 md:w-auto flex flex-col items-center p-2"
            />
            <Stat
                title="Total Events"
                value={total?.current || 0}
                previousValue={total?.previous || 0}
                className="w-1/2 md:w-auto flex flex-col items-center p-2"
            />
        </>
    );
};
