import { startOfHour, add, sub } from 'date-fns';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useCallback, useContext, useMemo, useState } from 'react';
import Select from 'react-select';
import { UnitInterval } from 'Types/types';

const unitOptions = [
    { value: 'STATS_UNITS_HOUR', label: 'Hourly' },
    { value: 'STATS_UNITS_DAY', label: 'Daily' },
    // { value: 'STATS_UNITS_MONTH', label: 'Monthly' },
];

const timeWindowOptionsByUnit = {
    STATS_UNITS_HOUR: [
        { value: '24', label: 'Last 24 Hours' },
        { value: '48', label: 'Last 48 Hours' },
        { value: '72', label: 'Last 72 Hours' },
    ],
    STATS_UNITS_DAY: [
        { value: '7', label: 'Last 7 Days' },
        { value: '14', label: 'Last 14 Days' },
        { value: '30', label: 'Last 30 Days' },
    ],
    // STATS_UNITS_MONTH: [{ value: '1', label: 'Last Month' }],
};

export const useChartTimeRange = () => {
    const { mapState } = useContext(IdentityMapContext);

    const [interval, setInterval] = useState<UnitInterval>('STATS_UNITS_HOUR');
    const [timeWindow, setTimeWindow] = useState(timeWindowOptionsByUnit.STATS_UNITS_HOUR[0]);
    const [timeWindowOptions, setTimeWindowOptions] = useState(timeWindowOptionsByUnit.STATS_UNITS_HOUR);

    const [narrowedTime, setNarrowedTime] = useState<[number, number] | undefined>(undefined);

    const { startDate, endDate } = useMemo(() => {
        if (timeWindow.value === '0') {
            return {
                startDate: mapState.selectedTime[0],
                endDate: mapState.selectedTime[1],
            };
        }

        const endDate = +startOfHour(add(new Date(), { hours: 1 }));
        let startDate = +endDate;

        switch (interval) {
            case 'STATS_UNITS_HOUR':
                startDate = +sub(startDate, { hours: parseInt(timeWindow.value) });
                break;
            case 'STATS_UNITS_DAY':
                startDate = +sub(startDate, { days: parseInt(timeWindow.value) });
                break;
            case 'STATS_UNITS_MONTH':
                startDate = +sub(startDate, { months: parseInt(timeWindow.value) });
                break;
        }

        return {
            startDate,
            endDate,
        };
    }, [interval, mapState.selectedTime, timeWindow.value]);

    const { narrowedStartDate, narrowedEndDate } = useMemo(() => {
        if (!narrowedTime) {
            return {
                narrowedStartDate: undefined,
                narrowedEndDate: undefined,
            };
        }
        return {
            narrowedStartDate: narrowedTime[0],
            narrowedEndDate: narrowedTime[1],
        };
    }, [narrowedTime]);

    const { selectedStartDate, selectedEndDate } = useMemo(() => {
        const selectedStartDate = narrowedStartDate ? narrowedStartDate : startDate;
        const selectedEndDate = narrowedEndDate ? narrowedEndDate : endDate;

        return {
            selectedStartDate,
            selectedEndDate,
        };
    }, [narrowedStartDate, narrowedEndDate, startDate, endDate]);

    const setNarrowedEpoch = (epoch: number | undefined) => {
        if (epoch) {
            let startDate = 0;
            let endDate = 0;
            switch (interval) {
                case 'STATS_UNITS_HOUR':
                    startDate = epoch;
                    endDate = +add(startDate, { hours: 1 });
                    break;
                case 'STATS_UNITS_DAY':
                    startDate = epoch;
                    endDate = +add(startDate, { days: 1 });
                    break;
                case 'STATS_UNITS_MONTH':
                    startDate = epoch;
                    endDate = +add(startDate, { months: 1 });
                    break;
            }
            setNarrowedTime([startDate, endDate]);
        } else {
            setNarrowedTime(undefined);
        }
    };

    const UnitSelect = useCallback(() => {
        return (
            <div data-test="unit-select">
                <Select
                    className="w-24"
                    classNamePrefix="select-xs"
                    isSearchable={false}
                    name="dataType"
                    defaultValue={unitOptions[0]}
                    options={unitOptions}
                    value={unitOptions.find((option) => option.value === interval)}
                    onChange={(e) => {
                        if (e) {
                            setNarrowedTime(undefined);
                            setInterval(e.value as UnitInterval);
                            setTimeWindowOptions(
                                timeWindowOptionsByUnit[e.value as keyof typeof timeWindowOptionsByUnit],
                            );
                            setTimeWindow(timeWindowOptionsByUnit[e.value as keyof typeof timeWindowOptionsByUnit][0]);
                        }
                    }}
                />
            </div>
        );
    }, [interval]);

    const TimeWindowSelect = useCallback(() => {
        return (
            <div data-test="time-window-select">
                <Select
                    className="w-28"
                    classNamePrefix="select-xs"
                    isSearchable={false}
                    name="dataType"
                    options={timeWindowOptions}
                    value={timeWindow}
                    onChange={(e) => {
                        if (e) {
                            setNarrowedTime(undefined);
                            setTimeWindow(e);
                        }
                    }}
                />
            </div>
        );
    }, [timeWindow, timeWindowOptions]);

    return {
        interval,
        timeWindow,
        startDate,
        endDate,
        narrowedTime,
        narrowedStartDate,
        narrowedEndDate,
        selectedStartDate,
        selectedEndDate,
        setNarrowedEpoch,
        UnitSelect,
        TimeWindowSelect,
    };
};
