import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { CubeTransparentIcon, QueueListIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'Library/Tooltip';
import { getIconSourceURL, getNodeIconElement } from 'Map/Graph/Icons';
import { Node as IdentityMapNode } from 'Types/types';
import { classNames, getDisplayName } from 'Utilities/utils';
import Permission from 'assets/icons/Permissions/Permission.png';
import UserGroup from 'assets/icons/UserGroups/UserGroup.png';
import { memo } from 'react';
import Avatar from 'react-avatar';
import { Handle, NodeProps, Position, Node } from 'reactflow';
import { NodeData } from './NavigatorTypes';
import User from 'assets/icons/Actors/User.png';

export const ChipComponent = ({ data, sourcePosition, targetPosition, selected, id }: NodeProps<NodeData>) => {
    return (
        <div
            className={classNames(
                'Chip w-[230px] h-[40px] pl-5 relative cursor-default',
                data.disabled ? 'pointer-events-none' : '',
            )}
        >
            <div
                className={classNames(
                    'px-2 w-full h-full flex items-center rounded-lg border shadow-md transition-all',
                    selected
                        ? 'bg-blue-800 border-white hover:bg-blue-800 hover:border-white'
                        : 'bg-gray-600 border-gray-500 hover:bg-gray-500/90 hover:border-gray-400',
                    data.highlighted ? 'border-white' : '',
                    data.disabled ? 'border-transparent shadow-none !bg-gray-700' : '',
                )}
            >
                {data.tooltip ? (
                    <Tooltip label={data.tooltip} placement="right" className="ml-2 w-72">
                        <div
                            className={classNames(
                                'flex w-full items-center justify-start',
                                data.disabled ? 'opacity-50' : '',
                            )}
                        >
                            <div className="w-6 h-6 flex-shrink-0 justify-center items-center">
                                <NavigatorIcon data={data} id={id} />
                            </div>
                            <div className="flex-shrink ml-2 overflow-hidden">
                                <div className="text-xs truncate break-words">{data.name}</div>
                            </div>
                        </div>
                    </Tooltip>
                ) : (
                    <div
                        className={classNames(
                            'flex w-full items-center justify-start',
                            data.disabled ? 'opacity-50' : '',
                        )}
                    >
                        <div className="w-6 h-6 flex-shrink-0 justify-center items-center">
                            <NavigatorIcon data={data} id={id} />
                        </div>
                        <div className="flex-shrink ml-2 overflow-hidden">
                            <div className="text-xs truncate break-words">{data.name}</div>
                        </div>
                    </div>
                )}

                <Handle
                    type="target"
                    position={targetPosition || Position.Left}
                    style={{ left: 18 }}
                    className="!w-2 !h-2 !bg-transparent !border-none"
                />
                <Handle
                    type="source"
                    id="default"
                    position={sourcePosition || Position.Right}
                    className={classNames(
                        '!w-2 !h-2 !cursor-default',
                        data.hasOutgoingEdges ? '!bg-blue-700' : '!bg-transparent !border-none',
                    )}
                />
                <Handle
                    type="source"
                    id="nested"
                    position={Position.Left}
                    className={'!border-none !bg-transparent !ml-6'}
                />
            </div>
        </div>
    );
};

export const GroupChipComponent = ({ data, targetPosition }: NodeProps<NodeData>) => {
    return (
        <div
            className={classNames(
                'GroupChip w-[230px] h-[40px] relative cursor-default',
                data.disabled ? 'pointer-events-none' : '',
            )}
        >
            {data.grouped && (
                <>
                    <div
                        className={classNames(
                            'absolute top-0 left-0 w-full h-full px-1 flex items-center rounded-lg bg-gray-600 border border-gray-500/50 shadow-md',
                            data.highlighted ? 'border-white' : '',
                            data.disabled ? 'border !border-gray-800/50 !bg-gray-700 shadow-none' : '',
                        )}
                    />
                    <div
                        className={classNames(
                            'absolute -top-0.5 left-0.5 w-full h-full px-1 flex items-center rounded-lg bg-gray-600 border border-gray-500/50 shadow-md',
                            data.highlighted ? 'border-white' : '',
                            data.disabled ? 'border !border-gray-800/50 !bg-gray-700 shadow-none' : '',
                        )}
                    />
                    <div
                        className={classNames(
                            'absolute -top-1 left-1 w-full h-full px-1 flex items-center rounded-lg bg-gray-600 border border-gray-500/50 shadow-md',
                            data.highlighted ? 'border-white' : '',
                            data.disabled ? 'border !border-gray-800/50 !bg-gray-700 shadow-none' : '',
                        )}
                    />
                </>
            )}
            <div
                className={classNames(
                    'absolute w-full h-full px-2 flex items-center rounded-lg bg-gray-600 border border-gray-500 shadow-md transition-all ',
                    data.grouped ? '-top-1.5 left-1.5' : 'top-0 left-0',
                    data.highlighted ? 'border-white' : '',
                    data.disabled ? 'border-0 shadow-none !bg-gray-700' : '',
                )}
            >
                <div
                    className={classNames(
                        'flex w-full items-center justify-between',
                        data.disabled ? 'opacity-50' : '',
                    )}
                >
                    <div className="w-6 h-6 flex justify-center items-center">
                        <img src={data.icon} />
                    </div>
                    <div className="ml-2 flex-1">
                        <div className="text-xs capitalize">{data.name}</div>
                    </div>
                    {/* {data.grouped ? (
                        <ChevronDownIcon className="h-5 w-5 text-gray-200" />
                    ) : (
                        <ChevronUpIcon className="h-5 w-5 text-gray-200" />
                    )} */}
                </div>
            </div>
            {targetPosition && (
                <Handle
                    type="target"
                    position={targetPosition || Position.Left}
                    style={{ top: 10 }}
                    className="!w-2 !h-2 !bg-transparent !border-none"
                />
            )}
        </div>
    );
};

export const SubGroupChipComponent = ({ data, sourcePosition }: NodeProps<NodeData>) => {
    return (
        <div
            className={classNames(
                'SubGroupChip w-[190px] h-[40px] left-2.5 relative',
                data.disabled ? 'pointer-events-none' : '',
            )}
        >
            {data.grouped && (
                <>
                    <div
                        className={classNames(
                            'absolute top-0 left-0 w-full h-full px-1 flex items-center rounded-lg bg-gray-600 border border-gray-500/50 shadow-md',
                            data.highlighted ? 'border-white' : '',
                            data.disabled ? 'border !border-gray-800/50 !bg-gray-700 shadow-none' : '',
                        )}
                    />
                    <div
                        className={classNames(
                            'absolute -top-0.5 left-0.5 w-full h-full px-1 flex items-center rounded-lg bg-gray-600 border border-gray-500/50 shadow-md',
                            data.highlighted ? 'border-white' : '',
                            data.disabled ? 'border !border-gray-800/50 !bg-gray-700 shadow-none' : '',
                        )}
                    />
                    <div
                        className={classNames(
                            'absolute -top-1 left-1 w-full h-full px-1 flex items-center rounded-lg bg-gray-600 border border-gray-500/50 shadow-md',
                            data.highlighted ? 'border-white' : '',
                            data.disabled ? 'border !border-gray-800/50 !bg-gray-700 shadow-none' : '',
                        )}
                    />
                </>
            )}
            <div
                className={classNames(
                    'absolute w-full h-full px-2 flex items-center rounded-lg bg-gray-600 border border-gray-500 shadow-md transition-all hover:bg-gray-500  hover:border-gray-400',
                    data.grouped ? '-top-1.5 left-1.5' : 'top-0 left-0',
                    data.highlighted ? 'border-white' : '',
                    data.disabled ? 'border-0 shadow-none !bg-gray-700' : '',
                )}
            >
                <div
                    className={classNames(
                        'flex w-full items-center justify-between',
                        data.disabled ? 'opacity-50' : '',
                    )}
                >
                    <div className="w-6 h-6 flex justify-center items-center">
                        <img src={data.icon} />
                    </div>
                    <div className="ml-2 flex-1">
                        <div className="text-xs max-h-4 overflow-hidden">{data.name}</div>
                    </div>
                    {data.grouped ? (
                        <ChevronDownIcon className="h-5 w-5 text-gray-200" />
                    ) : (
                        <ChevronUpIcon className="h-5 w-5 text-gray-200" />
                    )}
                </div>
            </div>
            {sourcePosition && !data.grouped && (
                <Handle type="source" position={sourcePosition || Position.Right} className="!w-2 !h-2 !bg-blue-700" />
            )}
        </div>
    );
};

export const HeaderChipComponent = ({ data }: NodeProps<NodeData>) => {
    return <div className="text-xs uppercase text-gray-400 font-semibold tracking-widest">{data.label}</div>;
};

export const InfoChipComponent = ({ data }: NodeProps<NodeData>) => {
    return (
        <div className="text-xs uppercase text-gray-400 font-semibold tracking-widest w-[230px] text-center">
            {data.name}
        </div>
    );
};

export const LoadMoreChipComponent = ({ data, sourcePosition, targetPosition }: NodeProps<NodeData>) => {
    return (
        <Tooltip label="Click to show more" placement="bottom">
            <div className={classNames('Chip w-[230px] h-[40px] pl-5 relative cursor-default')}>
                <div
                    className={classNames(
                        'px-2 w-full h-full flex items-center rounded-lg border shadow-md transition-all',
                        'bg-gray-600 border-gray-500 hover:bg-gray-500/90 hover:border-gray-400',
                        data.highlighted ? 'border-white' : '',
                        data.disabled ? 'border-transparent shadow-none !bg-gray-700' : '',
                    )}
                >
                    <div className="w-5 h-5 flex-shrink-0 justify-center items-center">
                        <QueueListIcon />
                    </div>
                    <div className="flex-shrink ml-2 overflow-hidden">
                        <div className="text-xs truncate break-words">{data.name}</div>
                    </div>
                </div>

                <Handle
                    type="target"
                    position={targetPosition || Position.Left}
                    style={{ left: 18 }}
                    className="!w-2 !h-2 !bg-transparent !border-none"
                />
                <Handle
                    type="source"
                    position={sourcePosition || Position.Right}
                    className={classNames(
                        '!w-2 !h-2 !cursor-default',
                        data.hasOutgoingEdges ? '!bg-blue-700' : '!bg-transparent !border-none',
                    )}
                />
            </div>
        </Tooltip>
    );
};

export const Chip = ChipComponent;
export const GroupChip = GroupChipComponent;
export const SubGroupChip = memo(SubGroupChipComponent);
export const LoadMoreChip = LoadMoreChipComponent;
export const HeaderChip = memo(HeaderChipComponent);
export const InfoChip = memo(InfoChipComponent);

const NavigatorIcon = ({ data, id }: Pick<NodeProps<NodeData>, 'data' | 'id'>) => {
    const node = createIdentityMapNodeFromNodeData({ data, id });
    if (data.name === 'All Actors' || data.name === 'All Targets') {
        return <CubeTransparentIcon className="h-6 w-6" />;
    }

    let src = '';
    switch (data.label) {
        case 'actor':
            return (
                <Avatar
                    size="100%"
                    name={getDisplayName(node)}
                    round={true}
                    maxInitials={2}
                    className="-mt-[1px] h-6 w-6"
                />
            );
        case 'target':
            src = getIconSourceURL(getNodeIconElement(node));
            break;
        case 'group':
            src = UserGroup;
            break;
        case 'policy':
            src = Permission;
            break;
        default:
            src = Permission;
    }

    return <img src={src} className="w-6 h-6" />;
};

export const createIdentityMapNodeFromNodeData = ({
    data,
    id,
}: Pick<NodeProps<NodeData>, 'data' | 'id'>): IdentityMapNode => {
    return {
        id: id,
        name: data.name,
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        group: data.label,
        label: data.label,
        props: { displayName: data.name },
        tags: [],
        attributes: [],
    };
};

export const createReactFlowNodeFromIdentityMapNode = (node: IdentityMapNode): Node<NodeData> => {
    return {
        id: String(node.id),
        type: 'chip',
        hidden: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: node.label || '', name: getDisplayName(node), related: true, icon: User },
        position: { x: 0, y: 0 },
    };
};
