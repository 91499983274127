import { getAuthProvider } from 'Hooks/Auth';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';
import KeycloakProviderWithHistory from './KeycloakProviderWithHistory';
import { NoAuthProvider } from './AuthStatusMessageComponents';

const AuthenticationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const provider = getAuthProvider();
    switch (provider) {
        case 'keycloak':
            return <KeycloakProviderWithHistory children={children} />;
        case 'auth0':
            return <Auth0ProviderWithHistory children={children} />;
        default:
            return <NoAuthProvider provider={provider} />;
    }
};

export default AuthenticationProvider;
