import { getUsersTimezone } from 'Utilities/utils';
import { addHours, format, startOfHour, subHours } from 'date-fns';
import { WorldMap } from './Charts/WorldMap';
import { classNames } from 'Utilities/utils';
import { SkipToTime } from 'Library/SkipToTime';

import { DashboardTabProps } from './DashboardModalTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { Tooltip } from 'Library/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

export const Location = ({ open, selectedStartDate, selectedEndDate }: DashboardTabProps) => {
    const { worldMapRestrictedTimeRange } = useFlags();

    const { startDate, endDate } = useMemo(() => {
        if (worldMapRestrictedTimeRange) {
            const now = Date.now();
            const end = startOfHour(addHours(now, 1));
            const start = subHours(end, worldMapRestrictedTimeRange);
            console.log(`Restricting world map time range to ${worldMapRestrictedTimeRange} hours`, start, end);
            return { startDate: +start, endDate: +end };
        }
        return { startDate: selectedStartDate, endDate: selectedEndDate };
    }, [worldMapRestrictedTimeRange, selectedStartDate, selectedEndDate]);

    if (!open) return <> </>;

    return (
        <>
            <div className={classNames('bg-gray-700 p-4 rounded-md mb-4')}>
                <div className="flex items-center justify-between mb-3">
                    <div>
                        <h3 className="text-xs text-gray-400 flex  my-3 " data-test="selected-date-time">
                            <span className="uppercase tracking-widest ">
                                {format(startDate, 'EEE do, HH:mm')} - {format(endDate, 'EEE do, HH:mm')}{' '}
                                <span className="text-gray-500">({getUsersTimezone()})</span>
                            </span>
                            <SkipToTime startDate={startDate} endDate={endDate} />
                            {worldMapRestrictedTimeRange && (
                                <Tooltip
                                    label={`Time restricted to ${worldMapRestrictedTimeRange} hours due to volume of events`}
                                >
                                    <InformationCircleIcon className="ml-1 h-4 w-4" />
                                </Tooltip>
                            )}
                        </h3>
                    </div>
                </div>

                <WorldMap startDate={startDate} endDate={endDate} />
            </div>
        </>
    );
};
