import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientInitOptions } from '@react-keycloak/core';
import { useEffect, useState } from 'react';
import { InvalidAuthProviderConfig } from './AuthStatusMessageComponents';

const keycloakConfig = {
    url: window.__env__.KEYCLOAK_URL,
    realm: window.__env__.KEYCLOAK_REALM,
    clientId: window.__env__.KEYCLOAK_CLIENT_ID,
};

const kc = new Keycloak(keycloakConfig);

const options: AuthClientInitOptions = {
    redirectUri: window.location.origin,
    onLoad: 'login-required',
    checkLoginIframe: false,
};

const KeycloakProviderWithHistory = ({ children }: { children: JSX.Element }) => {
    const [validProvider, setValidProvider] = useState(true);

    useEffect(() => {
        console.log('Authentication will be provided by Keycloak');

        if (!keycloakConfig.url || !keycloakConfig.realm || !keycloakConfig.clientId) {
            console.error('Keycloak configuration variables are not correctly specified:', keycloakConfig);
            setValidProvider(false);
        }
    }, []);

    return validProvider ? (
        <ReactKeycloakProvider authClient={kc} initOptions={options} LoadingComponent={<Loading />}>
            <KeyCloak>{children}</KeyCloak>
        </ReactKeycloakProvider>
    ) : (
        <InvalidAuthProviderConfig />
    );
};

export default KeycloakProviderWithHistory;

const KeyCloak = ({ children }: { children: JSX.Element }) => {
    return <>{children}</>;
};

const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs" data-testid="auth-spinner">
                Authenticating ...
            </p>
        </div>
    </div>
);
