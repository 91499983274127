import { Transition } from '@headlessui/react';

import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Helmet } from 'react-helmet-async';
import { Navigator } from './Navigator';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ReactFlowProvider } from 'reactflow';
import { useDebounce, useTenant } from 'Hooks/Hooks';
import { LIST_PROVIDERS } from 'Graph/queries';
import { useQuery } from '@apollo/client';
import { Tooltip } from 'Library/Tooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';

// const actorTagOptions = [
//     { value: 'actor_internal', label: 'Internal user' },
//     { value: 'actor_external', label: 'External user' },
//     { value: 'actor_machine', label: 'Machine user' },
// ];

// const targetTagOptions = [{ value: 'target_external', label: 'External target' }];

// const accessOptions = [
//     { value: 'target_accessed', label: 'Targets accessed' },
//     { value: 'target_unaccessed', label: 'Targets unaccessed' },
//     { value: 'actor_accessed', label: 'Actors accessed' },
//     { value: 'actor_unaccessed', label: 'Actors unaccessed' },
// ];

// const accessOptionRange = [
//     { value: 30, label: 'in the last 30 days' },
//     { value: 60, label: 'in the last 60 days' },
//     { value: 90, label: 'in the last 90 days' },
// ];

export const NavigatorModal = (): JSX.Element => {
    const { navigatorSearchBox } = useFlags();
    const tenantId = useTenant();
    const { dispatch } = useContext(IdentityMapContext);
    const closeNavigator = () => dispatch({ type: 'toggle-navigator' });
    useHotkeys('esc', closeNavigator);

    const [autoHide, setAutoHide] = useState(true);
    const [hideAllPermissions, setHideAllPermissions] = useState(true);
    const [quickSearch, setQuickSearch] = useState<string>('');
    const debouncedQuickSearchValue = useDebounce<string>(quickSearch, 200);

    const [providersById, setProvidersById] = useState<Record<string, object>>({});

    const {
        loading: loadingProviders,
        error: errorProviders,
        data: dataProviders,
    } = useQuery(LIST_PROVIDERS, { variables: { tenantId } });

    useEffect(() => {
        if (dataProviders && dataProviders.listProviders) {
            const providers = dataProviders.listProviders;
            const providersById = providers.reduce((memo: Record<string, string>, provider: any) => {
                if (['azuread', 'okta'].includes(provider.type)) {
                    memo[provider.providerId] = { ...provider, enabled: true };
                }
                return memo;
            }, {});
            providersById['unknown'] = { providerId: 'unknown', name: 'Unspecified', enabled: true };
            console.log('providersById', providersById);
            setProvidersById(providersById);
        }
    }, [dataProviders]);

    const onProviderToggle = (providerId: string, enabled: boolean) => {
        setProvidersById({ ...providersById, [providerId]: { ...providersById[providerId], enabled } });
    };

    const providersLoaded = !loadingProviders && !errorProviders && Object.keys(providersById).length > 0;

    const sortedProviders = Object.values(providersById).sort((a: any, b: any) => a.name.localeCompare(b.name));

    return (
        <Transition.Root show={true} as={Fragment}>
            <div className="fixed bottom-0 z-10 inset-0 overflow-y-auto top-[56px] h-[calc(100vh-56px)]">
                <Helmet>
                    <title>Permissions Navigator</title>
                </Helmet>
                <div className="flex items-end justify-center text-center sm:block sm:p-0">
                    <div
                        className="fixed top-[56px] h-[calc(100vh-56px)] w-[100vw] after:inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                        onClick={closeNavigator}
                    />

                    <div
                        id="Navigator"
                        className="absolute left-[50%] translate-x-[-50%] inline-block bg-gray-800 rounded-lg text-left shadow-xl transform transition-all w-[90vw] xxl:w-[75vw] mt-14 align-top md:mt-5"
                    >
                        <div
                            id="Header"
                            className="flex justify-between items-start p-4 pt-3 pb-3 border-b border-gray-700"
                        >
                            <h1 className="text-md font-semibold tracking-wider">Permissions Navigator</h1>
                        </div>
                        <div className="flex items-stretch relative overflow-hidden">
                            <div className="p-4 w-64 border-r border-gray-700 xs:collapse">
                                {navigatorSearchBox && (
                                    <div className="mb-5 relative">
                                        <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2">
                                            Search
                                        </p>
                                        <div className="flex items-center">
                                            <input
                                                type="search"
                                                className="input-gray text-xs w-full pl-8"
                                                placeholder="Enter search term..."
                                                value={quickSearch}
                                                onChange={(e) => setQuickSearch(e.target.value)}
                                            />
                                            <MagnifyingGlassIcon className="absolute left-2 w-4 h-4 text-gray-400" />
                                        </div>
                                    </div>
                                )}

                                <div className="mb-5 relative">
                                    <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2">
                                        Options
                                    </p>
                                    <div className="bg-gray-600 rounded-md p-2 mb-5 space-y-2 border border-gray-500">
                                        {/* <label className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="chk-box w-4 h-4 bg-gray-500 focus:ring-0 focus:outline-none"
                                            />
                                            <p className="pl-2 text-xs text-gray-300">Auto-collapse groups</p>
                                        </label> */}
                                        <label className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="chk-box w-4 h-4 bg-gray-500 focus:ring-0 focus:outline-none"
                                                checked={autoHide}
                                                onChange={(e) => setAutoHide(e.target.checked)}
                                            />
                                            <p className="pl-2 text-xs text-gray-300">Hide unrelated objects</p>
                                        </label>
                                        <label className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="chk-box w-4 h-4 bg-gray-500 focus:ring-0 focus:outline-none"
                                                checked={hideAllPermissions}
                                                onChange={(e) => setHideAllPermissions(e.target.checked)}
                                            />
                                            <p className="pl-2 text-xs text-gray-300">Hide "All" permissions</p>
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-5 relative">
                                    <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2">
                                        Providers
                                    </p>
                                    <div className="bg-gray-600 rounded-md p-2 mb-5 space-y-2 border border-gray-500">
                                        {sortedProviders.map((provider: any) => {
                                            return (
                                                <label className="flex items-center" key={provider.providerId}>
                                                    <input
                                                        type="checkbox"
                                                        className="chk-box w-4 h-4 bg-gray-500 focus:ring-0 focus:outline-none"
                                                        checked={provider.enabled}
                                                        onChange={() =>
                                                            onProviderToggle(provider.providerId, !provider.enabled)
                                                        }
                                                    />
                                                    <p className="pl-2 text-xs text-gray-300">{provider.name}</p>
                                                </label>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="mb-5 relative">
                                    <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2">
                                        Policy
                                    </p>
                                    <div className="bg-gray-600 rounded-md p-2 mb-5 space-y-2 border border-gray-500">
                                        <label className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="chk-box text-blue-700/50 w-4 h-4 bg-gray-500 focus:ring-0 focus:outline-none"
                                                checked={true}
                                                disabled={true}
                                            />
                                            <p className="pl-2 text-xs text-gray-300">Conditional Access</p>
                                        </label>
                                        <Tooltip label="Coming soon!">
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="chk-box w-4 h-4 bg-gray-500 focus:ring-0 focus:outline-none"
                                                    checked={false}
                                                    disabled={true}
                                                />
                                                <p className="pl-2 text-xs text-gray-400">Application Permissions</p>
                                            </label>
                                        </Tooltip>
                                    </div>
                                </div>

                                {/* <div>
                                        <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2">
                                            Access Status
                                        </p>
                                        <Select
                                            classNamePrefix="select-xs"
                                            className="group"
                                            placeholder="Select access status..."
                                            options={accessOptions}
                                        />
                                        <Select
                                            classNamePrefix="select-xs"
                                            className="group"
                                            placeholder="Select time range..."
                                            options={accessOptionRange}
                                        />
                                    </div> */}
                                {/* <div className="mb-5">
                                    <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2">
                                        Tag Filters
                                    </p>
                                    <div>
                                        <Select
                                            classNamePrefix="select-xs"
                                            className="group"
                                            options={actorTagOptions}
                                            placeholder="Actor tags..."
                                            isClearable={false}
                                            isMulti
                                        />
                                        <Select
                                            classNamePrefix="select-xs"
                                            className="group"
                                            options={targetTagOptions}
                                            placeholder="Target tags..."
                                            isClearable={false}
                                            isMulti
                                        />
                                    </div>
                                </div>
                                <div className="mb-5 relative">
                                    <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2">
                                        Advanced Filters
                                    </p>
                                    <div className="full drop-shadow-md rounded-md relative border border-gray-600">
                                        <div className="flex flex-col">
                                            <select className="flex-auto pl-3 pr-7 py-2 text-xs bg-gray-900 border-0 border-b-0 border-gray-400 focus:ring-0 focus:border-1 focus:border-gray-400 focus:outline-none rounded-t-md text-white">
                                                <option value="target">Target</option>
                                                <option value="actor">Actor</option>
                                                <option value="device">Device</option>
                                                <option value="identity">Identity</option>
                                                <option value="application">Application</option>
                                                <option value="link">Identity {'→'} Target</option>
                                            </select>
                                            <select className="flex-auto pl-3 pr-7 py-2 text-xs bg-gray-900 border-l-0 border-r-0 border-gray-700 focus:ring-0 focus:border-1 focus:border-gray-600 focus:outline-none text-white">
                                                <option value="name">name</option>
                                                <option value="domain">domain</option>
                                                <option value="tags">tags</option>
                                                <option value="id">IDs</option>
                                            </select>
                                            <select className="flex-auto pl-3 pr-7 py-2 text-xs bg-gray-900 border-0 border-b-0 border-gray-400 focus:ring-0 focus:border-1 focus:border-gray-400 focus:outline-none text-white">
                                                <option value="target">matches</option>
                                                <option value="actor">contains</option>
                                                <option value="device">does not match</option>
                                                <option value="device">does not contain</option>
                                                <option value="device">regular expression</option>
                                            </select>
                                        </div>
                                        <input
                                            type="text"
                                            className="block w-full px-3 py-2 rounded-none rounded-b-md border-0 border-t focus:ring-0 focus:border-gray-600 border-gray-600 text-xs bg-gray-700 text-white"
                                            placeholder="Enter filter value..."
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div className="bg-gray-700/50 flex-1 h-[calc(100vh-168px)] shadow-[2px_2px_4px_0_rgba(0,0,0,0.25)_inset]">
                                {providersLoaded ? (
                                    <ReactFlowProvider>
                                        <Navigator
                                            quickSearch={debouncedQuickSearchValue}
                                            autoHide={autoHide}
                                            hideAllPermissions={hideAllPermissions}
                                            providerIds={Object.values(providersById)
                                                .filter((p: { enabled: boolean }) => p.enabled)
                                                .map((p: { providerId: string }) => p.providerId)}
                                            clearQuickSearch={() => setQuickSearch('')}
                                        />
                                    </ReactFlowProvider>
                                ) : (
                                    <div className="flex items-center justify-center h-full">
                                        <div className="loader" />
                                    </div>
                                )}
                            </div>
                            {/* <div
                                className={classNames(
                                    'absolute top-0 right-[-1px] h-full p-4 w-64 border-l border-gray-600 bg-gray-800 transition-all',
                                    openPanel ? 'translate-x-[0%]' : 'translate-x-[calc(100%)]',
                                )}
                            >
                                <button
                                    type="button"
                                    onClick={() => setOpenPanel(!openPanel)}
                                    className={classNames(
                                        'rounded-l-md h-10 absolute top-3 left-[-32px] flex items-center justify-center border border-r-0 border-gray-600 transition-all pointer-events-auto focus:outline-none',
                                        openPanel ? 'bg-gray-800 hover:bg-gray-900 w-8' : 'w-8 bg-gray-900',
                                    )}
                                >
                                    {openPanel ? (
                                        <ArrowLeftOnRectangleIcon className="rotate-180 h-4 w-4 text-gray-300 block" />
                                    ) : (
                                        <ArrowRightOnRectangleIcon className="rotate-180 h-4 w-4 text-gray-300 block" />
                                    )}
                                </button>
                            </div> */}
                        </div>
                        <button
                            type="button"
                            className="text-white text-xs rounded-full p-1 bg-gray-900 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                            onClick={() => {
                                closeNavigator();
                            }}
                        >
                            <XMarkIcon className="h-4 w-4 text-gray-200" />
                        </button>
                    </div>
                </div>
            </div>
        </Transition.Root>
    );
};
