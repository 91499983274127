import { Provider } from 'Types/types';
import { useClipboard } from 'use-clipboard-copy';

export type ProviderAuthOutput = {
    provider: Provider;
    authHeader: string;
    url: string;
};

interface ProviderAuthResponseProps {
    authData: ProviderAuthOutput;
}

export const ProviderAuthResponse = ({ authData }: ProviderAuthResponseProps): JSX.Element => {
    const urlClipboard = useClipboard({
        copiedTimeout: 2000,
    });
    const authClipboard = useClipboard({
        copiedTimeout: 2000,
    });

    return (
        <div className="space-y-4">
            <p>
                <span className="text-yellow-500 font-semibold">Pending!</span> Your new integration was created.
            </p>
            <p>
                To complete setting up this provider, it requires a WebHook to be configured in{' '}
                <span className="capitalize">{authData.provider.type}</span>.
            </p>
            <p>To set the WebHook up, you will need these authentication credentials.</p>
            <p>Please note them down as they will be shown only once.</p>
            <div className="w-2/3 space-y-4 ml-4">
                <div>
                    <label htmlFor="email" className="block font-semibold">
                        Web Hook URL
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm h-8">
                        <div className="relative flex items-stretch flex-grow focus-within:z-10 ">
                            <input
                                type="text"
                                name="url"
                                id="url"
                                value={authData.url}
                                readOnly={true}
                                placeholder="https://okta.example.com/api/v1/webhooks/incoming/1234567890"
                                className="input-gray rounded-none text-xs select-all"
                            />
                        </div>
                        <button
                            onClick={() => urlClipboard.copy(authData.url)}
                            className="-ml-px btn inline-flex bg-gray-800 border border-gray-500 font-medium rounded-r focus:z-10"
                        >
                            <span>{urlClipboard.copied ? 'Copied!' : 'Copy'}</span>
                        </button>
                    </div>
                </div>
                <div>
                    <label htmlFor="email" className="block font-semibold">
                        Web Hook Authorization Code
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm h-8">
                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                            <input
                                type="text"
                                name="authorization"
                                id="authorization"
                                value={authData.authHeader}
                                readOnly={true}
                                placeholder="Y2xpZW50X2lkOmNsaWVudF9zZWNyZXQ="
                                className="input-gray rounded-none text-xs select-all"
                            />
                        </div>
                        <button
                            onClick={() => authClipboard.copy(authData.authHeader)}
                            className="-ml-px btn inline-flex bg-gray-800 border border-gray-500 font-medium rounded-r focus:z-10"
                        >
                            <span>{authClipboard.copied ? 'Copied!' : 'Copy'}</span>
                        </button>
                    </div>
                </div>
                <a
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(
                            authData,
                            (key, value) => {
                                if (key === '__typename') {
                                    return undefined;
                                }
                                return value;
                            },
                            '\t',
                        ),
                    )}`}
                    download="auth-credentials.json"
                    className="btn w-44"
                >
                    Download Credentials
                </a>
            </div>
        </div>
    );
};
