import { Providers } from 'Configuration/Providers/Providers';
import { useHistory } from 'react-router-dom';
import { Header, NextButton, WelcomeComponent } from './WelcomeComponents';

const Review = ({ prevStep }: WelcomeComponent): JSX.Element => {
    const history = useHistory();
    return (
        <div>
            <Header title="Review Your Setup" previous={prevStep} />
            <div className="mt-6 text-xs text-gray-300">
                <div className="space-y-6">
                    <p>SailPoint Identity Risk is collecting data from your environment. </p>
                    <p>
                        After, you will be able to inspect, model, visualize, and ultimately enforce access through the
                        identity map SailPoint Identity Risk will create.
                    </p>
                    <p>You have configured:</p>
                </div>
                <div className="mt-2 ml-2">
                    <Providers onEdit={() => {}} />
                </div>
            </div>
            <NextButton title="Launch SailPoint Identity Risk" next={() => history.push('/identity-map')} />
        </div>
    );
};

export default Review;
