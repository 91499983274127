import { OAuthError, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { useKeycloak } from '@react-keycloak/web';
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

type JwtPayload = {
    'https://doublezero.io/tenant': string;
    'https://doublezero.io/roles': string[] | undefined;
};

export const useAuth = () => {
    return (() => {
        const provider = getAuthProvider();
        switch (provider) {
            case 'keycloak':
                return useKeyCloakAuth;
            case 'auth0':
            default:
                return useAuth0Auth;
        }
    })()();
};

const useKeyCloakAuth = (): Auth => {
    const { keycloak, initialized } = useKeycloak();
    const { authenticated, token } = keycloak;
    const [, setTenantId] = useState<string | null>(localStorage.getItem('tenantId'));
    const [, setAccessToken] = useState<string | null>();

    useEffect(() => {
        if (!authenticated || !initialized) {
            console.log('Waiting on user to be logged in before fetching access token');
            return;
        }

        if (token && keycloak && keycloak.tokenParsed) {
            setAccessToken(token);
            localStorage.setItem('accessToken', token);

            const tenantId = keycloak.tokenParsed.tenantId;
            if (tenantId) {
                localStorage.setItem('tenantId', tenantId);
                setTenantId(tenantId);
            } else {
                console.error('No tenantId found in token');
            }
        }
    }, [authenticated, initialized, token, keycloak]);

    const getToken = async () => {
        if (!authenticated || !initialized) {
            return undefined;
        }
        const refreshed = await keycloak.updateToken(60);
        if (refreshed) {
            console.log('Token was about to expire, successfully refreshed');
        }

        if (keycloak.token) {
            localStorage.setItem('accessToken', keycloak.token);
        }

        return keycloak.token;
    };

    const clearCacheAndLogout = useCallback(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('tenantId');
        keycloak?.logout();
    }, [keycloak]);

    return {
        login: keycloak?.login,
        logout: clearCacheAndLogout,
        userId: keycloak?.tokenParsed?.sub,
        tenantId: keycloak?.tokenParsed?.tenantId,
        token: keycloak?.token,
        getToken: getToken,
        email: keycloak?.tokenParsed?.email,
    };
};

const useAuth0Auth = (): Auth => {
    const { user, logout, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [tenantId, setTenantId] = useState<string | null>(localStorage.getItem('tenantId'));
    const [accessToken, setAccessToken] = useState<string | null>();
    const history = useHistory();

    useEffect(() => {
        if (!user) {
            console.log('Waiting on user to be logged in before fetching access token');
            return;
        }

        if (!accessToken) {
            getAccessTokenSilently()
                .then((token: string) => {
                    if (token) {
                        setAccessToken(token);
                        localStorage.setItem('accessToken', token);

                        const decoded_token = jwtDecode<JwtPayload>(token);
                        const tenantId = decoded_token['https://doublezero.io/tenant'];
                        if (tenantId) {
                            localStorage.setItem('tenantId', tenantId);
                            setTenantId(tenantId);
                        } else {
                            console.error('No tenantId found in token');
                        }
                        const roles = decoded_token['https://doublezero.io/roles'];

                        if (roles && roles.length === 0 && history.location.pathname !== '/account-activated') {
                            console.log('No roles found in token, redirecting user to no roles holding page');
                            history.push('/account-activated');
                        }
                    }
                })
                .catch((err: OAuthError) => {
                    console.log('Error retrieving Access Token', err);
                    if (err && err.error === 'login_required') {
                        // if we fail to get a token because login is required typically means
                        // we are on an exterior page (like sign-up)
                    } else {
                        console.log('There was an issue getting an access token from auth0:', err.message);
                    }
                });
        }
    }, [accessToken, getAccessTokenSilently, history, user]);

    const getToken = useCallback(async () => {
        try {
            const token = await getAccessTokenSilently();
            if (token != accessToken) {
                setAccessToken(token);
                localStorage.setItem('accessToken', token);
            }
            return token;
        } catch (err) {
            console.log('Error retrieving Access Token', err);
            return undefined;
        }
    }, [accessToken, getAccessTokenSilently]);

    const clearCacheAndLogout = useCallback(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('tenantId');
        logout({ returnTo: window.location.origin });
    }, [logout]);

    return {
        login: loginWithRedirect,
        logout: clearCacheAndLogout,
        userId: user?.sub,
        tenantId: tenantId,
        token: accessToken,
        getToken: getToken,
        email: user?.email,
    };
};

type Auth = {
    login: (options?: RedirectLoginOptions | Keycloak.KeycloakLoginOptions) => void;
    logout: () => void;
    userId: string | undefined;
    tenantId: string | null | undefined;
    token?: string | null | undefined;
    getToken: () => Promise<string | undefined>;
    email?: string | null | undefined;
};
const supportedProviders = ['keycloak', 'auth0'];

export const getAuthProvider = (): string | undefined => {
    const provider = import.meta.env.VITE_AUTH_PROVIDER || window.__env__.AUTH_PROVIDER;
    if (supportedProviders.includes(provider)) {
        return provider;
    }
};

export const useSelectedAuthProvider = (): string | undefined => {
    return getAuthProvider();
};
