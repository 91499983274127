import { ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { useGraphControls } from 'Hooks/GraphHooks';
import { useNodes } from 'Hooks/Hooks';
import { Tooltip } from 'Library/Tooltip';
import { ContextMenu } from 'Map/Components/ContextMenu';
import { Node, PolicyStats } from 'Types/types';
import { useEffect, useMemo, useState } from 'react';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { Cell } from 'react-table';
import { classNames, getDisplayName, getEntityTypeFromLabel } from 'Utilities/utils';

type CellProps = {
    cell: Cell<any, any>;
};

const SUCCESS_COLOR = 'rgba(101, 163, 13, 0.75)';
const CHALLENGE_COLOR = 'rgba(245, 158, 11, 0.75)';
const FAILURE_COLOR = 'rgba(225, 29, 72, 0.75)';

export const MfaCell = ({ cell }: CellProps) => (
    <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden">
        {cell.value == 'true' ? (
            <div className="flex text-xs items-center">
                <ShieldCheckIcon className="text-green-500 h-4 w-4 mr-1.5" /> On
            </div>
        ) : (
            <div className="flex text-xs items-center">
                <ShieldExclamationIcon className="text-red-500 h-4 w-4 mr-1.5" /> Off
            </div>
        )}
    </td>
);

export const ProviderCell = ({ cell }: CellProps) => (
    <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden">
        {cell.value == 'true' ? (
            <div className="flex text-xs items-center">
                <ShieldCheckIcon className="text-green-500 h-4 w-4 mr-1.5" /> On
            </div>
        ) : (
            <div className="flex text-xs items-center">
                <ShieldExclamationIcon className="text-red-500 h-4 w-4 mr-1.5" /> Off
            </div>
        )}
    </td>
);

export const DateCell = ({ cell }: CellProps) => (
    <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden">
        {format(new Date(cell.value), 'EEE MMM do HH:mm')}
    </td>
);

export const PercentageCell = ({ cell, color }: CellProps & { color: string }) => (
    <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden ">
        <div className="flex items-center justify-center h-full">
            <Tooltip label={`${cell.value}%`} placement="top">
                <div className="h-3 w-full bg-gray-700 " role="progressbar" aria-valuemin={0} aria-valuemax={100}>
                    <div className="h-3" style={{ width: `${cell.value}%`, backgroundColor: color }}></div>
                </div>
            </Tooltip>
        </div>
    </td>
);

export const StratifiedPercentageCell = ({ cell }: CellProps) => {
    const stats = cell.value as PolicyStats | undefined;
    if (stats) {
        return (
            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden ">
                <div className="flex items-center justify-center h-full">
                    <Tooltip
                        label={`Success ${stats?.success || 0}%, Challenge ${stats?.warning || 0}%, Failure ${
                            stats?.critical || 0
                        }%`}
                        placement="top"
                    >
                        <div
                            className="h-3 w-full bg-gray-700 flex overflow-hidden"
                            role="progressbar"
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >
                            <div
                                className="h-3 flex flex-col"
                                style={{ width: `${stats.success}%`, backgroundColor: SUCCESS_COLOR }}
                            ></div>
                            <div
                                className="h-3 flex flex-col"
                                style={{
                                    width: `${stats.warning}%`,
                                    backgroundColor: CHALLENGE_COLOR,
                                }}
                            ></div>
                            <div
                                className="h-3 flex flex-col"
                                style={{
                                    width: `${stats.critical}%`,
                                    backgroundColor: FAILURE_COLOR,
                                }}
                            ></div>
                        </div>
                    </Tooltip>
                </div>
            </td>
        );
    } else {
        return (
            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden ">
                <div className="flex items-center justify-center h-full">
                    <Tooltip label={`${cell.value}%`} placement="top">
                        <div
                            className="h-3 w-full bg-gray-700 "
                            role="progressbar"
                            aria-valuemin={0}
                            aria-valuemax={100}
                        ></div>
                    </Tooltip>
                </div>
            </td>
        );
    }
};

type TriggerEventWithPosition = TriggerEvent & { clientX: number; clientY: number };

export const NodeCell = ({ cell, width }: CellProps & { width?: string }) => {
    const { isNodeInExplorer, isNodeInQuery } = useGraphControls();
    const { show } = useContextMenu({});
    const [isContextMenuMounted, setIsContextMenuMounted] = useState(false);
    const [contextMenuEvent, setContextMenuEvent] = useState<TriggerEventWithPosition>();

    const node = cell.value === undefined ? undefined : (cell.value as Node);

    const nodeId = node?.id;

    const mountContextMenu = (e: TriggerEventWithPosition) => {
        e.stopPropagation();
        e.preventDefault();
        setContextMenuEvent(e);
        setIsContextMenuMounted(true);
    };

    useEffect(() => {
        if (isContextMenuMounted && contextMenuEvent) {
            show(contextMenuEvent, {
                id: `${nodeId}+${cell.column.id}+${cell.row.id}`,
                position: {
                    x: contextMenuEvent?.clientX,
                    y: contextMenuEvent?.clientY,
                },
            });
            setContextMenuEvent(undefined);
        }
    }, [cell.column.id, cell.row.id, contextMenuEvent, isContextMenuMounted, nodeId, show]);

    if (!node) {
        return (
            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden">
                Unknown node
            </td>
        );
    }

    return (
        <td
            {...cell.getCellProps()}
            className={classNames('py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden', width ? width : '')}
        >
            {isContextMenuMounted && <ContextMenu id={`${node.id}+${cell.column.id}+${cell.row.id}`} node={node} />}
            <div className="flex items-center justify-start relative">
                <div className="truncate">
                    {isNodeInExplorer(node) ? (
                        isNodeInQuery(node) ? (
                            <Tooltip label="Node is in explorer and search is toggled on" placement="top">
                                <span className="w-4 h-4 absolute left-0 top-0 bottom-0 my-auto mx-0">
                                    <span className="w-1 h-1 top-0 bottom-0 my-auto left-0 bg-blue-700 absolute rounded-full"></span>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip label="Node is in explorer" placement="top">
                                <span className="w-4 h-4 absolute left-0 top-0 bottom-0 my-auto mx-0">
                                    <span className="w-1 h-1 top-0 bottom-0 my-auto left-0 bg-gray-500 absolute rounded-full"></span>
                                </span>
                            </Tooltip>
                        )
                    ) : null}
                    <span
                        id={String(node.id)}
                        onContextMenu={mountContextMenu}
                        onClick={mountContextMenu}
                        className="hover:underline underline-offset-2 cursor-pointer ml-3"
                    >
                        {getDisplayName(node)}
                    </span>
                </div>
            </div>
        </td>
    );
};

export const LazyNodeCell = ({ cell, width }: CellProps & { width?: string }) => {
    const { isNodeInExplorer, isNodeInQuery } = useGraphControls();
    const { show } = useContextMenu({});
    const [isContextMenuMounted, setIsContextMenuMounted] = useState(false);
    const [contextMenuEvent, setContextMenuEvent] = useState<TriggerEventWithPosition>();

    const nodeId = cell.value;
    const nodeLabel = nodeId.split('/')[1];
    const nodeType = getEntityTypeFromLabel(nodeLabel);

    const nodeIds = useMemo(() => [nodeId], [nodeId]);

    const { loading, error, nodes } = useNodes(nodeIds, nodeType);

    const node = Object.values(nodes)[0];

    const mountContextMenu = (e: TriggerEventWithPosition) => {
        e.stopPropagation();
        e.preventDefault();
        setContextMenuEvent(e);
        setIsContextMenuMounted(true);
    };

    useEffect(() => {
        if (isContextMenuMounted && contextMenuEvent) {
            show(contextMenuEvent, {
                id: `${nodeId}+${cell.column.id}+${cell.row.id}`,
                position: {
                    x: contextMenuEvent?.clientX,
                    y: contextMenuEvent?.clientY,
                },
            });
            setContextMenuEvent(undefined);
        }
    }, [cell.column.id, cell.row.id, contextMenuEvent, isContextMenuMounted, nodeId, show]);

    if (loading || !node) {
        return <Skeleton cell={cell} />;
    }
    if (error) {
        return (
            <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden">
                Error retrieving details for node: {nodeId}
            </td>
        );
    }

    return (
        <td
            {...cell.getCellProps()}
            className={classNames('py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden', width ? width : '')}
        >
            {isContextMenuMounted && <ContextMenu id={`${node.id}+${cell.column.id}+${cell.row.id}`} node={node} />}
            <div className="flex items-center justify-start relative w-full h-full">
                <div className="truncate">
                    {isNodeInExplorer(node) ? (
                        isNodeInQuery(node) ? (
                            <Tooltip label="Node is in explorer and search is toggled on" placement="top">
                                <span className="w-4 h-4 absolute left-0 top-0 bottom-0 my-auto mx-0">
                                    <span className="w-1 h-1 top-0 bottom-0 my-auto left-0 bg-blue-700 absolute rounded-full"></span>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip label="Node is in explorer" placement="top">
                                <span className="w-4 h-4 absolute left-0 top-0 bottom-0 my-auto mx-0">
                                    <span className="w-1 h-1 top-0 bottom-0 my-auto left-0 bg-gray-500 absolute rounded-full"></span>
                                </span>
                            </Tooltip>
                        )
                    ) : null}
                    <span
                        id={String(node.id)}
                        onContextMenu={mountContextMenu}
                        onClick={mountContextMenu}
                        className="hover:underline underline-offset-2 cursor-pointer ml-3"
                    >
                        {getDisplayName(node)}
                    </span>
                </div>
            </div>
        </td>
    );
};

export const DefaultCell = ({ cell }: CellProps) => (
    <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden">
        <div className="flex items-center w-full h-full">{cell.value}</div>
    </td>
);

export const Skeleton = ({ cell }: CellProps) => (
    <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap text-ellipsis overflow-hidden">
        <div className="skeleton" />
    </td>
);
