import { useContext, useEffect, useState } from 'react';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { classNames } from 'Utilities/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

import User from 'assets/icons/Actors/User.png';
import Desktop from 'assets/icons/Devices/Desktop.png';
import DoubleZero from 'assets/icons/Identities/DoubleZero.png';
import Target from 'assets/icons/Targets/Target.png';
import Application from 'assets/icons/Applications/Application.png';

import Labels from 'assets/icons/Layers/Labels.png';
import Events from 'assets/icons/Layers/Events.png';
import Names from 'assets/icons/Layers/Names.png';
import Posture from 'assets/icons/Layers/Posture.png';

import BaseButton from 'assets/icons/Layers/Button/Base.png';
import LabelsButton from 'assets/icons/Layers/Button/Labels.png';
import EventsButton from 'assets/icons/Layers/Button/Events.png';
import NamesButton from 'assets/icons/Layers/Button/Names.png';
import PostureButton from 'assets/icons/Layers/Button/Posture.png';

import { useHotkeys } from 'react-hotkeys-hook';
import { useProductTutorial } from 'Hooks/Hooks';

export const HighlightOptions = (): JSX.Element => {
    const { dispatch, mapState } = useContext(IdentityMapContext);
    const { visible, layers } = mapState;
    const { postureLayerEnabled } = useFlags();
    const { runOnTutorialStep, moveToNextTutorialStep } = useProductTutorial();

    const toggleActors = () => {
        const { visible } = mapState;
        visible.actors = !visible.actors;
        dispatch({ type: 'set-visibility', visible });
    };
    const toggleIdentities = () => {
        const { visible } = mapState;
        visible.identities = !visible.identities;
        dispatch({ type: 'set-visibility', visible });
    };
    const toggleDevices = () => {
        const { visible } = mapState;
        visible.devices = !visible.devices;
        dispatch({ type: 'set-visibility', visible });
    };
    const toggleTargets = () => {
        const { visible } = mapState;
        visible.targets = !visible.targets;
        dispatch({ type: 'set-visibility', visible });
    };
    const toggleApplications = () => {
        const { visible } = mapState;
        visible.applications = !visible.applications;
        dispatch({ type: 'set-visibility', visible });
    };

    const toggleLabels = () => {
        const { layers } = mapState;
        layers.labels = !layers.labels;
        dispatch({ type: 'set-layers', layers });
    };
    const toggleEvents = () => {
        const { layers } = mapState;
        layers.events = !layers.events;
        dispatch({ type: 'set-layers', layers });
    };
    const toggleNames = () => {
        const { layers } = mapState;
        layers.names = !layers.names;
        dispatch({ type: 'set-layers', layers });
    };
    const togglePosture = () => {
        const { layers } = mapState;
        layers.posture = !layers.posture;
        dispatch({ type: 'set-layers', layers });
    };

    const [toggleLayersPanel, setToggleLayersPanel] = useState(false);
    const [hoverLayersPanel, setHoverLayersPanel] = useState(false);
    const onLayersMouseEnter = () => {
        !toggleLayersPanel ? setHoverLayersPanel(true) : setHoverLayersPanel(false);
    };
    const onLayersMouseLeave = () => {
        !toggleLayersPanel && setHoverLayersPanel(false);
    };

    const [toggleObjectsPanel, setToggleObjectsPanel] = useState(false);
    const [hoverObjectsPanel, setHoverObjectsPanel] = useState(false);
    const onObjectsMouseEnter = () => {
        !toggleObjectsPanel ? setHoverObjectsPanel(true) : setHoverObjectsPanel(false);
    };
    const onObjectsMouseLeave = () => {
        !toggleObjectsPanel && setHoverObjectsPanel(false);
    };

    useHotkeys('control+e', toggleEvents);
    useHotkeys('control+l', toggleLabels);
    useHotkeys('control+n', toggleNames);

    useEffect(() => {
        runOnTutorialStep('Node Types Button', () => {
            setToggleLayersPanel(false);
            setHoverLayersPanel(false);
        });
    }, [runOnTutorialStep]);

    return (
        <div className="space-y-3">
            <div className="flex" onMouseLeave={onLayersMouseLeave} id="layers-widget">
                <button
                    id="Layers"
                    type="button"
                    onClick={() => {
                        setToggleLayersPanel(!toggleLayersPanel);

                        runOnTutorialStep('Layers Button', () => {
                            moveToNextTutorialStep();
                        });
                    }}
                    onMouseEnter={onLayersMouseEnter}
                    className="h-20 w-20 flex items-center justify-center rounded-md bg-gray-800 border border-gray-600 hover:border-white mr-3 focus:ring-0 focus:outline-none pointer-events-auto"
                >
                    <div>
                        <div className="h-12 w-12 text-white mx-auto relative">
                            <img src={BaseButton} className="absolute left-0 top-0 w-full h-full" />
                            {layers.events && (
                                <img src={EventsButton} className="absolute left-0 top-0 w-full h-full" />
                            )}
                            {layers.posture && postureLayerEnabled && (
                                <img src={PostureButton} className="absolute left-0 top-0 w-full h-full" />
                            )}
                            {layers.labels && (
                                <img src={LabelsButton} className="absolute left-0 top-0 w-full h-full" />
                            )}
                            {layers.names && <img src={NamesButton} className="absolute left-0 top-0 w-full h-full" />}
                        </div>
                        <span className="block text-xs text-gray-400">Layers</span>
                    </div>
                </button>
                <div
                    className={classNames(
                        'h-panel relative -left-4 pl-4',
                        toggleLayersPanel || hoverLayersPanel ? 'show' : 'pointer-events-none',
                    )}
                >
                    <div className="flex h-20 rounded-md items-center justify-center bg-gray-900 bg-opacity-75 border border-gray-600 px-3 space-x-3">
                        <div className="w-14 mt-1" style={{ transitionDelay: '0.05s' }}>
                            <button
                                id="Labels"
                                className={classNames(
                                    'btn border-2 border-gray-800 bg-gray-800 rounded p-0 w-12 h-12 mb-1 mx-auto focus:outline-none focus:ring-0',
                                    layers.labels
                                        ? 'border-blue-500'
                                        : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                )}
                                onClick={toggleLabels}
                            >
                                <img src={Labels} className="h-10 w-10" />
                            </button>
                            <div className="text-xs text-center">Labels</div>
                        </div>
                        <div className="w-14 mt-1" style={{ transitionDelay: '0.1s' }}>
                            <button
                                id="Events"
                                className={classNames(
                                    'btn border-2 border-gray-800 bg-gray-800 rounded p-0 w-12 h-12 mb-1 mx-auto focus:outline-none focus:ring-0',
                                    layers.events
                                        ? 'border-blue-500'
                                        : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                )}
                                onClick={toggleEvents}
                            >
                                <img src={Events} className="h-10 w-10" />
                            </button>
                            <div className="text-xs text-center">Events</div>
                        </div>
                        <div className="w-14 mt-1" style={{ transitionDelay: '0.15s' }}>
                            <button
                                id="Names"
                                className={classNames(
                                    'btn border-2 border-gray-800 bg-gray-800 rounded p-0 w-12 h-12 mb-1 mx-auto focus:outline-none focus:ring-0',
                                    layers.names
                                        ? 'border-blue-500'
                                        : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                )}
                                onClick={toggleNames}
                            >
                                <img src={Names} className="h-10 w-10" />
                            </button>
                            <div className="text-xs text-center">Names</div>
                        </div>
                        {postureLayerEnabled && (
                            <div className="w-14 mt-1" style={{ transitionDelay: '0.2s' }}>
                                <button
                                    id="Posture"
                                    className={classNames(
                                        'btn border-2 border-gray-800 bg-gray-800 rounded p-0 w-12 h-12 mb-1 mx-auto focus:outline-none focus:ring-0',
                                        layers.posture
                                            ? 'border-blue-500'
                                            : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                    )}
                                    onClick={togglePosture}
                                >
                                    <img src={Posture} className="h-10 w-10" />
                                </button>
                                <div className="text-xs text-center">Posture</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex" onMouseLeave={onObjectsMouseLeave} id="node-visibility-widget">
                <button
                    id="NodeTypes"
                    type="button"
                    onClick={() => setToggleObjectsPanel(!toggleObjectsPanel)}
                    onMouseEnter={onObjectsMouseEnter}
                    className="h-20 w-20 flex items-center justify-center rounded-md bg-gray-800 border border-gray-600 hover:border-white mr-3 focus:ring-0 focus:outline-none pointer-events-auto"
                >
                    <div>
                        <div className="h-12 w-12 text-white mx-auto grid grid-cols-2 grid-rows-2 gap-1 place-items-center relative">
                            <img
                                src={User}
                                className={classNames('h-4 w-4', visible.actors ? '' : 'filter grayscale opacity-50')}
                            />
                            <img
                                src={Desktop}
                                className={classNames('h-4 w-4', visible.devices ? '' : 'filter grayscale opacity-50')}
                            />
                            <img
                                src={Application}
                                className={classNames(
                                    'h-4 w-4',
                                    visible.applications ? '' : 'filter grayscale opacity-50',
                                )}
                            />
                            <img
                                src={Target}
                                className={classNames('h-4 w-4', visible.targets ? '' : 'filter grayscale opacity-50')}
                            />
                            <img
                                src={DoubleZero}
                                className={classNames(
                                    'h-4 w-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
                                    visible.identities ? '' : 'filter grayscale opacity-50',
                                )}
                            />
                        </div>
                        <span className="block text-xs text-gray-400">Display</span>
                    </div>
                </button>
                <div
                    className={classNames(
                        'h-panel relative -left-4 pl-4',
                        toggleObjectsPanel || hoverObjectsPanel ? 'show' : 'pointer-events-none',
                    )}
                >
                    <div className="flex h-20 rounded-md items-center justify-center bg-gray-900 bg-opacity-75 border border-gray-600 px-3 space-x-3">
                        <div className="w-14 mt-1" style={{ transitionDelay: '0.05s' }}>
                            <button
                                className={classNames(
                                    'btn border-2 border-gray-800 bg-gray-800 rounded p-0 w-12 h-12 mb-1 mx-auto focus:outline-none focus:ring-0',
                                    visible.actors
                                        ? 'border-blue-500'
                                        : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                )}
                                onClick={toggleActors}
                            >
                                <img
                                    src={User}
                                    className={classNames('h-7 w-7', visible.actors ? '' : 'filter grayscale')}
                                />
                            </button>
                            <div className="text-xs text-center">Actors</div>
                        </div>
                        <div className="w-14 mt-1" style={{ transitionDelay: '0.1s' }}>
                            <button
                                className={classNames(
                                    'btn border-2 border-gray-800 bg-gray-800 rounded px-2 mb-1 mx-auto focus:outline-none focus:ring-0',
                                    visible.devices
                                        ? 'border-blue-500'
                                        : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                )}
                                onClick={toggleDevices}
                            >
                                <img
                                    src={Desktop}
                                    className={classNames('h-7 w-7', visible.devices ? '' : 'filter grayscale')}
                                />
                            </button>
                            <div className="text-xs text-center">Devices</div>
                        </div>
                        <div className="w-14 mt-1" style={{ transitionDelay: '0.15s' }}>
                            <button
                                className={classNames(
                                    'btn border-2 border-gray-800 bg-gray-800 rounded px-2 mb-1 mx-auto focus:outline-none focus:ring-0',
                                    visible.applications
                                        ? 'border-blue-500'
                                        : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                )}
                                onClick={toggleApplications}
                            >
                                <img
                                    src={Application}
                                    className={classNames('h-7 w-7', visible.applications ? '' : 'filter grayscale')}
                                />
                            </button>
                            <div className="text-xs text-center -ml-1.5">Apps</div>
                        </div>
                        <div className="w-14 mt-1" style={{ transitionDelay: '0.2s' }}>
                            <button
                                className={classNames(
                                    'btn border-2 border-gray-800 bg-gray-800 rounded px-2 mb-1 mx-auto focus:outline-none focus:ring-0',
                                    visible.identities
                                        ? 'border-blue-500'
                                        : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                )}
                                onClick={toggleIdentities}
                            >
                                <img
                                    src={DoubleZero}
                                    className={classNames('h-7 w-7', visible.identities ? '' : 'filter grayscale')}
                                />
                            </button>
                            <div className="text-xs text-center">Identities</div>
                        </div>
                        <div className="w-14 mt-1" style={{ transitionDelay: '0.25s' }}>
                            <button
                                className={classNames(
                                    'btn border-2 border-gray-800 bg-gray-800 rounded px-2 mb-1 mx-auto focus:outline-none focus:ring-0',
                                    visible.targets
                                        ? 'border-blue-500'
                                        : 'opacity-50 hover:opacity-100 bg-gray-800 hover:border-blue-500',
                                )}
                                onClick={toggleTargets}
                            >
                                <img
                                    src={Target}
                                    className={classNames('h-7 w-7', visible.targets ? '' : 'filter grayscale')}
                                />
                            </button>
                            <div className="text-xs text-center">Targets</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
