import { ForwardRefRenderFunction, Fragment, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
    BellIcon,
    BellSlashIcon,
    ChevronDownIcon,
    ChevronUpDownIcon,
    ChevronUpIcon,
    FunnelIcon,
} from '@heroicons/react/24/solid';
import { DefaultCell, LazyNodeCell, Skeleton } from 'Map/DataBrowser/DataBrowserCells';
import {
    useTable,
    useFilters,
    useSortBy,
    usePagination,
    useBlockLayout,
    useResizeColumns,
    Column,
    useRowSelect,
    Cell,
    useExpanded,
} from 'react-table';
import {
    fuzzyTextFilterFn,
    fuzzyNodeDisplayNameFilterFn,
    filterGreaterThan,
    DefaultColumnFilter,
    GreaterThanColumnFilter,
    fuzzyDateFilterFn,
    SelectAlertStatusFilter,
    sortGreaterThan,
    SelectNodeAsyncFilter,
    SelectAlertDefinitionFilter,
} from 'Library/TableComponents';
import { AlertDefinition } from 'Alerts/AlertTypes';
import { useMutation, useQuery } from '@apollo/client';
import { useTenant } from 'Hooks/Hooks';
import { LIST_ALERT_DEFINITIONS, RESOLVE_ALERTS } from 'Graph/queries';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { classNames } from 'Utilities/utils';
import { useToasts } from 'Hooks/Toasts';
import { getJobDescriptor } from './JobDescriptors';
import { Tooltip } from 'Library/Tooltip';
import { useUserPermissions } from 'Utilities/UserPermissions';
import { LIST_ALERTS } from 'Graph/typedQueries';
import { AlertStatus, ComparisonType, FilterField, ListAlertsFilter, SortOrder } from 'GeneratedGQL/graphql';

type ActiveAlertData = {
    id: string;
    raisedAt: number;
    status: AlertStatus;
    muted: boolean;
    observedCount: number;
    definitionId: string;
    alertDefinitionName: string;
    triggeredNodeId: string;
} & {
    alertConfig: AlertDefinition;
};

const unknownAlertConfig: AlertDefinition = {
    name: 'Unknown alert definition',
    message: 'Unknown alert definition',
    jobType: 'UNKNOWN',
    query: '',
    muted: false,
    mutedUntil: '',
    status: 'ALERT_UNKNOWN',
    destination: [],
    alertThreshold: 0,
    warningThreshold: 0,
};

const ActiveAlertsColumns = () =>
    [
        {
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
            ),
            id: 'select',
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: 'Raised At',
            accessor: 'raisedAt',
            filter: 'fuzzyDate',
            width: 160,
            disableFilters: true,
        },
        {
            Header: 'Event Date',
            accessor: 'eventDate',
            disableFilters: true,
            width: 160,
        },
        {
            Header: 'Name',
            accessor: 'alertDefinitionName',
            filter: 'fuzzyText',
            width: 370,
            disableSortBy: true,
            Filter: SelectAlertDefinitionFilter,
        },
        {
            Header: 'Alert Level',
            accessor: 'status',
            Filter: SelectAlertStatusFilter,
            filter: 'includes',
            width: 130,
        },
        {
            Header: 'Count',
            accessor: 'observedCount',
            width: 140,
            filter: 'greaterThan',
            Filter: GreaterThanColumnFilter,
        },
        {
            Header: 'Triggered Node',
            accessor: 'triggeredNodeId',
            width: 300,
            filter: 'includesSome',
            Filter: SelectNodeAsyncFilter,
            disableSortBy: true,
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            width: 100,
            disableFilters: true,
            disableSortBy: true,
        },
    ] as Column<ActiveAlertData>[];

export const ActiveAlerts = (): JSX.Element => {
    const [toggleFilters, setToggleFilters] = useState(false);
    const [activeAlerts, setActiveAlerts] = useState<ActiveAlertData[]>([]);
    const [definitionsById, setDefinitionsById] = useState<Record<string, AlertDefinition>>({});
    const [alertsLoadedOnce, setAlertsLoadedOnce] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [filter, setFilter] = useState<ListAlertsFilter>();

    const tenantId = useTenant();
    const { addToast } = useToasts();
    const { userCan } = useUserPermissions();

    const canResolveAlerts = userCan('resolve', '/alerts');

    const {
        loading: loadingDefinitions,
        error: errorDefinitions,
        data: dataDefinitions,
    } = useQuery(LIST_ALERT_DEFINITIONS, {
        variables: { tenantId },
        fetchPolicy: 'cache-and-network',
        pollInterval: 300000,
    });

    const [resolveAlerts, { loading: loadingResolveAlerts }] = useMutation(RESOLVE_ALERTS, {
        refetchQueries: ['listAlerts', 'getTotalAlertCount'],
    });

    const resolveAllSelectedAlerts = async () => {
        const alertIds = selectedFlatRows.map((row) => row.original.id);
        try {
            await resolveAlerts({ variables: { tenantId, resolveAlertsInput: { alertIds } } });
        } catch (e) {
            addToast('There was an issue resolving the selected alerts', 'failure');
            console.error(e);
        }
        toggleAllRowsSelected(false);
    };

    const resolveAllAlerts = async () => {
        try {
            await resolveAlerts({ variables: { tenantId, resolveAlertsInput: { alertIds: [] } } });
        } catch (e) {
            addToast('There was an issue resolving the selected alerts', 'failure');
            console.error(e);
        }
        toggleAllRowsSelected(false);
    };

    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            fuzzyDate: fuzzyDateFilterFn,
            fuzzyNodeDisplayName: fuzzyNodeDisplayNameFilterFn,
            greaterThan: filterGreaterThan,
        }),
        [],
    );

    const sortTypes = useMemo(
        () => ({
            greaterThan: sortGreaterThan,
        }),
        [],
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            minWidth: 100,
            width: 200,
            maxWidth: 800,
        }),
        [],
    );

    const columns: Column<ActiveAlertData>[] = useMemo(ActiveAlertsColumns, []);

    const loadingData = useMemo(() => Array(16).fill({}), []);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize, filters, sortBy },
        rows,
        selectedFlatRows,
        setPageSize,
        toggleAllRowsSelected,
    } = useTable<ActiveAlertData>(
        {
            columns: columns,
            data: !alertsLoadedOnce ? loadingData : activeAlerts,
            pageCount,
            defaultColumn,
            filterTypes,
            sortTypes,
            disableMultiSort: true,
            autoResetPage: true,
            autoResetFilters: false,
            autoResetSortBy: false,
            autoResetSelectedRows: false,
            autoResetResize: false,
            initialState: {
                pageIndex: 0,
                pageSize: 16,
                sortBy: [{ id: 'raisedAt', desc: true }],
            },
            manualPagination: true,
            manualFilters: true,
            manualSortBy: true,
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useFilters,
        useBlockLayout,
        useResizeColumns,
        useRowSelect,
    );
    useEffect(() => {
        console.log(filters);

        const fields: FilterField[] = [];

        if (filters.length === 0) {
            setFilter(undefined);
            return;
        }

        filters.forEach((filter) => {
            const field: FilterField = {};

            field.field_name = filter.id;

            switch (filter.id) {
                case 'alertDefinitionName':
                    field.field_name = 'alertDefinitionId';
                    field.comparison = ComparisonType.ComparisonTypeEqual;
                    field.string_value = filter.value;
                    break;

                case 'status':
                    field.comparison = ComparisonType.ComparisonTypeEqual;
                    field.numeric_value = filter.value === 'ALERT_ALERT' ? 3 : 2;
                    break;

                case 'observedCount':
                    field.comparison = ComparisonType.ComparisonTypeGreaterThan;
                    field.numeric_value = filter.value;
                    break;

                case 'triggeredNodeId':
                    field.comparison = ComparisonType.ComparisonTypeEqual;
                    field.string_value = filter.value;
                    break;

                default:
                    break;
            }

            fields.push(field);
        });

        setFilter({ fields });
    }, [filters]);

    const {
        loading: loadingAlerts,
        error: errorAlerts,
        data: dataAlerts,
    } = useQuery(LIST_ALERTS, {
        variables: {
            tenantId: tenantId || '',
            input: {
                includeResolvedAlerts: false,
                pageSize: pageSize,
                pageIndex: pageIndex,
                sort:
                    sortBy && sortBy.length > 0 && sortBy[0].id
                        ? {
                              field: sortBy[0].id,
                              order: sortBy[0].desc ? SortOrder.SortOrderDescending : SortOrder.SortOrderAscending,
                          }
                        : undefined,
                filter: filter,
            },
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 300000,
    });

    const loading = loadingAlerts || loadingDefinitions;
    const error = errorAlerts || errorDefinitions;

    useEffect(() => {
        if (dataDefinitions && dataDefinitions.listAlertDefinitions && dataAlerts && dataAlerts.listAlerts) {
            const definitionsById: Record<string, AlertDefinition> = {};

            if (dataDefinitions.listAlertDefinitions.alertDefinitions) {
                dataDefinitions.listAlertDefinitions.alertDefinitions.map((definition: AlertDefinition) => {
                    if (definition.id) {
                        definitionsById[definition.id] = definition;
                    }
                });
            }

            if (dataAlerts.listAlerts.alerts) {
                const alerts = dataAlerts.listAlerts.alerts.map((alert) => {
                    const writableAlert: ActiveAlertData = { ...alert, alertConfig: unknownAlertConfig };

                    if (writableAlert.definitionId) {
                        // Update the alertData with the definition data
                        const definition = definitionsById[alert.definitionId];
                        if (definition) {
                            writableAlert.alertConfig = definition;
                        } else {
                            writableAlert.alertConfig = unknownAlertConfig;
                        }
                    }

                    return writableAlert;
                });

                const pageCount = dataAlerts.listAlerts.pageInfo?.pageCount || 1;

                setPageCount(pageCount);
                setActiveAlerts(alerts);
                setAlertsLoadedOnce(true);
            } else {
                setActiveAlerts([]);
                setAlertsLoadedOnce(true);
            }

            setDefinitionsById(definitionsById);
        }
    }, [dataAlerts, dataDefinitions]);

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <p className="uppercase tracking-wider font-bold text-xs text-gray-400">Active Alerts</p>
                <div className="flex items-center space-x-2 text-xs">
                    <>
                        <button
                            type="button"
                            className="btn rounded-md px-3 py-2 focus-none"
                            onClick={() => setToggleFilters(!toggleFilters)}
                        >
                            <FunnelIcon className="h-4 w-4" />
                        </button>
                        {canResolveAlerts && (
                            <button
                                type="button"
                                className={classNames(
                                    'btn rounded-md px-3 py-2 focus-none',
                                    loadingResolveAlerts || selectedFlatRows.length === 0 ? 'btn-disabled' : '',
                                )}
                                disabled={loadingResolveAlerts || selectedFlatRows.length === 0}
                                onClick={resolveAllSelectedAlerts}
                            >
                                Resolve Selected
                            </button>
                        )}
                        {canResolveAlerts && (
                            <button
                                type="button"
                                className={classNames(
                                    'btn rounded-md px-3 py-2 focus-none',
                                    loadingResolveAlerts ? 'btn-disabled' : '',
                                )}
                                disabled={loadingResolveAlerts}
                                onClick={resolveAllAlerts}
                            >
                                Resolve All
                            </button>
                        )}
                    </>
                </div>
            </div>

            <table {...getTableProps()} className="w-full text-left text-xs mb-4">
                <thead className="sticky top-0 bg-gray-700 z-10">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} className="pt-2 px-2 align-middle bg-gray-700">
                                    <div className="flex items-center mb-2">
                                        <span>{column.render('Header')}</span>
                                        {column.canSort &&
                                            (column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <ChevronDownIcon
                                                        className="h-5 w-5"
                                                        {...column.getSortByToggleProps()}
                                                    />
                                                ) : (
                                                    <ChevronUpIcon
                                                        className="h-5 w-5"
                                                        {...column.getSortByToggleProps()}
                                                    />
                                                )
                                            ) : (
                                                <ChevronUpDownIcon
                                                    className="h-5 w-5"
                                                    {...column.getSortByToggleProps()}
                                                />
                                            ))}
                                        <div
                                            {...column.getResizerProps()}
                                            className={column.isResizing ? 'resizerIsResizing' : 'resizer'}
                                        />
                                    </div>
                                    {toggleFilters && (
                                        <div className="pl-1">{column.canFilter ? column.render('Filter') : null}</div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="overflow-y-hidden pb-[40px] bg-gray-600">
                    {!loading && !error && activeAlerts.length === 0 && (
                        <tr className="text-gray-400 py-1">
                            <td colSpan={columns.length} className="px-2 py-4 text-center">
                                {Object.keys(definitionsById).length === 0 ? (
                                    <div className="space-y-2">
                                        <div>No active alerts</div>
                                        <div>Please create an alert definition to generate alerts</div>
                                    </div>
                                ) : (
                                    'No active alerts'
                                )}
                            </td>
                        </tr>
                    )}
                    {error && activeAlerts.length === 0 && (
                        <tr className="py-1 text-gray-400">
                            <td colSpan={columns.length} className="px-2 py-2 flex items-center justify-center">
                                <ExclamationTriangleIcon className="h-8 w-8 pr-2" />
                                There was an issue loading active alerts, please try again later.
                            </td>
                        </tr>
                    )}
                    {rows.length === 0 && activeAlerts.length > 0 && (
                        <tr className="py-1 text-gray-400">
                            <td colSpan={columns.length} className="px-2 py-2 flex items-center justify-center">
                                There are no matching alerts
                            </td>
                        </tr>
                    )}
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Fragment key={row.id}>
                                <tr {...row.getRowProps()} className="text-gray-300 hover:bg-gray-900">
                                    {row.cells.map((cell, idx) => {
                                        if (!alertsLoadedOnce) return <Skeleton cell={cell} key={idx} />;

                                        switch (cell.column.id) {
                                            case 'name':
                                                let name = 'Unknown alert definition';
                                                const definition = definitionsById[row.original.definitionId];
                                                if (definition) {
                                                    name = definition.name;
                                                }
                                                return (
                                                    <td {...cell.getCellProps()} className="py-1.5 px-2">
                                                        <div className="flex w-full h-full items-center">{name}</div>
                                                    </td>
                                                );
                                            case 'select':
                                                return (
                                                    <td {...cell.getCellProps()} className="py-1.5 px-2">
                                                        <div className="flex w-full h-full items-center justify-start">
                                                            <IndeterminateCheckbox
                                                                {...row.getToggleRowSelectedProps()}
                                                            />
                                                            <Tooltip
                                                                label={
                                                                    row.isExpanded
                                                                        ? 'Click to collapse alert'
                                                                        : 'Click to expand alert'
                                                                }
                                                                placement="top"
                                                            >
                                                                <button
                                                                    className="h-4 w-4 ml-3"
                                                                    {...row.getToggleRowExpandedProps()}
                                                                >
                                                                    {row.isExpanded ? '▼' : '▶'}
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                );
                                            case 'actions':
                                                return <ActiveAlertActions cell={cell} key={idx} />;
                                            case 'status':
                                                return (
                                                    <td {...cell.getCellProps()} className="py-1.5 px-2">
                                                        <div className="flex items-center uppercase text-xxs">
                                                            {cell.value === 'ALERT_OK' && (
                                                                <span className="bg-green-600 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    Ok
                                                                </span>
                                                            )}
                                                            {cell.value === 'ALERT_WARNING' && (
                                                                <span className="bg-yellow-500 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    Warning
                                                                </span>
                                                            )}
                                                            {cell.value === 'ALERT_ALERT' && (
                                                                <span className="bg-red-600 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    Alert
                                                                </span>
                                                            )}
                                                            {cell.value === 'ALERT_NO_DATA' && (
                                                                <span className="bg-gray-700 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    No Data
                                                                </span>
                                                            )}
                                                            {cell.value === 'ALERT_UNKNOWN' && (
                                                                <span className="bg-gray-700 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                    Unknown
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                            case 'raisedAt':
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="py-1.5 px-2 flex items-center "
                                                    >
                                                        <div className="flex items-center w-full h-full">
                                                            {format(
                                                                new Date(parseInt(cell.value)),
                                                                'EEE MMM do HH:mm:ss',
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                            case 'eventDate':
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="py-1.5 px-2 flex items-center "
                                                    >
                                                        <div className="flex items-center w-full h-full">
                                                            {format(
                                                                new Date(parseInt(cell.value) / 1000000),
                                                                'EEE MMM do HH:mm:ss',
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                            case 'triggeredNodeId':
                                                return <LazyNodeCell cell={cell} key={idx} />;
                                            case 'muted':
                                                return (
                                                    <td {...cell.getCellProps()} className="py-1.5 px-2 ">
                                                        <div className="flex space-x-2 items-center ">
                                                            {cell.value === true ? (
                                                                <span className=" py-1 px-2">
                                                                    <BellSlashIcon className="h-4 w-4 text-white" />
                                                                </span>
                                                            ) : (
                                                                <span className="py-1 px-2">
                                                                    <BellIcon className="h-4 w-4 text-gray-700 hover:text-gray-200" />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                            default:
                                                return <DefaultCell cell={cell} key={idx} />;
                                        }
                                    })}
                                </tr>
                                {row.isExpanded && (
                                    <tr className="bg-gray-800">
                                        <td colSpan={columns.length}>
                                            <div className="px-6 pt-3 pb-5">
                                                <div className="">
                                                    <div className="space-y-2">
                                                        <div>
                                                            <label className="text-xs">
                                                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">
                                                                    Alert Name
                                                                </span>
                                                                <p className="m-2 text-gray-300">
                                                                    {row.original.alertConfig.name}
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="text-xs">
                                                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">
                                                                    Alert Type
                                                                </span>
                                                                <p className="m-2 text-gray-300">
                                                                    {
                                                                        getJobDescriptor(
                                                                            row.original.alertConfig.jobType,
                                                                        ).name
                                                                    }
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="text-xs">
                                                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">
                                                                    Alert Description
                                                                </span>
                                                                <p className="m-2 text-gray-300">
                                                                    {
                                                                        getJobDescriptor(
                                                                            row.original.alertConfig.jobType,
                                                                        ).description
                                                                    }
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="text-xs">
                                                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">
                                                                    Alert Message
                                                                </span>
                                                                <p className="m-2 text-gray-300">
                                                                    {row.original.alertConfig.message}
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="text-xs">
                                                                <span className="w-40 text-right pr-3 pt-2 text-gray-400">
                                                                    Thresholds
                                                                </span>
                                                                <div className="pl-2 mt-2 space-y-2">
                                                                    <div className="pr-3 text-gray-300">
                                                                        <span className="inline-block w-2 h-2 bg-red-500 mr-1" />
                                                                        Alert threshold{' '}
                                                                        {row.original.alertConfig.alertThreshold}
                                                                    </div>
                                                                    <div className="pr-3 text-gray-300">
                                                                        <span className="inline-block w-2 h-2 bg-yellow-500 mr-1" />
                                                                        Warning threshold{' '}
                                                                        {row.original.alertConfig.warningThreshold}
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })}
                </tbody>
                {
                    <tfoot>
                        <tr className="font-light">
                            <th colSpan={ActiveAlertsColumns.length} className="p-0">
                                <div className="bg-gray-700 text-xs flex items-center p-2 justify-between">
                                    {alertsLoadedOnce ? (
                                        <>
                                            {rows.length > 0 ? (
                                                <div className="flex items-center">
                                                    <span className="ml-1">
                                                        Showing {pageIndex * pageSize + 1} to{' '}
                                                        {pageIndex * pageSize + pageSize} of ~{pageSize * pageCount}{' '}
                                                        records
                                                    </span>
                                                    {selectedFlatRows.length > 0 && (
                                                        <span className="ml-1">
                                                            - {selectedFlatRows.length} selected
                                                        </span>
                                                    )}{' '}
                                                    <span className="ml-1">
                                                        - Page {pageOptions.length === 0 ? 0 : pageIndex + 1} of{' '}
                                                        {pageOptions.length}
                                                    </span>
                                                    {loading && <span className="ml-2 h-4 w-4 loader" />}
                                                    {error && (
                                                        <span className="ml-2 text-red-500">
                                                            There was an issue loading results.
                                                        </span>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="text-gray-400"></div>
                                            )}

                                            {pageCount > 1 ? (
                                                <div className="flex items-center space-x-0.5">
                                                    <select
                                                        value={pageSize}
                                                        className="input-gray text-xxs px-5 py-0.5 rounded-none bg-gray-800 shadow-md border-0"
                                                        onChange={
                                                            // set the number of rows per page
                                                            (e) => {
                                                                setPageSize(Number(e.target.value));
                                                            }
                                                        }
                                                    >
                                                        <option>16</option>
                                                        <option>25</option>
                                                        <option>50</option>
                                                        <option>100</option>
                                                        <option>250</option>
                                                        <option>500</option>
                                                    </select>
                                                    <button
                                                        className="btn bg-gray-800 text-xs p-1"
                                                        onClick={() => gotoPage(0)}
                                                        disabled={!canPreviousPage}
                                                    >
                                                        <ChevronDoubleLeftIcon className="h-4 w-4" />
                                                    </button>
                                                    <button
                                                        className="btn bg-gray-800 text-xs p-1"
                                                        onClick={() => previousPage()}
                                                        disabled={!canPreviousPage}
                                                    >
                                                        <ChevronLeftIcon className="h-4 w-4" />
                                                    </button>
                                                    <button
                                                        className="btn bg-gray-800 text-xs p-1"
                                                        onClick={() => nextPage()}
                                                        disabled={!canNextPage}
                                                    >
                                                        <ChevronRightIcon className="h-4 w-4" />
                                                    </button>
                                                    <button
                                                        className="btn bg-gray-800 text-xs p-1"
                                                        onClick={() => gotoPage(pageCount - 1)}
                                                        disabled={!canNextPage}
                                                    >
                                                        <ChevronDoubleRightIcon className="h-4 w-4" />
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="h-5 w-5" />
                                            )}
                                        </>
                                    ) : (
                                        <div className="h-5 w-5" />
                                    )}
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                }
            </table>
        </div>
    );
};

interface IndeterminateCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    indeterminate?: boolean;
}

const BaseIndeterminateCheckbox: ForwardRefRenderFunction<HTMLInputElement, IndeterminateCheckboxProps> = (
    { indeterminate = false, ...rest },
    ref,
) => {
    const defaultRef = useRef<HTMLInputElement>(null);
    const inputRef = ref || defaultRef;

    useEffect(() => {
        if (inputRef && typeof inputRef !== 'function' && inputRef.current) {
            inputRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate, inputRef]);

    return <input type="checkbox" className="chk-box h-4 w-4" ref={inputRef} {...rest} />;
};

const IndeterminateCheckbox = forwardRef(BaseIndeterminateCheckbox);

type ActiveAlertActionCellProps = {
    cell: Cell<any, any>;
};

const ActiveAlertActions = ({ cell }: ActiveAlertActionCellProps) => {
    const tenantId = useTenant();
    const alertId = cell.row.original.id;
    const { addToast } = useToasts();

    const { userCan } = useUserPermissions();
    const canResolveAlerts = userCan('resolve', '/alerts');

    const [resolveAlerts, { loading }] = useMutation(RESOLVE_ALERTS, {
        refetchQueries: ['listAlerts', 'getTotalAlertCount'],
    });

    const resolveAlert = async () => {
        try {
            await resolveAlerts({ variables: { tenantId, resolveAlertsInput: { alertIds: [alertId] } } });
        } catch (e) {
            addToast('There was an issue resolving the alert', 'failure');

            console.error(e);
        }
    };

    return (
        <td {...cell.getCellProps()} className="py-1.5 px-2 ">
            <div className="flex space-x-2 items-center w-full h-full ">
                {canResolveAlerts && (
                    <button
                        className={classNames('btn py-1 px-2 rounded-md focus:ring-0', loading ? 'btn-disabled' : '')}
                        onClick={resolveAlert}
                    >
                        Resolve
                        {loading && <span className="ml-2 h-4 w-4 loader" />}
                    </button>
                )}
            </div>
        </td>
    );
};
