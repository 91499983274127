import { useApolloClient } from '@apollo/client';
import { ChevronDownIcon, DocumentDuplicateIcon } from '@heroicons/react/24/solid';
import { classNames, getUsersTimezone } from 'Utilities/utils';
import { Menu } from '@headlessui/react';
import { GET_ENTITIES_AS_NODES } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { BackendNode } from 'Map/Graph/Data';
import Papa from 'papaparse';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DashboardTabs } from 'Dashboard/DashboardModalTypes';
import { CSVSpecDefinitions } from './CSVSpecDefinitions';
import { format } from 'date-fns';
import { UnitInterval } from 'Types/types';
import { useContext, useState } from 'react';
import { ToastContext } from 'Map/Components/ToastContext';

type Result = Record<
    string,
    {
        items?: object[];
    }
>;

type NodeResult = {
    getEntitiesAsNodes: BackendNode[];
};

export const CSVDownloader = ({
    tab,
    startDate,
    endDate,
    interval,
}: {
    tab: DashboardTabs;
    startDate: number;
    endDate: number;
    interval: UnitInterval;
}) => {
    const tenantId = useTenant();
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const { dispatch: toastDispatch } = useContext(ToastContext);

    const downloadAllReports = async () => {
        setLoading(true);

        try {
            if (!tenantId) {
                console.error('No Tenant ID, cannot generate CSV');
                return;
            }

            const { name, aggregatedQueries: queries } = CSVSpecDefinitions[tab];

            const data = await Promise.all(
                queries.map((query) => {
                    let variables: Record<string, string | number> = {
                        tenantId,
                        startDate,
                        endDate,
                        ...query.variables,
                    };
                    if (query.hasLimit) {
                        variables = { ...variables, limit: 10000 };
                    }
                    if (query.hasUnit) {
                        variables = { ...variables, unit: interval };
                    }

                    return client.query<Result>({ query: query.query, variables });
                }),
            );

            const nodeIdsByEntityType: Record<string, string[]> = {
                STATS_ENTITY_TYPE_ACTOR: [],
                STATS_ENTITY_TYPE_DEVICE: [],
                STATS_ENTITY_TYPE_APPLICATION: [],
                STATS_ENTITY_TYPE_IDENTITY: [],
                STATS_ENTITY_TYPE_TARGET: [],
            };

            queries.map((query, index) => {
                if (query.resolveNodes) {
                    const items = data[index].data[query.queryName].items;

                    if (items === undefined) return;

                    return data[index].data[query.queryName].items?.map((item: Record<string, string>) => {
                        const entityType = query.variables.entityType as string;
                        const id = query.alternateNodeKey ? item[query.alternateNodeKey] : item.id;
                        nodeIdsByEntityType[entityType].push(id);
                    });
                }
            });

            const nodes = await Promise.all(
                Object.entries(nodeIdsByEntityType).map(([entityType, nodeIds]) => {
                    const entityIds = Array.from(new Set(nodeIds));
                    if (entityIds.length > 0) {
                        return client.query<NodeResult>({
                            query: GET_ENTITIES_AS_NODES,
                            variables: {
                                tenantId: tenantId,
                                entityIds: Array.from(new Set(nodeIds)),
                                entityType: entityType,
                            },
                        });
                    }
                }),
            );

            console.debug('Nodes to resolve:', nodes);

            const nodesById: Record<string, BackendNode> = {};

            nodes.map((backendNodes) => {
                if (backendNodes === undefined) return;

                backendNodes.data.getEntitiesAsNodes.map((node: BackendNode) => {
                    nodesById[node.nodeId] = node;
                });
            });

            const processedData = queries.map((query, index) => {
                const items = data[index].data[query.queryName].items;
                if (items) {
                    const results = items.map((item: Record<string, string>) => {
                        let node: BackendNode | undefined = undefined;

                        if (query.resolveNodes) {
                            if (query.alternateNodeKey) {
                                node = nodesById[item[query.alternateNodeKey]];
                            } else {
                                node = nodesById[item.id];
                            }
                        }

                        const processedItem = query.processItem(item, node);

                        return processedItem;
                    });
                    return results;
                } else {
                    return [];
                }
            });

            console.debug('Processed data', processedData);

            const csvData = processedData.map((data) => Papa.unparse(data));

            console.debug('CSV Data', csvData);

            const zip = new JSZip();

            csvData.map((data, index) => {
                const fileName = queries[index].fileName;
                console.debug('Adding file', fileName, 'to zip');
                zip.file(fileName, data);
            });

            console.debug('Generating zip file');
            const zipBlob = await zip.generateAsync({ type: 'blob' });

            const startDateString = format(startDate, 'EEE-do-HH:mm');
            const endDateString = format(endDate, 'EEE-do-HH:mm');
            const timeZone = getUsersTimezone();
            const zipFileName = `SailPoint Identity Risk Insights ${name} ${startDateString} - ${endDateString} ${timeZone}.zip`;
            console.debug('Initiating download as', zipFileName);

            saveAs(zipBlob, zipFileName);

            setLoading(false);

            toastDispatch({
                type: 'add-toast',
                message: 'Report downloaded successfully',
                status: 'success',
                autoTimeout: true,
                timeoutTimer: 5,
            });
        } catch (e) {
            console.error(e);
            setLoading(false);
            toastDispatch({
                type: 'add-toast',
                message: 'Failed to download report, please try again',
                status: 'failure',
                autoTimeout: true,
                timeoutTimer: 10,
            });
        }
    };

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={classNames(
                            open ? 'opacity-50' : '',
                            loading ? 'bg-gray-600' : '',
                            'btn btn-primary text-xs rounded-md inline-flex',
                        )}
                    >
                        {loading ? (
                            <span className="flex">
                                Generating Report <div className="loader h-4 w-4 ml-2" />
                            </span>
                        ) : (
                            <span className="flex">
                                Download Report
                                <ChevronDownIcon className="h-4 w-4 ml-2" />
                            </span>
                        )}
                    </Menu.Button>
                    {!loading && (
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-gray-700 shadow-lg text-xs outline-none">
                            <div className="py-2">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active ? 'bg-blue-700 text-white' : 'text-gray-300',
                                                'px-4 py-2 text-xs flex items-center w-full',
                                            )}
                                            onClick={downloadAllReports}
                                        >
                                            <DocumentDuplicateIcon className="h-4 w-4 mr-2" />
                                            Download all reports
                                        </button>
                                    )}
                                </Menu.Item>
                                {/* <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={classNames(
                                            active ? 'bg-blue-700 text-white' : 'text-gray-400',
                                            'px-4 py-2 text-xs flex items-center w-full',
                                        )}
                                        onClick={getData}
                                    >
                                        <DocumentIcon className="h-4 w-4 mr-2" />
                                        Download current report
                                    </button>
                                )}
                            </Menu.Item> */}
                            </div>
                        </Menu.Items>
                    )}
                </>
            )}
        </Menu>
    );
};
