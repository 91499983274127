import UserGroup from 'assets/icons/UserGroups/UserGroup.png';
import Permission from 'assets/icons/Permissions/Permission.png';
import Okta from 'assets/icons/Providers/Okta.png';
import AzureAD from 'assets/icons/Providers/AzureAD.png';
import Provider from 'assets/icons/Providers/Provider.png';
import Target from 'assets/icons/Targets/Target.png';
import Atlassian from 'assets/icons/Targets/Atlassian.png';
import AWS from 'assets/icons/Targets/AWS.png';
import GitHub from 'assets/icons/Targets/GitHub.png';
import MongoDB from 'assets/icons/Targets/MongoDB.png';
import Slack from 'assets/icons/Targets/Slack.png';
import User from 'assets/icons/Actors/User.png';
import { MarkerType, Node, Position } from 'reactflow';

export const headerChips: Node[] = [
    {
        id: 'a-header',
        type: 'header',
        data: { label: 'Actors' },
        position: { x: 0, y: -35 },
    },
    {
        id: 'g-header',
        type: 'header',
        data: { label: 'Groups' },
        position: { x: 400, y: -35 },
    },

    {
        id: 'c-header',
        type: 'header',
        data: { label: 'Policies' },
        position: { x: 800, y: -35 },
    },

    {
        id: 't-header',
        type: 'header',
        data: { label: 'Targets' },
        position: { x: 1200, y: -35 },
    },
];

export const initialNodesOkta: Node[] = [
    {
        id: 'a-header',
        type: 'header',
        data: { label: 'Actors' },
        position: { x: 0, y: -35 },
    },
    {
        id: 'p-header',
        type: 'header',
        data: { label: 'Providers' },
        position: { x: 260, y: -35 },
    },
    {
        id: 'g-header',
        type: 'header',
        data: { label: 'Groups' },
        position: { x: 520, y: -35 },
    },

    {
        id: 'c-header',
        type: 'header',
        data: { label: 'Conditions' },
        position: { x: 780, y: -35 },
    },

    {
        id: 't-header',
        type: 'header',
        data: { label: 'Targets' },
        position: { x: 1040, y: -35 },
    },

    {
        id: 'a-0',
        type: 'groupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '9 Total Actors', icon: User },
        position: { x: 0, y: 0 },
    },
    {
        id: 'a-1',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Frank Bird', icon: User, disabled: true },
        position: { x: 0, y: 60 },
    },
    {
        id: 'a-2',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Wilfred Goldner', icon: User },
        selected: true,
        position: { x: 0, y: 120 },
    },
    {
        id: 'a-3',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Alonzo Jacobs', icon: User },
        position: { x: 0, y: 180 },
    },

    {
        id: 'a-4',
        type: 'loadMore',
        data: { name: 'Load more' },
        position: { x: 0, y: 240 },
        selectable: false,
    },

    {
        id: 'p-0',
        type: 'groupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '2 Total Providers', icon: Provider },
        position: { x: 260, y: 0 },
    },
    {
        id: 'p-1',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Okta', icon: Okta },
        position: { x: 260, y: 60 },
    },
    {
        id: 'p-2',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Azure AD', icon: AzureAD },
        position: { x: 260, y: 120 },
    },

    {
        id: 'g-0',
        type: 'groupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '6 Total User Groups', icon: UserGroup },
        position: { x: 520, y: 0 },
    },
    {
        id: 'g-1',
        type: 'subGroupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '3 Okta', icon: Okta },
        position: { x: 520, y: 60 },
    },
    {
        id: 'g-2',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Sales', icon: UserGroup },
        position: { x: 520, y: 120 },
    },
    {
        id: 'g-3',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'US Region', icon: UserGroup },
        position: { x: 520, y: 180 },
    },
    {
        id: 'g-4',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Admin', icon: UserGroup },
        position: { x: 520, y: 240 },
    },
    {
        id: 'g-5',
        type: 'subGroupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '3 Azure AD', icon: AzureAD, grouped: true },
        position: { x: 520, y: 300 },
    },

    {
        id: 'c-0',
        type: 'groupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '6 Total Conditions', icon: Permission },
        position: { x: 780, y: 0 },
    },

    {
        id: 'c-1',
        type: 'subGroupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '3 Okta Conditions', icon: Okta },
        position: { x: 780, y: 60 },
    },

    {
        id: 'c-2',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Standard Policy', icon: Permission },
        position: { x: 780, y: 120 },
    },

    {
        id: 'c-3',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Protected Policy', icon: Permission },
        position: { x: 780, y: 180 },
    },

    {
        id: 'c-4',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Admin Policy', icon: Permission },
        position: { x: 780, y: 240 },
    },

    {
        id: 'c-5',
        type: 'subGroupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '3 Azure AD', icon: AzureAD, grouped: true },
        position: { x: 780, y: 300 },
    },

    {
        id: 't-0',
        type: 'groupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '15 Total Targets', icon: Target },
        position: { x: 1040, y: 0 },
    },

    {
        id: 't-1',
        type: 'subGroupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '5 Okta', icon: Okta },
        position: { x: 1040, y: 60 },
    },

    {
        id: 't-2',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Atlassian', icon: Atlassian },
        position: { x: 1040, y: 120 },
    },

    {
        id: 't-3',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Amazon Web Services', icon: AWS },
        position: { x: 1040, y: 180 },
    },

    {
        id: 't-4',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'GitHub', icon: GitHub },
        position: { x: 1040, y: 240 },
    },

    {
        id: 't-5',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'MongoDB', icon: MongoDB },
        position: { x: 1040, y: 300 },
    },

    {
        id: 't-6',
        type: 'chip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: 'Slack', icon: Slack },
        position: { x: 1040, y: 360 },
    },

    {
        id: 't-7',
        type: 'subGroupChip',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: { label: 'actor', name: '10 Azure AD', icon: AzureAD, grouped: true },
        position: { x: 1040, y: 420 },
    },
];

export const initialEdgesOkta = [
    {
        id: 'a2-p1',
        source: 'a-2',
        target: 'p-1',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },
    {
        id: 'a2-g3',
        source: 'a-2',
        target: 'g-3',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },

    {
        id: 'p1-g2',
        source: 'p-1',
        target: 'g-2',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },
    {
        id: 'p1-g3',
        source: 'p-1',
        target: 'g-3',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },

    {
        id: 'g2-c3',
        source: 'g-2',
        target: 'c-3',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },

    {
        id: 'g3-c2',
        source: 'g-3',
        target: 'c-2',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },

    {
        id: 'g4-c4',
        source: 'g-2',
        target: 'c-3',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },

    {
        id: 'c2-t6',
        source: 'c-2',
        target: 't-6',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },

    {
        id: 'c3-t2',
        source: 'c-3',
        target: 't-2',
        markerEnd: {
            type: MarkerType.Arrow,
            strokeWidth: 2,
            color: '#ffffff',
        },
        style: {
            strokeWidth: 2,
            stroke: 'rgba(255,255,255,1)',
        },
    },
];
