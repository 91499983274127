import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { NodePanel } from './NodePanel';
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    MagnifyingGlassIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid';
import { useHotkeys } from 'react-hotkeys-hook';
import { byPropertiesOf, classNames, getDisplayName } from 'Utilities/utils';
import { Resizable } from 're-resizable';
import { useLocalStorage } from 'Hooks/Hooks';
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from 'Library/Tooltip';

export const Explorer = (): JSX.Element => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { getScopedStorageKey } = useLocalStorage();
    const key = getScopedStorageKey('explorerWidth');
    const [explorerWidth, setExplorerWidth] = useState('450px');
    const [hover, setHover] = useState(false);

    const nodeToScrollToRef = useRef<HTMLDivElement>(null);

    const [shiftIsPressed, setShiftIsPressed] = useState(false);

    // When the shift key is pressed down, we want to offer different explorer behavior
    useHotkeys(
        '*',
        (key) => {
            if (!shiftIsPressed && key.shiftKey) {
                setShiftIsPressed(true);
            }
            if (shiftIsPressed && !key.shiftKey) {
                setShiftIsPressed(false);
            }
        },
        { keydown: true, keyup: true },
        [shiftIsPressed],
    );

    useEffect(() => {
        if (key) {
            const width = localStorage.getItem(key);
            if (width) {
                setExplorerWidth(width);
            }
        }
    }, [key]);

    useEffect(() => {
        if (mapState.scrollToNode && nodeToScrollToRef.current) {
            nodeToScrollToRef.current.scrollIntoView({ behavior: 'smooth' });
            dispatch({ type: 'set-scroll-to-node', node: undefined });
        }
    }, [dispatch, mapState.scrollToNode]);

    const [nodePanel, toggleNodePanel] = useState(true);

    const nodeToggle = useCallback(() => {
        toggleNodePanel(!nodePanel);
    }, [nodePanel, toggleNodePanel]);

    useHotkeys('e', nodeToggle, {}, [nodePanel, toggleNodePanel]);

    const [sortType, setSortType] = useState('');

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSortType(e.target.value);
    };

    const searchQueryReplacements = (query: string): string => {
        // To make it easier to find some objects, we replace some common words
        // with their synonyms in the search string
        const r = query.replace(/^user|use|us$/gi, 'actor');
        return r;
    };

    const [filterInputText, setFilterInputText] = useState('');
    // Create a locally sorted copy of the central selected nodes map state
    const sortedAndFilteredSelectedNodes = useMemo(() => {
        let nodes = Array.from(mapState.selectedNodes);

        nodes.map((node) => {
            node.computedName = getDisplayName(node);
        });

        if (filterInputText) {
            const searchString = searchQueryReplacements(filterInputText.toLowerCase());

            nodes = nodes.filter((node: Node) => {
                const name = getDisplayName(node).toLowerCase() || '';
                const label = node.label?.toLowerCase() || '';
                return name.includes(searchString) || label.includes(searchString);
            });
        }

        switch (sortType) {
            case 'name-ascending':
                nodes.sort(byPropertiesOf<Node>(['computedName', 'label']));
                break;
            case 'name-descending':
                nodes.sort(byPropertiesOf<Node>(['-computedName', 'label']));
                break;
            case 'type-ascending':
                nodes.sort(byPropertiesOf<Node>(['label', 'computedName']));
                break;
            case 'type-descending':
                nodes.sort(byPropertiesOf<Node>(['-label', 'computedName']));
                break;
            case 'tag-ascending':
                nodes.sort(byPropertiesOf<Node>(['tags', 'label', 'computedName']));
                break;
            case 'tag-descending':
                nodes.sort(byPropertiesOf<Node>(['-tags', 'label', 'computedName']));
                break;
        }
        return nodes;
    }, [filterInputText, mapState.selectedNodes, sortType]);

    const onInputValueChange = (changes: ChangeEvent<HTMLInputElement>) => {
        const inputValue = changes.target.value;
        setFilterInputText(inputValue);
    };

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [nodeCounter, setNodeCounter] = useState(false);

    useEffect(() => {
        setNodeCounter(true);
        setTimeout(() => {
            setNodeCounter(false), 2000;
        });
    }, [sortedAndFilteredSelectedNodes.length]);

    return (
        <div
            data-test="explorer"
            className={classNames(nodePanel ? 'pointer-events-auto' : 'pointer-events-none', 'hover:z-90')}
        >
            {mapState.selectedNodes.size > 0 && (
                <Resizable
                    onResizeStop={(_event, _direction, elementRef) => {
                        const width = elementRef.style.width;
                        setExplorerWidth(width);
                        if (key) {
                            localStorage.setItem(key, width);
                        }
                    }}
                    defaultSize={{
                        width: explorerWidth,
                        height: 'auto',
                    }}
                    maxWidth="700px"
                    minWidth="364px"
                    handleStyles={
                        hover && nodePanel
                            ? {
                                  right: {
                                      marginTop: -5,
                                      marginLeft: -7,
                                      top: '50%',
                                      left: '100%',
                                      cursor: 'ew-resize',
                                      border: '3px solid #999',
                                      borderTop: 'none',
                                      borderLeft: 'none',
                                      borderBottom: 'none',
                                      borderWidth: 5,
                                      borderColor: 'rgb(156 163 175)',
                                      width: 10,
                                      height: 20,
                                      boxSizing: 'border-box',
                                      zIndex: -1,
                                  },
                              }
                            : {}
                    }
                    enable={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                >
                    <div
                        id="Explorer"
                        onMouseEnter={() => {
                            setHover(true);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                        className={classNames(
                            nodePanel ? 'translate-x-0' : 'translate-x-[calc(-100%-12px)]',
                            'flex flex-col border border-gray-500 rounded-md relative transition-all duration-200 bg-gray-700',
                        )}
                    >
                        <div className="flex items-center justify-between p-2 border-b border-gray-500">
                            <div className="relative flex items-center">
                                <MagnifyingGlassIcon className="h-4 w-4 text-gray-300 absolute left-2" />
                                <input
                                    type="search"
                                    className="pl-7 input-gray p-1.5 text-xs w-40"
                                    placeholder="Filter"
                                    value={filterInputText}
                                    onChange={onInputValueChange}
                                />
                            </div>
                            <div className="flex items-center space-x-2">
                                <p className="uppercase tracking-wider font-bold text-xs text-gray-400 flex items-center">
                                    Sort By
                                </p>
                                <select
                                    className="input-gray p-1.5 text-xs w-24"
                                    value={sortType}
                                    onChange={handleChange}
                                >
                                    <option label="Default" value="" />
                                    <option label="&#9650;&nbsp;&nbsp;Name" value="name-ascending" />
                                    <option label="&#9660;&nbsp;&nbsp;Name" value="name-descending" />
                                    <option label="&#9650;&nbsp;&nbsp;Type" value="type-ascending" />
                                    <option label="&#9660;&nbsp;&nbsp;Type" value="type-descending" />
                                    <option label="&#9650;&nbsp;&nbsp;Tag" value="tag-ascending" />
                                    <option label="&#9660;&nbsp;&nbsp;Tag" value="tag-descending" />
                                </select>
                            </div>
                        </div>
                        <section
                            className={classNames(
                                isMobile ? 'max-h-[calc(100vh-374px)]' : 'max-h-[calc(100vh-326px)]',
                                'overflow-y-auto rounded-b-lg bg-gray-700 space-y-[1px]',
                            )}
                            data-test="explorer-node-list"
                        >
                            {sortedAndFilteredSelectedNodes.length > 0 ? (
                                sortedAndFilteredSelectedNodes.map((node: Node) => (
                                    <NodePanel
                                        key={node.id}
                                        nodeType={node.nodeType}
                                        node={node}
                                        scrollRef={mapState.scrollToNode == node ? nodeToScrollToRef : undefined}
                                    />
                                ))
                            ) : (
                                <div className="p-3 bg-gray-800">
                                    <p className="text-sm text-gray-600 text-center">No matching nodes found.</p>
                                </div>
                            )}
                        </section>
                        <Tooltip label={nodePanel ? 'Dock Panel' : 'Open Panel'} placement="right">
                            <button
                                id="Minimize"
                                type="button"
                                onClick={() => nodeToggle()}
                                className={classNames(
                                    'rounded-r-md h-12 absolute top-3 right-[-32px] flex items-center justify-center border border-gray-500 transition-all pointer-events-auto duration-200 focus:outline-none',
                                    nodePanel ? 'w-8 bg-gray-900' : 'bg-gray-800 hover:bg-gray-900 w-10 pl-2',
                                )}
                            >
                                {nodePanel ? (
                                    <ChevronDoubleLeftIcon className="h-4 w-4 text-gray-300 block" />
                                ) : (
                                    <ChevronDoubleRightIcon className="h-4 w-4 text-gray-300 block" />
                                )}

                                {sortedAndFilteredSelectedNodes.length > 0 && (
                                    <Tooltip label="Number of items in the Explorer panel.">
                                        <div
                                            className={classNames(
                                                nodeCounter ? 'animate-ping bg-blue-600' : 'bg-gray-800',
                                                'absolute rounded-full px-1 min-w-[32px] py-0.5  text-xs font-semibold text-gray-400 -right-3 -top-3 transition-all border border-gray-400',
                                            )}
                                        >
                                            {sortedAndFilteredSelectedNodes.length}
                                        </div>
                                    </Tooltip>
                                )}
                            </button>
                        </Tooltip>

                        <Tooltip
                            label={
                                shiftIsPressed ? 'Clear explorer items that are not searched' : 'Clear Explorer Items'
                            }
                        >
                            <button
                                onClick={() => {
                                    if (shiftIsPressed) {
                                        dispatch({ type: 'set-selected-nodes', nodes: mapState.queriedNodes });
                                    } else {
                                        dispatch({ type: 'set-selected-nodes', nodes: new Set() });
                                        dispatch({ type: 'set-queried-nodes', nodes: new Set() });
                                    }
                                }}
                                className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-2.5 -left-2.5 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                            >
                                <XMarkIcon className="h-3.5 w-3.5 text-gray-200" />
                            </button>
                        </Tooltip>
                    </div>
                </Resizable>
            )}
        </div>
    );
};
