import { SubmitHandler, useForm } from 'react-hook-form';
import { Panel } from './Panel';
import { useMutation, useQuery } from '@apollo/client';
import { PlusIcon, ShareIcon } from '@heroicons/react/24/solid';
import { HttpMethod, Webhook } from 'GeneratedGQL/graphql';
import { CREATE_WEBHOOK, LIST_WEBHOOKS, DELETE_WEBHOOK } from 'Graph/typedQueries';
import { useTenant } from 'Hooks/Hooks';
import { classNames } from 'Utilities/utils';
import { useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import ProviderSailPoint from 'assets/icons/Providers/SailPoint.png';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const Webhooks = () => {
    return (
        <Panel title="Webhook Integration" subtitle="Connect Trust to your Environment">
            <CreateWebhook />
            <ListWebhooks />
        </Panel>
    );
};

const CreateWebhook = (): JSX.Element => {
    const tenantId = useTenant();

    const [showForm, setShowForm] = useState(false);

    const [createWebhook, { loading, error }] = useMutation(CREATE_WEBHOOK, {
        refetchQueries: ['listWebhooks', 'getWebhook'],
    });

    const { register, handleSubmit } = useForm<Webhook>({ defaultValues: { method: HttpMethod.Post } });

    const onSubmit: SubmitHandler<Webhook> = async (formData) => {
        console.log(
            `Creating webhook: url=${formData.url}, method=${formData.method} and headers=${formData.headers?.map((h) => h && `${h.key}:${h.value.slice(0, 3)}...`).join(', ')}`,
        );

        await createWebhook({
            variables: {
                tenantId: tenantId || '',
                webhookInput: formData,
            },
        });

        setShowForm(false);
    };

    let content = <></>;

    if (loading) {
        content = (
            <h4 className="text-xs text-gray-300 flex items-center">
                <div className="loader h-4 w-4 mr-2" />
                Creating Webhook...
            </h4>
        );
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not create Webhook. Please retry later.</h4>;
    } else {
        content = (
            <>
                <button
                    onClick={() => setShowForm(true)}
                    disabled={showForm ? true : false}
                    className="btn btn-primary disabled:bg-gray-600 disabled:opacity-50 text-xs absolute top-6 right-6"
                >
                    <PlusIcon className="h-4 w-4 mr-2" />
                    New Webhook
                </button>
                {showForm && (
                    <div className="col-span-1 bg-gray-700 rounded-lg shadow mb-4">
                        <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                            <div className="mb-4 space-y-1 text-xs">
                                <h4 className="">
                                    SailPoint Identity Risk will deliver events to the configured Webhook
                                </h4>
                                <button
                                    className="text-xs text-gray-400 hover:text-blue-600"
                                    onClick={() => window.Beacon('article', '', { type: 'sidebar' })}
                                >
                                    Read the documentation on Webhook configuration
                                </button>
                            </div>
                            <div className="space-y-6 flex flex-col">
                                <label className="text-xs">
                                    <span className="w-40 text-right pr-3 pt-2 text-gray-400">Method</span>
                                    <div className="w-2/3 flex">
                                        <select className="input-gray text-xs rounded-none mt-1">
                                            <option>{HttpMethod.Post}</option>
                                            <option>{HttpMethod.Get}</option>
                                            <option>{HttpMethod.Put}</option>
                                        </select>
                                    </div>
                                </label>
                                <label className="text-xs">
                                    <span className="w-40 text-right pr-3 pt-2 text-gray-400">URL</span>
                                    <div className="w-2/3 flex">
                                        <input
                                            type="url"
                                            className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1 select-all"
                                            {...register('url')}
                                            required
                                            placeholder="https://example.com/webhook"
                                        />
                                    </div>
                                </label>
                                <label className="text-xs">
                                    <span className="w-40 text-right pr-3 pt-2 text-gray-400">
                                        Authorization Header Key
                                    </span>
                                    <div className="w-2/3 flex">
                                        <input
                                            type="text"
                                            className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1 select-all"
                                            {...register('headers.0.key')}
                                            required
                                            placeholder="Authorization"
                                        />
                                    </div>
                                </label>
                                <label className="text-xs">
                                    <span className="w-40 text-right pr-3 pt-2 text-gray-400">
                                        Authorization Header Value
                                    </span>
                                    <div className="w-2/3 flex">
                                        <input
                                            type="text"
                                            className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1 select-all"
                                            {...register('headers.0.value')}
                                            required
                                            placeholder="Bearer <token>"
                                        />
                                    </div>
                                </label>
                                <div className="flex space-x-2">
                                    <button className="btn btn-primary text-xs w-32 rounded-none" type="submit">
                                        Save Webhook
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setShowForm(false)}
                                        className="btn bg-gray-600 text-xs"
                                    >
                                        <span>Cancel</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </>
        );
    }
    return content;
};

const ListWebhooks = (): JSX.Element => {
    const tenantId = useTenant();

    const { enableImplicitIscWebhook } = useFlags();

    const { loading, error, data } = useQuery(LIST_WEBHOOKS, { variables: { tenantId: tenantId || '' } });

    let content = <></>;

    if (loading) {
        content = (
            <h4 className="text-xs text-gray-300 flex items-center">
                <div className="loader h-4 w-4 mr-2" />
                Loading Webhooks...
            </h4>
        );
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not load Webhooks. Please retry later.</h4>;
    } else {
        content = (
            <>
                <ul role="list" className="space-y-4">
                    {enableImplicitIscWebhook && <ImplicitISCWebhook />}
                    {data &&
                        data.listWebhooks.map((webhook: Webhook) => (
                            <ListWebhookItem {...webhook} key={webhook.webhookId} />
                        ))}
                </ul>
            </>
        );
    }
    return content;
};

const ListWebhookItem = (webhook: Webhook): JSX.Element => {
    const [expanded, setExpanded] = useState(false);
    const clipboard = useClipboard({
        copiedTimeout: 800,
    });

    let content = <></>;

    if (webhook) {
        content = (
            <div className="bg-gray-700 rounded-lg shadow">
                <div className="w-full flex justify-between p-4 space-x-4">
                    <ShareIcon className="w-8 h-8 text-green-500" />

                    <div className="flex-1 self-center truncate">
                        <div className="flex items-center justify-between space-x-3">
                            <div className="flex items-center space-x-2 flex-1">
                                <h3 className="text-white text-sm font-medium truncate">
                                    {webhook.method} {webhook.url}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        {expanded ? (
                            <>
                                <DeleteWebhook webhookId={webhook.webhookId} />
                                <button
                                    type="button"
                                    onClick={() => setExpanded(false)}
                                    className="btn bg-gray-600 text-xs"
                                >
                                    Close
                                </button>
                            </>
                        ) : (
                            <button type="button" onClick={() => setExpanded(true)} className="btn bg-gray-600 text-xs">
                                Details
                            </button>
                        )}
                    </div>
                </div>
                {expanded && (
                    <div className="p-4 pt-0 ml-12 space-y-4">
                        <div className="w-full relative font-medium text-xs">
                            Webhook ID
                            <div className="flex flex-start justify-start">
                                <input
                                    type="text"
                                    readOnly
                                    className="input-gray rounded-none text-xs flex-1 mt-2"
                                    value={webhook.webhookId}
                                />
                                <button
                                    type="button"
                                    data-text="Copied!"
                                    onClick={() => clipboard.copy(webhook.webhookId)}
                                    className={classNames('btn bg-gray-600 h-8.5 mt-2 ml-2')}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                        <div className="w-full relative font-medium text-xs">
                            Webhook Method
                            <div className="flex flex-start justify-start">
                                <input
                                    type="text"
                                    readOnly
                                    className="input-gray rounded-none text-xs flex-1 mt-2"
                                    value={webhook.method}
                                />
                                <button
                                    type="button"
                                    data-text="Copied!"
                                    onClick={() => clipboard.copy(webhook.method)}
                                    className={classNames('btn bg-gray-600 h-8.5 mt-2 ml-2')}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                        <div className="w-full relative font-medium text-xs">
                            Webhook URL
                            <div className="flex flex-start justify-start">
                                <input
                                    type="text"
                                    readOnly
                                    className="input-gray rounded-none text-xs flex-1 mt-2"
                                    value={webhook.url}
                                />
                                <button
                                    type="button"
                                    data-text="Copied!"
                                    onClick={() => clipboard.copy(webhook.url)}
                                    className={classNames('btn bg-gray-600 h-8.5 mt-2 ml-2')}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                        {webhook.headers &&
                            webhook.headers.length > 0 &&
                            webhook.headers.map((header) => (
                                <div className="w-full relative font-medium text-xs" key={header?.key}>
                                    {(header && `Header - ${header.key}`) || 'Unknown Header'}
                                    <div className="flex flex-start justify-start">
                                        <input
                                            type="text"
                                            readOnly
                                            className="input-gray rounded-none text-xs flex-1 mt-2"
                                            value={(header && header.value) || 'Unknown Value'}
                                        />
                                        <button
                                            type="button"
                                            data-text="Copied!"
                                            onClick={() => clipboard.copy((header && header.value) || 'Unknown Value')}
                                            className={classNames('btn bg-gray-600 h-8.5 mt-2 ml-2')}
                                        >
                                            Copy
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        );
    }
    return content;
};

type DeleteWebhookProps = {
    webhookId: string;
};

const DeleteWebhook = ({ webhookId }: DeleteWebhookProps): JSX.Element => {
    const tenantId = useTenant();

    const [deleteWebhook, { loading }] = useMutation(DELETE_WEBHOOK, {
        refetchQueries: ['listWebhooks', 'getWebhook'],
    });

    const deleteThisWebhook = async (tenantId: string, webhookId: string) => {
        console.log('deleting webhook: ' + webhookId);

        try {
            await deleteWebhook({ variables: { tenantId, webhookId } });
        } catch (e) {}
    };

    return (
        <button
            type="button"
            onClick={() => deleteThisWebhook(tenantId || '', webhookId)}
            className={classNames(
                'btn bg-red-600 hover:bg-gray-800 active:bg-blue-700 text-xs',
                loading ? 'btn-disabled' : '',
            )}
            disabled={loading}
        >
            <span className="text-xs">{loading ? 'Deleting...' : 'Delete'}</span>
        </button>
    );
};

export default Webhooks;

const ImplicitISCWebhook = (): JSX.Element => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="bg-gray-700 rounded-lg shadow">
            <div className="w-full flex justify-between p-4 space-x-4">
                <img src={ProviderSailPoint} className="w-8 h-8 text-green-500" />

                <div className="flex-1 self-center truncate">
                    <div className="flex items-center justify-between space-x-3">
                        <div className="flex items-center space-x-2 flex-1">
                            <h3 className="text-white text-sm font-medium truncate">
                                SailPoint Identity Security Cloud
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="flex items-start space-x-2">
                    {expanded ? (
                        <>
                            <button
                                type="button"
                                onClick={() => setExpanded(false)}
                                className="btn bg-gray-600 text-xs"
                            >
                                Close
                            </button>
                        </>
                    ) : (
                        <button type="button" onClick={() => setExpanded(true)} className="btn bg-gray-600 text-xs">
                            Details
                        </button>
                    )}
                </div>
            </div>
            {expanded && (
                <div className="p-4 pt-0 ml-12 space-y-4">
                    <div className="w-full relative font-medium text-xs text-gray-400">
                        Your Identity Security Cloud instance is connected to Identity Risk through SailPoint Platform
                        Bridge.
                    </div>
                </div>
            )}
        </div>
    );
};
