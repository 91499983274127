import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';

export const ProfilePanelTabHeader = ({
    title,
    onClick,
    active,
    count,
    riskCount,
}: {
    title: string;
    onClick: () => void;
    active: boolean;
    count: number;
    riskCount: number;
}) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(
                active ? 'border-white' : '',
                'flex-1 flex flex-col items-center justify-center px-3 py-1.5 transition-all border-2 border-transparent outline-none rounded-lg',
            )}
        >
            <h1 className="text-gray-400">{title}</h1>
            <div className="flex items-center justify-center relative">
                <h1 className="text-lg font-bold">{count}</h1>
                {riskCount && riskCount > 0 ? (
                    <div className="text-xs text-red-400 flex items-center absolute -right-8">
                        <ExclamationCircleIcon className="h-4 w-4 mr-1" />
                        {riskCount}
                    </div>
                ) : (
                    <div className="text-xs text-green-400 flex items-center absolute -right-8">
                        <CheckCircleIcon className="h-4 w-4 mr-1" />
                    </div>
                )}
            </div>
        </button>
    );
};
