import { useFlags } from 'launchdarkly-react-client-sdk';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
    BoltIcon,
    CursorArrowRaysIcon,
    CursorArrowRippleIcon,
    FunnelIcon,
    MagnifyingGlassIcon,
    PauseIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import { ApolloError } from '@apollo/client';
import { isNodeHidden } from 'Utilities/utils';
import { useProductTutorial } from 'Hooks/Hooks';
import { useGraphControls } from 'Hooks/GraphHooks';

export const StatusOverlay = ({
    loading,
    extendedLoading,
    error,
    data,
}: {
    loading: boolean;
    extendedLoading: boolean;
    error: ApolloError | undefined;
    // we disable this any since the type returned by the graphql query is not defined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}): JSX.Element => {
    const { maxNodeCount } = useFlags();

    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { tutorialRunning } = useProductTutorial();
    const { zoomGraph } = useGraphControls();

    const { graphData, unfilteredGraphData, pendingView, largeDatasetLoading, smallDatasetLoading } = mapState;

    useEffect(() => {
        if (largeDatasetLoading) {
            const nodeCount = mapState.graphData.nodes.filter((node) => isNodeHidden(node, mapState.visible)).length;
            const timeToWait = Math.max(1000, Math.min(15000, nodeCount));
            console.debug(
                'A large dataset is loading, hiding the map to allow layout shifts to happen behind the scenes',
                `Waiting ${timeToWait}ms`,
            );

            setTimeout(
                () => {
                    console.debug('Large dataset should be loaded by now, revealing the map');
                    if (!mapState.blockZoom) {
                        zoomGraph();
                    } else {
                        dispatch({ type: 'set-block-zoom', blocked: false });
                    }
                    setTimeout(() => dispatch({ type: 'set-large-dataset-loading', loading: false }), 1000);
                },
                Math.max(1000, timeToWait - 1000),
            );
        }
        if (smallDatasetLoading) {
            console.debug('A small dataset was loaded, revealing the map');
            if (!mapState.blockZoom) {
                zoomGraph();
            } else {
                dispatch({ type: 'set-block-zoom', blocked: false });
            }
            dispatch({ type: 'set-small-dataset-loading', loading: false });
        }
    }, [
        dispatch,
        largeDatasetLoading,
        mapState.blockZoom,
        mapState.graphData.nodes,
        mapState.graphData.nodes.length,
        mapState.graphRef,
        mapState.visible,
        smallDatasetLoading,
        zoomGraph,
    ]);

    const tooManyNodes = mapState.graphData && mapState.graphData.nodes.length >= maxNodeCount;
    const noData = data && data.nodes.length === 0;
    const noResults =
        graphData && graphData.nodes.length === 0 && unfilteredGraphData && unfilteredGraphData.nodes.length > 0;

    const backendEntryPointNothingSelected = useMemo(() => {
        return mapState.selectedNodes.size === 0;
    }, [mapState.selectedNodes.size]);

    const backendEntryPointNothingQueried = useMemo(() => {
        return mapState.queriedNodes.size === 0;
    }, [mapState.queriedNodes.size]);

    return (
        <>
            {(loading ||
                error ||
                tooManyNodes ||
                noResults ||
                pendingView ||
                largeDatasetLoading ||
                backendEntryPointNothingSelected ||
                backendEntryPointNothingQueried) && (
                // Under certain conditions, we want to hide the map, for example if there is an error,
                // too many nodes to load, or we are waiting for the dataset to load we will hide the map
                // and present a message to the user
                //
                // This div hides the underlying graph container, but does not dismount it from the DOM for performance reasons
                <div className="w-screen h-screen absolute top-0 left-0 bg-gray-900"></div>
            )}
            <section className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="space-y-4 text-gray-300 z-0 text-center items-center text-xs">
                    {(loading || largeDatasetLoading || pendingView) &&
                        !noResults &&
                        !tooManyNodes &&
                        !error &&
                        !backendEntryPointNothingSelected &&
                        !backendEntryPointNothingQueried && (
                            <h4 className="text-gray-400">
                                <div>
                                    <div className="loader h-6 w-6" />
                                    {pendingView && !loading && (
                                        <div className="mt-2">Restoring Identity Map View...</div>
                                    )}
                                    {loading && <div className="mt-2">Fetching Identity Map Data...</div>}
                                    {loading && extendedLoading && (
                                        <div className="mt-2">Hang tight, we're almost there</div>
                                    )}
                                    {largeDatasetLoading && (
                                        <div className="mt-2">Preparing Identity Map Layout...</div>
                                    )}
                                </div>
                            </h4>
                        )}

                    {error && <ErrorStatus />}

                    {backendEntryPointNothingSelected && <AwaitingSelectionStatus />}
                    {!backendEntryPointNothingSelected && backendEntryPointNothingQueried && <AwaitingQueryStatus />}

                    {!loading && !error && noData && !tutorialRunning && !backendEntryPointNothingSelected && (
                        <NoDataStatus />
                    )}

                    {!loading &&
                        !error &&
                        tooManyNodes &&
                        !backendEntryPointNothingQueried &&
                        !backendEntryPointNothingSelected && <TooManyNodesStatus />}

                    {!loading && !error && noResults && <NoResultsStatus />}
                </div>
            </section>
        </>
    );
};

const ErrorStatus = (): JSX.Element => {
    return (
        <div className="flex flex-col place-content-center">
            <span className="text-center">
                <QuestionMarkCircleIcon className="h-8 w-8 text-gray-500 inline mb-3" />
            </span>
            <h4 className="text-gray-400 tracking-widest text-base mb-3">Unable to load Identity Map</h4>
            <p className="text-gray-400 text-xs mb-3">Please refresh the page or try again later.</p>
            <p className="text-gray-400 text-xs">
                If this persists, please contact our team by <br />
                clicking <strong>Get Support</strong> in the navigation above.
            </p>
        </div>
    );
};

const NoResultsStatus = (): JSX.Element => {
    const { mapState } = useContext(IdentityMapContext);
    return (
        <div className="flex flex-col place-content-center">
            <span className="text-center">
                <FunnelIcon className="h-8 w-8 text-blue-700 inline mb-4" />
            </span>
            <h4 className="text-gray-300 tracking-widest text-base mb-3">Filter Returned No Results</h4>
            <p className="text-gray-400 text-xs mb-3">
                <br />
                Based on your filter criteria there are no results
                <br />
                <br />
                {mapState.unfilteredGraphData && (
                    <>
                        We searched through {mapState.unfilteredGraphData.nodes.length} nodes and{' '}
                        {mapState.unfilteredGraphData.links.length} edges in the current time period
                        <br />
                        <br />
                    </>
                )}
                To view data, modify or clear your filter criteria
            </p>
        </div>
    );
};

const AwaitingSelectionStatus = (): JSX.Element => {
    return (
        <div className="flex flex-col place-content-center">
            <>
                <span className="text-center">
                    <CursorArrowRaysIcon className="h-8 w-8 text-green-500 inline mb-4" />
                </span>
                <h4 className="text-gray-300 tracking-widest text-base mb-3">
                    Get Started by Adding a Node to the Explorer
                </h4>
                <p className="text-gray-400 text-xs mb-3">
                    <br />
                    Please add a node to the explorer to begin navigating the map
                    <br />
                    <br />
                    <span className="text-gray-500">
                        Nodes can be added to the explorer through the search bar or by clicking on a node in Insights
                    </span>
                    <br />
                    <br />
                </p>
            </>
        </div>
    );
};

const AwaitingQueryStatus = (): JSX.Element => {
    return (
        <div className="flex flex-col place-content-center">
            <>
                <span className="text-center">
                    <CursorArrowRippleIcon className="h-8 w-8 text-blue-600 inline mb-4" />
                </span>
                <h4 className="text-gray-300 tracking-widest text-base mb-3">
                    Toggle Search on an Explorer Node to Begin Navigating the Map
                </h4>
                <p className="text-gray-400 text-xs mb-3 leading-relaxed">
                    <br />
                    Click the switch next to a node in the explorer list
                    <br /> on the the left side of the screen to continue
                </p>
            </>
        </div>
    );
};

const NoDataStatus = (): JSX.Element => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { enableTour } = useFlags();
    const { startTutorial } = useProductTutorial();
    const history = useHistory();

    return (
        <div className="space-y-4">
            {mapState.firstEventAt ? (
                <div className="flex flex-col place-content-center">
                    <>
                        <span className="text-center">
                            <MagnifyingGlassIcon className="h-8 w-8 text-blue-600 inline mb-4" />
                        </span>
                        <h4 className="text-gray-300 tracking-widest text-base mb-3">
                            There is no activity in this Time Period
                        </h4>
                        <p className="text-gray-400 text-xs mb-3">
                            <br />
                            Adjust the selected time period using the slider below to see activity
                            <br />
                            <br />
                            <button
                                onClick={() => dispatch({ type: 'reset-map' })}
                                className="hover:text-blue-600 active:text-blue-500"
                            >
                                Alternatively, click here to set the time period to the latest available data
                            </button>
                            <br />
                            <br />
                            <span className="text-gray-500">
                                If you do not expect to see this message, please click the Get Support in the header
                                menu
                            </span>
                        </p>
                    </>
                </div>
            ) : (
                <>
                    <h3 className="text-sm">Welcome to SailPoint Identity Risk</h3>
                    <div className="flex pt-4">
                        <div className="px-6">
                            <h4>To populate the Identity Map</h4>
                            <button
                                className="btn btn-primary mx-auto tracking-widest mt-4"
                                onClick={() => history.push('/welcome')}
                                data-testid="setup-a-provider"
                            >
                                Setup a Provider
                            </button>
                        </div>
                        {enableTour && (
                            <div className="px-6 border-l-2 border-l-blue-600">
                                <h4>Learn how SailPoint Identity Risk works</h4>
                                <button
                                    className="btn btn-primary mx-auto tracking-widest mt-4"
                                    onClick={startTutorial}
                                    data-testid="start-tutorial"
                                >
                                    Start Tutorial
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const TooManyNodesStatus = (): JSX.Element => {
    const { maxNodeCount } = useFlags();
    const { mapState, dispatch } = useContext(IdentityMapContext);

    return (
        <div className="flex flex-col place-content-center text-sm">
            {mapState.selectedNodes.size > 0 ? (
                <div className="-mt-16">
                    <span className="text-center">
                        <PauseIcon className="h-8 w-8 text-blue-600 inline mb-4" />
                    </span>
                    <h4 className="text-gray-300 tracking-widest text-lg mb-3">Map visualization is paused</h4>
                    <p className="text-gray-400 mb-3">
                        <br />
                        There are {mapState.graphData.nodes.length} nodes in your search results
                        <br />
                        <br />
                        The map will render up to a maximum of {maxNodeCount} nodes
                        <br />
                        <br />
                        <br />
                        Please reduce the number of nodes in the result set to begin navigating the map
                        <br />
                        <span className="text-gray-500">or</span>
                        <br />
                        Open the
                        <button
                            className="underline hover:text-blue-600 active:text-blue-700 focus:outline-none focus:ring-0 mx-1"
                            onClick={() => dispatch({ type: 'toggle-data-browser' })}
                        >
                            Access Data Browser
                        </button>
                        to explore all results
                    </p>
                    <br />
                    <span className="text-gray-500">
                        <ul className="list-disc text-justify px-14 space-y-2 mt-3">
                            <span className="font-semibold text-left">Results can be reduced by</span>
                            <li>Narrowing the selected time period</li>
                            <li>
                                Removing nodes from the explorer by clicking the minus icon
                                <br />
                                or toggling the search selector to off for any node
                            </li>
                            <li>Applying or modifying a search filter</li>
                        </ul>
                    </span>
                </div>
            ) : (
                <>
                    <span className="text-center">
                        <BoltIcon className="h-8 w-8 text-green-600 inline mb-4" />
                    </span>
                    <h4 className="text-gray-400 tracking-widest text-base mb-3">
                        Get Started by Adding a Node to the Explorer
                    </h4>
                    <p className="text-gray-500 text-xs mb-3">
                        <br />
                        Please add a node to the explorer to begin navigating the map
                        <br />
                        <br />
                        <span className="text-gray-600">
                            Nodes can be added to the explorer through the search bar or by clicking on a node in
                            Insights
                        </span>
                        <br />
                        <br />
                        Alternatively, adjust or add a search filter
                        <br />
                        <br />
                        <span className="text-gray-600">
                            There are {mapState.graphData.nodes.length} nodes to select from in the current time range
                        </span>
                    </p>
                </>
            )}
        </div>
    );
};
