import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Fragment, useContext, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AlertsConfigMock } from './Components/AlertsConfigMock';
import { SideNavMock } from './Components/AlertsSideNavMock';
import { Helmet } from 'react-helmet-async';
import { ActiveAlertsMock } from './Components/ActiveAlertsMock';
import { AlertsRecipients } from './Components/AlertsRecepients';

export const AlertsModalMock = (): JSX.Element => {
    const { dispatch } = useContext(IdentityMapContext);

    const closePAlerts = () => dispatch({ type: 'toggle-alerts' });
    useHotkeys('esc', closePAlerts);

    const [selectedWindow, setSelectedWindow] = useState<'overview' | 'triggered' | 'config' | 'destinations'>(
        'triggered',
    );

    return (
        <Transition.Root show={true} as={Fragment}>
            <div className="fixed bottom-0 z-10 inset-0 top-[56px] h-[calc(100vh-56px)]">
                <Helmet>
                    <title>Alerts (Prototype)</title>
                </Helmet>
                <div className="flex items-end justify-center text-center sm:block sm:p-0">
                    <div
                        className="fixed top-[56px] h-[calc(100vh-56px)] w-[100vw] after:inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                        onClick={closePAlerts}
                    />

                    <div
                        id="Alerts"
                        className="absolute left-[50%] translate-x-[-50%] inline-block bg-gray-800 rounded-lg text-left shadow-xl transform transition-all w-[90vw] xxl:w-[75vw] mt-14 align-top md:mt-5"
                    >
                        <div
                            id="Header"
                            className="flex justify-between items-start p-4 pt-3 pb-3 border-b border-gray-700"
                        >
                            <h1 className="text-md font-semibold tracking-wider">Alerts (Prototype)</h1>
                        </div>
                        <div className="flex items-stretch relative ">
                            <div className="w-80 lg:w-64 border-r border-gray-700 xs:collapse h-[calc(100vh-169px)] overflow-y-scroll ">
                                <SideNavMock selectedWindow={selectedWindow} setSelectedWindow={setSelectedWindow} />
                            </div>
                            <div className="bg-gray-700/50 flex-1 h-[calc(100vh-168px)] shadow-[2px_2px_4px_0_rgba(0,0,0,0.25)_inset] overflow-y-scroll ">
                                {selectedWindow === 'config' && <AlertsConfigMock />}
                                {selectedWindow === 'triggered' && <ActiveAlertsMock />}
                                {selectedWindow === 'destinations' && <AlertsRecipients />}
                            </div>
                        </div>
                        <button
                            type="button"
                            className="text-white text-xs rounded-full p-1 bg-gray-900 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                            onClick={closePAlerts}
                        >
                            <XMarkIcon className="h-4 w-4 text-gray-200" />
                        </button>
                    </div>
                </div>
            </div>
        </Transition.Root>
    );
};
