import { NoSymbolIcon, CheckCircleIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { ContextMenu } from 'Map/Components/ContextMenu';
import { ReactNode } from 'react';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { Node } from 'Types/types';
import { classNames } from 'Utilities/utils';
import React from 'react';

type ListProps = {
    children?: ReactNode;
    title?: string;
    sort?: string[];
    sortCallback?: (sort: string) => void;
    className?: string;
    loading?: boolean;
    error?: boolean;
    emptyIsPositive?: boolean;
    skipHeaderLine?: boolean;
    skipListPadding?: boolean;
};

export const List = ({
    title,
    sort,
    sortCallback,
    className,
    children,
    loading,
    error,
    emptyIsPositive = false,
    skipHeaderLine = false,
    skipListPadding = false,
}: ListProps) => {
    const noChildren = !Boolean(children);
    const allUndefined = React.Children.toArray(children).every((child) => child === undefined);
    const isEmpty = noChildren || allUndefined;
    return (
        <div className={className ? className : 'bg-gray-700 rounded-md w-full p-3 relative'}>
            {title && (
                <div
                    className={classNames(
                        'flex items-center justify-between min-h-[42px]',
                        skipHeaderLine ? 'pb-1' : 'border-b border-gray-500 pb-3',
                    )}
                >
                    <h1 className="text-sm font-medium">{title}</h1>
                    {sort && (
                        <select
                            className="input-gray p-1.5 text-xs w-auto pr-8"
                            onChange={(event) => {
                                sortCallback && sortCallback(event.currentTarget.value);
                            }}
                        >
                            {sort?.map((item) => <option key={item}>{item}</option>)}
                        </select>
                    )}
                </div>
            )}

            {!error && !isEmpty ? (
                <ul className={classNames('space-y-1', skipListPadding ? '' : 'pt-2')}>{children}</ul>
            ) : loading ? null : emptyIsPositive && !error ? (
                <ListEmptyPositive />
            ) : (
                !error && <ListEmpty />
            )}

            {error && <ListError />}
            {loading && !error && <ListLoading />}
        </div>
    );
};

type ListItemProps = {
    title: string;
    description?: string;
    descriptionColor?: string;
    secondTitle?: string;
    secondDescription?: string;
    secondDescriptionColor?: string;
    thirdTitle?: string;
    thirdDescription?: string;
    thirdDescriptionColor?: string;
    hoverColor?: string;
    icon?: JSX.Element;
    iconHTMLImageElement?: HTMLImageElement;
    pointer?: 'cursor-pointer' | 'cursor-default';
    highlighted?: boolean;
    onClick?: () => void;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    node?: Node | null;
    children?: ReactNode;
    className?: string;
    height?: string;
};

export const ListItem: React.FunctionComponent<ListItemProps> = ({
    title,
    description,
    descriptionColor = 'text-gray-400',
    secondTitle,
    secondDescription,
    secondDescriptionColor = 'text-gray-400',
    thirdTitle,
    thirdDescription,
    thirdDescriptionColor = 'text-gray-400',
    hoverColor = 'bg-gray-600',
    icon,
    iconHTMLImageElement,
    onClick,
    onMouseOver,
    onMouseOut,
    pointer = 'cursor-default',
    highlighted,
    node,
    className,
    height = 'h-10',
}) => {
    const menuId = `list-menu-${node?.id}-${title}-${description}-${secondTitle}-${secondDescription}`;

    const displayContextMenu = (e: TriggerEvent & { clientX: number; clientY: number }) => {
        // prevent default List click action
        e.stopPropagation();
        e.preventDefault();

        // show context menu
        show(e, {
            position: {
                x: e.clientX,
                y: e.clientY,
            },
        });
    };

    const { show } = useContextMenu({
        id: menuId,
    });

    return (
        <>
            <li
                className={classNames(
                    'w-full flex justify-between items-center space-x-2 py-1 px-2 rounded-md break-inside-avoid-column',
                    height ? height : '',
                    `hover:${hoverColor}`,
                    pointer ? pointer : '',
                    highlighted ? 'bg-gray-800/50' : '',
                    className ? className : '',
                )}
                onContextMenu={node ? displayContextMenu : undefined}
                onClick={onClick && onClick}
                onMouseOver={onMouseOver && onMouseOver}
                onMouseOut={onMouseOut && onMouseOut}
                title={title}
            >
                {icon && <div className="flex-none">{icon}</div>}
                {iconHTMLImageElement && (
                    <div className="flex-none">
                        <img className="h-7 w-7" src={iconHTMLImageElement?.src} />
                    </div>
                )}
                <div className="flex-grow basis-1/2 items-center overflow-hidden">
                    <div className="flex-col">
                        <div className="text-white font-medium truncate break-words">{title}</div>
                        {description && <p className={descriptionColor}>{description}</p>}
                    </div>
                </div>
                {thirdDescription && (
                    <div className="flex-none w-20 text-right">
                        <h3 className={thirdDescriptionColor}>
                            {thirdDescription} {thirdTitle}
                        </h3>
                    </div>
                )}
                {secondDescription && (
                    <div className="flex-grow basis-1/4 text-right">
                        <h3 className={secondDescriptionColor}>
                            {secondDescription} {secondTitle}
                        </h3>
                    </div>
                )}
            </li>

            <ContextMenu id={menuId} node={node} />
        </>
    );
};

const ListLoading = () => {
    return (
        <ul className="text-center text-gray-300 pt-2 flex flex-col items-center justify-center h-40">
            <div className="h-8 w-8 loader mb-3" />
            Loading...
        </ul>
    );
};

const ListEmpty = () => {
    return (
        <ul className="text-center text-gray-400 pt-2 flex flex-col items-center justify-center h-40">
            <DocumentMagnifyingGlassIcon className="h-8 w-8 text-gray-400 mb-3" />
            No matching records
        </ul>
    );
};

const ListEmptyPositive = () => {
    return (
        <ul className="text-center text-gray-400 pt-2 flex flex-col items-center justify-center h-40">
            <CheckCircleIcon className="h-8 w-8 text-green-400 mb-3" />
            None Reported
        </ul>
    );
};

const ListError = () => {
    return (
        <ul className="text-center text-red-400 pt-2 flex flex-col items-center justify-center h-40">
            <NoSymbolIcon className="h-8 w-8 text-red-400 mb-3" />
            Error loading results
        </ul>
    );
};
