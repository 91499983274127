import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Link, Node } from 'Types/types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import {
    breakLoop,
    getMfaDetails,
    getOperatingSystemDisplayNameFromNode,
    groupingForceApplied,
    resetLinkStrengths,
    tagNameLookup,
} from 'Utilities/utils';
import { useWindowSize } from '@react-hook/window-size';
import { paintLink, paintLinkPointerArea, paintNode, paintNodePointerArea, renderFramePost } from './Paint';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHotkeys } from 'react-hotkeys-hook';
import forceInABox from 'force-in-a-box/src/forceInABox';
import { renderToString } from 'react-dom/server';
import { getGroupTrail, getParentGroupTrail, isAGroupLeafNode, isATargetGroup } from 'Utilities/NodeUtilities';
import { useProductTutorial } from 'Hooks/Hooks';
import { getIconSourceURL, getNodeIconElement, getTagIconElement } from './Icons';
import { NoSymbolIcon, ExclamationTriangleIcon, ShieldCheckIcon, CheckIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useGraphControls } from 'Hooks/GraphHooks';

export const NODE_R = 3;
export const PAN_HOTKEY = 'space';

export const Graph = (): JSX.Element => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { runOnTutorialStep, moveToNextTutorialStep } = useProductTutorial();
    const { maxNodeCount, autoPauseRedraw = true, cooldownTime = 3000 } = useFlags();
    const strengths = mapState.strengths;
    // We need to set the boundaries of graph to the size of the window minus the header
    // In medium and larger screens, the header takes 64px
    // In small screens the header takes 156px
    const [width, windowHeight] = useWindowSize();
    const height = useMemo(() => (width >= 768 ? windowHeight : windowHeight), [width, windowHeight]);
    const {
        getVisibleNodes,
        addNodeToExplorer,
        removeNodeFromExplorer,
        addNodeToQuery,
        isNodeInExplorer,
        isNodeInQuery,
    } = useGraphControls();

    const [panHotkeyIsPressed, setPanHotkeyIsPressed] = useState(false);
    const [altHotkeyIsPressed, setAltHotkeyIsPressed] = useState(false);

    // Watch all key presses, and if the alt key is pressed, set the altHotkeyIsPressed state to true
    useHotkeys(
        '*',
        (key) => {
            if (!altHotkeyIsPressed && key.altKey) {
                setAltHotkeyIsPressed(true);
            }
        },
        { keydown: true, keyup: false },
    );

    // When the pan hotkey is pressed down, we want to set that state and force a re-render
    useHotkeys(
        PAN_HOTKEY,
        () => {
            if (!panHotkeyIsPressed) {
                setPanHotkeyIsPressed(true);
            }
        },
        { keydown: true, keyup: false },
    );

    // When the pan hotkey is released, we want to set that state and force a re-render
    useHotkeys(
        PAN_HOTKEY,
        () => {
            setPanHotkeyIsPressed(false);
        },
        { keydown: false, keyup: true },
    );

    useEffect(() => {
        if (mapState.graphData.nodes.length > maxNodeCount) {
            return;
        }

        const g = mapState.graphRef?.current;
        if (g) {
            if (mapState.newGroupPending) {
                const { group } = mapState;
                const nodes = getVisibleNodes();
                const nodeCount = nodes.length;
                const largeDatasetNodeCount = 500;
                if (nodeCount > largeDatasetNodeCount) {
                    dispatch({ type: 'set-large-dataset-loading', loading: true });
                }

                if (groupingForceApplied(group)) {
                    const links = mapState.graphData.links.map((l) => {
                        const sourceId = l.source.toString();
                        const targetId = l.target.toString();
                        const source = nodes.findIndex((n) => n.id === sourceId);
                        const target = nodes.findIndex((n) => n.id === targetId);
                        return { source, target };
                    });

                    // Calculate a good size for the group layout graph, based on the number
                    // of nodes on the map, with a maximum size of 2000px
                    let graphSize = 100;
                    let distance = 35;
                    let charge = -5;

                    // If there is only a few nodes, we want to make the graph smaller
                    if (nodeCount < 10) {
                        graphSize = 50;
                        distance = 10;
                        charge = -1;
                    } else {
                        const nodeCountDoubled = nodeCount * 1.8 || 500;
                        graphSize = Math.min(nodeCountDoubled, 2000);
                        graphSize = Math.max(graphSize, 150);
                    }

                    const groupingForce = forceInABox();
                    groupingForce
                        .strength(0.12) // Strength to foci
                        .template('treemap') // Either treemap or force
                        .groupBy('group') // Node attribute to group
                        .links(links) // The graph links. Must be called after setting the grouping attribute
                        .enableGrouping(true)
                        .linkStrengthIntraCluster(0.3)
                        .forceLinkDistance(distance)
                        .size([graphSize, graphSize]);
                    g.d3Force('groupingForce', groupingForce);
                    g.d3Force('charge')?.strength(charge);
                    g.d3Force('link')?.strength(groupingForce.getLinkStrength);
                } else {
                    resetLinkStrengths(g, strengths);
                }

                g.d3ReheatSimulation();
                dispatch({ type: 'set-group-applied' });
            }
        }
    }, [
        dispatch,
        getVisibleNodes,
        mapState,
        mapState.graphData.links,
        mapState.graphData.nodes,
        mapState.graphRef,
        maxNodeCount,
        strengths,
    ]);

    const [highlightNodes, setHighlightNodes] = useState<Set<Node>>(new Set());
    const [highlightLinks, setHighlightLinks] = useState<Set<Link>>(new Set());

    const updateHighlight = (links: Set<Link>, nodes: Set<Node>) => {
        setHighlightNodes(nodes);
        setHighlightLinks(links);
    };

    const clickNode = useCallback(
        (node: Node, event: MouseEvent) => {
            console.log('Clicking node:', node);
            if (!panHotkeyIsPressed) {
                event.preventDefault();

                // If the shift key is pressed, there can be some special actions on the node
                if (event.shiftKey) {
                    // If the node is target and has a next level, expand it
                    if (isATargetGroup(node)) {
                        const trail = getGroupTrail(node);
                        if (trail) {
                            dispatch({ type: 'set-block-zoom', blocked: true });
                            dispatch({ type: 'add-level-trail', trail: trail });
                        }
                        return;
                    }
                    // If the node is a target and is a child level, go back to the parent level
                    if (isAGroupLeafNode(node)) {
                        const trail = getParentGroupTrail(node);
                        if (trail) {
                            dispatch({ type: 'set-block-zoom', blocked: true });
                            dispatch({ type: 'remove-level-trail', trail });
                        }
                        return;
                    }
                }

                const addingNode = !isNodeInExplorer(node);
                console.log('Adding node:', addingNode);

                if (addingNode) {
                    addNodeToExplorer(node);
                    if (event.shiftKey) {
                        addNodeToQuery(node);
                    }
                } else {
                    // Queried nodes can't be removed from the explorer by a single click
                    if (!isNodeInQuery(node)) {
                        removeNodeFromExplorer(node);
                    } else {
                        if (node.label && ['actor', 'target', 'device'].includes(node.label)) {
                            dispatch({ type: 'set-profile-node', node: node });
                            dispatch({ type: 'set-profile-window', open: true });
                        }
                    }
                }

                if (addingNode) {
                    if (node.label && ['actor', 'target', 'device'].includes(node.label)) {
                        dispatch({ type: 'set-profile-node', node: node });
                        dispatch({ type: 'set-profile-window', open: true });
                    }
                    dispatch({ type: 'set-scroll-to-node', node: node });
                }

                runOnTutorialStep('Click', () => {
                    if (node.props.displayName == 'Star Patrick') {
                        moveToNextTutorialStep();
                    }
                });
            }
        },
        [
            addNodeToExplorer,
            addNodeToQuery,
            dispatch,
            isNodeInExplorer,
            isNodeInQuery,
            moveToNextTutorialStep,
            panHotkeyIsPressed,
            removeNodeFromExplorer,
            runOnTutorialStep,
        ],
    );

    const handleLinkClick = useCallback(
        (link: Link) => {
            if (!panHotkeyIsPressed) {
                dispatch({ type: 'click-link', link });
            }
        },
        [dispatch, panHotkeyIsPressed],
    );

    const handleBackgroundClick = useCallback(() => {
        console.debug('entrypoint mode enabled, not executing background click');
    }, []);

    const nodeVisibility = useCallback(
        (node: Node): boolean => {
            switch (node.label) {
                case 'actor':
                    return mapState.visible.actors;
                case 'identity':
                    return mapState.visible.identities;
                case 'device':
                    return mapState.visible.devices;
                case 'target':
                    return mapState.visible.targets;
                case 'application':
                    return mapState.visible.applications;
            }
            return true;
        },
        [mapState.visible],
    );

    const linkVisibility = useCallback(
        (link: Link): boolean => {
            if (link) {
                if (link.source.hidden || (link.target as Node).hidden) {
                    return false;
                }

                switch (link.source.label) {
                    case 'actor':
                        if (!mapState.visible.actors) {
                            return false;
                        }
                        break;
                    case 'identity':
                        if (!mapState.visible.identities) {
                            return false;
                        }
                        break;
                    case 'device':
                        if (!mapState.visible.devices) {
                            return false;
                        }
                        break;
                    case 'target':
                        if (!mapState.visible.targets) {
                            return false;
                        }
                        break;
                    case 'application':
                        if (!mapState.visible.applications) {
                            return false;
                        }
                        break;
                }

                switch ((link.target as Node).label) {
                    case 'actor':
                        if (!mapState.visible.actors) {
                            return false;
                        }
                        break;
                    case 'identity':
                        if (!mapState.visible.identities) {
                            return false;
                        }
                        break;
                    case 'device':
                        if (!mapState.visible.devices) {
                            return false;
                        }
                        break;
                    case 'target':
                        if (!mapState.visible.targets) {
                            return false;
                        }
                        break;
                    case 'applications':
                        if (!mapState.visible.applications) {
                            return false;
                        }
                        break;
                }

                if (link.grouping) return false;
            }
            return true;
        },
        [mapState.visible],
    );

    const highlightRelatedLinks = useCallback((parent: Node, neighbor: Node, links: Set<Link>) => {
        parent.links.map((l) => {
            if ((l.target as Node).id == neighbor.id || (l.source as Node).id == neighbor.id) {
                links.add(l);
            }
        });
    }, []);

    const highlightNeighbors = useCallback(
        (parent: string | number, root: string, highlightNodes: Set<Node>, highlightLinks: Set<Link>, node: Node) => {
            highlightNodes.add(node);

            node.neighbors.map((neighbor) => {
                if (neighbor.id != parent) {
                    // Logic to stop loops forming
                    if (breakLoop(root, node.label, neighbor.label)) return;

                    // Recurse through and highlight all valid neighbors
                    highlightNeighbors(node.id, root, highlightNodes, highlightLinks, neighbor);
                    highlightRelatedLinks(node, neighbor, highlightLinks);
                }
            });
        },
        [highlightRelatedLinks],
    );

    const handleNodeHover = useCallback(
        (node: Node | null) => {
            if (mapState.identityMapContextMenuOpen) {
                return;
            }
            const tmpHighlightNodes = new Set<Node>();
            const tmpHighlightLinks = new Set<Link>();

            dispatch({ type: 'set-root-hovered-node', node: node });

            if (node) {
                highlightNeighbors(node.id, node.label || 'unknown', tmpHighlightNodes, tmpHighlightLinks, node);
            }

            updateHighlight(tmpHighlightLinks, tmpHighlightNodes);
        },
        [dispatch, highlightNeighbors, mapState.identityMapContextMenuOpen],
    );

    // If the context menu is closed, set the hovered node/link set to null
    useEffect(() => {
        if (!mapState.identityMapContextMenuOpen) {
            updateHighlight(new Set<Link>(), new Set<Node>());
        }
    }, [mapState.identityMapContextMenuOpen]);

    const handleLinkHover = useCallback(
        (l: Link | null) => {
            if (mapState.identityMapContextMenuOpen) {
                return;
            }
            if (!panHotkeyIsPressed) {
                const link = l as Link;

                const tmpHighlightNodes = new Set<Node>();
                const tmpHighlightLinks = new Set<Link>();
                if (link) {
                    tmpHighlightLinks.add(link);
                    tmpHighlightNodes.add(link.source as Node);
                    tmpHighlightNodes.add(link.target as Node);
                }
                updateHighlight(tmpHighlightLinks, tmpHighlightNodes);
            }
        },
        [mapState.identityMapContextMenuOpen, panHotkeyIsPressed],
    );

    const paintNodeCallback = useCallback(
        (node: Node, ctx: CanvasRenderingContext2D, globalScale: number) => {
            const nodeIsInExplorer = isNodeInExplorer(node);
            const nodeIsInQuery = isNodeInQuery(node);
            const nodeHighlighted = highlightNodes.has(node);
            const layers = mapState.layers;

            paintNode(
                node,
                nodeIsInExplorer,
                nodeHighlighted,
                nodeIsInQuery,
                globalScale,
                layers,
                ctx,
                mapState.selectedNodes,
                mapState.lockedNodes,
            );
        },
        [
            isNodeInExplorer,
            isNodeInQuery,
            highlightNodes,
            mapState.layers,
            mapState.selectedNodes,
            mapState.lockedNodes,
        ],
    );

    const paintLinkCallback = useCallback(
        (link: Link, ctx: CanvasRenderingContext2D, globalScale: number) => {
            const linkSelected = mapState.selectedLink === link;
            const linkHighlighted = highlightLinks.has(link);
            const layers = mapState.layers;

            paintLink(link, linkSelected, linkHighlighted, globalScale, layers, ctx);
        },
        [highlightLinks, mapState],
    );

    const { showId } = useFlags();

    const nodeLabel = useCallback(
        (node: Node) => {
            if (mapState.identityMapContextMenuOpen && mapState.rootHoveredNode?.id === node.id) {
                return '';
            }
            return getNodeLabel(node, showId);
        },
        [mapState.identityMapContextMenuOpen, mapState.rootHoveredNode?.id, showId],
    );

    const linkLabel = useCallback((link: Link) => {
        return getLinkLabel(link);
    }, []);

    const onRenderFramePost = useCallback(
        (ctx: CanvasRenderingContext2D, scale: number) => {
            const graph = mapState.graphRef?.current;
            const shouldDrawGroups = Object.values(mapState.group).some((g) => g !== undefined);

            if (graph && shouldDrawGroups) {
                const nodes = mapState.graphData.nodes;
                const nodeTypesVisible = mapState.visible;
                const groups = Array.from(new Set(nodes.map((n) => (n.group ? String(n.group) : ''))));
                renderFramePost(ctx, scale, nodes, nodeTypesVisible, groups, graph);
            }
        },
        [mapState.graphData.nodes, mapState.graphRef, mapState.group, mapState.visible],
    );

    const handleNodeDrag = useCallback(
        (node: Node, translate: { x: number; y: number }) => {
            // If alt (or option) is pressed, we are going to move all selected nodes
            // in parallel as we drag the node under the cursor
            if (altHotkeyIsPressed) {
                const { x, y } = translate;
                mapState.selectedNodes.forEach((n) => {
                    // We don't want to apply the translation 2x to the originally dragged node
                    if (n.id == node.id) return;

                    // Ensure that all the selected nodes are in fixed position mode
                    if (!n.fx) n.fx = n.x;
                    if (!n.fy) n.fy = n.y;

                    // Translate the node
                    n.fx += x;
                    n.fy += y;
                });
            }
        },
        [altHotkeyIsPressed, mapState.selectedNodes],
    );

    const handleNodeDragEnd = useCallback(
        (node: Node) => {
            // After we finished dragging a node, if this was a multi-node drag we need to
            // do some clean up
            if (altHotkeyIsPressed) {
                // Ensure the originally dragged node gets a fixed position so it doesn't ping back into place
                node.fx = node.x;
                node.fy = node.y;

                // Add all the selected nodes to the locked nodes set
                const newLockedNodes = new Set(mapState.lockedNodes);
                mapState.selectedNodes.forEach((n) => {
                    newLockedNodes.add(n);
                });
                dispatch({ type: 'set-locked-nodes', nodes: newLockedNodes });

                // For the alt (option) key into an unpressed state. This is not completely ideal as the
                // user will need to repress option to initiate another multi-drag
                setAltHotkeyIsPressed(false);
            } else {
                dispatch({ type: 'add-locked-node', node });
            }

            runOnTutorialStep('Drag', () => {
                if (node.props.displayName == 'Star Patrick') {
                    moveToNextTutorialStep();
                }
            });
        },
        [
            altHotkeyIsPressed,
            dispatch,
            mapState.lockedNodes,
            mapState.selectedNodes,
            moveToNextTutorialStep,
            runOnTutorialStep,
        ],
    );

    const handleZoom = useCallback(
        (zoom: { x: number; y: number; k: number }) => {
            const { x, y, k } = zoom;

            mapState.graphPosition.x = x;
            mapState.graphPosition.y = y;
            mapState.graphPosition.k = k;
        },
        [mapState.graphPosition],
    );

    return (
        <ForceGraph2D
            autoPauseRedraw={autoPauseRedraw}
            width={width}
            height={height}
            ref={mapState.graphRef}
            graphData={mapState.graphData.nodes.length <= maxNodeCount ? mapState.graphData : undefined}
            cooldownTime={cooldownTime}
            warmupTicks={mapState.largeDatasetLoading ? 60 : 3}
            nodeRelSize={3}
            linkVisibility={linkVisibility}
            linkCanvasObject={paintLinkCallback}
            linkPointerAreaPaint={useCallback(paintLinkPointerArea, [])}
            linkLabel={linkLabel}
            nodeVisibility={nodeVisibility}
            nodeCanvasObject={paintNodeCallback}
            nodePointerAreaPaint={useCallback(paintNodePointerArea, [])}
            nodeLabel={nodeLabel}
            onNodeClick={clickNode}
            onNodeHover={handleNodeHover}
            onLinkHover={handleLinkHover}
            onLinkClick={handleLinkClick}
            onRenderFramePost={onRenderFramePost}
            onBackgroundClick={handleBackgroundClick}
            linkCurvature={0.05}
            enableNodeDrag={!panHotkeyIsPressed}
            onNodeDrag={handleNodeDrag}
            onNodeDragEnd={handleNodeDragEnd}
            onZoom={handleZoom}
            maxZoom={100}
            minZoom={1}
        />
    );
};

const getLinkLabel = (link: Link) => {
    const source = link.source;
    const target = link.target as Node;
    const mfaDetails = getMfaDetails(link);
    const hasMfaDetails = mfaDetails.length > 0;
    const hasMfaTags =
        link.props.TAG_FACTOR_REQUIRE_MFA ||
        link.props.TAG_FACTOR_OK ||
        link.props.TAG_FACTOR_FAILED ||
        link.props.TAG_FACTOR_REJECTED ||
        mfaDetails.length > 0;
    const hasValidMfa = link.props.TAG_FACTOR_REQUIRE_MFA && link.props.TAG_FACTOR_OK;
    const hasFailedMfa =
        link.props.TAG_FACTOR_REQUIRE_MFA && (link.props.TAG_FACTOR_FAILED || link.props.TAG_FACTOR_REJECTED);
    const hasBeenFlaggedByMorpheusDfp = link.props.TAG_SESSION_FLAGGED_BY_MORPHEUS_DFP;

    return renderToString(
        <div className="text-center p-1.5">
            <div className="space-y-1">
                <div className="flex items-center justify-center">
                    <img className="h-5 w-5 ml-2 block" src={getIconSourceURL(getNodeIconElement(source))} />
                    <div className="pl-2 text-sm">{source.props.displayName} </div>
                </div>
                <div className="text-gray-500 text-xs">{linkType(source.label)}</div>
                <div className="flex items-center justify-center">
                    <img className="h-5 w-5 block" src={getIconSourceURL(getNodeIconElement(target))} />
                    <div className="pl-2 text-sm">{target.props.displayName} </div>
                </div>
            </div>

            {link.policyStats && link.policyStatsAbsolute && (
                <div>
                    <div className="py-2">
                        <div className="w-full border-t border-gray-600"></div>
                    </div>
                    <div className="flex justify-center space-y-0.5">
                        {link.policyStatsAbsolute.success > 0 && (
                            <PolicyStatDisplay
                                value={link.policyStatsAbsolute.success}
                                percentage={link.policyStats.success}
                                icon={<ShieldCheckIcon className="h-4 w-4 p-0 m-0 block text-lime-600" />}
                            />
                        )}
                        {link.policyStatsAbsolute.warning > 0 && (
                            <PolicyStatDisplay
                                value={link.policyStatsAbsolute.warning}
                                percentage={link.policyStats.warning}
                                icon={<ExclamationTriangleIcon className="h-4 w-4 p-0 m-0 block text-yellow-500" />}
                            />
                        )}
                        {link.policyStatsAbsolute.critical > 0 && (
                            <PolicyStatDisplay
                                value={link.policyStatsAbsolute.critical}
                                percentage={link.policyStats.critical}
                                icon={<NoSymbolIcon className="h-4 w-4 p-0 m-0 block text-red-500" />}
                            />
                        )}
                        {link.policyStatsAbsolute.neutral > 0 && (
                            <PolicyStatDisplay
                                value={link.policyStatsAbsolute.neutral}
                                percentage={link.policyStats.neutral}
                                icon={<InformationCircleIcon className="h-4 w-4 p-0 m-0 block text-gray-500" />}
                            />
                        )}
                    </div>
                </div>
            )}

            {hasMfaTags && (
                <div>
                    <div className="py-2">
                        <div className="w-full border-t border-gray-600"></div>
                    </div>
                    <div className="flex justify-center space-y-0.5 text-xs flex-col">
                        {hasBeenFlaggedByMorpheusDfp && (
                            <div>
                                <span className="flex items-center justify-center">
                                    <ExclamationTriangleIcon className="h-4 w-4 mr-2 text-blue-500" />
                                    Flagged by Morpheus DFP
                                </span>
                            </div>
                        )}

                        {hasValidMfa && (
                            <div>
                                <span className="flex items-center justify-center">
                                    <CheckIcon className="h-4 w-4 mr-2 text-lime-600" />
                                    MFA Valid
                                </span>
                            </div>
                        )}

                        {hasFailedMfa && (
                            <div>
                                <span className="flex items-center justify-center">
                                    <ExclamationTriangleIcon className="h-4 w-4 mr-2 text-red-500" />
                                    MFA Failed
                                </span>
                            </div>
                        )}

                        {hasMfaDetails && (
                            <ul className="text-xxs mt-2">
                                {getMfaDetails(link).map((detail) => (
                                    <li key={detail}>{detail}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>,
    );
};

const linkType = (label: string | undefined) => {
    switch (label) {
        case 'target':
        case 'identity':
            return 'Accessed by';
        case 'application':
        case 'actor':
        case 'device':
            return 'Used by';
    }
};

const PolicyStatDisplay = ({ value, percentage, icon }: { value: number; percentage: number; icon: JSX.Element }) => {
    return (
        <div className="text-xs flex px-1">
            <div className="w-4">
                <div className="flex place-content-center">{icon}</div>
            </div>
            <div className="flex flex-1 justify-start items-center pl-2">
                <span className="text-left whitespace-nowrap text-xs">
                    {value} ({percentage}%)
                </span>
            </div>
        </div>
    );
};

const getNodeLabel = (node: Node, showId?: boolean) => {
    return renderToString(
        <div className="text-center ml-2 mr-2">
            <div className="mb-2 mt-2">
                <h1 className="text-center">{node.props.displayName ? node.props.displayName : node.name}</h1>
            </div>

            {node.props.alternateId && <p className="text-xs">{node.props.alternateId}</p>}

            {node.props.serviceDomain && <p className="text-xs">{node.props.serviceDomain}</p>}
            {node.props.serviceId && <p className="text-xs">{node.props.serviceId}</p>}
            {node.props.application && <p className="text-xs">{node.props.application}</p>}

            {node.props.sessionStart && node.props.sessionStart != 0 && (
                <p className="text-xs">Session started at {epochToLocal(node.props.sessionStart)}</p>
            )}
            {node.props.sessionExpectedEnd && node.props.sessionExpectedEnd != 0 && (
                <p className="text-xs">Session expected to end at {epochToLocal(node.props.sessionExpectedEnd)}</p>
            )}
            {node.props.sessionActualEnd && node.props.sessionActualEnd != 0 && (
                <p className="text-xs">Session actually ended at {epochToLocal(node.props.sessionActualEnd)}</p>
            )}

            {node.props.deviceOperatingSystem && (
                <p className="text-xs capitalize">{getOperatingSystemDisplayNameFromNode(node)}</p>
            )}
            <div className="grid grid-col-1 justify-center space-y-0.5">
                {node.tags.map((tag) => {
                    return (
                        <div className="text-xs grid grid-flow-col auto-cols-min w-auto gap-2" key={tag}>
                            <div className="w-6">
                                <div className="h-6 w-6 p-0.5 flex place-content-center">
                                    <img
                                        className="h-5 w-5 p-0 m-0 block"
                                        src={getIconSourceURL(getTagIconElement(tag))}
                                    />
                                </div>
                            </div>
                            <div className="justify-self-start self-center">
                                <span className="text-left whitespace-nowrap">{tagNameLookup(tag)}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
            {showId && (
                <div className="mb-2 pt-2">
                    <p className="text-xs">{node.id}</p>
                </div>
            )}
        </div>,
    );
};

const epochToLocal = (epoch: number) => {
    // Convert epoch to local time
    // The epochs returned by our backend are in nanoseconds, so we need to convert them to seconds
    return new Date(epoch / 1000000).toLocaleString();
};
