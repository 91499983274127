type FilterOperationType = 'string' | 'number' | 'object';

type FilterProperty = {
    type: FilterOperationType;
    name: string;
};

type FilterSchema = {
    [key: string]: { [key: string]: FilterProperty };
};

export const FilterSchema: FilterSchema = {
    target: {
        displayName: { type: 'string', name: 'name' },
        serviceDomain: { type: 'string', name: 'domain' },
        tags: { type: 'object', name: ' tags' },
        id: { type: 'string', name: 'id' },
    },
    identity: {
        displayName: { type: 'string', name: 'name' },
        tags: { type: 'object', name: ' tags' },
        id: { type: 'string', name: 'id' },
    },
    actor: {
        displayName: { type: 'string', name: 'name' },
        alternateId: { type: 'string', name: 'email' },
        deviceCount: { type: 'number', name: 'device count' },
        tags: { type: 'object', name: ' tags' },
        id: { type: 'string', name: 'id' },
    },
    device: {
        displayName: { type: 'string', name: 'name' },
        userAgent: { type: 'string', name: 'user agent' },
        deviceOperatingSystem: { type: 'string', name: 'operating system' },
        deviceType: { type: 'string', name: 'device type' },
        tags: { type: 'object', name: ' tags' },
        id: { type: 'string', name: 'id' },
    },
    application: {
        displayName: { type: 'string', name: 'name' },
        tags: { type: 'object', name: ' tags' },
        id: { type: 'string', name: 'id' },
    },
    link: {
        success: { type: 'number', name: 'success count' },
        warning: { type: 'number', name: 'challenge count' },
        critical: { type: 'number', name: 'failure count' },
        unexpected: { type: 'number', name: 'unexpected location' },
    },
};
