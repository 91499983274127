import { Fragment, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
    BellIcon,
    BellSlashIcon,
    ChevronDownIcon,
    ChevronUpDownIcon,
    ChevronUpIcon,
    FunnelIcon,
} from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';
import { CheckCircleIcon, PauseIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import Select from 'react-select';
import { DefaultCell } from 'Map/DataBrowser/DataBrowserCells';
import { useTable, useFilters, useSortBy, usePagination, useBlockLayout, useResizeColumns, Column } from 'react-table';
import {
    fuzzyTextFilterFn,
    fuzzyNodeDisplayNameFilterFn,
    filterGreaterThan,
    DefaultColumnFilter,
} from 'Library/TableComponents';
import { availableRecipients } from './AlertsRecepients';

export type Destination = { value: string; label: string; description: string };

export type AlertData = {
    name: string;
    status: string;
    query: string;
    message: string;
    warn: number;
    alert: number;
    muted: boolean;
    mutedUntil: string;
    destination: Destination[];
};

const AlertConfigColumns = () =>
    [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            width: 500,
        },
        {
            Header: 'Status',
            accessor: 'status',
            filter: 'fuzzyText',
            width: 150,
        },
        {
            Header: 'Muted',
            accessor: 'muted',
            filter: 'fuzzyText',
            width: 150,
        },
        {
            Header: 'Muted Until',
            accessor: 'mutedUntil',
            filter: 'fuzzyText',
        },
        {
            Header: 'Destination',
            accessor: 'destination',
            filter: 'fuzzyText',
            width: 300,
        },
    ] as Column<AlertData>[];

export const configuredAlerts: AlertData[] = [
    {
        name: 'Actors that have had multiple failures followed by a success in the last 10 minutes',
        status: 'Ok',
        query: 'sum(last_10m):count_over_time(target.failure{actor}[10m]) > 1 and avg(last_10m):target.success{actor} > 0',
        message: '{actor} has had multiple failures followed by a success in the last 10 minutes',
        warn: 0,
        alert: 1,
        muted: false,
        mutedUntil: '',
        destination: [availableRecipients[0], availableRecipients[3], availableRecipients[2]],
    },
    {
        name: 'Actors that have accessed from a non-directory location in the last 24 hours',
        status: 'Ok',
        query: 'avg(last_24h):count_over_time(target.location{actor,not_directory}) > 0',
        message: '{actor} has accessed from a non-directory location in the last 24 hours',
        warn: 5,
        alert: 10,
        muted: false,
        mutedUntil: '',
        destination: [availableRecipients[4], availableRecipients[5]],
    },
    {
        name: 'Actors with over 95% failure in the last hour',
        status: 'Ok',
        query: 'avg(last_1h):sum:target.failure{actor} / sum:target.*{actor} > 0.95',
        message: '{actor} has had over 95% failure rate in the last hour',
        warn: 2,
        alert: 3,
        muted: true,
        mutedUntil: 'Next Friday',
        destination: [availableRecipients[1], availableRecipients[6], availableRecipients[7]],
    },
    {
        name: 'Actors that have one or more rejected MFA attempts in the last hour',
        status: 'Ok',
        query: 'avg(last_1h):count_over_time(target.mfa_rejected{actor}) > 0',
        message: '{actor} has had one or more rejected MFA attempts in the last hour',
        warn: 2,
        alert: 3,
        muted: true,
        mutedUntil: 'Indefinitely',
        destination: [availableRecipients[1], availableRecipients[2]],
    },
];

export const AlertsConfigMock = (): JSX.Element => {
    const [selectedAlert, setSelectedAlert] = useState<AlertData | undefined>();
    const [toggleFilters, setToggleFilters] = useState(false);

    const [selectedDestinations, setSelectedDestinations] = useState<Destination[] | undefined>();

    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            fuzzyNodeDisplayName: fuzzyNodeDisplayNameFilterFn,
            greaterThan: filterGreaterThan,
        }),
        [],
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            minWidth: 100,
            width: 200,
            maxWidth: 800,
        }),
        [],
    );

    const columns: Column<AlertData>[] = useMemo(AlertConfigColumns, []);

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable<AlertData>(
        {
            columns,
            data: configuredAlerts,
            defaultColumn,
            filterTypes,
            disableMultiSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: 40,
                sortBy: [{ id: 'name' }],
            },
        },
        useFilters,
        useSortBy,
        usePagination,
        useFilters,
        useBlockLayout,
        useResizeColumns,
    );

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <p className="uppercase tracking-wider font-bold text-xs text-gray-400">
                    <button
                        onClick={() => setSelectedAlert(undefined)}
                        className="uppercase tracking-wider font-bold text-xs text-gray-400"
                    >
                        Alert Definitions{' '}
                    </button>
                    {selectedAlert && (
                        <>
                            <span className="ml-1">{'>'}</span>
                            <span className="ml-1">Edit Alert</span>
                        </>
                    )}
                </p>
                <div className="flex items-center space-x-2 text-xs">
                    <button
                        type="button"
                        className="btn rounded-md px-3 py-2 focus-none"
                        onClick={() => setToggleFilters(!toggleFilters)}
                    >
                        <FunnelIcon className="h-4 w-4" />
                    </button>
                    <button
                        type="button"
                        className="btn rounded-md px-3 py-2 focus-none"
                        onClick={() => {
                            if (!selectedAlert) {
                                setSelectedAlert({
                                    name: '',
                                    status: 'Ok',
                                    query: '',
                                    message: '',
                                    warn: 0,
                                    alert: 0,
                                    muted: false,
                                    mutedUntil: '',
                                    destination: [],
                                });
                            }
                        }}
                    >
                        {selectedAlert ? 'Delete Alert' : 'Create Alert +'}
                    </button>
                    <Menu as="div" className="relative inline-block text-left ml-2 z-10">
                        <div>
                            <Menu.Button className="inline-flex justify-center w-full rounded-md px-3 py-2 bg-blue-700 text-xs font-medium text-white hover:bg-blue-800 focus:outline-none">
                                Actions
                                <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <CheckCircleIcon
                                                    className="mr-3 h-5 w-5 text-green-400"
                                                    aria-hidden="true"
                                                />
                                                Publish
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <PauseIcon
                                                    className="mr-3 h-5 w-5 text-yellow-400"
                                                    aria-hidden="true"
                                                />
                                                Pause
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <NoSymbolIcon
                                                    className="mr-3 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                />
                                                Disable
                                            </a>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
            {selectedAlert ? (
                <div className="mt-6 ml-4">
                    <form>
                        <div className="">
                            <h4 className="font-bold text-xs mb-2">Alert Details</h4>
                            <div className="space-y-2">
                                <div>
                                    <label className="text-xs">
                                        <span className="w-40 text-right pr-3 pt-2 text-gray-400">Alert Name</span>
                                        <input
                                            type="text"
                                            required
                                            className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1"
                                            placeholder="Enter Alert Name"
                                            value={selectedAlert.name}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="text-xs">
                                        <span className="w-40 text-right pr-3 pt-2 text-gray-400">Query</span>
                                        <textarea
                                            required
                                            className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1 font-mono text-xxs"
                                            placeholder="Enter Query"
                                            rows={5}
                                            value={selectedAlert.query}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="text-xs">
                                        <span className="w-40 text-right pr-3 pt-2 text-gray-400">Alert Message</span>
                                        <textarea
                                            required
                                            rows={5}
                                            className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1 font-mono text-xxs"
                                            placeholder="Enter Alert Message"
                                            value={selectedAlert.message}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6">
                            <h4 className="font-bold text-xs">Select Thresholds</h4>
                            <div className="space-y-2 w-80">
                                <label className="text-xs flex items-center justify-between">
                                    <span className="pr-3 text-gray-400">
                                        <span className="inline-block w-2 h-2 bg-red-500 mr-1" />
                                        Alert threshold
                                    </span>
                                    <input
                                        type="text"
                                        required
                                        className="input-gray text-xs w-20"
                                        placeholder="0"
                                        value={selectedAlert.alert}
                                    />
                                </label>
                                <label className="text-xs flex items-center justify-between">
                                    <span className="pr-3 text-gray-400">
                                        <span className="inline-block w-2 h-2 bg-yellow-500 mr-1" />
                                        Warning threshold
                                    </span>
                                    <input
                                        type="text"
                                        required
                                        className="input-gray text-xs w-20"
                                        placeholder="0"
                                        value={selectedAlert.warn}
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="mt-6">
                            <h4 className="font-bold text-xs">Select Destinations</h4>
                            <Select
                                isMulti
                                className="w-80 mt-2"
                                classNamePrefix="select-xs"
                                options={availableRecipients}
                                value={selectedDestinations || selectedAlert.destination}
                                onChange={(newValue) => setSelectedDestinations([...newValue])}
                            />
                        </div>

                        <div className="mt-6 flex space-x-2">
                            <button type="submit" className="text-xs btn btn-primary rounded-md">
                                Save Alert
                            </button>
                            <button
                                type="submit"
                                className="text-xs btn  rounded-md"
                                onClick={() => setSelectedAlert(undefined)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="overflow-scroll flex-1 h-[calc(100vh-208px)] mt-6">
                    <table {...getTableProps()} className="w-full text-left text-xs mb-4">
                        <thead className="sticky top-0 bg-gray-700">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()} className="pt-2 px-2 align-middle bg-gray-700">
                                            <div className="flex items-center mb-2">
                                                <span>{column.render('Header')}</span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ChevronDownIcon
                                                            className="h-5 w-5"
                                                            {...column.getSortByToggleProps()}
                                                        />
                                                    ) : (
                                                        <ChevronUpIcon
                                                            className="h-5 w-5"
                                                            {...column.getSortByToggleProps()}
                                                        />
                                                    )
                                                ) : (
                                                    <ChevronUpDownIcon
                                                        className="h-5 w-5"
                                                        {...column.getSortByToggleProps()}
                                                    />
                                                )}
                                                <div
                                                    {...column.getResizerProps()}
                                                    className={column.isResizing ? 'resizerIsResizing' : 'resizer'}
                                                />
                                            </div>
                                            {toggleFilters && (
                                                <div className="pl-1">
                                                    {column.canFilter ? column.render('Filter') : null}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="overflow-y-hidden pb-[40px] bg-gray-600">
                            {configuredAlerts.length > 0 &&
                                page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className="text-gray-300 hover:bg-gray-900 py-1"
                                            onClick={() => {
                                                setSelectedAlert(row.original);
                                            }}
                                        >
                                            {row.cells.map((cell, idx) => {
                                                switch (cell.column.id) {
                                                    case 'actions':
                                                        return (
                                                            <td {...cell.getCellProps()} className="px-2 ">
                                                                <div className="flex space-x-2 items-center ">
                                                                    <button className="btn py-1 px-2">Mute</button>
                                                                    <button className="btn py-1 px-2">Resolve</button>
                                                                </div>
                                                            </td>
                                                        );
                                                    case 'status':
                                                        return (
                                                            <td {...cell.getCellProps()} className="px-2 ">
                                                                <div className="flex space-x-2 items-center uppercase text-xxs">
                                                                    {cell.value === 'Ok' && (
                                                                        <span className="bg-green-600 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                            Ok
                                                                        </span>
                                                                    )}
                                                                    {cell.value === 'Warning' && (
                                                                        <span className="bg-yellow-500 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                            Warning
                                                                        </span>
                                                                    )}
                                                                    {cell.value === 'Alert' && (
                                                                        <span className="bg-red-600 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                            Alert
                                                                        </span>
                                                                    )}
                                                                    {cell.value === 'No Data' && (
                                                                        <span className="bg-gray-700 text-white py-1 px-2 rounded-md w-20 text-center">
                                                                            No Data
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        );
                                                    case 'muted':
                                                        return (
                                                            <td {...cell.getCellProps()} className="px-2 ">
                                                                <div className="flex space-x-2 items-center ">
                                                                    {cell.value === true ? (
                                                                        <span className=" py-1 px-2">
                                                                            <BellSlashIcon className="h-4 w-4 text-white" />
                                                                        </span>
                                                                    ) : (
                                                                        <span className="py-1 px-2">
                                                                            <BellIcon className="h-4 w-4 text-gray-700 hover:text-gray-200" />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        );
                                                    case 'destination':
                                                        return (
                                                            <td {...cell.getCellProps()} className="px-2 py-1.5">
                                                                <div className="flex space-x-2 items-center ">
                                                                    {cell.value
                                                                        .map((d: Destination) => d.label)
                                                                        .join(', ')}
                                                                </div>
                                                            </td>
                                                        );
                                                    default:
                                                        return <DefaultCell cell={cell} key={idx} />;
                                                }
                                            })}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
