import { ToastProvider } from 'Map/Components/ToastProvider';
import { TourProvider } from 'Map/Components/TourProvider';
import { MonitoringAndFlags } from './MonitoringAndFlags';
import { TenantStatus } from './TenantStatus';
import { UserPermissions } from './UserPermissions';
import { ConfigProvider as AvatarProvider } from 'react-avatar';

type UtilitiesAndProvidersProps = {
    children: JSX.Element;
};

// Avatar colors
const avatarColors = ['#e11d48', '#d97706', '#059669', '#0284c7', '#7c3aed', '#c026d3'];

const UtilitiesAndProviders = ({ children }: UtilitiesAndProvidersProps) => {
    return (
        <MonitoringAndFlags>
            <UserPermissions>
                <TenantStatus>
                    <TourProvider>
                        <ToastProvider>
                            {/* @ts-expect-error : ConfigProvider has no children property in interface declaration */}
                            <AvatarProvider colors={avatarColors}>{children}</AvatarProvider>
                        </ToastProvider>
                    </TourProvider>
                </TenantStatus>
            </UserPermissions>
        </MonitoringAndFlags>
    );
};

export default UtilitiesAndProviders;
