import { Fragment, useState } from 'react';
import {
    BellIcon,
    CheckIcon,
    InformationCircleIcon,
    ShieldExclamationIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid';
import { Popover, Transition } from '@headlessui/react';
import { classNames } from 'Utilities/utils';
import { Tooltip } from 'Library/Tooltip';

const timeline = [
    {
        id: 1,
        content: 'Elevated levels of non-directory location access observed',
        href: '#',
        date: 'just now',
        datetime: '2022-02-02',
        icon: <ShieldExclamationIcon />,
        iconBackground: 'bg-yellow-500',
        unread: true,
        isHovered: false,
    },
    {
        id: 3,
        content: 'A new Access Location report is ready to download.',
        target: '',
        href: '#',
        date: '3 days ago',
        datetime: '2022-01-20',
        icon: <InformationCircleIcon />,
        iconBackground: 'bg-gray-500',
        unread: false,
        isHovered: false,
    },
    {
        id: 4,
        content: 'Provider Okta has been created.',
        target: '',
        href: '#',
        date: 'Jan 20, 2024',
        datetime: '2024-01-20',
        icon: <CheckIcon />,
        iconBackground: 'bg-green-500',
        unread: false,
        isHovered: false,
    },
];

interface NotificationItemProps {
    id: number;
    content: string;
    target: string | undefined;
    href: string;
    date: string;
    datetime: string;
    icon: JSX.Element;
    iconBackground: string;
    unread: boolean;
}

interface NotificationProps {
    open: boolean;
}

export const Notifications = (): JSX.Element => (
    <Popover className="relative">
        {({ open }: NotificationProps) => (
            <>
                <Tooltip label="Notifications">
                    <Popover.Button
                        className={classNames(
                            open ? 'bg-gray-800' : '',
                            'inline-flex rounded-full items-center justify-center btn-gray p-2 bg-gray-900 hover:bg-gray-800 relative',
                        )}
                    >
                        <p className="text-xs">
                            <BellIcon className="h-5 w-5" aria-hidden="true" />
                        </p>
                        <div className="absolute h-4 w-4 -top-0.5 -right-0.5 rounded-full bg-red-500 text-white text-xs font-semibold flex place-content-center">
                            1
                        </div>
                    </Popover.Button>
                </Tooltip>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="bg-gray-800 border border-gray-600 shadow-lg rounded-md w-128 absolute z-10 -right-0 mt-3">
                        <div className="flow-root">
                            <ul role="list" className="">
                                {timeline.map((event) => (
                                    <NotificationItem
                                        key={event.id}
                                        id={event.id}
                                        content={event.content}
                                        target={event.target}
                                        href={event.href}
                                        date={event.date}
                                        datetime={event.datetime}
                                        icon={event.icon}
                                        iconBackground={event.iconBackground}
                                        unread={event.unread}
                                    />
                                ))}
                                <li className="flex justify-between">
                                    <button
                                        type="button"
                                        className="block p-3 text-gray-400 hover:text-blue-600 text-xs"
                                    >
                                        Mark all as read
                                    </button>
                                    <button
                                        type="button"
                                        className="block p-3 text-gray-400 hover:text-blue-600 text-xs"
                                    >
                                        Clear all
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
        )}
    </Popover>
);

export const NotificationItem = ({
    content,
    target,
    href,
    date,
    datetime,
    icon,
    iconBackground,
    unread,
}: NotificationItemProps): JSX.Element => {
    const [isHovered, setIsHovered] = useState(false);
    const [show, setShow] = useState(true);
    return (
        <>
            {show && (
                <li
                    className="border-b border-gray-600 p-3"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <div className="relative">
                        <div className="relative flex items-start space-x-3">
                            <div className="mt-1">
                                <span
                                    className={classNames(
                                        iconBackground,
                                        'h-6 w-6 rounded-full flex items-center justify-center',
                                    )}
                                >
                                    <span className="h-4 w-4 text-white" aria-hidden="true">
                                        {icon}
                                    </span>
                                </span>
                            </div>
                            <div className="min-w-0 flex-1">
                                <p className="text-xs text-gray-400">
                                    {content}{' '}
                                    <a href={href} className="font-medium text-white">
                                        {target}
                                    </a>
                                </p>
                                <p className="text-left text-xs text-gray-500">
                                    <time dateTime={datetime}>{date}</time>
                                </p>
                            </div>
                            <div className="relative">
                                {unread && <div className="rounded-full h-2 w-2 bg-blue-500" />}
                                {isHovered && (
                                    <button
                                        type="button"
                                        onClick={() => setShow(false)}
                                        className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 shadow-md absolute -top-1.5 -right-1.5 z-10"
                                    >
                                        <XMarkIcon className="h-3.5 w-3.5 text-gray-200" aria-hidden="true" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </li>
            )}
        </>
    );
};
