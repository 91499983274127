import { WorldMapChart } from './WorldMapChart';

export const WorldMap = ({ startDate, endDate }: { startDate: number; endDate: number }): JSX.Element => {
    return (
        <div>
            <div className="flex w-full">
                <div className="h-[560px] w-full relative overflow-hidden">
                    <WorldMapChart startDate={startDate} endDate={endDate} height="560px" />
                </div>
            </div>
        </div>
    );
};
