import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useKeycloak } from '@react-keycloak/web';
import { getAuthProvider } from 'Hooks/Auth';
import ExternalPage from 'Onboarding/ExternalPage';
import { FunctionComponent, useEffect } from 'react';
import { Route } from 'react-router-dom';
import logo from 'assets/logo-192x192.png';

type ProtectedRouteProps = {
    component: FunctionComponent;
    [key: string]: unknown;
};

const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
    const provider = getAuthProvider();

    switch (provider) {
        case 'keycloak':
            return <KeyCloakProtectedRoute {...props} />;
        case 'auth0':
        default:
            return <Auth0ProtectedRoute {...props} />;
    }
};

export default ProtectedRoute;

const Auth0ProtectedRoute = (props: ProtectedRouteProps) => {
    const { component, ...args } = props;
    return <Route component={withAuthenticationRequired(component)} {...args} />;
};

const KeyCloakProtectedRoute = (props: ProtectedRouteProps) => {
    const { component, ...args } = props;
    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        if (!keycloak.authenticated) {
            if (initialized) {
                keycloak.login();
            }
        }
    }, [initialized, keycloak]);

    if (initialized && keycloak.authenticated) {
        return <Route component={component} {...args} />;
    } else if (initialized && !keycloak.authenticated) {
        return <Loading />;
    }

    return <AuthError />;
};

const AuthError = (): JSX.Element => {
    return (
        <ExternalPage>
            <img className="-mt-16 h-12 w-auto" src={logo} alt="SailPoint Identity Risk Icon" />

            <h2 className="text-3xl font-extrabold text-gray-200">Authentication Issue</h2>
            <p className="text-sm text-gray-400 mt-2">Unfortunately you may not access SailPoint Identity Risk</p>
        </ExternalPage>
    );
};
const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs" data-testid="auth-spinner">
                Authenticating ...
            </p>
        </div>
    </div>
);
