import { List } from '../Library/List';

import { DashboardTabProps } from './DashboardModalTypes';
import { Stat } from 'Library/Stat';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
} from 'recharts';
import { generateDataSeries } from 'Map/Graph/Data';
import { subHours } from 'date-fns';
import { ChartLegend } from 'Utilities/ChartUtilities';
import { formatNumber, getDisplayName, getEntityTypeFromLabel } from 'Utilities/utils';
import {
    GET_ENTITY_BY_TRUST,
    GET_GLOBAL_TRUST_STATISTICS,
    GET_INVENTORY_STATISTICS,
    GET_TRUST_PROFILE_HISTORY,
    LIST_POLICY_PROFILES,
} from 'Graph/typedQueries';
import { useQuery } from '@apollo/client';
import { InventorySubtype, StatsEntityType, StatsOrder, StatsUnits } from 'GeneratedGQL/graphql';
import { useNodes, useTenant } from 'Hooks/Hooks';
import { TheWallInsights } from './Wall/TheWallInsights';
import { WorldMapChart } from './Charts/WorldMapChart';
import { useGraphControls } from 'Hooks/GraphHooks';
import { ContextMenu } from 'Map/Components/ContextMenu';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { Tooltip as HoverTooltip } from 'Library/Tooltip';
import { DocumentMagnifyingGlassIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UnitInterval } from 'Types/types';
import { useUserPermissions } from 'Utilities/UserPermissions';

export const Trust = ({
    open,
    selectedStartDate,
    selectedEndDate,
    startDate,
    endDate,
    interval,
}: DashboardTabProps) => {
    const tenantId = useTenant();

    const { userCan } = useUserPermissions();

    const canGetTrust = userCan('list', '/policy/profile');

    // Inventory statistics device
    const {
        data: inventoryStatisticsDevice,
        loading: loadingInventoryStatisticsDevice,
        error: errorInventoryStatisticsDevice,
    } = useQuery(GET_INVENTORY_STATISTICS, {
        variables: { tenantId: tenantId || '', entity: StatsEntityType.StatsEntityTypeDevice },
        skip: !tenantId || !canGetTrust,
    });
    // Inventory statistics actor
    const {
        data: inventoryStatisticsActor,
        loading: loadingInventoryStatisticsActor,
        error: errorInventoryStatisticsActor,
    } = useQuery(GET_INVENTORY_STATISTICS, {
        variables: { tenantId: tenantId || '', entity: StatsEntityType.StatsEntityTypeActor },
        skip: !tenantId || !canGetTrust,
    });
    // Inventory statistics target
    const {
        data: inventoryStatisticsTarget,
        loading: loadingInventoryStatisticsTarget,
        error: errorInventoryStatisticsTarget,
    } = useQuery(GET_INVENTORY_STATISTICS, {
        variables: { tenantId: tenantId || '', entity: StatsEntityType.StatsEntityTypeTarget },
        skip: !tenantId || !canGetTrust,
    });

    // Global trust statistics
    const { data: globalTrustStatisticsData, loading: loadingGlobalTrustStatistics } = useQuery(
        GET_GLOBAL_TRUST_STATISTICS,
        {
            variables: { tenantId: tenantId || '', entity: StatsEntityType.StatsEntityTypeActor },
            skip: !tenantId || !canGetTrust,
        },
    );
    const { getGlobalTrustStatistics } = globalTrustStatisticsData || { getGlobalTrustStatistics: null };

    // Get Actors By Trust
    const {
        data: actorsByTrustData,
        loading: loadingActorsByTrust,
        error: errorActorsByTrust,
    } = useQuery(GET_ENTITY_BY_TRUST, {
        variables: {
            tenantId: tenantId || '',
            entity: StatsEntityType.StatsEntityTypeActor,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            limit: 6,
            order: StatsOrder.StatsOrderAsc,
        },
        skip: !tenantId || !canGetTrust,
    });

    // Get Session By Trust
    const {
        data: sessionByTrustData,
        loading: loadingSessionsByTrust,
        error: errorSessionsByTrust,
    } = useQuery(GET_ENTITY_BY_TRUST, {
        variables: {
            tenantId: tenantId || '',
            entity: StatsEntityType.StatsEntityTypeSession,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            limit: 6,
            order: StatsOrder.StatsOrderAsc,
        },
        skip: !tenantId || !canGetTrust,
    });

    // Get Locations By Trust
    const {
        data: locationsByTrustData,
        loading: loadingLocationsByTrust,
        error: errorLocationsByTrust,
    } = useQuery(GET_ENTITY_BY_TRUST, {
        variables: {
            tenantId: tenantId || '',
            entity: StatsEntityType.StatsEntityTypeLocation,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            limit: 6,
            order: StatsOrder.StatsOrderAsc,
        },
        skip: !tenantId || !canGetTrust,
    });

    if (!open) return <> </>;

    if (!canGetTrust) {
        return (
            <div className="text-center text-gray-300 pt-2 flex flex-col items-center justify-center h-40">
                <DocumentMagnifyingGlassIcon className="h-8 w-8 text-gray-400 mb-3" />
                You do not have permission to view Trust Insights
            </div>
        );
    }

    const totalActors = inventoryStatisticsActor?.getInventoryStatistics?.items?.find(
        (item) => item?.subType === InventorySubtype.InventorySubtypeTotal,
    )?.count;

    const internalActors = inventoryStatisticsActor?.getInventoryStatistics?.items?.find(
        (item) => item?.subType === InventorySubtype.InventorySubtypeInternal,
    )?.count;

    const externalActors = inventoryStatisticsActor?.getInventoryStatistics?.items?.find(
        (item) => item?.subType === InventorySubtype.InventorySubtypeExternal,
    )?.count;

    const machineActors = inventoryStatisticsActor?.getInventoryStatistics?.items?.find(
        (item) => item?.subType === InventorySubtype.InventorySubtypeMachine,
    )?.count;

    const devices = inventoryStatisticsDevice?.getInventoryStatistics?.items?.find(
        (item) => item?.subType === InventorySubtype.InventorySubtypeTotal,
    )?.count;

    const managedDevices = inventoryStatisticsDevice?.getInventoryStatistics?.items?.find(
        (item) => item?.subType === InventorySubtype.InventorySubtypeManaged,
    )?.count;

    const targets = inventoryStatisticsTarget?.getInventoryStatistics?.items?.find(
        (item) => item?.subType === InventorySubtype.InventorySubtypeTotal,
    )?.count;

    const discoveredTargets = inventoryStatisticsTarget?.getInventoryStatistics?.items?.find(
        (item) => item?.subType === InventorySubtype.InventorySubtypeDiscovered,
    )?.count;

    const globalTrustScore = getGlobalTrustStatistics?.globalTrustScore;

    const trustScorePieData = [
        {
            name: 'High',
            value: getGlobalTrustStatistics?.buckets.find((bucket) => bucket.name === 'High')?.count,
            color: '#29AB65',
        },
        {
            name: 'Medium',
            value: getGlobalTrustStatistics?.buckets.find((bucket) => bucket.name === 'Medium')?.count,
            color: '#F5CD19',
        },
        {
            name: 'Low',
            value: getGlobalTrustStatistics?.buckets.find((bucket) => bucket.name === 'Low')?.count,
            color: '#B64C45',
        },
    ];

    const hasTrustScorePieData = trustScorePieData.some((item) => item.value !== 0);

    return (
        <div>
            <div>
                <div className="w-full grid lg:grid-cols-8 grid-cols-4 gap-4 mb-4">
                    <div className="col-span-4 justify-around bg-gray-700 rounded-lg flex p-2">
                        {loadingInventoryStatisticsActor ? (
                            <StatLoading />
                        ) : errorInventoryStatisticsActor ? (
                            <StatError />
                        ) : (
                            <>
                                <Stat
                                    title="Total Actors"
                                    value={totalActors || 0}
                                    dotColor="rgba(59, 130, 246, 1)" // Blue
                                    className="w-1/2 md:w-auto flex flex-col items-center p-2"
                                />
                                <Stat
                                    title="Internal Actors"
                                    value={internalActors || 0}
                                    dotColor="rgba(249, 115, 22, 1)" // Orange
                                    className="w-1/2 md:w-auto flex flex-col items-center p-2"
                                />
                                <Stat
                                    title="External Actors"
                                    value={externalActors || 0}
                                    dotColor="rgba(74, 222, 128, 1)" // Green
                                    className="w-1/2 md:w-auto flex flex-col items-center p-2"
                                />
                                <Stat
                                    title="Machine Actors"
                                    value={machineActors || 0}
                                    dotColor="rgba(219, 39, 119, 1)" // Pink
                                    className="w-1/2 md:w-auto flex flex-col items-center p-2"
                                />
                            </>
                        )}
                    </div>
                    <div className="col-span-2 justify-around bg-gray-700 rounded-lg flex p-2">
                        {loadingInventoryStatisticsDevice ? (
                            <StatLoading />
                        ) : errorInventoryStatisticsDevice ? (
                            <StatError />
                        ) : (
                            <>
                                <Stat
                                    title="Devices"
                                    value={devices || 0}
                                    dotColor="rgba(139, 92, 246, 1)" // Purple
                                    className="w-1/2 md:w-auto flex flex-col items-center p-2"
                                />
                                <Stat
                                    title="Managed Devices"
                                    value={managedDevices || 0}
                                    dotColor="rgba(252, 211, 77, 1)" // Yellow
                                    className="w-1/2 md:w-auto flex flex-col items-center p-2"
                                />
                            </>
                        )}
                    </div>
                    <div className="col-span-2 justify-around bg-gray-700 rounded-lg flex p-2">
                        {loadingInventoryStatisticsTarget ? (
                            <StatLoading />
                        ) : errorInventoryStatisticsTarget ? (
                            <StatError />
                        ) : (
                            <>
                                <Stat
                                    title="Targets"
                                    value={targets || 0}
                                    dotColor="rgba(96, 165, 250, 1)" // Light blue
                                    className="w-1/2 md:w-auto flex flex-col items-center p-2"
                                />
                                <Stat
                                    title="Discovered Targets"
                                    value={discoveredTargets || 0}
                                    dotColor="rgba(248, 113, 113, 1)" // Red
                                    className="w-1/2 md:w-auto flex flex-col items-center p-2"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="grid xl:grid-cols-6 grid-cols-2 gap-4">
                <div className="bg-gray-700 rounded-lg">
                    <List title="Trust Score" loading={loadingGlobalTrustStatistics}>
                        {globalTrustStatisticsData && (
                            <div className="w-full relative">
                                <GlobalTrustScore score={Math.round(globalTrustScore || 0)} />
                                <div className="absolute bottom-2 w-full flex justify-center items-center">
                                    <div
                                        style={{ textShadow: '1px 1px 2px #111827' }}
                                        className="text-6xl font-medium pl-2 relative text-gray-100"
                                    >
                                        {globalTrustScore == -1 ? 'N/A' : Math.round(globalTrustScore || 0)}
                                        <span className="absolute top-[calc(30%)] text-xs text-gray-500 hover:text-gray-300">
                                            <HoverTooltip
                                                label={
                                                    'The global trust score is derived from the average trust score across all accesses in the selected time window'
                                                }
                                                placement="right"
                                            >
                                                <InformationCircleIcon className="w-6 h-6 ml-2" />
                                            </HoverTooltip>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </List>
                </div>
                <div className="bg-gray-700 rounded-lg">
                    <List title="Actor Trust Breakdown" loading={loadingGlobalTrustStatistics}>
                        {globalTrustStatisticsData && hasTrustScorePieData && (
                            <div className="w-full pt-2.5 pb-6">
                                <TrustScorePie data={trustScorePieData} />
                            </div>
                        )}
                    </List>
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <List
                        title="Actors By Trust"
                        loading={loadingActorsByTrust}
                        error={Boolean(errorActorsByTrust)}
                        skipHeaderLine={true}
                        skipListPadding={true}
                    >
                        {actorsByTrustData &&
                            actorsByTrustData.getEntityByTrust &&
                            actorsByTrustData.getEntityByTrust?.items.length > 0 && (
                                <table className="w-full text-left border-collapse">
                                    <thead className="bg-gray-600 text-gray-100 rounded-t-lg">
                                        <tr>
                                            <th className="p-2">Name</th>
                                            <th className="p-2">Activity</th>
                                            <th className="p-2">Trust</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-200">
                                        {actorsByTrustData?.getEntityByTrust?.items?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="p-2 border-b border-gray-500">
                                                        {<LazyNodeCell nodeId={item?.entityId[0]} />}
                                                    </td>
                                                    {/* <td className="p-2 border-b border-gray-500">{item?.entityId[0]}</td> */}
                                                    <td className="p-2 border-b border-gray-500">
                                                        {item?.accessCount}
                                                    </td>
                                                    <td className="p-2 border-b border-gray-500">
                                                        {Math.round(item?.trustScore)}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                    </List>
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2 row-span-3 lg:-order-last order-last pb-2">
                    <TheWallInsights startDate={selectedStartDate} endDate={selectedEndDate} />
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <List title="Trust Profiles">
                        <></>
                    </List>
                    <TrustProfiles startDate={startDate} endDate={endDate} interval={interval} />
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <List
                        title="Sessions By Trust"
                        loading={loadingSessionsByTrust}
                        error={Boolean(errorSessionsByTrust)}
                        skipHeaderLine={true}
                        skipListPadding={true}
                    >
                        {sessionByTrustData?.getEntityByTrust &&
                            sessionByTrustData.getEntityByTrust.items.length > 0 && (
                                <div className="px-4">
                                    <table className="w-full text-left border-collapse">
                                        <thead className="bg-gray-600 text-gray-100">
                                            <tr>
                                                <th className="p-2">Actor</th>
                                                <th className="p-2">Device</th>
                                                <th className="p-2">Target</th>
                                                <th className="p-2">Trust</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-gray-200">
                                            {sessionByTrustData?.getEntityByTrust?.items?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="p-2 border-b border-gray-500">
                                                            {<LazyNodeCell nodeId={item?.entityId[0]} />}
                                                        </td>
                                                        <td className="p-2 border-b border-gray-500">
                                                            {<LazyNodeCell nodeId={item?.entityId[1]} />}
                                                        </td>
                                                        <td className="p-2 border-b border-gray-500">
                                                            {<LazyNodeCell nodeId={item?.entityId[2]} />}
                                                        </td>
                                                        <td className="p-2 border-b border-gray-500">
                                                            {Math.round(item?.trustScore)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                    </List>
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <List title="Activity By Location">
                        <div className="h-[270px] w-full relative overflow-hidden pt-2">
                            <WorldMapChart startDate={selectedStartDate} endDate={selectedEndDate} height="270px" />
                        </div>
                    </List>
                </div>
                <div className="bg-gray-700 rounded-lg col-span-2">
                    <List
                        title="Locations By Trust"
                        loading={loadingLocationsByTrust}
                        error={Boolean(errorLocationsByTrust)}
                        skipHeaderLine={true}
                        skipListPadding={true}
                    >
                        {locationsByTrustData?.getEntityByTrust &&
                            locationsByTrustData.getEntityByTrust.items.length > 0 && (
                                <div className="px-4">
                                    <table className="w-full text-left border-collapse">
                                        <thead className="bg-gray-600 text-gray-100 rounded-t-lg">
                                            <tr>
                                                <th className="p-2">Country</th>
                                                <th className="p-2">City</th>
                                                <th className="p-2">Activity</th>
                                                <th className="p-2">Trust</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-gray-200">
                                            {locationsByTrustData?.getEntityByTrust?.items?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="p-2 border-b border-gray-500">
                                                            {item.entityId[0]}
                                                        </td>
                                                        <td className="p-2 border-b border-gray-500">
                                                            {item.entityId[1]}
                                                        </td>
                                                        <td className="p-2 border-b border-gray-500">
                                                            {item?.accessCount}
                                                        </td>
                                                        <td className="p-2 border-b border-gray-500">
                                                            {Math.round(item?.trustScore)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                    </List>
                </div>
            </div>
        </div>
    );
};

// type NodeProperty = {
//     nodeId: string;
//     node: Node;
//     nodeProperty: keyof Node;
// };

// const NodeProperty = ({ nodeId, node, nodeProperty }: NodeProperty) => {
//     return <div>{node[nodeProperty]}</div>;
// };

type TrustScorePieData = {
    name: string;
    value: number;
    color: string;
}[];

const TrustScorePie = ({ data }: { data: TrustScorePieData }) => {
    return (
        <div className="flex justify-center items-center w-full h-52 pb-2 -mt-4 -ml-1">
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        data={data}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={3}
                        dataKey="value"
                        isAnimationActive={false}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} stroke="none" />
                        ))}
                    </Pie>
                    <Legend verticalAlign="bottom" content={<ChartLegend />} wrapperStyle={{ bottom: '-10px' }} />
                    <Tooltip
                        offset={25}
                        cursor={{ fill: 'rgba(255,255,255,0.15)', stroke: 'white', strokeWidth: 2 }}
                        contentStyle={{
                            background: 'rgb(31 41 55)',
                            borderRadius: '6px',
                            border: '1px solid rgb(107 114 128)',
                        }}
                        itemStyle={{ color: 'white' }}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

const colors = ['#8B909B', '#E697FF', '#007DC1', '#29AB65', '#FBE76A']; // Define unique colors for each profile

const now = new Date();

type TrustProfilesProps = {
    startDate: number;
    endDate: number;
    interval: UnitInterval;
};

const TrustProfiles = ({ startDate, endDate, interval }: TrustProfilesProps) => {
    const { useCalculatedTrustScore } = useFlags();

    // Recharts ine chart with
    // x axis: time
    // y axis: trust score per profile

    const tenantId = useTenant();

    const {
        data: dataTrustProfiles,
        loading: loadingDataTrustProfiles,
        error: errorDataTrustProfiles,
    } = useQuery(LIST_POLICY_PROFILES, { variables: { tenantId: tenantId || '' } });

    const {
        data: dataGetTrustProfileHistory,
        loading: loadingGetTrustProfileHistory,
        error: errorGetTrustProfileHistory,
    } = useQuery(GET_TRUST_PROFILE_HISTORY, {
        variables: {
            tenantId: tenantId || '',
            startDate,
            endDate,
            unit: interval as StatsUnits,
        },
    });

    const loading = loadingDataTrustProfiles || loadingGetTrustProfileHistory;
    const error = errorDataTrustProfiles || errorGetTrustProfileHistory;

    const series = useMemo(() => {
        // If we are not using calculated trust score, we can generate random data
        if (!useCalculatedTrustScore && dataTrustProfiles?.listPolicyProfiles) {
            const series = dataTrustProfiles?.listPolicyProfiles?.map((p) => {
                const data = generateDataSeries();
                return {
                    name: p.displayName,
                    data: data.map((d, index) => {
                        return { date: subHours(now, index).getTime(), value: d };
                    }),
                };
            });
            return series;
        }

        if (
            useCalculatedTrustScore &&
            dataGetTrustProfileHistory?.getTrustProfileHistory?.items &&
            dataTrustProfiles?.listPolicyProfiles
        ) {
            const profileHistories: Record<string, { name: string; data: { date: number; value: number }[] }> = {};

            dataTrustProfiles?.listPolicyProfiles?.map((p) => {
                profileHistories[p.profileId] = { name: p.displayName, data: [] };
            });

            dataGetTrustProfileHistory?.getTrustProfileHistory?.items.map(
                (p: { date: number; trustScore: Record<string, number> }) => {
                    if (p) {
                        const date = p.date;
                        const trustScores = p.trustScore;

                        Object.entries(trustScores).forEach(([profileId, trustScore]) => {
                            const roundedTrustScore = Math.round(trustScore);
                            const previousScore = profileHistories[profileId].data.slice(-1)[0]?.value || 0;

                            const score =
                                roundedTrustScore > 0 ? roundedTrustScore : previousScore > 0 ? previousScore : 0;

                            profileHistories[profileId].data.push({ date, value: score });
                        });
                    }
                },
            );

            return Object.values(profileHistories);
        }

        return [];
    }, [
        dataGetTrustProfileHistory?.getTrustProfileHistory?.items,
        dataTrustProfiles?.listPolicyProfiles,
        useCalculatedTrustScore,
    ]);

    if (loading) {
        return (
            <ul className="text-center text-gray-300 pt-2 flex flex-col items-center justify-center h-40">
                <div className="h-8 w-8 loader mb-3" />
                Loading...
            </ul>
        );
    }

    if (error) {
        return (
            <ul className="text-center text-red-400 pt-2 flex flex-col items-center justify-center h-40">
                <NoSymbolIcon className="h-8 w-8 text-red-400 mb-3" />
                Error loading results
            </ul>
        );
    }

    if (!series || series.length === 0) {
        return (
            <ul className="text-center text-gray-300 pt-2 flex flex-col items-center justify-center h-40">
                <DocumentMagnifyingGlassIcon className="h-8 w-8 text-gray-400 mb-3" />
                No matching records
            </ul>
        );
    }

    return (
        <div className="flex justify-center items-center pb-8 pr-8 ">
            {series && (
                <ResponsiveContainer width="100%" height={230}>
                    <LineChart height={230}>
                        <XAxis
                            dataKey="date"
                            type="number"
                            domain={['dataMin', 'dataMax']}
                            tickFormatter={(time) => new Date(time).toLocaleTimeString()}
                            tick={false}
                            axisLine={false}
                        />

                        <CartesianGrid stroke="rgba(0,0,0,0.15)" />

                        <YAxis
                            stroke="rgba(0,0,0,0.15)"
                            style={{
                                fontSize: '11px',
                            }}
                            tick={{ fill: 'rgba(255,255,255, 0.5)' }}
                            tickFormatter={formatNumber}
                        />

                        <Tooltip
                            labelFormatter={(label) => new Date(label).toLocaleTimeString()}
                            offset={25}
                            cursor={{ fill: 'rgba(255,255,255,0.15)', stroke: 'white', strokeWidth: 2 }}
                            contentStyle={{
                                background: 'rgb(31 41 55)',
                                borderRadius: '6px',
                                border: '1px solid rgb(107 114 128)',
                            }}
                        />
                        {series.length > 0 &&
                            series.map((s, index) => (
                                <Line
                                    dot={false}
                                    type="monotone"
                                    dataKey="value"
                                    data={s.data}
                                    name={s.name}
                                    key={s.name}
                                    stroke={colors[index % colors.length]}
                                    strokeWidth={1.5}
                                    isAnimationActive={false}
                                />
                            ))}
                        <Legend verticalAlign="bottom" height={8} content={<ChartLegend />} />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

const RADIAN = Math.PI / 180;
const piedata = [
    { name: 'A', value: 33, color: '#B54C45' },
    { name: 'B', value: 33, color: '#EBAB07' },
    { name: 'C', value: 33, color: '#29AB65' },
];
const iR = 60;
const oR = 80;

const needle = (value: number, cx: number, cy: number, iR: number, oR: number, color: string) => {
    const ang = 180.0 * (1 - value / 100);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle key="circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
        <path key="path" d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="none" fill={color} />,
    ];
};

const GlobalTrustScore = ({ score = 0 }: { score?: number }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [center, setCenter] = useState({ cx: 0, cy: 0 });

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (entries.length === 0 || !entries[0].target) return;
            const { width, height } = entries[0].target.getBoundingClientRect();
            setCenter({ cx: width / 2, cy: height / 2 });
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div className="flex justify-center items-center w-full h-56 -mt-4" ref={containerRef}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        isAnimationActive={false}
                        dataKey="value"
                        startAngle={180}
                        endAngle={0}
                        data={piedata}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        stroke="none"
                    >
                        {piedata.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    {needle(score, center.cx, center.cy, iR, oR, '#ffffff')}
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

type TriggerEventWithPosition = TriggerEvent & { clientX: number; clientY: number };

const LazyNodeCell = ({ nodeId }: { nodeId: string }) => {
    const { isNodeInExplorer, isNodeInQuery } = useGraphControls();
    const { show } = useContextMenu({});
    const [isContextMenuMounted, setIsContextMenuMounted] = useState(false);
    const [contextMenuEvent, setContextMenuEvent] = useState<TriggerEventWithPosition>();

    const nodeLabel = nodeId.split('/')[1];
    const nodeType = getEntityTypeFromLabel(nodeLabel);

    const nodeIds = useMemo(() => [nodeId], [nodeId]);

    const { loading, error, nodes } = useNodes(nodeIds, nodeType);

    const node = Object.values(nodes)[0];

    const mountContextMenu = (e: TriggerEventWithPosition) => {
        e.stopPropagation();
        e.preventDefault();
        setContextMenuEvent(e);
        setIsContextMenuMounted(true);
    };

    useEffect(() => {
        if (isContextMenuMounted && contextMenuEvent) {
            show(contextMenuEvent, {
                id: `${nodeId}`,
                position: {
                    x: contextMenuEvent?.clientX,
                    y: contextMenuEvent?.clientY,
                },
            });
            setContextMenuEvent(undefined);
        }
    }, [contextMenuEvent, isContextMenuMounted, nodeId, show]);

    if (loading || !node) {
        return <div>...</div>;
    }
    if (error) {
        return <div>Error retrieving details for node: {nodeId}</div>;
    }

    return (
        <div>
            {isContextMenuMounted && <ContextMenu id={`${node.id}`} node={node} />}
            <div className="flex items-center justify-start relative w-full h-full">
                <div className="truncate">
                    {isNodeInExplorer(node) ? (
                        isNodeInQuery(node) ? (
                            <HoverTooltip label="Node is in explorer and search is toggled on" placement="top">
                                <span className="w-4 h-4 absolute left-0 top-0 bottom-0 my-auto mx-0">
                                    <span className="w-1 h-1 top-0 bottom-0 my-auto left-0 bg-blue-700 absolute rounded-full"></span>
                                </span>
                            </HoverTooltip>
                        ) : (
                            <HoverTooltip label="Node is in explorer" placement="top">
                                <span className="w-4 h-4 absolute left-0 top-0 bottom-0 my-auto mx-0">
                                    <span className="w-1 h-1 top-0 bottom-0 my-auto left-0 bg-gray-500 absolute rounded-full"></span>
                                </span>
                            </HoverTooltip>
                        )
                    ) : null}
                    <span
                        id={String(node.id)}
                        onContextMenu={mountContextMenu}
                        onClick={mountContextMenu}
                        className="hover:underline underline-offset-2 cursor-pointer ml-3"
                    >
                        {getDisplayName(node)}
                    </span>
                </div>
            </div>
        </div>
    );
};

const StatLoading = () => {
    return (
        <ul className="text-center text-gray-300 flex flex-col items-center justify-center">
            <div className="h-6 w-6 loader mb-2" />
            Loading...
        </ul>
    );
};
const StatError = () => {
    return (
        <ul className="text-center text-red-400 pt-2 flex flex-col items-center justify-center">
            <NoSymbolIcon className="h-8 w-8 text-red-400 mb-3" />
            Error loading results
        </ul>
    );
};
