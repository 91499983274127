import { useEffect } from 'react';

export const NoAuthProvider = ({ provider }: { provider: string | undefined }) => {
    useEffect(() => {
        if (provider) {
            console.error(`Authentication provider ${provider} is not supported`);
        } else {
            console.error('Authentication provider is not set');
        }
    }, [provider]);

    return (
        <div className="flex h-screen">
            <div className="m-auto text-center">
                <p className="text-gray-400 text-xs">No authentication provider configured.</p>
            </div>
        </div>
    );
};

export const InvalidAuthProviderConfig = () => {
    return (
        <div className="flex h-screen">
            <div className="m-auto text-center">
                <p className="text-gray-400 text-xs">Authentication provider incorrectly configured.</p>
            </div>
        </div>
    );
};
