import { useQuery } from '@apollo/client';
import {
    CheckCircleIcon,
    EllipsisHorizontalIcon,
    ExclamationCircleIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import { GET_PROVIDER_FIELD_MAP, LIST_PROVIDERS } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useMemo, useState } from 'react';
import { DynamicProvider, Provider } from 'Types/types';
import ProviderDetail from './ProviderDetail';

export const Providers = ({ onEdit }: { onEdit: (providerId: string, providerName: string) => void }): JSX.Element => {
    const tenantId = useTenant();
    const { loading, error, data } = useQuery(LIST_PROVIDERS, { variables: { tenantId }, pollInterval: 60000 });
    const { data: providerFieldMapData } = useQuery(GET_PROVIDER_FIELD_MAP, { variables: { tenantId } });

    const providersFieldMap = useMemo(() => {
        if (providerFieldMapData) {
            const { getProviderFieldMap } = providerFieldMapData;
            const tempProvidersFieldMap: Record<string, string> = {};
            getProviderFieldMap.map((provider: DynamicProvider) => {
                tempProvidersFieldMap[provider.Name] = provider.DisplayName;
            });
            return tempProvidersFieldMap;
        }
    }, [providerFieldMapData]);

    const getDisplayName = (name: string) => {
        if (providersFieldMap) {
            return providersFieldMap[name] || name;
        }
        return name;
    };

    let content = <></>;

    if (loading) {
        content = <h4 className="text-xs text-gray-300">Loading Providers...</h4>;
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not load providers. Please retry later.</h4>;
    } else if (data) {
        const providers: Provider[] = data.listProviders;
        const sortedProviders = providers
            .slice()
            .sort((a, b) => `${a.type}${a.name}`.localeCompare(`${b.type}${b.name}`));
        if (sortedProviders.length === 0) {
            content = (
                <h4 className="text-xs mt-4 text-gray-500" data-test="providers-list-empty">
                    You do not have any providers enabled yet.
                </h4>
            );
        } else {
            content = (
                <div className="space-y-2 pt-2" data-test="providers-list">
                    {sortedProviders.map((provider: Provider) => (
                        <ProviderComponent
                            provider={provider}
                            key={provider.providerId}
                            displayName={getDisplayName(provider.type)}
                            onEdit={onEdit}
                        />
                    ))}
                </div>
            );
        }
    }

    return content;
};

const ProviderDisplayIcon = ({ provider }: { provider: Provider }) => {
    const { status } = provider;

    switch (status) {
        case 'ACTIVE':
            return <CheckCircleIcon className="h-4 text-green-600" />;
        case 'READY':
            return <EllipsisHorizontalIcon className="h-4 text-blue-600" />;
        case 'ERROR':
            return <ExclamationCircleIcon className="h-4 text-yellow-500" />;
        case 'CONFIG_ERROR':
            return <ExclamationCircleIcon className="h-4 text-yellow-500" />;
        case 'DEGRADED':
            return <ExclamationCircleIcon className="h-4 text-yellow-500" />;
        case 'UNKNOWN':
            return <QuestionMarkCircleIcon className="h-4 text-blue-600" />;
        default:
            break;
    }

    return <CheckCircleIcon className="h-5 text-blue-600" />;
};

const ProviderComponent = ({
    provider,
    displayName,
    onEdit,
}: {
    provider: Provider;
    displayName?: string;
    onEdit: (providerId: string, providerName: string) => void;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div id="ProviderButton">
            <div className="flex justify-between">
                <span className="flex items-center space-x-1 capitalize">
                    <ProviderDisplayIcon provider={provider} />
                    <h4>
                        {displayName || provider.type}
                        <span className="text-gray-500"> — {provider.name}</span>
                    </h4>
                </span>
                <span>
                    <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none">
                        {isOpen ? 'Hide Details' : 'Show Details'}
                    </button>
                </span>
            </div>
            {isOpen && (
                <div className="mt-2 ml-5 mb-6">
                    <ProviderDetail data={provider} onEdit={onEdit} />
                </div>
            )}
        </div>
    );
};
