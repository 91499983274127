export const geojson = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                value: 2090,
                maxValue: 2090,
                row: 7,
                col: 5,
                opacity: 0.8,
                centerLat: 47.8125,
                centerLon: -118.125,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-123.75, 50.625],
                        [-112.5, 50.625],
                        [-112.5, 45],
                        [-123.75, 45],
                        [-123.75, 50.625],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-123.75, 50.625],
                        [-112.5, 50.625],
                        [-112.5, 45],
                        [-123.75, 45],
                        [-123.75, 50.625],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 38,
                maxValue: 2090,
                row: 7,
                col: 16,
                opacity: 0.43270896240210005,
                centerLat: 47.8125,
                centerLon: 5.625,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [0, 50.625],
                        [11.25, 50.625],
                        [11.25, 45],
                        [0, 45],
                        [0, 50.625],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [0, 50.625],
                        [11.25, 50.625],
                        [11.25, 45],
                        [0, 45],
                        [0, 50.625],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 1505,
                maxValue: 2090,
                row: 8,
                col: 7,
                opacity: 0.769903229565714,
                centerLat: 42.1875,
                centerLon: -95.625,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-101.25, 45],
                        [-90, 45],
                        [-90, 39.375],
                        [-101.25, 39.375],
                        [-101.25, 45],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-101.25, 45],
                        [-90, 45],
                        [-90, 39.375],
                        [-101.25, 39.375],
                        [-101.25, 45],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 85,
                maxValue: 2090,
                row: 8,
                col: 8,
                opacity: 0.5064969857723416,
                centerLat: 42.1875,
                centerLon: -84.375,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-90, 45],
                        [-78.75, 45],
                        [-78.75, 39.375],
                        [-90, 39.375],
                        [-90, 45],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-90, 45],
                        [-78.75, 45],
                        [-78.75, 39.375],
                        [-90, 39.375],
                        [-90, 45],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 9,
                maxValue: 2090,
                row: 8,
                col: 9,
                opacity: 0.30069301172902235,
                centerLat: 42.1875,
                centerLon: -73.125,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-78.75, 45],
                        [-67.5, 45],
                        [-67.5, 39.375],
                        [-78.75, 39.375],
                        [-78.75, 45],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-78.75, 45],
                        [-67.5, 45],
                        [-67.5, 39.375],
                        [-78.75, 39.375],
                        [-78.75, 45],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 29,
                maxValue: 2090,
                row: 8,
                col: 15,
                opacity: 0.40793557544523495,
                centerLat: 42.1875,
                centerLon: -5.625,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-11.25, 45],
                        [0, 45],
                        [0, 39.375],
                        [-11.25, 39.375],
                        [-11.25, 45],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-11.25, 45],
                        [0, 45],
                        [0, 39.375],
                        [-11.25, 39.375],
                        [-11.25, 45],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 1159,
                maxValue: 2090,
                row: 9,
                col: 5,
                opacity: 0.7459597758049694,
                centerLat: 36.5625,
                centerLon: -118.125,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-123.75, 39.375],
                        [-112.5, 39.375],
                        [-112.5, 33.75],
                        [-123.75, 33.75],
                        [-123.75, 39.375],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-123.75, 39.375],
                        [-112.5, 39.375],
                        [-112.5, 33.75],
                        [-123.75, 33.75],
                        [-123.75, 39.375],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 85,
                maxValue: 2090,
                row: 9,
                col: 7,
                opacity: 0.5064969857723416,
                centerLat: 36.5625,
                centerLon: -95.625,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-101.25, 39.375],
                        [-90, 39.375],
                        [-90, 33.75],
                        [-101.25, 33.75],
                        [-101.25, 39.375],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-101.25, 39.375],
                        [-90, 39.375],
                        [-90, 33.75],
                        [-101.25, 33.75],
                        [-101.25, 39.375],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 85,
                maxValue: 2090,
                row: 9,
                col: 8,
                opacity: 0.5064969857723416,
                centerLat: 36.5625,
                centerLon: -84.375,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-90, 39.375],
                        [-78.75, 39.375],
                        [-78.75, 33.75],
                        [-90, 33.75],
                        [-90, 39.375],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-90, 39.375],
                        [-78.75, 39.375],
                        [-78.75, 33.75],
                        [-90, 33.75],
                        [-90, 39.375],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 7,
                maxValue: 2090,
                row: 9,
                col: 9,
                opacity: 0.27765885588024625,
                centerLat: 36.5625,
                centerLon: -73.125,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-78.75, 39.375],
                        [-67.5, 39.375],
                        [-67.5, 33.75],
                        [-78.75, 33.75],
                        [-78.75, 39.375],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-78.75, 39.375],
                        [-67.5, 39.375],
                        [-67.5, 33.75],
                        [-78.75, 33.75],
                        [-78.75, 39.375],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 510,
                maxValue: 2090,
                row: 9,
                col: 15,
                opacity: 0.6707202145779239,
                centerLat: 36.5625,
                centerLon: -5.625,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-11.25, 39.375],
                        [0, 39.375],
                        [0, 33.75],
                        [-11.25, 33.75],
                        [-11.25, 39.375],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-11.25, 39.375],
                        [0, 39.375],
                        [0, 33.75],
                        [-11.25, 33.75],
                        [-11.25, 39.375],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 7,
                maxValue: 2090,
                row: 10,
                col: 18,
                opacity: 0.27765885588024625,
                centerLat: 30.9375,
                centerLon: 28.125,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [22.5, 33.75],
                        [33.75, 33.75],
                        [33.75, 28.125],
                        [22.5, 28.125],
                        [22.5, 33.75],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [22.5, 33.75],
                        [33.75, 33.75],
                        [33.75, 28.125],
                        [22.5, 28.125],
                        [22.5, 33.75],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 7,
                maxValue: 2090,
                row: 19,
                col: 12,
                opacity: 0.27765885588024625,
                centerLat: -19.6875,
                centerLon: -39.375,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-45, -16.875],
                        [-33.75, -16.875],
                        [-33.75, -22.5],
                        [-45, -22.5],
                        [-45, -16.875],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-45, -16.875],
                        [-33.75, -16.875],
                        [-33.75, -22.5],
                        [-45, -22.5],
                        [-45, -16.875],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 3,
                maxValue: 2090,
                row: 20,
                col: 11,
                opacity: 0.2,
                centerLat: -25.3125,
                centerLon: -50.625,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-56.25, -22.5],
                        [-45, -22.5],
                        [-45, -28.125],
                        [-56.25, -28.125],
                        [-56.25, -22.5],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-56.25, -22.5],
                        [-45, -22.5],
                        [-45, -28.125],
                        [-56.25, -28.125],
                        [-56.25, -22.5],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 7,
                maxValue: 2090,
                row: 20,
                col: 12,
                opacity: 0.27765885588024625,
                centerLat: -25.3125,
                centerLon: -39.375,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [-45, -22.5],
                        [-33.75, -22.5],
                        [-33.75, -28.125],
                        [-45, -28.125],
                        [-45, -22.5],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-45, -22.5],
                        [-33.75, -22.5],
                        [-33.75, -28.125],
                        [-45, -28.125],
                        [-45, -22.5],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                value: 55,
                maxValue: 2090,
                row: 22,
                col: 28,
                opacity: 0.46659802586887766,
                centerLat: -36.5625,
                centerLon: 140.625,
                radius: 698072.4717257156,
                coordinates: [
                    [
                        [135, -33.75],
                        [146.25, -33.75],
                        [146.25, -39.375],
                        [135, -39.375],
                        [135, -33.75],
                    ],
                ],
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [135, -33.75],
                        [146.25, -33.75],
                        [146.25, -39.375],
                        [135, -39.375],
                        [135, -33.75],
                    ],
                ],
            },
        },
    ],
};
